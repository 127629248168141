import React, { ChangeEvent, useEffect, useRef, useState } from "react"
import { FormattedMessage } from "react-intl"
import { Button, DialogActions } from "@material-ui/core"
import useStyles from "./FileUploadStyles"
import Modal from "../Modal/Modal"
import Input from "../Input/Input"
import FieldMessage, { Field } from "../Messages/FieldMessage"

interface IFileUploadProps {
  name: string
  messageId?: Field
  onUpload: (files: Array<File | string>) => void
  isMultiple?: boolean
  hasError?: boolean
  accept?: string
  allowURL?: boolean
  required?: boolean
}

export default function FileUpload({
  name,
  onUpload,
  messageId,
  isMultiple = false,
  allowURL = false,
  required = false,
  hasError = false,
  accept = "image/*",
}: IFileUploadProps) {
  const classes = useStyles({ hasError })
  const [isModalOpen, setIsModalOpen] = useState(false)
  const allowUpload = accept !== ""
  const inputRef = useRef<HTMLInputElement>(null)

  const handleUrlSubmit = ({ target }: ChangeEvent<HTMLFormElement>) => {
    setIsModalOpen(false)
    const links = target[name].value.split(`\n`)
    if (links.length > 0) onUpload(links)
  }

  useEffect(() => {
    // reset the value when name changes
    // same file prevent handleInputChange to be triggered
    if (inputRef.current) inputRef.current.value = ``
  }, [name])

  const handleInputChange = ({
    currentTarget: { files },
  }: ChangeEvent<HTMLInputElement>) => {
    if (files) onUpload(Array.from(files))
  }

  return (
    <div className={classes.root}>
      {messageId && (
        // eslint-disable-next-line jsx-a11y/label-has-associated-control
        <label htmlFor={`fileUpload-${name}`} className={classes.label}>
          <FieldMessage id={messageId} />
        </label>
      )}
      <div className={classes.wrapper}>
        <div className={classes.prompt}>
          {allowUpload && (
            <Button component={"label"} htmlFor={`fileUpload-${name}`}>
              {isMultiple ? (
                <FormattedMessage
                  id={`uploadFiles`}
                  defaultMessage={`Upload Files`}
                />
              ) : (
                <FormattedMessage
                  id={`uploadFile`}
                  defaultMessage={`Upload File`}
                />
              )}
            </Button>
          )}
          {allowURL && (
            <Button onClick={() => setIsModalOpen(true)}>
              {isMultiple ? (
                <FormattedMessage
                  id={`insertUrls`}
                  defaultMessage={`Insert URLs`}
                />
              ) : (
                <FormattedMessage
                  id={`insertUrl`}
                  defaultMessage={`Insert URL`}
                />
              )}
            </Button>
          )}
        </div>
        {allowUpload && (
          <input
            className={classes.input}
            type="file"
            name={name}
            id={`fileUpload-${name}`}
            onChange={handleInputChange}
            multiple={isMultiple}
            accept={accept}
            required={required}
            ref={inputRef}
          />
        )}
      </div>
      <Modal onClose={() => setIsModalOpen(false)} open={isModalOpen}>
        <form onSubmit={handleUrlSubmit}>
          <Input
            name={name}
            messageId={isMultiple ? Field.URLS : Field.URL}
            multiline={isMultiple}
            helperText={
              isMultiple ? (
                <FormattedMessage
                  id={`multipleUrlHelp`}
                  defaultMessage={`Use newline to insert multiple links`}
                />
              ) : undefined
            }
          />
          <DialogActions>
            <Button type={"submit"} variant={"contained"} color={"default"}>
              {isMultiple ? (
                <FormattedMessage
                  id={`insertUrls`}
                  defaultMessage={`Insert URLs`}
                />
              ) : (
                <FormattedMessage
                  id={`insertUrl`}
                  defaultMessage={`Insert URL`}
                />
              )}
            </Button>
          </DialogActions>
        </form>
      </Modal>
    </div>
  )
}
