import { RequestStatus as Status, RequestStatus } from "../types/statusTypes"
import { makeShowState, ShowActions, ShowState } from "../models/showModel"
import { ActionTypes, AnyAction } from "../types/actionTypes"

const initialState: ShowState = makeShowState()

export default function showReducer(
  state = initialState,
  action: AnyAction | null = null
): ShowState {
  // helpers
  const setStatus = (key: ShowActions, status: Status) =>
    state.set("statuses", state.statuses.set(key, status))
  const setError = (key: ShowActions, error: string) =>
    state
      .set("errors", state.errors.set(key, error))
      .set("statuses", state.statuses.set(key, RequestStatus.FAILED))

  switch (action?.type) {
    // reset status to default and error message to empty string
    case ActionTypes.RESET_SHOW_STATUS:
      return setStatus(action.payload.key, RequestStatus.INITIAL).set(
        "errors",
        state.errors.set(action.payload.key, "")
      )

    // fetch
    case ActionTypes.FETCH_SHOWS_REQUESTED:
      return setStatus("fetchShows", RequestStatus.REQUESTED)

    case ActionTypes.FETCH_SHOWS_SUCCEEDED:
      return setStatus("fetchShows", RequestStatus.SUCCEEDED).set(
        "records",
        action.payload.shows
      )

    case ActionTypes.FETCH_SHOWS_FAILED:
      return setError("fetchShows", action.payload.message)

    // save
    case ActionTypes.SAVE_SHOW_REQUESTED:
      return setStatus("saveShow", RequestStatus.REQUESTED)

    case ActionTypes.SAVE_SHOW_SUCCEEDED:
      return setStatus("saveShow", RequestStatus.SUCCEEDED).set(
        "records",
        state.records.set(
          action.payload.show.id.toString(),
          action.payload.show
        )
      )

    case ActionTypes.SAVE_SHOW_FAILED:
      return setError("saveShow", action.payload.message)

    // delete
    case ActionTypes.DELETE_SHOW_REQUESTED:
      return setStatus("deleteShow", RequestStatus.REQUESTED)

    case ActionTypes.DELETE_SHOW_SUCCEEDED:
      return setStatus("deleteShow", RequestStatus.SUCCEEDED).set(
        "records",
        state.records.delete(action.payload.id.toString())
      )

    case ActionTypes.DELETE_SHOW_FAILED:
      return setError("deleteShow", action.payload.message)

    default: {
      return state
    }
  }
}
