import React, { ReactNode } from "react"
import CloseIcon from "@material-ui/icons/Close"
import {
  Fab,
  Dialog,
  DialogProps,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@material-ui/core"
import useStyles from "./ModalStyle"

interface Props {
  title?: ReactNode
  actions?: ReactNode
  onClose?: () => void
  dividers?: boolean
}

export default function Modal({
  title,
  open,
  onClose,
  children,
  actions,
  dividers,
  ...props
}: Props & Omit<DialogProps, "title">) {
  const classes = useStyles()

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      classes={{ paper: classes.root }}
      {...props}
    >
      {onClose && (
        <Fab
          size={`small`}
          onClick={onClose}
          aria-label="close modal"
          color={`primary`}
          className={classes.dialogAction}
        >
          <CloseIcon />
        </Fab>
      )}
      {title && (
        <DialogTitle id="modal-title" className={classes.title}>
          {title}
        </DialogTitle>
      )}
      <DialogContent className={classes.content} dividers={dividers}>
        {children}
      </DialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  )
}
