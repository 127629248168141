import React, { ChangeEvent } from "react"
import { EditComponentProps } from "@material-table/core"
import { ExtendedOrderReqAdmin, OrderDataGrid } from "../../types/globalTypes"
import CellGroup from "../../components/CellGroup/CellGroup"
import Input from "../../components/Input/Input"
import { blankOrderData, groupFormFields } from "../../utils/typeUtils"
import { Field } from "../../components/Messages/FieldMessage"
import { useRequiredFirstFormFields } from "../../hooks/activityHooks"
import { isEmpty } from "ramda"
import { ErrorType } from "../../components/Messages/ErrorMessage"

export function OrderGroupEditCell({
  rowData: { req = blankOrderData },
  onChange,
}: EditComponentProps<OrderDataGrid>) {
  const [sortedFields, isRequired] = useRequiredFirstFormFields(
    groupFormFields,
    req?.packageOrder?.optionId
  )

  const changeRow = (data: ExtendedOrderReqAdmin) => {
    onChange(data)
  }

  const handleChange = ({
    currentTarget: { name, value },
  }: ChangeEvent<HTMLInputElement>) => {
    changeRow({ ...req, [name]: value })
  }

  const handleChangeContactInfo = ({
    currentTarget: { name, value },
  }: ChangeEvent<HTMLInputElement>) => {
    if (req.packageOrder) {
      changeRow({
        ...req,
        packageOrder: {
          ...req.packageOrder,
          contactInfo: { ...req.packageOrder.contactInfo, [name]: value },
        },
      })
    }
  }

  return (
    <CellGroup>
      <Input
        type="text"
        name={"name"}
        messageId={Field.NAME}
        value={req.name}
        onChange={handleChange}
        required
        dense
      />
      <Input
        type="text"
        name={"email"}
        messageId={Field.EMAIL}
        value={req.email}
        onChange={handleChange}
        required
        dense
      />
      <Input
        type="text"
        name={"phone"}
        messageId={Field.PHONE}
        value={req.phone}
        inputError={
          isEmpty(req.phone) ? { type: ErrorType.REQUIRED } : undefined
        }
        onChange={handleChange}
        required
        dense
      />
      {sortedFields.map(
        (field) =>
          req.packageOrder && (
            <Input
              key={field}
              type="text"
              name={field}
              messageId={field}
              value={req.packageOrder.contactInfo[field]}
              onChange={handleChangeContactInfo}
              dense
              required={isRequired(field)}
            />
          )
      )}
    </CellGroup>
  )
}
