import { useSelector } from "react-redux"
import AddBox from "@material-ui/icons/AddBox"
import React, { memo, useState } from "react"
import { Navigate } from "react-router-dom"
import { FormattedMessage } from "react-intl"
import Button from "../../components/Button/Button"
import DataGrid from "../../components/DataGrid/DataGrid"
import config from "../../config"
import { PackageResAdmin } from "../../types/apiTypes"
import Results from "../../components/Results/Results"
import RouteMessage from "../../components/Messages/RouteMessage"
import { Field } from "../../components/Messages/FieldMessage"
import { Entity } from "../../components/Messages/EntityMessage"
import {
  useActivityDelete,
  useActivityFetch,
  useActivitySave,
} from "../../hooks/activityHooks"
import { useResourceFetch } from "../../hooks/resourceHooks"
import ActivityListPurposeCell from "./ActivityListPurposeCell"
import ActivityListTypeCell from "./ActivityListTypeCell"
import {
  useLanguage,
  useLocalStoragePagination,
} from "../../hooks/interfaceHooks"
import StatusProgressBar from "../../components/StatusProgressBar/StatusProgressBar"
import BoolMessage from "../../components/Messages/BoolMessage"
import SourceFilter from "../../components/SourceFilter/SourceFilter"
import { selectActivityDataGrid } from "../../selectors/activitySelector"

function ActivityList() {
  const [redirectId, setRedirectId] = useState<string | null>(null)
  const language = useLanguage()
  const [, fetchStatus, fetchError] = useActivityFetch()
  const activities = useSelector(selectActivityDataGrid)
  const [deleteActivity, deleteStatus, deleteError] = useActivityDelete()
  const [, resourceFetchStatus, resourceFetchError] = useResourceFetch()
  const [, saveStatus, saveError] = useActivitySave()
  const paginationProps = useLocalStoragePagination("activity")

  return (
    <>
      {redirectId !== null && (
        <Navigate to={config.routes.activityDetail.path(redirectId)} />
      )}
      <Results
        statuses={[fetchStatus, deleteStatus, saveStatus, resourceFetchStatus]}
        errors={[fetchError, deleteError, saveError, resourceFetchError]}
        actions={["fetch", "delete", "save", "fetch"]}
        entity={Entity.ACTIVITY}
      />
      <StatusProgressBar statuses={[fetchStatus, deleteStatus, saveStatus]} />
      <DataGrid<PackageResAdmin>
        {...paginationProps}
        title={<RouteMessage id={"activityList"} />}
        onRowClick={(_, row) => setRedirectId(row ? row.id.toString() : null)}
        customFilter={<SourceFilter />}
        cta={
          <Button
            onClick={() => setRedirectId(``)}
            variant="contained"
            color={`primary`}
            startIcon={<AddBox />}
          >
            <FormattedMessage
              id={`createActivity`}
              defaultMessage={`Create activity`}
            />
          </Button>
        }
        columns={[
          {
            title: Field.TITLE,
            field: `translations.${language}.title`,
            defaultSort: "asc",
            customSort: (a, b) =>
              a.translations[language].title.localeCompare(
                b.translations[language].title
              ),
          },
          {
            title: Field.PURPOSE,
            field: `tagIds`,
            render: ActivityListPurposeCell,
            customSort: (a) => (a.isForWeb ? 1 : -1),
          },
          {
            title: Field.TYPE,
            field: `tagIds`,
            render: ActivityListTypeCell,
            sorting: false,
          },
          {
            title: Field.IS_ACTIVE,
            field: `isActive`,
            render: ({ isActive }: PackageResAdmin) => (
              <BoolMessage value={isActive} />
            ),
          },
        ]}
        data={activities}
        editable={{
          onRowDelete: ({ id }) =>
            new Promise<void>((resolve) => {
              deleteActivity(id)
              resolve()
            }),
        }}
      />
    </>
  )
}

export default memo(ActivityList)
