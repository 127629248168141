import React from "react"
import { Tab, Tabs, Badge } from "@material-ui/core"
import { Language } from "../../types/apiTypes"
import { languageList } from "../../utils/typeUtils"
import useStyles from "./LanguageTabStyles"

interface ILanguageTabProps {
  language: Language
  notifications?: Language[]
  onChange: (lang: Language) => void
}

export default function LanguageTab({
  language,
  onChange,
  notifications,
}: ILanguageTabProps) {
  const classes = useStyles()
  const handleChange = (event: React.ChangeEvent<{}>, index: number) => {
    onChange(languageList[index])
  }

  return (
    <Tabs
      value={languageList.indexOf(language)}
      indicatorColor="primary"
      textColor="primary"
      onChange={handleChange}
    >
      {languageList.map((lang) => (
        <Tab
          label={
            <Badge
              color={"error"}
              variant={"dot"}
              overlap={"rectangular"}
              invisible={!notifications?.includes(lang)}
            >
              {lang}
            </Badge>
          }
          key={lang}
          className={classes.tab}
        />
      ))}
    </Tabs>
  )
}
