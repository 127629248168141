export interface GuideReq {
  email: string
  name: string
  phone: string
}

export enum InvoicePublisher {
  EMPTY = "EMPTY",
  ADDLAND = "ADDLAND",
  ADDLAND_SERVICES = "ADDLAND_SERVICES",
  ADDLAND_INCOMING = "ADDLAND_INCOMING",
}

export enum PaymentMethod {
  CREDIT_CARD = "CREDIT_CARD",
  TRANSFER = "TRANSFER",
  INVOICE = "INVOICE",
  CASH = "CASH",
}

export interface AgencyReq {
  accountNumber: string
  address: string
  crn: string
  email: string
  guides: GuideReq[]
  id: number
  invoiceDates: number[]
  invoicePublisher: InvoicePublisher
  note: string
  paymentMethods: PaymentMethod[]
  phone: string
  title: string
  vatId: string
  vatRate: number | null
}

export interface GuideRes {
  email: string
  name: string
  phone: string
}

export interface AgencyResAdmin {
  accountNumber: string
  address: string
  crn: string
  email: string
  guides: GuideRes[]
  id: number
  invoiceDates: number[]
  invoicePublisher: InvoicePublisher
  note: string
  paymentMethods: PaymentMethod[]
  phone: string
  title: string
  vatId: string
  vatRate: number | null
}

export enum BonCategory {
  TABLE_DANCE_2000 = "TABLE_DANCE_2000",
  TOPLESS_TABLE_DANCE_1000 = "TOPLESS_TABLE_DANCE_1000",
  DANCE_1500 = "DANCE_1500",
  AUTOEROTIC_SHOW_3000 = "AUTOEROTIC_SHOW_3000",
  STAG_SHOW_2500 = "STAG_SHOW_2500",
  VIP_TABLE_DANCE_SPECIAL_3000 = "VIP_TABLE_DANCE_SPECIAL_3000",
  CHR_15 = "CHR_15",
  CHR_30 = "CHR_30",
  CHR_60 = "CHR_60",
  MOET_CHR_30 = "MOET_CHR_30",
  MOET_CHR_60 = "MOET_CHR_60",
  BOTTLE_ALCOHOL_0_7 = "BOTTLE_ALCOHOL_0_7",
  BOTTLE_ALCOHOL_1_0 = "BOTTLE_ALCOHOL_1_0",
  BOTTLE_ALCOHOL_0_7_PREMIUM_BRANDS = "BOTTLE_ALCOHOL_0_7_PREMIUM_BRANDS",
  BOTTLE_ALCOHOL_1_0_PREMIUM_BRANDS = "BOTTLE_ALCOHOL_1_0_PREMIUM_BRANDS",
  BOTTLE_ALCOHOL_0_7_MOET_BRUT = "BOTTLE_ALCOHOL_0_7_MOET_BRUT",
  NONALCOHOLIC_DRINKS_5 = "NONALCOHOLIC_DRINKS_5",
  TAP_BEERS_10 = "TAP_BEERS_10",
  WELCOME_DRINK = "WELCOME_DRINK",
  BOTTLE_BOHEMIA = "BOTTLE_BOHEMIA",
  GIFT_BOA = "GIFT_BOA",
  SHORTS_FIGHT_WITH_STAG = "SHORTS_FIGHT_WITH_STAG",
  OTHER = "OTHER",
  TIPPING_DOLLARS = "TIPPING_DOLLARS",
  BEER_OR_DRINK_CARD = "BEER_OR_DRINK_CARD",
  BEER_CAN = "BEER_CAN",
  SHIRT_GROOM = "SHIRT_GROOM",
  SHIRT_FRIENDS = "SHIRT_FRIENDS",
  OPEN_CONSUMATION_1000 = "OPEN_CONSUMATION_1000",
  BRACELET_DOUBLETIME = "BRACELET_DOUBLETIME",
  BRACELET_DRINKS = "BRACELET_DRINKS",
  OLMECA_SHOT = "OLMECA_SHOT",
  CORONA_EXTRA = "CORONA_EXTRA",
  UNLIMITED_BEER_FRIEND = "UNLIMITED_BEER_FRIEND",
}

export enum BonResponsibility {
  HOSTESS = "HOSTESS",
  COORDINATOR = "COORDINATOR",
  BAR = "BAR",
  SHUTTLE = "SHUTTLE",
}

export interface BonReq {
  category: BonCategory
  content: string
  count: number
  forNPersons: number
  responsibility: BonResponsibility
}

export enum CareerPosition {
  acrobatics = "acrobatics",
  bartender = "bartender",
  fullNude = "fullNude",
  gogo = "gogo",
  hostess = "hostess",
  other = "other",
  receptionist = "receptionist",
  securityGuard = "securityGuard",
  waitress = "waitress",
}

export interface CareerApplicationReq {
  email: string
  fileUrls: string[]
  id: number
  message: string
  name: string
  phone: string
  position: CareerPosition
}

export interface CareerApplicationResAdmin {
  email: string
  fileUrls: string[]
  id: number
  message: string
  name: string
  phone: string
  position: CareerPosition
}

export interface ErrorRes {
  reason: string
}

export enum Language {
  CS = "CS",
  EN = "EN",
  FR = "FR",
  DE = "DE",
}

export interface TranslationReq {
  additionalInfo: string
  content: string
  keyword: string | null
  metaData: string
  title: string
}

export enum MediaType {
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
  PANORAMA = "PANORAMA",
}

export interface MediumReq {
  translations: { [key in Language]: TranslationReq }
  type: MediaType
  url: string
}

export interface PriorityReq {
  id: number
  priority: number
}

export interface GalleryReq {
  id: number
  isActive: boolean
  media: MediumReq[]
  priority: number
  tagIds: PriorityReq[]
  translations: { [key in Language]: TranslationReq }
}

export interface MediumRes {
  additionalInfo: string
  content: string
  keyword: string | null
  metaData: string
  title: string
  type: MediaType
  url: string
}

export interface PriorityRes {
  id: number
  priority: number
}

export interface GalleryRes {
  additionalInfo: string
  content: string
  id: number
  keyword: string | null
  media: MediumRes[]
  metaData: string
  priority: number
  tagIds: PriorityRes[]
  title: string
}

export interface TranslationResAdmin {
  additionalInfo: string
  content: string
  keyword: string | null
  metaData: string
  title: string
}

export interface MediumResAdmin {
  translations: { [key in Language]: TranslationResAdmin }
  type: MediaType
  url: string
}

export interface GalleryResAdmin {
  id: number
  isActive: boolean
  media: MediumResAdmin[]
  priority: number
  tagIds: PriorityRes[]
  translations: { [key in Language]: TranslationResAdmin }
}

export interface IdRes<T> {
  id: T
}

export interface AccountingInfo {
  accountNumber: string
  crn: string
  vatId: string
  vatRate: number | null
}

export interface AccessRights {
  adminCreator: "W"|"R"|"N"
  agency: "W"|"R"|"N"
  agencySelf: "W"|"R"|"N"
  invoice: "W"|"R"|"N"
  order: "W"|"R"|"N"
  orderAdmin: "W"|"R"|"N"
  orderCosts: "W"|"R"|"N"
  orderEmployee: "W"|"R"|"N"
  orderEmployeeReward: "W"|"R"|"N"
  orderPayment: "W"|"R"|"N"
  orderPrice: "W"|"R"|"N"
  pack: "W"|"R"|"N"
  packagePricesAgency: "W"|"R"|"N"
  packagePricesWeb: "W"|"R"|"N"
  page: "W"|"R"|"N"
  product: "W"|"R"|"N"
  resource: "W"|"R"|"N"
  section: "W"|"R"|"N"
  session: "W"|"R"|"N"
  sessionAdmin: "W"|"R"|"N"
  uiAgency: "W"|"R"|"N"
  uiInvoice: "W"|"R"|"N"
  uiOrderEmployee: "W"|"R"|"N"
  uiOrderFilter: "W"|"R"|"N"
  uiOrderList: "W"|"R"|"N"
  uiPackage: "W"|"R"|"N"
  uiPages: "W"|"R"|"N"
  uiReport: "W"|"R"|"N"
  uiReservation: "W"|"R"|"N"
  uiUserRoles: "W"|"R"|"N"
  uiVoucher: "W"|"R"|"N"
  user: "W"|"R"|"N"
  userAvailability: "W"|"R"|"N"
}

export type Role = "SuperAdmin" | "Admin" | "Reservations" | "Agency" | "Hostess" | "Manager" | "DanceManager" | EmployeeRole
export type EmployeeRole = "Dancer" | "DancerToken" | "DancerClub" | "DancerMale" | "DancerXXL" | "Driver" | "Steward" | "CoordinatorInternal" | "CoordinatorExternal" | "Security" | "Other"

export interface UserRes {
  accessRights: AccessRights
  accountNumber: string
  address: string
  agencyId: number | null
  crn: string
  email: string
  fullName: string
  id: number
  nickname: string
  note: string
  phone: string
  roles: Role[]
  sendInvoice: boolean
  vatId: string
}

export interface ComputedBon {
  category: BonCategory
  content: string
  count: number
  responsibility: BonResponsibility
}

export interface Bon {
  category: BonCategory
  content: string
  count: number
  forNPersons: number
  responsibility: BonResponsibility
}

export interface OrderCancellationResAdmin {
  cancellationFee: number
  cancelledAt: number
}

export enum Currency { CZK = "CZK", EUR = "EUR",
}

export enum NoteType {
  NOTE_ADMIN = "NOTE_ADMIN",
  NOTE_EMPLOYEE = "NOTE_EMPLOYEE",
  NOTE_OPEN = "NOTE_OPEN",
  NOTE_EXTRA = "NOTE_EXTRA",
  COLOR = "COLOR",
}

export enum OrderEmployeeStatus {
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
}

export interface OrderEmployeeResAdmin {
  dtEnd: number
  dtStart: number
  id: number
  note: string
  orderId: number
  packageOptionId: number | null
  reward: number
  role: Role
  specification: string | null
  status: OrderEmployeeStatus
  upsaleId: number | null
  userId: number | null
  uuid: string
}

export interface VatRateOverride {
  value: number | null
}

export enum FormField {
  FLIGHT_ARRIVAL_DATETIME = "FLIGHT_ARRIVAL_DATETIME",
  FLIGHT_NUMBER = "FLIGHT_NUMBER",
  ACTIVITY_START_DATETIME = "ACTIVITY_START_DATETIME",
  ACTIVITY_ADDRESS = "ACTIVITY_ADDRESS",
  RESERVATION_DATETIME = "RESERVATION_DATETIME",
  PICKUP_DATETIME = "PICKUP_DATETIME",
  DRIVE_START_DATETIME = "DRIVE_START_DATETIME",
  PICKUP_ADDRESS = "PICKUP_ADDRESS",
  DROPOUT_DATETIME = "DROPOUT_DATETIME",
  DROPOUT_ADDRESS = "DROPOUT_ADDRESS",
  GUIDE_CONTACT = "GUIDE_CONTACT",
  DRIVER_CONTACT = "DRIVER_CONTACT",
  COORDINATOR_CONTACT = "COORDINATOR_CONTACT",
}

export interface ResourceBlockingResAdmin {
  durationActual: number
  durationAfter: number
  durationBefore: number
  offset: number
  quantityBlocked: number
  quantityRequired: number
  resourceId: number
}

export interface CancellationPolicyResAdmin {
  interval: number
  percentage: number
}

export interface PricePerNCapitasResAdmin {
  numberOfPersons: number
  price: number
}

export interface InlineGalleryResAdmin {
  media: MediumResAdmin[]
  translations: { [key in Language]: TranslationResAdmin }
}

export interface RequiredRoleResAdmin {
  durationActual: number
  durationAfter: number
  durationBefore: number
  note: string
  offset: number
  reward: number
  role: Role
}

export interface PackageOptionResAdmin {
  availableFrom: number
  availableTo: number
  blocking: ResourceBlockingResAdmin[]
  bons: Bon[]
  cancellationPolicy: CancellationPolicyResAdmin[]
  cost: number
  costPerNCapitas: PricePerNCapitasResAdmin
  gallery: InlineGalleryResAdmin | null
  id: number
  isConstrainedByOpeningHours: boolean
  maxCapacity: number
  minCapacity: number
  optionalFields: FormField[]
  packageId: number
  priority: number
  requiredFields: FormField[]
  requiredRoles: RequiredRoleResAdmin[]
  translations: { [key in Language]: TranslationResAdmin }
}

export enum PackageType {
  activity = "activity",
  trip = "trip",
  shuttle = "shuttle",
  reservation = "reservation",
  hen = "hen",
  stag = "stag",
}

export interface PackageUpsaleIdsWithPriorityRes {
  priority: number
  upsaleId: number
}

export interface PackageResAdmin {
  addToReservation: boolean
  containsReservation: boolean
  gallery: InlineGalleryResAdmin | null
  id: number
  isActive: boolean
  isForWeb: boolean
  options: PackageOptionResAdmin[]
  priority: number
  requiredUpsaleIds: number[][]
  tagIds: PriorityRes[]
  translations: { [key in Language]: TranslationResAdmin }
  type: PackageType[]
  upsalePackageIds: PackageUpsaleIdsWithPriorityRes[]
}

export interface PackageOrderResAdmin {
  bons: ComputedBon[]
  contactInfo: { [key in FormField]: string }
  dtStart: number
  numberOfPersons: number
  option: PackageOptionResAdmin
  orderEmployees: OrderEmployeeResAdmin[]
  pack: PackageResAdmin
}

export interface SeatingResAdmin {
  id: number
  maxCapacity: number
  minCapacity: number
  sectionId: number
  title: string
}

export interface SectionResAdmin {
  bons: Bon[]
  id: number
  price: number
  translations: { [key in Language]: TranslationResAdmin }
}

export interface ReservationInnerResAdmin {
  seating: SeatingResAdmin
  section: SectionResAdmin
}

export interface ReservationResAdmin {
  bons: ComputedBon[]
  contactInfo: { [key in FormField]: string }
  dtStart: number
  numberOfPersons: number
  reservation: ReservationInnerResAdmin[]
}

export enum OrderStatus {
  CREATED = "CREATED",
  APPROVED = "APPROVED",
  FULFILLED = "FULFILLED",
  CANCELLED = "CANCELLED",
}

export interface PackageUpsaleResAdmin {
  availableFrom: number
  availableTo: number
  blocking: ResourceBlockingResAdmin[]
  bons: Bon[]
  containsReservation: boolean
  cost: number
  costPerNCapitas: PricePerNCapitasResAdmin
  gallery: InlineGalleryResAdmin | null
  id: number
  isActive: boolean
  isConstrainedByOpeningHours: boolean
  isForWeb: boolean
  maxCapacity: number
  minCapacity: number
  optionalFields: FormField[]
  priority: number
  requiredFields: FormField[]
  requiredRoles: RequiredRoleResAdmin[]
  tagIds: PriorityRes[]
  translations: { [key in Language]: TranslationResAdmin }
  type: PackageType[]
}

export interface UpsaleResAdmin {
  bons: ComputedBon[]
  contactInfo: { [key in FormField]: string }
  dtStart: number
  numberOfPersons: number
  orderEmployees: OrderEmployeeResAdmin[]
  quantity: number
  upsaleOption: PackageUpsaleResAdmin
  uuid: string
}

export interface OrderResAdmin {
  agencyId: number
  balance: number
  balanceHolder: UserRes | null
  bonSum: ComputedBon[]
  bons: Bon[]
  bonsComputed: ComputedBon[]
  cancellation: OrderCancellationResAdmin | null
  cost: number
  currency: Currency
  deposit: number
  email: string | null
  id: number
  isLocked: boolean
  isPaidDeposit: boolean
  isPaidRest: boolean
  language: Language
  name: string
  notes: { [key in NoteType]: string }
  orderEmployees: OrderEmployeeResAdmin[]
  overrideInvoicePublisher: InvoicePublisher | null
  overrideVatRate: VatRateOverride | null
  packageOrder: PackageOrderResAdmin | null
  paidByUserId: number | null
  paymentMethod: PaymentMethod
  paymentMethodDeposit: PaymentMethod
  phone: string | null
  price: number
  reservation: ReservationResAdmin | null
  status: OrderStatus
  upsales: UpsaleResAdmin[]
  voucherId: string
  voucherUsedAt: number | null
}

export enum InvoiceStatus {
  ISSUED = "ISSUED",
  PAID = "PAID",
  DELVERED = "DELVERED",
  CANCELLED = "CANCELLED",
}

export interface InvoiceResAdmin {
  accountingInfo: AccountingInfo
  address: string
  agencyId: number
  createdAt: number
  dueDate: number
  id: number
  invoicePublisher: InvoicePublisher
  orders: OrderResAdmin[]
  paymentMethod: PaymentMethod
  status: InvoiceStatus
  title: string
}

export interface LinkRes {
  link: string
}

export interface MinimalPackageOrderReq {
  mainPackageOptionId: number
  numberOfPersons: number
}

export interface MinimalReservationReq {
  numberOfPersons: number
  seatingIds: number[]
}

export interface MinimalUpsaleReq {
  numberOfPersons: number
  packageUpsaleId: number
  quantity: number
}

export interface MinimalOrderReq {
  packageOrder: MinimalPackageOrderReq | null
  reservation: MinimalReservationReq | null
  upsales: MinimalUpsaleReq[]
}

export interface MinimalOrderReqAdmin {
  agencyId: number
  packageOrder: MinimalPackageOrderReq | null
  reservation: MinimalReservationReq | null
  upsales: MinimalUpsaleReq[]
}

export enum OrderError {
  OVER_MAX_CAPACITY = "OVER_MAX_CAPACITY",
  INVALID_TIME = "INVALID_TIME",
  SEATING_UNAVAILABLE = "SEATING_UNAVAILABLE",
}

export interface OrderErrorRes {
  reasons: OrderError[]
}

export interface PackageOrderReq {
  contactInfo: { [key in FormField]: string }
  dtStart: number
  mainPackageOptionId: number
  numberOfPersons: number
}

export interface ReservationReq {
  contactInfo: { [key in FormField]: string }
  dtStart: number
  numberOfPersons: number
  seatingIds: number[]
}

export interface UpsaleReq {
  contactInfo: { [key in FormField]: string }
  dtStart: number
  numberOfPersons: number
  packageUpsaleId: number
  quantity: number
}

export interface OrderReq {
  currency: Currency
  email: string
  hasMarketingApproval: boolean
  name: string
  note: string
  packageOrder: PackageOrderReq | null
  paymentMethod: PaymentMethod
  phone: string
  reservation: ReservationReq | null
  upsales: UpsaleReq[]
}

export interface OrderCancellationReq {
  cancellationFee: number
  cancelledAt: number
}

export interface OrderEmployeeOrderReq {
  dtEnd: number
  dtStart: number
  id: number
  note: string
  reward: number
  role: Role
  status: OrderEmployeeStatus
  userId: number | null
  uuid: string
}

export interface PackageOrderReqAdmin {
  contactInfo: { [key in FormField]: string }
  dtStart: number
  numberOfPersons: number
  optionId: number
  orderEmployees: OrderEmployeeOrderReq[]
}

export interface ReservationReqAdmin {
  contactInfo: { [key in FormField]: string }
  dtStart: number
  numberOfPersons: number
  seatingIds: number[]
}

export interface UpsaleReqAdmin {
  contactInfo: { [key in FormField]: string }
  dtStart: number
  numberOfPersons: number
  orderEmployees: OrderEmployeeOrderReq[]
  packageUpsaleId: number
  quantity: number
  uuid: string | null
}

export interface OrderReqAdmin {
  agencyId: number
  balance: number
  balanceHolderId: number | null
  bons: Bon[]
  cancellation: OrderCancellationReq | null
  cost: number
  currency: Currency
  deposit: number
  email: string | null
  id: number
  isLocked: boolean
  isPaidDeposit: boolean
  isPaidRest: boolean
  language: Language
  name: string
  notes: { [key in NoteType]: string }
  orderEmployees: OrderEmployeeOrderReq[]
  overrideInvoicePublisher: InvoicePublisher | null
  overrideVatRate: VatRateOverride | null
  packageOrder: PackageOrderReqAdmin | null
  paymentMethod: PaymentMethod
  paymentMethodDeposit: PaymentMethod
  phone: string | null
  price: number
  reservation: ReservationReqAdmin | null
  status: OrderStatus
  upsales: UpsaleReqAdmin[]
  voucherId: string | null
}

export interface OrderRes {
  currency: Currency
  deposit: number
  email: string | null
  name: string
  paymentMethod: PaymentMethod
  phone: string | null
  price: { [key in Currency]: number }
  shouldBePaid: boolean
  token: string
}

export interface PricePerNCapitasReq {
  numberOfPersons: number
  price: number
}

export interface PackageOptionPriceReqElement {
  agencyId: number
  deposit: number
  feeOver10Persons: number
  packageOptionId: number
  price: number
  pricePerNCapitas: PricePerNCapitasReq
}

export interface PackageOptionPriceReq {
  value: PackageOptionPriceReqElement[]
}

export interface PricePerNCapitasRes {
  numberOfPersons: number
  price: { [key in Currency]: number }
}

export interface PackageOptionPriceRes {
  agencyId: number
  deposit: { [key in Currency]: number }
  feeOver10Persons: { [key in Currency]: number }
  packageOptionId: number
  price: { [key in Currency]: number }
  pricePerNCapitas: PricePerNCapitasRes
}

export interface PackageOptionPriceResAdmin {
  agencyId: number
  deposit: number
  feeOver10Persons: number
  packageOptionId: number
  price: number
  pricePerNCapitas: PricePerNCapitasResAdmin
}

export interface PriceBonResAdmin {
  bonSum: ComputedBon[]
  option: ComputedBon[]
  reservation: ComputedBon[]
  upsales: ComputedBon[]
}

export interface PriceContentResAdmin {
  balance: number
  costs: number
  deposit: number
  price: number
}

export interface PriceResInnerAdmin {
  option: PriceContentResAdmin
  reservation: PriceContentResAdmin
  upsales: PriceContentResAdmin
}

export interface PriceResAdmin {
  bons: PriceBonResAdmin
  price: PriceResInnerAdmin
}

export interface InlineGalleryReq {
  media: MediumReq[]
  translations: { [key in Language]: TranslationReq }
}

export interface BlockingReq {
  durationActual: number
  durationAfter: number
  durationBefore: number
  offset: number
  quantityBlocked: number
  quantityRequired: number
  resourceId: number
}

export interface PackageCancellationPolicyReq {
  interval: number
  percentage: number
}

export interface RequiredRoleReq {
  durationActual: number
  durationAfter: number
  durationBefore: number
  note: string
  offset: number
  reward: number
  role: Role
}

export interface OptionReq {
  availableFrom: number
  availableTo: number
  blocking: BlockingReq[]
  bons: BonReq[]
  cancellationPolicy: PackageCancellationPolicyReq[]
  cost: number
  costPerNCapitas: PricePerNCapitasReq
  gallery: InlineGalleryReq | null
  id: number | null
  isConstrainedByOpeningHours: boolean
  maxCapacity: number
  minCapacity: number
  optionalFields: FormField[]
  priority: number
  requiredFields: FormField[]
  requiredRoles: RequiredRoleReq[]
  translations: { [key in Language]: TranslationReq }
}

export interface PackageUpsaleIdsWithPriorityReq {
  priority: number
  upsaleId: number
}

export interface PackageReq {
  addToReservation: boolean
  containsReservation: boolean
  gallery: InlineGalleryReq | null
  id: number
  isActive: boolean
  isForWeb: boolean
  options: OptionReq[]
  priority: number
  requiredUpsaleIds: number[][]
  tagIds: PriorityReq[]
  translations: { [key in Language]: TranslationReq }
  type: PackageType[]
  upsalePackageIds: PackageUpsaleIdsWithPriorityReq[]
}

export interface InlineGalleryRes {
  additionalInfo: string
  content: string
  keyword: string | null
  media: MediumRes[]
  metaData: string
  title: string
}

export interface CancellationPolicyRes {
  interval: number
  percentage: number
}

export interface PackageOptionRes {
  additionalInfo: string
  availableFrom: number
  availableTo: number
  bons: Bon[]
  cancellationPolicy: CancellationPolicyRes[]
  content: string
  gallery: InlineGalleryRes | null
  id: number
  keyword: string | null
  maxCapacity: number
  metaData: string
  minCapacity: number
  optionalFields: FormField[]
  packageId: number
  price: PackageOptionPriceRes
  priority: number
  requiredFields: FormField[]
  title: string
}

export interface PackageRes {
  addToReservation: boolean
  additionalInfo: string
  containsReservation: boolean
  content: string
  gallery: InlineGalleryRes | null
  id: number
  keyword: string | null
  metaData: string
  options: PackageOptionRes[]
  priority: number
  requiredUpsaleIds: number[][]
  tagIds: PriorityRes[]
  title: string
  type: PackageType[]
  upsalePackageIds: PackageUpsaleIdsWithPriorityRes[]
}

export interface PageReq {
  id: number
  translations: { [key in Language]: TranslationReq }
}

export interface PageRes {
  additionalInfo: string
  content: string
  id: number
  keyword: string | null
  metaData: string
  title: string
}

export interface PageResAdmin {
  id: number
  translations: { [key in Language]: TranslationResAdmin }
}

export interface PackageUpsaleRes {
  additionalInfo: string
  availableFrom: number
  availableTo: number
  bons: Bon[]
  containsReservation: boolean
  content: string
  gallery: InlineGalleryRes | null
  id: number
  keyword: string | null
  language: Language
  maxCapacity: number
  metaData: string
  minCapacity: number
  optionalFields: FormField[]
  price: PackageOptionPriceRes
  priority: number
  requiredFields: FormField[]
  tagIds: PriorityRes[]
  title: string
  type: PackageType[]
}

export interface PriceBonRes {
  bonSum: ComputedBon[]
  option: ComputedBon[]
  reservation: ComputedBon[]
  upsales: { [key in number]: ComputedBon[] }
}

export interface PriceContentRes {
  deposit: number
  price: number
}

export interface PriceResInner {
  option: { [key in Currency]: PriceContentRes }
  reservation: { [key in Currency]: PriceContentRes }
  upsales: { [key in number]: { [curr in Currency]: PriceContentRes } }
}

export interface PriceRes {
  bons: PriceBonRes
  price: PriceResInner
}

export interface ProductReq {
  isActive: boolean
  price: number
  priority: number
  tagIds: PriorityReq[]
  translations: { [key in Language]: TranslationReq }
}

export interface ProductRes {
  additionalInfo: string
  content: string
  id: number
  keyword: string | null
  metaData: string
  price: { [key in Currency]: number }
  priority: number
  tagIds: PriorityRes[]
  title: string
}

export interface ProductResAdmin {
  id: number
  isActive: boolean
  price: number
  priority: number
  tagIds: PriorityRes[]
  translations: { [key in Language]: TranslationResAdmin }
}

export interface RecoverReq {
  email: string
  password: string
  token: string
}

export interface RequestRecoverReq {
  email: string
}

export interface ResourceReq {
  id: number
  maxCapacity: number
  title: string
}

export interface ResourceResAdmin {
  id: number
  maxCapacity: number
  title: string
}

export interface SectionReq {
  bons: BonReq[]
  id: number
  price: number
  translations: { [key in Language]: TranslationReq }
}

export interface CancellationFeeResAdmin {
  amount: number
  currency: Currency
}

export interface SectionRes {
  additionalInfo: string
  bons: Bon[]
  content: string
  id: number
  keyword: string | null
  metaData: string
  price: { [key in Currency]: number }
  title: string
}

export interface PackageAvailabilityReq {
  packageOptionIds: number[]
  packageUpsaleIds: number[]
}

export interface SessionResAdmin {
  dtEnd: number | null
  dtStart: number
  id: number
  orders: OrderResAdmin[]
  userId: number
}

export interface SetPasswordReq {
  password: string
  token: string
}

export interface StatusRes {
  message: string
}

export interface TagReq {
  category: string
  entityIds: PriorityReq[]
  id: number
  isActive: boolean
  priority: number
  translations: { [key in Language]: TranslationReq }
}

export interface TagResAdmin {
  category: string
  entityIds: number[]
  id: number
  isActive: boolean
  priority: number
  translations: { [key in Language]: TranslationResAdmin }
}

export interface TagRes {
  additionalInfo: string
  category: string
  content: string
  entityIds: PriorityRes[]
  id: number
  keyword: string | null
  metaData: string
  priority: number
  title: string
}

export interface PackageUpsaleReq {
  availableFrom: number
  availableTo: number
  blocking: BlockingReq[]
  bons: BonReq[]
  containsReservation: boolean
  cost: number
  costPerNCapitas: PricePerNCapitasReq
  gallery: InlineGalleryReq | null
  id: number
  isActive: boolean
  isConstrainedByOpeningHours: boolean
  isForWeb: boolean
  maxCapacity: number
  minCapacity: number
  optionalFields: FormField[]
  priority: number
  requiredFields: FormField[]
  requiredRoles: RequiredRoleReq[]
  tagIds: PriorityReq[]
  translations: { [key in Language]: TranslationReq }
  type: PackageType[]
}

export interface UserAvailabilityReq {
  availableFrom: number
  availableTo: number
  id: number
  userId: number
}

export interface UserAvailabilityResAdmin {
  availableFrom: number
  availableTo: number
  id: number
  userId: number
}

export interface UserReq {
  accountNumber: string
  address: string
  agencyId: number | null
  crn: string
  email: string
  fullName: string
  id: number
  nickname: string
  note: string
  phone: string
  roles: Role[]
  sendInvoice: boolean
  vatId: string
}

export interface AvailableTimesReq {
  dtStart: number
  packageOptionIds: number[]
  upsalePackageIds: number[]
}

export interface AvailableTimesRes {
  times: number[]
}

export interface CallBackReq {
  email: string
  phone: string
}

export interface SeatingRes {
  id: number
  maxCapacity: number
  minCapacity: number
  sectionId: number
  title: string
}

export interface SeatingReq {
  id: number
  maxCapacity: number
  minCapacity: number
  sectionId: number
  title: string
}

export interface MinimalUpsaleReqAdmin {
  dtStart: number
  numberOfPersons: number
  packageUpsaleId: number
  quantity: number
}

export interface OrderConflictReq {
  dtStart: number
  mainPackageOptionId: number | null
  seatingIds: number[]
  upsales: MinimalUpsaleReqAdmin[]
}

export interface SiteRes {
  additionalInfo: string
  content: string
  gallery: InlineGalleryRes | null
  id: number
  keyword: string | null
  metaData: string
  title: string
}

export interface NotificationResAdmin {
  dtEnd: number
  dtStart: number
  iconUrl: string
  id: number
  isActive: boolean
  translations: { [key in Language]: TranslationResAdmin }
}

export interface SiteResAdmin {
  gallery: InlineGalleryResAdmin | null
  id: number
  notification: NotificationResAdmin | null
  translations: { [key in Language]: TranslationResAdmin }
}

export interface NotificationReqAdmin {
  dtEnd: number
  dtStart: number
  iconUrl: string
  isActive: boolean
  translations: { [key in Language]: TranslationReq }
}

export interface SiteReqAdmin {
  gallery: InlineGalleryReq | null
  id: number
  notification: NotificationReqAdmin | null
  translations: { [key in Language]: TranslationReq }
}

export interface NotificationRes {
  additionalInfo: string
  content: string
  iconUrl: string
  id: number
  keyword: string | null
  metaData: string
  title: string
}

export interface ReservationCapacityResInner {
  freeCapacity: number
  maxCapacity: number
  numberOfPersons: number
}

export interface ReservationCapacityRes {
  value: { [key in number]: ReservationCapacityResInner }
}

export interface ShowScheduleReq {
  dtEnd: number
  dtStart: number
}

export interface ShowReq {
  gallery: InlineGalleryReq
  id: number
  schedules: ShowScheduleReq[]
  translations: { [key in Language]: TranslationReq }
}

export interface ShowScheduleRes {
  dtEnd: number
  dtStart: number
}

export interface TranslationRes {
  additionalInfo: string
  content: string
  keyword: string | null
  metaData: string
  title: string
}

export interface ShowRes {
  gallery: InlineGalleryRes
  id: number
  schedules: ShowScheduleRes[]
  translation: TranslationRes
}

export interface ShowScheduleResAdmin {
  dtEnd: number
  dtStart: number
}

export interface ShowResAdmin {
  gallery: InlineGalleryResAdmin
  id: number
  schedules: ShowScheduleResAdmin[]
  translations: { [key in Language]: TranslationResAdmin }
}

export interface ConflictIgnoreReq {
  firstId: number
  secondId: number
}

export interface ConflictIgnoreResAdmin {
  firstId: number
  secondId: number
}

export interface IntervalRes {
  dtEnd: number
  dtStart: number
}

export interface OrderConflictResElement {
  conflictIntervals: IntervalRes[]
  entityId: number
  entityTitle: string
  isIgnored: boolean
  order1Title: string
  order2Title: string
  orderId1: number
  orderId2: number
}

export interface OrderConflictRes {
  employee: OrderConflictResElement[]
  resource: OrderConflictResElement[]
}