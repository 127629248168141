import React, { ChangeEvent, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Form from "../../components/Form/Form"
import GalleryUpload from "../../components/GalleryUpload/GalleryUpload"
import Input from "../../components/Input/Input"
import LanguageTab from "../../components/LanguageTab/LanguageTab"
import EntityList from "../../components/EntityList/EntityList"
import MultiSelect from "../../components/MultiSelect/MultiSelect"
import { selectActivityInputOptions } from "../../selectors/activitySelector"
import { fetchActivities } from "../../actions/activityActions"
import config from "../../config"
import Results from "../../components/Results/Results"
import {
  blankTranslations,
  languageList,
  simpleToFullTranslation,
} from "../../utils/typeUtils"
import { MediaType, MediumResAdmin } from "../../types/apiTypes"
import WebPageMessage, {
  WebPage,
} from "../../components/Messages/WebPageMessage"
import { Field } from "../../components/Messages/FieldMessage"
import { Entity } from "../../components/Messages/EntityMessage"
import {
  useTagDelete,
  useTagFetch,
  useTagSave,
  useTagState,
} from "../../hooks/tagHooks"
import {
  useGalleryFetch,
  useGallerySave,
  useGalleryState,
} from "../../hooks/galleryHooks"
import { usePageSharedState } from "../../hooks/stateHooks"
import { AppState } from "../../store"
import { arrayDiff } from "../../utils/arrayUtils"
import { Box, Grid } from "@material-ui/core"

export default function PageHomepage() {
  const dispatch = useDispatch()
  const [video, setVideo] = useState("")
  const [galleryId, setGalleryId] = useState<number | null>(null)
  const [videosSlider, setVideosSlider] =
    useState<MediumResAdmin[] | null>(null)
  const [videosSliderId, setVideosSliderId] = useState<number | null>(null)
  const state = usePageSharedState(config.pageIds.homepage)
  const galleryState = useGalleryState()
  const panoramaState = useGalleryState()
  const tagState = useTagState()
  const [fetchTags, tagFetchStatus, tagFetchError] = useTagFetch()
  const [saveTags, tagSaveStatus, tagSaveError] = useTagSave(
    "package",
    config.tagCategories.homepage
  )
  const [saveGallery, gallerySaveStatus, gallerySaveError] = useGallerySave([
    config.tagIds.gallery.homepage,
  ])
  const [fetchGallery, galleryFetchStatus, galleryFetchError] = useGalleryFetch(
    config.tagIds.gallery.homepage
  )
  const [deleteTag, tagDeleteStatus, tagDeleteError] = useTagDelete("package")
  const activityInputOptions = useSelector((appState: AppState) =>
    selectActivityInputOptions(appState, true, false)
  )
  const [remoteTags, setRemoteTags] = useState<number[]>([])

  useEffect(() => {
    dispatch(fetchActivities())
    state.fetchPage()
    fetchTags(
      "package",
      (tagResponse) => {
        tagState.fillWithData(
          tagResponse.map((tag) => tag.id.toString()),
          tagResponse.map((tag) => tag.translations),
          tagResponse.map((tag) => tag.entityIds.map((id) => id.toString()))
        )
        setRemoteTags(tagResponse.map((tag) => tag.id))
      },
      config.tagCategories.homepage
    )
    fetchGallery(
      [],
      ([{ media, id } = { media: [], id: null }, sliderGallery]) => {
        sliderGallery && setVideosSlider(sliderGallery.media)
        sliderGallery && setVideosSliderId(sliderGallery.id)
        const [videoMedium] = media.filter((i) => i.type === MediaType.VIDEO)
        const imageMedia = media.filter((i) => i.type === MediaType.IMAGE)
        const panoramaMedia = media.filter((i) => i.type === MediaType.PANORAMA)
        setGalleryId(id)
        videoMedium && setVideo(videoMedium.url)
        galleryState.fillData({ default: imageMedia })
        panoramaState.fillData({ default: panoramaMedia })
      }
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const handleSubmit = async () => {
    // save page
    await state.savePage()

    // save tags
    const tagIds = await saveTags(
      tagState.tags,
      tagState.entities,
      tagState.titles
    )

    if (tagIds) {
      tagState.handleTagSave(tagIds)
      await Promise.all(
        arrayDiff(remoteTags, tagIds).map((id) => deleteTag(id))
      )
      setRemoteTags(tagIds)
    }

    // save gallery
    await saveGallery(
      galleryId,
      [
        ...(video ? [{ id: "video", source: video }] : []),
        ...galleryState.getGallery(),
        ...panoramaState.getGallery(),
      ],
      [
        ...(video ? [blankTranslations] : []),
        ...galleryState
          .getGallery()
          .map((i) =>
            simpleToFullTranslation([galleryState.labels[i.id]], ["title"])
          ),
        ...panoramaState
          .getGallery()
          .map((i) =>
            simpleToFullTranslation([panoramaState.labels[i.id]], ["title"])
          ),
      ],
      [
        ...(video ? [MediaType.VIDEO] : []),
        ...galleryState.getGallery().map(() => MediaType.IMAGE),
        ...panoramaState.getGallery().map(() => MediaType.PANORAMA),
      ]
    )

    // save videos slider
    if (videosSlider) {
      await saveGallery(
        videosSliderId,
        videosSlider.map((item) => ({ id: "videoSlider", source: item.url })),
        videosSlider.map((item) => item.translations),
        videosSlider.map(() => MediaType.VIDEO)
      )
    }
  }

  const handleVideoChange = (e: ChangeEvent<HTMLInputElement>) => {
    setVideo(e.currentTarget.value)
  }

  const handleTagDelete = () => {
    tagState.handleTagDelete()
  }

  const handleSliderChangeUrl = (e: ChangeEvent<HTMLInputElement>) => {
    const indexToUpdate = parseInt(e.target.name, 10)
    const newData = videosSlider?.map((item, index) =>
      index === indexToUpdate ? { ...item, url: e.target.value } : item
    )
    newData && setVideosSlider(newData)
  }
  const handleSliderChangeTitle = (e: ChangeEvent<HTMLInputElement>) => {
    const indexToUpdate = parseInt(e.target.name, 10)
    const newData = videosSlider && [...videosSlider]
    if (newData) {
      newData[indexToUpdate].translations[state.language].title = e.target.value
      setVideosSlider(newData)
    }
  }

  const createKeyFromIndex = (index: number) => `${index}`

  return (
    <>
      <Results
        statuses={[state.saveStatus, state.fetchStatus]}
        errors={[state.saveError, state.fetchError]}
        actions={["save", "fetch"]}
        entity={Entity.PAGE}
      />
      <Results
        statuses={[tagFetchStatus, tagSaveStatus, tagDeleteStatus]}
        errors={[tagFetchError, tagSaveError, tagDeleteError]}
        actions={["fetch", "save", "delete"]}
        entity={Entity.TAG}
      />
      <Results
        statuses={[galleryFetchStatus, gallerySaveStatus]}
        errors={[galleryFetchError, gallerySaveError]}
        actions={["fetch", "save"]}
        entity={Entity.GALLERY}
      />
      <Form
        statuses={[
          galleryFetchStatus,
          gallerySaveStatus,
          tagFetchStatus,
          tagSaveStatus,
          tagDeleteStatus,
          state.saveStatus,
          state.fetchStatus,
        ]}
        title={<WebPageMessage id={WebPage.HOMEPAGE} />}
        onSubmit={handleSubmit}
        toolbar={
          <LanguageTab
            language={state.language}
            onChange={state.setLanguage}
            notifications={languageList.filter((lang) => {
              const { content, title, metaData } = state.translations[lang]
              return !content || !title || !metaData
            })}
          />
        }
        useGenericCta
      >
        <Input
          name={`title`}
          messageId={Field.TITLE}
          onChange={state.handleTitle}
          value={state.title}
          showRequiredWarning={state.isSubmitted}
        />
        <Input
          name={`meta`}
          messageId={Field.META}
          onChange={state.handleMeta}
          value={state.meta}
          showRequiredWarning={state.isSubmitted}
        />
        <Input
          name={`keywords`}
          messageId={Field.KEYWORDS}
          onChange={state.handleKeywords}
          value={state.keywords}
          showRequiredWarning={state.isSubmitted}
        />
        <Input
          name={`video`}
          messageId={Field.VIDEO}
          onChange={handleVideoChange}
          value={video}
          showRequiredWarning={state.isSubmitted}
        />
        {videosSlider?.map((slide, index) => (
          <Box key={createKeyFromIndex(index)}>
            Slide {index + 1}
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Input
                  name={`${index}`}
                  messageId={Field.TITLE}
                  onChange={handleSliderChangeTitle}
                  value={
                    videosSlider?.[index].translations[state.language].title ||
                    ""
                  }
                  showRequiredWarning={state.isSubmitted}
                />
              </Grid>
              <Grid item xs={6}>
                <Input
                  name={`${index}`}
                  messageId={Field.VIDEO}
                  onChange={handleSliderChangeUrl}
                  value={videosSlider?.[index].url || ""}
                  showRequiredWarning={state.isSubmitted}
                />
              </Grid>
            </Grid>
          </Box>
        ))}
        <EntityList
          entityPairs={tagState.tags.map((tag) => [
            tag,
            tagState.titles[tag]?.[state.language] || ``,
          ])}
          onEntityChange={tagState.handleActiveTagChange}
          onCreate={tagState.handleTagCreate}
          onDelete={handleTagDelete}
          onSort={tagState.handleTagSort}
          activeEntity={tagState.activeTag}
        >
          <Input
            name={`tagTitle`}
            messageId={Field.TITLE}
            onChange={(e) =>
              tagState.handleTagTitleChange(
                e.currentTarget.value,
                state.language
              )
            }
            value={tagState.titles[tagState.activeTag]?.[state.language]}
          />
          <MultiSelect
            onChange={tagState.handleEntitiesChange}
            values={tagState.entities[tagState.activeTag] || []}
            options={activityInputOptions}
            messageId={Field.ACTIVITIES}
            name={`tagEntities`}
          />
        </EntityList>
        <GalleryUpload
          name={`gallery`}
          language={state.language}
          gallery={galleryState.getGallery()}
          labels={galleryState.labels}
          onUpload={galleryState.handleUpload}
          onSort={galleryState.handleSort}
          onLabelChange={galleryState.handleLabelChange}
          onDelete={galleryState.handleDelete}
          messageId={Field.GALLERY}
        />
        <GalleryUpload
          name={`panorama`}
          language={state.language}
          gallery={panoramaState.getGallery()}
          labels={panoramaState.labels}
          onUpload={panoramaState.handleUpload}
          onSort={panoramaState.handleSort}
          onLabelChange={panoramaState.handleLabelChange}
          onDelete={panoramaState.handleDelete}
          messageId={Field.PANORAMA}
        />
      </Form>
    </>
  )
}
