import Typography from "@material-ui/core/Typography"
import React, { ChangeEvent, memo, useEffect, useState, useMemo } from "react"
import { Map } from "immutable"
import Grid from "@material-ui/core/Grid"
import { Navigate } from "react-router-dom"
import { useSelector } from "react-redux"
import FieldSet from "../../components/FieldSet/FieldSet"
import Input from "../../components/Input/Input"
import Select from "../../components/Select/Select"
import Form from "../../components/Form/Form"
import { IFormData, LiteEvent, OptionPrices } from "../../types/globalTypes"
import {
  GuideReq,
  InvoicePublisher,
  PackageResAdmin,
  PackageUpsaleResAdmin,
  PaymentMethod
} from "../../types/apiTypes"
import ComboBox from "../../components/ComboBox/ComboBox"
import {
  selectActivityInputOptions,
  selectAgencyActivities,
  selectOptionIdPackageIdMap,
} from "../../selectors/activitySelector"
import MultipleFiledSet from "../../components/MultipleFiledSet/MultipleFiledSet"
import {
  createBlankOptionPrice,
  getVatOptionByRate,
  getVatRateByOption,
  idParamToPost,
  invoicePublishersInputOptions,
  paymentMethodsInputOptions,
  vatRateInputOptions,
} from "../../utils/typeUtils"
import Results from "../../components/Results/Results"
import {
  useAgencyFetch,
  useAgencyGuideState,
  useAgencyPriceSave,
  useAgencySave,
} from "../../hooks/agencyHooks"
import {
  OptionPriceMap,
  usePricesFetch,
  usePriceState,
} from "../../hooks/priceHooks"
import RouteMessage from "../../components/Messages/RouteMessage"
import { Field } from "../../components/Messages/FieldMessage"
import { Entity } from "../../components/Messages/EntityMessage"
import { useUpsaleFetch } from "../../hooks/upsaleHooks"
import { useActivityFetch } from "../../hooks/activityHooks"
import MultiSelect from "../../components/MultiSelect/MultiSelect"
import {
  selectAgencyUpsales,
  selectUpsaleInputOptions,
  selectUpsaleRecords,
} from "../../selectors/upsaleSelector"
import EntityList from "../../components/EntityList/EntityList"
import config from "../../config"
import { ErrorType } from "../../components/Messages/ErrorMessage"
import AgencyDetailUserList from "./AgencyDetailUserList"
import { AppState } from "../../store"
import AgencyDetailPriceButton from "./AgencyDetailPriceButton"
import { UpsaleMap } from "../../models/upsaleModel"
import { useIsCompetent, useLanguage } from "../../hooks/interfaceHooks"
import InlineFieldset from "../../components/InlineFieldset/InlineFieldset"
import { useIdParam } from "../../hooks/routerHooks"
import { decodeInputName, encodeInputName } from "../../utils/stringUtils"

const createOptionUpsaleMap = (upsales: UpsaleMap): Map<number, number> =>
  Map(
    upsales
      .valueSeq()
      .toArray()
      .map((upsale) => [upsale.id, upsale.id])
  )

const encodeGuideInputName = (key: keyof GuideReq) => encodeInputName(key, "guide")

export default memo(() => {
  const id = useIdParam()
  const canEditPrices = useIsCompetent("packagePricesAgency", "W")
  const canEditOrderFields = useIsCompetent("orderAdmin", "W")
  const activityPriceState = usePriceState()
  const upsalePriceState = usePriceState()
  const lang = useLanguage()
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([])
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
  const [invoiceDay, setInvoiceDay] = useState<number>(1)
  const [agencyMap, fetchStatus, fetchError] = useAgencyFetch(id)
  const [saveAgency, saveStatus, saveError] = useAgencySave()
  const [redirectId, setRedirectId] = useState<number | null>(null)
  const guideState = useAgencyGuideState()
  const [
    saveActivityPrices,
    saveActivityPricesStatus,
    saveActivityPricesError,
  ] = useAgencyPriceSave()
  const [saveUpsalePrices, saveUpsalePricesStatus, saveUpsalePricesError] =
    useAgencyPriceSave(true)
  const [, activityFetchStatus, activityFetchError] = useActivityFetch()
  const activities = useSelector(selectAgencyActivities)
  const [, upsaleFetchStatus, upsaleFetchError] = useUpsaleFetch()
  const upsales = useSelector(selectAgencyUpsales)
  const activityOptions = useSelector((state: AppState) =>
    selectActivityInputOptions(state, false, true)
  )
  const upsaleOptions = useSelector((state: AppState) =>
    selectUpsaleInputOptions(state, false, true)
  )
  const optionPackageMap = useSelector(selectOptionIdPackageIdMap)
  const upsaleRecords = useSelector(selectUpsaleRecords)
  const agency = agencyMap.get(id)
  const [
    fetchActivityPrices,
    fetchActivityPricesStatus,
    fetchActivityPricesError,
  ] = usePricesFetch(id ? parseFloat(id) : undefined)
  const [fetchUpsalePrices, fetchUpsalePricesStatus, fetchUpsalePricesError] =
    usePricesFetch(id ? parseFloat(id) : undefined, true)

  const activitiesSorted = useMemo(
    () =>
      activityPriceState.priceOrder
        .map((activity) => activities.get(activity))
        .filter((item): item is PackageResAdmin => !!item)
        .map((activity) => ({
          ...activity,
          options: activity.options.sort((a, b) =>
            a.translations[lang].title.localeCompare(b.translations[lang].title)
          ),
        }))
        .sort((a, b) =>
          a.translations[lang].title.localeCompare(b.translations[lang].title)
        ),
    [activityPriceState.priceOrder, lang, activities]
  )

  const upsalesSorted = useMemo(
    () =>
      upsalePriceState.priceOrder
        .map((upsale) => upsaleRecords.get(upsale))
        .filter((item): item is PackageUpsaleResAdmin => !!item)
        .sort((a, b) =>
          a.translations[lang].title.localeCompare(b.translations[lang].title)
        ),
    [upsalePriceState.priceOrder, lang, upsaleRecords]
  )

  useEffect(() => {
    if (optionPackageMap.size > 0)
      fetchActivityPrices((priceMap) => {
        activityPriceState.fillPrices(priceMap, optionPackageMap)
      })
    // eslint-disable-next-line
  }, [optionPackageMap])

  useEffect(() => {
    if (upsales.size > 1)
      fetchUpsalePrices((priceMap) => {
        upsalePriceState.fillPrices(priceMap, createOptionUpsaleMap(upsales))
      })
    // eslint-disable-next-line
  }, [upsales])

  useEffect(() => {
    setPaymentMethods(agency?.paymentMethods || [])
    setInvoiceDay(agency?.invoiceDates?.[0] || 1)
    guideState.fillWithData(agency?.guides || [])
    // eslint-disable-next-line
  }, [agency])

  const handleSubmit = async (values: IFormData) => {
    setIsSubmitted(true)
    if (paymentMethods.length === 0) return false
    const response = await saveAgency(
      {
        accountNumber: values?.accountNumber,
        address: values?.address,
        crn: values?.crn,
        email: values?.email,
        guides: guideState.guideIds.map(
          (guideId) => guideState.guides[guideId]
        ),
        invoiceDates: [invoiceDay],
        invoicePublisher: (values?.invoicePublisher ||
          agency?.invoicePublisher) as InvoicePublisher,
        vatRate: getVatRateByOption(values?.vatRate),
        note: values?.note || agency?.note || "",
        paymentMethods,
        phone: values?.phone,
        title: values?.title,
        vatId: values?.vatId,
        id: idParamToPost(id),
      },
      id ? parseFloat(id) : undefined
    )
    if (response && canEditPrices) {
      saveActivityPrices(activityPriceState.prices, response.id)
      saveUpsalePrices(upsalePriceState.prices, response.id)
    }
    setRedirectId(response?.id || null)
    return true
  }

  const handleActivityPriceChange = ({
    currentTarget: { name, value },
  }: ChangeEvent<HTMLInputElement>) => {
    const [activityId, optionId, key] = decodeInputName(name)
    activityPriceState.changePrice(activityId, optionId, key, value)
  }

  const handleChangeActivity = ({
    currentTarget: { value: newActivityId, name },
  }: LiteEvent) => {
    const { options = [] } = activities.get(newActivityId) || {}
    const [oldActivityId] = decodeInputName(name)
    activityPriceState.addPriceGroup(
      newActivityId,
      options.map((option) => option.id.toString())
    )
    activityPriceState.removePriceGroup(oldActivityId)
  }

  const handleActivityPriceCreate = (): void => {
    const freeActivities = activities.filter(
      (value, key) => !activityPriceState.prices[key]
    )
    if (freeActivities.size === 0) return
    const [[nextFreeId, nextFreeActivity]] = freeActivities
    if (nextFreeId)
      activityPriceState.addPriceGroup(
        nextFreeId,
        nextFreeActivity.options.map((option) => option.id.toString())
      )
  }

  const handleActivityPriceFill = (): void => {
    const priceMap: OptionPriceMap = Map(
      activities
        .valueSeq()
        .toArray()
        .flatMap(({ options }) => options.map((option) => option.id))
        .map((optionId) => [
          optionId.toString(),
          createBlankOptionPrice(optionId),
        ])
    )
    activityPriceState.fillPrices(priceMap, optionPackageMap)
  }

  const handleUpsalePriceChange = ({
    currentTarget: { name, value },
  }: ChangeEvent<HTMLInputElement>) => {
    const [upsaleId, optionId, key] = decodeInputName(name)
    upsalePriceState.changePrice(upsaleId, optionId, key, value)
  }

  const handleChangeUpsale = ({
    currentTarget: { value: newUpsaleId, name },
  }: LiteEvent) => {
    const [oldUpsaleId] = decodeInputName(name)
    upsalePriceState.addPriceGroup(newUpsaleId, [newUpsaleId])
    upsalePriceState.removePriceGroup(oldUpsaleId)
  }

  const handleUpsalePriceCreate = (): void => {
    const freeUpsales = upsales.filter(
      (value, key) => !upsalePriceState.prices[key]
    )
    if (freeUpsales.size === 0) return
    const [[nextFreeId]] = freeUpsales
    if (nextFreeId) upsalePriceState.addPriceGroup(nextFreeId, [nextFreeId])
  }

  const handleUpsalePriceFill = (): void => {
    const priceMap: OptionPriceMap = Map(
      upsales
        .valueSeq()
        .toArray()
        .map(({ id: upsaleId }) => [
          upsaleId.toString(),
          createBlankOptionPrice(upsaleId),
        ])
    )
    upsalePriceState.fillPrices(priceMap, createOptionUpsaleMap(upsales))
  }

  const handleInvoiceDayChange = ({
    currentTarget: { value },
  }: LiteEvent): void => {
    setInvoiceDay(parseFloat(value))
  }

  if (redirectId !== null && !id)
    return (
      <Navigate to={config.routes.agencyDetail.path(redirectId.toString())} />
    )

  return (
    <>
      <Results
        statuses={[fetchStatus, saveStatus]}
        errors={[fetchError, saveError]}
        actions={["fetch", "save"]}
        entity={Entity.AGENCY}
      />
      <Results
        statuses={[activityFetchStatus]}
        errors={[activityFetchError]}
        actions={["fetch"]}
        entity={Entity.ACTIVITY}
      />
      <Results
        statuses={[upsaleFetchStatus]}
        errors={[upsaleFetchError]}
        actions={["fetch"]}
        entity={Entity.UPSALE}
      />
      <Results
        statuses={[
          fetchActivityPricesStatus,
          saveActivityPricesStatus,
          fetchUpsalePricesStatus,
          saveUpsalePricesStatus,
        ]}
        errors={[
          fetchActivityPricesError,
          saveActivityPricesError,
          fetchUpsalePricesError,
          saveUpsalePricesError,
        ]}
        actions={["fetch", "save", "fetch", "save"]}
        entity={Entity.PRICE}
      />
      <Form
        title={<RouteMessage id={"agencyDetail"} />}
        onSubmit={handleSubmit}
        useGenericCta
        statuses={[
          fetchStatus,
          saveStatus,
          activityFetchStatus,
          fetchActivityPricesStatus,
        ]}
      >
        <FieldSet messageId={Field.CONTACT}>
          <Input
            name={"title"}
            messageId={Field.TITLE}
            defaultValue={agency?.title}
            autoFocus
          />
          <Input
            name={"phone"}
            messageId={Field.PHONE}
            defaultValue={agency?.phone}
          />
          <Input
            name={"email"}
            messageId={Field.EMAIL}
            defaultValue={agency?.email}
            type={"email"}
          />
        </FieldSet>
        <FieldSet messageId={Field.CONTACTS}>
          <EntityList
            entityPairs={guideState.guideIds
              .map((guideId): [string, string] => [
                guideId,
                guideState.guides[guideId].name,
              ])
              .sort(([_, a], [__, b]) => a.localeCompare(b))}
            onEntityChange={guideState.setActiveGuide}
            activeEntity={guideState.activeGuideId}
            onCreate={guideState.createGuide}
            onDelete={guideState.deleteGuide}
          >
            <Input
              name={encodeGuideInputName('name')}
              messageId={Field.NAME}
              value={guideState.activeGuide.name}
              onChange={guideState.changeGuide}
            />
            <Input
              name={encodeGuideInputName("phone")}
              messageId={Field.PHONE}
              value={guideState.activeGuide.phone}
              type={"phone"}
              onChange={guideState.changeGuide}
            />
            <Input
              name={encodeGuideInputName("email")}
              messageId={Field.EMAIL}
              value={guideState.activeGuide.email}
              type={"email"}
              onChange={guideState.changeGuide}
            />
          </EntityList>
        </FieldSet>
        {id && <AgencyDetailUserList agencyId={parseFloat(id)} />}
        <FieldSet messageId={Field.BILLING_INFO}>
          <Input
            name={"address"}
            messageId={Field.ADDRESS}
            defaultValue={agency?.address}
          />
          <Input
            name={"accountNumber"}
            messageId={Field.ACCOUNT_NUMBER}
            defaultValue={agency?.accountNumber}
          />
          <Input
            name={"crn"}
            messageId={Field.CRN}
            defaultValue={agency?.crn}
          />
          <Select
            name={"vatRate"}
            key={agency ? `vatRate-refresh` : `vatRate`}
            options={vatRateInputOptions}
            messageId={Field.VAT_RATE}
            defaultValue={getVatOptionByRate(agency?.vatRate)}
          />
          <Input
            name={"vatId"}
            messageId={Field.VAT_ID}
            defaultValue={agency?.vatId}
          />
          {canEditOrderFields && (
            <Select
              name={"invoicePublisher"}
              key={agency ? `invoicePublisher-refresh` : `invoicePublisher`}
              messageId={Field.INVOICE_PUBLISHER}
              options={invoicePublishersInputOptions}
              defaultValue={
                agency?.invoicePublisher || InvoicePublisher.ADDLAND
              }
            />
          )}
          {canEditOrderFields && (
            <MultiSelect
              name={"paymentMethod"}
              messageId={Field.PAYMENT_METHOD}
              options={paymentMethodsInputOptions}
              values={paymentMethods}
              onChange={(name, values) =>
                setPaymentMethods(values as PaymentMethod[])
              }
              inputError={
                isSubmitted && paymentMethods.length === 0
                  ? {
                      type: ErrorType.REQUIRED,
                    }
                  : undefined
              }
            />
          )}
          {canEditOrderFields && (
            <ComboBox
              messageId={Field.INVOICE_DAY}
              name={"invoiceDay"}
              options={Array.from({ length: 27 }, (v, k) => ({
                value: `${k + 1}`,
                content: `${k + 1}`,
              }))}
              onChange={handleInvoiceDayChange}
              value={invoiceDay.toString()}
              dense={false}
            />
          )}
          {canEditOrderFields && (
            <Input
              messageId={Field.NOTE}
              name={"note"}
              defaultValue={agency?.note}
            />
          )}
        </FieldSet>

        <MultipleFiledSet
          messageId={Field.ACTIVITIES}
          entity={Entity.ACTIVITY}
          onCreate={canEditPrices ? handleActivityPriceCreate : undefined}
          onRemove={
            canEditPrices ? activityPriceState.removePriceGroup : undefined
          }
          altCta={
            canEditPrices ? (
              <AgencyDetailPriceButton onClick={handleActivityPriceFill} />
            ) : undefined
          }
        >
          {activitiesSorted.map(({ id: activityId, options }) => (
            <div key={activityId} style={{ width: "100%" }}>
              <ComboBox
                messageId={Field.ACTIVITY}
                name={encodeInputName(activityId, "activity")}
                options={activityOptions}
                getOptionDisabled={({ value }) =>
                  !!activityPriceState.prices[value]
                }
                value={activityId.toString()}
                onChange={handleChangeActivity}
                disabled={!canEditPrices}
                dense={false}
              />
              {options.map(({ id: optionId, translations }) => {
                const encodeName = (key: keyof OptionPrices) =>
                  encodeInputName(activityId, optionId, key)
                return (
                  <Grid
                    container
                    spacing={2}
                    alignItems={"center"}
                    key={`priceGroup-${activityId}-${optionId}`}
                  >
                    <Grid item xs={3}>
                      <Typography variant={"caption"}>
                        {translations.CS.title}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Input
                        type="number"
                        messageId={Field.PRICE_ACTIVITY}
                        name={encodeName("priceUnit")}
                        value={
                          activityPriceState.prices[activityId]?.[optionId]
                            ?.priceUnit
                        }
                        onChange={handleActivityPriceChange}
                        showRequiredWarning={isSubmitted}
                        disabled={!canEditPrices}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Input
                        type="number"
                        messageId={Field.PRICE_PERSON}
                        name={encodeName("pricePerPerson")}
                        value={
                          activityPriceState.prices[activityId]?.[optionId]
                            ?.pricePerPerson
                        }
                        onChange={handleActivityPriceChange}
                        showRequiredWarning={isSubmitted}
                        disabled={!canEditPrices}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Input
                        type="number"
                        messageId={Field.FEE_OVER}
                        name={encodeName("fee")}
                        value={
                          activityPriceState.prices[activityId]?.[optionId]?.fee
                        }
                        onChange={handleActivityPriceChange}
                        showRequiredWarning={isSubmitted}
                        disabled={!canEditPrices}
                      />
                    </Grid>
                  </Grid>
                )
              })}
            </div>
          ))}
        </MultipleFiledSet>

        <MultipleFiledSet
          messageId={Field.UPSALES}
          entity={Entity.UPSALE}
          onCreate={canEditPrices ? handleUpsalePriceCreate : undefined}
          onRemove={
            canEditPrices ? upsalePriceState.removePriceGroup : undefined
          }
          altCta={
            canEditPrices ? (
              <AgencyDetailPriceButton onClick={handleUpsalePriceFill} />
            ) : undefined
          }
        >
          {upsalesSorted.map(({ id: upsaleId }) => {
            const encodeName = (key: keyof OptionPrices) =>
              encodeInputName(upsaleId, upsaleId, key)
            return (
              <InlineFieldset key={upsaleId}>
                <ComboBox
                  messageId={Field.UPSALES}
                  name={encodeInputName(upsaleId, 'upsale')}
                  options={upsaleOptions}
                  getOptionDisabled={({ value }) =>
                    !!upsalePriceState.prices[value]
                  }
                  value={upsaleId.toString()}
                  onChange={handleChangeUpsale}
                  disabled={!canEditPrices}
                  dense={false}
                />
                <Input
                  type="number"
                  messageId={Field.PRICE}
                  name={encodeName("priceUnit")}
                  value={upsalePriceState.prices[upsaleId][upsaleId].priceUnit}
                  onChange={handleUpsalePriceChange}
                  showRequiredWarning={isSubmitted}
                  disabled={!canEditPrices}
                />
                <Input
                  type="number"
                  messageId={Field.COST_PER_PERSON}
                  name={encodeName("pricePerPerson")}
                  value={
                    upsalePriceState.prices[upsaleId][upsaleId].pricePerPerson
                  }
                  onChange={handleUpsalePriceChange}
                  showRequiredWarning={isSubmitted}
                  disabled={!canEditPrices}
                />
                <Input
                  type="number"
                  messageId={Field.FEE_OVER}
                  name={encodeName("fee")}
                  value={upsalePriceState.prices[upsaleId][upsaleId].fee}
                  onChange={handleUpsalePriceChange}
                  showRequiredWarning={isSubmitted}
                  disabled={!canEditPrices}
                />
              </InlineFieldset>
            )
          })}
        </MultipleFiledSet>
      </Form>
    </>
  )
})
