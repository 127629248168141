import React from "react"
import { FormattedMessage } from "react-intl"
import useStyles from "./VoucherSummaryStyles"
import { Voucher } from "../../types/globalTypes"
import { getReservationContents } from "../../utils/apiUtils"
import { useLanguage } from "../../hooks/interfaceHooks"
import Price from "../Price/Price"
import BonSummary from "../BonSummary/BonSummary"
import { formatDateTime } from "../../utils/timeUtils"

interface IVoucherSummary {
  voucher: Voucher
}

export default function VoucherSummary({ voucher }: IVoucherSummary) {
  const classes = useStyles()
  const language = useLanguage()
  const reservationContents = getReservationContents(
    voucher.reservation?.reservation
  )
  return (
    <table className={classes.root}>
      <tbody>
        <tr>
          <th>
            <FormattedMessage id="customer" defaultMessage={"Customer"} />
          </th>
          <td>{voucher.name}</td>
        </tr>
        {"packageOrder" in voucher && (
          <tr>
            <th>
              <FormattedMessage
                id="orderContent"
                defaultMessage={"Order content"}
              />
            </th>
            <td>
              <ul className={classes.content}>
                <li>
                  {voucher.packageOrder?.option.translations[language].title}
                </li>
                <BonSummary summary={voucher.bonsComputed} element={"li"} />
              </ul>
            </td>
          </tr>
        )}
        {reservationContents && (
          <tr>
            <th>
              <FormattedMessage
                id="tableReservation"
                defaultMessage={"Table reservation"}
              />
            </th>
            <td>{reservationContents}</td>
          </tr>
        )}
        <tr>
          <th>
            <FormattedMessage id="orderDate" defaultMessage={"Order Date"} />
          </th>
          <td>
            {formatDateTime(
              voucher.packageOrder?.dtStart || voucher.reservation?.dtStart
            )}
          </td>
        </tr>
        {voucher.voucherUsedAt && (
          <tr>
            <th>
              <FormattedMessage
                id="voucherUsedAt"
                defaultMessage={"Use Date"}
              />
            </th>
            <td>{formatDateTime(voucher.voucherUsedAt)}</td>
          </tr>
        )}
        <tr>
          <th>
            <FormattedMessage id="orderPrice" defaultMessage={"Order price"} />
          </th>
          <td>
            <Price price={voucher.price} />
          </td>
        </tr>
        {"packageOrder" in voucher && (
          <tr>
            <th>
              <FormattedMessage id="surcharge" defaultMessage={"Surcharge"} />
            </th>
            <td className={classes.surcharge}>
              <Price
                price={voucher.isPaidRest ? 0 : voucher.price - voucher.deposit}
              />
            </td>
          </tr>
        )}
      </tbody>
    </table>
  )
}
