import performRequest, { getIdParam, getIdsParam } from "../utils/apiUtils"
import { HttpMethods } from "../types/globalTypes"
import {
  IdRes,
  PackageOptionPriceReq,
  PackageReq,
  PackageResAdmin,
  ResourceResAdmin,
} from "../types/apiTypes"

export async function requestGetActivities(
  basicAuth: string,
  ids: number[] = []
): Promise<PackageResAdmin[]> {
  return performRequest(
    {
      endpoint: `package${getIdsParam(ids)}`,
      auth: basicAuth,
      method: HttpMethods.GET,
    },
    true
  )
}

export async function requestDeleteActivity(
  basicAuth: string,
  id: number
): Promise<number> {
  return performRequest(
    {
      endpoint: `package${getIdParam(id)}`,
      auth: basicAuth,
      method: HttpMethods.DELETE,
    },
    true
  )
}

export async function requestSaveActivity(
  basicAuth: string,
  body: PackageReq,
  id?: number
): Promise<PackageResAdmin> {
  return performRequest(
    {
      endpoint: `package${getIdParam(id)}`,
      auth: basicAuth,
      method: id ? HttpMethods.PUT : HttpMethods.POST,
      body,
    },
    true
  )
}

export async function requestGetResources(
  basicAuth: string,
  ids: number[] = []
): Promise<ResourceResAdmin[]> {
  return performRequest(
    {
      endpoint: `resource${getIdsParam(ids)}`,
      auth: basicAuth,
      method: HttpMethods.GET,
    },
    true
  )
}

export async function requestSavePackagePrices(
  basicAuth: string,
  body: PackageOptionPriceReq
): Promise<IdRes<number>> {
  return performRequest({
    endpoint: `packagePrices`,
    auth: basicAuth,
    method: HttpMethods.POST,
    body,
  })
}

export async function requestSaveUpsalePrices(
  basicAuth: string,
  body: PackageOptionPriceReq
): Promise<IdRes<number>> {
  return performRequest({
    endpoint: `packageUpsalePrices`,
    auth: basicAuth,
    method: HttpMethods.POST,
    body,
  })
}
