import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date"
import React from "react"
import { DateTimePicker, DateTimePickerProps } from "@material-ui/pickers"
import { LiteEvent } from "../../types/globalTypes"
import { createLiteEvent } from "../../utils/typeUtils"
import Input from "../Input/Input"
import { Field } from "../Messages/FieldMessage"
import { FormField } from "../../types/apiTypes"
import useSelectStyles from "../Select/SelectStyle"
import { formatDateTime } from "../../utils/timeUtils"

interface IDateTimeInputProps {
  value?: number | null
  name: string
  onChange: (e: LiteEvent<number>) => void
  dense?: boolean
  messageId?: Field | FormField
  required?: boolean
}

export default function DateTimeInput({
  value,
  name,
  onChange,
  dense,
  messageId,
  required = false,
}: IDateTimeInputProps & Omit<DateTimePickerProps, "onChange">) {
  const classes = useSelectStyles()
  const handleChange = (date: MaterialUiPickersDate) => {
    if (date) onChange(createLiteEvent(name, date.valueOf()))
  }
  return (
    <DateTimePicker
      className={classes.root}
      ampm={false}
      onChange={handleChange}
      value={value}
      TextFieldComponent={({ placeholder, ...inputProps }) => (
        <Input
          {...inputProps}
          value={formatDateTime(value)}
          name={name}
          dense={dense}
          messageId={messageId}
          required={required}
        />
      )}
    />
  )
}
