import IconButton from "@material-ui/core/IconButton"
import { ArrowDownward } from "@material-ui/icons"
import { Chip } from "@material-ui/core"
import React, { ChangeEvent, useState } from "react"
import {
  Autocomplete,
  // @ts-ignore
  AutocompleteGetTagProps,
  // @ts-ignore
  AutocompleteRenderInputParams,
} from "@material-ui/lab"
import Input from "../Input/Input"
import {
  InputError,
  InputOption,
  SimpleInputOption,
} from "../../types/globalTypes"
import { Field } from "../Messages/FieldMessage"
import Sortable from "../Sortable/Sortable"
import { arrayMove } from "../../utils/arrayUtils"
import useStyles from "./MultiSelectStyles"
import { useInputOptions } from "../../hooks/utilHooks"
import useSelectStyles from "../Select/SelectStyle"

interface IMultiSelectProps {
  onChange: (name: string, values: string[]) => void
  values: string[]
  options: InputOption[]
  messageId: Field
  name: string
  inputError?: InputError
  dense?: boolean
}

export default function MultiSelect({
  onChange,
  values,
  options,
  name,
  messageId,
  inputError,
  dense,
}: IMultiSelectProps) {
  const sortedOptions = useInputOptions(options)
  const [isSorting, setIsSorting] = useState(false)
  const classes = useStyles({ isSorting })
  const selectClasses = useSelectStyles()

  const handleChange = (
    event: ChangeEvent<{}>,
    inputValues: Array<InputOption | undefined>
  ) => {
    const realValues = inputValues.filter((i) => i) as InputOption[]
    onChange(
      name,
      realValues.map((i) => i.value)
    )
  }

  const handleSort = (sourceIndex: number, destinationIndex: number) => {
    onChange(name, arrayMove(values, sourceIndex, destinationIndex))
  }

  const handleToggleSorting = () => {
    setIsSorting(!isSorting)
  }

  return (
    <Autocomplete<SimpleInputOption, true, true, false>
      className={selectClasses.root}
      multiple
      id={name}
      options={sortedOptions}
      getOptionLabel={(option) => option.content}
      value={values.map(
        (value) =>
          sortedOptions.find(
            (option) => option.value === value
          ) as SimpleInputOption
      )}
      onChange={handleChange}
      renderTags={(
        _values: Array<SimpleInputOption>,
        getProps: AutocompleteGetTagProps
      ) => (
        <Sortable
          id={`${name}-sort`}
          onSort={handleSort}
          isHorizontal={!isSorting}
          parentProps={{ as: "div", className: classes.chips }}
        >
          {_values.map((value, index) => (
            <Chip
              label={value?.content}
              {...getProps({ index })}
              classes={{ label: classes.label }}
            />
          ))}
        </Sortable>
      )}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <Input
          {...params}
          name={name}
          dense={dense}
          messageId={messageId}
          inputError={inputError}
          helperText={
            values.length > 1 ? (
              <IconButton
                aria-label="Change direction"
                onClick={handleToggleSorting}
                className={classes.sortButton}
                size={"small"}
                title={"Change direction"}
              >
                <ArrowDownward />
              </IconButton>
            ) : null
          }
        />
      )}
      disableCloseOnSelect
    />
  )
}
