import React from "react"
import { FormattedMessage } from "react-intl"
import config from "../../config"

interface IRouteMessageProps {
  id: keyof typeof config.routes
}

export default function RouteMessage({ id }: IRouteMessageProps) {
  switch (id) {
    case "ordersList":
      return <FormattedMessage id={`orders`} defaultMessage={`Orders`} />
    case "reservation":
      return (
        <FormattedMessage id={`reservation`} defaultMessage={`Reservation`} />
      )
    case "report":
      return <FormattedMessage id={`report`} defaultMessage={`Report`} />
    case "invoiceList":
      return <FormattedMessage id={`invoices`} defaultMessage={`Invoices`} />
    case "voucherList":
      return <FormattedMessage id={`vouchers`} defaultMessage={`Vouchers`} />
    case "activityList":
      return (
        <FormattedMessage id={`activities`} defaultMessage={`Activities`} />
      )
    case "activityDetail":
      return <FormattedMessage id={`activity`} defaultMessage={`Activity`} />
    case "upsaleList":
      return <FormattedMessage id={`upsales`} defaultMessage={`Upsales`} />
    case "upsaleDetail":
      return (
        <FormattedMessage
          id={`upsaleDetail`}
          defaultMessage={`Upsale detail`}
        />
      )
    case "agencyList":
      return <FormattedMessage id={`agencies`} defaultMessage={`Agencies`} />
    case "agencyDetail":
      return <FormattedMessage id={`agency`} defaultMessage={`Agency`} />
    case "userList":
      return <FormattedMessage id={`users`} defaultMessage={`Users`} />
    case "userDetail":
      return <FormattedMessage id={`user`} defaultMessage={`User`} />
    case "productList":
      return <FormattedMessage id={`products`} defaultMessage={`Products`} />
    case "productDetail":
      return <FormattedMessage id={`product`} defaultMessage={`Product`} />
    case "packageList":
      return <FormattedMessage id={`packages`} defaultMessage={`Packages`} />
    case "packageDetail":
      return (
        <FormattedMessage
          id={`packageDetail`}
          defaultMessage={`Package detail`}
        />
      )
    case "newsList":
      return <FormattedMessage id={`news`} defaultMessage={`News`} />
    case "newsDetail":
      return (
        <FormattedMessage id={`newsDetail`} defaultMessage={`News detail`} />
      )
    case "upsellingList":
    case "upsellingDetail":
      return <FormattedMessage id={`upselling`} defaultMessage={`Upselling`} />
    case "pageList":
      return <FormattedMessage id={`pages`} defaultMessage={`Pages`} />
    case "careerList":
      return (
        <FormattedMessage
          id={`careerApplications`}
          defaultMessage={`Career Applications`}
        />
      )
    case "showList":
      return (
        <FormattedMessage
          id={`shows`}
          defaultMessage={`Shows`}
        />
      )
    case "showDetail":
      return (
        <FormattedMessage
          id={`showDetail`}
          defaultMessage={`Show detail`}
        />
      )
    default:
      return <span>Missing translation for {id}</span>
  }
}
