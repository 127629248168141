import React from "react"
import { format } from "date-fns"
import GridOnIcon from "@material-ui/icons/GridOn"
import IconButton from "@material-ui/core/IconButton"
import { Tooltip } from "@material-ui/core"
import { FormattedMessage } from "react-intl"
import { ExtendedColumn } from "./DataGrid"
import { downloadBlob } from "../../utils/apiUtils"

interface IDataGridCsvButtonProps<T extends object> {
  data: T[]
  columns: ExtendedColumn<T>[]
}

export default function DataGridCsvButton<T extends object>({
  columns,
  data,
}: IDataGridCsvButtonProps<T>) {
  const exportCols = columns.filter(
    (col) => col.exportField || col.customExport
  )

  const handleClick = () => {
    const exportData = Array.isArray(data)
      ? data.map((row) =>
          exportCols
            .sort((a, b) => (a.exportSort || 0) - (b.exportSort || 0))
            .map((col) =>
              col.customExport
                ? col.customExport(row)
                : col.exportField
                ? row[col.exportField]
                : ""
            )
            .join(",")
        )
      : ""
    downloadBlob(
      new Blob(
        [
          '\uFEFF', // BOM prefix
          [
            'sep=,', // excel separator
            exportCols.map((col) => col.exportHeader || col.title).join(","), // head
            ...exportData // cols
          ].join("\r\n"),
        ],
        { type: "text/csv;charset=windows-1250" }
      ),
      `export_${format(Date.now(), "ddMMyyHHmm")}.csv`
    )
  }

  return exportCols.length > 0 ? (
    <Tooltip
      title={
        <FormattedMessage id={"exportAsCsv"} defaultMessage={"Export as CSV"} />
      }
    >
      <IconButton onClick={handleClick}>
        <GridOnIcon />
      </IconButton>
    </Tooltip>
  ) : null
}
