import {
  IdRes,
  ShowReq,
  ShowResAdmin,
} from "../types/apiTypes"
import performRequest, { getIdParam, getIdsParam } from "../utils/apiUtils"
import { HttpMethods } from "../types/globalTypes"

export async function requestGetShows(
  basicAuth: string,
  ids: number[] = []
): Promise<ShowResAdmin[]> {
  return performRequest(
    {
      endpoint: `show${getIdsParam(ids)}`,
      auth: basicAuth,
      method: HttpMethods.GET,
    },
    true
  )
}

export async function requestSaveShow(
  basicAuth: string,
  show: ShowReq,
  id?: number
): Promise<IdRes<number>> {
  return performRequest(
    {
      endpoint: `show${getIdParam(id)}`,
      auth: basicAuth,
      method: id ? HttpMethods.PUT : HttpMethods.POST,
      body: show,
    },
    true
  )
}

export async function requestDeleteShow(
  basicAuth: string,
  id: number
): Promise<IdRes<number>> {
  return performRequest(
    {
      endpoint: `show${getIdParam(id)}`,
      auth: basicAuth,
      method: HttpMethods.DELETE,
    },
    true
  )
}
