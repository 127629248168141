import React, { useCallback, useState } from "react"
import { FormattedMessage } from "react-intl"
import Grid from "@material-ui/core/Grid"
import { DialogActions } from "@material-ui/core"
import Modal from "../../components/Modal/Modal"
import Button from "../../components/Button/Button"
import { ExtendedOrderReqAdmin, LiteEvent } from "../../types/globalTypes"
import DateInput from "../../components/DateInput/DateInput"
import { Field } from "../../components/Messages/FieldMessage"
import Select from "../../components/Select/Select"
import { currentQuarterTime, isInvalid } from "../../utils/timeUtils"
import { isEmpty } from "ramda"
import { useAvailableTimeOptions } from "../../hooks/orderHooks"

interface OrderDateModalProps {
  onChange: (newValue: ExtendedOrderReqAdmin) => void
  onClose: () => void
  req: ExtendedOrderReqAdmin
}

export default function OrderDateModal({
  onChange,
  onClose,
  req,
}: OrderDateModalProps) {
  const [date, setDate] = useState<number>(
    () => req.packageOrder?.dtStart ?? currentQuarterTime().valueOf()
  )
  const { options, refetchOptions } = useAvailableTimeOptions(req, setDate)

  const handleDateChange = ({ currentTarget: { value } }: LiteEvent<number>) => {
    setDate(value)
    refetchOptions(value)
  }

  const handleTimeChange = useCallback(
    ({ currentTarget: { value } }: LiteEvent) => {
      setDate(parseFloat(value))
    },
    []
  )

  const handleSubmit = useCallback(() => {
    onChange({
      ...req,
      packageOrder: req.packageOrder && {
        ...req.packageOrder,
        dtStart: date,
      },
    })
    onClose()
  }, [date, onChange, onClose, req])

  const disableActions = isInvalid(date) || isEmpty(options)

  return (
    <Modal
      onClose={onClose}
      open={true}
      maxWidth={"md"}
      fullWidth
      title={
        <FormattedMessage id={"orderDate"} defaultMessage={"Order Date"} />
      }
    >
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <DateInput
            invalidDateMessage={
              <FormattedMessage id={"orderDateUnavailable"} />
            }
            messageId={Field.DATE}
            onChange={handleDateChange}
            value={date}
            name={`date`}
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            name={"time"}
            messageId={Field.TIME}
            options={options}
            value={date}
            onChange={handleTimeChange}
            disabled={disableActions}
          />
        </Grid>
      </Grid>
      <DialogActions>
        <Button
          disabled={disableActions}
          variant={"contained"}
          color={"primary"}
          onClick={handleSubmit}
        >
          <FormattedMessage id={"submit"} defaultMessage={"Submit"} />
        </Button>
      </DialogActions>
    </Modal>
  )
}
