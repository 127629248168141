import React from "react"
import { Typography } from "@material-ui/core"

export default function NotFound() {
  return (
    <Typography component={"h1"} variant={"h5"} align={"center"}>
      Page not found
    </Typography>
  )
}
