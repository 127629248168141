import React, { useCallback } from "react"
import useDeepCompareEffect from "use-deep-compare-effect"
import { EditComponentProps } from "@material-table/core"
import {
  ExtendedOrderReqAdmin,
  OrderDataGrid,
} from "../../types/globalTypes"
import { blankOrderData } from "../../utils/typeUtils"
import { filter } from "ramda"
import { UpsaleReqAdmin } from "../../types/apiTypes"
import { useSelector } from "react-redux"
import {
  selectActivityRecords,
  selectOptionMap,
} from "../../selectors/activitySelector"

/*
 Why this component exists?
 Several useEffect hooks in multiple columns caused sync problems
 and it was difficult to keep track what's going on.
 see: https://kentcdodds.com/blog/use-state-lazy-initialization-and-function-updates#dispatch-function-updates
*/
export function OrderReqDataSyncCell({
  rowData: { req = blankOrderData },
  onChange,
}: EditComponentProps<OrderDataGrid>) {
  const activityMap = useSelector(selectActivityRecords)
  const optionMap = useSelector(selectOptionMap)
  const changeRow = useCallback(
    (data: ExtendedOrderReqAdmin) => {
      onChange(data)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  // TODO: remove this component and move logic to OrderActivityEditCell
  // effects for optionId
  useDeepCompareEffect(() => {
    const optionId = req?.packageOrder?.optionId
    if (!req.isLocked && optionId) {
      const activity = activityMap.get(req?.packageId?.toString())
      const option = optionMap.get(optionId.toString())
      if (activity && option)
        changeRow({
          ...req,
          // filter applicable upsales
          upsales: filter(
            (upsale: UpsaleReqAdmin) =>
              activity.upsalePackageIds
                .map((i) => i.upsaleId)
                .includes(upsale.packageUpsaleId),
            req.upsales
          ),
        })
    }
  }, [req.isLocked, req.packageOrder?.optionId, changeRow, activityMap, optionMap])

  return <></>
}
