import React from "react"
import { FormattedMessage } from "react-intl"

export default function UnauthorizedMessage() {
  return (
    <FormattedMessage
      id={"routeNotAllowed"}
      defaultMessage={"You are not allowed to view this page."}
    />
  )
}