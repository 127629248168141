import React, { ReactNode } from "react"
import DataGrid from "components/DataGrid/DataGrid"

import { useSelector } from "react-redux"

import ReportSummary from "./ReportSummary"
import RouteMessage from "../../components/Messages/RouteMessage"
import { Field } from "components/Messages/FieldMessage"
import { ReportEmployeeDataGrid } from "types/globalTypes"
import { useLanguage, useLocalStoragePagination } from "hooks/interfaceHooks"
import OrderFilter from "components/OrderFilter/OrderFilter"
import { useIntl } from "react-intl"
import { selectReportEmployeeDataGrid } from "../../selectors/reportSelector"

interface Props {
  toolbarIcons: ReactNode
}

const EmployeeReport = ({ toolbarIcons }: Props) => {
  const reportEmployee = useSelector(selectReportEmployeeDataGrid)
  const lang = useLanguage()
  const { formatMessage } = useIntl()
  const paginationProps = useLocalStoragePagination("report")

  return (
    <DataGrid<ReportEmployeeDataGrid>
      {...paginationProps}
      title={<RouteMessage id={"report"} />}
      data={reportEmployee}
      customFilter={<OrderFilter />}
      toolbarIcons={toolbarIcons}
      defaultFilterOpen
      columns={[
        {
          title: Field.NICKNAME,
          field: "nickname",
          exportField: "nickname",
        },
        {
          title: Field.ACTIVITIES,
          field: "nickname",
          render: ({ activities }: ReportEmployeeDataGrid) => (
            /* todo: extract/use component for list without bullets? */
            <ul style={{ listStyleType: "none" }}>
              {activities.map(({ id, packageOrder }) => (
                <li key={id}>
                  {packageOrder?.option.translations[lang].title}
                </li>
              ))}
            </ul>
          ),
        },
        {
          title: Field.REWARD,
          field: "reward",
          render: ({ reward }: ReportEmployeeDataGrid) => `${reward} Kč`,
        },
        {
          title: Field.SEND_INVOICE,
          render: ({ sendInvoice }: ReportEmployeeDataGrid) =>
            sendInvoice
              ? formatMessage({ id: "yes", defaultMessage: "Yes" })
              : formatMessage({ id: "no", defaultMessage: "No" }),
        },
      ]}
    >
      <ReportSummary />
    </DataGrid>
  )
}

export default EmployeeReport
