import { makeStyles, Theme } from "@material-ui/core/styles"

interface Props {
  zoom: number
  isOpen: boolean
}

export default makeStyles((theme: Theme) => ({
  root: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    marginRight: "auto",
    maxWidth: 160,
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  drawerPaper: {
    position: "fixed",
    top: "56px",
    overflow: `auto`,
    whiteSpace: "nowrap",
    width: theme.spacing(30),
    paddingBottom: theme.spacing(10),
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  drawerPaperClose: {
    width: 0,
  },

  container: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(4),
    paddingLeft: ({ isOpen }: Props) =>
      isOpen ? theme.spacing(28) : 0,
    paddingRight: 0,
    display: "flex",
  },

  zoomEffect: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    transformOrigin: "top left",
    transform: ({ zoom }: Props) => `scale(${zoom / 100})`,
    transition: theme.transitions.create("padding-left", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}))
