import { ActionTypes } from "../types/actionTypes"
import { SessionMap } from "../models/sessionModel"

export const fetchSessions = (ids: number[] = []) =>
  ({
    type: ActionTypes.FETCH_SESSIONS_REQUESTED,
    payload: { ids },
  } as const)

export const fetchSessionsSucceed = (sessions: SessionMap) =>
  ({
    type: ActionTypes.FETCH_SESSIONS_SUCCEEDED,
    payload: {
      sessions,
    },
  } as const)

export const fetchSessionsFailed = (error: string) =>
  ({
    type: ActionTypes.FETCH_SESSIONS_FAILED,
    payload: {
      error,
    },
  } as const)
