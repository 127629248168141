import React, { ReactNode } from "react"
import { Autocomplete } from "@material-ui/lab"
import { createLiteEvent } from "../../utils/typeUtils"
import {
  SimpleInputOption,
  InputOption,
  LiteEvent,
} from "../../types/globalTypes"
import Input from "../Input/Input"
import { Field } from "../Messages/FieldMessage"
import { useInputOptions } from "../../hooks/utilHooks"
import useSelectStyles from "../Select/SelectStyle"
import useStyles from "./ComboBoxStyles"
import clsx from "clsx"

interface IComboBoxProps {
  name: string
  options: InputOption[]
  onChange: (e: LiteEvent) => void
  messageId?: Field
  label?: ReactNode
  value?: string | null
  dense?: boolean
  disabled?: boolean
  disableClearable?: boolean
  disableSort?: boolean
  getOptionDisabled?: (option: SimpleInputOption) => boolean
  size?: "small" | "medium"
  style?: React.CSSProperties
  onBlur?: () => void
}

export default function ComboBox({
  messageId,
  name,
  options,
  value,
  onChange,
  dense = true,
  label,
  getOptionDisabled,
  disabled = false,
  disableClearable = false,
  disableSort = false,
  size,
  style,
  onBlur,
}: IComboBoxProps) {
  const styles = useStyles()
  const autocompleteOptions = useInputOptions(options, !disableSort)
  const classes = useSelectStyles()
  const handleChange = (
    event: React.ChangeEvent<{}>,
    option: InputOption | null
  ) => {
    onChange(createLiteEvent(name, option?.value || ""))
  }

  const optionValue =
    value === null || value === "0"
      ? {
          content: " ",
          value: "undefined",
          disabled: true,
        }
      : autocompleteOptions.find((option) => option.value === value)

  return (
    <Autocomplete<SimpleInputOption, false, boolean, false>
      onBlur={onBlur}
      className={classes.root}
      style={style}
      value={optionValue || { content: "", value: "" }}
      id={name}
      options={autocompleteOptions}
      getOptionLabel={(option) => option.content}
      onChange={handleChange}
      renderInput={(params) => (
        <Input
          {...params}
          name={name}
          dense={dense}
          messageId={messageId}
          label={label}
        />
      )}
      renderOption={(option) => (
        <span className={clsx(option.promoted && styles.promoted)}>
          {option.content}
        </span>
      )}
      getOptionDisabled={getOptionDisabled}
      disabled={disabled}
      size={size}
      disableClearable={disableClearable}
    />
  )
}
