import performRequest from "../utils/apiUtils"
import { HttpMethods } from "../types/globalTypes"
import { ReservationCapacityRes } from "../types/apiTypes"

export async function requestGetReservationCapacity(
  basicAuth: string,
  dtStart = 0
): Promise<ReservationCapacityRes> {
  return performRequest(
    {
      endpoint: `capacity?dtStart=${dtStart}`,
      auth: basicAuth,
      method: HttpMethods.GET,
    },
    true
  )
}
