import React from "react"
import {
  KeyboardTimePicker,
  KeyboardTimePickerProps,
} from "@material-ui/pickers"
import {
  addMilliseconds,
  differenceInMilliseconds,
  isValid,
  startOfDay,
} from "date-fns"
import { LiteEvent } from "../../types/globalTypes"
import { createLiteEvent } from "../../utils/typeUtils"
import useStyles from "./MinuteInputStyles"
import FieldMessage, { Field } from "../Messages/FieldMessage"

interface IMinuteInputProps {
  messageId: Field
  value: number
  name: string
  onChange?: (e: LiteEvent) => void
}

export default function MinuteInput({
  value,
  onChange,
  messageId,
  name,
  ...props
}: IMinuteInputProps & Omit<KeyboardTimePickerProps, "onChange">) {
  const classes = useStyles()

  const handleChange = (date: Date | null) => {
    if (onChange && date && isValid(date)) {
      onChange(
        createLiteEvent(
          name,
          differenceInMilliseconds(date, startOfDay(date)).toString()
        )
      )
    }
  }

  return (
    <KeyboardTimePicker
      {...props}
      className={classes.root}
      label={<FieldMessage id={messageId} />}
      inputVariant={`outlined`}
      onChange={handleChange}
      value={addMilliseconds(startOfDay(Date.now()), value)}
      ampm={false}
    />
  )
}
