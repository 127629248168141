import React, { SyntheticEvent, useState } from "react"
import ReactCrop, { Crop } from "react-image-crop"
import "react-image-crop/dist/ReactCrop.css"
import { useImageReader } from "../../hooks/utilHooks"
import { CropDimensions } from "../../hooks/galleryHooks"

interface IImageCropProps {
  image: File | string
  onComplete: (crop: Crop) => void
  defaultCrop?: CropDimensions
}

const minWidth = 300
const minHeight = 100

export default function ImageCrop({
  image,
  onComplete,
  defaultCrop: { x, y, width, height } = {
    x: 0,
    y: 0,
    width: minWidth,
    height: minHeight,
  },
}: IImageCropProps) {
  const source = useImageReader(image)
  const [scale, setScale] = useState<[number, number]>([1, 1])
  const [crop, setCrop] = useState<Crop>({
    unit: "px",
    x,
    y,
    width,
    height,
  })

  const handleImageLoad = ({
    currentTarget: target,
  }: SyntheticEvent<HTMLImageElement>) => {
    const [scaleX, scaleY] = [
      target.naturalWidth / target.width,
      target.naturalHeight / target.height,
    ]
    setScale([scaleX, scaleY])
    setCrop({
      unit: "px",
      x: x / scaleX,
      y: y / scaleY,
      width: width / scaleX,
      height: height / scaleY,
    })
    // Return false when setting crop state in here.
    return false
  }

  const handleComplete = (cropData: Crop) => {
    const [scaleX, scaleY] = scale
    onComplete({
      ...cropData,
      x: (cropData?.x || 0) * scaleX,
      y: (cropData?.y || 0) * scaleY,
      width: (cropData?.width || 0) * scaleX,
      height: (cropData?.height || 0) * scaleY,
    })
  }

  return (
    <ReactCrop
      crop={crop}
      onChange={setCrop}
      onComplete={handleComplete}
      keepSelection
      minWidth={minWidth}
      minHeight={minHeight}
    >
      <img alt={"to be cropped"} src={source} onLoad={handleImageLoad} />
    </ReactCrop>
  )
}
