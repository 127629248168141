import { ActionTypes } from "../types/actionTypes"
import {
  CareerApplicationReq,
  CareerApplicationResAdmin,
} from "../types/apiTypes"
import { CareerMap } from "../models/careerModel"

export const fetchCareer = (ids?: number[]) =>
  ({
    type: ActionTypes.FETCH_CAREER_REQUESTED,
    payload: {
      ids,
    },
  } as const)

export const fetchCareerFailed = (message: string) =>
  ({
    type: ActionTypes.FETCH_CAREER_FAILED,
    payload: {
      message,
    },
  } as const)

export const fetchCareerSucceed = (careers: CareerMap) =>
  ({
    type: ActionTypes.FETCH_CAREER_SUCCEEDED,
    payload: {
      careers,
    },
  } as const)

export const saveCareer = (formData: CareerApplicationReq, id?: number) =>
  ({
    type: ActionTypes.SAVE_CAREER_REQUESTED,
    payload: {
      id,
      formData,
    },
  } as const)

export const saveCareerFailed = (message: string) =>
  ({
    type: ActionTypes.SAVE_CAREER_FAILED,
    payload: {
      message,
    },
  } as const)

export const saveCareerSucceed = (career: CareerApplicationResAdmin) =>
  ({
    type: ActionTypes.SAVE_CAREER_SUCCEEDED,
    payload: {
      career,
    },
  } as const)

export const deleteCareer = (id: number) =>
  ({
    type: ActionTypes.DELETE_CAREER_REQUESTED,
    payload: {
      id,
    },
  } as const)

export const deleteCareerFailed = (message: string) =>
  ({
    type: ActionTypes.DELETE_CAREER_FAILED,
    payload: {
      message,
    },
  } as const)

export const deleteCareerSucceed = (id: number) =>
  ({
    type: ActionTypes.DELETE_CAREER_SUCCEEDED,
    payload: {
      id,
    },
  } as const)
