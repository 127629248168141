import performRequest from "../utils/apiUtils"
import {
  RecoverReq,
  RequestRecoverReq,
  StatusRes,
  UserRes,
} from "../types/apiTypes"
import { HttpMethods } from "../types/globalTypes"

export async function requestUserAuthentication(
  token: string
): Promise<UserRes> {
  if (!token) throw new Error("Missing token")
  return performRequest({
    endpoint: `user/whoami`,
    method: HttpMethods.GET,
    auth: token,
  })
}

export async function requestRecoveryLink(body: RequestRecoverReq) {
  return performRequest({
    endpoint: "recovery/request",
    method: HttpMethods.POST,
    body,
    headers: {
      Authorization: "Bearer asdf", // TODO
    },
  })
}

export async function requestRecoverPassword(body: RecoverReq) {
  return performRequest({
    endpoint: "recovery/recover",
    method: HttpMethods.POST,
    body,
    headers: {
      Authorization: "Bearer asdf", // TODO
    },
  })
}

export async function requestGetJWT(auth: string): Promise<StatusRes> {
  return performRequest({
    endpoint: "user/jwt",
    method: HttpMethods.GET,
    auth,
  })
}
