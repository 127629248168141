import React, { ChangeEvent, FC, forwardRef, Ref } from "react"
import AddBox from "@material-ui/icons/AddBox"
import Check from "@material-ui/icons/Check"
import Clear from "@material-ui/icons/Clear"
import DeleteOutline from "@material-ui/icons/DeleteOutline"
import ChevronRight from "@material-ui/icons/ChevronRight"
import Edit from "@material-ui/icons/Edit"
import SaveAlt from "@material-ui/icons/SaveAlt"
import FilterList from "@material-ui/icons/FilterList"
import FirstPage from "@material-ui/icons/FirstPage"
import LastPage from "@material-ui/icons/LastPage"
import ChevronLeft from "@material-ui/icons/ChevronLeft"
import Search from "@material-ui/icons/Search"
import ArrowUpward from "@material-ui/icons/ArrowUpward"
import Remove from "@material-ui/icons/Remove"
import ViewColumn from "@material-ui/icons/ViewColumn"
import { Paper } from "@material-ui/core"
import {
  EditComponentProps,
  MTableEditRow,
  MTableToolbar,
} from "@material-table/core"
import { toolbarStyles, paperStyles } from "./DataGridStyles"
import Input from "../Input/Input"

export const cellStyleCompact = {
  fontSize: `0.7rem`,
  verticalAlign: `top`,
  padding: `12px 4px`,
}

export const cellStyleComfort = {
  fontSize: `0.7rem`,
  verticalAlign: `top`,
  padding: `12px 6px`,
}

export function DataGridEditCell<Data extends object>({
  value,
  onChange,
  columnDef: { field, title },
}: EditComponentProps<Data>) {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.currentTarget.value)
  }
  if (Array.isArray(field))
    throw new Error(`Column field is an array [${field.join(",")}]`)
  return (
    <Input
      onChange={handleChange}
      value={value}
      name={field}
      label={title}
      dense
    />
  )
}

export const DataGridIcons = {
  Add: forwardRef((props, ref: Ref<SVGSVGElement>) => (
    <AddBox
      {...props}
      ref={ref}
      onClick={() => {
        // scroll to the end of a page as the new row is rendered at the bottom
        // setInterval is necessary because the new row is rendered after this fn call
        setTimeout(() => {
          document
            .querySelector('tr[mode="add"]')
            ?.scrollIntoView({ behavior: "smooth", block: "center" })
        }, 500)
      }}
    />
  )),
  Check: forwardRef((props, ref: Ref<SVGSVGElement>) => (
    <Check {...props} ref={ref} />
  )),
  Clear: forwardRef((props, ref: Ref<SVGSVGElement>) => (
    <Clear {...props} ref={ref} />
  )),
  Delete: forwardRef((props, ref: Ref<SVGSVGElement>) => (
    <DeleteOutline {...props} ref={ref} />
  )),
  DetailPanel: forwardRef((props, ref: Ref<SVGSVGElement>) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref: Ref<SVGSVGElement>) => (
    <Edit {...props} ref={ref} />
  )),
  Export: forwardRef((props, ref: Ref<SVGSVGElement>) => (
    <SaveAlt {...props} ref={ref} />
  )),
  Filter: forwardRef((props, ref: Ref<SVGSVGElement>) => (
    <FilterList {...props} ref={ref} />
  )),
  FirstPage: forwardRef((props, ref: Ref<SVGSVGElement>) => (
    <FirstPage {...props} ref={ref} />
  )),
  LastPage: forwardRef((props, ref: Ref<SVGSVGElement>) => (
    <LastPage {...props} ref={ref} />
  )),
  NextPage: forwardRef((props, ref: Ref<SVGSVGElement>) => (
    <ChevronRight {...props} ref={ref} />
  )),
  PreviousPage: forwardRef((props, ref: Ref<SVGSVGElement>) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref: Ref<SVGSVGElement>) => (
    <Clear {...props} ref={ref} />
  )),
  Search: forwardRef((props, ref: Ref<SVGSVGElement>) => (
    <Search {...props} ref={ref} />
  )),
  SortArrow: forwardRef((props, ref: Ref<SVGSVGElement>) => (
    <ArrowUpward {...props} ref={ref} />
  )),
  ThirdStateCheck: forwardRef((props, ref: Ref<SVGSVGElement>) => (
    <Remove {...props} ref={ref} />
  )),
  ViewColumn: forwardRef((props, ref: Ref<SVGSVGElement>) => (
    <ViewColumn {...props} ref={ref} />
  )),
}
export const DataGridContainer = ({
  children,
  ...containerProps
}: {
  children: React.ReactNode
}) => {
  const [toolbar, body, pagination] = React.Children.toArray(children)
  const classes = paperStyles()
  return (
    <div {...containerProps}>
      {toolbar}
      <Paper elevation={1} className={classes.root}>
        {body}
        {pagination}
      </Paper>
    </div>
  )
}

export const DataGridToolbar = (props: object) => {
  const classes = toolbarStyles()
  return (
    <div className={classes.root}>
      <MTableToolbar {...props} />
    </div>
  )
}

export const DataGridEditRow: FC<object> = (rowProps: object) => (
  <MTableEditRow
    {...rowProps}
    onKeyDown={(e: KeyboardEvent) => e.code === "Enter" && e.preventDefault()}
  />
)