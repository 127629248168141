import { RequestStatus as Status, RequestStatus } from "../types/statusTypes"
import { makeUserState, UserActions, UserState } from "../models/userModel"
import { ActionTypes, AnyAction } from "../types/actionTypes"

const initialState: UserState = makeUserState()

export default function userReducer(
  state = initialState,
  action: AnyAction | null = null
): UserState {
  // helpers
  const setStatus = (key: UserActions, status: Status) =>
    state.set("statuses", state.statuses.set(key, status))
  const setError = (key: UserActions, error: string) =>
    state
      .set("errors", state.errors.set(key, error))
      .set("statuses", state.statuses.set(key, RequestStatus.FAILED))

  switch (action?.type) {
    // logout
    case ActionTypes.LOGOUT_USER_SUCCEEDED:
      return initialState

    // reset status to default and error message to empty string
    case ActionTypes.RESET_USER_STATUS:
      return setStatus(action.payload.key, RequestStatus.INITIAL).set(
        "errors",
        state.errors.set(action.payload.key, "")
      )

    // fetch
    case ActionTypes.FETCH_USERS_REQUESTED:
      return setStatus("fetchUsers", RequestStatus.REQUESTED)

    case ActionTypes.FETCH_USERS_SUCCEEDED:
      return setStatus("fetchUsers", RequestStatus.SUCCEEDED).set(
        "records",
        action.payload.users
      )

    case ActionTypes.FETCH_USERS_FAILED:
      return setError("fetchUsers", action.payload.message)

    // login
    case ActionTypes.LOG_USER_SUCCEEDED:
      return state.set(
        "records",
        state.records.set(
          action.payload.user.id.toString(),
          action.payload.user
        )
      )

    // save
    case ActionTypes.SAVE_USER_REQUESTED:
      return setStatus("saveUser", RequestStatus.REQUESTED)

    case ActionTypes.SAVE_USER_SUCCEEDED:
      return setStatus("saveUser", RequestStatus.SUCCEEDED).set(
        "records",
        state.records.set(
          action.payload.user.id.toString(),
          action.payload.user
        )
      )

    case ActionTypes.SAVE_USER_FAILED:
      return setError("saveUser", action.payload.message)

    // delete
    case ActionTypes.DELETE_USER_REQUESTED:
      return setStatus("deleteUser", RequestStatus.REQUESTED)

    case ActionTypes.DELETE_USER_SUCCEEDED:
      return setStatus("deleteUser", RequestStatus.SUCCEEDED).set(
        "records",
        state.records.delete(action.payload.id.toString())
      )

    case ActionTypes.DELETE_USER_FAILED:
      return setError("deleteUser", action.payload.message)

    default: {
      return state
    }
  }
}
