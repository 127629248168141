import React from "react"
import { FormattedMessage } from "react-intl"

export enum WebPage {
  HOMEPAGE = "homepage",
  OFFER = "offer",
  PARTY = "party",
  GALLERY = "gallery",
  SUPPORT = "support",
  CAREER = "career",
  CONTACT = "contact",
  SHOW = "show",
}

interface IWebPageMessageProps {
  id: WebPage
}

export default function WebPageMessage({ id }: IWebPageMessageProps) {
  switch (id) {
    case WebPage.HOMEPAGE:
      return <FormattedMessage id={`homepage`} defaultMessage={`Homepage`} />
    case WebPage.OFFER:
      return <FormattedMessage id={`offer`} defaultMessage={`Offer`} />
    case WebPage.PARTY:
      return <FormattedMessage id={`party`} defaultMessage={`Party`} />
    case WebPage.GALLERY:
      return <FormattedMessage id={`gallery`} defaultMessage={`Gallery`} />
    case WebPage.SUPPORT:
      return <FormattedMessage id={`support`} defaultMessage={`Support`} />
    case WebPage.CAREER:
      return <FormattedMessage id={`career`} defaultMessage={`Career`} />
    case WebPage.CONTACT:
      return <FormattedMessage id={`contact`} defaultMessage={`Contact`} />
    case WebPage.SHOW:
      return <FormattedMessage id={`show`} defaultMessage={`Show`} />
    default:
      return null
  }
}
