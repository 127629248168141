import performRequest, { getIdsParam } from "../utils/apiUtils"
import { HttpMethods } from "../types/globalTypes"
import { IdRes, SessionResAdmin } from "../types/apiTypes"

export async function requestSessions(
  basicAuth: string,
  ids: number[]
): Promise<SessionResAdmin[]> {
  return performRequest({
    endpoint: `session${getIdsParam(ids)}`,
    auth: basicAuth,
    method: HttpMethods.GET,
  })
}

export async function requestCloseSession(basicAuth: string, id: number) {
  return performRequest({
    endpoint: `session/${id}/close`,
    auth: basicAuth,
    method: HttpMethods.POST,
  })
}

export async function requestOpenSession(basicAuth: string): Promise<IdRes<number>> {
  return performRequest({
    endpoint: `session`,
    auth: basicAuth,
    method: HttpMethods.POST,
  })
}

export async function requestActiveSession(basicAuth: string): Promise<IdRes<number>> {
  return performRequest({
    endpoint: `session/active`,
    auth: basicAuth,
    method: HttpMethods.GET,
  })
}
