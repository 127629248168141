import React, { useState } from "react"
import Button from "components/Button/Button"
import DataGrid from "components/DataGrid/DataGrid"
import RouteMessage from "components/Messages/RouteMessage"
import { SiteResAdmin } from "types/apiTypes"
import AddBox from "@material-ui/icons/AddBox"
import { FormattedMessage } from "react-intl"
import { Navigate } from "react-router-dom"
import config from "config"
import { useLanguage, useLocalStoragePagination } from "hooks/interfaceHooks"
import { useProductSave } from "hooks/productHooks"
import { useNewsDelete, useNewsFetch } from "hooks/newsHooks"
import Results from "components/Results/Results"
import { Entity } from "components/Messages/EntityMessage"
import StatusProgressBar from "components/StatusProgressBar/StatusProgressBar"
import { Field } from "../../components/Messages/FieldMessage"

function NewsList() {
  const [redirectId, setRedirectId] = useState<string | null>(null)
  const language = useLanguage()
  const [, saveStatus, saveError] = useProductSave()
  const [news, fetchStatus, fetchError] = useNewsFetch()
  const [deleteNews, deleteStatus, deleteError] = useNewsDelete()
  const paginationProps = useLocalStoragePagination("news")

  return (
    <>
      <Results
        statuses={[saveStatus, fetchStatus, deleteStatus]}
        errors={[saveError, fetchError, deleteError]}
        actions={["save", "fetch", "delete"]}
        entity={Entity.NEWS}
      />
      {redirectId !== null && (
        <Navigate to={config.routes.newsDetail.path(redirectId)} />
      )}
      <StatusProgressBar statuses={[fetchStatus]} />
      <DataGrid<SiteResAdmin>
        {...paginationProps}
        data={news.valueSeq().toArray()}
        title={<RouteMessage id={"newsList"} />}
        onRowClick={(_, row) => setRedirectId(row ? row.id.toString() : null)}
        columns={[
          {
            title: Field.TITLE,
            field: `notification.translations.${language}.title`,
            defaultSort: "asc"
          }
        ]}
        cta={
          <Button
            onClick={() => setRedirectId(``)}
            variant="contained"
            color={`primary`}
            startIcon={<AddBox />}
          >
            <FormattedMessage
              id={`createNews`}
              defaultMessage={`Create news`}
            />
          </Button>
        }
        editable={{
          onRowDelete: ({ id }) =>
            new Promise<void>((resolve) => {
              deleteNews(id)
              resolve()
            })
        }}
      />
    </>
  )
}

export default NewsList
