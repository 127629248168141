import React, { Children, ReactNode } from "react"
import useStyles from "./CellGroupStyle"

interface CellGroupProps {
  children: ReactNode
}

export default function CellGroup({ children }: CellGroupProps) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {Children.count(children) > 1 ? Children.map(children, (child) => child && <div>{child}</div>) : children}
    </div>
  )
}
