import { AppState } from "store"

export const selectNewsRecords = (state: AppState) => state.news.records

export const selectNewsFetchStatus = (state: AppState) =>
  state.news.statuses.fetchNews

export const selectNewsSaveStatus = (state: AppState) =>
  state.news.statuses.saveNews

export const selectNewsDeleteStatus = (state: AppState) =>
  state.news.statuses.deleteNews

export const selectNewsFetchError = (state: AppState) =>
  state.news.errors.fetchNews

export const selectNewsSaveError = (state: AppState) =>
  state.news.errors.saveNews

export const selectNewsDeleteError = (state: AppState) =>
  state.news.errors.deleteNews
