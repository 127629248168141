import React, { ReactNode } from "react"
import {
  Collapse,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core"
import { ExpandLess, ExpandMore } from "@material-ui/icons"

interface CollapseProps {
  children: ReactNode
  title: string
  expanded: boolean
}

export default function CollapseComponent({
  children,
  title,
  expanded,
}: CollapseProps) {
  const [open, setOpen] = React.useState(expanded)

  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <List component="nav" aria-labelledby="nested-list-subheader">
      <ListItem button onClick={handleClick}>
        <ListItemText
          primary={<Typography variant={"button"}>{title}</Typography>}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </List>
  )
}
