import { RecordOf } from "immutable"
import { call, put, select } from "redux-saga/effects"
import { selectToken } from "../selectors/interfaceSelector"
import {
  fetchConflictsFailed,
  fetchConflictsSucceeded,
  fetchOrders,
  fetchOrdersFailed,
  fetchOrdersSucceeded,
  fetchSeating,
  fetchSeatingFailed,
  fetchSeatingSucceeded,
  fetchSections,
  fetchSectionsFailed,
  fetchSectionsSucceeded,
  saveEmployeeStatus,
  saveEmployeeStatusFailed,
  saveEmployeeStatusSucceeded,
  sendVoucherEmail,
  sendVoucherEmailFailed,
  sendVoucherEmailSucceeded,
  setOrderFilter,
  setOrderPageNumber,
  setOrderPageSize,
  setOrderSearchQuery, setOrderSortType
} from "../actions/orderActions"
import {
  OrderConflictRes,
  OrderResAdmin,
  SeatingResAdmin,
  SectionResAdmin
} from "../types/apiTypes"
import {
  requestGetConflictsForAllOrders,
  requestGetOrders,
  requestGetSeating,
  requestGetSections,
  requestSaveEmployeeStatus,
  requestSendVoucherEmail,
} from "../api/orderRequest"
import { arrayToMap, arrayToOrderedMap } from "../utils/arrayUtils"
import { defaultFilters } from "../models/ordersModel"
import { Field } from "../components/Messages/FieldMessage"
import {
  selectOrderFilter,
  selectOrderFilterDates,
  selectOrderPageNumber,
  selectOrderPageSize,
  selectOrderSearchQuery, selectOrderSortType
} from "../selectors/orderSelector"
import config from "../config"
import { Source } from "../types/filterTypes"
import { OPTION_ALL } from "../utils/typeUtils"
import { ActionTypes } from "../types/actionTypes"
import { Entity } from "../components/Messages/EntityMessage"
import { storeItems } from "../utils/apiUtils"
import { SortType, StorageFilters } from "../types/globalTypes"

export function* fetchOrdersSaga({
  type,
  payload,
}:
  | ReturnType<typeof fetchOrders>
  | ReturnType<typeof setOrderFilter>
  | ReturnType<typeof setOrderPageSize>
  | ReturnType<typeof setOrderSearchQuery>
  | ReturnType<typeof setOrderSortType>
  | ReturnType<typeof setOrderPageNumber>) {
  try {
    const auth: string = yield select(selectToken)
    const pageSize: number = yield select(selectOrderPageSize)
    const searchQuery: string = yield select(selectOrderSearchQuery)
    const pageNumber: number = yield select(selectOrderPageNumber)
    const sortType: SortType = yield select(selectOrderSortType)
    const filters: RecordOf<typeof defaultFilters> = yield select(
      selectOrderFilter
    )
    const [dtStart, dtEnd]: [number, number] = yield select(
      selectOrderFilterDates
    )
    const paymentMethod = filters.get(Field.PAYMENT_METHOD).first()
    const source = filters.get(Field.SOURCE).first()
    const reservation = filters.get(Field.CONTAINS_RESERVATION).first()
    const agencyIds = filters.get(Field.AGENCY).toArray()
    const entityIds = filters.get(Entity.ORDER).toArray()
    const packageIds = filters.get(Field.ACTIVITY).toArray()
    const orderEmployeeIds = filters.get(Field.EMPLOYEE).toArray()
    const data: OrderResAdmin[] = yield call(
      requestGetOrders,
      auth,
      typeof payload === "object" && "ids" in payload ? payload.ids : entityIds,
      searchQuery
        ? {
            sortOrder: sortType,
            contactInfo: searchQuery,
            offset: pageNumber * pageSize,
            limit: pageSize,
            dtStart: 0,
          }
        : {
            sortOrder: sortType,
            offset: pageNumber * pageSize,
            limit: pageSize,
            dtStart,
            dtEnd,
            status: filters.get(Field.ORDER_STATUS).toArray(),
            packageIds,
            orderEmployeeIds,
            paymentMethods:
              paymentMethod === OPTION_ALL || !paymentMethod
                ? undefined
                : [paymentMethod],
            hasReservation:
              reservation === OPTION_ALL
                ? undefined
                : reservation ? 'true' : 'false',
            agencyIds:
              source === Source.WEB
                ? [config.agencyIds.web, ...agencyIds]
                : agencyIds,
            agencyIdsExclude:
              source === Source.AGENCY ? [config.agencyIds.web] : [],
          }
    )
    const orderFilters = filters.toJS() as unknown as StorageFilters
    yield call(storeItems, { orderFilters, sortType })
    yield put(
      fetchOrdersSucceeded(
        arrayToOrderedMap(data),
        type === ActionTypes.SET_ORDER_PAGE_NUMBER ? "merge" : "set"
      )
    )
  } catch (e) {
    yield put(fetchOrdersFailed(e instanceof Error ? e.message : ""))
  }
}

export function* fetchConflictsSaga() {
  try {
    const auth: string = yield select(selectToken)
    const conflicts: OrderConflictRes = yield call(requestGetConflictsForAllOrders, auth)
    yield put(fetchConflictsSucceeded(conflicts))
  } catch (e) {
    yield put(fetchConflictsFailed(e instanceof Error ? e.message : ""))
  }
}

export function* fetchSectionsSaga({
  payload: { ids },
}: ReturnType<typeof fetchSections>) {
  try {
    const auth: string = yield select(selectToken)
    const data: SectionResAdmin[] = yield call(requestGetSections, auth, ids)
    yield put(fetchSectionsSucceeded(arrayToMap(data)))
  } catch (e) {
    yield put(fetchSectionsFailed(e instanceof Error ? e.message : ""))
  }
}

export function* fetchSeatingSaga({
  payload: { ids },
}: ReturnType<typeof fetchSeating>) {
  try {
    const auth: string = yield select(selectToken)
    const data: SeatingResAdmin[] = yield call(requestGetSeating, auth, ids)
    yield put(fetchSeatingSucceeded(arrayToMap(data)))
  } catch (e) {
    yield put(fetchSeatingFailed(e instanceof Error ? e.message : ""))
  }
}

export function* saveEmployeeStatusSaga({
  payload: { id, status, orderId },
}: ReturnType<typeof saveEmployeeStatus>) {
  try {
    const auth: string = yield select(selectToken)
    yield call(requestSaveEmployeeStatus, auth, id, status)
    yield put(saveEmployeeStatusSucceeded(id, orderId, status))
  } catch (e) {
    yield put(saveEmployeeStatusFailed(e instanceof Error ? e.message : ""))
  }
}

export function* sendVoucherEmailSaga({
  payload: { id },
}: ReturnType<typeof sendVoucherEmail>) {
  try {
    const auth: string = yield select(selectToken)
    yield call(requestSendVoucherEmail, auth, id)
    yield put(sendVoucherEmailSucceeded())
  } catch (e) {
    yield put(sendVoucherEmailFailed(e instanceof Error ? e.message : ""))
  }
}
