import React, { useState } from "react"
import { ListAlt, SupervisedUserCircle } from "@material-ui/icons"
import ToolbarButton from "components/ToolbarButton/ToolbarButton"
import { useIsCompetent } from "hooks/interfaceHooks"
import { useIntl } from "react-intl"
import EmployeeReport from "./EmployeeReport"
import ReportList from "./ReportList"

export default function Report() {
  const { formatMessage } = useIntl()
  const canSeeEmployeeReport = useIsCompetent("orderEmployeeReward", "R", "W")
  const [isEmployeeView, setIsEmployeeView] = useState(false)
  const toolbarIcons = canSeeEmployeeReport ? (
    <ToolbarButton
      icon={isEmployeeView ? <ListAlt /> : <SupervisedUserCircle />}
      onClick={() => setIsEmployeeView(!isEmployeeView)}
      title={
        isEmployeeView
          ? formatMessage({
              id: "showActivities",
              defaultMessage: "Show activities",
            })
          : formatMessage({
              id: "showUsers",
              defaultMessage: "Show users",
            })
      }
    />
  ) : undefined

  return isEmployeeView ? (
    <EmployeeReport toolbarIcons={toolbarIcons} />
  ) : (
    <ReportList toolbarIcons={toolbarIcons} />
  )
}
