import React from "react"
import { useSelector } from "react-redux"
import Summary from "components/Summary/Summary"
import { useCan } from "hooks/interfaceHooks"
import Number from "../../components/Number/Number"
import SummaryMessage from "../../components/Messages/SummaryMessage"
import { selectReportSummary } from "../../selectors/reportSelector"

const ReportSummary = () => {
  const can = useCan()
  const { activities, externalCosts, guests, profit, staffCosts, turnover } =
    useSelector(selectReportSummary)
  return (
    <Summary
      summary={[
        {
          title: <Number value={activities} />,
          description: <SummaryMessage id={"activities"} />,
        },
        {
          title: <Number value={guests} />,
          description: <SummaryMessage id={"guests"} />,
        },
        ...(can("orderCosts", "R", "W")
          ? [
              {
                title: <Number value={externalCosts} currency />,
                description: <SummaryMessage id={"externalCosts"} />,
              },
              {
                title: <Number value={profit} currency />,
                description: <SummaryMessage id={"profit"} />,
              },
            ]
          : []),
        ...(can("orderPrice", "R", "W")
          ? [
              {
                title: <Number value={turnover} currency />,
                description: <SummaryMessage id={"turnover"} />,
              },
            ]
          : []),
        ...(can("orderEmployeeReward", "R", "W")
          ? [
              {
                title: <Number value={staffCosts} currency />,
                description: <SummaryMessage id={"staffCosts"} />,
              },
            ]
          : []),
      ]}
    />
  )
}

export default ReportSummary
