import React from "react"
import { useIntl } from "react-intl"

interface INumberProps {
  value: number
  currency?: boolean
}

export default function Number({ value, currency }: INumberProps) {
  const { formatNumber } = useIntl()
  return (
    <>
      {formatNumber(value, {
        ...(currency && { style: `currency`, currency: `CZK` }),
        minimumSignificantDigits: 1,
      })}
    </>
  )
}
