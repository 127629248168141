import { makeStyles } from "@material-ui/core/styles"

export default makeStyles((theme) => ({
  root: {
    listStyle: "none",
    padding: 0,
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  item: {
    padding: theme.spacing(2),
    // background: theme.palette.background.default,
    borderRadius: theme.spacing(1),
    border: `1px solid ${theme.palette.grey.A200}`,
  },
  date: {
    display: `flex`,
    flexWrap: `wrap`,
    "& > *:nth-child(2)": {
      width: `50%`,
      flexGrow: 1,
    },
  },
  subject: {
    height: theme.spacing(1),
  },
}))
