import FieldMessage from "components/Messages/FieldMessage"
import React from "react"
import { UpsaleBrief } from "types/globalTypes"
import { formatContactInfoValue } from "utils/timeUtils"
import { mapObjIndexed, values } from "ramda"
import useStyles from "./UpsaleSummaryStyles"
import { Spacer } from "../Spacer"
import { rejectEmpty } from "../../utils/transformUtils"
import { FormField } from "../../types/apiTypes"

interface UpsaleFieldsProps {
  fields: Record<FormField, string>
}

export function UpsaleFields({ fields }: UpsaleFieldsProps) {
  return (
    <Spacer separator={<br />}>
      {
        values(
          mapObjIndexed(
            (value, field) => (
              <Spacer key={field} separator=": ">
                <FieldMessage id={field} />
                {formatContactInfoValue(field, value)}
              </Spacer>
            ),
            rejectEmpty(fields)
          )
        )
      }
    </Spacer>
  )
}

interface UpsaleSummaryProps {
  summary: UpsaleBrief[]
}

export default function UpsaleSummary({ summary }: UpsaleSummaryProps) {
  const styles = useStyles()

  return (
    <ul className={styles.list}>
      {summary.map(([title, quantity, fields], i) => (
        <li key={i}>
          <div>
            {quantity}x {title}
          </div>
          <UpsaleFields fields={fields} />
        </li>
      ))}
    </ul>
  )
}
