import React from "react"
import { Voucher } from "../../types/globalTypes"
import { getReservationContents } from "../../utils/apiUtils"
import useStyles from "./ReceiptStyles"
import { bonCategoryGroupMap } from "../../utils/typeUtils"
import BonSummary from "../BonSummary/BonSummary"
import { formatDateTime } from "../../utils/timeUtils"
import IntlProvider from "../IntlProvider/IntlProvider"

interface IReceiptProps {
  voucher: Voucher
  printDate: Date
}

export default function Receipt({ voucher, printDate }: IReceiptProps) {
  const classes = useStyles()
  const bons = voucher.bonsComputed
  const barBons = bons.filter(
    ({ category }) => bonCategoryGroupMap[category] === 1
  )
  const otherBons = bons.filter(
    ({ category }) => bonCategoryGroupMap[category] !== 1
  )
  const reservationContents = getReservationContents(
    voucher.reservation?.reservation
  )

  return (
    <IntlProvider overrideLocale={"en"}>
      <div className={classes.root}>
        <p>
          Please, show this receipt to our hostess in the club and she will take
          care of you and your party.
        </p>
        <h1 className={classes.packageName}>
          {"packageOrder" in voucher
            ? voucher.packageOrder?.option.translations.EN.title
            : "Table reservation"}
        </h1>
        <p className={classes.baseInfo}>
          Customer: <strong>{voucher.name}</strong> <br />
          Number of people:{" "}
          <strong>{voucher.packageOrder?.numberOfPersons}</strong>
        </p>
        {reservationContents && (
          <p className={classes.reservations}>
            <strong>{reservationContents}</strong> -{" "}
            {voucher?.reservation?.numberOfPersons}&nbsp;persons
          </p>
        )}
        {bons.length > 0 && (
          <ul className={classes.bons}>
            {barBons.length > 0 && (
              <li className={classes.bonCategory}>at the bar</li>
            )}
            <BonSummary summary={barBons} element={"li"} />
            {otherBons.length > 0 && (
              <li className={classes.bonCategory}>at the entrance</li>
            )}
            <BonSummary summary={otherBons} element={"li"} />
          </ul>
        )}
        <p className={classes.code}>{voucher.voucherId}</p>
        {voucher.voucherUsedAt && (
          <p className={classes.date}>
            <span>Date of application:</span>
            <span>{formatDateTime(voucher.voucherUsedAt)}</span>
          </p>
        )}
        <p className={classes.date}>
          <span>Date of print:</span>
          <span>{formatDateTime(printDate)}</span>
        </p>
      </div>
    </IntlProvider>
  )
}
