import { makeStyles } from "@material-ui/core/styles"
import { green, red, orange } from "@material-ui/core/colors"

export default makeStyles(() => ({
  danger: {
    background: `${red["100"]} !important`,
    color: `${red["900"]} !important`
  },
  warning: {
    background: `${orange["100"]} !important`,
    color: `${orange["900"]} !important`
  },
  success: {
    background: `${green["100"]} !important`,
    color: `${green["900"]} !important`
  },
  spinner: {
    position: "absolute",
    left: "calc(50% - 12px)",
    top: "calc(50% - 12px)",
  },
}))
