import React from "react"
import { FormattedMessage } from "react-intl"

interface IPersonsMessageProps {
  count: number
}

export default function PersonsMessage({ count }: IPersonsMessageProps) {
  return (
    <FormattedMessage
      id={"persons"}
      values={{ count }}
      defaultMessage={"{count} persons"}
    />
  )
}
