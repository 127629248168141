import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { AccessRights, Language, UserRes } from "../types/apiTypes"
import {
  selectLanguage,
  selectLoggedUser,
  selectUserAccessRights,
} from "../selectors/interfaceSelector"
import config from "../config"
import { useFetchHook } from "./fetchHooks"
import { requestGetJWT } from "../api/interfaceRequests"
import { RequestStatus } from "../types/statusTypes"
import { readItems, storeItems } from "../utils/apiUtils"
import { PaginationProps } from "../components/DataGrid/DataGrid"

export function useLanguage(): Language {
  return useSelector(selectLanguage)
}

export function useSchemePreference(): [boolean, () => void] {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)")
  const [isDark, setIsDark] = useState<boolean>(true)
  useEffect(() => {
    setIsDark(prefersDarkMode)
  }, [prefersDarkMode])
  const toggleScheme = () => setIsDark(!isDark)
  return [isDark, toggleScheme]
}

export function useGetUsersAgency(): number {
  const { agencyId } = useSelector(selectLoggedUser) || {}
  return agencyId || config.agencyIds.web
}

export function useGetLoggedUser(): UserRes | null {
  return useSelector(selectLoggedUser)
}

export function useAuthorization(): AccessRights {
  return useSelector(selectUserAccessRights)
}

export function useCan(): (
  resource: keyof AccessRights,
  ...operations: Array<"W" | "R" | "N">
) => boolean {
  const accessRights = useAuthorization()
  return (resource, ...operations) =>
    operations.includes(accessRights[resource])
}

export function useIsCompetent(
  resource: keyof AccessRights,
  ...operations: Array<"W" | "R" | "N">
): boolean {
  const can = useCan()
  return can(resource, ...operations)
}

const defaultPagination = { page: 0, size: 10 }

export function useLocalStoragePagination(id: string): PaginationProps {
  const [{ page, size }, setPagination] = useState<{
    page: number
    size: number
  }>(() => {
    const { pagination } = readItems(["pagination"])
    if (!pagination) return defaultPagination
    return pagination[id] || defaultPagination
  })

  const onPageNumberChange = (i: number) => {
    setPagination((pagination) => ({ ...pagination, page: i }))
    const { pagination } = readItems(["pagination"])
    storeItems({
      pagination: {
        ...pagination,
        [id]: { page: i, size },
      },
    })
  }

  const onPageSizeChange = (i: number) => {
    setPagination((pagination) => ({ ...pagination, size: i }))
    const { pagination } = readItems(["pagination"])
    storeItems({
      pagination: {
        ...pagination,
        [id]: { size: i, page },
      },
    })
  }

  return {
    pageSize: size,
    pageNumber: page,
    onPageNumberChange,
    onPageSizeChange,
  }
}

export function useJWT(): [() => Promise<string>, RequestStatus, string] {
  const state = useFetchHook()
  return [
    async () => {
      try {
        state.handleRequest()
        const { message } = await requestGetJWT(state.token)
        state.handleSuccess()
        return message
        // eslint-disable-next-line
      } catch (e: any) {
        state.handleFail(e.message)
        return ""
      }
    },
    state.status,
    state.error,
  ]
}
