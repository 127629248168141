import makeStyles from "@material-ui/core/styles/makeStyles"
import { Theme } from "@material-ui/core"

interface Props {
  isCompact: boolean
}

export default makeStyles((theme: Theme) => ({
  root: {
    border: 0,
    padding: 0,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  legend: {
    width: "100%",
    paddingBottom: theme.spacing(1),
  },
  options: {
    display: `flex`,
    flexWrap: ({ isCompact }: Props) => (isCompact ? `nowrap` : `wrap`),
  },
  item: {
    flexGrow: ({ isCompact }: Props) => (isCompact ? 1 : 0),
    display: `block`,
    "& > input": {
      appearance: `none`,
      padding: 0,
      margin: 0,
      width: 0,
      height: 0,
    },
    "& > label": {
      display: `inline-block`,
      padding: theme.spacing(1.5),
      borderRadius: theme.spacing(1),
      minWidth: ({ isCompact }: Props) =>
        isCompact ? `100%` : theme.spacing(16),
      overflow: `hidden`,
      textOverflow: `ellipsis`,
      cursor: "pointer",
      textAlign: "center",
      letterSpacing: 2,
      border: `1px solid ${theme.palette.text.disabled}`,
    },
    "&:not(:last-child)": {
      marginRight: theme.spacing(2),
    },
    "& > input:checked + label": {
      borderColor: theme.palette.secondary.main,
      background: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },
  },
}))
