import { Box, makeStyles, Theme, Tooltip } from "@material-ui/core"
import IconButton from "@material-ui/core/IconButton"
import { Info as InfoIcon } from "@material-ui/icons"
import React, { ChangeEvent, useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { Map } from "immutable"
import MultipleFiledSet from "../../components/MultipleFiledSet/MultipleFiledSet"
import { Entity } from "../../components/Messages/EntityMessage"
import ComboBox from "../../components/ComboBox/ComboBox"
import Input from "../../components/Input/Input"
import Price from "../../components/Price/Price"
import Modal from "../../components/Modal/Modal"
import { ExtendedOrderReqAdmin, LiteEvent } from "../../types/globalTypes"
import {
  makePriceId,
  OptionPriceMap,
  usePricesFetch,
} from "../../hooks/priceHooks"
import { Field } from "../../components/Messages/FieldMessage"
import DateTimeInput from "../../components/DateTimeInput/DateTimeInput"
import {
  FormField, OrderEmployeeOrderReq,
  PackageUpsaleIdsWithPriorityRes,
  UpsaleReqAdmin
} from "../../types/apiTypes"
import { selectUpsaleRecords } from "../../selectors/upsaleSelector"
import {
  useGetLoggedUser,
  useIsCompetent,
  useLanguage,
} from "../../hooks/interfaceHooks"
import {
  blankContactInfo,
  blankTranslations,
  roleToOrderEmployee,
  timeFormFields,
} from "../../utils/typeUtils"
import { decodeInputName, encodeInputName, uid } from "../../utils/stringUtils"
import { ErrorType } from "../../components/Messages/ErrorMessage"
import { selectActivityRecords } from "../../selectors/activitySelector"
import { sortBy } from "ramda"
import { useAvailableTimeOptions } from "../../hooks/orderHooks"
import Select from "../../components/Select/Select"

interface IOrderUpsellingModalProps {
  onChange: (data: ExtendedOrderReqAdmin) => void
  onClose: () => void
  isOpen: boolean
  req: ExtendedOrderReqAdmin
  upsales: PackageUpsaleIdsWithPriorityRes[]
}

const useStyles = makeStyles((theme: Theme) => ({
  contentModal: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  field: {
    minWidth: "150px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
}))

export default function OrderUpsellingModal({
  onChange,
  onClose,
  isOpen,
  req,
  upsales,
}: IOrderUpsellingModalProps) {
  const classes = useStyles()
  const [prices, setPrices] = useState<OptionPriceMap>(Map)
  const language = useLanguage()
  const activity = useSelector(selectActivityRecords).get(
    req.packageId.toString()
  )
  const isUpsaleRequired = (id: number): boolean =>
    activity?.requiredUpsaleIds.flat().includes(id) ?? false
  const upsaleMap = useSelector(selectUpsaleRecords)
  const loggedUser = useGetLoggedUser()
  const canSeeAllPrices = useIsCompetent("packagePricesAgency", "R", "W")
  const agencyId = (
    canSeeAllPrices ? req?.agencyId : loggedUser?.agencyId
  ) as number
  const [fetchPrices] = usePricesFetch(agencyId, true)
  const { options } = useAvailableTimeOptions(req)
  const defaultDate: number =
    req.packageOrder?.dtStart || req.reservation?.dtStart || Date.now()

  const upsaleIdsWithPrice = useMemo(
    () =>
      [...upsales]
        .filter((i) => prices.get(makePriceId(agencyId, i.upsaleId)))
        .sort((a, b) => a.priority - b.priority)
        .map((i) => i.upsaleId),
    [prices, upsales, agencyId]
  )

  useEffect(() => {
    if (isOpen) {
      fetchPrices((map) => {
        setPrices(map)
      })
    }
    // eslint-disable-next-line
  }, [isOpen, agencyId])

  const changeRow = (data: ExtendedOrderReqAdmin) => {
    onChange(data)
  }

  const changeUpsale = <T,>(
    {
      currentTarget: { name, value },
    }: LiteEvent<T>,
    factory: (
      upsale: UpsaleReqAdmin,
      value: T,
      field: string
    ) => UpsaleReqAdmin
  ) => {
    const [field, uuid] = decodeInputName(name)
    changeRow({
      ...req,
      upsales: req.upsales.map((upsale) =>
        upsale.uuid === uuid ? factory(upsale, value, field) : upsale
      ),
    })
  }



  // const changeDate = (
  //   e: LiteEvent<number>,
  //   valueProcessor?: (value: string) => number
  // ) => {
  //
  // }

  const handleAddUpsale = () => {
    const upsaleId = upsaleIdsWithPrice[0]
    if (upsaleId)
      changeRow({
        ...req,
        upsales: [
          ...req.upsales,
          {
            quantity: 1,
            packageUpsaleId: upsaleId,
            dtStart: defaultDate,
            contactInfo: blankContactInfo,
            numberOfPersons:
              req.packageOrder?.numberOfPersons ||
              req.reservation?.numberOfPersons ||
              1,
            uuid: uid(),
            orderEmployees:
              upsaleMap
                .get(upsaleId.toString())
                ?.requiredRoles?.map(roleToOrderEmployee(defaultDate)) || [],
          },
        ],
      })
  }

  const handleRemoveUpsale = (uuid: string) => {
    changeRow({
      ...req,
      upsales: req.upsales.filter((upsale) => upsale.uuid !== uuid),
    })
  }

  const handleQuantityChange = (e: ChangeEvent<HTMLInputElement>) => {
    changeUpsale(e, (upsale) => ({
      ...upsale,
      quantity: e.target.valueAsNumber || 0,
    }))
  }

  const handleUpsaleChange = (e: LiteEvent) => {
    changeUpsale(e, (upsale, value) => ({
      ...upsale,
      orderEmployees:
        upsaleMap
          .get(value)
          ?.requiredRoles?.map(roleToOrderEmployee(defaultDate)) || [],
      packageUpsaleId: parseInt(value, 10),
    }))
  }

  const handleContactDateChange = (e: LiteEvent<number>) => {
    changeUpsale(e, (upsale, value, field) => ({
      ...upsale,
      contactInfo: {
        ...upsale.contactInfo,
        [field]: value.toString(),
      },
    }))
  }

  const handleDateInputChange = (e: LiteEvent<number>) => {
    changeUpsale(e, (upsale, value) => ({
      ...upsale,
      orderEmployees: upsale.orderEmployees.map((employee): OrderEmployeeOrderReq => ({
        ...employee,
        dtStart: value
      })),
      dtStart: value
    }))
  }

  const handleDateSelectChange = (e: LiteEvent) => {
    changeUpsale(e, (upsale, value) => {
      const dtStart = new Date(parseInt(value)).valueOf()
      return ({
        ...upsale,
        orderEmployees: upsale.orderEmployees.map((employee): OrderEmployeeOrderReq => ({
          ...employee,
          dtStart
        })),
        dtStart
      })
    })
  }

  const handleContactChange = (e: ChangeEvent<HTMLInputElement>): void => {
    changeUpsale(e, (upsale, value, field) => ({
      ...upsale,
      contactInfo: { ...upsale.contactInfo, [field]: value },
    }))
  }

  const requiredUpsalesFirst = sortBy((id: number) => !isUpsaleRequired(id))

  return (
    <Modal onClose={onClose} open={isOpen} maxWidth={"md"} fullWidth>
      <MultipleFiledSet
        entity={Entity.UPSALE}
        onCreate={handleAddUpsale}
        onRemove={handleRemoveUpsale}
      >
        {req.upsales.map(
          ({ packageUpsaleId, quantity, dtStart, contactInfo, uuid }) => {
            const {
              requiredFields = [],
              optionalFields = [],
              translations = blankTranslations,
            } = upsaleMap.get(packageUpsaleId.toString()) || {}
            const fields = new Set<FormField>([
              ...requiredFields,
              ...optionalFields,
            ])
            const timeFormFieldsSet = new Set<FormField>(timeFormFields)
            return (
              <div className={classes.contentModal} key={uuid}>
                <IconButton size={"small"} edge={"end"}>
                  <Tooltip title={translations[language].content}>
                    <InfoIcon />
                  </Tooltip>
                </IconButton>
                <Box
                  display="flex"
                  flexWrap={"wrap"}
                  alignItems={"baseline"}
                  width="100%"
                  paddingLeft={4}
                  gridGap={16}
                >
                  <ComboBox
                    name={encodeInputName("upsaleId", uuid)}
                    disableSort
                    options={requiredUpsalesFirst(upsaleIdsWithPrice).map(
                      (upsaleId) => ({
                        value: upsaleId.toString(),
                        content:
                          upsaleMap.get(upsaleId.toString())?.translations[
                            language
                          ].title || `Upsale ID ${upsaleId}`,
                        promoted: isUpsaleRequired(upsaleId),
                      })
                    )}
                    onChange={handleUpsaleChange}
                    value={packageUpsaleId.toString()}
                  />
                  <div className={classes.field}>
                    {req.packageOrder ? (
                      <Select
                        name={encodeInputName("dtStart", uuid)}
                        options={options}
                        value={dtStart.toString()}
                        messageId={Field.UPSALE_DATETIME}
                        onChange={handleDateSelectChange}
                        dense
                      />
                    ) : (
                      <DateTimeInput
                        name={encodeInputName("dtStart", uuid)}
                        messageId={Field.UPSALE_DATETIME}
                        value={dtStart}
                        onChange={handleDateInputChange}
                        dense
                      />
                    )}
                  </div>
                  <div className={classes.field}>
                    <Input
                      type={"number"}
                      min={1}
                      name={encodeInputName("upsaleQuantity", uuid)}
                      onChange={handleQuantityChange}
                      value={quantity || ""}
                      placeholder="0"
                      inputError={
                        !quantity ? { type: ErrorType.REQUIRED } : undefined
                      }
                      messageId={Field.QUANTITY}
                      dense
                    />
                  </div>
                  {[...fields].map((field, index) =>
                    timeFormFieldsSet.has(field) ? (
                      <div className={classes.field} key={field}>
                        <DateTimeInput
                          name={encodeInputName(field, uuid)}
                          messageId={field}
                          value={parseInt(contactInfo[field], 10) || null}
                          onChange={handleContactDateChange}
                          dense
                          required={index < requiredFields.length}
                        />
                      </div>
                    ) : (
                      <div className={classes.field} key={field}>
                        <Input
                          type="text"
                          name={encodeInputName(field, uuid)}
                          messageId={field}
                          value={contactInfo[field]}
                          onChange={handleContactChange}
                          dense
                          required={index < requiredFields.length}
                        />
                      </div>
                    )
                  )}
                  <Price
                    price={
                      prices.get(makePriceId(agencyId, packageUpsaleId))?.price
                    }
                    processor={(value) => value * quantity}
                  />
                </Box>
              </div>
            )
          }
        )}
      </MultipleFiledSet>
    </Modal>
  )
}
