import { createStyles, makeStyles, Theme } from "@material-ui/core"

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: "140px",
      width: `100%`,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
  })
)
