import React, { useState } from "react"
import { Navigate } from "react-router-dom"
import { CareerApplicationResAdmin } from "../../types/apiTypes"
import DataGrid from "../../components/DataGrid/DataGrid"
import config from "../../config"
import RouteMessage from "../../components/Messages/RouteMessage"
import { Field } from "../../components/Messages/FieldMessage"
import { useCareerDelete, useCareerFetch } from "../../hooks/careerHooks"
import Results from "../../components/Results/Results"
import { Entity } from "../../components/Messages/EntityMessage"
import StatusProgressBar from "../../components/StatusProgressBar/StatusProgressBar"
import { localeSort } from "../../utils/arrayUtils"
import { useLocalStoragePagination } from "../../hooks/interfaceHooks"

export default function CareerList() {
  const [careers, fetchStatus, fetchError] = useCareerFetch()
  const [deleteCareer, deleteStatus, deleteError] = useCareerDelete()
  const [redirectId, setRedirectId] = useState<string | null>(null)
  const paginationProps = useLocalStoragePagination("career")

  if (redirectId !== null)
    return <Navigate to={config.routes.careerDetail.path(redirectId)} />

  return (
    <>
      <Results
        statuses={[fetchStatus, deleteStatus]}
        errors={[fetchError, deleteError]}
        actions={["fetch", "delete"]}
        entity={Entity.CAREER}
      />
      <StatusProgressBar statuses={[fetchStatus]} />
      <DataGrid<CareerApplicationResAdmin>
        {...paginationProps}
        title={<RouteMessage id={"careerList"} />}
        onRowClick={(_, row) => setRedirectId(row ? row.id.toString() : null)}
        columns={[
          {
            title: Field.NAME,
            field: `name`,
            customSort: localeSort("name"),
          },
          {
            title: Field.EMAIL,
            field: `email`,
          },
          {
            title: Field.PHONE,
            field: `phone`,
          },
          {
            title: Field.POSITION,
            field: `position`,
          },
        ]}
        data={careers.valueSeq().toArray()}
        editable={{
          onRowDelete: ({ id }) =>
            new Promise<void>((resolve) => {
              deleteCareer(id)
              resolve()
            }),
        }}
      />
    </>
  )
}
