import React, { useEffect, useMemo } from "react"
import { useSelector } from "react-redux"
import { EditComponentProps } from "@material-table/core"
import { InputOption, LiteEvent, OrderDataGrid } from "../../types/globalTypes"
import ComboBox from "../../components/ComboBox/ComboBox"
import {
  blankOrderData,
  OPTION_ALL,
  paymentMethodsInputOptions,
} from "../../utils/typeUtils"
import { Field } from "../../components/Messages/FieldMessage"
import { selectAgencyRecords } from "../../selectors/agencySelector"
import PaymentMethodMessage from "../../components/Messages/PaymentMethodMessage"
import CellGroup from "../../components/CellGroup/CellGroup"
import Checkbox from "../../components/Checkbox/Checkbox"
import Can from "../../components/Can/Can"
import { useIsCompetent } from "../../hooks/interfaceHooks"

export function OrderMethodEditCell({
  onChange,
  rowData: { req = blankOrderData },
}: EditComponentProps<OrderDataGrid>) {
  const agencies = useSelector(selectAgencyRecords)
  const canChangeMethod = useIsCompetent("orderAdmin", "W")
  const agencyId = req?.agencyId?.toString() || ""
  const allowedMethods = useMemo<InputOption[]>(
    () =>
      canChangeMethod
        ? paymentMethodsInputOptions.filter((i) => i.value !== OPTION_ALL) // use all except ALL
        : agencies // use agency's methods
            .get(agencyId)
            ?.paymentMethods?.map((method) => ({
              value: method,
              content: <PaymentMethodMessage id={method} />,
            })) || [],
    [agencies, agencyId, canChangeMethod]
  )

  // use default agency method
  useEffect(() => {
    const method = allowedMethods.find(
      ({ value }) => value === req?.paymentMethod
    )
    if (!method && allowedMethods[0]) {
      onChange({ ...req, paymentMethod: allowedMethods[0].value })
    }
    // eslint-disable-next-line
  }, [allowedMethods, req.paymentMethod])

  const handleMethodChange = ({
    currentTarget: { value, name },
  }: LiteEvent) => {
    onChange({
      ...req,
      [name]: value,
    })
  }

  const handleFlagChange = ({
    currentTarget: { name, checked },
  }: LiteEvent) => {
    onChange({
      ...req,
      [name]: checked,
    })
  }

  return (
    <CellGroup>
      <Can resource={"orderPayment"} operations={["W"]}>
        <Checkbox
          name={`isPaidRest`}
          onChange={handleFlagChange}
          checked={req.isPaidRest}
          messageId={Field.IS_ORDER_PAID}
          size={`small`}
        />
      </Can>
      <ComboBox
        name={`paymentMethod`}
        options={allowedMethods}
        onChange={handleMethodChange}
        value={req.paymentMethod}
        size={`small`}
        messageId={Field.PAYMENT_METHOD}
      />
      <Can resource={"orderPayment"} operations={["W"]}>
        <Checkbox
          name={`isPaidDeposit`}
          onChange={handleFlagChange}
          checked={req.isPaidDeposit}
          messageId={Field.IS_DEPOSIT_PAID}
          size={`small`}
        />
      </Can>
      <ComboBox
        name={`paymentMethodDeposit`}
        options={allowedMethods}
        onChange={handleMethodChange}
        value={req.paymentMethodDeposit}
        size={`small`}
        messageId={Field.PAYMENT_METHOD}
      />
    </CellGroup>
  )
}
