type AgencyActionCreators = typeof import("../actions/agencyActions")
type UserActionCreators = typeof import("../actions/userActions")
type InterfaceActionCreators = typeof import("../actions/interfaceActions")
type ActivityActionCreators = typeof import("../actions/activityActions")
type OrderActionCreators = typeof import("../actions/orderActions")
type ProductActionCreators = typeof import("../actions/productActions")
type CareerActionCreators = typeof import("../actions/careerActions")
type InvoiceCreators = typeof import("../actions/invoiceActions")
type SessionCreators = typeof import("../actions/sessionActions")
type UpsaleCreators = typeof import("../actions/upsaleActions")
type NewsCreators = typeof import("../actions/newsActions")
type ShowCreators = typeof import("../actions/showActions")
type ActionCreators = AgencyActionCreators &
  ActivityActionCreators &
  UserActionCreators &
  InterfaceActionCreators &
  OrderActionCreators &
  ProductActionCreators &
  CareerActionCreators &
  InvoiceCreators &
  SessionCreators &
  NewsCreators &
  ShowCreators &
  UpsaleCreators

// explanation: https://warhol.io/blog/reducing-redux-boilerplate-in-typescript
/* eslint-disable @typescript-eslint/no-explicit-any */
export type AnyAction = {
  [Name in keyof ActionCreators]: ActionCreators[Name] extends (
    ...args: any[]
  ) => any
    ? ReturnType<ActionCreators[Name]>
    : never
}[keyof ActionCreators]
/* eslint-disable @typescript-eslint/no-explicit-any */

export enum ActionTypes {
  FETCH_USERS_REQUESTED = "FETCH_USERS_REQUESTED",
  FETCH_USERS_SUCCEEDED = "FETCH_USERS_SUCCEEDED",
  FETCH_USERS_FAILED = "FETCH_USERS_FAILED",
  DELETE_USER_REQUESTED = "DELETE_USER_REQUESTED",
  DELETE_USER_SUCCEEDED = "DELETE_USER_SUCCEEDED",
  DELETE_USER_FAILED = "DELETE_USER_FAILED",
  SAVE_USER_REQUESTED = "SAVE_USER_REQUESTED",
  SAVE_USER_SUCCEEDED = "SAVE_USER_SUCCEEDED",
  SAVE_USER_FAILED = "SAVE_USER_FAILED",
  LOG_USER_REQUESTED = "LOG_USER_REQUESTED",
  LOG_USER_SUCCEEDED = "LOG_USER_SUCCEEDED",
  LOG_USER_FAILED = "LOG_USER_FAILED",
  LOGOUT_USER_REQUESTED = "LOGOUT_USER_REQUESTED",
  LOGOUT_USER_SUCCEEDED = "LOGOUT_USER_SUCCEEDED",
  LOGOUT_USER_FAILED = "LOGOUT_USER_FAILED",
  RESTORE_INTERFACE_REQUESTED = "RESTORE_INTERFACE_REQUESTED",
  RESTORE_INTERFACE_SUCCEEDED = "RESTORE_INTERFACE_SUCCEEDED",
  RESTORE_INTERFACE_FAILED = "RESTORE_INTERFACE_FAILED",
  CHANGE_LOCALE_REQUESTED = "CHANGE_LOCALE_REQUESTED",
  CHANGE_LOCALE_SUCCEEDED = "CHANGE_LOCALE_SUCCEEDED",
  CHANGE_LOCALE_FAILED = "CHANGE_LOCALE_FAILED",
  FETCH_AGENCIES_REQUESTED = "FETCH_AGENCIES_REQUESTED",
  FETCH_AGENCIES_FAILED = "FETCH_AGENCIES_FAILED",
  FETCH_AGENCIES_SUCCEEDED = "FETCH_AGENCIES_SUCCEEDED",
  REMOVE_AGENCY = "REMOVE_AGENCY",
  ADD_AGENCY = "ADD_AGENCY",
  FETCH_ACTIVITIES_REQUESTED = "FETCH_ACTIVITIES_REQUESTED",
  FETCH_ACTIVITIES_FAILED = "FETCH_ACTIVITIES_FAILED",
  FETCH_ACTIVITIES_SUCCEEDED = "FETCH_ACTIVITIES_SUCCEEDED",
  SAVE_ACTIVITY_REQUESTED = "SAVE_ACTIVITY_REQUESTED",
  SAVE_ACTIVITY_SUCCEEDED = "SAVE_ACTIVITY_SUCCEEDED",
  SAVE_ACTIVITY_FAILED = "SAVE_ACTIVITY_FAILED",
  DELETE_ACTIVITY_REQUESTED = "DELETE_ACTIVITY_REQUESTED",
  DELETE_ACTIVITY_SUCCEEDED = "DELETE_ACTIVITY_SUCCEEDED",
  DELETE_ACTIVITY_FAILED = "DELETE_ACTIVITY_FAILED",
  FETCH_UPSALES_REQUESTED = "FETCH_UPSALES_REQUESTED",
  FETCH_UPSALES_FAILED = "FETCH_UPSALES_FAILED",
  FETCH_UPSALES_SUCCEEDED = "FETCH_UPSALES_SUCCEEDED",
  SAVE_UPSALE_REQUESTED = "SAVE_UPSALE_REQUESTED",
  SAVE_UPSALE_SUCCEEDED = "SAVE_UPSALE_SUCCEEDED",
  SAVE_UPSALE_FAILED = "SAVE_UPSALE_FAILED",
  DELETE_UPSALE_REQUESTED = "DELETE_UPSALE_REQUESTED",
  DELETE_UPSALE_SUCCEEDED = "DELETE_UPSALE_SUCCEEDED",
  DELETE_UPSALE_FAILED = "DELETE_UPSALE_FAILED",
  FETCH_RESERVATIONS_REQUESTED = "FETCH_RESERVATIONS_REQUESTED",
  FETCH_RESERVATIONS_FAILED = "FETCH_RESERVATIONS_FAILED",
  FETCH_RESERVATIONS_SUCCEEDED = "FETCH_RESERVATIONS_SUCCEEDED",
  REMOVE_RESERVATION = "REMOVE_RESERVATION",
  FETCH_ORDERS_REQUESTED = "FETCH_ORDERS_REQUESTED",
  FETCH_ORDERS_FAILED = "FETCH_ORDERS_FAILED",
  FETCH_ORDERS_SUCCEEDED = "FETCH_ORDERS_SUCCEEDED",
  FETCH_CONFLICTS_REQUESTED = "FETCH_CONFLICTS_REQUESTED",
  FETCH_CONFLICTS_FAILED = "FETCH_CONFLICTS_FAILED",
  FETCH_CONFLICTS_SUCCEEDED = "FETCH_CONFLICTS_SUCCEEDED",
  FETCH_EMPLOYEES_REQUESTED = "FETCH_EMPLOYEES_REQUESTED",
  FETCH_EMPLOYEES_FAILED = "FETCH_EMPLOYEES_FAILED",
  FETCH_EMPLOYEES_SUCCEEDED = "FETCH_EMPLOYEES_SUCCEEDED",
  ADD_ORDER = "ADD_ORDER",
  REMOVE_ORDER = "REMOVE_ORDER",
  ADD_EMPLOYEES = "ADD_EMPLOYEES",
  SAVE_EMPLOYEE_STATUS_REQUESTED = "SAVE_EMPLOYEE_STATUS_REQUESTED",
  SAVE_EMPLOYEE_STATUS_FAILED = "SAVE_EMPLOYEE_STATUS_FAILED",
  SAVE_EMPLOYEE_STATUS_SUCCEEDED = "SAVE_EMPLOYEE_STATUS_SUCCEEDED",
  SAVE_EMPLOYEE_REQUESTED = "SAVE_EMPLOYEE_REQUESTED",
  SAVE_EMPLOYEE_FAILED = "SAVE_EMPLOYEE_FAILED",
  SAVE_EMPLOYEE_SUCCEEDED = "SAVE_EMPLOYEE_SUCCEEDED",
  FETCH_RESOURCES_REQUESTED = "FETCH_RESOURCES_REQUESTED",
  FETCH_RESOURCES_FAILED = "FETCH_RESOURCES_FAILED",
  FETCH_RESOURCES_SUCCEEDED = "FETCH_RESOURCES_SUCCEEDED",
  FETCH_PRODUCT_REQUESTED = "FETCH_PRODUCT_REQUESTED",
  FETCH_PRODUCT_FAILED = "FETCH_PRODUCT_FAILED",
  FETCH_PRODUCT_SUCCEEDED = "FETCH_PRODUCT_SUCCEEDED",
  SAVE_PRODUCT_REQUESTED = "SAVE_PRODUCT_REQUESTED",
  SAVE_PRODUCT_SUCCEEDED = "SAVE_PRODUCT_SUCCEEDED",
  SAVE_PRODUCT_FAILED = "SAVE_PRODUCT_FAILED",
  DELETE_PRODUCT_REQUESTED = "DELETE_PRODUCT_REQUESTED",
  DELETE_PRODUCT_SUCCEEDED = "DELETE_PRODUCT_SUCCEEDED",
  DELETE_PRODUCT_FAILED = "DELETE_PRODUCT_FAILED",
  FETCH_CAREER_REQUESTED = "FETCH_CAREER_REQUESTED",
  FETCH_CAREER_SUCCEEDED = "FETCH_CAREER_SUCCEEDED",
  FETCH_CAREER_FAILED = "FETCH_CAREER_FAILED",
  DELETE_CAREER_REQUESTED = "DELETE_CAREER_REQUESTED",
  DELETE_CAREER_SUCCEEDED = "DELETE_CAREER_SUCCEEDED",
  DELETE_CAREER_FAILED = "DELETE_CAREER_FAILED",
  SAVE_CAREER_REQUESTED = "SAVE_CAREER_REQUESTED",
  SAVE_CAREER_SUCCEEDED = "SAVE_CAREER_SUCCEEDED",
  SAVE_CAREER_FAILED = "SAVE_CAREER_FAILED",
  FETCH_SECTIONS_REQUESTED = "FETCH_SECTIONS_REQUESTED",
  FETCH_SECTIONS_SUCCEEDED = "FETCH_SECTIONS_SUCCEEDED",
  FETCH_SECTIONS_FAILED = "FETCH_SECTIONS_FAILED",
  SAVE_SECTION_REQUESTED = "SAVE_SECTION_REQUESTED",
  SAVE_SECTION_SUCCEEDED = "SAVE_SECTION_SUCCEEDED",
  SAVE_SECTION_FAILED = "SAVE_SECTION_FAILED",
  FETCH_SEATING_REQUESTED = "FETCH_SEATING_REQUESTED",
  FETCH_SEATING_SUCCEEDED = "FETCH_SEATING_SUCCEEDED",
  FETCH_SEATING_FAILED = "FETCH_SEATING_FAILED",
  FETCH_PRICES_REQUESTED = "FETCH_PRICES_REQUESTED",
  FETCH_PRICES_SUCCEEDED = "FETCH_PRICES_SUCCEEDED",
  FETCH_PRICES_FAILED = "FETCH_PRICES_FAILED",
  SAVE_PRICE_REQUESTED = "SAVE_PRICE_REQUESTED",
  SAVE_PRICE_SUCCEEDED = "SAVE_PRICE_SUCCEEDED",
  SAVE_PRICE_FAILED = "SAVE_PRICE_FAILED",
  FETCH_INVOICES_REQUESTED = "FETCH_INVOICES_REQUESTED",
  FETCH_INVOICES_FAILED = "FETCH_INVOICES_FAILED",
  FETCH_INVOICES_SUCCEEDED = "FETCH_INVOICES_SUCCEEDED",
  FETCH_SESSIONS_REQUESTED = "FETCH_SESSIONS_REQUESTED",
  FETCH_SESSIONS_FAILED = "FETCH_SESSIONS_FAILED",
  FETCH_SESSIONS_SUCCEEDED = "FETCH_SESSIONS_SUCCEEDED",
  RESET_USER_STATUS = "RESET_USER_STATUS",
  RESET_AGENCY_STATUS = "RESET_AGENCY_STATUS",
  RESET_PRODUCT_STATUS = "RESET_PRODUCT_STATUS",
  RESET_ACTIVITY_STATUS = "RESET_ACTIVITY_STATUS",
  RESET_UPSALE_STATUS = "RESET_UPSALE_STATUS",
  SET_ZOOM = "SET_ZOOM",
  SET_FILTER = "SET_FILTER",
  SET_ORDER_FILTER = "SET_ORDER_FILTER",
  SET_ORDER_FILTERS = "SET_ORDER_FILTERS",
  SET_ORDER_PAGE_NUMBER = "SET_ORDER_PAGE_NUMBER",
  SET_ORDER_PAGE_SIZE = "SET_ORDER_PAGE_SIZE",
  SET_ORDER_SEARCH_QUERY = "SET_ORDER_SEARCH_QUERY",
  SET_ORDER_SORT_TYPE = "SET_ORDER_SORT_TYPE",
  SET_BACKLINK = "SET_BACKLINK",
  SEND_VOUCHER_EMAIL_REQUESTED = "SEND_VOUCHER_EMAIL_REQUESTED",
  SEND_VOUCHER_EMAIL_SUCCEEDED = "SEND_VOUCHER_EMAIL_SUCCEEDED",
  SEND_VOUCHER_EMAIL_FAILED = "SEND_VOUCHER_EMAIL_FAILED",
  RESET_ORDER_STATUS = "RESET_ORDER_STATUS",
  FETCH_NEWS_REQUESTED = "FETCH_NEWS_REQUESTED",
  FETCH_NEWS_FAILED = "FETCH_NEWS_FAILED",
  FETCH_NEWS_SUCCEEDED = "FETCH_NEWS_SUCCEEDED",
  SAVE_NEWS_REQUESTED = "SAVE_NEWS_REQUESTED",
  SAVE_NEWS_SUCCEEDED = "SAVE_NEWS_SUCCEEDED",
  SAVE_NEWS_FAILED = "SAVE_NEWS_FAILED",
  DELETE_NEWS_REQUESTED = "DELETE_NEWS_REQUESTED",
  DELETE_NEWS_SUCCEEDED = "DELETE_NEWS_SUCCEEDED",
  DELETE_NEWS_FAILED = "DELETE_NEWS_FAILED",
  RESET_NEWS_STATUS = "RESET_NEWS_STATUS",
  FETCH_SHOWS_REQUESTED = "FETCH_SHOW_REQUESTED",
  FETCH_SHOWS_FAILED = "FETCH_SHOW_FAILED",
  FETCH_SHOWS_SUCCEEDED = "FETCH_SHOW_SUCCEEDED",
  SAVE_SHOW_REQUESTED = "SAVE_SHOW_REQUESTED",
  SAVE_SHOW_SUCCEEDED = "SAVE_SHOW_SUCCEEDED",
  SAVE_SHOW_FAILED = "SAVE_SHOW_FAILED",
  DELETE_SHOW_REQUESTED = "DELETE_SHOW_REQUESTED",
  DELETE_SHOW_SUCCEEDED = "DELETE_SHOW_SUCCEEDED",
  DELETE_SHOW_FAILED = "DELETE_SHOW_FAILED",
  RESET_SHOW_STATUS = "RESET_SHOW_STATUS",
}
