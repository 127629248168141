import { makeStyles, Theme } from "@material-ui/core"

interface Props {
  hasError: boolean
}

export default makeStyles((theme: Theme) => ({
  root: {},
  wrapper: {
    background: theme.palette.background.default,
    height: 120,
    position: `relative`,
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
    borderRadius: theme.spacing(0.5),
    border: ({ hasError }: Props) =>
      `2px dashed ${
        hasError ? theme.palette.error.main : theme.palette.grey.A700
      }`,
    "&:hover": {
      border: `2px dashed ${theme.palette.grey.A200}`,
    },
  },
  prompt: {
    position: "relative",
    zIndex: 1,
    "& > *:not(:first-child)": {
      marginLeft: theme.spacing(2),
    },
  },
  input: {
    position: `absolute`,
    opacity: 0,
    color: `transparent`,
    width: "100%",
    height: "100%",
    cursor: `pointer`,
  },
  label: {
    display: "block",
    marginBottom: theme.spacing(1),
  },
}))
