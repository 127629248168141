import { Record, RecordOf, Map } from "immutable"
import { normalize, schema } from "normalizr"
import { RequestStatus } from "../types/statusTypes"
import { AgencyResAdmin } from "../types/apiTypes"

export type AgencyMap = Map<string, AgencyResAdmin>

export function normalizeAgencies(data: AgencyResAdmin[]): AgencyMap {
  const agenciesSchema = [new schema.Entity("agencies")]
  const {
    entities: { agencies },
  } = normalize<
    AgencyResAdmin,
    { [key: string]: { [key: string]: AgencyResAdmin } }
  >(data, agenciesSchema)
  return Map(agencies)
}

const defaultStatuses = {
  fetchAgencies: RequestStatus.INITIAL,
}

const makeStatuses: Record.Factory<typeof defaultStatuses> = Record(
  defaultStatuses
)

const defaultErrors = {
  fetchAgencies: "",
}

const makeErrors: Record.Factory<typeof defaultErrors> = Record(defaultErrors)

type AgencyStateType = {
  statuses: RecordOf<typeof defaultStatuses>
  errors: RecordOf<typeof defaultErrors>
  records: AgencyMap
}

const defaultAgencyState: AgencyStateType = {
  statuses: makeStatuses(),
  errors: makeErrors(),
  records: Map<string, AgencyResAdmin>(),
}

export type AgencyState = RecordOf<AgencyStateType>

export type AgencyActions = keyof typeof defaultStatuses

export const makeAgencyState: Record.Factory<AgencyStateType> = Record(
  defaultAgencyState
)
