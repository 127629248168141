import { createSelector } from "reselect"
import { isAfter, add, isBefore } from "date-fns"
import { AppState } from "../store"
import { Field } from "../components/Messages/FieldMessage"
import { selectFilters } from "./interfaceSelector"

export const selectInvoiceRecords = (state: AppState) => state.invoice.records

export function selectInvoiceStatus(state: AppState) {
  return state.invoice.statuses.fetchInvoices
}

export const selectInvoiceList = createSelector(
  selectInvoiceRecords,
  selectFilters,
  (map, filters) => {
    const [from, to] = filters[Field.DATE]
    return map
      .valueSeq()
      .toArray()
      .filter((invoice) => {
        if (filters[Field.INVOICE_STATUS].size > 0) {
          return filters[Field.INVOICE_STATUS].has(invoice.status)
        }
        return true
      })
      .filter((invoice) => {
        if (filters[Field.AGENCY].size > 0) {
          return filters[Field.AGENCY].has(invoice.agencyId.toString())
        }
        return true
      })
      .filter(
        (invoice) =>
          isAfter(new Date(invoice.createdAt), new Date(from)) &&
          isBefore(new Date(invoice.createdAt), add(new Date(to), { days: 1 }))
      )
  }
)
