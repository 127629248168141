import { Map, Record, RecordOf } from "immutable"
import { normalize, schema } from "normalizr"
import { RequestStatus } from "../types/statusTypes"
import { ProductResAdmin } from "../types/apiTypes"

export type ProductMap = Map<string, ProductResAdmin>

export function normalizeProducts(data: ProductResAdmin[]): ProductMap {
  const productSchema = [new schema.Entity("products")]
  const {
    entities: { products },
  } = normalize<
    ProductResAdmin,
    { [key: string]: { [key: string]: ProductResAdmin } }
  >(data, productSchema)
  return Map(products)
}

const defaultStatuses = {
  fetchProducts: RequestStatus.INITIAL,
  saveProduct: RequestStatus.INITIAL,
  deleteProduct: RequestStatus.INITIAL,
}

const makeStatuses: Record.Factory<typeof defaultStatuses> = Record(
  defaultStatuses
)

const defaultErrors = {
  fetchProducts: "",
  saveProduct: "",
  deleteProduct: "",
}

const makeErrors: Record.Factory<typeof defaultErrors> = Record(defaultErrors)

export type ProductsStateType = {
  statuses: RecordOf<typeof defaultStatuses>
  errors: RecordOf<typeof defaultErrors>
  records: ProductMap
}

const defaultProductState: ProductsStateType = {
  statuses: makeStatuses(),
  errors: makeErrors(),
  records: Map() as ProductMap,
}

export type ProductState = RecordOf<typeof defaultProductState>

export type ProductActions = keyof typeof defaultStatuses

export const makeDefaultProductState: Record.Factory<ProductsStateType> = Record(
  defaultProductState
)
