import React, { ChangeEvent, useEffect, useState } from "react"
import { Navigate } from "react-router-dom"
import Form from "../../components/Form/Form"
import LanguageTab from "../../components/LanguageTab/LanguageTab"
import Input from "../../components/Input/Input"
import { RequestStatus } from "../../types/statusTypes"
import {
  blankSingleTranslations,
  blankTranslations,
  isSourceVideo,
  languageList,
  simpleToFullTranslation,
} from "../../utils/typeUtils"
import Results from "../../components/Results/Results"
import config from "../../config"
import RouteMessage from "../../components/Messages/RouteMessage"
import { Field } from "../../components/Messages/FieldMessage"
import { Entity } from "../../components/Messages/EntityMessage"
import { useShowFetch, useShowSave } from "../../hooks/showHooks"
import { useLanguageState, useTranslationState } from "../../hooks/stateHooks"
import { useIdParam } from "../../hooks/routerHooks"
import MultipleFiledSet from "../../components/MultipleFiledSet/MultipleFiledSet"
import DateTimeRange from "../../components/DateTimeRange/DateTimeRange"
import { encodeInputName } from "../../utils/stringUtils"
import ImageUpload from "../../components/ImageUpload/ImageUpload"
import { LinkRes, MediaType, MediumReq } from "../../types/apiTypes"
import { requestUploadFile } from "../../api/galleryRequests"
import { useToken } from "../../hooks/fetchHooks"
import { useGalleryState } from "../../hooks/galleryHooks"
import GalleryUpload from "../../components/GalleryUpload/GalleryUpload"

export default function ShowDetail() {
  const id = useIdParam()
  const token = useToken()
  const [isSubmitted, setIsSubmitted] = useState(false)
  const { language, setLanguage } = useLanguageState()
  const [title, onTitleChange, fillTitle] = useTranslationState()
  const [content, onContentChange, fillContent] = useTranslationState()
  const [schedule, setSchedule] = useState<Array<[number, number]>>([])
  const [save, saveStatus, saveError] = useShowSave()
  const [shows, fetchStatus, fetchError] = useShowFetch(id)
  const [cropImage, setCropImage] = useState<string | File>(``)
  const [cardMedium, setCardMedium] = useState<string | File>(``)
  const galleryState = useGalleryState()
  const show = shows.get(id)

  useEffect(() => {
    if (show) {
      fillTitle(show.translations, "title")
      fillContent(show.translations, "content")
      const [cropMedium, medium, ...gallery] =
        show.gallery?.media || []
      setCropImage(cropMedium?.url)
      setCardMedium(medium?.url)
      setSchedule(show.schedules.map(({ dtStart, dtEnd }) => [dtStart, dtEnd]))
      galleryState.fillData({ default: gallery })
    }
    // fillTranslations & fillPrices are safe deps (same as setIsActive) →
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onTitleChange(e.currentTarget.value, language)
  }

  const handleContentChange = (e: ChangeEvent<HTMLInputElement>) => {
    onContentChange(e.currentTarget.value, language)
  }


  const handleScheduleCreate = () => {
    setSchedule([...schedule, [Date.now(), Date.now()]])
  }

  const handleScheduleRemove = (key: string) => {
    const index = parseInt(key)
    setSchedule(schedule.filter((_, idx) => idx !== index))
  }

  const onScheduleChange = (name: string, [from, to]: [number, number]) => {
    const index = parseInt(name)
    setSchedule(schedule.map((val, idx) => (idx === index ? [from, to] : val)))
  }

  const handleSubmit = async () => {
    setIsSubmitted(true)
    const media = await Promise.all(
      [
        { id: "cropImage", source: cropImage },
        { id: "cardMedium", source: cardMedium },
        ...galleryState.getGallery(),
      ].map(({ source, id: mediumId }) =>
        (source instanceof File
          ? requestUploadFile(token, source)
          : new Promise<LinkRes>((res) => {
              res({ link: source })
            })
        ).then(
          ({ link }): MediumReq => ({
            translations: simpleToFullTranslation(
              [
                galleryState.labels[mediumId] || blankSingleTranslations,
                galleryState.contents[mediumId] || blankSingleTranslations,
              ],
              ["title", "content"]
            ),
            type: isSourceVideo(source) ? MediaType.VIDEO : MediaType.IMAGE,
            url: link,
          })
        )
      )
    )
    save(
      {
        id: id ? parseInt(id) : 0,
        gallery: {
          media,
          translations: blankTranslations,
        },
        schedules: schedule.map(([from, to]) => ({
          dtStart: from,
          dtEnd: to,
        })),
        translations: simpleToFullTranslation(
          [title, content],
          ["title", "content"]
        ),
      },
      id
    )
  }

  return (
    <>
      {!id && saveStatus === RequestStatus.SUCCEEDED && (
        <Navigate to={config.routes.showList.path()} />
      )}
      <Results
        statuses={[saveStatus, fetchStatus]}
        errors={[saveError, fetchError]}
        actions={["save", "fetch"]}
        entity={Entity.SHOW}
      />
      <Form
        title={<RouteMessage id={"showDetail"} />}
        onSubmit={handleSubmit}
        statuses={[saveStatus, fetchStatus]}
        toolbar={
          <LanguageTab
            language={language}
            onChange={setLanguage}
            notifications={languageList.filter((lang) => !title[lang])}
          />
        }
        useGenericCta
      >
        <Input
          messageId={Field.TITLE}
          name={`title`}
          onChange={handleTitleChange}
          type={`text`}
          value={title[language]}
          autoFocus
          showRequiredWarning={isSubmitted}
        />
        <Input
          messageId={Field.CONTENT}
          name={`content`}
          onChange={handleContentChange}
          type={`text`}
          value={content[language]}
          multiline
        />
        <ImageUpload
          source={cropImage}
          name={`cropImage`}
          onUpload={setCropImage}
          onDelete={() => setCropImage(``)}
          messageId={Field.CROP_IMAGE}
          showRequiredWarning={isSubmitted}
        />
        <ImageUpload
          source={cardMedium}
          name={`cardMedium`}
          onUpload={setCardMedium}
          onDelete={() => setCardMedium(``)}
          messageId={Field.MAIN_IMAGE_VIDEO}
          showRequiredWarning={isSubmitted}
          allowURL
        />
        <GalleryUpload
          name={`gallery`}
          messageId={Field.GALLERY}
          language={language}
          gallery={galleryState.getGallery()}
          labels={galleryState.labels}
          onUpload={(files) => galleryState.handleUpload(files)}
          onSort={(prev, next) => galleryState.handleSort(prev, next)}
          onDelete={(itemId) => galleryState.handleDelete(itemId)}
          onLabelChange={galleryState.handleLabelChange}
          allowURL
        />
        <MultipleFiledSet
          entity={Entity.TIME}
          onCreate={handleScheduleCreate}
          onRemove={handleScheduleRemove}
          messageId={Field.TIME}
        >
          {schedule.map(([dtStart, dtEnd], index) => (
            <DateTimeRange
              key={index}
              value={[dtStart, dtEnd]}
              name={encodeInputName(index)}
              onChange={onScheduleChange}
            />
          ))}
        </MultipleFiledSet>
      </Form>
    </>
  )
}
