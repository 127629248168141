import { makeStyles, Theme } from "@material-ui/core"

const useInlineFieldsetStyles = makeStyles<Theme, { childrenNumber: number }>(
  (theme: Theme) => ({
    root: {
      border: 0,
      padding: 0,

      "& > legend": {
        display: "block",
        width: "100%",
      },
    },
    container: {
      width: "100%",
      display: "inline-flex",
      gap: `0 ${theme.spacing(2)}px`,
      flexWrap: "wrap",

      "& > *": {
        flexBasis: (props) => `calc(${100 / props.childrenNumber}% - ${theme.spacing(4)}px)`,
        flexGrow: 1,
      },
    },
  })
)

export default useInlineFieldsetStyles
