import { ActionTypes } from "../types/actionTypes"
import { PackageReq, PackageResAdmin } from "../types/apiTypes"
import {
  ActivityActions,
  ActivityMap,
  ResourceMap,
} from "../models/activityModel"

export const fetchActivities = (ids?: number[]) =>
  ({
    type: ActionTypes.FETCH_ACTIVITIES_REQUESTED,
    payload: {
      ids,
    },
  } as const)

export const fetchActivitiesFailed = (message: string) =>
  ({
    type: ActionTypes.FETCH_ACTIVITIES_FAILED,
    payload: {
      message,
    },
  } as const)

export const fetchActivitiesSucceeded = (activities: ActivityMap) =>
  ({
    type: ActionTypes.FETCH_ACTIVITIES_SUCCEEDED,
    payload: {
      activities,
    },
  } as const)

export const fetchResources = (ids?: number[]) =>
  ({
    type: ActionTypes.FETCH_RESOURCES_REQUESTED,
    payload: {
      ids,
    },
  } as const)

export const fetchResourcesFailed = (message: string) =>
  ({
    type: ActionTypes.FETCH_RESOURCES_FAILED,
    payload: {
      message,
    },
  } as const)

export const fetchResourcesSucceeded = (resources: ResourceMap) =>
  ({
    type: ActionTypes.FETCH_RESOURCES_SUCCEEDED,
    payload: {
      resources,
    },
  } as const)

export const saveActivity = (formData: PackageReq, id?: number) =>
  ({
    type: ActionTypes.SAVE_ACTIVITY_REQUESTED,
    payload: {
      id,
      formData,
    },
  } as const)

export const saveActivityFailed = (message: string) =>
  ({
    type: ActionTypes.SAVE_ACTIVITY_FAILED,
    payload: {
      message,
    },
  } as const)

export const saveActivitySucceeded = (activity: PackageResAdmin) =>
  ({
    type: ActionTypes.SAVE_ACTIVITY_SUCCEEDED,
    payload: {
      activity,
    },
  } as const)

export const deleteActivity = (id: number) =>
  ({
    type: ActionTypes.DELETE_ACTIVITY_REQUESTED,
    payload: { id },
  } as const)

export const deleteActivityFailed = (message: string) =>
  ({
    type: ActionTypes.DELETE_ACTIVITY_FAILED,
    payload: {
      message,
    },
  } as const)

export const deleteActivitySucceeded = (id: number) =>
  ({
    type: ActionTypes.DELETE_ACTIVITY_SUCCEEDED,
    payload: { id },
  } as const)

export const resetActivityStatus = (key: ActivityActions) =>
  ({
    type: ActionTypes.RESET_ACTIVITY_STATUS,
    payload: { key },
  } as const)
