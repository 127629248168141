import { FormattedMessage, useIntl } from "react-intl"
import AddBox from "@material-ui/icons/AddBox"
import Calendar from "@material-ui/icons/Event"
import React, { ChangeEvent, memo, useState } from "react"
import { useSelector } from "react-redux"
import { Navigate } from "react-router-dom"
import Agenda from "../../components/Agenda/Agenda"
import Button from "../../components/Button/Button"
import DataGrid from "../../components/DataGrid/DataGrid"
import Modal from "../../components/Modal/Modal"
import config from "../../config"
import { selectUserGridData } from "../../selectors/userSelector"
import { UserRes } from "../../types/apiTypes"
import Results from "../../components/Results/Results"
import RouteMessage from "../../components/Messages/RouteMessage"
import { Field } from "../../components/Messages/FieldMessage"
import { Entity } from "../../components/Messages/EntityMessage"
import { useUserDelete, useUserFetch } from "../../hooks/userHooks"
import UserFilter from "../../components/UserFilter/UserFilter"
import { useAgencyFetch } from "../../hooks/agencyHooks"
import StatusProgressBar from "../../components/StatusProgressBar/StatusProgressBar"
import { isEmployeeRole } from "../../utils/apiUtils"
import {
  useIsCompetent,
  useLocalStoragePagination,
} from "../../hooks/interfaceHooks"
import { localeSort } from "../../utils/arrayUtils"

function UserList() {
  const { formatMessage } = useIntl()
  const canEditUsers = useIsCompetent("user", "W")
  const canChangeAvailability = useIsCompetent("userAvailability", "W")
  const [redirectId, setRedirectId] = useState<null | string>(null)
  const [userId, setUserId] = useState<null | number>(null)
  const [, fetchStatus, fetchError] = useUserFetch()
  const [agencyMap] = useAgencyFetch()
  const userGridData = useSelector(selectUserGridData)
  const [deleteUser, deleteStatus, deleteError] = useUserDelete()
  const paginationProps = useLocalStoragePagination("user")

  const handleAvailabilityClick = (
    e: ChangeEvent,
    data: UserRes | UserRes[]
  ) => {
    const { id } = Array.isArray(data) ? data[0] : data
    setUserId(id)
  }

  const handleModalClose = () => {
    setUserId(null)
  }

  const handleRowClick = (event?: React.MouseEvent, row?: UserRes) =>
    setRedirectId(row?.id.toString() || null)

  if (redirectId !== null)
    return <Navigate to={config.routes.userDetail.path(redirectId)} />

  return (
    <>
      <Results
        statuses={[deleteStatus, fetchStatus]}
        errors={[deleteError, fetchError]}
        actions={[`delete`, "fetch"]}
        entity={Entity.USER}
      />
      <StatusProgressBar statuses={[fetchStatus, deleteStatus]} />
      <DataGrid<UserRes>
        {...paginationProps}
        title={<RouteMessage id={"userList"} />}
        onRowClick={canEditUsers ? handleRowClick : undefined}
        columns={[
          {
            title: Field.NICKNAME,
            field: "nickname",
            exportField: "nickname",
            defaultSort: "asc",
            customSort: localeSort("nickname"),
          },
          {
            title: Field.NAME,
            field: "fullName",
            exportField: "fullName",
            customSort: localeSort("fullName"),
          },
          { title: Field.EMAIL, field: "email", exportField: "email" },
          { title: Field.PHONE, field: "phone", exportField: "phone" },
          {
            title: Field.AGENCY,
            field: "agencyId",
            render: ({ agencyId }: UserRes) =>
              (agencyId && agencyMap.get(agencyId.toString())?.title) || "",
            customExport: ({ agencyId }: UserRes) =>
              (agencyId && agencyMap.get(agencyId.toString())?.title) || "",
          },
          {
            title: Field.ROLE,
            field: "roles",
            render: ({ roles }: UserRes) => roles.join(", "),
            customExport: ({ roles }: UserRes) => roles.join(" | "),
          },
        ]}
        data={userGridData}
        cta={
          canEditUsers ? (
            <Button
              onClick={() => setRedirectId(``)}
              variant="contained"
              color={`primary`}
              startIcon={<AddBox />}
            >
              <FormattedMessage
                id={`createUser`}
                defaultMessage={`Create user`}
              />
            </Button>
          ) : null
        }
        actions={[
          (row) => ({
            icon: () => <Calendar />,
            tooltip: formatMessage({
              id: "availability",
              defaultMessage: "Availability",
            }),
            onClick: handleAvailabilityClick,
            disabled: !row.roles.some(isEmployeeRole) || !canChangeAvailability,
          }),
        ]}
        customFilter={<UserFilter />}
        editable={{
          isDeleteHidden: () => !canEditUsers,
          onRowDelete: ({ id }) =>
            new Promise<void>((resolve) => {
              deleteUser(id)
              resolve()
            }),
        }}
      />
      <Modal onClose={handleModalClose} open={!!userId}>
        {userId && <Agenda userId={userId} />}
      </Modal>
    </>
  )
}

export default memo(UserList)
