import { ActionTypes, AnyAction } from "../types/actionTypes"
import { makeCareerSate } from "../models/careerModel"
import { RequestStatus } from "../types/statusTypes"

const initialState = makeCareerSate()

export default function careerReducer(
  state = initialState,
  action: AnyAction | null = null
) {
  switch (action?.type) {
    // logout
    case ActionTypes.LOGOUT_USER_SUCCEEDED:
      return initialState

    case ActionTypes.FETCH_CAREER_REQUESTED: {
      return state.set(
        "statuses",
        state.statuses.set("fetchCareer", RequestStatus.REQUESTED)
      )
    }

    case ActionTypes.FETCH_CAREER_SUCCEEDED: {
      return state
        .set(
          "statuses",
          state.statuses.set("fetchCareer", RequestStatus.SUCCEEDED)
        )
        .set("records", action.payload.careers)
    }

    case ActionTypes.FETCH_CAREER_FAILED: {
      return state
        .set(
          "statuses",
          state.statuses.set("fetchCareer", RequestStatus.FAILED)
        )
        .set("errors", state.errors.set("fetchCareer", action.payload.message))
    }

    case ActionTypes.SAVE_CAREER_REQUESTED: {
      return state.set(
        "statuses",
        state.statuses.set("saveCareer", RequestStatus.REQUESTED)
      )
    }

    case ActionTypes.SAVE_CAREER_FAILED: {
      return state
        .set("statuses", state.statuses.set("saveCareer", RequestStatus.FAILED))
        .set("errors", state.errors.set("saveCareer", action.payload.message))
    }

    case ActionTypes.SAVE_CAREER_SUCCEEDED: {
      return state
        .set(
          "statuses",
          state.statuses.set("saveCareer", RequestStatus.SUCCEEDED)
        )
        .set(
          "records",
          state.records.set(
            action.payload.career.id.toString(),
            action.payload.career
          )
        )
    }

    case ActionTypes.DELETE_CAREER_REQUESTED: {
      return state.set(
        "statuses",
        state.statuses.set("deleteCareer", RequestStatus.REQUESTED)
      )
    }

    case ActionTypes.DELETE_CAREER_FAILED: {
      return state
        .set(
          "statuses",
          state.statuses.set("deleteCareer", RequestStatus.FAILED)
        )
        .set("errors", state.errors.set("deleteCareer", action.payload.message))
    }

    case ActionTypes.DELETE_CAREER_SUCCEEDED: {
      return state
        .set(
          "statuses",
          state.statuses.set("deleteCareer", RequestStatus.SUCCEEDED)
        )
        .set("records", state.records.remove(action.payload.id.toString()))
    }

    default: {
      return state
    }
  }
}
