import {
  makeDefaultProductState,
  ProductActions,
  ProductState,
} from "../models/productModel"
import { ActionTypes, AnyAction } from "../types/actionTypes"
import { RequestStatus as Status, RequestStatus } from "../types/statusTypes"

const initialSate: ProductState = makeDefaultProductState()

export default function productReducer(
  state = initialSate,
  action: AnyAction | null = null
): ProductState {
  // helpers
  const setStatus = (key: ProductActions, status: Status) =>
    state.set("statuses", state.statuses.set(key, status))
  const setError = (key: ProductActions, error: string) =>
    state
      .set("errors", state.errors.set(key, error))
      .set("statuses", state.statuses.set(key, RequestStatus.FAILED))

  switch (action?.type) {
    // logout
    case ActionTypes.LOGOUT_USER_SUCCEEDED:
      return initialSate

    // reset status to default and error message to empty string
    case ActionTypes.RESET_PRODUCT_STATUS:
      return setStatus(action.payload.key, RequestStatus.INITIAL).set(
        "errors",
        state.errors.set(action.payload.key, "")
      )

    // fetch
    case ActionTypes.FETCH_PRODUCT_REQUESTED:
      return setStatus("fetchProducts", RequestStatus.REQUESTED)

    case ActionTypes.FETCH_PRODUCT_SUCCEEDED:
      return setStatus("fetchProducts", RequestStatus.SUCCEEDED).set(
        "records",
        action.payload.products
      )

    case ActionTypes.FETCH_PRODUCT_FAILED:
      return setError("fetchProducts", action.payload.message)

    // save
    case ActionTypes.SAVE_PRODUCT_REQUESTED:
      return setStatus("saveProduct", RequestStatus.REQUESTED)

    case ActionTypes.SAVE_PRODUCT_SUCCEEDED:
      return setStatus("saveProduct", RequestStatus.SUCCEEDED).set(
        "records",
        state.records.set(
          action.payload.product.id.toString(),
          action.payload.product
        )
      )

    case ActionTypes.SAVE_PRODUCT_FAILED:
      return setError("saveProduct", action.payload.message)

    // delete
    case ActionTypes.DELETE_PRODUCT_REQUESTED:
      return setStatus("deleteProduct", RequestStatus.REQUESTED)

    case ActionTypes.DELETE_PRODUCT_SUCCEEDED:
      return setStatus("deleteProduct", RequestStatus.SUCCEEDED).set(
        "records",
        state.records.remove(action.payload.id.toString())
      )

    case ActionTypes.DELETE_PRODUCT_FAILED:
      return setError("deleteProduct", action.payload.message)

    default:
      return state
  }
}
