import React from "react"
import BonSummary from "../../components/BonSummary/BonSummary"
import { ReservationDataGrid } from "../../types/globalTypes"

export default function ReservationBonCell({
  reservation,
  bonSum
}: ReservationDataGrid) {
  if (!reservation) return null
  return <BonSummary summary={bonSum} />
}
