import { Record, RecordOf, Map } from "immutable"
import { RequestStatus } from "../types/statusTypes"
import { PackageUpsaleResAdmin } from "../types/apiTypes"

export type UpsaleMap = Map<string, PackageUpsaleResAdmin>

const defaultStatuses = {
  fetchUpsales: RequestStatus.INITIAL,
  saveUpsale: RequestStatus.INITIAL,
  deleteUpsale: RequestStatus.INITIAL,
}

const makeStatuses: Record.Factory<typeof defaultStatuses> = Record(
  defaultStatuses
)

const defaultErrors = {
  fetchUpsales: "",
  saveUpsale: "",
  deleteUpsale: "",
}

const makeErrors: Record.Factory<typeof defaultErrors> = Record(defaultErrors)

type UpsaleStateType = {
  statuses: RecordOf<typeof defaultStatuses>
  errors: RecordOf<typeof defaultErrors>
  records: UpsaleMap
}

const defaultUpsaleState: UpsaleStateType = {
  statuses: makeStatuses(),
  errors: makeErrors(),
  records: Map() as UpsaleMap,
}

export type UpsaleState = RecordOf<UpsaleStateType>

export type UpsaleActions = keyof typeof defaultStatuses

export const makeUpsaleState: Record.Factory<UpsaleStateType> = Record(
  defaultUpsaleState
)
