import React from "react"
import { FormattedMessage } from "react-intl"
import { Source } from "../../types/filterTypes"

interface ISourceMessageProps {
  id: Source
}

export default function SourceMessage({ id }: ISourceMessageProps) {
  switch (id) {
    case Source.AGENCY:
      return <FormattedMessage id={`agency`} defaultMessage={`Agency`} />
    case Source.WEB:
      return <FormattedMessage id={`web`} defaultMessage={`Web`} />
    default:
      return null
  }
}
