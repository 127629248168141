import React from "react"
import CircularProgress from "@material-ui/core/CircularProgress"
import { ButtonProps } from "@material-ui/core/Button"
import useStyles from "./LoadingButtonStyle"
import Button from "../Button/Button"

interface Props {
  isLoading: boolean
}

export default function LoadingButton({
  isLoading,
  children,
  disabled,
  ...rest
}: ButtonProps & Props) {
  const classes = useStyles({ isLoading })
  return (
    <Button {...rest} disabled={isLoading || disabled} className={classes.root}>
      {children}
      {isLoading && <CircularProgress size={24} className={classes.spinner} />}
    </Button>
  )
}
