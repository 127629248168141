import { IdRes, TagReq, TagResAdmin } from "../types/apiTypes"
import performRequest, { getIdParam } from "../utils/apiUtils"
import { HttpMethods } from "../types/globalTypes"

export async function requestSaveTag(
  basicAuth: string,
  body: TagReq,
  entity: "product" | "gallery" | "package" | "upsale",
  id?: number
): Promise<IdRes<number>> {
  return performRequest(
    {
      endpoint: `${entity}/tag${getIdParam(id)}`,
      auth: basicAuth,
      method: id ? HttpMethods.PUT : HttpMethods.POST,
      body,
    },
    true
  )
}
export async function requestDeleteTag(
  basicAuth: string,
  entity: "product" | "gallery" | "package" | "upsale",
  id: number
): Promise<IdRes<number>> {
  return performRequest(
    {
      endpoint: `${entity}/tag${getIdParam(id)}`,
      auth: basicAuth,
      method: HttpMethods.DELETE,
    },
    true
  )
}

export async function requestGetTags(
  basicAuth: string,
  entity: "product" | "gallery" | "package" | "upsale",
  category?: string
): Promise<TagResAdmin[]> {
  return performRequest(
    {
      endpoint: `${entity}/tag${category ? `?category=${category}` : ``}`,
      auth: basicAuth,
      method: HttpMethods.GET,
    },
    true
  )
}

export async function requestGetTag(
  basicAuth: string,
  entity: "product" | "gallery" | "package" | "upsale",
  id: number
): Promise<TagResAdmin> {
  return performRequest(
    {
      endpoint: `${entity}/tag${getIdParam(id)}`,
      auth: basicAuth,
      method: HttpMethods.GET,
    },
    true
  )
}
