import React, { useState } from "react"
import { FormattedMessage } from "react-intl"
import { Grid } from "@material-ui/core"
import { LiteEvent } from "../../types/globalTypes"
import MinuteInput from "../MinuteInput/MinuteInput"
import { Field } from "../Messages/FieldMessage"
import { decodeInputName, encodeInputName } from "../../utils/stringUtils"
import { last } from "ramda"


interface IMinuteRangeProps {
  value: [number, number]
  name: string
  onChange: (e: LiteEvent) => void
}

const FIELD_FROM = "from"
const FIELD_TO = "to"

export default function MinuteRange({
  value,
  onChange,
  name,
}: IMinuteRangeProps) {
  const [[fromError, toError], setErrors] = useState([false, false])
  const [from, to] = value

  const handleChange = (e: LiteEvent) => {
    const { name: targetName, value: targetValue } = e.currentTarget
    const field = last(decodeInputName(targetName))
    if (field === FIELD_FROM && parseInt(targetValue, 10) >= to) {
      setErrors([true, false])
    } else if (field === FIELD_TO && parseInt(targetValue, 10) <= from) {
      setErrors([false, true])
    } else {
      setErrors([false, false])
      onChange(e)
    }
  }

  const helperText = (
    <FormattedMessage
      id={`invalidTimeRange`}
      defaultMessage={`From must be lower than To`}
    />
  )

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <MinuteInput
          value={from}
          name={encodeInputName(name, FIELD_FROM)}
          messageId={Field.FROM}
          onChange={handleChange}
          error={fromError}
          helperText={fromError && helperText}
        />
      </Grid>
      <Grid item xs={6}>
        <MinuteInput
          value={to}
          name={encodeInputName(name, FIELD_TO)}
          messageId={Field.TO}
          onChange={handleChange}
          error={toError}
          helperText={toError && helperText}
        />
      </Grid>
    </Grid>
  )
}
