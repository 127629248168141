import { useSelector } from "react-redux"
import React, { useState, useEffect } from "react"
import Settings from "@material-ui/icons/Settings"
import { FormattedMessage } from "react-intl"
import { Navigate } from "react-router-dom"
import DataGrid from "../../components/DataGrid/DataGrid"
import { PackageUpsaleResAdmin } from "../../types/apiTypes"
import config from "../../config"
import Results from "../../components/Results/Results"
import { useUpsaleFetch, useUpsaleSave } from "../../hooks/upsaleHooks"
import RouteMessage from "../../components/Messages/RouteMessage"
import { Field } from "../../components/Messages/FieldMessage"
import { Entity } from "../../components/Messages/EntityMessage"
import Button from "../../components/Button/Button"
import Modal from "../../components/Modal/Modal"
import EntityList from "../../components/EntityList/EntityList"
import {
  useTagDelete,
  useTagFetch,
  useTagSave,
  useTagState,
} from "../../hooks/tagHooks"
import { useLanguageState } from "../../hooks/stateHooks"
import LanguageTab from "../../components/LanguageTab/LanguageTab"
import Input from "../../components/Input/Input"
import MultiSelect from "../../components/MultiSelect/MultiSelect"
import Form from "../../components/Form/Form"
import { arrayDiff } from "../../utils/arrayUtils"
import StatusProgressBar from "../../components/StatusProgressBar/StatusProgressBar"
import BoolMessage from "../../components/Messages/BoolMessage"
import {
  selectUpsaleInputOptions,
  selectUpsellingDataGrid,
} from "../../selectors/upsaleSelector"
import { AppState } from "../../store"
import {
  useLanguage,
  useLocalStoragePagination,
} from "../../hooks/interfaceHooks"

export default function UpsellingList() {
  const [redirectId, setRedirectId] = useState<string | null>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const tagState = useTagState()
  const [remoteTags, setRemoteTags] = useState<string[]>([])
  const { language, setLanguage } = useLanguageState()
  const locale = useLanguage()
  const [, saveStatus, saveError] = useUpsaleSave()
  const [, fetchStatus, fetchError] = useUpsaleFetch()
  const upsaleDataGrid = useSelector(selectUpsellingDataGrid)
  const upsaleInputOptions = useSelector((state: AppState) =>
    selectUpsaleInputOptions(state, true, false)
  )
  const [saveTags, saveTagsStatus, saveTagsError] = useTagSave("upsale")
  const [fetchTags, fetchTagsStatus, fetchTagsError] = useTagFetch()
  const [deleteTag, deleteTagStatus, deleteTagError] = useTagDelete("upsale")
  const paginationProps = useLocalStoragePagination("upselling")

  const message = (
    <FormattedMessage
      id={"manageCategories"}
      defaultMessage={"Manage categories"}
    />
  )

  useEffect(() => {
    fetchTags("upsale", (response) => {
      const tagIds = response.map((tag) => tag.id.toString())
      tagState.fillWithData(
        tagIds,
        response.map((tag) => tag.translations),
        response.map((tag) => tag.entityIds.map((id) => id.toString()))
      )
      setRemoteTags(tagIds)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = async () => {
    await saveTags(tagState.tags, tagState.entities, tagState.titles)
    await Promise.all(
      arrayDiff(remoteTags, tagState.tags).map((id) =>
        deleteTag(parseFloat(id))
      )
    )
    setIsModalOpen(false)
  }

  return (
    <>
      <Results
        statuses={[saveStatus, fetchStatus]}
        errors={[saveError, fetchError]}
        actions={["save", "fetch"]}
        entity={Entity.UPSALE}
      />
      <Results
        statuses={[saveTagsStatus, fetchTagsStatus, deleteTagStatus]}
        errors={[saveTagsError, fetchTagsError, deleteTagError]}
        actions={["save", "fetch", "delete"]}
        entity={Entity.TAG}
      />
      {redirectId !== null && (
        <Navigate to={config.routes.upsellingDetail.path(redirectId)} />
      )}
      <StatusProgressBar statuses={[fetchStatus]} />
      <DataGrid<PackageUpsaleResAdmin>
        {...paginationProps}
        title={<RouteMessage id={"upsellingList"} />}
        data={upsaleDataGrid}
        onRowClick={(_, row) => setRedirectId(row ? row.id.toString() : null)}
        columns={[
          {
            title: Field.TITLE,
            field: `translations.${locale}.title`,
            defaultSort: "asc",
            customSort: (a, b) =>
              a.translations[locale].title.localeCompare(
                b.translations[locale].title
              ),
          },
          {
            title: Field.IS_ACTIVE,
            field: `isActive`,
            render: ({ isActive }: PackageUpsaleResAdmin) => (
              <BoolMessage value={isActive} />
            ),
          },
        ]}
        cta={
          <Button
            onClick={() => setIsModalOpen(true)}
            variant="contained"
            color={`primary`}
            startIcon={<Settings />}
          >
            {message}
          </Button>
        }
      />
      <Modal open={isModalOpen} fullWidth onClose={() => setIsModalOpen(false)}>
        <Form
          onSubmit={handleSubmit}
          title={message}
          toolbar={<LanguageTab language={language} onChange={setLanguage} />}
          statuses={[saveTagsStatus, fetchTagsStatus]}
          useGenericCta
        >
          <EntityList
            entityPairs={tagState.tags.map((tag) => [
              tag,
              tagState.titles[tag]?.[language] || "",
            ])}
            onEntityChange={tagState.handleActiveTagChange}
            onSort={tagState.handleTagSort}
            onCreate={tagState.handleTagCreate}
            onDelete={tagState.handleTagDelete}
            activeEntity={tagState.activeTag}
          >
            <Input
              messageId={Field.TITLE}
              value={tagState.titles[tagState.activeTag]?.[language]}
              name={`title-${tagState.activeTag}`}
              onChange={(e) =>
                tagState.handleTagTitleChange(e.currentTarget.value, language)
              }
            />
            <p>{JSON.stringify(tagState.entities[tagState.activeTag])}</p>
            <MultiSelect
              onChange={tagState.handleEntitiesChange}
              values={tagState.entities[tagState.activeTag] || []}
              options={upsaleInputOptions}
              messageId={Field.UPSALES}
              name={`tagEntities`}
            />
          </EntityList>
        </Form>
      </Modal>
    </>
  )
}
