import { makeStyles, Theme } from "@material-ui/core"

export default makeStyles((theme: Theme) => ({
  root: {
    paddingRight: theme.spacing(15),
    paddingLeft: theme.spacing(3.5),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    "& + &": {
      marginLeft: theme.spacing(1.5),
    },
  },
}))
