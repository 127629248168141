import { Record, RecordOf, Map } from "immutable"
import { normalize, schema } from "normalizr"
import { CareerApplicationResAdmin } from "../types/apiTypes"
import { RequestStatus } from "../types/statusTypes"

export type CareerMap = Map<string, CareerApplicationResAdmin>

export function normalizeCareers(data: CareerApplicationResAdmin[]): CareerMap {
  const careerSchema = [new schema.Entity("career")]
  const {
    entities: { career },
  } = normalize<
    CareerApplicationResAdmin,
    { [key: string]: { [key: string]: CareerApplicationResAdmin } }
  >(data, careerSchema)
  return Map(career)
}

const defaultStatuses = {
  fetchCareer: RequestStatus.INITIAL,
  saveCareer: RequestStatus.INITIAL,
  deleteCareer: RequestStatus.INITIAL,
}

const makeStatuses: Record.Factory<typeof defaultStatuses> = Record(
  defaultStatuses
)

const defaultErrors = {
  fetchCareer: "",
  saveCareer: "",
  deleteCareer: "",
}

export const makeErrors: Record.Factory<typeof defaultErrors> = Record(
  defaultErrors
)

type CareersStateType = {
  statuses: RecordOf<typeof defaultStatuses>
  errors: RecordOf<typeof defaultErrors>
  records: CareerMap
}

const defaultSate: CareersStateType = {
  statuses: makeStatuses(),
  errors: makeErrors(),
  records: Map<string, CareerApplicationResAdmin>(),
}

export type CareerState = RecordOf<CareersStateType>

export const makeCareerSate: Record.Factory<CareersStateType> = Record(
  defaultSate
)
