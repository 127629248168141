import React, { useEffect } from "react"
import { Outlet, useNavigate } from "react-router-dom"
import Paper from "@material-ui/core/Paper"
import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"
import useStyles from "./PublicLayoutStyle"
import Copyright from "../Copyright/Copyright"
import { useDispatch, useSelector } from "react-redux"
import {
  selectBacklink,
} from "../../selectors/interfaceSelector"
import config from "../../config"
import { setBacklink } from "../../actions/interfaceActions"
import { useGetLoggedUser } from "../../hooks/interfaceHooks"

export default function PublicLayout() {
  const classes = useStyles()
  const user = useGetLoggedUser()
  const dispatch = useDispatch()
  const backlink = useSelector(selectBacklink)
  const navigate = useNavigate()

  useEffect(() => {
    if (user) {
      if (backlink) {
        dispatch(setBacklink(null))
        navigate(backlink)
      } else {
        navigate(config.routes.dashboard.path())
      }
    }
  }, [dispatch, user, backlink, navigate])

  return (
    <main className={classes.root} data-testid={"public-layout"}>
      <Container maxWidth="sm">
        <Paper>
          <Box paddingX={5} paddingY={3}>
            <Outlet />
          </Box>
        </Paper>
        <Box mt={1}>
          <Copyright />
        </Box>
      </Container>
    </main>
  )
}
