import React from "react"
import { FormattedMessage } from "react-intl"
import { BonCategory } from "../../types/apiTypes"

interface IBonCategoryMessageProps {
  id: BonCategory
}

export default function BonCategoryMessage({ id }: IBonCategoryMessageProps) {
  switch (id) {
    case BonCategory.BOTTLE_ALCOHOL_0_7_MOET_BRUT:
      return (
        <FormattedMessage
          id={`BOTTLE_ALCOHOL_0_7_MOET_BRUT`}
          defaultMessage={`BOTTLE_ALCOHOL_0_7_MOET_BRUT`}
        />
      )
    case BonCategory.OPEN_CONSUMATION_1000:
      return (
        <FormattedMessage
          id={`OPEN_CONSUMATION_1000`}
          defaultMessage={`OPEN_CONSUMATION_1000`}
        />
      )

    case BonCategory.BEER_CAN:
      return <FormattedMessage id={`BEER_CAN`} defaultMessage={`BEER_CAN`} />
    case BonCategory.SHIRT_FRIENDS:
      return (
        <FormattedMessage
          id={`SHIRT_FRIENDS`}
          defaultMessage={`SHIRT_FRIENDS`}
        />
      )
    case BonCategory.SHIRT_GROOM:
      return (
        <FormattedMessage id={`SHIRT_GROOM`} defaultMessage={`SHIRT_GROOM`} />
      )
    case BonCategory.TABLE_DANCE_2000:
      return (
        <FormattedMessage
          id={`TABLE_DANCE_2000`}
          defaultMessage={`TABLE_DANCE_2000`}
        />
      )
    case BonCategory.TOPLESS_TABLE_DANCE_1000:
      return (
        <FormattedMessage
          id={`TOPLESS_TABLE_DANCE_1000`}
          defaultMessage={`TOPLESS_TABLE_DANCE_1000`}
        />
      )
    case BonCategory.DANCE_1500:
      return (
        <FormattedMessage id={`DANCE_1500`} defaultMessage={`DANCE_1500`} />
      )
    case BonCategory.AUTOEROTIC_SHOW_3000:
      return (
        <FormattedMessage
          id={`AUTOEROTIC_SHOW_3000`}
          defaultMessage={`AUTOEROTIC_SHOW_3000`}
        />
      )
    case BonCategory.STAG_SHOW_2500:
      return (
        <FormattedMessage
          id={`STAG_SHOW_2500`}
          defaultMessage={`STAG_SHOW_2500`}
        />
      )
    case BonCategory.VIP_TABLE_DANCE_SPECIAL_3000:
      return (
        <FormattedMessage
          id={`VIP_TABLE_DANCE_SPECIAL_3000`}
          defaultMessage={`VIP_TABLE_DANCE_SPECIAL_3000`}
        />
      )
    case BonCategory.CHR_15:
      return <FormattedMessage id={`CHR_15`} defaultMessage={`CHR_15`} />
    case BonCategory.CHR_30:
      return <FormattedMessage id={`CHR_30`} defaultMessage={`CHR_30`} />
    case BonCategory.CHR_60:
      return <FormattedMessage id={`CHR_60`} defaultMessage={`CHR_60`} />
    case BonCategory.MOET_CHR_30:
      return (
        <FormattedMessage id={`MOET_CHR_30`} defaultMessage={`MOET_CHR_30`} />
      )
    case BonCategory.MOET_CHR_60:
      return (
        <FormattedMessage id={`MOET_CHR_60`} defaultMessage={`MOET_CHR_60`} />
      )
    case BonCategory.BOTTLE_ALCOHOL_0_7:
      return (
        <FormattedMessage
          id={`BOTTLE_ALCOHOL_0_7`}
          defaultMessage={`BOTTLE_ALCOHOL_0_7`}
        />
      )
    case BonCategory.BOTTLE_ALCOHOL_1_0:
      return (
        <FormattedMessage
          id={`BOTTLE_ALCOHOL_1_0`}
          defaultMessage={`BOTTLE_ALCOHOL_1_0`}
        />
      )
    case BonCategory.BOTTLE_ALCOHOL_0_7_PREMIUM_BRANDS:
      return (
        <FormattedMessage
          id={`BOTTLE_ALCOHOL_0_7_PREMIUM_BRANDS`}
          defaultMessage={`BOTTLE_ALCOHOL_0_7_PREMIUM_BRANDS`}
        />
      )
    case BonCategory.BOTTLE_ALCOHOL_1_0_PREMIUM_BRANDS:
      return (
        <FormattedMessage
          id={`BOTTLE_ALCOHOL_1_0_PREMIUM_BRANDS`}
          defaultMessage={`BOTTLE_ALCOHOL_1_0_PREMIUM_BRANDS`}
        />
      )
    case BonCategory.NONALCOHOLIC_DRINKS_5:
      return (
        <FormattedMessage
          id={`NONALCOHOLIC_DRINKS_5`}
          defaultMessage={`NONALCOHOLIC_DRINKS_5`}
        />
      )
    case BonCategory.TAP_BEERS_10:
      return (
        <FormattedMessage id={`TAP_BEERS_10`} defaultMessage={`TAP_BEERS_10`} />
      )
    case BonCategory.WELCOME_DRINK:
      return (
        <FormattedMessage
          id={`WELCOME_DRINK`}
          defaultMessage={`WELCOME_DRINK`}
        />
      )
    case BonCategory.BOTTLE_BOHEMIA:
      return (
        <FormattedMessage
          id={`BOTTLE_BOHEMIA`}
          defaultMessage={`BOTTLE_BOHEMIA`}
        />
      )
    case BonCategory.GIFT_BOA:
      return <FormattedMessage id={`GIFT_BOA`} defaultMessage={`GIFT_BOA`} />
    case BonCategory.SHORTS_FIGHT_WITH_STAG:
      return (
        <FormattedMessage
          id={`SHORTS_FIGHT_WITH_STAG`}
          defaultMessage={`SHORTS_FIGHT_WITH_STAG`}
        />
      )
    case BonCategory.BEER_OR_DRINK_CARD:
      return (
        <FormattedMessage
          id={`BEER_OR_DRINK_CARD`}
          defaultMessage={`BEER_OR_DRINK_CARD`}
        />
      )
    case BonCategory.OTHER:
      return <FormattedMessage id={`OTHER`} defaultMessage={`OTHER`} />
    case BonCategory.TIPPING_DOLLARS:
      return (
        <FormattedMessage
          id={`TIPPING_DOLLARS`}
          defaultMessage={`Tipping dollars`}
        />
      )
    case BonCategory.BRACELET_DOUBLETIME:
      return (
        <FormattedMessage
          id={`BRACELET_DOUBLETIME`}
          defaultMessage={`BRACELET_DOUBLETIME`}
        />
      )
    case BonCategory.BRACELET_DRINKS:
      return (
        <FormattedMessage
          id={`BRACELET_DRINKS`}
          defaultMessage={`BRACELET_DRINKS`}
        />
      )
    case BonCategory.OLMECA_SHOT:
      return (
        <FormattedMessage id={`OLMECA_SHOT`} defaultMessage={`OLMECA_SHOT`} />
      )
    case BonCategory.CORONA_EXTRA:
      return (
        <FormattedMessage id={`CORONA_EXTRA`} defaultMessage={`CORONA_EXTRA`} />
      )
    case BonCategory.UNLIMITED_BEER_FRIEND:
      return (
        <FormattedMessage id={`UNLIMITED_BEER_FRIEND`} defaultMessage={`UNLIMITED_BEER_FRIEND`} />
      )
    default:
      return null
  }
}
