import React, { ChangeEvent, useEffect, useState } from "react"
import { Navigate } from "react-router-dom"
import Form from "../../components/Form/Form"
import { Currency } from "../../types/apiTypes"
import LanguageTab from "../../components/LanguageTab/LanguageTab"
import Input from "../../components/Input/Input"
import Switch from "../../components/Switch/Switch"
import { RequestStatus } from "../../types/statusTypes"
import { languageList, simpleToFullTranslation } from "../../utils/typeUtils"
import Results from "../../components/Results/Results"
import config from "../../config"
import RouteMessage from "../../components/Messages/RouteMessage"
import { Field } from "../../components/Messages/FieldMessage"
import { Entity } from "../../components/Messages/EntityMessage"
import { useProductFetch, useProductSave } from "../../hooks/productHooks"
import { useLanguageState, useTranslationState } from "../../hooks/stateHooks"
import { useIdParam } from "../../hooks/routerHooks"

export default function ProductDetail() {
  const id = useIdParam()
  const [isActive, setIsActive] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [price, setPrice] = useState(0)
  const { language, setLanguage } = useLanguageState()
  const [translations, onTitleChange, fillTranslations] = useTranslationState()
  const [save, saveStatus, saveError] = useProductSave()
  const [products, fetchStatus, fetchError] = useProductFetch(id)
  const product = products.get(id)

  useEffect(() => {
    if (product) {
      setIsActive(product.isActive)
      fillTranslations(product.translations, "title")
      setPrice(product.price)
    }
    // fillTranslations & fillPrices are safe deps (same as setIsActive) →
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product])

  const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onTitleChange(e.currentTarget.value, language)
  }

  const handlePriceChange = ({
    currentTarget: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setPrice(parseFloat(value))
  }

  const handleSubmit = () => {
    setIsSubmitted(true)
    save(
      {
        isActive,
        price,
        priority: product?.priority || 0,
        tagIds: product?.tagIds || [],
        translations: simpleToFullTranslation([translations], ["title"]),
      },
      id
    )
  }

  return (
    <>
      {!id && saveStatus === RequestStatus.SUCCEEDED && (
        <Navigate to={config.routes.productList.path()} />
      )}
      <Results
        statuses={[saveStatus, fetchStatus]}
        errors={[saveError, fetchError]}
        actions={["save", "fetch"]}
        entity={Entity.PRODUCT}
      />
      <Form
        title={<RouteMessage id={"productDetail"} />}
        onSubmit={handleSubmit}
        statuses={[saveStatus, fetchStatus]}
        toolbar={
          <LanguageTab
            language={language}
            onChange={setLanguage}
            notifications={languageList.filter((lang) => !translations[lang])}
          />
        }
        useGenericCta
      >
        <Input
          messageId={Field.TITLE}
          name={`title`}
          onChange={handleTitleChange}
          type={`text`}
          value={translations[language]}
          autoFocus
          showRequiredWarning={isSubmitted}
        />
        <Input
          messageId={Field.PRICE}
          name={Currency.CZK}
          onChange={handlePriceChange}
          type={"number"}
          min={0}
          value={price}
          required
        />
        <Switch
          messageId={Field.IS_ACTIVE}
          checked={isActive}
          name={"active"}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setIsActive(e.target.checked)
          }
        />
      </Form>
    </>
  )
}
