import React, { ReactNode } from "react"
import { useCan } from "../../hooks/interfaceHooks"
import { AccessRights } from "../../types/apiTypes"

interface ICanProps {
  resource: keyof AccessRights
  operations: Array<"W" | "R" | "N">
  children: ReactNode
  no?: () => ReactNode
}

export default function Can({ children, no, operations, resource }: ICanProps) {
  const can = useCan()
  return <>{can(resource, ...operations) ? children : no ? no() : null}</>
}
