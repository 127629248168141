const translations = {
  body: {
    emptyDataSourceMessage: "Data nejsou k dispozici",
    filterRow: {
      filterTooltip: "Filtrovat",
    },
    editRow: {
      saveTooltip: "Uložit",
      cancelTooltip: "Zrušit",
      deleteText: "Smazat",
    },
    addTooltip: "Vytvořit",
    deleteTooltip: "Smazat",
    editTooltip: "Upravit",
  },
  header: {
    actions: "Akce",
  },
  grouping: {
    groupedBy: "Seskupit",
    placeholder: "",
  },
  pagination: {
    firstTooltip: "První",
    previousTooltip: "Předchozí",
    nextTooltip: "Další",
    labelRowsPerPage: "Počet záznamů na stránku",
    lastTooltip: "Poslední",
    labelRowsSelect: "záznamů",
  },
  toolbar: {
    addRemoveColumns: "Přidat / odebrat sloupce",
    showColumnsTitle: "Zobrazit název sloupce",
    nRowsSelected: "Vybráno {0} řádků",
    exportTitle: "CSV",
    exportName: "CSV",
    searchTooltip: "Vyhledat",
    searchPlaceholder: "Vyhledat",
  },
}

export default translations