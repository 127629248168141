import { Set } from "immutable"
import { createSelector } from "reselect"
import { AppState } from "../store"
import { selectLanguage, selectLoggedUser } from "./interfaceSelector"
import { OrderConflictResElement, OrderEmployeeResAdmin, OrderResAdmin, UserRes } from "../types/apiTypes"
import { EmployeeDataGrid, OrderDataGrid } from "../types/globalTypes"
import { selectUserRecords } from "./userSelector"
import { selectActivityRecords } from "./activitySelector"
import { getAllOrderResEmployees, getOrderValidationErrors, orderResToOrderReq } from "../utils/typeUtils"
import { selectUpsaleRecords } from "./upsaleSelector"
import { Field } from "../components/Messages/FieldMessage"
import { setYear } from "date-fns"
import { selectAgencyRecords } from "./agencySelector"
import { AgencyMap } from "../models/agencyModel"

export const selectOrderFetchStatus = (state: AppState) =>
  state.order.statuses.fetchOrders

export const selectSendVoucherEmailStatus = (state: AppState) =>
  state.order.statuses.sendVoucherEmail

export const selectOrders = (state: AppState) => state.order.records

export const selectConflictingEmployees = (state: AppState) =>
  state.order.conflictingEmployees

export const selectConflictingResources = (state: AppState) =>
  state.order.conflictingResources

export const selectSectionFetchStatus = (state: AppState) =>
  state.order.statuses.fetchSections

export const selectSections = (state: AppState) => state.order.sections

export const selectSeatingFetchStatus = (state: AppState) =>
  state.order.statuses.fetchSeating

export const selectConflictFetchStatus = (state: AppState) =>
  state.order.statuses.fetchConflicts

export const selectSeating = (state: AppState) => state.order.seating

export const selectOrderFetchError = (state: AppState) =>
  state.order.errors.fetchOrders

export const selectSectionFetchError = (state: AppState) =>
  state.order.errors.fetchSections

export const selectSeatingFetchError = (state: AppState) =>
  state.order.errors.fetchSeating

export const selectEmployeeFetchError = (state: AppState) =>
  state.order.errors.fetchEmployees

export const selectConflictFetchError = (state: AppState) =>
  state.order.errors.fetchConflicts

export const selectSendVoucherEmailError = (state: AppState) =>
  state.order.errors.sendVoucherEmail

export const selectOrderFilter = (state: AppState) => state.order.filters

export const selectOrderPageSize = (state: AppState) => state.order.pageSize

export const selectOrderPageNumber = (state: AppState) => state.order.pageNumber

export const selectOrderSortType = (state: AppState) => state.order.sortType

export const selectOrderSearchQuery = (state: AppState) =>
  state.order.searchQuery.trim()

export const selectOrdersList = createSelector(
  selectOrders,
  (map): OrderResAdmin[] => map.valueSeq().toArray()
)

export const selectOrderDataGrid = createSelector(
  selectOrdersList,
  selectLoggedUser,
  selectAgencyRecords,
  (orders: OrderResAdmin[], loggedUser: UserRes | null, agencyMap: AgencyMap): OrderDataGrid[] =>
    orders.map((order): OrderDataGrid => {
      const allEmployees = getAllOrderResEmployees(order)
      return {
        ...order,
        req: {
          // append req for data edit
          ...orderResToOrderReq(order),
          // append packageId -> useful for data gird edits but not present in req
          packageId: order.packageOrder?.option.packageId || 0,
          // append bonSum
          bonSum: order.bonSum,
        },
        loggedEmployee:
          allEmployees.find((i) => i.userId === loggedUser?.id) || null,
        // append all employees
        allEmployees,
        // for CSV export & render
        agencyName: agencyMap?.get(order.agencyId.toString())?.title
      }
    })
)

export const selectEmployeeDataGrid = createSelector(
  selectOrderDataGrid,
  selectUserRecords,
  selectActivityRecords,
  selectLanguage,
  selectLoggedUser,
  (grid, users, activities, language, user): EmployeeDataGrid[] =>
    grid
      .flatMap((order) =>
        order.orderEmployees.map((employee: OrderEmployeeResAdmin) => ({
          orderId: order.id,
          packageId: order.packageOrder
            ? order.packageOrder.option.packageId
            : null,
          packageName: order.packageOrder
            ? activities.get(order.packageOrder.option.packageId.toString())
                ?.translations?.[language]?.title || ""
            : null,
          optionId: order.packageOrder ? order.packageOrder.option.id : null,
          optionName: order.packageOrder
            ? order.packageOrder.option.translations[language].title
            : null,
          numberOfPersons: order.packageOrder
            ? order.packageOrder.numberOfPersons
            : order.reservation
            ? order.reservation.numberOfPersons
            : 0,
          orderStatus: order.status,
          phone: order.phone || "",
          email: order.email || "",
          name: order.name,
          color: order.notes.COLOR,
          dtStart: employee.dtStart,
          dtEnd: employee.dtEnd,
          employeeId: employee.id,
          employeeStatus: employee.status,
          employeeReward: employee.reward,
          employeeUserId: employee.userId,
          employeePackageOptionId: employee.packageOptionId,
          employeeUpsaleId: employee.upsaleId,
          employeeName:
            users.get(employee?.userId?.toString() || "")?.nickname ||
            employee.role,
          employeeRole: employee.role,
          employeeNote: employee.note,
          employeeSpecification: employee.specification,
        }))
      )
      .filter(({ employeeUserId }) =>
        user
          ? user.accessRights.order === "N"
            ? employeeUserId === user.id
            : true
          : false
      )
)

export const selectSectionInputOptions = createSelector(
  selectSections,
  selectLanguage,
  (sections, language) =>
    sections.toArray().map(([id, { translations }]) => ({
      value: id,
      content: translations[language].title,
    }))
)

export const selectSeatingInputOptions = createSelector(
  selectSeating,
  (seating) =>
    seating.toArray().map(([id, { title }]) => ({
      value: id,
      content: title,
    }))
)

export const selectHeededConflictIds = createSelector(
  selectConflictingResources,
  selectConflictingEmployees,
  (resources, employees): Set<number> =>
    Set([
      ...resources
        .filter((i) => !i.isIgnored)
        .flatMap(({ orderId1, orderId2 }) => [orderId1, orderId2]),
      ...employees
        .filter((i) => !i.isIgnored)
        .flatMap(({ orderId1, orderId2 }) => [orderId1, orderId2]),
    ])
)

export const selectHeededConflictCount = createSelector(
  selectHeededConflictIds,
  (set) => set.size
)

export interface Conflict extends OrderConflictResElement {
  type: "employee" | "resource"
}

type ConflictsGrouped = Record<string, Conflict[]>
type Conflicts = [string, Conflict[]][]

export const selectConflictsGroupedByOrderPairs = createSelector(
  selectConflictingResources,
  selectConflictingEmployees,
  (resources, employees): Conflicts => {
    const conflicts = [...employees, ...resources].reduce(
      (acc: ConflictsGrouped, conflict, index) => {
        const key = `${conflict.orderId1}-${conflict.orderId2}`
        const type = index + 1 > employees.length ? "resource" : "employee"
        if (acc[key]) {
          acc = { ...acc, [key]: [...acc[key], { ...conflict, type }] }
        } else {
          acc[key] = [{ ...conflict, type }]
        }
        return acc
      },
      {}
    )
    return Object.entries(conflicts)
  }
)

export const selectOrderIdsWithError = createSelector(
  selectOrders,
  selectActivityRecords,
  selectUpsaleRecords,
  selectLanguage,
  (orderMap, activityMap, upsaleMap, lang): Set<number> =>
    Set([
      ...orderMap
        .valueSeq()
        .toArray()
        .filter(
          (order) =>
            getOrderValidationErrors(
              orderResToOrderReq(order),
              upsaleMap,
              lang,
              order.packageOrder
                ? activityMap.get(
                    order.packageOrder.option.packageId.toString()
                  )
                : undefined
            ).length > 0
        )
        .map(({ id }) => id),
    ])
)

export function selectOrderFilterDates(state: AppState): [number, number] {
  const custom = state.order.filters[Field.DATE]
  return [
    custom?.first() ? new Date(custom.first()).valueOf() : 0,
    custom?.last()
      ? new Date(custom.last()).valueOf()
      : setYear(new Date(), 2036).valueOf(),
  ]
}
