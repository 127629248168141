import AddBox from "@material-ui/icons/AddBox"
import React, { useState } from "react"
import { FormattedMessage } from "react-intl"
import { Navigate } from "react-router-dom"
import Button from "../../components/Button/Button"
import DataGrid from "../../components/DataGrid/DataGrid"
import { ProductResAdmin } from "../../types/apiTypes"
import config from "../../config"
import Results from "../../components/Results/Results"
import RouteMessage from "../../components/Messages/RouteMessage"
import { Field } from "../../components/Messages/FieldMessage"
import { Entity } from "../../components/Messages/EntityMessage"
import {
  useProductDelete,
  useProductFetch,
  useProductSave,
} from "../../hooks/productHooks"
import StatusProgressBar from "../../components/StatusProgressBar/StatusProgressBar"
import { useLanguage, useLocalStoragePagination } from "../../hooks/interfaceHooks"

export default function ProductList() {
  const [redirectId, setRedirectId] = useState<string | null>(null)
  const language = useLanguage()
  const [, saveStatus, saveError] = useProductSave()
  const [products, fetchStatus, fetchError] = useProductFetch()
  const [deleteProduct, deleteStatus, deleteError] = useProductDelete()
  const paginationProps = useLocalStoragePagination("product")

  return (
    <>
      <Results
        statuses={[saveStatus, fetchStatus, deleteStatus]}
        errors={[saveError, fetchError, deleteError]}
        actions={["save", "fetch", "delete"]}
        entity={Entity.PRODUCT}
      />
      {redirectId !== null && (
        <Navigate to={config.routes.productDetail.path(redirectId)} />
      )}
      <StatusProgressBar statuses={[fetchStatus]} />
      <DataGrid<ProductResAdmin>
        {...paginationProps}
        data={products.valueSeq().toArray()}
        title={<RouteMessage id={"productList"} />}
        onRowClick={(_, row) => setRedirectId(row ? row.id.toString() : null)}
        columns={[
          {
            title: Field.TITLE,
            field: `translations.${language}.title`,
            defaultSort: "asc",
            customSort: (a, b) =>
              a.translations[language].title.localeCompare(
                b.translations[language].title
              ),
          },
          {
            title: Field.PRICE,
            field: `price`,
          },
        ]}
        cta={
          <Button
            onClick={() => setRedirectId(``)}
            variant="contained"
            color={`primary`}
            startIcon={<AddBox />}
          >
            <FormattedMessage
              id={`createProduct`}
              defaultMessage={`Create product`}
            />
          </Button>
        }
        editable={{
          onRowDelete: ({ id }) =>
            new Promise<void>((resolve) => {
              deleteProduct(id)
              resolve()
            }),
        }}
      />
    </>
  )
}
