import { ChangeEvent, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AgencyMap } from "../models/agencyModel"
import { RequestStatus } from "../types/statusTypes"
import {
  selectAgencyFetchError,
  selectAgencyFetchStatus,
  selectAgencyRecords,
} from "../selectors/agencySelector"
import {
  addAgency,
  fetchAgencies,
  removeAgency,
} from "../actions/agencyActions"
import { AgencyReq, AgencyResAdmin, GuideReq } from "../types/apiTypes"
import { selectToken } from "../selectors/interfaceSelector"
import {
  requestDeleteAgency,
  requestGetAgencies,
  requestSaveAgency,
  requestSaveAgencyPackagePrices,
  requestSaveAgencyUpsalePrices,
} from "../api/agencyRequests"
import { delay, delayMoment, delayWhile } from "../utils/apiUtils"
import config from "../config"
import { PriceState } from "./priceHooks"
import { removeKey } from "../utils/objectUtils"
import { decodeInputName, uid } from "../utils/stringUtils"

export function useAgencyFetch(
  id?: string
): [AgencyMap, RequestStatus, string] {
  const dispatch = useDispatch()
  const status = useSelector(selectAgencyFetchStatus)
  const error = useSelector(selectAgencyFetchError)
  const agencies = useSelector(selectAgencyRecords)
  useEffect(() => {
    dispatch(fetchAgencies(id ? [parseFloat(id)] : undefined))
  }, [dispatch, id])
  return [agencies, status, error]
}

export function useAgencySave(): [
  (data: AgencyReq, id?: number) => Promise<AgencyResAdmin | null>,
  RequestStatus,
  string
] {
  const dispatch = useDispatch()
  const auth = useSelector(selectToken)
  const [status, setStatus] = useState<RequestStatus>(RequestStatus.INITIAL)
  const [error, setError] = useState<string>("")
  return [
    async (data, id?) => {
      try {
        setStatus(RequestStatus.REQUESTED)
        const response = await requestSaveAgency(auth, data, id)
        const [agency] = await requestGetAgencies(auth, [response.id])
        dispatch(addAgency(agency))
        setStatus(RequestStatus.SUCCEEDED)
        return agency
        // eslint-disable-next-line
      } catch (e: any) {
        setError(e.message)
        setStatus(RequestStatus.FAILED)
        return null
      } finally {
        await delay(2000)
        setError("")
        setStatus(RequestStatus.INITIAL)
      }
    },
    status,
    error,
  ]
}

export function useAgencyDelete(): [
  (id: number) => Promise<null>,
  RequestStatus,
  string
] {
  const dispatch = useDispatch()
  const auth = useSelector(selectToken)
  const [status, setStatus] = useState<RequestStatus>(RequestStatus.INITIAL)
  const [error, setError] = useState<string>("")
  return [
    async (id) => {
      try {
        setStatus(RequestStatus.REQUESTED)
        await requestDeleteAgency(auth, id)
        dispatch(removeAgency(id))
        setStatus(RequestStatus.SUCCEEDED)
        return null
        // eslint-disable-next-line
      } catch (e: any) {
        setError(e.message)
        setStatus(RequestStatus.FAILED)
        return null
      } finally {
        await delay(2000)
        setError("")
        setStatus(RequestStatus.INITIAL)
      }
    },
    status,
    error,
  ]
}

export function useAgencyPriceSave(
  isUpsale: boolean = false
): [(prices: PriceState, agencyId?: number) => void, RequestStatus, string] {
  const auth = useSelector(selectToken)
  const [status, setStatus] = useState<RequestStatus>(RequestStatus.INITIAL)
  const [error, setError] = useState<string>("")
  const requestFn = isUpsale
    ? requestSaveAgencyUpsalePrices
    : requestSaveAgencyPackagePrices
  return [
    async (priceState, agencyId = config.agencyIds.web) => {
      try {
        setStatus(RequestStatus.REQUESTED)
        const prices = Object.values(priceState).reduce(
          (acc, curr) => ({ ...acc, ...curr }),
          {}
        )
        await requestFn(auth, agencyId, {
          value: Object.entries(prices).map(([optionId, price]) => ({
            agencyId,
            packageOptionId: parseFloat(optionId),
            deposit: parseFloat(price.deposit || `0`),
            feeOver10Persons: parseFloat(price.fee || `0`),
            price: parseFloat(price.priceUnit || `0`),
            pricePerNCapitas: {
              numberOfPersons: 1,
              price: parseFloat(price.pricePerPerson || `0`),
            },
          })),
        })
        setStatus(RequestStatus.SUCCEEDED)
        await delayMoment()
        setStatus(RequestStatus.INITIAL)
        // eslint-disable-next-line
      } catch (e: any) {
        setError(e.message)
        setStatus(RequestStatus.FAILED)
        await delayWhile()
        setError("")
        setStatus(RequestStatus.INITIAL)
      }
    },
    status,
    error,
  ]
}

export function useAgencyGuideState() {
  const firstGuideKey = "erste"
  const blankGuide = { name: "", phone: "", email: "" }
  const [activeGuideId, setActiveGuideId] = useState<string>(firstGuideKey)
  const [guides, setGuides] = useState<{ [key: string]: GuideReq }>({
    [firstGuideKey]: blankGuide,
  })
  const [guideIds, setGuideIds] = useState<string[]>([firstGuideKey])

  return {
    activeGuideId,
    guideIds,
    setActiveGuide: (key: string) => setActiveGuideId(key),
    guides,
    activeGuide: guides[activeGuideId],
    createGuide: () => {
      const key = uid()
      setGuides({ ...guides, [key]: blankGuide })
      setGuideIds([...guideIds, key])
      setActiveGuideId(key)
    },
    deleteGuide: () => {
      if (guideIds.length > 1) {
        const reducedGuideIds = guideIds.filter((i) => i !== activeGuideId)
        setGuideIds(reducedGuideIds)
        setActiveGuideId(reducedGuideIds[0])
        setGuides(removeKey(guides, activeGuideId))
      }
    },
    changeGuide: ({
      currentTarget: { name, value },
    }: ChangeEvent<HTMLInputElement>) => {
      const [key] = decodeInputName(name)
      if (key === "name" || key === "phone" || key === "email")
        setGuides({
          ...guides,
          [activeGuideId]: { ...guides[activeGuideId], [key]: value },
        })
    },
    fillWithData: (data: GuideReq[]) => {
      if (data.length > 0) {
        const dataWithKeys: [string, GuideReq][] = data.map((item) => [
          uid(),
          item,
        ])
        setGuides(Object.fromEntries(dataWithKeys))
        setGuideIds(dataWithKeys.map(([id]) => id))
        setActiveGuideId(dataWithKeys[0][0])
      }
    },
  }
}
