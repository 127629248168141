import React, { useEffect } from "react"
import { Box } from "@material-ui/core"
import { FormattedMessage } from "react-intl"
import { ValidationError } from "../../utils/typeUtils"
import Toast from "../Toast/Toast"
import FieldMessage, { Field } from "../Messages/FieldMessage"
import ErrorMessage, { ErrorType } from "../Messages/ErrorMessage"

interface IValidationErrorsProps {
  errors: ValidationError[]
  resetErrors?: () => void
}

const getMessageKey = (...parts: Array<string | number>): string =>
  parts.join(`-`)

export default function ValidationErrors({
  errors,
  resetErrors,
}: IValidationErrorsProps) {
  useEffect(() => {
    let timeout: NodeJS.Timeout
    if (errors.length > 0) {
      timeout = setTimeout(() => {
        resetErrors && resetErrors()
      }, 15000)
    }
    return () => clearTimeout(timeout)
  }, [errors, resetErrors])

  const handleClose = () => {
    resetErrors && resetErrors()
  }

  const errorMap = errors.reduce(
    (
      acc: Record<string, [Field, ErrorType][]>,
      [field, errorType, group = ""]
    ) => {
      if (acc[group]) {
        acc[group] = [...acc[group], [field, errorType]]
      } else {
        acc[group] = [[field, errorType]]
      }
      return acc
    },
    {}
  )

  if (errors.length === 0) {
    return null
  }
  return (
    <Toast
      severity={"error"}
      open={errors.length > 0}
      handleClose={handleClose}
      title={
        <FormattedMessage
          id={"recordHasErrors"}
          defaultMessage={"The record has following errors"}
        />
      }
      message={Object.entries(errorMap).map(
        ([groupName, groupErrors], groupIndex) => (
          <Box
            paddingTop={groupIndex > 0 ? "1em" : "0"}
            key={groupName || "undefined"}
          >
            {groupName && <strong>{groupName}</strong>}
            {groupErrors.map(([field, error], errorIndex) => (
              <Box
                display={"flex"}
                flexWrap={"nowrap"}
                key={getMessageKey(field, error, groupIndex, errorIndex)}
              >
                <Box component={"span"} mr={2}>
                  <FieldMessage id={field} />
                </Box>
                <Box component={"span"} ml={"auto"}>
                  <ErrorMessage id={error} />
                </Box>
              </Box>
            ))}
          </Box>
        )
      )}
    />
  )
}
