import { ActionTypes } from "../types/actionTypes"
import { InvoiceMap } from "../models/invoiceModel"

export const fetchInvoices = (ids?: number[]) =>
  ({
    type: ActionTypes.FETCH_INVOICES_REQUESTED,
    payload: { ids },
  } as const)

export const fetchInvoicesSucceed = (invoices: InvoiceMap) =>
  ({
    type: ActionTypes.FETCH_INVOICES_SUCCEEDED,
    payload: {
      invoices,
    },
  } as const)

export const fetchInvoicesFailed = (error: string) =>
  ({
    type: ActionTypes.FETCH_INVOICES_FAILED,
    payload: {
      error,
    },
  } as const)
