import React from "react"
import { Voucher } from "../../types/globalTypes"
import { useLanguage } from "../../hooks/interfaceHooks"
import {
  getReservationContents,
  getVoucherSurcharge,
} from "../../utils/apiUtils"
import BonSummary from "../../components/BonSummary/BonSummary"
import Price from "../../components/Price/Price"

export function VoucherContentsCells(voucher: Voucher) {
  const language = useLanguage()
  const reservationContents = getReservationContents(
    voucher.reservation?.reservation
  )
  return (
    <>
      <span>
        {voucher.packageOrder?.option.translations[language].title}
        {reservationContents && ", "}
      </span>

      <span>{reservationContents}</span>
    </>
  )
}

export function VoucherSurchargeCell(voucher: Voucher) {
  return <Price price={getVoucherSurcharge(voucher)} />
}

export function VoucherBonsCell({ bonSum }: Voucher) {
  return <BonSummary summary={bonSum} />
}
