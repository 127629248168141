import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Form from "../../components/Form/Form"
import Input from "../../components/Input/Input"
import LanguageTab from "../../components/LanguageTab/LanguageTab"
import EntityList from "../../components/EntityList/EntityList"
import MultiSelect from "../../components/MultiSelect/MultiSelect"
import { fetchProduct } from "../../actions/productActions"
import { selectProductInputOptions } from "../../selectors/productSelector"
import config from "../../config"
import Results from "../../components/Results/Results"
import { languageList } from "../../utils/typeUtils"
import WebPageMessage, {
  WebPage,
} from "../../components/Messages/WebPageMessage"
import { Field } from "../../components/Messages/FieldMessage"
import { Entity } from "../../components/Messages/EntityMessage"
import {
  useTagDelete,
  useTagFetch,
  useTagSave,
  useTagState,
} from "../../hooks/tagHooks"
import { usePageSharedState } from "../../hooks/stateHooks"
import { arrayDiff } from "../../utils/arrayUtils"

export default function PageOffer() {
  const dispatch = useDispatch()
  const state = usePageSharedState(config.pageIds.offer)
  const drinkState = useTagState()
  const dancerState = useTagState()
  const [fetchTags, fetchTagStatus, fetchTagError] = useTagFetch()
  const [saveTags, saveTagStatus, saveTagError] = useTagSave("product")
  const [deleteTag, deleteTagStatus, deleteTagError] = useTagDelete("product")
  const productOptions = useSelector(selectProductInputOptions)
  const [remoteTags, setRemoteTags] = useState<number[]>([])

  useEffect(() => {
    state.fetchPage()
    dispatch(fetchProduct())
    fetchTags("product", (tags) => {
      setRemoteTags(tags.map((tag) => tag.id))
      const drinkTags = tags.filter(
        (i) => i.category === config.tagCategories.drinks
      )
      const dancerTags = tags.filter(
        (i) => i.category === config.tagCategories.dancers
      )
      drinkState.fillWithData(
        drinkTags.map((tag) => tag.id.toString()),
        drinkTags.map((tag) => tag.translations),
        drinkTags.map((tag) => tag.entityIds.map((id) => id.toString()))
      )
      dancerState.fillWithData(
        dancerTags.map((tag) => tag.id.toString()),
        dancerTags.map((tag) => tag.translations),
        dancerTags.map((tag) => tag.entityIds.map((id) => id.toString()))
      )
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const handleSubmit = async () => {
    await state.savePage()
    const drinkTagIds = await saveTags(
      drinkState.tags,
      drinkState.entities,
      drinkState.titles,
      drinkState.contents,
      config.tagCategories.drinks
    )
    if (drinkTagIds) drinkState.handleTagSave(drinkTagIds)
    const dancerTagIds = await saveTags(
      dancerState.tags,
      dancerState.entities,
      dancerState.titles,
      dancerState.contents,
      config.tagCategories.dancers
    )
    if (dancerTagIds) dancerState.handleTagSave(dancerTagIds)
    const tagIds = [...(dancerTagIds || []), ...(drinkTagIds || [])]
    await Promise.all(arrayDiff(remoteTags, tagIds).map((id) => deleteTag(id)))
    setRemoteTags(tagIds)
  }

  const handleDeleteDrinkTag = async () => {
    drinkState.handleTagDelete()
  }

  const handleDeleteDancerTag = async () => {
    dancerState.handleTagDelete()
  }

  return (
    <>
      <Results
        statuses={[state.saveStatus, state.fetchStatus]}
        errors={[state.saveError, state.fetchError]}
        actions={["save", "fetch"]}
        entity={Entity.PAGE}
      />
      <Results
        statuses={[saveTagStatus, fetchTagStatus, deleteTagStatus]}
        errors={[saveTagError, fetchTagError, deleteTagError]}
        actions={["save", "fetch", "delete"]}
        entity={Entity.TAG}
      />
      <Form
        statuses={[
          saveTagStatus,
          fetchTagStatus,
          deleteTagStatus,
          state.saveStatus,
          state.fetchStatus,
        ]}
        title={<WebPageMessage id={WebPage.OFFER} />}
        onSubmit={handleSubmit}
        toolbar={
          <LanguageTab
            language={state.language}
            onChange={state.setLanguage}
            notifications={languageList.filter((lang) => {
              const { content, title, metaData } = state.translations[lang]
              return !content || !title || !metaData
            })}
          />
        }
        useGenericCta
      >
        <Input
          name={`title`}
          messageId={Field.TITLE}
          onChange={state.handleTitle}
          value={state.title}
          showRequiredWarning={state.isSubmitted}
        />
        <Input
          name={`meta`}
          messageId={Field.META}
          onChange={state.handleMeta}
          value={state.meta}
          showRequiredWarning={state.isSubmitted}
        />
        <Input
          name={`keywords`}
          messageId={Field.KEYWORDS}
          onChange={state.handleKeywords}
          value={state.keywords}
          showRequiredWarning={state.isSubmitted}
        />
        <EntityList
          entityPairs={drinkState.tags.map((tag) => [
            tag,
            drinkState.titles?.[tag]?.[state.language],
          ])}
          onEntityChange={drinkState.handleActiveTagChange}
          onCreate={drinkState.handleTagCreate}
          onDelete={handleDeleteDrinkTag}
          onSort={drinkState.handleTagSort}
          activeEntity={drinkState.activeTag}
        >
          <Input
            name={`tagTitle`}
            messageId={Field.TITLE}
            onChange={(e) =>
              drinkState.handleTagTitleChange(
                e.currentTarget.value,
                state.language
              )
            }
            value={drinkState.titles?.[drinkState.activeTag]?.[state.language]}
          />
          <Input
            name={`tagContent`}
            messageId={Field.CONTENT}
            onChange={(e) =>
              drinkState.handleTagContentChange(
                e.currentTarget.value,
                state.language
              )
            }
            value={
              drinkState.contents?.[drinkState.activeTag]?.[state.language]
            }
          />
          <MultiSelect
            onChange={drinkState.handleEntitiesChange}
            values={drinkState.entities?.[drinkState.activeTag] || []}
            options={productOptions}
            messageId={Field.DRINKS}
            name={`tagEntities`}
          />
        </EntityList>
        <EntityList
          entityPairs={dancerState.tags.map((tag) => [
            tag,
            dancerState.titles[tag]?.[state.language],
          ])}
          onEntityChange={dancerState.handleActiveTagChange}
          onCreate={dancerState.handleTagCreate}
          onDelete={handleDeleteDancerTag}
          onSort={dancerState.handleTagSort}
          activeEntity={dancerState.activeTag}
        >
          <Input
            name={`tagTitle`}
            messageId={Field.TITLE}
            onChange={(e) =>
              dancerState.handleTagTitleChange(
                e.currentTarget.value,
                state.language
              )
            }
            value={
              dancerState.titles?.[dancerState.activeTag]?.[state.language]
            }
          />
          <Input
            name={`tagContent`}
            messageId={Field.CONTENT}
            onChange={(e) =>
              dancerState.handleTagContentChange(
                e.currentTarget.value,
                state.language
              )
            }
            value={
              dancerState.contents?.[dancerState.activeTag]?.[state.language]
            }
          />
          <MultiSelect
            onChange={dancerState.handleEntitiesChange}
            values={dancerState.entities?.[dancerState.activeTag] || []}
            options={productOptions}
            messageId={Field.DANCERS}
            name={`tagEntities`}
          />
        </EntityList>
      </Form>
    </>
  )
}
