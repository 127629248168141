import React from "react"
import { FormattedMessage } from "react-intl"
import { PackageType } from "../../types/apiTypes"

interface IActivityTypeMessageProps {
  id: PackageType
}

export default function ActivityTypeMessage({ id }: IActivityTypeMessageProps) {
  switch (id) {
    case PackageType.activity:
      return <FormattedMessage id={`activity`} defaultMessage={`Activity`} />
    case PackageType.trip:
      return <FormattedMessage id={`trip`} defaultMessage={`Trip`} />
    case PackageType.shuttle:
      return <FormattedMessage id={`shuttle`} defaultMessage={`Shuttle`} />
    case PackageType.reservation:
      return (
        <FormattedMessage id={`reservation`} defaultMessage={`Reservation`} />
      )
    case PackageType.hen:
      return <FormattedMessage id={`hen`} defaultMessage={`Hen party`} />
    case PackageType.stag:
      return <FormattedMessage id={`stag`} defaultMessage={`Stag party`} />
    default:
      return <>{id}</>
  }
}
