import React, {
  ChangeEvent,
  useEffect,
  useReducer,
  useState,
  memo,
} from "react"
import Button from "@material-ui/core/Button"
import { Add } from "@material-ui/icons"
import { FormattedMessage } from "react-intl"
import { Set as ImmutableSet } from "immutable"
import { useSelector } from "react-redux"
import { Navigate } from "react-router-dom"
import Form from "../../components/Form/Form"
import { LiteEvent } from "../../types/globalTypes"
import LanguageTab from "../../components/LanguageTab/LanguageTab"
import EntityList from "../../components/EntityList/EntityList"
import {
  activityDetailActionCreators as creators,
  activityDetailReducer as reducer,
  activityDetailState as initialState,
} from "./ActivityDetailState"
import Input from "../../components/Input/Input"
import Checkbox from "../../components/Checkbox/Checkbox"
import FieldSet from "../../components/FieldSet/FieldSet"
import MinuteInput from "../../components/MinuteInput/MinuteInput"
import MultipleFiledSet from "../../components/MultipleFiledSet/MultipleFiledSet"
import {
  activityTypeInputOptions,
  blankTranslations,
  bonResponsibilityOptions,
  employeeRoleInputOptions,
  formFieldsInputOptions,
  fullToSimpleTranslation,
  idParamToPost,
  languageList,
  simpleToFullTranslation,
} from "../../utils/typeUtils"
import Results from "../../components/Results/Results"
import ComboBox from "../../components/ComboBox/ComboBox"
import MultiSelect from "../../components/MultiSelect/MultiSelect"
import { GeneralEvent, getTargetStringValue } from "../../utils/inputUtils"
import { RequestStatus } from "../../types/statusTypes"
import config from "../../config"
import RouteMessage from "../../components/Messages/RouteMessage"
import { Field } from "../../components/Messages/FieldMessage"
import { Entity } from "../../components/Messages/EntityMessage"
import { selectUpsaleInputOptions } from "../../selectors/upsaleSelector"
import { useUpsaleFetch } from "../../hooks/upsaleHooks"
import { useActivityFetch, useActivitySave } from "../../hooks/activityHooks"
import { useTagState } from "../../hooks/tagHooks"
import {
  useLanguageState,
  useToggle,
  useTranslationState,
} from "../../hooks/stateHooks"
import { useResourceFetch } from "../../hooks/resourceHooks"
import { millisecondsInDay } from "../../utils/timeUtils"
import CheckboxList from "../../components/CheckboxList/CheckboxList"
import { AppState } from "../../store"
import BonCategorySelect from "../../components/BonCategorySelect/BonCategorySelect"
import InlineFieldset from "../../components/InlineFieldset/InlineFieldset"
import {
  BlockingReq,
  BonReq,
  FormField,
  PackageCancellationPolicyReq,
  PackageType,
  RequiredRoleReq,
} from "../../types/apiTypes"
import { useIdParam } from "../../hooks/routerHooks"
import { decodeInputName, encodeInputName } from "../../utils/stringUtils"

function ActivityDetail() {
  const id = useIdParam()
  const [shouldRedirect, setShouldRedirect] = useState(false)
  const { language, setLanguage } = useLanguageState()
  const [title, onTitleChange, fillTitle] = useTranslationState()
  const [content, onContentChange, fillContent] = useTranslationState()
  const [isSubmitted, setIsSubmitted] = useState(false)
  const {
    activeTag,
    tags,
    titles,
    contents,
    handleActiveTagChange,
    handleTagSort,
    handleTagTitleChange,
    handleTagContentChange,
    handleTagDelete: deleteTag,
    handleTagCreate: createTag,
    fillWithData: fillTagStateWithData,
  } = useTagState()
  const [options, dispatch] = useReducer<typeof reducer>(reducer, initialState)
  const [saveActivity, saveStatus, saveError] = useActivitySave()
  const [isActive, toggleActive, setIsActive] = useToggle()
  const [isWeb, , setIsWeb] = useToggle()
  const [types, setTypes] = useState<ImmutableSet<PackageType>>(ImmutableSet)
  const [hasReservation, toggleHasReservation, setHasReservation] = useToggle()
  const [requiredUpsaleIds, setRequiredUpsaleIds] = useState<string[][]>([])
  const [upsales, setUpsales] = useState<string[]>([])
  const [resources, resourceStatus, resourceError] = useResourceFetch()
  const [activities, fetchStatus, fetchError] = useActivityFetch(id)
  const [, upsaleFetchStatus, upsaleFetchError] = useUpsaleFetch()
  const upsaleInputOptions = useSelector((state: AppState) =>
    selectUpsaleInputOptions(state, isWeb, !isWeb)
  )
  const activity = activities.get(id)

  // set the data
  useEffect(() => {
    if (activity) {
      fillTitle(activity.translations, "title")
      setTypes(ImmutableSet(activity.type))
      fillContent(activity.translations, "content")
      setRequiredUpsaleIds(
        activity.requiredUpsaleIds.map((group) =>
          group.map((i) => i.toString())
        )
      )
      setUpsales(
        activity.upsalePackageIds
          .slice()
          .sort((a, b) => a.priority - b.priority)
          .map(({ upsaleId }) => upsaleId.toString())
      )
      setHasReservation(activity.containsReservation)
      setIsActive(activity.isActive)
      fillTagStateWithData(
        activity.options.map((option) => option.id.toString()),
        activity.options.map((option) => option.translations),
        activity.options.map(() => [])
      )
      dispatch(creators.fillWithOptions(activity.options))
      setIsWeb(activity.isForWeb)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activity])

  const onSubmit = () => {
    setIsSubmitted(true)
    saveActivity(
      {
        id: idParamToPost(id),
        isActive,
        requiredUpsaleIds: requiredUpsaleIds.map((group) =>
          group.map((i) => parseFloat(i))
        ),
        containsReservation: hasReservation,
        isForWeb: isWeb,
        tagIds: activity?.tagIds || [],
        type: [...types],
        translations: {
          ...simpleToFullTranslation(
            [
              title,
              content,
              fullToSimpleTranslation(
                activity?.translations || blankTranslations,
                "additionalInfo"
              ),
            ],
            ["title", "content", "additionalInfo"]
          ),
        },
        upsalePackageIds: upsales.map((i, index) => ({
          upsaleId: parseFloat(i),
          priority: index,
        })),
        addToReservation: activity?.addToReservation || false, // do not change → handled by packageDetail
        gallery: activity?.gallery || null, // do not change → handled by packageDetail
        priority: activity?.priority || 999, // do not change → handled by packageDetail
        options: tags.map((tagId, index) => {
          const option = activity?.options.find(
            (o) => o.id === parseFloat(tagId)
          )
          return {
            availableFrom: options[tagId].availableFrom,
            availableTo: options[tagId].availableTo,
            blocking: Object.values(options[tagId].blocks),
            bons: Object.values(options[tagId].bons),
            cost: options[tagId].costExternal,
            costPerNCapitas: {
              price: options[tagId].costPerPerson,
              numberOfPersons: options[tagId].costPersons,
            },
            isConstrainedByOpeningHours:
              options[tagId].isConstrainedByOpeningHours,
            maxCapacity: options[tagId].maxCapacity,
            minCapacity: options[tagId].minCapacity,
            translations: simpleToFullTranslation(
              [titles[tagId], contents[tagId]],
              ["title", "content"]
            ),
            optionalFields: [...options[tagId].optionalFields],
            requiredFields: [...options[tagId].requiredFields],
            gallery: option?.gallery || null, // do not change → handled by packageDetail
            priority: index,
            id: option?.id || null,
            cancellationPolicy: Object.values(options[tagId].cancelPolicies),
            requiredRoles: Object.values(options[tagId].roles),
          }
        }),
      },
      id
    )
    setShouldRedirect(true)
  }

  const handleChangeOption = (e: GeneralEvent) => {
    const value = getTargetStringValue(e)
    dispatch(creators.changeOption(activeTag, e.currentTarget.name, value))
  }

  const handleChangeOptionMultiple = (name: string, values: string[]) => {
    dispatch(
      creators.changeOptionFields(
        activeTag,
        name,
        new Set(values) as Set<FormField>
      )
    )
  }

  const handleChangeOptionEntity = (e: GeneralEvent) => {
    const [prop, nestProp, key] = decodeInputName(e.currentTarget.name)
    const value = getTargetStringValue(e)
    if (prop === `cancelPolicies`)
      dispatch(creators.changePolicy(activeTag, key, nestProp, value))
    if (prop === `roles`)
      dispatch(creators.changeRole(activeTag, key, nestProp, value))
    if (prop === `blocks`)
      dispatch(creators.changeBlock(activeTag, key, nestProp, value))
    if (prop === `bon`)
      dispatch(creators.changeBon(activeTag, key, nestProp, value))
  }

  const handleChangeTranslation = ({
    currentTarget: { name, value },
  }: ChangeEvent<HTMLInputElement>) => {
    if (name === "title") onTitleChange(value, language)
    if (name === "content") onContentChange(value, language)
  }

  const handleTagCreate = () => {
    const tagId = createTag()
    dispatch(creators.createOption(tagId))
  }

  const handleTagDelete = () => {
    deleteTag()
    if (tags.length > 1) dispatch(creators.removeOption(activeTag))
  }

  const handleChangeType = ({
    currentTarget: { value },
  }: LiteEvent<PackageType>) => {
    setTypes(types.has(value) ? types.remove(value) : types.add(value))
  }

  const handleRequiredUpsaleIdsChange =
    (index: number): ((name: string, values: string[]) => void) =>
    (name: string, values: string[]) => {
      setRequiredUpsaleIds((groups) =>
        groups.map((ids, idx) => (idx === index ? values : ids))
      )
    }

  const handleCreateUpsaleGroup = () => {
    setRequiredUpsaleIds([...requiredUpsaleIds, []])
  }

  const handleRemoveUpsaleGroup = (key: string) => {
    setRequiredUpsaleIds((groups) =>
      groups.filter((_, idx) => idx !== parseFloat(key))
    )
  }

  if (shouldRedirect)
    return (
      <Navigate to={config.routes.activityList.path()} />
    )

  return (
    <>
      {saveStatus === RequestStatus.SUCCEEDED && !id && (
        <Navigate to={config.routes.activityList.path()} />
      )}
      <Results
        statuses={[saveStatus, resourceStatus, fetchStatus]}
        errors={[saveError, resourceError, fetchError]}
        actions={["save", "fetch", "fetch"]}
        entity={Entity.ACTIVITY}
      />
      <Results
        statuses={[upsaleFetchStatus]}
        errors={[upsaleFetchError]}
        actions={["fetch"]}
        entity={Entity.UPSALE}
      />
      <Form
        title={<RouteMessage id={"activityDetail"} />}
        onSubmit={onSubmit}
        statuses={[upsaleFetchStatus, saveStatus, resourceStatus, fetchStatus]}
        toolbar={
          <LanguageTab
            language={language}
            onChange={setLanguage}
            notifications={languageList.filter(
              (lang) =>
                !title[lang] ||
                !content[lang] ||
                tags.some((tag) => !titles[tag][lang])
            )}
          />
        }
        useGenericCta
      >
        <Checkbox
          messageId={Field.IS_ACTIVE}
          name={"isActive"}
          checked={isActive}
          onChange={toggleActive}
        />
        <Checkbox
          messageId={Field.CONTAINS_RESERVATION}
          name={"containsReservation"}
          checked={hasReservation}
          onChange={() => toggleHasReservation()}
        />
        <Checkbox
          messageId={Field.IS_AGENCY}
          name={`isAgency`}
          checked={!isWeb}
          onChange={() => setIsWeb(false)}
        />
        <Checkbox
          messageId={Field.IS_WEB}
          name={`isWeb`}
          checked={isWeb}
          onChange={() => setIsWeb(true)}
        />
        <Input
          messageId={Field.TITLE}
          name={`title`}
          onChange={handleChangeTranslation}
          value={title[language]}
          showRequiredWarning={isSubmitted}
        />
        <Input
          messageId={Field.DESCRIPTION}
          name={`content`}
          onChange={handleChangeTranslation}
          value={content[language]}
          showRequiredWarning={isSubmitted}
          multiline
        />
        <MultiSelect
          onChange={(_: string, values: string[]) => setUpsales(values)}
          values={upsales}
          options={upsaleInputOptions}
          messageId={Field.UPSALES}
          name={"upsalePackageOptionIds"}
        />
        {upsales.length > 0 && (
          <MultipleFiledSet
            entity={Entity.GROUP}
            messageId={Field.REQUIRED_UPSALES}
            onCreate={handleCreateUpsaleGroup}
            onRemove={handleRemoveUpsaleGroup}
          >
            {requiredUpsaleIds.map((ids, idx) => (
              <MultiSelect
                /* eslint-disable-next-line react/no-array-index-key */
                key={idx}
                onChange={handleRequiredUpsaleIdsChange(idx)}
                values={ids}
                options={upsaleInputOptions.filter((option) =>
                  upsales.includes(option.value)
                )}
                messageId={Field.UPSALES}
                name={"upsalePackageOptionIds"}
              />
            ))}
          </MultipleFiledSet>
        )}
        <CheckboxList<PackageType>
          messageId={Field.TYPE}
          values={types}
          name={`types`}
          options={activityTypeInputOptions}
          onChange={handleChangeType}
        />
        <EntityList
          entityPairs={tags.map((tag) => [tag, titles[tag][language]])}
          onEntityChange={handleActiveTagChange}
          onCreate={handleTagCreate}
          onDelete={handleTagDelete}
          onSort={handleTagSort}
          activeEntity={activeTag}
        >
          <Input
            messageId={Field.OPTION_TITLE}
            name={`title`}
            onChange={(e) =>
              handleTagTitleChange(e.currentTarget.value, language)
            }
            value={titles[activeTag][language]}
            showRequiredWarning={isSubmitted}
          />
          <Input
            messageId={Field.OPTION_CONTENT}
            name={`content`}
            onChange={(e) =>
              handleTagContentChange(e.currentTarget.value, language)
            }
            value={contents[activeTag][language]}
            showRequiredWarning={isSubmitted}
            multiline
          />
          <Checkbox
            messageId={Field.IS_CONSTRAINED}
            name={`isConstrainedByOpeningHours`}
            checked={options[activeTag]?.isConstrainedByOpeningHours || false}
            onChange={handleChangeOption}
          />
          <MultiSelect
            onChange={handleChangeOptionMultiple}
            values={[...(options[activeTag]?.requiredFields || [])]}
            options={formFieldsInputOptions}
            messageId={Field.REQUIRED_FIELDS}
            name={"requiredFields"}
          />
          <MultiSelect
            onChange={handleChangeOptionMultiple}
            values={[...(options[activeTag]?.optionalFields || [])]}
            options={formFieldsInputOptions}
            messageId={Field.OPTIONAL_FIELDS}
            name={"optionalFields"}
          />
          <InlineFieldset messageId={Field.AVAILABILITY}>
            <MinuteInput
              messageId={Field.FROM}
              value={options[activeTag]?.availableFrom || 0}
              name={`availableFrom`}
              onChange={handleChangeOption}
            />
            <MinuteInput
              messageId={Field.TO}
              value={options[activeTag]?.availableTo || 0}
              name={`availableTo`}
              onChange={handleChangeOption}
            />
          </InlineFieldset>
          <FieldSet messageId={Field.COSTS}>
            <InlineFieldset>
              <Input
                messageId={Field.EXTERNAL_COST}
                value={options[activeTag]?.costExternal || 0}
                name={`costExternal`}
                onChange={handleChangeOption}
                type={"number"}
                min={0}
              />
              <Input
                messageId={Field.COST_PER_PERSON}
                value={options[activeTag]?.costPerPerson || 0}
                name={`costPerPerson`}
                onChange={handleChangeOption}
                type={"number"}
                min={0}
              />
              <Input
                messageId={Field.NUMBER_OF_PERSONS}
                value={options[activeTag]?.costPersons || 0}
                name={`costPersons`}
                onChange={handleChangeOption}
                type={"number"}
                min={1}
              />
            </InlineFieldset>
          </FieldSet>
          <InlineFieldset messageId={Field.CAPACITY}>
            <Input
              messageId={Field.MIN}
              value={options[activeTag]?.minCapacity || 0}
              name={`minCapacity`}
              onChange={handleChangeOption}
              type={"number"}
              min={1}
              required
            />
            <Input
              messageId={Field.MAX}
              value={options[activeTag]?.maxCapacity || 0}
              name={`maxCapacity`}
              onChange={handleChangeOption}
              type={"number"}
              min={1}
              required
            />
          </InlineFieldset>
          <MultipleFiledSet
            messageId={Field.CANCELLATION_POLICY}
            entity={Entity.POLICY}
            onCreate={() => {
              dispatch(creators.addPolicy(activeTag))
            }}
            onRemove={(key: string) => {
              dispatch(creators.removePolicy(activeTag, key))
            }}
            altCta={
              <Button
                variant={"outlined"}
                color={"secondary"}
                startIcon={<Add />}
                onClick={() => {
                  dispatch(creators.useWebPolicies(activeTag))
                }}
              >
                <FormattedMessage
                  id={"defaultWebPolicies"}
                  defaultMessage={"Default web policies"}
                />
              </Button>
            }
          >
            {Object.entries(options[activeTag]?.cancelPolicies || []).map(
              ([key, { interval, percentage }]) => {
                const encodePolicyInput = (
                  field: keyof PackageCancellationPolicyReq | "days"
                ) => encodeInputName("cancelPolicies", field, key)
                return (
                  <InlineFieldset key={key}>
                    <Input
                      messageId={Field.DAYS}
                      name={encodePolicyInput("days")}
                      value={
                        Math.floor(interval / millisecondsInDay) || undefined
                      }
                      type={"number"}
                      min={0}
                      onChange={handleChangeOptionEntity}
                    />
                    <MinuteInput
                      messageId={Field.INTERVAL}
                      name={encodePolicyInput("interval")}
                      value={interval % millisecondsInDay}
                      onChange={handleChangeOptionEntity}
                    />
                    <Input
                      messageId={Field.PERCENTAGE}
                      name={encodePolicyInput("percentage")}
                      value={percentage}
                      type={`number`}
                      onChange={handleChangeOptionEntity}
                      min={0}
                    />
                  </InlineFieldset>
                )
              }
            )}
          </MultipleFiledSet>
          <MultipleFiledSet
            messageId={Field.BLOCKS}
            entity={Entity.BLOCKING}
            onCreate={() => {
              dispatch(creators.addBlock(activeTag))
            }}
            onRemove={(key: string) => {
              dispatch(creators.removeBlock(activeTag, key))
            }}
          >
            {Object.entries(options[activeTag]?.blocks || []).map(
              ([key, block]) => {
                const encodeBlockInput = (field: keyof BlockingReq) =>
                  encodeInputName("blocks", field, key)
                return (
                  <InlineFieldset key={key}>
                    <MinuteInput
                      messageId={Field.OFFSET}
                      value={block.offset}
                      name={encodeBlockInput("offset")}
                      onChange={handleChangeOptionEntity}
                    />
                    <MinuteInput
                      messageId={Field.DURATION_BEFORE}
                      value={block.durationBefore}
                      name={encodeBlockInput("durationBefore")}
                      onChange={handleChangeOptionEntity}
                    />
                    <MinuteInput
                      messageId={Field.DURATION_ACTUAL}
                      value={block.durationActual}
                      name={encodeBlockInput("durationActual")}
                      onChange={handleChangeOptionEntity}
                    />
                    <MinuteInput
                      messageId={Field.DURATION_AFTER}
                      value={block.durationAfter}
                      name={encodeBlockInput("durationAfter")}
                      onChange={handleChangeOptionEntity}
                    />
                    <ComboBox
                      name={encodeBlockInput("resourceId")}
                      messageId={Field.GROUP}
                      options={resources
                        .toArray()
                        .map(([value, { title: resourceTitle }]) => ({
                          value,
                          content: resourceTitle,
                        }))}
                      onChange={handleChangeOptionEntity}
                      value={block.resourceId.toString()}
                      dense={false}
                    />
                    <Input
                      messageId={Field.QUANTITY_REQUIRED}
                      name={encodeBlockInput("quantityRequired")}
                      value={block.quantityRequired}
                      onChange={handleChangeOptionEntity}
                      type={"number"}
                      min={0}
                    />
                    <Input
                      messageId={Field.QUANTITY_BLOCKED}
                      name={encodeBlockInput("quantityBlocked")}
                      value={block.quantityBlocked}
                      onChange={handleChangeOptionEntity}
                      type={"number"}
                      min={0}
                    />
                  </InlineFieldset>
                )
              }
            )}
          </MultipleFiledSet>
          <MultipleFiledSet
            messageId={Field.ROLES}
            entity={Entity.ROLE}
            onCreate={() => {
              dispatch(creators.addRole(activeTag))
            }}
            onRemove={(key: string) => {
              dispatch(creators.removeRole(activeTag, key))
            }}
          >
            {Object.entries(options[activeTag]?.roles || []).map(
              ([
                key,
                {
                  role,
                  reward,
                  offset,
                  durationActual,
                  durationAfter,
                  durationBefore,
                  note,
                },
              ]) => {
                const encodeRoleInput = (field: keyof RequiredRoleReq) =>
                  encodeInputName("roles", field, key)
                return (
                  <div key={key}>
                    <InlineFieldset>
                      <ComboBox
                        messageId={Field.ROLE}
                        name={encodeRoleInput("role")}
                        value={role}
                        dense={false}
                        options={employeeRoleInputOptions}
                        onChange={handleChangeOptionEntity}
                      />
                      <Input
                        messageId={Field.REWARD}
                        name={encodeRoleInput("reward")}
                        value={reward}
                        type={`number`}
                        onChange={handleChangeOptionEntity}
                        min={0}
                      />
                    </InlineFieldset>
                    <InlineFieldset>
                      <MinuteInput
                        messageId={Field.OFFSET}
                        value={offset}
                        name={encodeRoleInput("offset")}
                        onChange={handleChangeOptionEntity}
                      />
                      <MinuteInput
                        messageId={Field.DURATION_BEFORE}
                        value={durationBefore}
                        name={encodeRoleInput("durationBefore")}
                        onChange={handleChangeOptionEntity}
                      />
                      <MinuteInput
                        messageId={Field.DURATION_ACTUAL}
                        value={durationActual}
                        name={encodeRoleInput("durationActual")}
                        onChange={handleChangeOptionEntity}
                      />
                      <MinuteInput
                        messageId={Field.DURATION_AFTER}
                        value={durationAfter}
                        name={encodeRoleInput("durationAfter")}
                        onChange={handleChangeOptionEntity}
                      />
                    </InlineFieldset>
                    <InlineFieldset>
                      <Input
                        messageId={Field.DESCRIPTION}
                        name={encodeRoleInput("note")}
                        onChange={handleChangeOptionEntity}
                        value={note}
                      />
                    </InlineFieldset>
                  </div>
                )
              }
            )}
          </MultipleFiledSet>
          <MultipleFiledSet
            messageId={Field.BONS}
            entity={Entity.BON}
            onCreate={() => {
              dispatch(creators.addBon(activeTag))
            }}
            onRemove={(key: string) => {
              dispatch(creators.removeBon(activeTag, key))
            }}
          >
            {Object.entries(options[activeTag]?.bons || []).map(
              ([
                key,
                {
                  content: bonTitle,
                  category,
                  count,
                  forNPersons,
                  responsibility,
                },
              ]) => {
                const encodeBonInput = (field: keyof BonReq) =>
                  encodeInputName("bon", field, key)
                return (
                  <InlineFieldset key={key}>
                    <BonCategorySelect
                      onChange={handleChangeOptionEntity}
                      dense={false}
                      value={category}
                      name={encodeBonInput("category")}
                    />
                    <Input
                      messageId={Field.TITLE}
                      name={encodeBonInput("content")}
                      value={bonTitle}
                      onChange={handleChangeOptionEntity}
                    />
                    <Input
                      messageId={Field.QUANTITY}
                      name={encodeBonInput("count")}
                      value={count}
                      onChange={handleChangeOptionEntity}
                      type={"number"}
                      min={1}
                    />
                    <Input
                      messageId={Field.FOR_N_PERSONS}
                      name={encodeBonInput("forNPersons")}
                      value={forNPersons}
                      onChange={handleChangeOptionEntity}
                      type={"number"}
                      min={1}
                    />
                    <ComboBox
                      messageId={Field.RESPONSIBILITIES}
                      name={encodeBonInput("responsibility")}
                      value={responsibility}
                      dense={false}
                      options={bonResponsibilityOptions}
                      onChange={handleChangeOptionEntity}
                      disableClearable
                    />
                  </InlineFieldset>
                )
              }
            )}
          </MultipleFiledSet>
        </EntityList>
      </Form>
    </>
  )
}

export default memo(ActivityDetail)
