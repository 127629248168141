import React from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  orderStatusesInputOptions,
  paymentMethodsInputOptions,
  periodInputOptions, reservationInputOptions,
  sortTypeInputOptions,
  sourceInputOptions
} from "../../utils/typeUtils"
import Filter, { FilterTypes } from "../Filter/Filter"
import { Field } from "../Messages/FieldMessage"
import { selectAgencyInputOptions } from "../../selectors/agencySelector"
import {
  selectOrderFilter,
  selectOrderSortType,
} from "../../selectors/orderSelector"
import { setOrderFilter, setOrderSortType } from "../../actions/orderActions"
import { getPeriodByDates } from "../../utils/timeUtils"
import { SortType } from "../../types/globalTypes"
import { selectEmployeesInputOptions } from "../../selectors/userSelector"
import { selectActivityInputOptions } from "../../selectors/activitySelector"
import { AppState } from "../../store"

export default function OrderFilter() {
  const dispatch = useDispatch()
  const agencyOptions = useSelector(selectAgencyInputOptions)
  const employeeOptions = useSelector(selectEmployeesInputOptions)
  const activityOptions = useSelector((state: AppState) =>
    selectActivityInputOptions(state, false, false)
  )
  const filters = useSelector(selectOrderFilter)
  const sortType = useSelector(selectOrderSortType)

  const handleChange = (key: string, value: string[]) => {
    if (key === Field.SORT_TYPE) {
      const [newSortType] = value as SortType[]
      dispatch(setOrderSortType(newSortType))
    } else {
      dispatch(setOrderFilter(key, value))
    }
  }

  return (
    <Filter
      fields={[
        {
          name: Field.AGENCY,
          options: agencyOptions,
          type: FilterTypes.MultiSelect,
          values: filters.get(Field.AGENCY)?.toArray() || [],
        },
        {
          name: Field.SOURCE,
          options: sourceInputOptions,
          type: FilterTypes.RadioList,
          values: filters.get(Field.SOURCE)?.toArray() || [],
        },
        {
          name: Field.ORDER_STATUS,
          options: orderStatusesInputOptions,
          type: FilterTypes.MultiSelect,
          values: filters.get(Field.ORDER_STATUS)?.toArray() || [],
        },
        {
          name: Field.PAYMENT_METHOD,
          options: paymentMethodsInputOptions,
          type: FilterTypes.RadioList,
          values: filters.get(Field.PAYMENT_METHOD)?.toArray() || [],
        },
        {
          name: Field.EMPLOYEE,
          options: employeeOptions,
          type: FilterTypes.MultiSelect,
          values: filters.get(Field.EMPLOYEE)?.toArray() || [],
        },
        {
          name: Field.ACTIVITY,
          options: activityOptions,
          type: FilterTypes.MultiSelect,
          values: filters.get(Field.ACTIVITY)?.toArray() || [],
        },
        {
          name: Field.CONTAINS_RESERVATION,
          options: reservationInputOptions,
          type: FilterTypes.RadioList,
          values: filters.get(Field.CONTAINS_RESERVATION)?.toArray() || [],
        },
        {
          name: Field.PERIOD,
          options: periodInputOptions,
          type: FilterTypes.RadioList,
          values: [getPeriodByDates(filters.get(Field.DATE))],
        },
        {
          name: Field.DATE,
          options: [],
          type: FilterTypes.DateRange,
          values: filters.get(Field.DATE)?.toArray() || [],
        },
        {
          name: Field.SORT_TYPE,
          options: sortTypeInputOptions,
          type: FilterTypes.RadioList,
          values: [sortType],
        },
      ]}
      onChange={handleChange}
    />
  )
}
