import {
  makeSessionState,
  SessionActions,
  SessionState,
} from "../models/sessionModel"
import { RequestStatus as Status, RequestStatus } from "../types/statusTypes"
import { ActionTypes, AnyAction } from "../types/actionTypes"

const initialState = makeSessionState()

export default function sessionReducer(
  state = initialState,
  action: AnyAction | null = null
): SessionState {
  const setStatus = (key: SessionActions, status: Status) =>
    state.set("statuses", state.statuses.set(key, status))

  switch (action?.type) {
    // logout
    case ActionTypes.LOGOUT_USER_SUCCEEDED:
      return initialState

    case ActionTypes.FETCH_SESSIONS_REQUESTED: {
      return setStatus("fetchSessions", RequestStatus.REQUESTED)
    }

    case ActionTypes.FETCH_SESSIONS_SUCCEEDED: {
      return setStatus("fetchSessions", RequestStatus.SUCCEEDED).set(
        "records",
        action.payload.sessions
      )
    }

    case ActionTypes.FETCH_SESSIONS_FAILED: {
      return setStatus("fetchSessions", RequestStatus.FAILED).set(
        "errors",
        initialState.errors.set("fetchSessions", action.payload.error)
      )
    }

    default:
      return state
  }
}
