import React, { useEffect, useState } from "react"
import { Button } from "@material-ui/core"
import { FormattedMessage } from "react-intl"
import { Add } from "@material-ui/icons"
import { Map, Set } from "immutable"
import { useSelector } from "react-redux"
import { EditComponentProps } from "@material-table/core"
import {
  ExtendedOrderReqAdmin,
  LiteEvent,
  OrderDataGrid,
} from "../../types/globalTypes"
import ComboBox from "../../components/ComboBox/ComboBox"
import {
  blankContactInfo,
  blankOrderData,
  roleToOrderEmployee,
} from "../../utils/typeUtils"
import FieldMessage, { Field } from "../../components/Messages/FieldMessage"
import {
  makePriceId,
  OptionPriceMap,
  usePricesFetch,
} from "../../hooks/priceHooks"
import {
  selectActivityRecords,
  selectOptionIdPackageIdMap,
  selectOptionMap,
} from "../../selectors/activitySelector"
import {
  useGetUsersAgency,
  useIsCompetent,
  useLanguage,
} from "../../hooks/interfaceHooks"
import { useOrderPriceFetch } from "../../hooks/orderHooks"
import Modal from "../../components/Modal/Modal"
import CellGroup from "../../components/CellGroup/CellGroup"
import Price from "../../components/Price/Price"
import { PackageOptionPriceResAdmin } from "../../types/apiTypes"
import { currentQuarterTime } from "../../utils/timeUtils"
import { noop } from "ramda-adjunct"

export function OrderActivityEditCell({
  rowData: { req = blankOrderData },
  onChange,
}: EditComponentProps<OrderDataGrid>) {
  const activityMap = useSelector(selectActivityRecords)
  const optionMap = useSelector(selectOptionMap)
  const canChangeAgency = useIsCompetent("packagePricesAgency", "R", "W")
  const usersAgency = useGetUsersAgency()
  const language = useLanguage()
  const { packageId, packageOrder } = req
  const optionId = packageOrder?.optionId || 0
  const agencyId = canChangeAgency ? req?.agencyId : usersAgency
  const [fetchPrices] = usePricesFetch(agencyId)
  const [fetchOrderPrice] = useOrderPriceFetch()
  const optionIdPackageIdMap = useSelector(selectOptionIdPackageIdMap)
  const [allowedPackageIds, setAllowedPackageIds] = useState<
    Set<string | undefined>
  >(Set([]))
  const [allowedOptionIds, setAllowedOptionIds] = useState<
    Set<number | undefined>
  >(Set([]))
  const [priceMap, setPriceMap] = useState<OptionPriceMap>(
    Map<string, PackageOptionPriceResAdmin>()
  )
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const price = priceMap.get(makePriceId(req.agencyId, optionId))

  const optionSelectedInfo = optionMap.get(optionId.toString())

  // fetch prices on agency change for filtering
  useEffect(() => {
    fetchPrices((prices: OptionPriceMap) => {
      const priceArray = prices.valueSeq().toArray()
      setAllowedPackageIds(
        Set(
          priceArray.map(({ packageOptionId }) =>
            optionIdPackageIdMap.get(packageOptionId)?.toString()
          )
        )
      )
      setAllowedOptionIds(Set(priceArray.map(({ packageOptionId: id }) => id)))
      setPriceMap(prices)
    })
    // eslint-disable-next-line
  }, [agencyId])

  const changeRow = (data: ExtendedOrderReqAdmin): void => {
    onChange(data)
  }

  const handleActivityChange = ({
    currentTarget: { valueAsNumber, value },
  }: LiteEvent) => {
    const activity = activityMap.get(value)
    if (activity) {
      const [{ id, requiredRoles }] = activity.options
      changeRow({
        ...req,
        packageId: valueAsNumber,
        packageOrder: req.packageOrder
          ? {
              ...req.packageOrder,
              optionId: id,
              orderEmployees: req.isLocked
                ? req.packageOrder.orderEmployees
                : requiredRoles.map(
                    roleToOrderEmployee(req.packageOrder?.dtStart)
                  ),
            }
          : {
              contactInfo: blankContactInfo,
              dtStart: currentQuarterTime().valueOf(),
              optionId: id,
              numberOfPersons: 1,
              orderEmployees: requiredRoles.map(
                roleToOrderEmployee(currentQuarterTime().valueOf())
              ),
            },
      })
    }
  }

  const handleOptionChange = ({
    currentTarget: { valueAsNumber: id, value },
  }: LiteEvent) => {
    if (req.packageOrder) {
      changeRow({
        ...req,
        packageOrder: {
          ...req.packageOrder,
          optionId: id,
          orderEmployees: req.isLocked
            ? req.packageOrder.orderEmployees
            : optionMap
                .get(value)
                ?.requiredRoles.map(
                  roleToOrderEmployee(req.packageOrder?.dtStart)
                ) || [],
        },
      })
    }
  }

  const handleCloseModal = () => {
    fetchOrderPrice(req)
      .then(([priceRes, _, { bonSum }]) => {
        changeRow({ ...req, ...priceRes, bonSum })
      })
      .catch(noop)
      .finally(() => setIsModalOpen(false))
  }

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  return (
    <>
      <CellGroup>
        <Button
          onClick={handleOpenModal}
          variant={"outlined"}
          color={"secondary"}
          startIcon={<Add />}
        >
          <FormattedMessage
            id={`setActivity`}
            defaultMessage={`Set activity`}
          />
        </Button>
        {packageId ? (
          <span>
            {
              activityMap.get(packageId.toString())?.translations[language]
                .title
            }
          </span>
        ) : null}
        {optionId ? (
          <span>
            {optionMap.get(optionId.toString())?.translations[language].title}
          </span>
        ) : null}
      </CellGroup>
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <CellGroup>
          {allowedPackageIds.size > 0 && (
            <ComboBox
              messageId={Field.ACTIVITY}
              name={`orderActivity`}
              options={activityMap
                .toArray()
                .filter(([id]) => allowedPackageIds.has(id))
                .map(([id, activity]) => ({
                  value: id,
                  content: activity.translations[language].title,
                }))}
              value={packageId ? packageId.toString() : null}
              onChange={handleActivityChange}
              size={`small`}
            />
          )}
          {allowedOptionIds.has(optionId) && (
            <ComboBox
              style={{ marginTop: 10 }}
              messageId={Field.OPTION}
              name={`orderOption`}
              options={(activityMap.get(packageId.toString())?.options || [])
                .filter(({ id }) => allowedOptionIds.has(id))
                .map((option) => ({
                  value: option.id.toString(),
                  content: option.translations[language].title,
                }))}
              value={optionId.toString()}
              onChange={handleOptionChange}
              size={`small`}
            />
          )}
          {Boolean(price?.price) && price?.price && (
            <span>
              <FieldMessage id={Field.PRICE} />
              {": "}
              <Price price={price.price} />
            </span>
          )}
          {price?.pricePerNCapitas && price.pricePerNCapitas.price > 0 && (
            <span>
              <FieldMessage id={Field.PRICE_PERSON} />
              {": "}
              <Price price={price.pricePerNCapitas.price} />
            </span>
          )}
          {optionSelectedInfo && (
            <span>
              <FormattedMessage
                id="activity.numberOfPersons"
                values={{
                  min: optionSelectedInfo.minCapacity,
                  max: optionSelectedInfo.maxCapacity,
                }}
                defaultMessage="Number of persons: {min} - {max} (min - max)"
              />
            </span>
          )}
        </CellGroup>
      </Modal>
    </>
  )
}
