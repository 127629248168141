import React, { ReactNode } from "react"
import { RequestStatus } from "../../types/statusTypes"
import Toast from "../Toast/Toast"
import EntityMessage, { Entity } from "../Messages/EntityMessage"
import ResultMessage from "../Messages/ResultMessage"

export type ResultAction = "fetch" | "save" | "delete" | "sendEmail"

interface IResultsProps {
  statuses: RequestStatus[]
  errors: ReactNode[]
  actions: Array<ResultAction>
  entity: Entity
}

export default function Results({
  statuses,
  errors,
  entity,
  actions,
}: IResultsProps) {
  return (
    <>
      {statuses.map((status, index) => [
        <Toast
          key={`failToast`}
          open={status === RequestStatus.FAILED}
          message={errors[index]}
          title={<EntityMessage id={entity} />}
          severity={"error"}
        />,
        <Toast
          key={`successToast`}
          open={
            status === RequestStatus.SUCCEEDED && actions[index] !== "fetch"
          }
          message={
            <ResultMessage action={actions[index]} entity={entity} />
          }
          title={<EntityMessage id={entity} />}
          severity={"success"}
        />,
      ])}
    </>
  )
}
