import { makeStyles } from "@material-ui/core/styles"

export default makeStyles({
  root: {
    pointerEvents: ({ isLoading }: { isLoading: boolean }) =>
      isLoading ? "none" : "inherit",
  },
  spinner: {
    position: "absolute",
    left: "calc(50% - 12px)",
    top: "calc(50% - 12px)",
  },
})
