import React, { ChangeEvent } from "react"
import { EditComponentProps } from "@material-table/core"
import { ReservationDataGrid } from "../../types/globalTypes"
import { Field } from "../../components/Messages/FieldMessage"
import { blankOrderData } from "../../utils/typeUtils"
import Input from "../../components/Input/Input"
import CellGroup from "../../components/CellGroup/CellGroup"

export default function ReservationGroupEditCell({
  rowData: { req = blankOrderData },
  onChange,
}: EditComponentProps<ReservationDataGrid>) {
  const handleChange = ({
    currentTarget: { value, name },
  }: ChangeEvent<HTMLInputElement>) => {
    onChange({ ...req, [name]: value })
  }
  return (
    <CellGroup>
      <Input
        messageId={Field.NAME}
        onChange={handleChange}
        name={`name`}
        value={req.name}
        dense
      />
      <Input
        messageId={Field.EMAIL}
        onChange={handleChange}
        name={`email`}
        value={req.email}
        type={"email"}
        dense
      />
      <Input
        messageId={Field.PHONE}
        onChange={handleChange}
        name={`phone`}
        value={req.phone}
        type={"tel"}
        dense
      />
    </CellGroup>
  )
}
