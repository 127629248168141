import React, { useCallback, useMemo } from "react"
import CollapseComponent from "../CollapseComponent/CollapseComponent"
import NavLink from "../NavLink/NavLink"
import RouteMessage from "../Messages/RouteMessage"
import config from "../../config"
import { useAuthorization } from "../../hooks/interfaceHooks"
import { AccessRights } from "../../types/apiTypes"

type Route = keyof typeof config.routes

const crmRoutes: Route[] = [
  "ordersList",
  "reservation",
  "report",
  "invoiceList",
  "voucherList",
  "activityList",
  "upsaleList",
  "agencyList",
  "userList"
]

const webRoutes: Route[] = [
  "productList",
  "packageList",
  "upsellingList",
  "pageList",
  "careerList",
  "newsList",
  "showList"
]

export default function NavLinks() {
  const accessRights = useAuthorization()

  const filterRoutes = useCallback(
    (routes: Route[]): Route[] =>
      routes.filter((route) =>
        config.routes[route].allowedAccess === null
          ? true
          : config.routes[route].allowedAccess?.some(([resource, operations]) =>
              operations.includes(accessRights[resource as keyof AccessRights])
            )
      ),
    [accessRights]
  )

  const memoizedRoutes = useMemo<{ crm: Route[]; web: Route[] }>(
    () => ({
      crm: filterRoutes(crmRoutes),
      web: filterRoutes(webRoutes),
    }),
    [filterRoutes]
  )

  return (
    <>
      {memoizedRoutes.crm.length > 0 ? (
        <CollapseComponent expanded title={`CRM`}>
          {memoizedRoutes.crm.map((route) => (
            <NavLink route={route} key={route}>
              <RouteMessage id={route} />
            </NavLink>
          ))}
        </CollapseComponent>
      ) : null}
      {memoizedRoutes.web.length > 0 ? (
        <CollapseComponent expanded title={`WEB`}>
          {memoizedRoutes.web.map((route) => (
            <NavLink route={route} key={route}>
              <RouteMessage id={route} />
            </NavLink>
          ))}
        </CollapseComponent>
      ) : null}
    </>
  )
}
