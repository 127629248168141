import performRequest, { getIdParam, getIdsParam } from "../utils/apiUtils"
import { HttpMethods } from "../types/globalTypes"
import {
  AgencyReq,
  AgencyResAdmin,
  IdRes,
  PackageOptionPriceReq,
  PackageOptionPriceResAdmin,
  StatusRes,
} from "../types/apiTypes"

export async function requestGetAgencies(
  basicAuth: string,
  ids: number[] = []
): Promise<AgencyResAdmin[]> {
  return performRequest({
    endpoint: `agency${getIdsParam(ids)}`,
    auth: basicAuth,
    method: HttpMethods.GET,
  })
}

export async function requestDeleteAgency(
  basicAuth: string,
  id: number
): Promise<StatusRes> {
  return performRequest({
    endpoint: `agency${getIdParam(id)}`,
    auth: basicAuth,
    method: HttpMethods.DELETE,
  })
}

export async function requestSaveAgency(
  basicAuth: string,
  agency: AgencyReq,
  id?: number
): Promise<IdRes<number>> {
  return performRequest({
    endpoint: `agency${getIdParam(id)}`,
    auth: basicAuth,
    method: id ? HttpMethods.PUT : HttpMethods.POST,
    body: agency,
  })
}

export async function requestSaveAgencyPackagePrices(
  basicAuth: string,
  id: number,
  body: PackageOptionPriceReq
): Promise<IdRes<number>> {
  return performRequest({
    endpoint: `packagePrices/1d/second${getIdParam(id)}`,
    auth: basicAuth,
    method: HttpMethods.POST,
    body,
  })
}

export async function requestSaveAgencyUpsalePrices(
  basicAuth: string,
  id: number,
  body: PackageOptionPriceReq
): Promise<IdRes<number>> {
  return performRequest({
    endpoint: `packageUpsalePrices/1d/second${getIdParam(id)}`,
    auth: basicAuth,
    method: HttpMethods.POST,
    body,
  })
}

export async function requestGetPackagePrices(
  basicAuth: string,
  agencyId: number
): Promise<PackageOptionPriceResAdmin[]> {
  return performRequest({
    endpoint: `packagePrices?agencyIds=${agencyId}`,
    auth: basicAuth,
    method: HttpMethods.GET,
  })
}

export async function requestGetUpsalePrices(
  basicAuth: string,
  agencyId: number
): Promise<PackageOptionPriceResAdmin[]> {
  return performRequest({
    endpoint: `packageUpsalePrices?agencyIds=${agencyId}`,
    auth: basicAuth,
    method: HttpMethods.GET,
  })
}
