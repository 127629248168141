import clsx from "clsx"
import React, { MouseEvent } from "react"
import * as colors from "@material-ui/core/colors"
import { LiteEvent } from "../../types/globalTypes"
import useStyles from "./ColorPickerStyles"
import { createLiteEvent } from "../../utils/typeUtils"

const palette = [
  "",
  colors.red["500"],
  colors.pink["500"],
  colors.purple["500"],
  colors.deepPurple["500"],
  colors.indigo["500"],
  colors.blue["500"],
  colors.cyan["500"],
  colors.teal["500"],
  colors.green["500"],
  colors.lightGreen["500"],
  colors.lime["500"],
  colors.yellow["500"],
  colors.amber["500"],
  colors.orange["500"],
  colors.deepOrange["500"],
]

interface IColorPickerProps {
  onChange: (e: LiteEvent) => void
  value?: string
}

export default function ColorPicker({ onChange, value }: IColorPickerProps) {
  const classes = useStyles()

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    onChange(createLiteEvent("color", e.currentTarget.value))
  }

  return (
    <ul className={classes.root}>
      {palette.map((color) => (
        <li key={color} className={classes.item}>
          <button
            type="button"
            className={clsx(
              classes.button,
              color === value && classes.activeButton
            )}
            onClick={handleClick}
            value={color}
            style={{ background: color || "transparent" }}
          >
            {color}
          </button>
        </li>
      ))}
    </ul>
  )
}
