import React, { useMemo, useState, memo, CSSProperties } from "react"
import { useIntl } from "react-intl"
import MaterialTable, { Column, MaterialTableProps } from "@material-table/core"
import { Typography } from "@material-ui/core"
import FilterList from "@material-ui/icons/FilterList"
import useStyles from "./DataGridStyles"
import {
  DataGridContainer,
  DataGridIcons,
  DataGridToolbar,
  cellStyleCompact,
  DataGridEditCell,
  DataGridEditRow,
  cellStyleComfort,
} from "./DataGridOverrides"
import ToolbarButton from "../ToolbarButton/ToolbarButton"
import localization from "./DataGridCzechTranslation"
import { Field, useFieldMessage } from "../Messages/FieldMessage"
import DataGridCsvButton from "./DataGridCSVButton"
import DataGridPdfButton from "./DataGridPDFButton"

export type ExtendedColumn<T extends object> = Column<T> & {
  exportField?: keyof T
  customExport?: (data: T) => string
  exportHeader?: string,
  exportSort?: number
}

export interface PaginationProps {
  onPageSizeChange: (page: number) => void
  onPageNumberChange: (page: number) => void
  pageSize: number
  pageNumber: number
}

type DataGridProps<T extends object> = {
  title?: React.ReactNode
  defaultFilterOpen?: boolean
  customFilter?: React.ReactNode
  cta?: React.ReactNode
  children?: React.ReactNode
  toolbarIcons?: React.ReactNode
  searchable?: boolean
  columns: ExtendedColumn<T>[]
  styleRow?: (item: T) => CSSProperties
  cellEditableFields?: Array<string | keyof T | undefined>
  onSearchChange?: (text: string) => void
} & MaterialTableProps<T> &
  Partial<PaginationProps>

const pageSizeOptions = [5, 10, 20, 100, 200]

function DataGrid<RowData extends object>({
  title,
  columns,
  actions,
  customFilter,
  defaultFilterOpen = false,
  cta,
  children,
  toolbarIcons,
  components,
  options,
  searchable = true,
  styleRow,
  cellEditableFields,
  data,
  onPageSizeChange,
  onPageNumberChange,
  onSearchChange,
  pageNumber,
  pageSize,
  ...props
}: DataGridProps<RowData>) {
  const classes = useStyles()
  const [isFilterOpen, setIsFilterOpen] = useState(defaultFilterOpen)
  const toggleFilter = () => setIsFilterOpen(!isFilterOpen)
  const { locale, formatMessage } = useIntl()

  const translateField = useFieldMessage()
  const memoizedColumns = useMemo(
    () =>
      columns.map(
        ({
          title: columnTitle,
          cellStyle,
          headerStyle,
          editComponent,
          width,
          field,
          editable,
          ...column
        }) => ({
          title: columnTitle ? translateField(columnTitle as Field) : undefined,
          cellStyle: {
            ...cellStyle,
            ...(actions ? cellStyleCompact : cellStyleComfort),
            ...(width ? { minWidth: width } : {}),
          },
          headerStyle: {
            ...headerStyle,
            ...(actions ? cellStyleCompact : cellStyleComfort),
            ...(width ? { minWidth: width } : {}),
          },
          ...(!column.lookup && {
            editComponent: editComponent || DataGridEditCell,
          }),
          editable:
            editable ||
            (cellEditableFields && typeof field === "string"
              ? cellEditableFields.includes(field)
                ? "always"
                : "never"
              : "always"),
          field,
          ...column,
        })
      ),
    // eslint-disable-next-line
    [locale, cellEditableFields]
  )

  const handleChangePageNumber = (number: number) => {
    onPageNumberChange && onPageNumberChange(number)
  }

  const handleChangePageSize = (size: number) => {
    onPageSizeChange && onPageSizeChange(size)
  }

  const handleSearchChange = (text: string) => {
    onSearchChange && onSearchChange(text)
  }

  return (
    <div className={classes.root}>
      {isFilterOpen && customFilter}
      {children}
      <MaterialTable
        icons={DataGridIcons}
        actions={actions}
        data={data}
        columns={memoizedColumns}
        onPageChange={handleChangePageNumber}
        onRowsPerPageChange={handleChangePageSize}
        onSearchChange={handleSearchChange}
        page={pageNumber}
        title={
          <>
            <Typography variant={"h6"} component={"h1"}>
              {title}
            </Typography>
            <DataGridCsvButton<RowData>
              columns={memoizedColumns}
              data={Array.isArray(data) ? data : []}
            />
            <DataGridPdfButton<RowData>
              columns={memoizedColumns}
              data={Array.isArray(data) ? data : []}
            />
            {customFilter && (
              <ToolbarButton
                icon={<FilterList />}
                onClick={toggleFilter}
                title={
                  isFilterOpen
                    ? formatMessage({
                        id: "closeFilter",
                        defaultMessage: "Close Filter",
                      })
                    : formatMessage({
                        id: "openFilter",
                        defaultMessage: "Open Filter",
                      })
                }
              />
            )}
            {toolbarIcons}
          </>
        }
        {...(locale === "cs" && { localization })}
        components={{
          EditRow: DataGridEditRow,
          Container: DataGridContainer,
          Toolbar: DataGridToolbar,
          ...components,
        }}
        options={{
          addRowPosition: "first",
          search: searchable,
          pageSize,
          initialPage: pageNumber,
          pageSizeOptions,
          paging: pageSize !== undefined && pageNumber !== undefined,
          actionsCellStyle: { ...cellStyleCompact, padding: 4 },
          rowStyle: styleRow,
          ...options,
        }}
        {...props}
      />
      {cta}
    </div>
  )
}

export default memo(
  DataGrid,
  // TODO: arePropsEqual might need more checks
  (
    prevProps: DataGridProps<object>,
    nextProps: DataGridProps<object>
  ): boolean =>
    prevProps.data === nextProps.data &&
    prevProps.columns === nextProps.columns &&
    prevProps.styleRow === nextProps.styleRow
) as typeof DataGrid
