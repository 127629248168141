import { Map, Record, RecordOf } from "immutable"
import { RequestStatus } from "../types/statusTypes"
import {
  PackageOptionResAdmin,
  PackageResAdmin,
  ResourceResAdmin,
} from "../types/apiTypes"

export type ActivityMap = Map<string, PackageResAdmin>

export type ResourceMap = Map<string, ResourceResAdmin>

export type OptionMap = Map<string, PackageOptionResAdmin>

const defaultStatuses = {
  fetchActivities: RequestStatus.INITIAL,
  saveActivity: RequestStatus.INITIAL,
  deleteActivity: RequestStatus.INITIAL,
  fetchResources: RequestStatus.INITIAL,
}

const makeStatuses: Record.Factory<typeof defaultStatuses> = Record(
  defaultStatuses
)

const defaultErrors = {
  fetchActivities: "",
  fetchResources: "",
  saveActivity: "",
  deleteActivity: "",
}

const makeErrors: Record.Factory<typeof defaultErrors> = Record(defaultErrors)

type ActivityStateType = {
  statuses: RecordOf<typeof defaultStatuses>
  errors: RecordOf<typeof defaultErrors>
  records: ActivityMap
  resources: ResourceMap
}

const defaultActivityState: ActivityStateType = {
  statuses: makeStatuses(),
  errors: makeErrors(),
  records: Map<string, PackageResAdmin>(),
  resources: Map<string, ResourceResAdmin>(),
}

export type ActivityState = RecordOf<ActivityStateType>

export type ActivityActions = keyof typeof defaultStatuses

export const makeActivityState: Record.Factory<ActivityStateType> = Record(
  defaultActivityState
)
