import { call, put, select } from "redux-saga/effects"
import {
  changeLocale,
  changeLocaleFailed,
  changeLocaleSucceeded,
  logoutUserFailed,
  logoutUserSucceeded,
  logUser,
  logUserFailed,
  logUserSucceeded,
  restoreInterfaceFailed,
  restoreInterfaceSucceeded,
} from "../actions/interfaceActions"
import { requestUserAuthentication } from "../api/interfaceRequests"
import {
  clearItems,
  generateBasicAuthToken,
  readItems,
  storeItems,
} from "../utils/apiUtils"
import { selectToken } from "../selectors/interfaceSelector"
import { UserRes } from "../types/apiTypes"
import { StorageItems } from "../types/globalTypes"

export function* authenticateUserSaga({
  payload: { email, password },
}: ReturnType<typeof logUser>) {
  try {
    let token: string = yield select(selectToken)
    if (email && password) {
      token = generateBasicAuthToken(email, password)
      yield call(storeItems, { token })
    }
    const user: UserRes = yield call(requestUserAuthentication, token)
    yield put(logUserSucceeded(user, token))
    // eslint-disable-next-line
  } catch (e: any) {
    yield put(logUserFailed(e.message))
  }
}

export function* logoutUserSaga() {
  try {
    yield call(clearItems, [`token`])
    yield put(logoutUserSucceeded())
    // eslint-disable-next-line
  } catch (e: any) {
    yield put(logoutUserFailed(e.message))
  }
}

export function* changeLocaleSaga({
  payload: { locale },
}: ReturnType<typeof changeLocale>) {
  try {
    yield call(storeItems, { locale })
    yield put(changeLocaleSucceeded(locale))
    // eslint-disable-next-line
  } catch (e: any) {
    yield put(changeLocaleFailed(e.message))
  }
}

export function* restoreInterfaceSaga() {
  try {
    const keys: Array<keyof StorageItems> = [
      `token`,
      `locale`,
      `zoom`,
      `orderFilters`,
      `sortType`,
    ]
    const { token, ...items }: Partial<StorageItems> = yield call(
      readItems,
      keys
    )
    if (token) {
      const user: UserRes = yield call(requestUserAuthentication, token)
      yield put(logUserSucceeded(user, token))
    }
    yield put(restoreInterfaceSucceeded(items))
    // eslint-disable-next-line
  } catch (e: any) {
    yield call(clearItems, [`token`, `locale`])
    yield put(restoreInterfaceFailed(e.message))
  }
}
