import React, { SyntheticEvent, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { FormattedMessage, useIntl } from "react-intl"
import { Payment, GetApp } from "@material-ui/icons"
import { fetchInvoices } from "../../actions/invoiceActions"
import {
  selectInvoiceList,
  selectInvoiceStatus,
} from "../../selectors/invoiceSelector"
import DataGrid from "../../components/DataGrid/DataGrid"
import Modal from "../../components/Modal/Modal"
import DateInput from "../../components/DateInput/DateInput"
import Form from "../../components/Form/Form"
import { paymentMethodsInputOptions } from "../../utils/typeUtils"
import RadioList from "../../components/RadioList/RadioList"
import { LiteEvent } from "../../types/globalTypes"
import InvoiceFilter from "../../components/InvoiceFilter/InvoiceFilter"
import { InvoiceResAdmin } from "../../types/apiTypes"
import { Field } from "../../components/Messages/FieldMessage"
import PaymentMethodMessage from "../../components/Messages/PaymentMethodMessage"
import InvoiceStatusMessage from "../../components/Messages/InvoiceStatusMessage"
import Price from "../../components/Price/Price"
import { requestDownloadInvoice } from "../../api/invoiceRequests"
import { selectToken } from "../../selectors/interfaceSelector"
import { downloadBlob } from "../../utils/apiUtils"
import { useIsCompetent, useLocalStoragePagination } from "../../hooks/interfaceHooks"
import { formatDateTime } from "../../utils/timeUtils"

export default function InvoiceList() {
  const token = useSelector(selectToken)
  const canAddPayment = useIsCompetent("invoice", "W")
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const invoiceList = useSelector(selectInvoiceList)
  const invoiceFetchStatus = useSelector(selectInvoiceStatus)
  const paginationProps = useLocalStoragePagination("invoice")
  const [invoiceState, setInvoiceState] = useState({
    isModalVisible: false,
    invoiceDate: Date.now(),
    invoicePaymentOptions: "",
  })

  useEffect(() => {
    dispatch(fetchInvoices())
  }, [dispatch])

  function handleCloseModal() {
    setInvoiceState({ ...invoiceState, isModalVisible: false })
  }

  function handleOpenModal() {
    setInvoiceState({ ...invoiceState, isModalVisible: true })
  }

  function handleInvoiceDateChange(e: LiteEvent<number>) {
    setInvoiceState({
      ...invoiceState,
      invoiceDate: e.currentTarget.value,
    })
  }

  function handleInvoiceOptionsChange(e: SyntheticEvent<HTMLInputElement>) {
    setInvoiceState({
      ...invoiceState,
      invoicePaymentOptions: e.currentTarget.value,
    })
  }

  function handleSubmit() {
    console.log("handle Submit")
  }

  const handleDownloadInvoice = async (id: number) => {
    const file = await requestDownloadInvoice(token, id)
    downloadBlob(file, `invoice_${id}.pdf`)
  }

  return (
    <>
      <DataGrid<InvoiceResAdmin>
        {...paginationProps}
        columns={[
          { title: Field.AGENCY, field: "title", exportField: "title" },
          { title: Field.NUMBER, field: "id", exportField: "id" },
          {
            title: Field.DATE,
            field: "createdAt",
            render: ({ createdAt }: InvoiceResAdmin) => formatDateTime(createdAt),
            customExport: ({ createdAt }: InvoiceResAdmin) =>
              formatDateTime(createdAt, true),
          },
          {
            title: Field.DUE_DATE,
            field: "dueDate",
            render: ({ dueDate }: InvoiceResAdmin) => formatDateTime(dueDate),
            customExport: ({ dueDate }: InvoiceResAdmin) =>
              formatDateTime(dueDate, true),
          },
          {
            title: Field.PRICE,
            field: "price",
            render: ({ orders }: InvoiceResAdmin) => (
              <Price
                price={orders.reduce((total, { price }) => total + price, 0)}
              />
            ),
            customExport: ({ orders }: InvoiceResAdmin) =>
              orders.reduce((total, { price }) => total + price, 0).toString(),
          },
          {
            title: Field.PAYMENT_METHOD,
            field: "paymentMethod",
            render: ({ paymentMethod }: InvoiceResAdmin) => (
              <PaymentMethodMessage id={paymentMethod} />
            ),
            exportField: "paymentMethod",
          },
          {
            title: Field.PAYMENT_STATUS,
            field: "status",
            render: ({ status }: InvoiceResAdmin) => (
              <InvoiceStatusMessage id={status} />
            ),
            exportField: "status",
          },
        ]}
        data={invoiceList}
        actions={[
          () => ({
            icon: () => <Payment />,
            tooltip: formatMessage({
              id: "markPayment",
              defaultMessage: `Mark payment`,
            }),
            onClick: handleOpenModal,
            hidden: !canAddPayment,
          }),
          (row) => ({
            icon: () => <GetApp />,
            tooltip: formatMessage({
              id: "downloadInvoice",
              defaultMessage: `Download invoice`,
            }),
            onClick: () => handleDownloadInvoice(row.id),
          }),
        ]}
        customFilter={<InvoiceFilter />}
      />

      <Modal onClose={handleCloseModal} open={invoiceState.isModalVisible}>
        <Form
          title={
            <FormattedMessage
              id={"addPayment"}
              defaultMessage={"Add payment"}
            />
          }
          onSubmit={handleSubmit}
          statuses={[invoiceFetchStatus]}
        >
          <DateInput
            name={"invoiceDate"}
            messageId={Field.DATE}
            value={invoiceState.invoiceDate}
            onChange={handleInvoiceDateChange}
          />
          <RadioList
            value={invoiceState.invoicePaymentOptions}
            options={paymentMethodsInputOptions}
            name={"invoicePaymentOptions"}
            onChange={handleInvoiceOptionsChange}
          />
        </Form>
      </Modal>
    </>
  )
}
