import React, { ReactNode, useState } from "react"
import { ClickAwayListener, IconButton, Tooltip } from "@material-ui/core"
import { Info as InfoIcon } from "@material-ui/icons"
import useStyles from "./IconCellStyles"

interface IIconCellProps {
  icon?: ReactNode
  tooltip?: ReactNode
  children?: ReactNode
  element?: "span" | "strong"
}

export default function IconCell({
  children,
  icon,
  tooltip,
  element: Element = "span",
}: IIconCellProps) {
  const [isOpen, setIsOpen] = useState(false)
  const classes = useStyles()
  const toggleOpen = () => setIsOpen(!isOpen)
  const useTooltip = Array.isArray(tooltip) ? tooltip.length : tooltip

  const handleClickAway = () => setIsOpen(false)

  return useTooltip ? (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Tooltip title={tooltip || ``} open={isOpen}>
        <div className={classes.root}>
          <IconButton size={`small`} onClick={toggleOpen}>
            {icon || <InfoIcon />}
          </IconButton>
          <Element className={classes.text}>{children}</Element>
        </div>
      </Tooltip>
    </ClickAwayListener>
  ) : (
    <>{children}</>
  )
}
