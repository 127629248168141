import React, { useEffect, useState } from "react"
import { Set } from "immutable"
import { FormattedMessage } from "react-intl"
import { Navigate } from "react-router-dom"
import { useSelector } from "react-redux"
import Form from "../../components/Form/Form"
import Input from "../../components/Input/Input"
import { Role } from "../../types/apiTypes"
import { IFormData, LiteEvent } from "../../types/globalTypes"
import Results from "../../components/Results/Results"
import Select from "../../components/Select/Select"
import RouteMessage from "../../components/Messages/RouteMessage"
import { Field } from "../../components/Messages/FieldMessage"
import { Entity } from "../../components/Messages/EntityMessage"
import { useUserFetch, useUserSave } from "../../hooks/userHooks"
import {
  employeeRoleInputOptions,
  idParamToPost,
  roleInputOptions,
} from "../../utils/typeUtils"
import { useAgencyFetch } from "../../hooks/agencyHooks"
import { selectLoggedUser } from "../../selectors/interfaceSelector"
import CheckboxList from "../../components/CheckboxList/CheckboxList"
import { selectLastInsertedUser } from "../../selectors/userSelector"
import { RequestStatus } from "../../types/statusTypes"
import config from "../../config"
import Checkbox from "../../components/Checkbox/Checkbox"
import { useIsCompetent } from "../../hooks/interfaceHooks"
import { selectAgencyInputOptions } from "../../selectors/agencySelector"
import { useIdParam } from "../../hooks/routerHooks"

const ROLE_EMPLOYEE = "employee"

const inputOptions = [
  ...roleInputOptions,
  {
    value: ROLE_EMPLOYEE,
    content: <FormattedMessage id={"employee"} defaultMessage={"Employee"} />,
  },
]

export default function UserDetail() {
  const id = useIdParam()
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
  const canChangeRole = useIsCompetent("uiUserRoles", "W")
  const { agencyId: userAgencyId } = useSelector(selectLoggedUser) || {
    agencyId: null,
  }
  const agencyInputOptions = useSelector(selectAgencyInputOptions)
  const [save, saveStatus, saveError] = useUserSave()
  const [userMap, fetchStatus, fetchError] = useUserFetch(id)
  const [, agencyFetchStatus, agencyFetchError] = useAgencyFetch()
  const [userRoles, setUserRoles] = useState<Set<Role>>(
    canChangeRole ? Set(["Dancer"]) : Set(["Agency"])
  )
  const [agencyId, setAgencyId] = useState<number | null>(
    canChangeRole ? null : userAgencyId
  )
  const lastInsertedUser = useSelector(selectLastInsertedUser)
  const user = userMap.get(id)
  const hasEmployeeRole: boolean = employeeRoleInputOptions.some(({ value }) =>
    userRoles.has(value)
  )

  useEffect(() => {
    if (user) {
      const { roles, agencyId: agency } = user
      setAgencyId(canChangeRole ? agency : userAgencyId)
      setUserRoles(Set(roles))
    }
  }, [user, canChangeRole, userAgencyId])

  const handleSubmit = (data: IFormData) => {
    if (data.nickname && data.email && data.fullName) {
      setIsSubmitted(true)
      save(
        {
          id: idParamToPost(id),
          nickname: data.nickname,
          sendInvoice: !!data.sendInvoice,
          accountNumber: data.accountNumber,
          address: data.address,
          agencyId: userRoles.has("Agency") ? agencyId : null,
          crn: data.crn,
          email: data.email,
          fullName: data.fullName,
          note: data.note,
          phone: data.phone,
          roles: [...userRoles],
          vatId: data.vatId,
        },
        id
      )
    }
  }

  const handleUserRoleChange = ({ currentTarget: { value } }: LiteEvent) => {
    if (value === ROLE_EMPLOYEE) {
      if (hasEmployeeRole) {
        setUserRoles(
          Set(
            roleInputOptions
              .filter(({ value: role }) => userRoles.has(role))
              .map(({ value: role }) => role)
          )
        )
      } else {
        setUserRoles(userRoles.add("CoordinatorInternal"))
      }
    } else {
      const role = value as Role
      setUserRoles(
        userRoles.has(role) ? userRoles.delete(role) : userRoles.add(role)
      )
    }
  }

  const handleUserAgencyChange = ({
    currentTarget: { valueAsNumber },
  }: LiteEvent) => {
    setAgencyId(valueAsNumber)
  }

  if (isSubmitted && !id && saveStatus === RequestStatus.SUCCEEDED)
    return (
      <Navigate
        to={config.routes.userDetail.path(lastInsertedUser.id.toString())}
      />
    )

  return (
    <>
      <Results
        statuses={[saveStatus, fetchStatus]}
        errors={[saveError, fetchError]}
        actions={["save", "fetch"]}
        entity={Entity.USER}
      />
      <Results
        statuses={[agencyFetchStatus]}
        errors={[agencyFetchError]}
        actions={["fetch"]}
        entity={Entity.AGENCY}
      />
      <Form
        title={<RouteMessage id={"userDetail"} />}
        statuses={[saveStatus, fetchStatus]}
        onSubmit={handleSubmit}
        useGenericCta
      >
        <Input
          messageId={Field.EMAIL}
          name={`email`}
          type={`email`}
          defaultValue={user?.email}
          required
          autoFocus
        />
        <Input
          messageId={Field.NICKNAME}
          name={`nickname`}
          defaultValue={user?.nickname}
          required
        />
        <Input
          messageId={Field.NAME}
          name={`fullName`}
          defaultValue={user?.fullName}
          required
        />
        <Input
          messageId={Field.PHONE}
          name={`phone`}
          defaultValue={user?.phone}
          type={`tel`}
        />
        <Input messageId={Field.CRN} name={`crn`} defaultValue={user?.crn} />
        <Input
          messageId={Field.VAT_ID}
          name={`vatId`}
          defaultValue={user?.vatId}
        />
        <Input
          messageId={Field.ADDRESS}
          name={`address`}
          defaultValue={user?.address}
        />
        <Input messageId={Field.NOTE} name={`note`} defaultValue={user?.note} />
        <Input
          messageId={Field.ACCOUNT_NUMBER}
          name={`accountNumber`}
          defaultValue={user?.accountNumber}
        />
        <Checkbox
          messageId={Field.SEND_INVOICE}
          name={"sendInvoice"}
          key={user ? `sendInvoice-refresh` : `sendInvoice`}
          defaultChecked={user?.sendInvoice}
        />
        {canChangeRole && (
          <>
            <CheckboxList
              options={inputOptions}
              name={`role`}
              messageId={Field.ROLE}
              onChange={handleUserRoleChange}
              values={userRoles}
              value={hasEmployeeRole ? ROLE_EMPLOYEE : undefined}
            />
            {hasEmployeeRole && (
              <CheckboxList
                name={`job`}
                messageId={Field.POSITION}
                options={employeeRoleInputOptions}
                onChange={handleUserRoleChange}
                values={userRoles}
              />
            )}
            {userRoles.has("Agency") && (
              <Select
                messageId={Field.AGENCY}
                name={"agencyId"}
                value={agencyId}
                options={agencyInputOptions}
                onChange={handleUserAgencyChange}
              />
            )}
          </>
        )}
      </Form>
    </>
  )
}
