import { createStyles, makeStyles, Theme } from "@material-ui/core"

export default makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      minWidth: 0,
      color: theme.palette.primary.dark,
    },
  })
)
