import React, { ReactElement } from "react"
import config from "../../config"
import { useAuthorization } from "../../hooks/interfaceHooks"
import { AccessRights } from "../../types/apiTypes"
import UnauthorizedMessage from "../UnauthorizedMessage/UnauthorizedMessage"
import { useSelector } from "react-redux"
import { selectRestorationFetchStatus } from "../../selectors/interfaceSelector"
import { RequestStatus } from "../../types/statusTypes"

interface IAuthRouteProps {
  route: keyof typeof config.routes
  element: React.ReactElement
}

export default function AuthRoute({
  route,
  element,
}: IAuthRouteProps): ReactElement<IAuthRouteProps> {
  const { allowedAccess } = config.routes[route]
  const accessRights = useAuthorization()
  const interfaceFetchStatus = useSelector(selectRestorationFetchStatus)

  if (
    interfaceFetchStatus === RequestStatus.REQUESTED ||
    interfaceFetchStatus === RequestStatus.INITIAL
  )
    return <span>Loading</span>

  if (!allowedAccess) return element

  if (
    allowedAccess.some(([resource, operations]) =>
      operations.includes(accessRights[resource as keyof AccessRights])
    )
  )
    return element

  return <UnauthorizedMessage />
}
