import React from "react"
import { Entity } from "./EntityMessage"
import { ResultAction } from "../Results/Results"
import ActionMessage from "./ActionMessage"
import { FormattedMessage } from "react-intl"

interface IResultMessageProps {
  action: ResultAction
  entity: Entity
}

export default function ResultMessage({ entity, action }: IResultMessageProps) {
  switch (entity) {
    default:
      return (
        <span>
          <ActionMessage id={action} />
          &nbsp;
          <FormattedMessage
            id={"wasSuccessful"}
            defaultMessage={"was sucessfull"}
          />
        </span>
      )
  }
}
