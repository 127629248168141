import React from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { useSelector } from "react-redux"
import FindInPageIcon from "@material-ui/icons/FindInPage"
import LaunchIcon from "@material-ui/icons/Launch"
import { useNavigate } from "react-router-dom"
import DataGrid, { ExtendedColumn } from "../../components/DataGrid/DataGrid"
import config from "../../config"
import Button from "../../components/Button/Button"
import OrderFilter from "../../components/OrderFilter/OrderFilter"
import Summary from "../../components/Summary/Summary"
import { VoucherDataGrid } from "../../types/globalTypes"
import { getVoucherSurcharge } from "../../utils/apiUtils"
import {
  VoucherBonsCell,
  VoucherContentsCells,
  VoucherSurchargeCell,
} from "./VoucherListCells"
import RouteMessage from "../../components/Messages/RouteMessage"
import { Field } from "../../components/Messages/FieldMessage"
import BonSummary from "../../components/BonSummary/BonSummary"
import { formatDateTime } from "../../utils/timeUtils"
import CenterFocusStrongIcon from "@material-ui/icons/CenterFocusStrong"
import {
  selectVoucherBonSum,
  selectVoucherDataGrid,
  selectVoucherSummary,
} from "../../selectors/voucherSelector"
import { useOrderFetch } from "../../hooks/orderHooks"
import StatusProgressBar from "../../components/StatusProgressBar/StatusProgressBar"
import { useLocalStoragePagination } from "../../hooks/interfaceHooks"
import Number from "../../components/Number/Number"
import SummaryMessage from "../../components/Messages/SummaryMessage"

export const voucherDataGridCols: ExtendedColumn<VoucherDataGrid>[] = [
  {
    title: Field.AGENCY,
    field: `agency`,
    exportField: "agency",
  },
  {
    title: Field.NAME,
    field: `name`,
    exportField: "name",
  },
  {
    title: Field.NUMBER_OF_PERSONS,
    field: `numberOfPersons`,
    customExport: (data: VoucherDataGrid) =>
      data.packageOrder?.numberOfPersons.toString() ||
      data.reservation?.numberOfPersons?.toString() ||
      "",
    render: (data: VoucherDataGrid) =>
      data.packageOrder?.numberOfPersons.toString() ||
      data.reservation?.numberOfPersons?.toString() ||
      "",
  },
  {
    title: Field.CONTENT,
    field: `packageOrder.optionId`,
    render: VoucherContentsCells,
    customExport: (data: VoucherDataGrid) =>
      data.packageOrder?.option.translations.CS.title || "",
    sorting: false,
  },
  {
    title: Field.BONS,
    field: `packageOrder.optionId`,
    render: VoucherBonsCell,
    customExport: (voucher: VoucherDataGrid) =>
      voucher.bonSum
        .map(
          ({ count, category, content }) => `${count} × ${category} ${content}`
        )
        .join(", "),
    sorting: false,
    width: 250,
  },
  {
    title: Field.SURCHARGE,
    field: `packageOrder.optionId`,
    render: VoucherSurchargeCell,
    customSort: (a, b) => getVoucherSurcharge(a) - getVoucherSurcharge(b),
  },
  {
    title: Field.DATE_CREATED,
    field: `dtStart`,
    type: "datetime",
    render: ({ packageOrder }: VoucherDataGrid) =>
      formatDateTime(packageOrder?.dtStart),
    customExport: ({ packageOrder }: VoucherDataGrid) =>
      formatDateTime(packageOrder?.dtStart),
  },
  {
    title: Field.DATE_USED,
    field: `voucherUsedAt`,
    type: "datetime",
    render: ({ voucherUsedAt }: VoucherDataGrid) =>
      formatDateTime(voucherUsedAt),
    customExport: ({ voucherUsedAt }: VoucherDataGrid) =>
      formatDateTime(voucherUsedAt),
  },
  {
    title: Field.STATUS,
    field: `status`,
    exportField: `status`,
  },
]

export default function VoucherList() {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const vouchers = useSelector(selectVoucherDataGrid)
  const summary = useSelector(selectVoucherSummary)
  const bonBrief = useSelector(selectVoucherBonSum)
  const statuses = useOrderFetch()
  const paginationProps = useLocalStoragePagination("voucher")
  const handleSessionReportRedirect = () =>
    navigate(config.routes.sessionList.path())

  const handleValidateClick = () =>
    navigate(config.routes.voucherDetail.path(""))

  return (
    <>
      <StatusProgressBar statuses={statuses} />
      <DataGrid<VoucherDataGrid>
        {...paginationProps}
        title={<RouteMessage id={"voucherList"} />}
        customFilter={<OrderFilter />}
        columns={voucherDataGridCols}
        cta={
          <Button
            onClick={handleSessionReportRedirect}
            variant="contained"
            color={`primary`}
            startIcon={<LaunchIcon />}
          >
            <FormattedMessage
              id={`sessionReport`}
              defaultMessage={`Session report`}
            />
          </Button>
        }
        data={vouchers}
        actions={[
          (row) => ({
            icon: () => <CenterFocusStrongIcon />,
            tooltip: formatMessage({
              id: "validateVoucher",
              defaultMessage: "Validate voucher",
            }),
            onClick: () =>
              navigate(config.routes.voucherDetail.path(row.voucherId)),
          }),
        ]}
      >
        <Summary
          summary={[
            {
              title: <Number value={summary.vouchers} />,
              description: <SummaryMessage id={"vouchers"} />,
            },
            {
              title: <Number value={summary.guests} />,
              description: <SummaryMessage id={"guests"} />,
            },
            {
              title: <Number value={summary.surcharge} currency />,
              description: <SummaryMessage id={"surcharge"} />,
            },
            {
              title: <Number value={summary.bons} />,
              description: <SummaryMessage id={"bons"} />,
              tooltip: <BonSummary summary={bonBrief} />,
            },
          ]}
        />
        <Button
          onClick={handleValidateClick}
          variant="contained"
          color={`primary`}
          startIcon={<FindInPageIcon />}
        >
          <FormattedMessage
            id={`validateVoucher`}
            defaultMessage={`Validate voucher`}
          />
        </Button>
      </DataGrid>
    </>
  )
}
