import { useState } from "react"
import { useSelector } from "react-redux"
import {
  ITagEntities,
  ITranslations,
  StringMap,
  TranslationMap,
} from "../types/globalTypes"
import { RequestStatus } from "../types/statusTypes"
import { selectToken } from "../selectors/interfaceSelector"
import {
  requestDeleteTag,
  requestGetTags,
  requestSaveTag,
} from "../api/tagRequests"
import {
  blankSingleTranslations,
  fullToSimpleTranslation,
  isNumeric, listToPriority,
  simpleToFullTranslation
} from "../utils/typeUtils"
import { delay } from "../utils/apiUtils"
import { TagResAdmin } from "../types/apiTypes"
import { removeKey, renameKeys } from "../utils/objectUtils"
import { arrayMove } from "../utils/arrayUtils"
import { uid } from "../utils/stringUtils"

export const firstTagId = "erste"

export function useTagState() {
  const translations = { [firstTagId]: blankSingleTranslations }
  const [tags, setTags] = useState<string[]>([firstTagId])
  const [titles, setTitles] = useState<ITranslations>(translations)
  const [contents, setContents] = useState<ITranslations>(translations)
  const [entities, setEntities] = useState<ITagEntities>({ [firstTagId]: [] })
  const [activeTag, setActiveTag] = useState<string>(firstTagId)
  return {
    tags,
    titles,
    contents,
    entities,
    activeTag,
    handleActiveTagChange(tagId: string) {
      setActiveTag(tagId)
    },
    handleTagCreate(callback?: (tagId: string) => void) {
      const tagId = uid()
      setTags([...tags, tagId])
      setTitles({ ...titles, [tagId]: blankSingleTranslations })
      setContents({ ...contents, [tagId]: blankSingleTranslations })
      setEntities({ ...entities, [tagId]: [] })
      setActiveTag(tagId)
      callback && callback(tagId)
      return tagId
    },
    handleTagDelete(callback?: (tagId: string) => void) {
      if (tags.length > 1) {
        const reducedTags = tags.filter((i) => i !== activeTag)
        setTags(reducedTags)
        setActiveTag(reducedTags[0])
        setTitles(removeKey(titles, activeTag))
        setContents(removeKey(contents, activeTag))
        setEntities(removeKey(entities, activeTag))
        callback && callback(activeTag)
      }
    },
    handleTagSort(source: number, destination: number) {
      setTags((items) => arrayMove(items, source, destination))
    },
    handleTagTitleChange(value: string, language: string) {
      setTitles({
        ...titles,
        [activeTag]: { ...titles[activeTag], [language]: value },
      })
    },
    handleTagContentChange(value: string, language: string) {
      setContents({
        ...contents,
        [activeTag]: { ...contents[activeTag], [language]: value },
      })
    },
    handleEntitiesChange(name: string, values: string[]) {
      setEntities({ ...entities, [activeTag]: values })
    },
    fillWithData(
      ids: string[],
      translationRes: TranslationMap[],
      entityRes: Array<string[]>
    ) {
      if (ids.length === 0 || translationRes.length === 0) return
      setTitles(
        ids.reduce((acc: ITranslations, id: string, index: number) => {
          acc[id] = fullToSimpleTranslation(translationRes[index], "title")
          return acc
        }, {} as ITranslations)
      )
      setContents(
        ids.reduce((acc: ITranslations, id: string, index: number) => {
          acc[id] = fullToSimpleTranslation(translationRes[index], "content")
          return acc
        }, {} as ITranslations)
      )
      setEntities(
        ids.reduce((acc: ITagEntities, id: string, index: number) => {
          acc[id] = entityRes[index] || []
          return acc
        }, {} as ITagEntities)
      )
      setTags(ids)
      setActiveTag(ids[0])
    },
    handleTagSave(apiIds: number[]): StringMap {
      const renameMap = tags.reduce((acc: StringMap, tag, index) => {
        const id = apiIds[index]?.toString()
        if (id !== tag) {
          acc[tag] = id
        }
        return acc
      }, {})
      setTags(apiIds.map((id) => id.toString()))
      setTitles(renameKeys(titles, renameMap))
      setContents(renameKeys(contents, renameMap))
      setEntities(renameKeys(entities, renameMap))
      setActiveTag(apiIds[0]?.toString())
      return renameMap
    },
  }
}

export function useTagFetch(): [
  (
    entity: "product" | "gallery" | "package" | "upsale",
    callback: (tags: Array<TagResAdmin>) => void,
    category?: string
  ) => Promise<null>,
  RequestStatus,
  string
] {
  const token = useSelector(selectToken)
  const [status, setStatus] = useState(RequestStatus.INITIAL)
  const [error, setError] = useState("")

  return [
    async (
      entity: "product" | "gallery" | "package" | "upsale",
      callback: (tags: Array<TagResAdmin>) => void,
      category?: string
    ): Promise<null> => {
      try {
        setStatus(RequestStatus.REQUESTED)
        const tags = await requestGetTags(token, entity, category)
        callback(tags)
        setStatus(RequestStatus.SUCCEEDED)
        // eslint-disable-next-line
      } catch (e: any) {
        console.error(e)
        setStatus(RequestStatus.FAILED)
        setError(e.message)
      } finally {
        await delay(500)
        setError("")
        setStatus(RequestStatus.INITIAL)
      }
      return null
    },
    status,
    error,
  ]
}

export function useTagSave(
  entity: "product" | "gallery" | "package" | "upsale",
  defaultCategory?: string
): [
  (
    tags: string[],
    entities: ITagEntities,
    titles: ITranslations,
    contents?: ITranslations,
    category?: string
  ) => Promise<null | number[]>,
  RequestStatus,
  string
] {
  const token = useSelector(selectToken)
  const [status, setStatus] = useState(RequestStatus.INITIAL)
  const [error, setError] = useState("")

  return [
    async (
      tags: string[],
      entities: ITagEntities,
      titles: ITranslations,
      contents?: ITranslations,
      category?: string
    ): Promise<null | number[]> => {
      try {
        setStatus(RequestStatus.REQUESTED)
        const responses = await Promise.all(
          tags.map((tag, idx) =>
            requestSaveTag(
              token,
              {
                id: isNumeric(tag) ? parseFloat(tag) : 0,
                priority: idx + 1,
                isActive: true,
                category: category || defaultCategory || "",
                entityIds: listToPriority(entities[tag]),
                translations: simpleToFullTranslation(
                  [
                    titles[tag],
                    contents ? contents[tag] : blankSingleTranslations,
                  ],
                  ["title", "content"]
                ),
              },
              entity,
              isNumeric(tag) ? parseFloat(tag) : undefined
            )
          )
        )
        setStatus(RequestStatus.SUCCEEDED)
        return responses.map((response) => response.id)
        // eslint-disable-next-line
      } catch (e: any) {
        setStatus(RequestStatus.FAILED)
        setError(e.message)
        return null
      } finally {
        await delay(500)
        setStatus(RequestStatus.INITIAL)
      }
    },
    status,
    error,
  ]
}

export function useTagDelete(
  entity: "product" | "gallery" | "package" | "upsale"
): [(id: number) => Promise<null>, RequestStatus, string] {
  const token = useSelector(selectToken)
  const [status, setStatus] = useState(RequestStatus.INITIAL)
  const [error, setError] = useState("")

  return [
    async (id: number): Promise<null> => {
      try {
        setStatus(RequestStatus.REQUESTED)
        await requestDeleteTag(token, entity, id)
        setStatus(RequestStatus.SUCCEEDED)
        // eslint-disable-next-line
      } catch (e: any) {
        setStatus(RequestStatus.FAILED)
        setError(e.message)
      } finally {
        await delay(500)
        setError("")
        setStatus(RequestStatus.INITIAL)
      }
      return null
    },
    status,
    error,
  ]
}
