import React from "react"
import { FormattedMessage } from "react-intl"
import { BonGroup } from "../../types/globalTypes"
import FieldMessage, { Field } from "./FieldMessage"

interface IBonGroupMessageProps {
  id: BonGroup
}

export default function BonGroupMessage({ id }: IBonGroupMessageProps) {
  switch (id) {
    case BonGroup.T_SHIRT:
      return <FormattedMessage id={`T_SHIRT`} defaultMessage={`T_SHIRT`} />

    case BonGroup.TOKEN:
      return <FormattedMessage id={`TOKEN`} defaultMessage={`TOKEN`} />

    case BonGroup.OTHER:
      return <FormattedMessage id={`OTHER`} defaultMessage={`OTHER`} />

    case BonGroup.TIPPING_DOLLAR:
      return (
        <FormattedMessage
          id={`TIPPING_DOLLAR`}
          defaultMessage={`TIPPING_DOLLAR`}
        />
      )

    case BonGroup.BEER_CARD:
      return <FormattedMessage id={`BEER_CARD`} defaultMessage={`BEER_CARD`} />

    case BonGroup.DRINKS:
      return <FieldMessage id={Field.DRINKS} />
    default:
      return null
  }
}
