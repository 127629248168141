import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Tooltip from "@material-ui/core/Tooltip"
import { Badge } from "@material-ui/core"
import { OrderEmployeeStatus, Role } from "../../types/apiTypes"
import RoleMessage from "../Messages/RoleMessage"

interface IEmployeeCellProps {
  name: string
  status: OrderEmployeeStatus
  role: Role
}

const useStyles = makeStyles((theme) => ({
  [OrderEmployeeStatus.PENDING]: {
    backgroundColor: theme.palette.primary.main,
  },
  [OrderEmployeeStatus.DECLINED]: {
    backgroundColor: theme.palette.error.main,
  },
  [OrderEmployeeStatus.ACCEPTED]: {
    backgroundColor: "green",
  },
}))

export default function EmployeeCell({
  name,
  status,
  role,
}: IEmployeeCellProps) {
  const classes = useStyles()
  return (
    <Tooltip title={status}>
      <Badge variant={"dot"} classes={{ badge: classes[status] }}>
        {name} (<RoleMessage id={role} />)
      </Badge>
    </Tooltip>
  )
}
