import { ActionTypes } from "../types/actionTypes"
import { UpsaleActions, UpsaleMap } from "../models/upsaleModel"
import { PackageUpsaleReq, PackageUpsaleResAdmin } from "../types/apiTypes"

export const fetchUpsales = (ids?: number[]) =>
  ({
    type: ActionTypes.FETCH_UPSALES_REQUESTED,
    payload: {
      ids,
    },
  } as const)

export const fetchUpsalesSuccess = (upsales: UpsaleMap) =>
  ({
    type: ActionTypes.FETCH_UPSALES_SUCCEEDED,
    payload: {
      upsales,
    },
  } as const)

export const fetchUpsalesFailed = (message: string) =>
  ({
    type: ActionTypes.FETCH_UPSALES_FAILED,
    payload: {
      message,
    },
  } as const)

export const saveUpsale = (data: PackageUpsaleReq, id?: number) =>
  ({
    type: ActionTypes.SAVE_UPSALE_REQUESTED,
    payload: {
      data,
      id,
    },
  } as const)

export const saveUpsaleSucceeded = (upsale: PackageUpsaleResAdmin) =>
  ({
    type: ActionTypes.SAVE_UPSALE_SUCCEEDED,
    payload: {
      upsale,
    },
  } as const)

export const saveUpsaleFailed = (message: string) =>
  ({
    type: ActionTypes.SAVE_UPSALE_FAILED,
    payload: {
      message,
    },
  } as const)

export const deleteUpsale = (id: number) =>
  ({
    type: ActionTypes.DELETE_UPSALE_REQUESTED,
    payload: {
      id,
    },
  } as const)

export const deleteUpsaleSucceeded = (id: number) =>
  ({
    type: ActionTypes.DELETE_UPSALE_SUCCEEDED,
    payload: {
      id,
    },
  } as const)

export const deleteUpsaleFailed = (message: string) =>
  ({
    type: ActionTypes.DELETE_UPSALE_FAILED,
    payload: {
      message,
    },
  } as const)

export const resetUpsaleStatus = (key: UpsaleActions) =>
  ({
    type: ActionTypes.RESET_UPSALE_STATUS,
    payload: {
      key,
    },
  } as const)
