import { makeStyles, Theme } from "@material-ui/core"

export default makeStyles((theme: Theme) => ({
  list: {
    listStyle: "none",
    marginBlockEnd: 0,
    marginBlockStart: 0,
    marginInlineStart: "1rem",
    paddingInlineStart: 0,

    "& > * + *": {
      marginTop: theme.spacing(1),
    }
  },
}))
