import React from "react"
import { Field } from "../../components/Messages/FieldMessage"
import { ReservationDataGrid } from "../../types/globalTypes"
import ReservationAgencyCell from "./ReservationAgencyCell"
import ReservationAgencyEditCell from "./ReservationAgencyEditCell"
import ReservationDateEditCell from "./ReservationDateEditCell"
import ReservationPersonsEditCell from "./ReservationPersonsEditCell"
import ReservationGroupEditCell from "./ReservationGroupEditCell"
import ReservationGroupCell from "./ReservationGroupCell"
import ReservationTableCell from "./ReservationTableCell"
import ReservationTableEditCell from "./ReservationTableEditCell"
import ReservationBonCell from "./ReservationBonCell"
import ReservationPriceCell from "./ReservationPriceCell"
import ReservationPriceEditCell from "./ReservationPriceEditCell"
import { ExtendedColumn } from "../../components/DataGrid/DataGrid"
import OrderStatusMessage from "../../components/Messages/OrderStatusMessage"
import ReservationStatusEditCell from "./ReservationStatusEditCell"
import { formatDateTime } from "../../utils/timeUtils"

const columns: ExtendedColumn<ReservationDataGrid>[] = [
  {
    title: Field.SOURCE,
    field: "req",
    render: (props: ReservationDataGrid) => (
      <ReservationAgencyCell {...props} />
    ),
    editComponent: ReservationAgencyEditCell,
    customSort: (a, b) => a.agencyId - b.agencyId,
    exportField: "agencyId",
  },
  {
    title: Field.DATE,
    field: "req",
    render: ({ reservation: { dtStart } }: ReservationDataGrid) =>
      formatDateTime(dtStart),
    editComponent: ReservationDateEditCell,
    customExport: ({ reservation: { dtStart } }: ReservationDataGrid) =>
      formatDateTime(dtStart, true),
    customSort: (a, b) => a.reservation.dtStart - b.reservation.dtStart,
  },
  {
    title: Field.NUMBER_OF_PERSONS,
    field: "req",
    editComponent: ReservationPersonsEditCell,
    render: ({ reservation: { numberOfPersons } }: ReservationDataGrid) =>
      numberOfPersons,
    customSort: (a, b) =>
      a.reservation.numberOfPersons - b.reservation.numberOfPersons,
    customExport: ({ reservation: { numberOfPersons } }: ReservationDataGrid) =>
      numberOfPersons.toString(),
  },
  {
    title: Field.GROUP,
    field: "req",
    editComponent: ReservationGroupEditCell,
    render: ReservationGroupCell,
    customSort: (a, b) => a.name.localeCompare(b.name),
    customExport: (data: ReservationDataGrid) =>
      [data.name, data.phone, data.email].join(" | "),
  },
  {
    title: Field.TABLE_NUMBER,
    field: `req`,
    render: ReservationTableCell,
    editComponent: ReservationTableEditCell,
    sorting: false,
    customExport: (data: ReservationDataGrid) =>
      data.reservation.reservation.map((res) => res.seating.id).join(" | "),
  },
  {
    title: Field.BONS,
    field: "section",
    render: ReservationBonCell,
    editable: "never",
    sorting: false,
    customExport: (data: ReservationDataGrid) =>
      data.reservation.reservation
        .flatMap((res) =>
          res.section.bons.map((bon) => `${bon.count} × ${bon.category}`)
        )
        .join(" | "),
  },
  {
    title: Field.PRICE,
    field: "req",
    render: ReservationPriceCell,
    editComponent: ReservationPriceEditCell,
    customSort: (a, b) => a.price - b.price,
    exportField: "price",
  },
  {
    title: Field.STATUS,
    field: "req",
    render: ({ status }: ReservationDataGrid) => (
      <OrderStatusMessage id={status} />
    ),
    editComponent: ReservationStatusEditCell,
    customSort: (a, b) => a.status.localeCompare(b.status),
    exportField: "status",
  },
]

export default columns
