import { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RequestStatus } from "../types/statusTypes"
import {
  selectConflictFetchStatus,
  selectHeededConflictIds,
  selectOrderFetchStatus,
  selectOrderPageNumber,
  selectOrderPageSize,
  selectSeatingFetchStatus,
  selectSectionFetchStatus,
} from "../selectors/orderSelector"
import { selectActivityFetchStatus } from "../selectors/activitySelector"
import {
  addOrder,
  fetchConflicts,
  fetchOrders,
  fetchSeating,
  fetchSections,
  setOrderPageNumber,
  setOrderPageSize,
} from "../actions/orderActions"
import { fetchActivities } from "../actions/activityActions"
import {
  MinimalUpsaleReqAdmin,
  OrderEmployeeResAdmin,
  OrderReqAdmin,
  PriceBonResAdmin,
} from "../types/apiTypes"
import { selectToken } from "../selectors/interfaceSelector"
import { delayMoment } from "../utils/apiUtils"
import {
  requestGetCancellationFee,
  requestGetEmployeeConflicts,
  requestGetOrderAvailableTimes,
  requestGetOrderConflicts,
  requestGetOrderPrice,
  requestGetOrders,
  requestGetOrderTimes,
  requestGetUnavailableSeating,
  requestSaveOrder,
} from "../api/orderRequest"
import { selectUpsaleFetchStatus } from "../selectors/upsaleSelector"
import { fetchUpsales } from "../actions/upsaleActions"
import { useFetchHook, useToken } from "./fetchHooks"
import { ErrorType } from "../components/Messages/ErrorMessage"
import { useUserFetch } from "./userHooks"
import { fetchAgencies } from "../actions/agencyActions"
import { selectAgencyFetchStatus } from "../selectors/agencySelector"
import { useIsCompetent } from "./interfaceHooks"
import { ExtendedOrderReqAdmin, InputOption } from "../types/globalTypes"
import { PaginationProps } from "../components/DataGrid/DataGrid"
import { startOfDay } from "date-fns"
import { formatDateTime } from "../utils/timeUtils"
import { arrayUnique } from "../utils/arrayUtils"

export function useOrderFetch(): RequestStatus[] {
  const dispatch = useDispatch()
  const canSeeConflicts = useIsCompetent("orderAdmin", "R", "W")
  const orderStatus = useSelector(selectOrderFetchStatus)
  const activityStatus = useSelector(selectActivityFetchStatus)
  const sectionStatus = useSelector(selectSectionFetchStatus)
  const seatingStatus = useSelector(selectSeatingFetchStatus)
  const upsaleStatus = useSelector(selectUpsaleFetchStatus)
  const conflictStatus = useSelector(selectConflictFetchStatus)
  const agencyStatus = useSelector(selectAgencyFetchStatus)
  const [, userStatus] = useUserFetch()

  useEffect(() => {
    dispatch(fetchOrders())
    dispatch(fetchActivities())
    dispatch(fetchAgencies())
    dispatch(fetchUpsales())
    dispatch(fetchSections())
    dispatch(fetchSeating())
    if (canSeeConflicts) {
      dispatch(fetchConflicts())
    }
  }, [dispatch, canSeeConflicts])

  return [
    orderStatus,
    userStatus,
    activityStatus,
    sectionStatus,
    seatingStatus,
    upsaleStatus,
    conflictStatus,
    agencyStatus,
  ]
}

export function useOrderSave(): [
  (data: OrderReqAdmin, id?: number) => Promise<number | null>,
  RequestStatus,
  string,
  number | null
] {
  const dispatch = useDispatch()
  const auth = useSelector(selectToken)
  const conflictingIds = useSelector(selectHeededConflictIds)
  const [status, setStatus] = useState<RequestStatus>(RequestStatus.INITIAL)
  const [error, setError] = useState<string>("")
  const [orderId, setOrderId] = useState<null | number>(null)
  return [
    async (data, id?) => {
      try {
        setStatus(RequestStatus.REQUESTED)
        setOrderId(id || null)
        const response = await requestSaveOrder(auth, data, id)
        const [order] = await requestGetOrders(auth, [response.id])
        dispatch(addOrder(order))
        if (conflictingIds.has(id || 0)) {
          dispatch(fetchConflicts())
        }
        setStatus(RequestStatus.SUCCEEDED)
        setOrderId(null)
        return response.id
        // eslint-disable-next-line
      } catch (e: any) {
        setError(e.message)
        setStatus(RequestStatus.FAILED)
        console.error(JSON.stringify(data))
        setOrderId(null)
        return null
      } finally {
        await delayMoment()
        setStatus(RequestStatus.INITIAL)
        setError("")
        setOrderId(null)
      }
    },
    status,
    error,
    orderId,
  ]
}

export function useSeatingAvailabilityFetch(
  dtStart?: number
): [
  number[],
  React.Dispatch<React.SetStateAction<number[]>>,
  RequestStatus,
  ErrorType | string
] {
  const state = useFetchHook()
  const [seating, setSeating] = useState<number[]>([])
  const token = useSelector(selectToken)

  useEffect(() => {
    const fetchUnavailableSeating = async (date: number) => {
      try {
        state.handleRequest()
        const response = await requestGetUnavailableSeating(token, date)
        state.handleSuccess()
        setSeating(response)
        // eslint-disable-next-line
      } catch (e: any) {
        state.handleFail(e.message)
      }
    }
    if (dtStart) {
      fetchUnavailableSeating(dtStart)
    }
    // eslint-disable-next-line
  }, [dtStart])

  return [seating, setSeating, state.status, state.error]
}

interface OrderPrice {
  cost: number
  deposit: number
  price: number
  balance: number
}

const emptyPrice: OrderPrice = {
  cost: 0,
  deposit: 0,
  price: 0,
  balance: 0,
}

const emptyBonResAdmin: PriceBonResAdmin = {
  option: [],
  upsales: [],
  reservation: [],
  bonSum: [],
}

type OrderPriceRequest = (
  req: ExtendedOrderReqAdmin /* | OrderReqAdmin */
) => Promise<[OrderPrice, OrderPrice, PriceBonResAdmin]>

export function useOrderPriceFetch(): [OrderPriceRequest] {
  const token = useToken()
  return [
    async (req) => {
      if (req.isLocked) throw new Error("Order is locked")

      if (!req.packageOrder?.optionId && !req.reservation)
        return [emptyPrice, emptyPrice, emptyBonResAdmin]

      const { price, bons } = await requestGetOrderPrice(token, {
        agencyId: req.agencyId,
        packageOrder: req.packageOrder
          ? {
              mainPackageOptionId: req.packageOrder.optionId,
              numberOfPersons: req.packageOrder.numberOfPersons,
            }
          : null,
        reservation: req.reservation
          ? {
              numberOfPersons: req.reservation.numberOfPersons,
              seatingIds: req.reservation.seatingIds,
            }
          : null,
        upsales: req.upsales.map(
          ({ numberOfPersons, packageUpsaleId, quantity }) => ({
            numberOfPersons,
            packageUpsaleId,
            quantity,
          })
        ),
      })
      return [
        // price summary
        {
          cost:
            price.reservation.costs + price.option.costs + price.upsales.costs,
          deposit:
            price.reservation.deposit +
            price.option.deposit +
            price.upsales.deposit,
          price:
            price.reservation.price + price.option.price + price.upsales.price,
          balance:
            price.reservation.balance +
            price.option.balance +
            price.upsales.balance,
        },
        // reservation
        {
          cost: price.reservation.costs,
          deposit: price.reservation.deposit,
          price: price.reservation.price,
          balance: price.reservation.balance,
        },
        // bons
        bons,
      ]
    },
  ]
}

export function useOrderConflictCheck(): [
  (req: OrderReqAdmin) => Promise<number[]>,
  RequestStatus,
  string
] {
  const state = useFetchHook()
  return [
    async (req: OrderReqAdmin): Promise<number[]> => {
      try {
        state.handleRequest()
        if (!req.packageOrder && !req.reservation)
          throw Error("Order must have either packageOrder or reservation")
        const orderIds = await requestGetOrderConflicts(state.token, {
          dtStart: req.packageOrder?.dtStart || req.reservation?.dtStart || 0, // never 0, but TS doesn't understand the line above
          mainPackageOptionId: req.packageOrder?.optionId || null,
          upsales: req.upsales.map(
            ({
              contactInfo,
              uuid,
              orderEmployees,
              ...rest
            }): MinimalUpsaleReqAdmin => rest
          ),
          seatingIds: req.reservation?.seatingIds || [],
        })
        state.handleSuccess()
        return orderIds
        // eslint-disable-next-line
      } catch (e: any) {
        console.warn(e.message)
        state.handleFail(e.message)
        return []
      } finally {
        state.handleReset()
      }
    },
    state.status,
    state.error,
  ]
}

export function useEmployeeConflictCheck(): [
  (
    dtStart: number,
    ids: number[],
    orderId?: number
  ) => Promise<OrderEmployeeResAdmin[]>,
  RequestStatus,
  string
] {
  const state = useFetchHook()
  return [
    async (
      dtStart: number,
      ids: number[],
      orderId?: number
    ): Promise<OrderEmployeeResAdmin[]> => {
      try {
        if (ids.length > 0) {
          state.handleRequest()
          const employees = await requestGetEmployeeConflicts(
            state.token,
            dtStart,
            ids,
            orderId
          )
          state.handleSuccess()
          return employees
        }
        return []
        // eslint-disable-next-line
      } catch (e: any) {
        state.handleFail(e.message)
        return []
      } finally {
        state.handleReset()
      }
    },
    state.status,
    state.error,
  ]
}

export function useCancellationFeeFetch(): [
  (orderId: number, cancelledAt: number) => Promise<number>,
  RequestStatus,
  string
] {
  const state = useFetchHook()
  return [
    async (orderId, cancelledAt) => {
      try {
        state.handleRequest()
        const { amount } = await requestGetCancellationFee(
          state.token,
          orderId,
          cancelledAt
        )
        state.handleSuccess()
        return amount
        // eslint-disable-next-line
      } catch (e: any) {
        state.handleFail(e.message)
        return 0
      } finally {
        state.handleReset()
      }
    },
    state.status,
    state.error,
  ]
}

export function useOrderPagination(): PaginationProps {
  const dispatch = useDispatch()
  const pageSize = useSelector(selectOrderPageSize)
  const pageNumber = useSelector(selectOrderPageNumber)
  return {
    pageNumber,
    pageSize,
    onPageNumberChange: (i) => dispatch(setOrderPageNumber(i)),
    onPageSizeChange: (i) => dispatch(setOrderPageSize(i)),
  }
}

export function useAvailableTimeOptions(
  req: ExtendedOrderReqAdmin,
  callback?: (date: number) => void
): {
  options: InputOption[]
  refetchOptions: (date: number) => Promise<void>
} {
  const token = useToken()
  const canSelectUnavailable = useIsCompetent("orderAdmin", "W", "R")
  const [options, setOptions] = useState<InputOption[]>([])

  const fetchTimeOptions = useCallback(
    async (value: number) => {
      if (!req.packageOrder) return

      /* fetch available times for selected calendar day */
      const dtStart = startOfDay(value).valueOf()

      const { times: availableTimes } = await requestGetOrderAvailableTimes({
        dtStart,
        packageOptionIds: [req.packageOrder.optionId],
        upsalePackageIds: req.upsales.map((upsale) => upsale.packageUpsaleId),
      })

      const times = canSelectUnavailable
        ? await requestGetOrderTimes(token, dtStart)
        : availableTimes

      /*
       * change value to first available slot
       * if current time is invalid for selected day
       */
      if (callback && !times.includes(value)) {
        callback(times[0])
      }

      setOptions(
        [...availableTimes, ...times]
          .filter(arrayUnique)
          .sort((a, b) => a - b)
          .map((t) => {
            const isAvailable = availableTimes.includes(t)
            return {
              value: t.toString(),
              content: formatDateTime(t),
              /* do not promote when only available times are displayed */
              promoted: canSelectUnavailable && isAvailable,
              demoted: canSelectUnavailable && !isAvailable,
            }
          })
      )
    },
    [canSelectUnavailable, req.packageOrder, req.upsales, token, callback]
  )

  useEffect(() => {
    if (req.packageOrder) fetchTimeOptions(req.packageOrder.dtStart)
  }, [fetchTimeOptions, req.packageOrder])

  return { options, refetchOptions: fetchTimeOptions }
}
