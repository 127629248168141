import { RequestStatus as Status, RequestStatus } from "../types/statusTypes"
import {
  ActivityActions,
  ActivityState,
  makeActivityState,
} from "../models/activityModel"
import { ActionTypes, AnyAction } from "../types/actionTypes"

const initialState: ActivityState = makeActivityState()

export default function userReducer(
  state = initialState,
  action: AnyAction | null = null
): ActivityState {
  const setStatus = (key: ActivityActions, status: Status) =>
    state.set("statuses", state.statuses.set(key, status))
  const setError = (key: ActivityActions, error: string) =>
    state
      .set("errors", state.errors.set(key, error))
      .set("statuses", state.statuses.set(key, RequestStatus.FAILED))

  switch (action?.type) {
    // logout
    case ActionTypes.LOGOUT_USER_SUCCEEDED:
      return initialState

    // reset status to default and error message to empty string
    case ActionTypes.RESET_ACTIVITY_STATUS:
      return setStatus(action.payload.key, RequestStatus.INITIAL).set(
        "errors",
        state.errors.set(action.payload.key, "")
      )

    // resources fetch
    case ActionTypes.FETCH_RESOURCES_REQUESTED:
      return setStatus("fetchResources", RequestStatus.REQUESTED)

    case ActionTypes.FETCH_RESOURCES_SUCCEEDED:
      return setStatus("fetchResources", RequestStatus.SUCCEEDED).set(
        "resources",
        action.payload.resources
      )

    case ActionTypes.FETCH_RESOURCES_FAILED:
      return setError("fetchResources", action.payload.message)

    // activity fetch
    case ActionTypes.FETCH_ACTIVITIES_REQUESTED:
      return setStatus("fetchActivities", RequestStatus.REQUESTED)

    case ActionTypes.FETCH_ACTIVITIES_SUCCEEDED:
      return setStatus("fetchActivities", RequestStatus.SUCCEEDED).set(
        "records",
        state.records.merge(action.payload.activities)
      )

    case ActionTypes.FETCH_ACTIVITIES_FAILED:
      return setError("fetchActivities", action.payload.message)

    // activity save
    case ActionTypes.SAVE_ACTIVITY_REQUESTED:
      return setStatus("saveActivity", RequestStatus.REQUESTED)

    case ActionTypes.SAVE_ACTIVITY_FAILED:
      return setError("saveActivity", action.payload.message)

    case ActionTypes.SAVE_ACTIVITY_SUCCEEDED:
      return setStatus("saveActivity", RequestStatus.SUCCEEDED).set(
        "records",
        state.records.set(
          action.payload.activity.id.toString(),
          action.payload.activity
        )
      )

    // activity delete
    case ActionTypes.DELETE_ACTIVITY_REQUESTED:
      return setStatus("deleteActivity", RequestStatus.REQUESTED)

    case ActionTypes.DELETE_ACTIVITY_FAILED:
      return setError("deleteActivity", action.payload.message)

    case ActionTypes.DELETE_ACTIVITY_SUCCEEDED:
      return setStatus("deleteActivity", RequestStatus.SUCCEEDED).set(
        "records",
        state.records.remove(action.payload.id.toString())
      )

    default:
      return state
  }
}
