const config = {
  publicEndpoint: process.env.REACT_APP_PUBLIC_ENDPOINT,
  privateEndpoint: process.env.REACT_APP_PRIVATE_ENDPOINT,
  storageEndpoint: process.env.REACT_APP_STORAGE_ENDPOINT,
  packagePreview: process.env.REACT_APP_PREVIEW_URL,
  useMockData: true,
  dateTimeFormat: "dd.\u00A0MM.\u00A0y HH:mm",
  timeFormat: "HH:mm",
  dateTimeFormatReverse: "HH:mm dd.\u00A0MM.\u00A0y",
  // TODO: refactor
  iconsBaseUrl:
    "https://storage.googleapis.com/goldfingers-dev/raw/site_icons/",
  // TODO: refactor
  icons: [
    "BiTimeFive.svg",
    "BiSpreadsheet.svg",
    "BiCamera.svg",
    "BiCalendar.svg",
    "BiDrink.svg",
    "BiPhone.svg",
    "BiMoney.svg",
    "BiBasket.svg",
  ],
  routes: {
    signIn: {
      path: (): string => `/public`,
      allowedAccess: null,
    },
    recoverPassword: {
      path: (token: string = `:token`): string => `/public/recover/${token}`,
      allowedAccess: null,
    },
    forgotPassword: {
      path: (): string => `/public/password`,
      allowedAccess: null,
    },
    dashboard: {
      path: (): string => `/app`,
      allowedAccess: null,
    },
    userList: {
      path: (): string => `/app/users`,
      allowedAccess: null,
    },
    userDetail: {
      path: (id: string = `:id`): string => `/app/users/edit/${id}`,
      allowedAccess: [["user", ["W"]]],
    },
    agencyList: {
      path: (): string => `/app/agencies`,
      allowedAccess: [["uiAgency", ["R", "W"]]],
    },
    agencyDetail: {
      path: (id: string = `:id`): string => `/app/agencies/detail/${id}`,
      allowedAccess: [["uiAgency", ["R", "W"]]],
    },
    activityList: {
      path: (): string => `/app/activities`,
      allowedAccess: [["uiPackage", ["R", "W"]]],
    },
    activityDetail: {
      path: (id: string = `:id`): string => `/app/activities/detail/${id}`,
      allowedAccess: [["uiPackage", ["W"]]],
    },
    upsaleList: {
      path: (): string => `/app/upsales`,
      allowedAccess: [["uiPackage", ["R", "W"]]],
    },
    upsaleDetail: {
      path: (id: string = `:id`): string => `/app/upsales/detail/${id}`,
      allowedAccess: [["uiPackage", ["W"]]],
    },
    voucherList: {
      path: (): string => `/app/vouchers`,
      allowedAccess: [["uiVoucher", ["R", "W"]]],
    },
    voucherDetail: {
      path: (id: string = `:id`): string => `/app/vouchers/detail/${id}`,
      allowedAccess: [["uiVoucher", ["W"]]],
    },
    packageList: {
      path: (): string => `/app/packages`,
      allowedAccess: [["uiPackage", ["R", "W"]]],
    },
    packageDetail: {
      path: (id: string = `:id`): string => `/app/packages/detail/${id}`,
      allowedAccess: [["uiPackage", ["W"]]],
    },
    newsList: {
      path: (): string => `/app/news`,
      allowedAccess: [
        ["uiPages", ["R", "W"]],
        ["page", ["R", "W"]],
      ],
    },
    newsDetail: {
      path: (id: string = `:id`): string => `/app/news/detail/${id}`,
      allowedAccess: [
        ["uiPages", ["R", "W"]],
        ["page", ["R", "W"]],
      ],
    },
    upsellingList: {
      path: (): string => `/app/upselling`,
      allowedAccess: [["uiPackage", ["R", "W"]]],
    },
    upsellingDetail: {
      path: (id: string = `:id`): string => `/app/upselling/detail/${id}`,
      allowedAccess: [["uiPackage", ["W"]]],
    },
    ordersList: {
      path: (): string => `/app/orders`,
      allowedAccess: [
        ["uiOrderList", ["R", "W"]],
        ["uiOrderEmployee", ["R", "W"]],
      ],
    },
    orderEmployee: {
      path: (
        id: string = `:id`,
        status: string = `:status`
      ): string => `/app/orders/employee/${id}/${status}`,
      allowedAccess: [
        ["uiOrderList", ["R", "W"]],
        ["uiOrderEmployee", ["R", "W"]],
      ],
    },
    report: {
      path: (): string => `/app/report`,
      allowedAccess: [["uiReport", ["R", "W"]]],
    },
    reservation: {
      path: (): string => `/app/reservation`,
      allowedAccess: [["uiReservation", ["R", "W"]]],
    },
    productList: {
      path: (): string => `/app/products`,
      allowedAccess: [["product", ["R", "W"]]],
    },
    productDetail: {
      path: (id: string = `:id`): string => `/app/products/detail/${id}`,
      allowedAccess: [["product", ["W"]]],
    },
    careerList: {
      path: (): string => `/app/career`,
      allowedAccess: [["uiPages", ["R", "W"]]],
    },
    careerDetail: {
      path: (id: string = `:id`): string => `/app/career/detail/${id}`,
      allowedAccess: [["uiPages", ["W"]]],
    },
    showList: {
      path: (): string => `/app/show`,
      allowedAccess: [["uiPages", ["R", "W"]]],
    },
    showDetail: {
      path: (id: string = `:id`): string => `/app/show/detail/${id}`,
      allowedAccess: [["uiPages", ["W", "R"]]],
    },
    pageList: {
      path: (): string => `/app/page`,
      allowedAccess: [["uiPages", ["R", "W"]]],
    },
    pageDetail: {
      path: (id: string = `:id`): string => `/app/page/detail/${id}`,
      allowedAccess: [["uiPages", ["W"]]],
    },
    invoiceList: {
      path: (): string => `/app/invoice`,
      allowedAccess: [["uiInvoice", ["R", "W"]]],
    },
    sessionList: {
      path: (): string => `/app/vouchers/session`,
      allowedAccess: [["sessionAdmin", ["W", "R"]]],
    },
  },
  tagIds: {
    gallery: {
      homepage: 1,
      gallery: 2,
      support: 3,
      party: 4,
      shows: 5,
      superstar: 6,
      history: 7,
    },
  },
  agencyIds: {
    web: 1,
  },
  pageIds: {
    homepage: 1,
    party: 2,
    gallery: 3,
    support: 4,
    career: 5,
    contact: 6,
    offer: 7,
    show: 8,
  },
  tagCategories: {
    homepage: "homepage",
    party: "party",
    drinks: "drinks",
    dancers: "dancers",
    gallery: "gallery",
  },
}

export default config
