import AddBox from "@material-ui/icons/AddBox"
import React, { memo, useState } from "react"
import { FormattedMessage } from "react-intl"
import { Navigate } from "react-router-dom"
import Button from "../../components/Button/Button"
import DataGrid from "../../components/DataGrid/DataGrid"
import config from "../../config"
import { AgencyResAdmin } from "../../types/apiTypes"
import {
  useAgencyDelete,
  useAgencyFetch,
  useAgencySave,
} from "../../hooks/agencyHooks"
import Results from "../../components/Results/Results"
import RouteMessage from "../../components/Messages/RouteMessage"
import { Field } from "../../components/Messages/FieldMessage"
import { Entity } from "../../components/Messages/EntityMessage"
import { ErrorType } from "../../components/Messages/ErrorMessage"
import { isNotNull, ValidationError } from "../../utils/typeUtils"
import StatusProgressBar from "../../components/StatusProgressBar/StatusProgressBar"
import ValidationErrors from "../../components/ValidationErrors/ValidationErrors"
import { localeSort } from "../../utils/arrayUtils"
import { useLocalStoragePagination } from "../../hooks/interfaceHooks"

function AgencyList() {
  const [redirectId, setRedirectId] = useState<string | null>(null)
  const [agencies, fetchStatus, fetchError] = useAgencyFetch()
  const [errors, setErrors] = useState<ValidationError[]>([])
  const [saveAgency, saveStatus, saveError] = useAgencySave()
  const [deleteAgency, deleteStatus, deleteError] = useAgencyDelete()
  const paginationProps = useLocalStoragePagination("agency")

  if (redirectId !== null)
    return <Navigate to={config.routes.agencyDetail.path(redirectId)} />

  return (
    <>
      <Results
        statuses={[fetchStatus, saveStatus, deleteStatus]}
        errors={[fetchError, saveError, deleteError]}
        actions={["fetch", "save", "delete"]}
        entity={Entity.AGENCY}
      />
      <StatusProgressBar statuses={[fetchStatus, saveStatus, deleteStatus]} />
      <ValidationErrors errors={errors} />
      <DataGrid<AgencyResAdmin>
        {...paginationProps}
        title={<RouteMessage id={"agencyList"} />}
        onRowClick={(_, row) => setRedirectId(row ? row.id.toString() : null)}
        cta={
          <Button
            onClick={() => setRedirectId(``)}
            variant="contained"
            color={`primary`}
            startIcon={<AddBox />}
          >
            <FormattedMessage
              id={`createAgency`}
              defaultMessage={`Create agency`}
            />
          </Button>
        }
        columns={[
          {
            title: Field.TITLE,
            field: "title",
            editPlaceholder: "title",
            defaultSort: "asc",
            customSort: localeSort("title")
          },
          { title: Field.PHONE, field: "phone" },
          { title: Field.ADDRESS, field: "address" },
          { title: Field.CRN, field: "crn" },
        ]}
        data={agencies.valueSeq().toArray()}
        editable={{
          onRowUpdate: (req) =>
            new Promise<void>((resolve, reject) => {
              const validationErrors = [
                req.title ? null : [Field.TITLE, ErrorType.REQUIRED],
                req.phone ? null : [Field.PHONE, ErrorType.REQUIRED],
                req.address ? null : [Field.ADDRESS, ErrorType.REQUIRED],
                req.crn ? null : [Field.CRN, ErrorType.REQUIRED],
              ].filter(isNotNull) as ValidationError[]
              if (validationErrors.length > 0) {
                setErrors(validationErrors)
                reject()
              } else {
                saveAgency(req, req.id).then(() => resolve())
              }
            }),
          onRowDelete: ({ id }) =>
            new Promise<void>((resolve) => {
              deleteAgency(id).then(() => resolve())
            }),
          onRowAddCancelled: () => {
            setErrors([])
          },
        }}
      />
    </>
  )
}

export default memo(AgencyList)
