import React from "react"
import { FormattedMessage } from "react-intl"
import { InvoiceStatus } from "../../types/apiTypes"

interface IInvoicePublisherMessageProps {
  id: InvoiceStatus
}

export default function InvoiceStatusMessage({
  id,
}: IInvoicePublisherMessageProps) {
  switch (id) {
    case InvoiceStatus.CANCELLED:
      return <FormattedMessage id={`cancelled`} defaultMessage={`Cancelled`} />
    case InvoiceStatus.DELVERED:
      return <FormattedMessage id={`delivered`} defaultMessage={`Delivered`} />
    case InvoiceStatus.ISSUED:
      return <FormattedMessage id={`issued`} defaultMessage={`Issued`} />
    case InvoiceStatus.PAID:
      return <FormattedMessage id={`paid`} defaultMessage={`Paid`} />
    default:
      return null
  }
}
