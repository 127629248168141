import { ProductReq, ProductResAdmin } from "../types/apiTypes"
import performRequest, { getIdParam, getIdsParam } from "../utils/apiUtils"
import { HttpMethods } from "../types/globalTypes"

export async function requestGetProducts(
  basicAuth: string,
  ids: number[] = []
): Promise<ProductResAdmin[]> {
  return performRequest(
    {
      endpoint: `product${getIdsParam(ids)}`,
      auth: basicAuth,
      method: HttpMethods.GET,
    },
    true
  )
}

export async function requestSaveProduct(
  basicAuth: string,
  product: ProductReq,
  id?: number
): Promise<ProductReq> {
  return performRequest(
    {
      endpoint: `product${getIdParam(id)}`,
      auth: basicAuth,
      method: id ? HttpMethods.PUT : HttpMethods.POST,
      body: product,
    },
    true
  )
}

export async function requestDeleteProduct(basicAuth: string, id: number) {
  return performRequest(
    {
      endpoint: `product${getIdParam(id)}`,
      auth: basicAuth,
      method: HttpMethods.DELETE,
    },
    true
  )
}
