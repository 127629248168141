import { makeStyles } from "@material-ui/core/styles"
import { Theme } from "@material-ui/core"

export default makeStyles<Theme, { isSorting: boolean }>(() => ({
  chips: {
    display: "inline-flex",
    flexWrap: "wrap",
    flexDirection: (props) => (props.isSorting ? "column" : "row"),
  },
  label: {
    whiteSpace: "initial",
    overflow: "visible",
    textOverflow: "initial",
    display: "block",
  },
  sortButton: {
    position: "absolute",
    top: 12,
    left: "0",
    transform: (props) =>
      props.isSorting
        ? "translateX(-100%) rotate(-90deg)"
        : "translateX(-100%)",
  },
}))
