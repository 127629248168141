import { call, put, select, delay } from "redux-saga/effects"
import {
  requestDeleteUser,
  requestGetUsers,
  requestSaveUser,
} from "../api/userRequests"
import {
  deleteUser,
  deleteUserFailed,
  deleteUserSucceeded,
  fetchUsers,
  fetchUsersFailed,
  fetchUsersSucceeded,
  resetUserStatus,
  saveUser,
  saveUserFailed,
  saveUserSucceeded,
} from "../actions/userActions"
import { selectToken } from "../selectors/interfaceSelector"
import { IdRes, UserRes } from "../types/apiTypes"
import { UserActions } from "../models/userModel"
import { arrayToMap } from "../utils/arrayUtils"

function* resetStatus(key: UserActions) {
  yield delay(3000)
  yield put(resetUserStatus(key))
}

export function* fetchUsersSaga({
  payload: { ids },
}: ReturnType<typeof fetchUsers>) {
  try {
    const auth: string = yield select(selectToken)
    const data: UserRes[] = yield call(requestGetUsers, auth, ids)
    yield put(fetchUsersSucceeded(arrayToMap(data)))
    // eslint-disable-next-line
  } catch (e: any) {
    yield put(fetchUsersFailed(e.message))
  } finally {
    yield* resetStatus("fetchUsers")
  }
}

export function* saveUserSaga({
  payload: { formData, id },
}: ReturnType<typeof saveUser>) {
  try {
    const auth: string = yield select(selectToken)
    const res: IdRes<number> = yield call(requestSaveUser, auth, formData, id)
    const [user]: UserRes[] = yield call(requestGetUsers, auth, [res.id])
    yield put(saveUserSucceeded(user))
    // eslint-disable-next-line
  } catch (e: any) {
    yield put(saveUserFailed(e.message))
  } finally {
    yield* resetStatus("saveUser")
  }
}

export function* deleteUserSaga({
  payload: { id },
}: ReturnType<typeof deleteUser>) {
  try {
    const auth: string = yield select(selectToken)
    yield call(requestDeleteUser, auth, id)
    yield put(deleteUserSucceeded(id))
    // eslint-disable-next-line
  } catch (e: any) {
    yield put(deleteUserFailed(e.message))
  } finally {
    yield* resetStatus("deleteUser")
  }
}
