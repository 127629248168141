import React, { ChangeEvent, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import Form from "../../components/Form/Form"
import GalleryUpload from "../../components/GalleryUpload/GalleryUpload"
import Input from "../../components/Input/Input"
import LanguageTab from "../../components/LanguageTab/LanguageTab"
import config from "../../config"
import Results from "../../components/Results/Results"
import {
  blankTranslations,
  isMediaItemVideo,
  languageList,
  richToSimpleTranslation,
  simpleToFullTranslation,
} from "../../utils/typeUtils"
import { MediaType, MediumResAdmin } from "../../types/apiTypes"
import WebPageMessage, {
  WebPage,
} from "../../components/Messages/WebPageMessage"
import { Field } from "../../components/Messages/FieldMessage"
import { Entity } from "../../components/Messages/EntityMessage"
import {
  useGalleryFetch,
  useGallerySave,
  useGalleryState,
} from "../../hooks/galleryHooks"
import {
  usePageSharedState,
  useRichTextState,
  useTranslationState,
} from "../../hooks/stateHooks"
import FieldSet from "../../components/FieldSet/FieldSet"
import ImageUpload from "../../components/ImageUpload/ImageUpload"
import InlineFieldset from "../../components/InlineFieldset/InlineFieldset"
import RichText from "../../components/RichText/RichText"

const NS_SHOW = "show"
const NS_SUPERSTAR = "superstar"
const NS_HISTORY = "history"

const tagIds = config.tagIds.gallery
type Id = number | null

export default function PageHomepage() {
  const dispatch = useDispatch()
  const state = usePageSharedState(config.pageIds.show)
  const galleryState = useGalleryState()
  // superstar state init
  const [saveSuperstarGallery, saveSuperstarStatus, saveSuperstarError] =
    useGallerySave([tagIds.superstar])
  const [fetchSuperstarGallery, fetchSuperstarStatus, fetchSuperstarError] =
    useGalleryFetch(tagIds.superstar)
  const [superstarGalleryId, setSuperstarGalleryId] = useState<Id>(null)
  const [superstarTitle, onSuperstarTitleChange, fillSuperstarTitle] =
    useTranslationState()
  const [superstarContent, onSuperstarContentChange, fillSuperstarContent] =
    useTranslationState()
  // history state init
  const [saveHistoryGallery, saveHistoryStatus, saveHistoryError] =
    useGallerySave([tagIds.history])
  const [fetchHistoryGallery, fetchHistoryStatus, fetchHistoryError] =
    useGalleryFetch(tagIds.history)
  const [historyGalleryId, setHistoryGalleryId] = useState<Id>(null)
  const [historyTitle, onHistoryTitleChange, fillHistoryTitle] =
    useTranslationState()
  const [historyContent, onHistoryContentChange, fillHistoryContent] =
    useTranslationState()
  // show state init
  const [saveShowGallery, saveShowStatus, saveShowError] = useGallerySave([
    tagIds.shows,
  ])
  const [fetchShowGallery, fetchShowStatus, fetchShowError] = useGalleryFetch(
    tagIds.shows
  )
  const [showGalleryId, setShowGalleryId] = useState<Id>(null)
  const [showTitle, onShowTitleChange, fillShowTitle] = useTranslationState()
  const [showContent, onShowContentChange, fillShowContent] =
    useTranslationState()
  const [leftProgram, setLeftProgram, restoreLeftProgram] = useRichTextState(
    state.language
  )
  const [rightProgram, setRightProgram, restoreRightProgram] = useRichTextState(
    state.language
  )

  useEffect(() => {
    const fetchData = async () => {
      const galleryData: { [namespace: string]: MediumResAdmin[] } = {}
      await state.fetchPage()
      await fetchSuperstarGallery(
        [],
        ([
          { media, id, translations } = {
            media: [],
            id: null,
            translations: blankTranslations,
          },
        ]) => {
          setSuperstarGalleryId(id)
          fillSuperstarTitle(translations, "title")
          fillSuperstarContent(translations, "content")
          galleryData[NS_SUPERSTAR] = media
        }
      )
      await fetchHistoryGallery(
        [],
        ([
          { media, id, translations } = {
            media: [],
            id: null,
            translations: blankTranslations,
          },
        ]) => {
          setHistoryGalleryId(id)
          fillHistoryTitle(translations, "title")
          fillHistoryContent(translations, "content")
          galleryData[NS_HISTORY] = media
        }
      )
      await fetchShowGallery(
        [],
        ([
          { media, id, translations } = {
            media: [],
            id: null,
            translations: blankTranslations,
          },
        ]) => {
          setShowGalleryId(id)
          fillShowTitle(translations, "title")
          fillShowContent(translations, "content")
          restoreLeftProgram(translations, "additionalInfo")
          restoreRightProgram(translations, "metaData")
          galleryData[NS_SHOW] = media
        }
      )
      return galleryData
    }
    fetchData()
      .then((map) => {
        galleryState.fillData(map)
      })
      .catch(console.error)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const handleSubmit = async () => {
    // save page
    await state.savePage()

    // save galleries
    await saveSuperstarGallery(
      superstarGalleryId,
      galleryState.getGallery(NS_SUPERSTAR),
      galleryState
        .getGallery(NS_SUPERSTAR)
        .map((i) =>
          simpleToFullTranslation([galleryState.labels[i.id]], ["title"])
        ),
      galleryState
        .getGallery(NS_SUPERSTAR)
        .map((medium) =>
          isMediaItemVideo(medium) ? MediaType.VIDEO : MediaType.IMAGE
        ),
      undefined,
      simpleToFullTranslation(
        [superstarTitle, superstarContent],
        ["title", "content"]
      )
    )
    await saveHistoryGallery(
      historyGalleryId,
      galleryState.getGallery(NS_HISTORY),
      galleryState
        .getGallery(NS_HISTORY)
        .map((i) =>
          simpleToFullTranslation([galleryState.labels[i.id]], ["title"])
        ),
      galleryState
        .getGallery(NS_HISTORY)
        .map((medium) =>
          isMediaItemVideo(medium) ? MediaType.VIDEO : MediaType.IMAGE
        ),
      undefined,
      simpleToFullTranslation(
        [historyTitle, historyContent],
        ["title", "content"]
      )
    )
    await saveShowGallery(
      showGalleryId,
      galleryState.getGallery(NS_SHOW),
      galleryState
        .getGallery(NS_SHOW)
        .map((i) =>
          simpleToFullTranslation([galleryState.labels[i.id]], ["title"])
        ),
      galleryState
        .getGallery(NS_SHOW)
        .map((medium) =>
          isMediaItemVideo(medium) ? MediaType.VIDEO : MediaType.IMAGE
        ),
      undefined,
      simpleToFullTranslation(
        [
          showTitle,
          showContent,
          richToSimpleTranslation(leftProgram),
          richToSimpleTranslation(rightProgram),
        ],
        ["title", "content", "additionalInfo", "metaData"]
      )
    )
  }

  const handleTitleChange = ({
    currentTarget: { value, name },
  }: ChangeEvent<HTMLInputElement>) => {
    if (name.includes("superstar")) {
      onSuperstarTitleChange(value, state.language)
    } else if (name.includes("show")) {
      onShowTitleChange(value, state.language)
    } else {
      onHistoryTitleChange(value, state.language)
    }
  }

  const handleContentChange = ({
    currentTarget: { value, name },
  }: ChangeEvent<HTMLInputElement>) => {
    if (name.includes("superstar")) {
      onSuperstarContentChange(value, state.language)
    } else if (name.includes("show")) {
      onShowContentChange(value, state.language)
    } else {
      onHistoryContentChange(value, state.language)
    }
  }

  return (
    <>
      <Results
        statuses={[state.saveStatus, state.fetchStatus]}
        errors={[state.saveError, state.fetchError]}
        actions={["save", "fetch"]}
        entity={Entity.PAGE}
      />
      <Results
        statuses={[saveSuperstarStatus, fetchSuperstarStatus]}
        errors={[saveSuperstarError, fetchSuperstarError]}
        actions={["save", "fetch"]}
        entity={Entity.GALLERY}
      />
      <Results
        statuses={[saveHistoryStatus, fetchHistoryStatus]}
        errors={[saveHistoryError, fetchHistoryError]}
        actions={["save", "fetch"]}
        entity={Entity.GALLERY}
      />
      <Results
        statuses={[saveShowStatus, fetchShowStatus]}
        errors={[saveShowError, fetchShowError]}
        actions={["save", "fetch"]}
        entity={Entity.GALLERY}
      />
      <Form
        statuses={[
          state.saveStatus,
          state.fetchStatus,
          fetchHistoryStatus,
          fetchSuperstarStatus,
          fetchShowStatus,
        ]}
        title={<WebPageMessage id={WebPage.SHOW} />}
        onSubmit={handleSubmit}
        toolbar={
          <LanguageTab
            language={state.language}
            onChange={state.setLanguage}
            notifications={languageList.filter((lang) => {
              const { content, title, metaData } = state.translations[lang]
              return !content || !title || !metaData
            })}
          />
        }
        useGenericCta
      >
        <Input
          name={`title`}
          messageId={Field.TITLE}
          onChange={state.handleTitle}
          value={state.title}
          showRequiredWarning={state.isSubmitted}
        />
        <Input
          name={`meta`}
          messageId={Field.META}
          onChange={state.handleMeta}
          value={state.meta}
          showRequiredWarning={state.isSubmitted}
        />
        <Input
          name={`keywords`}
          messageId={Field.KEYWORDS}
          onChange={state.handleKeywords}
          value={state.keywords}
          showRequiredWarning={state.isSubmitted}
        />
        <ImageUpload
          source={galleryState.getGallery(NS_SHOW)?.[0]?.source}
          name={`cover`}
          onUpload={(file) => galleryState.handleUpload([file], NS_SHOW)}
          onDelete={() =>
            galleryState.handleDelete(
              galleryState.getGallery(NS_SHOW)?.[0]?.id,
              NS_SHOW
            )
          }
          messageId={Field.MAIN_IMAGE_VIDEO}
          allowURL
        />
        <Input
          messageId={Field.HEADLINE}
          name={`showTitle`}
          onChange={handleTitleChange}
          type={`text`}
          value={showTitle[state.language]}
        />
        <Input
          messageId={Field.CONTENT}
          name={`showContent`}
          onChange={handleContentChange}
          type={`text`}
          value={showContent[state.language]}
          multiline
        />
        <br />
        <br />
        <InlineFieldset messageId={Field.PROGRAM}>
          <RichText
            language={state.language}
            value={leftProgram}
            onChange={setLeftProgram}
          />
          <RichText
            language={state.language}
            value={rightProgram}
            onChange={setRightProgram}
          />
        </InlineFieldset>
        <FieldSet messageId={Field.SUPERSTAR}>
          <Input
            messageId={Field.HEADLINE}
            name={`superstarTitle`}
            onChange={handleTitleChange}
            type={`text`}
            value={superstarTitle[state.language]}
            multiline
          />
          <Input
            messageId={Field.CONTENT}
            name={`superstarContent`}
            onChange={handleContentChange}
            type={`text`}
            value={superstarContent[state.language]}
            multiline
          />
          <GalleryUpload
            name={NS_SUPERSTAR}
            language={state.language}
            gallery={galleryState.getGallery(NS_SUPERSTAR)}
            labels={galleryState.labels}
            onUpload={(files) => galleryState.handleUpload(files, NS_SUPERSTAR)}
            onSort={(...args) => galleryState.handleSort(...args, NS_SUPERSTAR)}
            onLabelChange={galleryState.handleLabelChange}
            onDelete={(id) => galleryState.handleDelete(id, NS_SUPERSTAR)}
            messageId={Field.GALLERY}
            allowURL
          />
        </FieldSet>
        <FieldSet messageId={Field.HISTORY}>
          <Input
            messageId={Field.HEADLINE}
            name={`historyTitle`}
            onChange={handleTitleChange}
            type={`text`}
            value={historyTitle[state.language]}
            multiline
          />
          <Input
            messageId={Field.CONTENT}
            name={`historyContent`}
            onChange={handleContentChange}
            type={`text`}
            value={historyContent[state.language]}
            multiline
          />
          <GalleryUpload
            name={NS_HISTORY}
            language={state.language}
            gallery={galleryState.getGallery(NS_HISTORY)}
            labels={galleryState.labels}
            onUpload={(files) => galleryState.handleUpload(files, NS_HISTORY)}
            onSort={(...args) => galleryState.handleSort(...args, NS_HISTORY)}
            onLabelChange={galleryState.handleLabelChange}
            onDelete={(id) => galleryState.handleDelete(id, NS_HISTORY)}
            messageId={Field.GALLERY}
            allowURL
          />
        </FieldSet>
      </Form>
    </>
  )
}
