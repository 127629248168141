import { call, delay, put, select } from "redux-saga/effects"
import { IdRes, ShowResAdmin } from "../types/apiTypes"
import { selectToken } from "../selectors/interfaceSelector"
import {
  deleteShow,
  deleteShowFailed,
  deleteShowSucceeded,
  fetchShows,
  fetchShowsFailed,
  fetchShowsSucceeded,
  resetShowStatus,
  saveShow,
  saveShowFailed,
  saveShowSucceeded,
} from "actions/showActions"
import {
  requestDeleteShow,
  requestGetShows,
  requestSaveShow,
} from "api/showRequests"
import { ShowActions, normalizeShows } from "models/showModel"

function* resetStatus(key: ShowActions) {
  yield delay(3000)
  yield put(resetShowStatus(key))
}

export function* fetchShowSaga({
  payload: { ids },
}: ReturnType<typeof fetchShows>) {
  try {
    const auth: string = yield select(selectToken)
    const show: ShowResAdmin[] = yield call(requestGetShows, auth, ids)
    yield put(fetchShowsSucceeded(normalizeShows(show)))
  } catch (e: unknown) {
    yield put(fetchShowsFailed(e instanceof Error ? e.message : ""))
  } finally {
    yield* resetStatus("fetchShows")
  }
}

export function* saveShowSaga({
  payload: { formData, id },
}: ReturnType<typeof saveShow>) {
  try {
    const auth: string = yield select(selectToken)
    const res: IdRes<number> = yield call(requestSaveShow, auth, formData, id)
    const [show]: ShowResAdmin[] = yield call(requestGetShows, auth, [res.id])
    yield put(saveShowSucceeded(show))
  } catch (e: unknown) {
    yield put(saveShowFailed(e instanceof Error ? e.message : ""))
  } finally {
    yield* resetStatus("saveShow")
  }
}

export function* deleteShowSaga({
  payload: { id },
}: ReturnType<typeof deleteShow>) {
  try {
    const auth: string = yield select(selectToken)
    yield call(requestDeleteShow, auth, id)
    yield put(deleteShowSucceeded(id))
  } catch (e: unknown) {
    yield put(deleteShowFailed(e instanceof Error ? e.message : ""))
  } finally {
    yield* resetStatus("deleteShow")
  }
}
