import { createSelector } from "reselect"
import { AppState } from "../store"
import { selectLanguage } from "./interfaceSelector"

export const selectProductRecords = (state: AppState) => state.product.records

export const selectProductFetchStatus = (state: AppState) =>
  state.product.statuses.fetchProducts

export const selectProductSaveStatus = (state: AppState) =>
  state.product.statuses.saveProduct

export const selectProductDeleteStatus = (state: AppState) =>
  state.product.statuses.deleteProduct

export const selectProductFetchError = (state: AppState) =>
  state.product.errors.fetchProducts

export const selectProductSaveError = (state: AppState) =>
  state.product.errors.saveProduct

export const selectProductDeleteError = (state: AppState) =>
  state.product.errors.deleteProduct

export const selectProductList = createSelector(selectProductRecords, (map) =>
  map.valueSeq().toArray()
)

export const selectProductById = createSelector(
  selectProductRecords,
  (state: AppState, id?: string) => id,
  (products, id) => (id && products.size > 0 ? products.get(id) : null)
)

export const selectProductInputOptions = createSelector(
  selectProductList,
  selectLanguage,
  (products, language) =>
    products.map(({ id, translations, price }) => ({
      value: id.toString(),
      content: `${translations[language].title} (${price} Kč)`,
    }))
)
