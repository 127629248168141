import React from "react"
import { endOfDay, startOfDay } from "date-fns"
import { LiteEvent } from "../../types/globalTypes"
import DateInput from "../DateInput/DateInput"
import { Field } from "../Messages/FieldMessage"
import InlineFieldset from "../InlineFieldset/InlineFieldset"
import { decodeInputName, encodeInputName } from "../../utils/stringUtils"

interface IDateInputProps {
  value: [number, number]
  name: string
  onChange: (name: string, val: [number, number]) => void
}

const FIELD_FROM = "from"
const FIELD_TO = "to"

export default function DateRange({ value, onChange, name }: IDateInputProps) {
  const [from, to] = value

  const handleChange = ({
    currentTarget: { name: targetName, valueAsNumber: date },
  }: LiteEvent<number>) => {
    const [, field] = decodeInputName(targetName)
    return onChange(
      name,
      field === FIELD_FROM
        ? date < to
          ? [startOfDay(date).valueOf(), to]
          : [startOfDay(date).valueOf(), endOfDay(date).valueOf()]
        : [from, endOfDay(date).valueOf()]
    )
  }

  return (
    <InlineFieldset>
      <DateInput
        value={from}
        name={encodeInputName(name, FIELD_FROM)}
        messageId={Field.FROM}
        onChange={handleChange}
      />
      <DateInput
        value={to}
        name={encodeInputName(name, FIELD_TO)}
        messageId={Field.TO}
        onChange={handleChange}
        minDate={from}
      />
    </InlineFieldset>
  )
}
