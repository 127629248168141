import { FormattedMessage } from "react-intl"
import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { EditComponentProps } from "@material-table/core"
import {
  ExtendedOrderReqAdmin,
  LiteEvent,
  OrderDataGrid,
} from "../../types/globalTypes"
import ComboBox from "../../components/ComboBox/ComboBox"
import {
  blankOrderData,
  getVatOptionByRate,
  getVatRateByOption,
  invoicePublishersInputOptions,
  vatRateInputOptions,
} from "../../utils/typeUtils"
import { Field } from "../../components/Messages/FieldMessage"
import {
  selectAgencyInputOptions,
  selectAgencyRecords,
} from "../../selectors/agencySelector"
import CellGroup from "../../components/CellGroup/CellGroup"
import { InvoicePublisher } from "../../types/apiTypes"
import Can from "../../components/Can/Can"
import { useGetUsersAgency } from "../../hooks/interfaceHooks"
import { useOrderPriceFetch } from "../../hooks/orderHooks"
import config from "../../config"
import { noop } from "ramda-adjunct"

const defaultValue = "default"

const defaultOption = {
  value: defaultValue,
  content: (
    <FormattedMessage id={"defaultValue"} defaultMessage={"Default value"} />
  ),
}

export function OrderSourceEditCell({
  rowData: { req = blankOrderData },
  onChange,
}: EditComponentProps<OrderDataGrid>) {
  const agencies = useSelector(selectAgencyRecords)
  const inputOptions = useSelector(selectAgencyInputOptions)
  const usersAgency = useGetUsersAgency()
  const isAgency = usersAgency !== config.agencyIds.web
  const [fetchPrice] = useOrderPriceFetch()

  const selectedAgency = agencies.get(req?.agencyId.toString())

  const changeRow = (data: ExtendedOrderReqAdmin) => {
    onChange(data)
  }

  const handleAgencyChange = ({
    currentTarget: { valueAsNumber, value },
  }: LiteEvent) => {
    const agency = agencies.get(value)
    changeRow({
      ...req,
      agencyId: valueAsNumber,
      email: req.email || agency?.email || null,
      // set agency's default payment methods
      paymentMethod: agency?.paymentMethods?.[0] || req.paymentMethod,
      paymentMethodDeposit: agency?.paymentMethods?.[0] || req.paymentMethod,
      // reset package and upsales
      packageId: 0,
      packageOrder: null,
      upsales: [],
      // reset financial overrides
      overrideInvoicePublisher: null,
      overrideVatRate: null,
    })
  }

  const handlePublisherChange = ({ currentTarget: { value } }: LiteEvent) => {
    changeRow({
      ...req,
      overrideInvoicePublisher:
        value === defaultValue ? null : (value as InvoicePublisher),
    })
  }

  const handleVATChange = ({ currentTarget: { value } }: LiteEvent) => {
    changeRow({
      ...req,
      overrideVatRate:
        value === defaultValue ? null : { value: getVatRateByOption(value) },
    })
  }

  const handleBlur = () => {
    fetchPrice(req).then(([price, _, { bonSum }]) =>
      changeRow({ ...req, ...price, bonSum })
    ).catch(noop)
  }

  useEffect(() => {
    if (isAgency) changeRow({ ...req, agencyId: usersAgency })
    // eslint-disable-next-line
  }, [isAgency, usersAgency])

  return (
    <CellGroup>
      <ComboBox
        messageId={Field.SOURCE}
        name={`orderSource`}
        options={inputOptions}
        value={req.agencyId.toString()}
        onChange={handleAgencyChange}
        onBlur={handleBlur}
        disableClearable
      />
      <Can resource={"orderAdmin"} operations={["W"]}>
        <ComboBox
          messageId={Field.INVOICE_PUBLISHER}
          name={`invoicePublisher`}
          value={
            req.overrideInvoicePublisher !== null
              ? req.overrideInvoicePublisher
              : selectedAgency?.invoicePublisher || defaultValue
          }
          options={[defaultOption, ...invoicePublishersInputOptions]}
          onChange={handlePublisherChange}
          disableClearable
        />
      </Can>
      <Can resource={"orderAdmin"} operations={["W"]}>
        <ComboBox
          messageId={Field.VAT_RATE}
          name={`vatRate`}
          value={
            req.overrideVatRate !== null
              ? getVatOptionByRate(req.overrideVatRate.value)
              : getVatOptionByRate(selectedAgency?.vatRate) || defaultValue
          }
          options={[defaultOption, ...vatRateInputOptions]}
          onChange={handleVATChange}
          disableClearable
        />
      </Can>
    </CellGroup>
  )
}
