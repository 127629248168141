import React from "react"
import { FormattedMessage } from "react-intl"
import { OrderStatus } from "../../types/apiTypes"

interface IOrderStatusMessageProps {
  id: OrderStatus
}

export default function OrderStatusMessage({ id }: IOrderStatusMessageProps) {
  switch (id) {
    case OrderStatus.APPROVED:
      return <FormattedMessage id={`APPROVED`} defaultMessage={`Approved`} />
    case OrderStatus.CANCELLED:
      return <FormattedMessage id={`CANCELLED`} defaultMessage={`Canceled`} />
    case OrderStatus.CREATED:
      return <FormattedMessage id={`CREATED`} defaultMessage={`Created`} />
    case OrderStatus.FULFILLED:
      return <FormattedMessage id={`FULFILLED`} defaultMessage={`Fulfilled`} />
    default:
      return null
  }
}
