import React, { ChangeEvent } from "react"
import IconButton from "@material-ui/core/IconButton"
import CropIcon from "@material-ui/icons/Crop"
import DeleteIcon from "@material-ui/icons/Delete"
import { FormControl, FormHelperText } from "@material-ui/core"
import { Language } from "../../types/apiTypes"
import { MediaItem, TranslationSimpleMap } from "../../types/globalTypes"
import Input from "../Input/Input"
import FileUpload from "../FileUpload/FileUpload"
import Sortable from "../Sortable/Sortable"
import useStyles from "./GalleryUploadStyles"
import Thumbnail from "../Thumbnail/Thumbnail"
import { Field } from "../Messages/FieldMessage"
import ErrorMessage, { ErrorType } from "../Messages/ErrorMessage"
import { decodeInputName, encodeInputName } from "../../utils/stringUtils"
import { isSourceVideo } from "../../utils/typeUtils"

interface IGalleryUpload {
  gallery: MediaItem[]
  name: string
  labels: {
    [key: string]: TranslationSimpleMap
  }
  contents?: {
    [key: string]: TranslationSimpleMap
  }
  language: Language
  onUpload: (files: Array<File | string>) => void
  onSort: (source: number, destination: number) => void
  onCrop?: (id: string) => void
  onLabelChange: (id: string, language: Language, value: string) => void
  onContentChange?: (id: string, language: Language, value: string) => void
  onDelete: (id: string) => void
  allowURL?: boolean
  isMultiple?: boolean
  required?: boolean
  showRequiredWarning?: boolean
  messageId?: Field
}

export default function GalleryUpload({
  gallery,
  onUpload,
  name,
  onDelete,
  onSort,
  onCrop,
  contents,
  onLabelChange,
  onContentChange,
  labels,
  language,
  messageId,
  allowURL = false,
  isMultiple = true,
  required = false,
  showRequiredWarning = false,
}: IGalleryUpload) {
  const classes = useStyles()

  const handleLabelChange = ({
    currentTarget: { value, name: labelName },
  }: ChangeEvent<HTMLInputElement>) => {
    const [, id] = decodeInputName(labelName)
    onLabelChange(id, language, value)
  }

  const handleContentChange = ({
    currentTarget: { value, name: labelName },
  }: ChangeEvent<HTMLInputElement>) => {
    if (onContentChange) {
      const [, id] = encodeInputName(labelName)
      onContentChange(id, language, value)
    }
  }

  const handleDelete = (id: string) => () => {
    onDelete(id)
  }

  return (
    <FormControl fullWidth margin={"normal"} className={classes.root}>
      <FileUpload
        messageId={messageId}
        onUpload={onUpload}
        name={name}
        isMultiple={isMultiple}
        allowURL={allowURL}
        required={required && gallery.length === 0}
        hasError={showRequiredWarning && gallery.length === 0}
      />
      {showRequiredWarning && gallery.length === 0 && (
        <FormHelperText error>
          <ErrorMessage id={ErrorType.SHOULD_FILL} />
        </FormHelperText>
      )}
      {gallery.length > 0 && (
        <Sortable
          id={`sortable-${name}`}
          onSort={onSort}
          shouldDisableInteractivity={false}
        >
          {gallery.map((item) => (
            <div key={item.id} className={classes.item}>
              <IconButton aria-label="delete" onClick={handleDelete(item.id)}>
                <DeleteIcon />
              </IconButton>
              {onCrop && item.source instanceof File && (
                <IconButton aria-label="crop" onClick={handleDelete(item.id)}>
                  <CropIcon />
                </IconButton>
              )}
              <Thumbnail source={item.source} />
              <Input
                name={encodeInputName('label', item.id)}
                value={labels[item.id]?.[language] || ""}
                onChange={handleLabelChange}
                dense
              />
              {contents && (
                <Input
                  name={encodeInputName('content', item.id)}
                  value={contents[item.id]?.[language] || ""}
                  onChange={handleContentChange}
                  dense
                />
              )}
              {isSourceVideo(item.source) && (
                <Input
                  name={encodeInputName('src', item.id)}
                  value={item.source}
                  disabled
                  dense
                />
              )}
            </div>
          ))}
        </Sortable>
      )}
    </FormControl>
  )
}
