import { createSelector } from "reselect"
import { ComputedBon, OrderResAdmin } from "../types/apiTypes"
import { VoucherDataGrid } from "../types/globalTypes"
import { selectOrdersList } from "./orderSelector"
import { selectAgencyRecords } from "./agencySelector"
import { AgencyMap } from "../models/agencyModel"
import { getBonSummary } from "../utils/apiUtils"

export const selectVoucherDataGrid = createSelector(
  selectOrdersList, // filter is shared
  selectAgencyRecords,
  (orders: OrderResAdmin[], agencyMap: AgencyMap): VoucherDataGrid[] =>
    orders.map((order) => ({
      ...order,
      agency: agencyMap.get(order.agencyId?.toString())?.title || "Web",
    }))
)

const voucherSummary = {
  vouchers: 0,
  guests: 0,
  surcharge: 0,
  bons: 0
}

export const selectVoucherBonSum = createSelector(
  selectOrdersList,
  (orders): ComputedBon[] => getBonSummary(orders.flatMap(({ bonSum }) => bonSum))
)

export const selectVoucherSummary = createSelector(selectOrdersList, (orders) =>
  orders.reduce(
    (reduction: typeof voucherSummary, order) => ({
      vouchers: reduction.vouchers + 1,
      guests:
        reduction.guests +
        (order.packageOrder
          ? order.packageOrder.numberOfPersons
          : order.reservation
            ? order.reservation.numberOfPersons
            : 0),
      surcharge: reduction.surcharge + (order.price - order.deposit),
      bons: reduction.bons + order.bons.length
    }),
    voucherSummary
  )
)