import React from "react"
import CircularProgress from "@material-ui/core/CircularProgress"
import useStyles from "./SpinnerLayoutStyle"

export interface Props {
  isVisible: boolean
}

export default function SpinnerLayout({ isVisible }: Props) {
  const classes = useStyles({ isVisible })
  return (
    <div
      className={classes.root}
      data-testid={`spinner`}
      aria-hidden={isVisible ? "false" : "true"}
    >
      <CircularProgress color={"inherit"} />
    </div>
  )
}
