import React from "react"
import { Button as MuiButton } from "@material-ui/core"
import { ButtonProps } from "@material-ui/core/Button"
import useStyles from "./ButtonStyle"

export default function Button({ children, className, ...props }: ButtonProps) {
  const classes = useStyles()
  return (
    <MuiButton className={classes.root} {...props}>
      {children}
    </MuiButton>
  )
}
