import React, { ReactElement } from "react"
import {
  DragDropContext,
  Droppable,
  Draggable,
  DragDropContextProps,
  DroppableProps,
} from "react-beautiful-dnd"

interface ISortableProps {
  onSort?: (sourceIndex: number, destinationIndex: number) => void
  children: ReactElement[]
  id: string
  isHorizontal?: boolean
  dropProps?: Omit<DroppableProps, "children" | "droppableId">
  parentProps?: {
    className: string
    as: "div" | "main" | "ul"
  }
  shouldDisableInteractivity?: boolean
}

export default function Sortable({
  onSort,
  children,
  id,
  isHorizontal = false,
  dropProps,
  parentProps = {
    className: ``,
    as: `div`,
  },
  shouldDisableInteractivity = true,
}: ISortableProps & Omit<DragDropContextProps, "onDragEnd">) {
  const { className, as: Element } = parentProps
  return (
    <DragDropContext
      onDragEnd={(result) =>
        onSort && result.destination
          ? onSort(result.source.index, result.destination.index)
          : undefined
      }
    >
      <Droppable
        droppableId={id}
        {...dropProps}
        direction={isHorizontal ? "horizontal" : "vertical"}
      >
        {(provided) => (
          <Element
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={className}
          >
            {React.Children.map(children, (child, index) => (
              <Draggable
                draggableId={
                  // @ts-ignore // TODO
                  typeof child?.key === "string" ? child?.key : index.toString()
                }
                index={index}
                disableInteractiveElementBlocking={shouldDisableInteractivity}
                shouldRespectForcePress
              >
                {({ innerRef, draggableProps, dragHandleProps }) => (
                  <div ref={innerRef} {...draggableProps} {...dragHandleProps}>
                    {child}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </Element>
        )}
      </Droppable>
    </DragDropContext>
  )
}
