import { useIntl } from "react-intl"
import { isValidElement, useEffect, useMemo, useState } from "react"
import { SimpleInputOption, InputOption } from "../types/globalTypes"
import config from "../config"

export function useInputOptions(
  options: InputOption[],
  sort?: boolean
): SimpleInputOption[] {
  const { formatMessage: translate } = useIntl() // obfuscate babel-react-intl

  return useMemo<SimpleInputOption[]>(() => {
    const output = options.map((option) => ({
      ...option,
      content: isValidElement(option.content)
        ? translate({ id: option?.content?.props?.id })
        : (option.content as string),
    }))

    return sort
      ? output.sort((a, b) => a.content.localeCompare(b.content))
      : output
  }, [options, sort, translate])
}

export function useImageReader(source: File | string) {
  const [src, setSrc] = useState(``)
  useEffect(() => {
    if (source instanceof File) {
      const reader = new FileReader()
      reader.onload = ({ target }: ProgressEvent<FileReader>) => {
        if (target && typeof target.result === `string`) setSrc(target.result)
      }
      reader.readAsDataURL(source)
    } else {
      setSrc(`${config.publicEndpoint}/image/${source}?width=${1024}`)
    }
  }, [source])
  return src
}
