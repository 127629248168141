import Grid from "@material-ui/core/Grid"
import React, {
  ChangeEvent,
  ReactElement,
  ReactNode,
  SyntheticEvent,
} from "react"
import SaveIcon from "@material-ui/icons/Save"
import { FormattedMessage } from "react-intl"
import { LinearProgress, Paper, Typography } from "@material-ui/core"
import LoadingButton from "../LoadingButton/LoadingButton"
import useStyles from "./FormStyles"
import { IFormData } from "../../types/globalTypes"
import { RequestStatus } from "../../types/statusTypes"

export interface IFormProps {
  title?: ReactNode
  children: ReactNode
  altActions?: ReactNode
  onSubmit: (values: IFormData) => void
  error?: string
  toolbar?: ReactElement
  useGenericCta?: boolean
  statuses?: RequestStatus[]
  usePaper?: boolean
}

export default function Form({
  title,
  children,
  onSubmit,
  useGenericCta = false,
  usePaper = true,
  toolbar,
  altActions,
  statuses,
}: IFormProps) {
  const isLoading = statuses?.some((status) => status === RequestStatus.REQUESTED) || false

  const classes = useStyles({ isLoading })

  const handleSubmit = (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault()
  }

  const handleClick = (e: SyntheticEvent<HTMLButtonElement>) => {
    const form = e.currentTarget.form as HTMLFormElement
    const formData = new FormData(form)
    const data: IFormData = {}
    formData.forEach((value, key) => {
      if (form[key].type === `checkbox`) {
        data[key] = `true`
      } else if (typeof value === "string") {
        data[key] = value
      }
    })
    onSubmit(data)
  }

  return (
    <form onSubmit={handleSubmit} method={"post"} className={classes.root}>
      {isLoading && <LinearProgress color={"secondary"} />}
      <header className={classes.header}>
        {title && (
          <Typography component={"h1"} variant={"h5"}>
            {title}
          </Typography>
        )}
        {toolbar}
      </header>

      {usePaper ? (
        <Paper>
          <div className={classes.body}>{children}</div>
        </Paper>
      ) : (
        children
      )}
      <Grid container spacing={2}>
        <Grid item>
          <LoadingButton
            type="button"
            variant="contained"
            color="primary"
            isLoading={isLoading}
            startIcon={<SaveIcon />}
            onClick={handleClick}
          >
            {useGenericCta ? (
              <FormattedMessage id={`submit`} defaultMessage={`Submit`} />
            ) : (
              title
            )}
          </LoadingButton>
        </Grid>
        <Grid item>{altActions}</Grid>
      </Grid>
    </form>
  )
}
