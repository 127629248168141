import { useSelector } from "react-redux"
import React, { useState } from "react"
import AddBox from "@material-ui/icons/AddBox"
import { FormattedMessage } from "react-intl"
import { Navigate } from "react-router-dom"
import Button from "../../components/Button/Button"
import DataGrid from "../../components/DataGrid/DataGrid"
import { PackageUpsaleResAdmin } from "../../types/apiTypes"
import config from "../../config"
import Results from "../../components/Results/Results"
import {
  useUpsaleDelete,
  useUpsaleFetch,
  useUpsaleSave,
} from "../../hooks/upsaleHooks"
import RouteMessage from "../../components/Messages/RouteMessage"
import { Field } from "../../components/Messages/FieldMessage"
import { Entity } from "../../components/Messages/EntityMessage"
import {
  useLanguage,
  useLocalStoragePagination,
} from "../../hooks/interfaceHooks"
import StatusProgressBar from "../../components/StatusProgressBar/StatusProgressBar"
import BoolMessage from "../../components/Messages/BoolMessage"
import UpsaleListPurposeCell from "./UpsaleListPurposeCell"
import UpsaleListTypeCell from "./UpsaleListTypeCell"
import SourceFilter from "../../components/SourceFilter/SourceFilter"
import { selectUpsaleDataGrid } from "../../selectors/upsaleSelector"

export default function UpsaleList() {
  const [redirectId, setRedirectId] = useState<string | null>(null)
  const language = useLanguage()
  const [, saveStatus, saveError] = useUpsaleSave()
  const [, fetchStatus, fetchError] = useUpsaleFetch()
  const upsales = useSelector(selectUpsaleDataGrid)
  const [deleteUpsale, deleteStatus, deleteError] = useUpsaleDelete()
  const paginationProps = useLocalStoragePagination("upsale")

  return (
    <>
      <Results
        statuses={[saveStatus, fetchStatus, deleteStatus]}
        errors={[saveError, fetchError, deleteError]}
        actions={["save", "fetch", "delete"]}
        entity={Entity.UPSALE}
      />
      {redirectId !== null && (
        <Navigate to={config.routes.upsaleDetail.path(redirectId)} />
      )}
      <StatusProgressBar statuses={[fetchStatus]} />
      <DataGrid<PackageUpsaleResAdmin>
        {...paginationProps}
        data={upsales}
        title={<RouteMessage id={`upsaleList`} />}
        onRowClick={(_, row) => setRedirectId(row ? row.id.toString() : null)}
        customFilter={<SourceFilter />}
        columns={[
          {
            title: Field.TITLE,
            field: `translations.${language}.title`,
            defaultSort: "asc",
            customSort: (a, b) =>
              a.translations[language].title.localeCompare(
                b.translations[language].title
              ),
          },
          {
            title: Field.PURPOSE,
            field: `tagIds`,
            render: UpsaleListPurposeCell,
            customSort: (a) => (a.isForWeb ? 1 : -1),
          },
          {
            title: Field.TYPE,
            field: `tagIds`,
            render: UpsaleListTypeCell,
            sorting: false,
          },
          {
            title: Field.IS_ACTIVE,
            field: `isActive`,
            render: ({ isActive }: PackageUpsaleResAdmin) => (
              <BoolMessage value={isActive} />
            ),
          },
        ]}
        cta={
          <Button
            onClick={() => setRedirectId(``)}
            variant="contained"
            color={`primary`}
            startIcon={<AddBox />}
          >
            <FormattedMessage
              id={`createUpsale`}
              defaultMessage={`Create upsale`}
            />
          </Button>
        }
        editable={{
          onRowDelete: ({ id }) =>
            new Promise<void>((resolve) => {
              deleteUpsale(id)
              resolve()
            }),
        }}
      />
    </>
  )
}
