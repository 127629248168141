import React from "react"
import ComboBox from "../ComboBox/ComboBox"
import { Field } from "../Messages/FieldMessage"
import { GeneralEvent } from "../../utils/inputUtils"
import {
  bonCategoryGroupMap,
  bonCategoryInputOptions,
  bonGroupOptions,
  createLiteEvent,
} from "../../utils/typeUtils"
import { LiteEvent } from "../../types/globalTypes"

interface IBonCategorySelectProps {
  onChange: (e: GeneralEvent) => void
  dense: boolean
  value: string
  name: string
}

export default function BonCategorySelect({
  onChange,
  dense,
  value,
  name,
}: IBonCategorySelectProps) {
  const handleGroupChange = (e: LiteEvent) => {
    const groupIndex = bonGroupOptions.findIndex(
      (option) => option.value === e.currentTarget.value
    )
    const [bonCategory] =
      Object.entries(bonCategoryGroupMap).find(
        ([, index]) => index === groupIndex
      ) || []
    bonCategory && onChange(createLiteEvent(name, bonCategory))
  }

  const handleChange = (e: LiteEvent) => {
    onChange(createLiteEvent(name, e.currentTarget.value))
  }

  return (
    <>
      <ComboBox
        messageId={Field.GROUP}
        name={"bonCategoryGroup"}
        options={bonGroupOptions}
        dense={dense}
        onChange={handleGroupChange}
        value={bonGroupOptions[bonCategoryGroupMap[value]]?.value}
        disableClearable
      />
      <ComboBox
        messageId={Field.CATEGORY}
        name={"bonCategory"}
        options={bonCategoryInputOptions[bonCategoryGroupMap[value]]}
        dense={dense}
        value={value}
        onChange={handleChange}
        disableClearable
      />
    </>
  )
}
