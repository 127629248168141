import { Record, RecordOf, Map } from "immutable"
import { RequestStatus } from "../types/statusTypes"
import { UserRes } from "../types/apiTypes"

export type UserMap = Map<string, UserRes>

const defaultStatuses = {
  fetchUsers: RequestStatus.INITIAL,
  deleteUser: RequestStatus.INITIAL,
  saveUser: RequestStatus.INITIAL,
}

const makeStatuses: Record.Factory<typeof defaultStatuses> = Record(
  defaultStatuses
)

const defaultErrors = {
  fetchUsers: "",
  deleteUser: "",
  saveUser: "",
}

const makeErrors: Record.Factory<typeof defaultErrors> = Record(defaultErrors)

type UserStateType = {
  statuses: RecordOf<typeof defaultStatuses>
  errors: RecordOf<typeof defaultErrors>
  records: UserMap
}

const defaultUserState: UserStateType = {
  statuses: makeStatuses(),
  errors: makeErrors(),
  records: Map() as UserMap,
}

export type UserState = RecordOf<UserStateType>

export type UserActions = keyof typeof defaultStatuses

export const makeUserState: Record.Factory<UserStateType> = Record(
  defaultUserState
)
