import React, { memo, useState } from "react"
import { Navigate } from "react-router-dom"
import DataGrid from "../../components/DataGrid/DataGrid"
import config from "../../config"
import { PackageResAdmin } from "../../types/apiTypes"
import Results from "../../components/Results/Results"
import RouteMessage from "../../components/Messages/RouteMessage"
import { Field } from "../../components/Messages/FieldMessage"
import { Entity } from "../../components/Messages/EntityMessage"
import { useActivityFetch } from "../../hooks/activityHooks"
import StatusProgressBar from "../../components/StatusProgressBar/StatusProgressBar"
import BoolMessage from "../../components/Messages/BoolMessage"
import { useLanguage, useLocalStoragePagination } from "../../hooks/interfaceHooks"

function PackageList() {
  const [redirectId, setRedirectId] = useState<string | null>(null)
  const language = useLanguage()
  const [activities, fetchStatus, fetchError] = useActivityFetch()
  const paginationProps = useLocalStoragePagination("package")

  return (
    <>
      {redirectId !== null && (
        <Navigate to={config.routes.packageDetail.path(redirectId)} />
      )}
      <Results
        statuses={[fetchStatus]}
        errors={[fetchError]}
        actions={["fetch"]}
        entity={Entity.PACKAGE}
      />
      <StatusProgressBar statuses={[fetchStatus]} />
      <DataGrid<PackageResAdmin>
        {...paginationProps}
        title={<RouteMessage id={"packageList"} />}
        onRowClick={(_, row) => setRedirectId(row ? row.id.toString() : null)}
        columns={[
          {
            title: Field.TITLE,
            field: `translations.${language}.title`,
            defaultSort: "asc",
            customSort: (a, b) =>
              a.translations[language].title.localeCompare(
                b.translations[language].title
              ),
          },
          {
            title: Field.IS_ACTIVE,
            field: `isActive`,
            render: ({ isActive }: PackageResAdmin) => (
              <BoolMessage value={isActive} />
            ),
          },
        ]}
        data={activities
          .valueSeq()
          .toArray()
          .filter(({ isForWeb }) => isForWeb)}
      />
    </>
  )
}

export default memo(PackageList)
