import React from "react"
import { useIntl } from "react-intl"
import { Currency } from "../../types/apiTypes"
import { Price as PriceType } from "../../types/globalTypes"

interface IPriceProps {
  price?: PriceType | number
  processor?: (i: number) => number
  currency?: Currency
}

export default function Price({
  price,
  processor = (i) => i,
  currency = Currency.CZK,
}: IPriceProps) {
  const { formatNumber } = useIntl()
  if (price === undefined) return null
  return (
    <span>
      {formatNumber(
        processor(typeof price === "number" ? price : price[currency]),
        {
          style: "currency",
          currency,
          minimumSignificantDigits: 1,
        }
      )}
    </span>
  )
}
