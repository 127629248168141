import React, { FormEvent, useState } from "react"
import { FormattedMessage } from "react-intl"
import Box from "@material-ui/core/Box"
import Avatar from "@material-ui/core/Avatar"
import LockOutlinedIcon from "@material-ui/icons/LockOutlined"
import Typography from "@material-ui/core/Typography"
import Input from "../../components/Input/Input"
import { Field } from "../../components/Messages/FieldMessage"
import LoadingButton from "../../components/LoadingButton/LoadingButton"
import { requestRecoveryLink } from "../../api/interfaceRequests"
import { RequestStatus } from "../../types/statusTypes"
import Toast from "../../components/Toast/Toast"

export default function ForgotPassword() {
  const [status, setStatus] = useState(RequestStatus.INITIAL)
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    try {
      setStatus(RequestStatus.REQUESTED)
      await requestRecoveryLink({
        email: e.currentTarget.email.value,
      })
      setStatus(RequestStatus.SUCCEEDED)
    } catch {
      setStatus(RequestStatus.SUCCEEDED)
    }
  }

  return (
    <>
      <Toast
        open={status === RequestStatus.SUCCEEDED}
        title={
          <FormattedMessage
            id={`forgottenPassword`}
            defaultMessage={`Forgotten password`}
          />
        }
        message={
          <FormattedMessage
            id={"recoveryLinkSent"}
            defaultMessage={"Check your email for recovery link"}
          />
        }
      />
      <Box display={"flex"} alignItems={"center"} mb={2}>
        <Box pr={2}>
          <Avatar>
            <LockOutlinedIcon />
          </Avatar>
        </Box>
        <Typography component="h1" variant="h5">
          <FormattedMessage
            id={`forgottenPassword`}
            defaultMessage={`Forgotten password`}
          />
        </Typography>
      </Box>
      <form method={"post"} onSubmit={handleSubmit}>
        <Input name={"email"} messageId={Field.EMAIL} required type={"email"} />
        <Box mt={2}>
          <LoadingButton
            isLoading={status === RequestStatus.REQUESTED}
            disabled={status === RequestStatus.SUCCEEDED}
            type={"submit"}
            variant={"contained"}
            color={"primary"}
          >
            <FormattedMessage
              id={`recoverPassword`}
              defaultMessage={`Recover password`}
            />
          </LoadingButton>
        </Box>
      </form>
    </>
  )
}
