import React from "react"
import { Box } from "@material-ui/core"
import { OrderEmployeeResAdmin, UserRes } from "../../types/apiTypes"
import { formatDateTime } from "../../utils/timeUtils"

interface IEmployeeInfoProps {
  employee: OrderEmployeeResAdmin
  user?: UserRes
}

export default function EmployeeInfo({ employee, user }: IEmployeeInfoProps) {
  return (
    <Box display={"flex"} flexWrap={"nowrap"}>
      <Box component={"span"} width={80}>
        ID: {employee.orderId}
      </Box>
      <Box component={"span"} mr={"auto"} whiteSpace={"nowrap"}>
        {formatDateTime(employee.dtStart)}&nbsp;–&nbsp;
        {formatDateTime(employee.dtEnd, true)}
      </Box>
      <Box component={"span"} ml={2}>
        {user?.fullName}
      </Box>
    </Box>
  )
}
