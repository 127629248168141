import React, { ChangeEvent, useCallback } from "react"
import { useSelector } from "react-redux"
import { selectOptionMap } from "selectors/activitySelector"
import { EditComponentProps } from "@material-table/core"
import { useIsCompetent } from "hooks/interfaceHooks"
import { ExtendedOrderReqAdmin, OrderDataGrid } from "../../types/globalTypes"
import Input from "../../components/Input/Input"
import { blankOrderData } from "../../utils/typeUtils"
import { Field } from "../../components/Messages/FieldMessage"
import { useOrderPriceFetch } from "../../hooks/orderHooks"
import { useDebouncedCallback } from "use-debounce"
import { Box } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { ErrorType } from "../../components/Messages/ErrorMessage"
import { noop } from "ramda-adjunct"

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    color: theme.palette.error.main,
  },
}))

export function OrderPersonsEditCell({
  rowData: { req = blankOrderData },
  onChange,
}: EditComponentProps<OrderDataGrid>) {
  const classes = useStyles()
  const [fetchPrice] = useOrderPriceFetch()
  const optionMap = useSelector(selectOptionMap)
  const optionSelectedInfo =
    req.packageOrder && optionMap.get(req.packageOrder?.optionId.toString())
  const canOverwriteMinMax = useIsCompetent("orderAdmin", "W")

  const changeRow = useCallback(
    (data: ExtendedOrderReqAdmin) => {
      onChange(data)
    },
    [onChange]
  )

  const debouncedFetchPrice = useDebouncedCallback(
    (data: ExtendedOrderReqAdmin) =>
      fetchPrice(data).then(([price, _, { bonSum }]) =>
        changeRow({ ...data, ...price, bonSum })
      ).catch(noop),
    500
  )

  const handleChange = useCallback(
    ({ currentTarget: { valueAsNumber } }: ChangeEvent<HTMLInputElement>) => {
      const next: ExtendedOrderReqAdmin = {
        ...req,
        packageOrder: req.packageOrder && {
          ...req.packageOrder,
          numberOfPersons: valueAsNumber || 0,
        },
      }
      changeRow(next)
      debouncedFetchPrice(next)
    },
    [changeRow, debouncedFetchPrice, req]
  )

  if (!req.packageOrder) return <></>

  return (
    <>
      <Input
        type={"number"}
        min={canOverwriteMinMax ? 1 : optionSelectedInfo?.minCapacity || 1}
        max={canOverwriteMinMax ? undefined : optionSelectedInfo?.maxCapacity}
        name={"numberOfPersons"}
        messageId={Field.NUMBER_OF_PERSONS}
        value={req.packageOrder?.numberOfPersons || ""}
        placeholder="0"
        inputError={
          !req.packageOrder?.numberOfPersons
            ? { type: ErrorType.REQUIRED }
            : undefined
        }
        onChange={handleChange}
        dense
      />
      {optionSelectedInfo && req.packageOrder && (
        <Box
          className={
            optionSelectedInfo.maxCapacity < req.packageOrder.numberOfPersons ||
            req.packageOrder.numberOfPersons < optionSelectedInfo.minCapacity
              ? classes.errorMessage
              : ""
          }
        >
          Min: {optionSelectedInfo?.minCapacity} | Max:{" "}
          {optionSelectedInfo?.maxCapacity}
        </Box>
      )}
    </>
  )
}
