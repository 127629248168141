import { call, delay, put, select } from "redux-saga/effects"
import {
  deleteUpsale,
  deleteUpsaleFailed,
  deleteUpsaleSucceeded,
  fetchUpsales,
  fetchUpsalesFailed,
  fetchUpsalesSuccess,
  resetUpsaleStatus,
  saveUpsale,
  saveUpsaleFailed,
  saveUpsaleSucceeded,
} from "../actions/upsaleActions"
import {
  requestDeleteUpsale,
  requestGetUpsales,
  requestSaveUpsale,
} from "../api/upsaleRequests"
import { UpsaleActions } from "../models/upsaleModel"
import { IdRes, PackageUpsaleResAdmin } from "../types/apiTypes"
import { selectToken } from "../selectors/interfaceSelector"
import { arrayToMap } from "../utils/arrayUtils"

function* resetStatus(key: UpsaleActions) {
  yield delay(3000)
  yield put(resetUpsaleStatus(key))
}

export function* fetchUpsalesSaga({
  payload: { ids },
}: ReturnType<typeof fetchUpsales>) {
  try {
    const auth: string = yield select(selectToken)
    const upsales: PackageUpsaleResAdmin[] = yield call(
      requestGetUpsales,
      auth,
      ids
    )
    yield put(fetchUpsalesSuccess(arrayToMap(upsales)))
    // eslint-disable-next-line
  } catch (e: any) {
    yield put(fetchUpsalesFailed(e.message))
  } finally {
    yield* resetStatus("fetchUpsales")
  }
}

export function* saveUpsaleSaga({
  payload: { data, id },
}: ReturnType<typeof saveUpsale>) {
  try {
    const auth: string = yield select(selectToken)
    const res: IdRes<number> = yield call(requestSaveUpsale, auth, data, id)
    const [upsale]: PackageUpsaleResAdmin[] = yield call(
      requestGetUpsales,
      auth,
      [res.id]
    )
    yield put(saveUpsaleSucceeded(upsale))
    // eslint-disable-next-line
  } catch (e: any) {
    yield put(saveUpsaleFailed(e.message))
  } finally {
    yield* resetStatus("saveUpsale")
  }
}

export function* deleteUpsaleSaga({
  payload: { id },
}: ReturnType<typeof deleteUpsale>) {
  try {
    const auth: string = yield select(selectToken)
    yield call(requestDeleteUpsale, auth, id)
    yield put(deleteUpsaleSucceeded(id))
    // eslint-disable-next-line
  } catch (e: any) {
    yield put(deleteUpsaleFailed(e.message))
  } finally {
    yield* resetStatus("deleteUpsale")
  }
}
