import { makeStyles, Theme } from "@material-ui/core"

export default makeStyles((theme: Theme) => ({
  root: {},
  item: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(2),
    },
  },
}))
