import React from "react"
import { EditComponentProps } from "@material-table/core"
import { LiteEvent, ReservationDataGrid } from "../../types/globalTypes"
import {
  blankOrderData,
  orderStatusesInputOptions,
} from "../../utils/typeUtils"
import ComboBox from "../../components/ComboBox/ComboBox"

export default function ReservationStatusEditCell({
  rowData: { req = blankOrderData },
  onChange,
}: EditComponentProps<ReservationDataGrid>) {
  const handleChange = ({ currentTarget: { value } }: LiteEvent) => {
    onChange({
      ...req,
      status: value,
    })
  }

  return (
    <ComboBox
      name={`status`}
      options={orderStatusesInputOptions}
      onChange={handleChange}
      value={req.status}
    />
  )
}
