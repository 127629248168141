import {
  makeUpsaleState,
  UpsaleActions,
  UpsaleState,
} from "../models/upsaleModel"
import { ActionTypes, AnyAction } from "../types/actionTypes"
import { RequestStatus as Status, RequestStatus } from "../types/statusTypes"

const initialState: UpsaleState = makeUpsaleState()

export default function upsaleReducer(
  state = initialState,
  action: AnyAction | null = null
): UpsaleState {
  // helpers
  const setStatus = (key: UpsaleActions, status: Status) =>
    state.set("statuses", state.statuses.set(key, status))
  const setError = (key: UpsaleActions, error: string) =>
    state
      .set("errors", state.errors.set(key, error))
      .set("statuses", state.statuses.set(key, RequestStatus.FAILED))

  switch (action?.type) {
    // logout
    case ActionTypes.LOGOUT_USER_SUCCEEDED:
      return initialState

    // reset status to default and error message to empty string
    case ActionTypes.RESET_UPSALE_STATUS:
      return setStatus(action.payload.key, RequestStatus.INITIAL).set(
        "errors",
        state.errors.set(action.payload.key, "")
      )

    // fetch
    case ActionTypes.FETCH_UPSALES_REQUESTED:
      return setStatus("fetchUpsales", RequestStatus.REQUESTED)

    case ActionTypes.FETCH_UPSALES_SUCCEEDED:
      return setStatus("fetchUpsales", RequestStatus.SUCCEEDED).set(
        "records",
        state.records.merge(action.payload.upsales)
      )

    case ActionTypes.FETCH_UPSALES_FAILED:
      return setError("fetchUpsales", action.payload.message)

    // save
    case ActionTypes.SAVE_UPSALE_REQUESTED:
      return setStatus("saveUpsale", RequestStatus.REQUESTED)

    case ActionTypes.SAVE_UPSALE_SUCCEEDED:
      return setStatus("saveUpsale", RequestStatus.SUCCEEDED).set(
        "records",
        state.records.set(
          action.payload.upsale.id.toString(),
          action.payload.upsale
        )
      )

    case ActionTypes.SAVE_UPSALE_FAILED:
      return setError("saveUpsale", action.payload.message)

    // delete
    case ActionTypes.DELETE_UPSALE_REQUESTED:
      return setStatus("deleteUpsale", RequestStatus.REQUESTED)

    case ActionTypes.DELETE_UPSALE_SUCCEEDED:
      return setStatus("deleteUpsale", RequestStatus.SUCCEEDED).set(
        "records",
        state.records.remove(action.payload.id.toString())
      )

    case ActionTypes.DELETE_UPSALE_FAILED:
      return setError("deleteUpsale", action.payload.message)

    default:
      return state
  }
}
