import React, { ChangeEvent } from "react"
import { EditComponentProps } from "@material-table/core"
import { ReservationDataGrid } from "../../types/globalTypes"
import { blankOrderData } from "../../utils/typeUtils"
import Input from "../../components/Input/Input"
import Can from "../../components/Can/Can"

export default function ReservationPriceEditCell({
  rowData: { req = blankOrderData },
  onChange,
}: EditComponentProps<ReservationDataGrid>) {
  const handleChange = ({
    currentTarget: { valueAsNumber: price },
  }: ChangeEvent<HTMLInputElement>) => {
    if (price >= 0) onChange({ ...req, price })
  }

  return (
    <Can resource={"orderPrice"} operations={["W"]}>
      <Input
        onChange={handleChange}
        name={`reservationPrice`}
        value={req.price}
        type={"number"}
        min={0}
        dense
      />
    </Can>
  )
}
