import React, { ChangeEvent, useRef, useState, useEffect } from "react"
import { FormattedMessage } from "react-intl"
import { Box, Typography } from "@material-ui/core"
import { useNavigate } from "react-router-dom"
import Input from "../../components/Input/Input"
import Section from "../../components/Section/Section"
import { RequestStatus } from "../../types/statusTypes"
import Print from "../../components/Print/Print"
import { delay } from "../../utils/apiUtils"
import VoucherSummary from "../../components/VoucherSummary/VoucherSummary"
import LoadingButton from "../../components/LoadingButton/LoadingButton"
import config from "../../config"
import { Voucher } from "../../types/globalTypes"
import { Field } from "../../components/Messages/FieldMessage"
import { useSessionHook } from "../../hooks/sessionHooks"
import { useFetchVoucher, useRedeemVoucher } from "../../hooks/voucherHooks"
import Receipt from "../../components/Receipt/Receipt"
import VoucherStatus from "../../components/VoucherStatus/VoucherStatus"
import VoucherDetailModal from "./VoucherDetailModal"
import { useIdParam } from "../../hooks/routerHooks"
import { useGetLoggedUser } from "../../hooks/interfaceHooks"

const actionMessage = (
  <FormattedMessage
    id={`validateVoucher`}
    defaultMessage={`Validate voucher`}
  />
)

export default function VoucherDetail() {
  const navigate = useNavigate()
  const id = useIdParam()
  const { fullName = "" } = useGetLoggedUser() || {}
  const [voucher, setVoucher] = useState<Voucher | null>()
  const { openSession, closeSession, session, status } = useSessionHook()
  const [redeemVoucher, redeemVoucherStatus] = useRedeemVoucher()
  const [fetchVoucher, fetchVoucherStatus] = useFetchVoucher()
  const [printDate, setPrintDate] = useState<Date>(new Date())
  const input = useRef<HTMLInputElement>(null)
  const [voucherCode, setVoucherCode] = useState("")
  const depositUnpaid =
    voucher && "packageOrder" in voucher ? !voucher.isPaidDeposit : false

  const handleSubmit = async (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault()
    const code = e.currentTarget.code.value
    if (code.length > 0) {
      const response = await fetchVoucher(code)
      setVoucher(response)
      if (response) {
        setVoucherCode("")
        input?.current?.focus() // focus
      }
    } else {
      input?.current?.focus()
    }
  }

  const handleEndSession = async () => {
    await closeSession()
    navigate(config.routes.voucherList.path())
  }

  const handleRedeemVoucher = async () => {
    try {
      if (session && voucher) {
        await redeemVoucher(session, voucher.voucherId)
        setPrintDate(new Date())
        await delay(1000)
        window.print()
        setVoucher(null)
      }
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    if (id) {
      setVoucherCode(id)
    }
  }, [id])

  return (
    <>
      <VoucherDetailModal
        isOpen={!session && status === RequestStatus.SUCCEEDED}
        name={fullName}
        status={status}
        onConfirm={openSession}
      />
      <Section margin={4} heading={actionMessage}>
        <form onSubmit={handleSubmit}>
          <Input
            name={"code"}
            messageId={Field.VOUCHER_CODE}
            inputRef={input}
            value={voucherCode}
            onChange={(e) => setVoucherCode(e.target.value)}
            autoFocus
          />
          <LoadingButton
            isLoading={fetchVoucherStatus === RequestStatus.REQUESTED}
            color={"primary"}
            variant={"contained"}
            type={"submit"}
          >
            {actionMessage}
          </LoadingButton>
        </form>
      </Section>
      <Section
        heading={
          <FormattedMessage
            id={"voucherStatus"}
            defaultMessage={"Voucher status"}
          />
        }
        margin={4}
      >
        <VoucherStatus
          status={voucher?.status}
          isLoading={
            fetchVoucherStatus === RequestStatus.REQUESTED ||
            redeemVoucherStatus === RequestStatus.REQUESTED
          }
          isDepositUnpaid={depositUnpaid}
          isInvalid={fetchVoucherStatus === RequestStatus.FAILED}
          isRedeemed={redeemVoucherStatus === RequestStatus.FAILED}
        />
      </Section>
      {voucher && (
        <Section heading={null} margin={4}>
          <VoucherSummary voucher={voucher} />
          <Box mt={4}>
            <LoadingButton
              isLoading={redeemVoucherStatus === RequestStatus.REQUESTED}
              onClick={handleRedeemVoucher}
              color={"primary"}
              variant={"contained"}
              disabled={depositUnpaid}
            >
              <FormattedMessage
                id={`markVoucherAsUsed`}
                defaultMessage={`Mark voucher as used`}
              />
            </LoadingButton>
          </Box>
          <Box mt={2}>
            <Typography variant={"caption"} component={`p`}>
              <FormattedMessage
                id={`voucherConfirmation`}
                defaultMessage={`By clicking this button you are confirming that surcharge has been payed by customer. Bon will be printed immediately.`}
              />
            </Typography>
          </Box>
          <Print>
            <Receipt voucher={voucher} printDate={printDate} />
          </Print>
        </Section>
      )}
      {session && (
        <LoadingButton
          isLoading={status === RequestStatus.REQUESTED}
          onClick={handleEndSession}
          color={"primary"}
          variant={"outlined"}
        >
          <FormattedMessage id={`endSession`} defaultMessage={`End session`} />
        </LoadingButton>
      )}
    </>
  )
}
