import React, { ChangeEvent, useState } from "react"
import { EditComponentProps } from "@material-table/core"
import { Button } from "@material-ui/core"
import { FormattedMessage } from "react-intl"
import {
  ExtendedOrderReqAdmin,
  LiteEvent,
  OrderDataGrid,
} from "../../types/globalTypes"
import {
  blankOrderData,
  orderStatusesInputOptions,
} from "../../utils/typeUtils"
import ComboBox from "../../components/ComboBox/ComboBox"
import { OrderStatus } from "../../types/apiTypes"
import { useModalState } from "../../hooks/stateHooks"
import Modal from "../../components/Modal/Modal"
import { useCancellationFeeFetch } from "../../hooks/orderHooks"
import Form from "../../components/Form/Form"
import Input from "../../components/Input/Input"
import { Field } from "../../components/Messages/FieldMessage"
import CellGroup from "../../components/CellGroup/CellGroup"
import { useIsCompetent } from "../../hooks/interfaceHooks"

export function OrderStatusEditCell({
  rowData: { req = blankOrderData, id },
  onChange,
}: EditComponentProps<OrderDataGrid>) {
  const [isOpen, openModal, closeModal] = useModalState()
  const canEditFee = useIsCompetent("orderAdmin", "W")
  const [fetchFee, fetchStatus] = useCancellationFeeFetch()
  const [fee, setFee] = useState<number>(
    req?.cancellation?.cancellationFee || 0
  )

  const changeRow = (data: ExtendedOrderReqAdmin) => {
    onChange(data)
  }

  const handleChangeStatus = async ({
    currentTarget: { value },
  }: LiteEvent) => {
    const status = value as OrderStatus
    if (status === OrderStatus.CANCELLED) {
      openModal()
      if (id) {
        const amount = await fetchFee(id, Date.now())
        setFee(amount)
      }
    } else {
      changeRow({ ...req, status })
    }
  }

  const handleChangeFee = async ({
    currentTarget: { valueAsNumber },
  }: ChangeEvent<HTMLInputElement>) => {
    setFee(valueAsNumber)
  }

  const handleSubmitCancellation = () => {
    changeRow({
      ...req,
      status: OrderStatus.CANCELLED,
      cancellation: { cancellationFee: fee, cancelledAt: Date.now() },
    })
    closeModal()
  }

  return (
    <CellGroup>
      <ComboBox
        name={`orderStatus`}
        options={orderStatusesInputOptions}
        onChange={handleChangeStatus}
        value={req.status}
      />
      {req?.status === OrderStatus.CANCELLED && canEditFee && (
        <Button onClick={openModal} variant={"outlined"} color={"secondary"}>
          <FormattedMessage id={"changeFee"} defaultMessage={"Change fee"} />
        </Button>
      )}
      <Modal open={isOpen} onClose={closeModal}>
        <Form
          onSubmit={handleSubmitCancellation}
          useGenericCta
          usePaper={false}
          title={"Confirm cancellation fee"}
          statuses={[fetchStatus]}
        >
          <Input
            messageId={Field.CANCELLATION_FEE}
            disabled={!canEditFee}
            name={"fee"}
            value={fee}
            type={"number"}
            min={0}
            onChange={handleChangeFee}
          />
        </Form>
      </Modal>
    </CellGroup>
  )
}
