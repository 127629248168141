import React from "react"
import { LiteEvent } from "../../types/globalTypes"
import { Field } from "../Messages/FieldMessage"
import InlineFieldset from "../InlineFieldset/InlineFieldset"
import { decodeInputName, encodeInputName } from "../../utils/stringUtils"
import DateTimeInput from "../DateTimeInput/DateTimeInput"

interface IDateInputProps {
  value: [number, number]
  name: string
  onChange: (name: string, val: [number, number]) => void
}

const FIELD_FROM = "from"
const FIELD_TO = "to"

export default function DateTimeRange({
  value,
  onChange,
  name,
}: IDateInputProps) {
  const [from, to] = value

  const handleChange = ({
    currentTarget: { name: targetName, valueAsNumber: date },
  }: LiteEvent<number>) => {
    const [, field] = decodeInputName(targetName)
    return onChange(
      name,
      field === FIELD_FROM
        ? date < to
          ? [date, to]
          : [date, date]
        : [from, date]
    )
  }

  return (
    <InlineFieldset>
      <DateTimeInput
        value={from}
        name={encodeInputName(name, FIELD_FROM)}
        messageId={Field.FROM}
        onChange={handleChange}
        maxDate={to}
      />
      <DateTimeInput
        value={to}
        name={encodeInputName(name, FIELD_TO)}
        messageId={Field.TO}
        onChange={handleChange}
        minDate={from}
      />
    </InlineFieldset>
  )
}
