import { call, put, select } from "redux-saga/effects"
import {
  requestDeleteActivity,
  requestGetActivities,
  requestGetResources,
  requestSaveActivity,
} from "../api/activityRequests"
import {
  deleteActivity,
  deleteActivityFailed,
  deleteActivitySucceeded,
  fetchActivities,
  fetchActivitiesFailed,
  fetchActivitiesSucceeded,
  fetchResources,
  fetchResourcesFailed,
  fetchResourcesSucceeded,
  resetActivityStatus,
  saveActivity,
  saveActivityFailed,
  saveActivitySucceeded,
} from "../actions/activityActions"
import { selectToken } from "../selectors/interfaceSelector"
import { IdRes, PackageResAdmin, ResourceResAdmin } from "../types/apiTypes"
import { arrayToMap } from "../utils/arrayUtils"
import { delayMoment, delayWhile } from "../utils/apiUtils"

export function* fetchActivitiesSaga({
  payload: { ids },
}: ReturnType<typeof fetchActivities>) {
  try {
    const auth: string = yield select(selectToken)
    const data: PackageResAdmin[] = yield call(requestGetActivities, auth, ids)
    yield put(fetchActivitiesSucceeded(arrayToMap(data)))
    yield delayMoment()
    // eslint-disable-next-line
  } catch (e: any) {
    yield put(fetchActivitiesFailed(e.message))
    yield delayWhile()
  } finally {
    yield put(resetActivityStatus("fetchActivities"))
  }
}

export function* saveActivitySaga({
  payload: { formData, id },
}: ReturnType<typeof saveActivity>) {
  try {
    const auth: string = yield select(selectToken)
    const res: IdRes<number> = yield call(
      requestSaveActivity,
      auth,
      formData,
      id
    )
    const [activity]: PackageResAdmin[] = yield call(
      requestGetActivities,
      auth,
      [res.id]
    )
    yield put(saveActivitySucceeded(activity))
    yield delayMoment()
    // eslint-disable-next-line
  } catch (e: any) {
    yield put(saveActivityFailed(e.message))
    yield delayWhile()
  } finally {
    yield put(resetActivityStatus("saveActivity"))
  }
}

export function* deleteActivitySaga({
  payload: { id },
}: ReturnType<typeof deleteActivity>) {
  try {
    const auth: string = yield select(selectToken)
    yield call(requestDeleteActivity, auth, id)
    yield put(deleteActivitySucceeded(id))
    yield delayMoment()
    // eslint-disable-next-line
  } catch (e: any) {
    yield put(deleteActivityFailed(e.message))
    yield delayWhile()
  } finally {
    yield put(resetActivityStatus("deleteActivity"))
  }
}

export function* fetchResourcesSaga({
  payload: { ids },
}: ReturnType<typeof fetchResources>) {
  try {
    const auth: string = yield select(selectToken)
    const data: ResourceResAdmin[] = yield call(requestGetResources, auth, ids)
    yield put(fetchResourcesSucceeded(arrayToMap(data)))
    yield delayMoment()
    // eslint-disable-next-line
  } catch (e: any) {
    yield put(fetchResourcesFailed(e.message))
    yield delayWhile()
  } finally {
    yield put(resetActivityStatus("fetchResources"))
  }
}
