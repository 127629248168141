import React from "react"
import { FormattedMessage } from "react-intl"

export enum Entity {
  CAREER = "career",
  SHOW = "show",
  UPSALE = "upsale",
  ACTIVITY = "activity",
  PACKAGE = "package",
  PAGE = "page",
  ORDER = "order",
  TIME = "time",
  POLICY = "policy",
  GROUP = "group",
  ROLE = "role",
  BON = "bon",
  RESPONSIBILITY = "responsibility",
  EMPLOYEE = "employee",
  PRODUCT = "product",
  TAG = "tag",
  AGENCY = "agency",
  GALLERY = "gallery",
  VOUCHER = "voucher",
  PRICE = "price",
  USER = "user",
  SESSION = "session",
  AVAILABILITY = "availability",
  BLOCKING = "blocking",
  RESERVATION = "reservation",
  NEWS = "NEWS",
}

interface IEntityMessageProps {
  id: Entity
}

export default function EntityMessage({ id }: IEntityMessageProps) {
  switch (id) {
    case Entity.AVAILABILITY:
      return (
        <FormattedMessage id={`availability`} defaultMessage={`Availability`} />
      )
    case Entity.ACTIVITY:
      return <FormattedMessage id={`activity`} defaultMessage={`Activity`} />
    case Entity.ORDER:
      return <FormattedMessage id={`order`} defaultMessage={`Order`} />
    case Entity.UPSALE:
      return <FormattedMessage id={`upsale`} defaultMessage={`Upsale`} />
    case Entity.PACKAGE:
      return <FormattedMessage id={`package`} defaultMessage={`Package`} />
    case Entity.PAGE:
      return <FormattedMessage id={`page`} defaultMessage={`Page`} />
    case Entity.TIME:
      return <FormattedMessage id={`time`} defaultMessage={`Time`} />
    case Entity.POLICY:
      return <FormattedMessage id={`policy`} defaultMessage={`Policy`} />
    case Entity.GROUP:
      return <FormattedMessage id={`group`} defaultMessage={`Group`} />
    case Entity.ROLE:
      return <FormattedMessage id={`role`} defaultMessage={`Role`} />
    case Entity.BON:
      return <FormattedMessage id={`bon`} defaultMessage={`Bon`} />
    case Entity.RESPONSIBILITY:
      return (
        <FormattedMessage
          id={`responsibility`}
          defaultMessage={`Responsibility`}
        />
      )
    case Entity.EMPLOYEE:
      return <FormattedMessage id={`employee`} defaultMessage={`Employee`} />
    case Entity.PRODUCT:
      return <FormattedMessage id={"product"} defaultMessage={"Product"} />
    case Entity.TAG:
      return <FormattedMessage id={"tag"} defaultMessage={"Tag"} />
    case Entity.RESERVATION:
      return (
        <FormattedMessage id={"reservation"} defaultMessage={"Reservation"} />
      )
    case Entity.AGENCY:
      return <FormattedMessage id={"agency"} defaultMessage={"Agency"} />
    case Entity.GALLERY:
      return <FormattedMessage id={"gallery"} defaultMessage={"Gallery"} />
    case Entity.VOUCHER:
      return <FormattedMessage id={"voucher"} defaultMessage={"Voucher"} />
    case Entity.PRICE:
      return <FormattedMessage id={"price"} defaultMessage={"Price"} />
    case Entity.USER:
      return <FormattedMessage id={"user"} defaultMessage={"User"} />
    case Entity.SESSION:
      return <FormattedMessage id={"session"} defaultMessage={"Session"} />
    case Entity.CAREER:
      return <FormattedMessage id={"career"} defaultMessage={"Career"} />
    case Entity.BLOCKING:
      return <FormattedMessage id={"blocking"} defaultMessage={"Blocking"} />
    case Entity.NEWS:
      return <FormattedMessage id={"news"} defaultMessage={"News"} />
    case Entity.SHOW:
      return <FormattedMessage id={"show"} defaultMessage={"Show"} />
    default:
      return <span>Missing translation for `Entity.{id}`</span>
  }
}
