import React from "react"
import { PackageResAdmin } from "../../types/apiTypes"
import ActivityTypeMessage from "../../components/Messages/ActivityTypeMessage"
import CellGroup from "../../components/CellGroup/CellGroup"

export default function ActivityListTypeCell({ type }: PackageResAdmin) {
  return (
    <CellGroup>
      {type
        .map((id) => (
          <ActivityTypeMessage key={id} id={id} />
        ))}
    </CellGroup>
  )
}
