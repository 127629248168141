import React from "react"
import { Tooltip } from "@material-ui/core"
import { FormattedMessage } from "react-intl"
import { format } from "date-fns"
import { jsPDF as JSPDF } from "jspdf"
import autoTable, { CellInput, RowInput } from "jspdf-autotable"
import PictureAsPdf from "@material-ui/icons/PictureAsPdf"
import IconButton from "@material-ui/core/IconButton"
import { ExtendedColumn } from "./DataGrid"
import { blobToBase64 } from "../../utils/apiUtils"

interface IDataGridPdfButtonProps<T extends object> {
  data: T[]
  columns: ExtendedColumn<T>[]
}

export default function DataGridPdfButton<T extends object>({
  columns,
  data,
}: IDataGridPdfButtonProps<T>) {
  const exportCols = columns.filter(
    (col) => col.exportField || col.customExport
  )

  const handleClick = async () => {
    const doc = new JSPDF({ orientation: "landscape" })
    const response = await fetch("/PublicSans-Regular.ttf")
    const blob = await response.blob()
    const base64 = await blobToBase64(blob)
    const [, font] = base64.split(",") // get the raw data without extra info
    doc.addFileToVFS("PublicSans-Regular.ttf", font)
    doc.addFont("PublicSans-Regular.ttf", "PublicSans", "normal")
    doc.setFont("PublicSans", "normal")

    const exportData: RowInput[] = Array.isArray(data)
      ? data.map((row) =>
          exportCols.map(
            (col): CellInput =>
              (col.customExport
                ? col.customExport(row)
                : col.exportField
                ? row[col.exportField]
                : "") as string
          )
        )
      : []

    autoTable(doc, {
      head: [
        exportCols.map(({ title }) => (typeof title === "string" ? title : "")),
      ],
      body: exportData,
      styles: { font: "PublicSans" },
    })

    doc.save(`export_${format(Date.now(), "ddMMyyHHmm")}.pdf`)
  }

  return exportCols.length > 0 ? (
    <Tooltip
      title={
        <FormattedMessage id={"exportAsPdf"} defaultMessage={"Export as PDF"} />
      }
    >
      <IconButton onClick={handleClick}>
        <PictureAsPdf />
      </IconButton>
    </Tooltip>
  ) : null
}
