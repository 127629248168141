import React, { ChangeEvent } from "react"
import { EditComponentProps } from "@material-table/core"
import { ExtendedOrderReqAdmin, OrderDataGrid } from "../../types/globalTypes"
import CellGroup from "../../components/CellGroup/CellGroup"
import { FormField } from "../../types/apiTypes"
import Input from "../../components/Input/Input"
import { blankOrderData, addressFormFields } from "../../utils/typeUtils"
import { useRequiredFirstFormFields } from "../../hooks/activityHooks"

export function OrderAddressEditCell({
  rowData: { req = blankOrderData },
  onChange,
}: EditComponentProps<OrderDataGrid>) {
  const { packageOrder } = req
  const [sortedFields, isRequired] = useRequiredFirstFormFields(
    addressFormFields,
    packageOrder?.optionId
  )

  const handleChangeRow = (data: ExtendedOrderReqAdmin): void => {
    onChange(data)
  }

  const handleChange = ({
    currentTarget: { name, value },
  }: ChangeEvent<HTMLInputElement>) => {
    const key = name as FormField
    if (req.packageOrder) {
      handleChangeRow({
        ...req,
        packageOrder: {
          ...req.packageOrder,
          contactInfo: {
            ...req.packageOrder.contactInfo,
            [key]: value
          }
        }
      })
    }
  }

  if (!packageOrder) {
    return <></>
  }

  return (
    <CellGroup>
      {sortedFields.map((field) => (
        <Input
          key={field}
          type="text"
          name={field}
          messageId={field}
          value={packageOrder.contactInfo[field]}
          onChange={handleChange}
          dense
          required={isRequired(field)}
        />
      ))}
    </CellGroup>
  )
}
