import { makeDefaultNewsState, NewsActions, NewsState } from "models/newsModel"
import { ActionTypes, AnyAction } from "types/actionTypes"
import { RequestStatus as Status, RequestStatus } from "../types/statusTypes"

const initialSate: NewsState = makeDefaultNewsState()

export default function newsReducer(
  state = initialSate,
  action: AnyAction | null = null
): NewsState {
  // helpers
  const setStatus = (key: NewsActions, status: Status) =>
    state.set("statuses", state.statuses.set(key, status))
  const setError = (key: NewsActions, error: string) =>
    state
      .set("errors", state.errors.set(key, error))
      .set("statuses", state.statuses.set(key, RequestStatus.FAILED))

  switch (action?.type) {
    // logout
    case ActionTypes.LOGOUT_USER_SUCCEEDED:
      return initialSate

    // reset status to default and error message to empty string
    case ActionTypes.RESET_NEWS_STATUS:
      return setStatus(action.payload.key, RequestStatus.INITIAL).set(
        "errors",
        state.errors.set(action.payload.key, "")
      )

    // fetch
    case ActionTypes.FETCH_NEWS_REQUESTED:
      return setStatus("fetchNews", RequestStatus.REQUESTED)

    case ActionTypes.FETCH_NEWS_SUCCEEDED:
      return setStatus("fetchNews", RequestStatus.SUCCEEDED).set(
        "records",
        action.payload.news
      )

    case ActionTypes.FETCH_NEWS_FAILED:
      return setError("fetchNews", action.payload.message)

    // save
    case ActionTypes.SAVE_NEWS_REQUESTED:
      return setStatus("saveNews", RequestStatus.REQUESTED)

    case ActionTypes.SAVE_NEWS_SUCCEEDED:
      return setStatus("saveNews", RequestStatus.SUCCEEDED).set(
        "records",
        state.records.set(
          action.payload.news.id.toString(),
          action.payload.news
        )
      )

    case ActionTypes.SAVE_NEWS_FAILED:
      return setError("saveNews", action.payload.message)

    // delete
    case ActionTypes.DELETE_NEWS_REQUESTED:
      return setStatus("deleteNews", RequestStatus.REQUESTED)

    case ActionTypes.DELETE_NEWS_SUCCEEDED:
      return setStatus("deleteNews", RequestStatus.SUCCEEDED).set(
        "records",
        state.records.remove(action.payload.id.toString())
      )

    case ActionTypes.DELETE_NEWS_FAILED:
      return setError("deleteNews", action.payload.message)

    default:
      return state
  }
}
