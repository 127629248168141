import React, { ReactNode, useCallback } from "react"
import { useSelector } from "react-redux"
import DataGrid from "../../components/DataGrid/DataGrid"
import OrderFilter from "../../components/OrderFilter/OrderFilter"
import { selectEmployeeDataGrid } from "../../selectors/orderSelector"
import { EmployeeDataGrid } from "../../types/globalTypes"
import { isNotNull, orderStatusesLookupTable } from "../../utils/typeUtils"
import RouteMessage from "../../components/Messages/RouteMessage"
import {
  Currency,
  OrderEmployeeStatus,
  OrderStatus,
} from "../../types/apiTypes"
import {
  useIsCompetent,
  useLocalStoragePagination,
} from "../../hooks/interfaceHooks"
import { Field } from "../../components/Messages/FieldMessage"
import EmployeeCell from "../../components/Cells/EmployeeCell"
import { selectUserLookupTable } from "../../selectors/userSelector"
import CellGroup from "../../components/CellGroup/CellGroup"
import { selectOptionLookupTable } from "../../selectors/activitySelector"
import { formatDateTime } from "../../utils/timeUtils"
import { equals, either } from "ramda"


export const canEmployeeAccept = either(
  equals(OrderEmployeeStatus.DECLINED),
  equals(OrderEmployeeStatus.PENDING)
)

export const canEmployeeDecline = equals(OrderEmployeeStatus.PENDING)

export const isOrderOpened = either(
  equals(OrderStatus.CREATED),
  equals(OrderStatus.APPROVED)
)

interface EmployeeListProps {
  toolbar: ReactNode
  styleRowFn: (id: number, color: string) => object
}

export default function EmployeeList({
  toolbar,
  styleRowFn,
}: EmployeeListProps) {
  const employeeList = useSelector(selectEmployeeDataGrid)
  const userLookup = useSelector(selectUserLookupTable)
  const optionLookup = useSelector(selectOptionLookupTable)
  const canEditEmployee = useIsCompetent("orderEmployeeReward", "W")
  const paginationProps = useLocalStoragePagination("employee")

  const styleRow = useCallback(
    (item: EmployeeDataGrid): object => styleRowFn(item.orderId, item.color),
    [styleRowFn]
  )

  return (
    <DataGrid<EmployeeDataGrid>
      {...paginationProps}
      title={<RouteMessage id={"ordersList"} />}
      searchable={false}
      data={employeeList}
      customFilter={<OrderFilter />}
      toolbarIcons={toolbar}
      columns={[
        {
          title: Field.EMPLOYEE,
          field: "employeeUserId",
          render: ({
            employeeStatus,
            employeeName,
            employeeRole,
          }: EmployeeDataGrid) => (
            <EmployeeCell
              name={employeeName}
              status={employeeStatus}
              role={employeeRole}
            />
          ),
          lookup: userLookup,
        },
        {
          title: Field.ACTIVITY,
          field: "optionId",
          render: ({ packageName, optionName }: EmployeeDataGrid) => (
            <CellGroup>
              {packageName}
              {optionName}
            </CellGroup>
          ),
          lookup: optionLookup,
        },
        {
          title: Field.DATE,
          field: "dtStart",
          width: 150,
          defaultSort: "desc" as "desc",
          type: "datetime" as "datetime",
          render: ({ dtStart }: EmployeeDataGrid) => formatDateTime(dtStart),
          customExport: ({ dtStart }: EmployeeDataGrid) =>
            formatDateTime(dtStart, true),
        },
        {
          title: Field.NUMBER_OF_PERSONS,
          field: "numberOfPersons",
          type: "numeric" as "numeric",
          align: "left" as "left",
        },
        canEditEmployee
          ? {
              title: Field.REWARD,
              field: "employeeReward",
              type: "currency" as "currency",
              align: "left" as "left",
              currencySetting: {
                locale: "cs-CS",
                currencyCode: Currency.CZK,
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              },
            }
          : null,
        {
          title: Field.NOTE,
          field: "employeeNote",
          render: ({ employeeNote }: EmployeeDataGrid) =>
            employeeNote || "Edit",
        },
        {
          title: Field.STATUS,
          field: "orderStatus",
          lookup: orderStatusesLookupTable,
        },
      ].filter(isNotNull)}
      styleRow={styleRow}
    />
  )
}
