import React from "react"
import MaterialCheckbox, { CheckboxProps } from "@material-ui/core/Checkbox"
import FormControl from "@material-ui/core/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import { InputError } from "../../types/globalTypes"
import FieldMessage, { Field } from "../Messages/FieldMessage"
import ErrorMessage from "../Messages/ErrorMessage"

interface ICheckboxProps {
  messageId: Field
  name: string
  inputError?: InputError
}

export default function Checkbox({
  messageId,
  inputError,
  name,
  size,
  ...props
}: ICheckboxProps & CheckboxProps) {
  return (
    <FormControl error={!!inputError}>
      <FormControlLabel
        control={<MaterialCheckbox name={name} size={size} {...props} />}
        label={<FieldMessage id={messageId} />}
      />
      {inputError && (
        <FormHelperText>
          {inputError.message ?? <ErrorMessage id={inputError.type} />}
        </FormHelperText>
      )}
    </FormControl>
  )
}
