import React, { useState } from "react"
import { Navigate } from "react-router-dom"
import DataGrid from "../../components/DataGrid/DataGrid"
import config from "../../config"
import WebPageMessage, {
  WebPage,
} from "../../components/Messages/WebPageMessage"
import RouteMessage from "../../components/Messages/RouteMessage"
import { Field } from "../../components/Messages/FieldMessage"
import { useLocalStoragePagination } from "../../hooks/interfaceHooks"

type Page = {
  id: WebPage
}

const pages: Page[] = [
  { id: WebPage.HOMEPAGE },
  { id: WebPage.OFFER },
  { id: WebPage.PARTY },
  { id: WebPage.GALLERY },
  { id: WebPage.SUPPORT },
  { id: WebPage.CAREER },
  { id: WebPage.CONTACT },
  { id: WebPage.SHOW },
]

export default function PageList() {
  const [redirectId, setRedirectId] = useState<null | string>(null)
  const paginationProps = useLocalStoragePagination("product")

  if (redirectId !== null)
    return <Navigate to={config.routes.pageDetail.path(redirectId)} />

  return (
    <DataGrid<Page>
      {...paginationProps}
      title={<RouteMessage id={"pageList"} />}
      onRowClick={(_, row) => setRedirectId(row ? row.id : null)}
      data={pages}
      columns={[
        {
          title: Field.TITLE,
          field: "id",
          render: ({ id }: Page) => <WebPageMessage id={id} />,
        },
      ]}
    />
  )
}
