import React from "react"
import { FormattedMessage } from "react-intl"
import { SessionStatus } from "../../types/globalTypes"

interface ISessionStatusMessageProps {
  id: SessionStatus
}

export default function SessionStatusMessage({
  id,
}: ISessionStatusMessageProps) {
  switch (id) {
    case SessionStatus.ACTIVE:
      return <FormattedMessage id={`active`} defaultMessage={`Active`} />
    case SessionStatus.ENDED:
      return <FormattedMessage id={`ended`} defaultMessage={`Ended`} />
    default:
      return null
  }
}
