import { useEffect } from "react"
import { deleteShow, fetchShows, saveShow } from "actions/showActions"
import { ShowMap } from "models/showModel"
import { useDispatch, useSelector } from "react-redux"
import {
  selectShowDeleteError,
  selectShowDeleteStatus,
  selectShowFetchError,
  selectShowFetchStatus,
  selectShowRecords,
  selectShowSaveError,
  selectShowSaveStatus,
} from "selectors/showSelector"
import { RequestStatus } from "types/statusTypes"
import { ShowReq } from "../types/apiTypes"

export function useShowSave(): [
  (data: ShowReq, id?: string) => void,
  RequestStatus,
  string
] {
  const dispatch = useDispatch()
  const status = useSelector(selectShowSaveStatus)
  const error = useSelector(selectShowSaveError)
  return [
    (data: ShowReq, id?: string) =>
      dispatch(saveShow(data, id ? parseFloat(id) : undefined)),
    status,
    error,
  ]
}

export function useShowDelete(): [(id: number) => void, RequestStatus, string] {
  const dispatch = useDispatch()
  const status = useSelector(selectShowDeleteStatus)
  const error = useSelector(selectShowDeleteError)
  return [(id: number) => dispatch(deleteShow(id)), status, error]
}

export function useShowFetch(id?: string): [ShowMap, RequestStatus, string] {
  const dispatch = useDispatch()
  const shows = useSelector(selectShowRecords)
  const status = useSelector(selectShowFetchStatus)
  const error = useSelector(selectShowFetchError)
  const singleShow = id ? shows.get(id) : null
  useEffect(() => {
    if (!singleShow) dispatch(fetchShows(id ? [parseFloat(id)] : undefined))
  }, [dispatch, id, singleShow])
  return [shows, status, error]
}
