import React, { useState } from "react"
import { EditComponentProps } from "@material-table/core"
import OrderDateModal from "./OrderDateModal"
import Input from "../../components/Input/Input"
import { formatDateTime } from "../../utils/timeUtils"
import DateTimeInput from "../../components/DateTimeInput/DateTimeInput"
import CellGroup from "../../components/CellGroup/CellGroup"
import { Field } from "../../components/Messages/FieldMessage"
import { blankOrderData, timeFormFields } from "../../utils/typeUtils"
import {
  ExtendedOrderReqAdmin,
  LiteEvent,
  OrderDataGrid,
} from "../../types/globalTypes"
import { useRequiredFirstFormFields } from "../../hooks/activityHooks"

export function OrderDateEditCell({
  rowData: { req = blankOrderData },
  onChange,
}: EditComponentProps<OrderDataGrid>) {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [sortedFields, isRequired] = useRequiredFirstFormFields(
    timeFormFields,
    req?.packageOrder?.optionId
  )

  const handleChange = (data: ExtendedOrderReqAdmin): void => {
    onChange(data)
  }

  const handleContactChange = ({
    currentTarget: { name, value },
  }: LiteEvent<number>) => {
    onChange({
      ...req,
      packageOrder: {
        ...req.packageOrder,
        contactInfo: {
          ...req.packageOrder?.contactInfo,
          [name]: value.toString(),
        },
      },
    })
  }

  if (!req.packageOrder?.optionId) return <></>

  return (
    <CellGroup>
      <Input
        messageId={Field.DATE}
        name={`dtStart`}
        onClick={() => setIsModalOpen(true)}
        value={formatDateTime(req.packageOrder.dtStart)}
        dense
      />
      {sortedFields.map(
        (field) =>
          req.packageOrder && (
            <DateTimeInput
              key={field}
              name={field}
              messageId={field}
              value={
                req.packageOrder.contactInfo[field]
                  ? parseInt(req.packageOrder.contactInfo[field], 10)
                  : null
              }
              onChange={handleContactChange}
              required={isRequired(field)}
              dense
            />
          )
      )}
      {isModalOpen && (
        <OrderDateModal
          onChange={handleChange}
          onClose={() => setIsModalOpen(false)}
          req={req}
        />
      )}
    </CellGroup>
  )
}
