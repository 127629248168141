import { FormattedMessage } from "react-intl"
import React from "react"
import { Field } from "../../components/Messages/FieldMessage"
import {
  OrderActivityCell,
  OrderAddressCell,
  OrderCostsCell,
  OrderDateCell,
  OrderEmployeeCell,
  OrderExtrasCell,
  OrderGroupCell,
  OrderMethodCell,
  OrderNoteCell,
  OrderSourceCell,
  OrderStatusCell,
  OrderUpsalesCell,
} from "./OrderListCells"
import { OrderPersonsEditCell } from "./OrderPersonsEditCell"
import { OrderGroupEditCell } from "./OrderGroupEditCell"
import { OrderNoteEditCell } from "./OrderNoteEditCell"
import { OrderMethodEditCell } from "./OrderMethodEditCell"
import { OrderStatusEditCell } from "./OrderStatusEditCell"
import { OrderSourceEditCell } from "./OrderSourceEditCell"
import { OrderDataGrid } from "../../types/globalTypes"
import { OrderEmployeeEditCell } from "./OrderEmployeeEditCell"
import { OrderDateEditCell } from "./OrderDateEditCell"
import { OrderAddressEditCell } from "./OrderAddressEditCell"
import { OrderActivityEditCell } from "./OrderActivityEditCell"
import { OrderExtrasEditCell } from "./OrderExtrasEditCell"
import { OrderCostsEditCell } from "./OrderCostsEditCell"
import { ExtendedColumn } from "../../components/DataGrid/DataGrid"
import { formatDateTime } from "../../utils/timeUtils"
import OrderUpsalesEditCell from "./OrderUpsalesEditCell"
import { OrderReqDataSyncCell } from "./OrderReqDataSyncCell"
import { addressFormFields, groupFormFields } from "../../utils/typeUtils"

const join = (...items: Array<string|null|undefined>): string => items.filter(i => i).join(' | ')

const columns: { [key: string]: ExtendedColumn<OrderDataGrid> } = {
  reqDataSync: {
    field: "req",
    render: () => null,
    editComponent: OrderReqDataSyncCell,
  },
  numberOfPersons: {
    title: Field.NUMBER_OF_PERSONS,
    field: "req",
    render: (props) => props.packageOrder?.numberOfPersons,
    editComponent: OrderPersonsEditCell,
    sorting: false,
    customExport: (data: OrderDataGrid) => data.packageOrder?.numberOfPersons.toString() || "",
    exportSort: 5,
    cellStyle: { minWidth: 90 },
    headerStyle: { minWidth: 90 },
    searchable: false,
  },
  group: {
    title: Field.GROUP,
    field: "req",
    render: OrderGroupCell,
    editComponent: OrderGroupEditCell,
    width: 150,
    sorting: false,
    customExport: (data: OrderDataGrid) => join(
      data.name,
      data.email,
      data.phone,
      ...groupFormFields.map(field => data.packageOrder?.contactInfo[field])
    ),
    exportSort: 6,
    customFilterAndSearch: () => true,
    searchable: true,
  },
  note: {
    title: Field.NOTE,
    field: "req",
    render: (props: OrderDataGrid) => <OrderNoteCell {...props} />,
    editComponent: OrderNoteEditCell,
    width: 120,
    sorting: false,
    searchable: false,
  },
  noteExtra: {
    title: Field.NOTE,
    field: "allEmployees[0].id",
    render: ({ allEmployees }: OrderDataGrid) =>
      allEmployees[0].id || (
        <FormattedMessage id={"edit"} defaultMessage={"Edit"} />
      ),
    width: 120,
    sorting: false,
    searchable: false,
  },
  method: {
    title: Field.PAYMENT_METHOD,
    field: "req",
    render: OrderMethodCell,
    editComponent: OrderMethodEditCell,
    sorting: false,
    width: 130,
    searchable: false,
  },
  status: {
    title: Field.STATUS,
    field: "req",
    render: OrderStatusCell,
    editComponent: OrderStatusEditCell,
    sorting: false,
    width: 120,
    searchable: false,
    exportHeader: "",
    customExport: () => "", // last field is empty for the editor
    exportSort: 7,
  },
  source: {
    title: Field.SOURCE,
    field: "req",
    render: (props: OrderDataGrid) => <OrderSourceCell {...props} />,
    editComponent: OrderSourceEditCell,
    sorting: false,
    customExport: ({ agencyName }: OrderDataGrid) => agencyName || "WEB",
    exportSort: 4,
    width: 140,
    searchable: false,
  },
  employees: {
    title: Field.EMPLOYEES,
    field: "req",
    render: (props: OrderDataGrid) => <OrderEmployeeCell {...props} />,
    editComponent: OrderEmployeeEditCell,
    sorting: false,
    width: 250,
    searchable: false,
  },
  employeesReduced: {
    title: Field.EMPLOYEES,
    field: "req",
    editable: "never",
    render: (props: OrderDataGrid) => <OrderEmployeeCell {...props} />,
    sorting: false,
    searchable: false,
  },
  date: {
    title: Field.DATE,
    field: "req",
    render: OrderDateCell,
    editComponent: OrderDateEditCell,
    sorting: false,
    customExport: ({ packageOrder }: OrderDataGrid) =>
      formatDateTime(packageOrder?.dtStart, true),
    exportSort: 1,
    searchable: false,
  },
  address: {
    title: Field.ADDRESS,
    field: "req",
    render: OrderAddressCell,
    editComponent: OrderAddressEditCell,
    width: 150,
    sorting: false,
    searchable: false,
    customExport: (data: OrderDataGrid) => join(
      ...addressFormFields.map(field => data.packageOrder?.contactInfo[field])
    ),
    exportSort: 3,
  },
  activity: {
    title: Field.ACTIVITY,
    field: "req",
    cellStyle: { minWidth: 200 },
    headerStyle: { minWidth: 200 },
    render: (props: OrderDataGrid) => <OrderActivityCell {...props} />,
    editComponent: OrderActivityEditCell,
    sorting: false,
    customExport: (data: OrderDataGrid) => join(
      data.packageOrder?.pack.translations.EN.title,
      data.packageOrder?.option.translations.EN.title
    ),
    exportSort: 2,
    searchable: false,
  },
  extras: {
    title: Field.EXTRAS,
    field: "req",
    render: (props: OrderDataGrid) => <OrderExtrasCell {...props} />,
    editComponent: OrderExtrasEditCell,
    sorting: false,
    width: 230,
    searchable: false,
  },
  upsales: {
    title: Field.UPSALES,
    field: "req",
    render: (props: OrderDataGrid) => <OrderUpsalesCell {...props} />,
    editComponent: OrderUpsalesEditCell,
    sorting: false,
    width: 280,
    searchable: false,
  },
  costs: {
    title: Field.PRICE,
    field: "req",
    render: OrderCostsCell,
    editComponent: OrderCostsEditCell,
    width: 130,
    sorting: false,
    searchable: false,
  },
}

export default columns
