import React from "react"
import BonCategoryMessage from "../Messages/BonCategoryMessage"
import { ComputedBon } from "../../types/apiTypes"

interface IBonSummaryProps {
  summary: ComputedBon[]
  element?: "p" | "li" | "span" | "div"
  separator?: string
}

function getBonKey(category: string, content: string, index: number): string {
  return `${category}-${content}-${index}`
}

export default function BonSummary({
  summary,
  element: Element = "li",
  separator,
}: IBonSummaryProps) {
  return (
    <>
      {summary
        // .sort((a, b) => (a.category > b.category ? -1 : 1))
        .map(({ count, category, content }, index) => (
          <Element key={getBonKey(category, content, index)}>
            {count}&nbsp;×&nbsp;
            <BonCategoryMessage id={category} />
            {content && ` (${content})`}
            {index < summary.length - 1 && separator}
          </Element>
        ))}
    </>
  )
}
