import React from "react"
import { FormControlLabel, Switch as MuiSwitch } from "@material-ui/core"
import { SwitchProps } from "@material-ui/core/Switch"
import FieldMessage, { Field } from "../Messages/FieldMessage"

interface ISwitchProps {
  messageId: Field
}

export default function Switch({
  messageId,
  ...props
}: ISwitchProps & SwitchProps) {
  return (
    <FormControlLabel
      control={<MuiSwitch {...props} value="checkedB" color="primary" />}
      label={<FieldMessage id={messageId} />}
    />
  )
}
