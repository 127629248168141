import { makeStyles, Theme } from "@material-ui/core"

export default makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    paddingLeft: theme.spacing(16),
    paddingRight: theme.spacing(16),
    maxWidth: 1140,
    position: "sticky",
    left: theme.spacing(32)
  },
  child: {},
}))
