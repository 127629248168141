import React from "react"
import { compose } from "redux"
import {
  ComputedBon,
  Currency,
  FormField,
  Language,
  OrderEmployeeResAdmin,
  OrderEmployeeStatus,
  OrderReqAdmin,
  OrderResAdmin,
  OrderStatus,
  ReservationResAdmin,
  Role,
  SessionResAdmin,
  TranslationReq,
  UserRes,
} from "./apiTypes"
import { ErrorType } from "../components/Messages/ErrorMessage"
import { defaultFilters } from "../models/ordersModel"

declare global {
  interface Window {
    // eslint-disable-next-line no-undef
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
  }
}

export type StorageFilters = {
  [keys in keyof typeof defaultFilters]: string[]
}

export interface StorageItems {
  token: string
  locale: Locale
  zoom: string
  orderFilters: StorageFilters
  sortType: SortType
  pagination: Record<string, { page: number; size: number }>
}

export interface IRouterParams {
  id: string
}

export interface InputOption<
  V extends string = string,
  C extends React.ReactNode = React.ReactNode
> {
  value: V
  content: C
  disabled?: boolean
  promoted?: boolean
  demoted?: boolean
}

export enum HttpMethods {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
}

export type Locale = "en" | "cs"

export const locales: Locale[] = ["en", "cs"]

export interface LiteEvent<T = string> {
  currentTarget: {
    name: string
    value: T
    valueAsNumber: number
    type: string
    checked: boolean
  }
}

export type TranslationMap = { [key in Language]: TranslationReq }

export type TranslationSimpleMap = { [key in Language]: string }

export type EventType = {
  id: string
  dtStart: number
  dtEnd: number
}

export type MediaItem = {
  id: string
  source: string | File
}

export interface InputError {
  type: ErrorType
  message?: string
}

export interface IFormData {
  [key: string]: string
}

export interface ITranslations {
  [key: string]: TranslationSimpleMap
}

export interface ITagEntities {
  [key: string]: string[]
}

export interface StringMap {
  [key: string]: string
}

export type Price = {
  [key in Currency]: number
}

export type UpsaleBrief = [string, number, Record<FormField, string>]

export type Voucher = OrderResAdmin
export type VoucherDataGrid = OrderResAdmin & { agency: string }

export interface ExtendedOrderEmployee {
  assignmentId: number | null
  reward: number
  userId: number | null
  note: string
  role: Role
  key: string
}

export type ExtendedOrderReqAdmin = OrderReqAdmin & {
  packageId: number
  bonSum: ComputedBon[]
}

export type ReportDataGrid = OrderResAdmin & {
  agencyName?: string
  employeeReward: number
}

export type OrderDataGrid = OrderResAdmin & {
  req?: ExtendedOrderReqAdmin // must be optional due to new record feature
  loggedEmployee: OrderEmployeeResAdmin | null // is currently logged user assigned
  allEmployees: OrderEmployeeResAdmin[]
  agencyName?: string
}

export type SessionDataGrid = SessionResAdmin & {
  name: string
  surchargeReceived: number
  bonSum: ComputedBon[]
  status: SessionStatus
}

export type ReservationDataGrid = Omit<OrderResAdmin, "reservation"> & {
  reservation: ReservationResAdmin // we avoid reservation to be null possibility
  req?: ExtendedOrderReqAdmin // must be optional due to new record feature
}

export type EmployeeDataGrid = {
  orderId: number
  numberOfPersons: number
  dtStart: number
  dtEnd: number
  orderStatus: OrderStatus
  employeeId: number
  employeeStatus: OrderEmployeeStatus
  employeeReward: number
  employeeUserId: number | null
  employeeName: string
  employeeNote: string
  employeeSpecification: string | null
  employeeRole: Role
  employeeUpsaleId: number | null
  employeePackageOptionId: number | null
  packageId: number | null
  packageName: string | null
  optionId: number | null
  optionName: string | null
  name: string
  email: string
  phone: string
  color: string
}

export type ReportEmployeeDataGrid = UserRes & {
  reward: number
  activities: OrderResAdmin[]
}

export enum SessionStatus {
  ACTIVE = "ACTIVE",
  ENDED = "ENDED",
}

export enum BonGroup {
  TOKEN = "TOKEN",
  DRINKS = "DRINKS",
  OTHER = "OTHER",
  TIPPING_DOLLAR = "TIPPING_DOLLAR",
  BEER_CARD = "BEER_CARD",
  T_SHIRT = "T_SHIRT",
}

export interface OptionPrices {
  priceUnit?: string
  pricePerPerson?: string
  deposit?: string
  fee?: string
}

export type SimpleInputOption = {
  content: string
  value: string
  disabled?: boolean
  promoted?: boolean
}
export type SortType = "asc" | "desc"
