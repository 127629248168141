import {
  makeFilters,
  makeOrderState,
  OrderActions,
  OrderState,
} from "../models/ordersModel"
import { ActionTypes, AnyAction } from "../types/actionTypes"
import { RequestStatus, RequestStatus as Status } from "../types/statusTypes"
import { Entity } from "../components/Messages/EntityMessage"
import { OrderedMap, Set } from "immutable"
import { Field } from "../components/Messages/FieldMessage"
import { getFilterDatesByPeriod } from "../utils/timeUtils"
import { Period } from "../types/filterTypes"
import { OrderEmployeeResAdmin } from "../types/apiTypes"
import { map } from "ramda"

const defaultOrderState: OrderState = makeOrderState()

export default function orderReducer(
  state = defaultOrderState,
  action: AnyAction | null = null
): OrderState {
  const setStatus = (key: OrderActions, status: Status) =>
    state.set("statuses", state.statuses.set(key, status))
  const setError = (key: OrderActions, error: string) =>
    state
      .set("errors", state.errors.set(key, error))
      .set("statuses", state.statuses.set(key, RequestStatus.FAILED))

  switch (action?.type) {
    // logout
    case ActionTypes.LOGOUT_USER_SUCCEEDED:
      return defaultOrderState

    // orders
    case ActionTypes.FETCH_ORDERS_REQUESTED:
      return setStatus("fetchOrders", Status.REQUESTED)

    case ActionTypes.FETCH_ORDERS_SUCCEEDED: {
      return setStatus("fetchOrders", Status.SUCCEEDED).set(
        "records",
        action.payload.strategy === "merge"
          ? OrderedMap([
              ...state.records.entrySeq().toArray(),
              ...action.payload.orders.entrySeq().toArray(),
            ])
          : action.payload.orders
      )
    }

    case ActionTypes.FETCH_ORDERS_FAILED:
      return setError("fetchOrders", action.payload.message)

    case ActionTypes.ADD_ORDER:
      return state.set(
        "records",
        state.records.set(
          action.payload.order.id.toString(),
          action.payload.order
        )
      )

    // sections
    case ActionTypes.FETCH_SECTIONS_REQUESTED:
      return setStatus("fetchSections", Status.REQUESTED)

    case ActionTypes.FETCH_SECTIONS_SUCCEEDED:
      return setStatus("fetchSections", Status.SUCCEEDED).set(
        "sections",
        action.payload.sections
      )

    case ActionTypes.FETCH_SECTIONS_FAILED:
      return setError("fetchSections", action.payload.message)

    // conflicts
    case ActionTypes.FETCH_CONFLICTS_REQUESTED:
      return setStatus("fetchConflicts", Status.REQUESTED)

    case ActionTypes.FETCH_CONFLICTS_SUCCEEDED:
      return setStatus("fetchConflicts", Status.SUCCEEDED)
        .set("conflictingResources", action.payload.conflicts.resource)
        .set("conflictingEmployees", action.payload.conflicts.employee)

    case ActionTypes.FETCH_CONFLICTS_FAILED:
      return setError("fetchConflicts", action.payload.message)

    // seating
    case ActionTypes.FETCH_SEATING_REQUESTED:
      return setStatus("fetchSeating", Status.REQUESTED)

    case ActionTypes.FETCH_SEATING_SUCCEEDED:
      return setStatus("fetchSeating", Status.SUCCEEDED).set(
        "seating",
        action.payload.seating
      )

    case ActionTypes.FETCH_SEATING_FAILED:
      return setError("fetchSeating", action.payload.message)

    // employee status
    case ActionTypes.SAVE_EMPLOYEE_STATUS_REQUESTED: {
      const orderId = action.payload.orderId.toString()
      const order = state.records.get(orderId)
      const employeeModifier = (employee: OrderEmployeeResAdmin) =>
        employee.id === action.payload.id
          ? { ...employee, status: action.payload.status }
          : employee
      return setStatus("saveEmployeeStatus", Status.REQUESTED).set(
        "records",
        order
          ? state.records.set(orderId, {
              ...order,
              orderEmployees: order.orderEmployees.map(employeeModifier),
              packageOrder: order.packageOrder
                ? {
                    ...order.packageOrder,
                    orderEmployees:
                      order.packageOrder.orderEmployees.map(employeeModifier),
                  }
                : null,
              upsales: order.upsales.map((upsale) => ({
                ...upsale,
                orderEmployees: upsale.orderEmployees.map(employeeModifier),
              })),
            })
          : state.records
      )
    }

    case ActionTypes.SAVE_EMPLOYEE_STATUS_SUCCEEDED:
      return setStatus("saveEmployeeStatus", Status.SUCCEEDED)

    case ActionTypes.SAVE_EMPLOYEE_STATUS_FAILED:
      return setError("saveEmployeeStatus", action.payload.message)

    case ActionTypes.SEND_VOUCHER_EMAIL_REQUESTED:
      return setStatus("sendVoucherEmail", Status.REQUESTED)

    case ActionTypes.SEND_VOUCHER_EMAIL_SUCCEEDED:
      return setStatus("sendVoucherEmail", Status.SUCCEEDED)

    case ActionTypes.SEND_VOUCHER_EMAIL_FAILED:
      return setError("sendVoucherEmail", action.payload.message)

    case ActionTypes.RESET_ORDER_STATUS: {
      return setStatus("sendVoucherEmail", Status.INITIAL)
        .set("searchQuery", "")
        .set("pageNumber", 0)
    }

    case ActionTypes.SET_ORDER_PAGE_SIZE: {
      return setStatus("fetchOrders", Status.REQUESTED)
        .set("pageSize", action.payload)
        .set("pageNumber", 0)
    }

    case ActionTypes.SET_ORDER_SEARCH_QUERY: {
      return setStatus("fetchOrders", Status.REQUESTED)
        .set("searchQuery", action.payload)
        .set("pageNumber", 0)
    }

    case ActionTypes.SET_ORDER_SORT_TYPE: {
      return setStatus("fetchOrders", Status.REQUESTED)
        .set("sortType", action.payload)
        .set("pageNumber", 0)
    }

    case ActionTypes.SET_ORDER_PAGE_NUMBER: {
      return setStatus("fetchOrders", Status.REQUESTED).set(
        "pageNumber",
        action.payload
      )
    }

    case ActionTypes.RESTORE_INTERFACE_SUCCEEDED: {
      return state
        .set(
          "filters",
          action.payload.orderFilters
            ? makeFilters(
                map((values) => Set(values), action.payload.orderFilters)
              )()
            : defaultOrderState.filters
        )
        .set("sortType", action.payload.sortType || defaultOrderState.sortType)
    }

    case ActionTypes.SET_ORDER_FILTER: {
      const { key, value } = action.payload
      const newState = setStatus("fetchOrders", Status.REQUESTED)
      switch (key) {
        case Entity.ORDER:
          return newState
            .set(
              "filters",
              defaultOrderState.filters
                .set(Entity.ORDER, Set<string>(value))
                .set(Field.DATE, Set<string>(['1970-01-01', '2038-01-01']))
                .set(Field.ORDER_STATUS, Set<string>())
            )
            .set("pageNumber", 0)
        case Field.ACTIVITY:
        case Field.EMPLOYEE:
        case Field.CONTAINS_RESERVATION:
        case Field.AGENCY:
        case Field.SOURCE:
        case Field.PAYMENT_METHOD:
        case Field.DATE:
        case Field.ORDER_STATUS:
          return newState
            .set(
              "filters",
              state.filters
                .set(key, Set<string>(value))
                .set(Entity.ORDER, Set<string>())
            )
            .set("pageNumber", 0)
        case Field.PERIOD:
          return newState
            .set(
              "filters",
              state.filters
                .set(Field.DATE, getFilterDatesByPeriod(value[0] as Period))
                .set(Entity.ORDER, Set<string>())
            )
            .set("pageNumber", 0)
        default:
          return newState
      }
    }

    default: {
      return state
    }
  }
}
