import makeStyles from "@material-ui/core/styles/makeStyles"

export default makeStyles(() => ({
  root: {
    fontFamily: "'Raleway', sans-serif",
    maxWidth: 320,
  },
  packageName: {
    fontSize: 18,
    fontWeight: "bold",
    textTransform: "uppercase",
    lineHeight: 1,
    textAlign: "center",
    margin: 0,
    padding: 0
  },
  baseInfo: {
    fontSize: 14,
    lineHeight: 1,
    marginTop: 16,
    marginBottom: 16
  },
  bons: {
    fontSize: 14,
    marginTop: 8,
    marginBottom: 8,
    paddingBottom: 8,
    paddingTop: 8,
    marginLeft: 0,
    paddingLeft: 0,
    borderTop: "1px solid black",
    borderBottom: "1px solid black",
    listStyle: "none",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  bonCategory: {
    fontWeight: "normal",
    textTransform: "lowercase",
    "&:not(:first-child)": {
      marginTop: 4,
    },
  },
  date: {
    fontSize: 10,
    margin: 0,
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: 20,
    paddingRight: 20,
  },
  code: {
    fontSize: 10,
    textAlign: "center",
  },
  reservations: {
    fontSize: 14,
    margin: 0
  },
  information: {
    fontSize: 10,
  },
}))
