import React from "react"
import { FormattedMessage } from "react-intl"

interface ILocaleMessageProps {
  locale: string
}

export default function LocaleMessage({ locale }: ILocaleMessageProps) {
  if (locale === "en")
    return <FormattedMessage id={`english`} defaultMessage={`English`} />
  if (locale === "cs")
    return <FormattedMessage id={`czech`} defaultMessage={`Czech`} />
  return <>Language</>
}
