import { Crop } from "react-image-crop"
import React, { useEffect, useState } from "react"
import Form from "../../components/Form/Form"
import GalleryUpload from "../../components/GalleryUpload/GalleryUpload"
import Input from "../../components/Input/Input"
import LanguageTab from "../../components/LanguageTab/LanguageTab"
import EntityList from "../../components/EntityList/EntityList"
import Results from "../../components/Results/Results"
import config from "../../config"
import {
  isMediaItemVideo,
  isNumeric,
  languageList,
  simpleToFullTranslation,
} from "../../utils/typeUtils"
import {
  GalleryResAdmin,
  MediaType,
  MediumResAdmin,
} from "../../types/apiTypes"
import WebPageMessage, {
  WebPage,
} from "../../components/Messages/WebPageMessage"
import { Field } from "../../components/Messages/FieldMessage"
import { Entity } from "../../components/Messages/EntityMessage"
import { useTagState } from "../../hooks/tagHooks"
import {
  useGalleryDelete,
  useGalleryFetch,
  useGallerySave,
  useGalleryState,
} from "../../hooks/galleryHooks"
import { usePageSharedState } from "../../hooks/stateHooks"
import { arrayDiff } from "../../utils/arrayUtils"
import { renameKeys } from "../../utils/objectUtils"
import ImageCrop from "../../components/ImageCrop/ImageCrop"
import { MediaItem } from "../../types/globalTypes"
import Modal from "../../components/Modal/Modal"

export default function PageGallery() {
  const state = usePageSharedState(config.pageIds.gallery)
  const galleryState = useGalleryState()
  const tagState = useTagState()
  const [saveGallery, saveGalleryStatus, saveGalleryError] = useGallerySave([
    config.tagIds.gallery.gallery,
  ])
  const [fetchGallery, fetchGalleryStatus, fetchGalleryError] = useGalleryFetch(
    config.tagIds.gallery.gallery
  )
  const [
    deleteGallery,
    deleteGalleryStatus,
    deleteGalleryError,
  ] = useGalleryDelete()
  const [remoteGalleries, setRemoteGalleries] = useState<number[]>([])
  const [croppedImage, setCroppedMedium] = useState<MediaItem | null>(null)

  useEffect(() => {
    state.fetchPage()
    fetchGallery([], (rawGalleries) => {
      const galleries = rawGalleries.filter(
        (gal) => gal !== undefined
      ) as GalleryResAdmin[]
      galleryState.fillData(
        galleries.reduce((acc, gallery) => {
          const { id, media } = gallery as GalleryResAdmin
          acc[id] = media
          return acc
        }, {} as { [namespace: string]: MediumResAdmin[] })
      )
      tagState.fillWithData(
        galleries.map((gallery) => gallery.id.toString()),
        galleries.map((gallery) => gallery.translations),
        []
      )
      setRemoteGalleries(galleries.map((i) => i.id))
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = async () => {
    await state.savePage()
    const galleryIds = await Promise.all(
      tagState.tags.map((tag, idx) =>
        saveGallery(
          isNumeric(tag) ? parseInt(tag, 10) : null,
          galleryState.getGallery(tag),
          galleryState
            .getGallery(tag)
            .map((medium) =>
              simpleToFullTranslation(
                [galleryState.labels[medium.id]],
                ["title"]
              )
            ),
          galleryState
            .getGallery(tag)
            .map((medium) =>
              isMediaItemVideo(medium) ? MediaType.VIDEO : MediaType.IMAGE
            ),
          undefined,
          simpleToFullTranslation([tagState.titles[tag]], ["title"]),
          idx + 1,
          galleryState.crop
        )
      )
    ).then((res): number[] => res.filter((i) => i !== null) as number[])
    const renameMap = tagState.handleTagSave(galleryIds)
    galleryState.setGallery(renameKeys(galleryState.gallery, renameMap))
    await Promise.all(
      arrayDiff(remoteGalleries, galleryIds).map((id) => deleteGallery(id))
    )
    setRemoteGalleries(galleryIds)
  }

  const handleTagDelete = async () => {
    tagState.handleTagDelete()
  }

  const handleInitCropFile = (mediumId: string) => {
    const search = galleryState
      .getGallery(tagState.activeTag)
      .find((medium) => medium.id === mediumId)
    if (search) setCroppedMedium(search)
  }

  return (
    <>
      <Modal
        open={!!croppedImage}
        onClose={() => setCroppedMedium(null)}
        maxWidth={false}
      >
        {croppedImage && (
          <ImageCrop
            image={croppedImage.source}
            defaultCrop={galleryState.getMediumCrop(croppedImage.id)}
            onComplete={({ x = 0, y = 0, width = 0, height = 0 }: Crop) => {
              galleryState.setMediumCrop(croppedImage.id, {
                x,
                y,
                width,
                height,
              })
            }}
          />
        )}
      </Modal>
      <Results
        statuses={[fetchGalleryStatus, saveGalleryStatus, deleteGalleryStatus]}
        errors={[fetchGalleryError, saveGalleryError, deleteGalleryError]}
        actions={["fetch", "save", "delete"]}
        entity={Entity.GALLERY}
      />
      <Results
        statuses={[state.fetchStatus, state.saveStatus]}
        errors={[state.fetchError, state.saveError]}
        actions={["fetch", "save"]}
        entity={Entity.PAGE}
      />
      <Form
        title={<WebPageMessage id={WebPage.GALLERY} />}
        onSubmit={handleSubmit}
        toolbar={
          <LanguageTab
            language={state.language}
            onChange={state.setLanguage}
            notifications={languageList.filter((lang) => {
              const { content, title, metaData } = state.translations[lang]
              return !content || !title || !metaData
            })}
          />
        }
        statuses={[
          state.fetchStatus,
          state.saveStatus,
          fetchGalleryStatus,
          saveGalleryStatus,
          deleteGalleryStatus,
        ]}
        useGenericCta
      >
        <Input
          name={`title`}
          messageId={Field.TITLE}
          onChange={state.handleTitle}
          value={state.title}
          showRequiredWarning={state.isSubmitted}
        />
        <Input
          name={`meta`}
          messageId={Field.META}
          onChange={state.handleMeta}
          value={state.meta}
          showRequiredWarning={state.isSubmitted}
        />
        <Input
          name={`keywords`}
          messageId={Field.KEYWORDS}
          onChange={state.handleKeywords}
          value={state.keywords}
          showRequiredWarning={state.isSubmitted}
        />
        <EntityList
          entityPairs={tagState.tags.map((tag) => [
            tag,
            tagState.titles?.[tag]?.[state.language] || ``,
          ])}
          onEntityChange={tagState.handleActiveTagChange}
          onCreate={tagState.handleTagCreate}
          onDelete={handleTagDelete}
          onSort={tagState.handleTagSort}
          activeEntity={tagState.activeTag}
        >
          <Input
            name={`tagTitle`}
            messageId={Field.TITLE}
            onChange={(e) =>
              tagState.handleTagTitleChange(
                e.currentTarget.value,
                state.language
              )
            }
            value={tagState.titles?.[tagState.activeTag]?.[state.language]}
          />
          <GalleryUpload
            name={`gallery`}
            language={state.language}
            gallery={galleryState.getGallery(tagState.activeTag)}
            labels={galleryState.labels}
            onUpload={(files) =>
              galleryState.handleUpload(files, tagState.activeTag)
            }
            onSort={(prev, next) =>
              galleryState.handleSort(prev, next, tagState.activeTag)
            }
            onDelete={(id) => galleryState.handleDelete(id, tagState.activeTag)}
            onLabelChange={galleryState.handleLabelChange}
            onCrop={handleInitCropFile}
            allowURL
            required
          />
        </EntityList>
      </Form>
    </>
  )
}
