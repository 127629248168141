import { ActionTypes } from "../types/actionTypes"
import { ProductActions, ProductMap } from "../models/productModel"
import { ProductReq, ProductResAdmin } from "../types/apiTypes"

export const fetchProduct = (ids?: number[]) =>
  ({
    type: ActionTypes.FETCH_PRODUCT_REQUESTED,
    payload: {
      ids,
    },
  } as const)

export const fetchProductSuccess = (products: ProductMap) =>
  ({
    type: ActionTypes.FETCH_PRODUCT_SUCCEEDED,
    payload: {
      products,
    },
  } as const)

export const fetchProductFailed = (message: string) =>
  ({
    type: ActionTypes.FETCH_PRODUCT_FAILED,
    payload: {
      message,
    },
  } as const)

export const saveProduct = (data: ProductReq, id?: number) =>
  ({
    type: ActionTypes.SAVE_PRODUCT_REQUESTED,
    payload: {
      data,
      id,
    },
  } as const)

export const saveProductSucceeded = (product: ProductResAdmin) =>
  ({
    type: ActionTypes.SAVE_PRODUCT_SUCCEEDED,
    payload: {
      product,
    },
  } as const)

export const saveProductFailed = (message: string) =>
  ({
    type: ActionTypes.SAVE_PRODUCT_FAILED,
    payload: {
      message,
    },
  } as const)

export const deleteProduct = (id: number) =>
  ({
    type: ActionTypes.DELETE_PRODUCT_REQUESTED,
    payload: {
      id,
    },
  } as const)

export const deleteProductSucceeded = (id: number) =>
  ({
    type: ActionTypes.DELETE_PRODUCT_SUCCEEDED,
    payload: {
      id,
    },
  } as const)

export const deleteProductFailed = (message: string) =>
  ({
    type: ActionTypes.DELETE_PRODUCT_FAILED,
    payload: {
      message,
    },
  } as const)

export const resetProductStatus = (key: ProductActions) =>
  ({
    type: ActionTypes.RESET_PRODUCT_STATUS,
    payload: {
      key,
    },
  } as const)
