import { makeStyles, Theme } from "@material-ui/core"

export default makeStyles((theme: Theme) => ({
  root: {
    display: "inline-flex",
    flexWrap: "nowrap",
    alignItems: "center",
  },
  text: {
    marginLeft: theme.spacing(1),
  },
}))
