import { Outlet, useLocation, useNavigate } from "react-router-dom"
import React, { useEffect, useState } from "react"
import clsx from "clsx"
import Drawer from "@material-ui/core/Drawer"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import { Hidden } from "@material-ui/core"
import Container from "@material-ui/core/Container"
import MenuIcon from "@material-ui/icons/Menu"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import ExitToAppIcon from "@material-ui/icons/ExitToApp"
import { useDispatch, useSelector } from "react-redux"
import {
  logoutUser,
  setBacklink,
  setZoom,
} from "../../actions/interfaceActions"
import Copyright from "../Copyright/Copyright"
import useStyles from "./AuthLayoutStyles"
import LocaleSelect from "../LocaleSelect/LocaleSelect"
import NavLinks from "../NavLinks/NavLinks"
import ColorSchemeSelect from "../ColorSchemeSelect/ColorSchemeSelect"
import { RouterProps } from "../Router/Router"
import Logo from "../Logo/Logo"
import Zoom from "../Zoom/Zoom"
import {
  selectRestorationFetchStatus,
  selectZoom,
} from "../../selectors/interfaceSelector"
import { RequestStatus } from "../../types/statusTypes"
import config from "../../config"
import { useGetLoggedUser } from "../../hooks/interfaceHooks"

export default function AuthLayout({ isDark, toggleColorScheme }: RouterProps) {
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState<boolean>(true)
  const toggleDrawer = () => setIsOpen(!isOpen)
  const zoom = useSelector(selectZoom)
  const classes = useStyles({ zoom, isOpen })
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const user = useGetLoggedUser()
  const interfaceFetchStatus = useSelector(selectRestorationFetchStatus)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  useEffect(() => {
    if (!user && interfaceFetchStatus === RequestStatus.SUCCEEDED) {
      dispatch(setBacklink(pathname))
      navigate(config.routes.signIn.path())
    }
  }, [dispatch, pathname, navigate, user, interfaceFetchStatus])

  return (
    <div className={classes.root} data-testid={"auth-layout"}>
      <AppBar className={classes.appBar} position={"fixed"}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="Toggle navigation"
            onClick={toggleDrawer}
            className={clsx(classes.menuButton)}
          >
            {isOpen ? <ChevronLeftIcon /> : <MenuIcon />}
          </IconButton>
          <div className={classes.title}>
            <Logo />
          </div>
          <Zoom
            value={zoom}
            onChange={(value: number) => dispatch(setZoom(value))}
          />
          <LocaleSelect />
          <Hidden smDown>
            <ColorSchemeSelect
              isDark={isDark}
              toggleColorScheme={toggleColorScheme}
            />
          </Hidden>
          <IconButton
            aria-label={`button-to-logout`}
            onClick={() => dispatch(logoutUser())}
            color="inherit"
          >
            <ExitToAppIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        aria-label={"navigation"}
        aria-hidden={isOpen}
        classes={{
          paper: clsx(classes.drawerPaper, !isOpen && classes.drawerPaperClose),
        }}
        open={isOpen}
      >
        <NavLinks />
      </Drawer>
      <main className={classes.container}>
        <div className={classes.zoomEffect}>
          <Container maxWidth={false}>
            <Outlet />
          </Container>
          <Copyright />
        </div>
      </main>
    </div>
  )
}
