import { createSelector } from "reselect"
import { AppState } from "../store"
import { Language } from "../types/apiTypes"
import { forbiddenAccessRights } from "../utils/typeUtils"

export function selectLoggedUser(state: AppState) {
  return state.ui.user
}

export function selectUserAccessRights(state: AppState) {
  return state.ui.user?.accessRights || forbiddenAccessRights
}

export function selectLoginFetchStatus(state: AppState) {
  return state.ui.statuses.loginUser
}

export function selectLoginFetchError(state: AppState) {
  return state.ui.errors.loginUser
}

export function selectRestorationFetchStatus(state: AppState) {
  return state.ui.statuses.restoreInterface
}

export function selectToken(state: AppState): string {
  return state.ui.token
}

export function selectLocale(state: AppState) {
  return state.ui.locale
}

export function selectZoom(state: AppState) {
  return state.ui.zoom
}

export function selectLanguage(state: AppState): Language {
  if (state.ui.locale === "cs") return Language.CS
  return Language.EN
}

export function selectFilters(state: AppState) {
  return state.ui.filters
}

export function selectBacklink(state: AppState) {
  return state.ui.backlink
}

export const selectFilterMap = createSelector(
  selectFilters,
  (filters) =>
    filters.toSeq().toMap()
)
