import { RequestStatus as Status, RequestStatus } from "../types/statusTypes"
import {
  AgencyActions,
  AgencyState,
  makeAgencyState,
} from "../models/agencyModel"
import { ActionTypes, AnyAction } from "../types/actionTypes"

const initialState: AgencyState = makeAgencyState()

export default function userReducer(
  state = initialState,
  action: AnyAction | null = null
): AgencyState {
  // helpers
  const setStatus = (key: AgencyActions, status: Status) =>
    state.set("statuses", state.statuses.set(key, status))
  const setError = (key: AgencyActions, error: string) =>
    state
      .set("errors", state.errors.set(key, error))
      .set("statuses", state.statuses.set(key, RequestStatus.FAILED))

  switch (action?.type) {
    // logout
    case ActionTypes.LOGOUT_USER_SUCCEEDED:
      return initialState

    // reset status to default and error message to empty string
    case ActionTypes.RESET_AGENCY_STATUS:
      return setStatus(action.payload.key, RequestStatus.INITIAL).set(
        "errors",
        state.errors.set(action.payload.key, "")
      )

    // fetch
    case ActionTypes.FETCH_AGENCIES_REQUESTED:
      return setStatus("fetchAgencies", RequestStatus.REQUESTED)

    case ActionTypes.FETCH_AGENCIES_SUCCEEDED:
      return setStatus("fetchAgencies", RequestStatus.SUCCEEDED).set(
        "records",
        action.payload.agencies
      )

    case ActionTypes.FETCH_AGENCIES_FAILED:
      return setError("fetchAgencies", action.payload.message)

    case ActionTypes.ADD_AGENCY:
      return state.set(
        "records",
        state.records.set(
          action.payload.agency.id.toString(),
          action.payload.agency
        )
      )

    case ActionTypes.REMOVE_AGENCY:
      return state.set(
        "records",
        state.records.remove(action.payload.id.toString())
      )

    default: {
      return state
    }
  }
}
