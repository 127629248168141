import React from "react"
import { Box, FormControl, FormHelperText, IconButton } from "@material-ui/core"
import { Delete } from "@material-ui/icons"
import FileUpload from "../FileUpload/FileUpload"
import useStyles from "./ImageUploadStyles"
import Thumbnail from "../Thumbnail/Thumbnail"
import { Field } from "../Messages/FieldMessage"
import ErrorMessage, { ErrorType } from "../Messages/ErrorMessage"

interface IGalleryUpload {
  source: File | string
  name: string
  onUpload: (file: File | string) => void
  onDelete?: () => void
  allowURL?: boolean
  required?: boolean
  messageId?: Field
  showRequiredWarning?: boolean
}

export default function ImageUpload({
  source,
  onUpload,
  name,
  onDelete,
  allowURL = false,
  showRequiredWarning = false,
  required,
  messageId,
}: IGalleryUpload) {
  const classes = useStyles()

  const handleUpload = (files: Array<File | string>) => {
    if (files[0]) onUpload(files[0])
  }

  return (
    <FormControl fullWidth margin={"normal"} className={classes.root}>
      <FileUpload
        onUpload={handleUpload}
        name={name}
        allowURL={allowURL}
        isMultiple={false}
        required={required}
        messageId={messageId}
        hasError={showRequiredWarning && !source}
      />
      {showRequiredWarning && !source && (
        <FormHelperText error>
          <ErrorMessage id={ErrorType.SHOULD_FILL} />
        </FormHelperText>
      )}
      {source && (
        <Box textAlign={"center"} display={"flex"} alignItems={"center"} mt={1}>
          {onDelete && (
            <IconButton onClick={() => onDelete()}>
              <Delete />
            </IconButton>
          )}
          <Thumbnail
            source={source}
            alt={`Medium preview`}
            size={"medium"}
            link
          />
        </Box>
      )}
    </FormControl>
  )
}
