import React from "react"
import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import useStyles from "./StatusButtonStyles"

export type Status = "success" | "warning" | "danger" | "default"

interface IStatusButtonProps {
  onClick: () => void
  isLoading: boolean
  disabled?: boolean
  children: React.ReactNode
  variant: "outlined" | "contained"
  status: Status
}

export default function StatusButton({
  onClick,
  children,
  isLoading = false,
  disabled = false,
  variant = "outlined",
  status,
}: IStatusButtonProps) {
  const classes = useStyles()
  const classMap: Record<Status, string | undefined> = {
    success: classes.success,
    danger: classes.danger,
    warning: classes.warning,
    default: undefined,
  }
  return (
    <Button
      onClick={onClick}
      className={classMap[status]}
      variant={variant}
      disabled={disabled}
    >
      {children}
      {isLoading && <CircularProgress size={24} className={classes.spinner} />}
    </Button>
  )
}
