import Paper from "@material-ui/core/Paper"
import React, { SyntheticEvent } from "react"
import { InputOption } from "../../types/globalTypes"
import FilterRange from "../DateRange/DateRange"
import FilterList from "../RadioList/RadioList"
import MultiSelectFilter from "../MultiSelect/MultiSelect"
import useStyles from "./FilterStyles"
import { Field } from "../Messages/FieldMessage"

export enum FilterTypes {
  MultiSelect,
  RadioList,
  DateRange,
}

interface IFilterProps {
  fields: Array<{
    name: Field
    values: string[]
    type: FilterTypes
    options: InputOption[]
  }>
  onChange: (key: string, value: string[]) => void
}

export default function Filter({ onChange, fields }: IFilterProps) {
  const classes = useStyles()

  const handleSelectChange = (key: string, inputValues: string[]) => {
    onChange(key, inputValues)
  }

  const handleRadioListChange = ({
    currentTarget: { name: key, value },
  }: SyntheticEvent<HTMLInputElement>) => {
    onChange(key, [value])
  }

  const handleDateChange = (key: string, [from, to]: [number, number]) => {
    onChange(key, [(new Date(from)).toISOString(), (new Date(to)).toISOString()])
  }

  return (
    <Paper className={classes.root}>
      {fields.map(({ values, options, name, type }) => {
        switch (type) {
          case FilterTypes.MultiSelect:
            return (
              <MultiSelectFilter
                key={name}
                onChange={handleSelectChange}
                values={values}
                options={options}
                messageId={name}
                name={name}
              />
            )
          case FilterTypes.RadioList:
            return (
              <FilterList
                key={name}
                messageId={name}
                name={name}
                onClick={handleRadioListChange}
                options={options}
                value={values[0]}
              />
            )
          case FilterTypes.DateRange:
            return (
              <FilterRange
                name={name}
                key={name}
                value={[new Date(values[0]).valueOf(), new Date(values[1]).valueOf()]}
                onChange={handleDateChange}
              />
            )
          default:
            return null
        }
      })}
    </Paper>
  )
}
