import React, { useState, useEffect } from "react"
import { FormattedMessage } from "react-intl"
import { useSelector } from "react-redux"
import { Add } from "@material-ui/icons"
import UpsaleSummary from "components/UpsaleSummary/UpsaleSummary"
import { EditComponentProps } from "@material-table/core"
import { Button } from "@material-ui/core"
import { ExtendedOrderReqAdmin, OrderDataGrid } from "../../types/globalTypes"
import { blankOrderData } from "../../utils/typeUtils"
import OrderUpsellingModal from "./OrderUpsellingModal"
import { selectActivityRecords } from "../../selectors/activitySelector"
import { selectUpsaleRecords } from "../../selectors/upsaleSelector"
import { useLanguage } from "../../hooks/interfaceHooks"
import { useOrderPriceFetch } from "../../hooks/orderHooks"
import { PackageUpsaleIdsWithPriorityRes } from "types/apiTypes"
import { noop } from "ramda-adjunct"

const OrderUpsalesEditCell = ({
  onChange,
  rowData: { req = blankOrderData },
}: EditComponentProps<OrderDataGrid>) => {
  const [fetchPrice] = useOrderPriceFetch()
  const activityMap = useSelector(selectActivityRecords)
  const [isUpsellingModalOpen, setIsUpsellingModalOpen] = useState(false)
  const upsaleMap = useSelector(selectUpsaleRecords)
  const language = useLanguage()
  const [upsales, setUpsales] = useState<PackageUpsaleIdsWithPriorityRes[]>([])

  useEffect(() => {
    if (req.packageOrder) {
      setUpsales(
        activityMap.get(req?.packageId.toString() || "")?.upsalePackageIds || []
      )
    } else {
      // If there is no packageOrder, take all the upsales
      setUpsales(
        [...upsaleMap].map(([_, { id, priority }]) => ({
          upsaleId: id,
          priority,
        }))
      )
    }
  }, [activityMap, req?.packageId, req.packageOrder, upsaleMap])

  const handleChangeRow = (data: ExtendedOrderReqAdmin): void => {
    onChange(data)
  }

  const handleCloseUpsaleModal = () => {
    fetchPrice(req)
      .then(([price, _, { bonSum }]) => {
        handleChangeRow({ ...req, ...price, bonSum })
      })
      .catch(noop)
      .finally(() => setIsUpsellingModalOpen(false))
  }

  const handleOpenUpsaleModal = () => {
    setIsUpsellingModalOpen(true)
  }

  return (
    <>
      {/* Upsales */}
      {upsales.length > 0 && (
        <>
          <Button
            onClick={handleOpenUpsaleModal}
            variant={"outlined"}
            color={"secondary"}
            startIcon={<Add />}
          >
            <FormattedMessage id={`upsales`} defaultMessage={`Upsales`} />
          </Button>
          <UpsaleSummary
            summary={req.upsales.map((upsale) => [
              upsaleMap.get(upsale.packageUpsaleId.toString())?.translations[
                language
              ].title || "",
              upsale.quantity,
              upsale.contactInfo,
            ])}
          />
        </>
      )}

      {/* upselling modal */}
      <OrderUpsellingModal
        onChange={handleChangeRow}
        req={req}
        onClose={handleCloseUpsaleModal}
        isOpen={isUpsellingModalOpen}
        upsales={upsales}
      />
    </>
  )
}

export default OrderUpsalesEditCell
