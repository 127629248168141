import { FormattedMessage, useIntl } from "react-intl"
import React, {
  CSSProperties,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectUpsaleRecords } from "selectors/upsaleSelector"
import MailIcon from "@material-ui/icons/Mail"
import LockIcon from "@material-ui/icons/Lock"
import UnlockIcon from "@material-ui/icons/LockOpen"
import DataGrid from "../../components/DataGrid/DataGrid"
import OrderFilter from "../../components/OrderFilter/OrderFilter"
import {
  selectOrderDataGrid,
  selectOrders,
  selectSendVoucherEmailError,
  selectSendVoucherEmailStatus,
} from "../../selectors/orderSelector"
import { ExtendedOrderReqAdmin, OrderDataGrid } from "../../types/globalTypes"
import {
  blankOrderData,
  getAllOrderReqEmployees,
  getOrderValidationErrors,
  isNotNull,
  orderResToOrderReq,
  ValidationError,
} from "../../utils/typeUtils"
import RouteMessage from "../../components/Messages/RouteMessage"
import columns from "./OrderlistColumns"
import {
  OrderEmployeeResAdmin,
  OrderEmployeeStatus,
  OrderReqAdmin,
} from "../../types/apiTypes"
import {
  fetchConflicts,
  resetOrderStatus,
  saveEmployeeStatus,
  sendVoucherEmail,
  setOrderSearchQuery,
} from "../../actions/orderActions"
import {
  useEmployeeConflictCheck,
  useOrderConflictCheck,
  useOrderPagination,
  useOrderSave,
} from "../../hooks/orderHooks"
import Results from "../../components/Results/Results"
import { Entity } from "../../components/Messages/EntityMessage"
import Toast from "../../components/Toast/Toast"
import OrderInfo from "../../components/OrderInfo/OrderInfo"
import EmployeeInfo from "../../components/EmployeeInfo/EmployeeInfo"
import StatusProgressBar from "../../components/StatusProgressBar/StatusProgressBar"
import OrderConflicts from "../../components/OrderConflicts/OrderConflicts"
import { selectUserRecords } from "../../selectors/userSelector"
import { RequestStatus } from "../../types/statusTypes"
import {
  useGetUsersAgency,
  useIsCompetent,
  useLanguage,
} from "../../hooks/interfaceHooks"
import { selectActivityRecords } from "../../selectors/activitySelector"
import ValidationErrors from "../../components/ValidationErrors/ValidationErrors"
import { isEmpty } from "ramda"
import LoadingButton from "../../components/LoadingButton/LoadingButton"
import { useDebouncedCallback } from "use-debounce"
import CheckIcon from "@material-ui/icons/Check"
import {
  canEmployeeAccept,
  canEmployeeDecline,
  isOrderOpened,
} from "./EmployeeList"
import CloseIcon from "@material-ui/icons/Close"

interface OrderListProps {
  toolbar: ReactNode
  statuses: RequestStatus[]
  styleRowFn: (id: number, color: string, isLocked?: boolean) => CSSProperties
}

export default function OrderList({
  toolbar,
  statuses,
  styleRowFn,
}: OrderListProps) {
  const { formatMessage } = useIntl()
  const ordersList = useSelector(selectOrderDataGrid)
  const canSave = useIsCompetent("order", "W")
  const cannotSaveWithErrors = useIsCompetent("orderAdmin", "N")
  const canChangeRewards = useIsCompetent("orderEmployeeReward", "W")
  const canChangeEmployees = useIsCompetent("orderEmployee", "W")
  const canSaveAnyAgency = useIsCompetent("agency", "W")
  const canSeeFilters = useIsCompetent("uiOrderFilter", "R", "W")
  const language = useLanguage()
  const usersAgency = useGetUsersAgency()
  const userMap = useSelector(selectUserRecords)
  const orderMap = useSelector(selectOrders)
  const activityMap = useSelector(selectActivityRecords)
  const upsaleMap = useSelector(selectUpsaleRecords)
  const sendVoucherEmailStatus = useSelector(selectSendVoucherEmailStatus)
  const sendVoucherEmailError = useSelector(selectSendVoucherEmailError)
  const dispatch = useDispatch()
  const [errors, setErrors] = useState<ValidationError[]>([])
  const [saveOrder, saveStatus, saveError, loadingOrderId] = useOrderSave()
  const [checkOrder, checkStatus, checkError] = useOrderConflictCheck()
  const [conflictingOrders, setConflicts] = useState<number[]>([])
  const [conflictingEmployees, setEmployees] = useState<
    OrderEmployeeResAdmin[]
  >([])
  const [checkEmployees, employeeConflictStatus, employeeConflictError] =
    useEmployeeConflictCheck()
  const paginationProps = useOrderPagination()
  const pendingSave = useRef(false)

  const handleSearchChange = useDebouncedCallback((query: string) => {
    if (query.length >= 4) {
      dispatch(setOrderSearchQuery(query))
    }
    if (query.length === 0) {
      dispatch(setOrderSearchQuery(""))
    }
  }, 750)

  const resetErrors = () => {
    setConflicts([])
    setErrors([])
    setEmployees([])
  }

  const styleRow = useCallback(
    (item: OrderDataGrid): object => ({
      ...styleRowFn(item.id, item.notes.COLOR, item.isLocked),
      ...(loadingOrderId === item.id && {
        pointerEvents: "none",
        animation: "pulse 2s alternate infinite",
      }),
    }),
    [loadingOrderId, styleRowFn]
  )

  const displayOrderErrors = async (
    order: OrderReqAdmin,
    packageId?: number
  ): Promise<boolean> => {
    const conflictingOrderIds = await checkOrder(order)

    const conflictingOrderEmployees: OrderEmployeeResAdmin[] =
      await checkEmployees(
        order.packageOrder?.dtStart || order.reservation?.dtStart || Date.now(),
        getAllOrderReqEmployees(order)
          .map((employee) => employee.userId)
          .filter(isNotNull),
        order.id
      )
    const validationErrors = getOrderValidationErrors(
      order,
      upsaleMap,
      language,
      packageId ? activityMap.get(packageId.toString()) : undefined
    )

    setConflicts(conflictingOrderIds)
    setErrors(validationErrors)
    setEmployees(conflictingOrderEmployees)
    return (
      validationErrors.length > 0 ||
      conflictingOrderIds.length > 0 ||
      conflictingOrderEmployees.length > 0
    )
  }

  const handleRowSave = async (
    {
      packageId,
      agencyId,
      email,
      bonSum,
      ...rest
    }: ExtendedOrderReqAdmin = blankOrderData,
    id?: number
  ): Promise<void> => {
    /* ignore repeated submit when save already in progress */
    if (pendingSave.current) {
      return new Promise((_, reject) => reject())
    }
    pendingSave.current = true
    resetErrors()
    const requestData: OrderReqAdmin = {
      ...rest,
      agencyId: canSaveAnyAgency ? agencyId : usersAgency,
      email: email || null,
    }
    const hasErrors = await displayOrderErrors(requestData, packageId)
    return new Promise<void>((resolve, reject) => {
      /* prevent saving order without phone number */
      if (isEmpty(requestData.phone)) {
        reject()
        return
      }

      if (cannotSaveWithErrors && hasErrors) {
        reject()
      } else {
        saveOrder(requestData, id).then(() => dispatch(fetchConflicts()))
        resolve()
      }
    }).finally(() => {
      pendingSave.current = false
    })
  }

  const handleLockToggle = async ({ req }: OrderDataGrid) => {
    if (req) {
      const { bonSum, packageId, ...orderReq } = req
      pendingSave.current = true
      await saveOrder(
        { ...orderReq, isLocked: !orderReq.isLocked },
        orderReq.id
      )
      pendingSave.current = false
    }
  }

  const handleRowClick = async (
    e?: React.MouseEvent<Element, MouseEvent>,
    row?: OrderDataGrid
  ) => {
    if (
      row &&
      e?.target instanceof HTMLElement &&
      !(e?.target instanceof HTMLButtonElement) &&
      !e?.target.querySelector(":scope > button")
    ) {
      const { req, ...order } = row
      const orderReq = orderResToOrderReq(order)
      await displayOrderErrors(orderReq, order.packageOrder?.option.packageId)
    }
  }

  // eslint-disable-next-line
  useEffect(() => {
    if (
      sendVoucherEmailStatus === RequestStatus.SUCCEEDED ||
      sendVoucherEmailStatus === RequestStatus.FAILED
    ) {
      const timeOut = setTimeout(() => dispatch(resetOrderStatus()), 3000)
      return () => clearTimeout(timeOut)
    }
  }, [sendVoucherEmailStatus, dispatch])

  return (
    <>
      <Results
        statuses={[
          saveStatus,
          checkStatus,
          employeeConflictStatus,
          sendVoucherEmailStatus,
        ]}
        errors={[
          saveError,
          checkError,
          employeeConflictError,
          sendVoucherEmailError,
        ]}
        actions={["save", "save", "save", "save", "sendEmail"]}
        entity={Entity.ORDER}
      />
      <Toast
        open={conflictingOrders.length > 0}
        title={
          <FormattedMessage
            id={"orderHasConflicts"}
            defaultMessage={"Order has conflicting resources"}
          />
        }
        message={conflictingOrders.map((orderId) => (
          <OrderInfo key={orderId} order={orderMap.get(orderId.toString())} />
        ))}
        severity={"error"}
      />
      <Toast
        open={conflictingEmployees.length > 0}
        title={
          <FormattedMessage
            id={"orderEmployeeConflict"}
            defaultMessage={"Order has conflicting employees"}
          />
        }
        message={conflictingEmployees.map((employee) =>
          employee.userId ? (
            <EmployeeInfo
              key={employee.id}
              employee={employee}
              user={userMap.get(employee.userId.toString())}
            />
          ) : null
        )}
        severity={"error"}
      />
      <StatusProgressBar
        statuses={statuses.concat(
          saveStatus,
          checkStatus,
          employeeConflictStatus
        )}
      />
      <ValidationErrors errors={errors} resetErrors={resetErrors} />
      <OrderConflicts />
      <DataGrid<OrderDataGrid>
        defaultFilterOpen
        title={<RouteMessage id={"ordersList"} />}
        data={ordersList}
        customFilter={canSeeFilters ? <OrderFilter /> : null}
        toolbarIcons={toolbar}
        onRowClick={handleRowClick}
        columns={[
          columns.reqDataSync,
          columns.source,
          columns.activity,
          columns.date,
          columns.numberOfPersons,
          columns.group,
          columns.address,
          canChangeEmployees ? columns.employees : columns.employeesReduced,
          canChangeRewards ? columns.reward : null,
          columns.extras,
          columns.upsales,
          columns.note,
          columns.method,
          columns.costs,
          columns.status,
        ].filter(isNotNull)}
        actions={[
          (order: OrderDataGrid) => ({
            icon: () => <MailIcon />,
            tooltip: formatMessage({
              id: "resendVoucher",
              defaultMessage: "Resend voucher",
            }),
            hidden: !canSave,
            onClick: () => dispatch(sendVoucherEmail(order.id)),
          }),
          (order: OrderDataGrid) => ({
            icon: () => (order.isLocked ? <UnlockIcon /> : <LockIcon />),
            tooltip: order.isLocked
              ? formatMessage({
                  id: "unlockOrder",
                  defaultMessage: "Unlock order",
                })
              : formatMessage({
                  id: "lockOrder",
                  defaultMessage: "Lock order",
                }),
            hidden: !canSave,
            onClick: () => handleLockToggle(order),
          }),
          ({
            loggedEmployee: employee,
            id: orderId,
            status: orderStatus,
          }: OrderDataGrid) => ({
            icon: () => <CheckIcon />,
            tooltip: formatMessage({
              id: "acceptOrder",
              defaultMessage: "Accept order",
            }),
            hidden: !(
              employee &&
              canEmployeeAccept(employee.status) &&
              isOrderOpened(orderStatus)
            ),
            onClick: () =>
              employee
                ? dispatch(
                    saveEmployeeStatus(
                      employee.id,
                      orderId,
                      OrderEmployeeStatus.ACCEPTED
                    )
                  )
                : {},
          }),
          ({
            loggedEmployee: employee,
            id: orderId,
            status: orderStatus,
          }: OrderDataGrid) => ({
            icon: () => <CloseIcon />,
            tooltip: formatMessage({
              id: "declineOrder",
              defaultMessage: "Decline order",
            }),
            hidden: !(
              employee &&
              canEmployeeAccept(employee.status) &&
              isOrderOpened(orderStatus)
            ),
            disabled: employee ? !canEmployeeDecline(employee.status) : true,
            onClick: () =>
              employee
                ? dispatch(
                    saveEmployeeStatus(
                      employee.id,
                      orderId,
                      OrderEmployeeStatus.DECLINED
                    )
                  )
                : {},
          }),
        ]}
        editable={
          canSave
            ? {
                onRowUpdate: ({ req, id }) => handleRowSave(req, id),
                onRowAdd: ({ req }) => handleRowSave(req),
                onRowAddCancelled: resetErrors,
                onRowUpdateCancelled: resetErrors,
              }
            : undefined
        }
        onSearchChange={handleSearchChange}
        styleRow={styleRow}
        cta={
          <LoadingButton
            variant={"contained"}
            color={`primary`}
            isLoading={statuses.some(
              (status) => status === RequestStatus.REQUESTED
            )}
            onClick={() =>
              paginationProps.onPageNumberChange(paginationProps.pageNumber + 1)
            }
          >
            <FormattedMessage id={"loadMore"} defaultMessage={"Load more"} />
          </LoadingButton>
        }
      />
    </>
  )
}
