import { UserRes } from "../types/apiTypes"
import { ActionTypes } from "../types/actionTypes"
import { Locale, StorageItems } from "../types/globalTypes"

export const logUser = (email?: string, password?: string) =>
  ({
    type: ActionTypes.LOG_USER_REQUESTED,
    payload: { email, password },
  } as const)

export const logUserSucceeded = (user: UserRes, token: string) =>
  ({
    type: ActionTypes.LOG_USER_SUCCEEDED,
    payload: { user, token },
  } as const)

export const logUserFailed = (error: string) =>
  ({
    type: ActionTypes.LOG_USER_FAILED,
    payload: { message: error },
  } as const)

export const logoutUser = () =>
  ({
    type: ActionTypes.LOGOUT_USER_REQUESTED,
  } as const)

export const logoutUserSucceeded = () =>
  ({
    type: ActionTypes.LOGOUT_USER_SUCCEEDED,
  } as const)

export const logoutUserFailed = (message: string) =>
  ({
    type: ActionTypes.LOGOUT_USER_FAILED,
    payload: { message },
  } as const)

export const restoreInterface = () =>
  ({
    type: ActionTypes.RESTORE_INTERFACE_REQUESTED,
  } as const)

export const restoreInterfaceSucceeded = (payload: Partial<StorageItems>) =>
  ({
    type: ActionTypes.RESTORE_INTERFACE_SUCCEEDED,
    payload,
  } as const)

export const restoreInterfaceFailed = (message: string) =>
  ({
    type: ActionTypes.RESTORE_INTERFACE_FAILED,
    payload: { message },
  } as const)

export const changeLocale = (locale: Locale) =>
  ({
    type: ActionTypes.CHANGE_LOCALE_REQUESTED,
    payload: { locale },
  } as const)

export const changeLocaleSucceeded = (locale: Locale) =>
  ({
    type: ActionTypes.CHANGE_LOCALE_SUCCEEDED,
    payload: { locale },
  } as const)

export const changeLocaleFailed = (message: string) =>
  ({
    type: ActionTypes.CHANGE_LOCALE_FAILED,
    payload: { message },
  } as const)

export const setZoom = (value: number) =>
  ({
    type: ActionTypes.SET_ZOOM,
    payload: { value },
  } as const)


export const setBacklink = (value: string | null) =>
  ({
    type: ActionTypes.SET_BACKLINK,
    payload: { value },
  } as const)

export const setFilter = (key: string, value: string[]) =>
  ({
    type: ActionTypes.SET_FILTER,
    payload: { key, value },
  } as const)
