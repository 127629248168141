import React, {
  ChangeEvent,
  memo,
  SyntheticEvent,
  useEffect,
  useState,
} from "react"
import { Set } from "immutable"
import { Navigate } from "react-router-dom"
import Form from "../../components/Form/Form"
import { LiteEvent } from "../../types/globalTypes"
import LanguageTab from "../../components/LanguageTab/LanguageTab"
import Input from "../../components/Input/Input"
import Checkbox from "../../components/Checkbox/Checkbox"
import MinuteInput from "../../components/MinuteInput/MinuteInput"
import MultipleFiledSet from "../../components/MultipleFiledSet/MultipleFiledSet"
import {
  bonResponsibilityOptions,
  employeeRoleInputOptions,
  formFieldsInputOptions,
  idParamToPost,
  languageList,
  sampleBon as bon,
  simpleToFullTranslation,
  upsaleTypeInputOptions,
} from "../../utils/typeUtils"
import Results from "../../components/Results/Results"
import ComboBox from "../../components/ComboBox/ComboBox"
import MultiSelect from "../../components/MultiSelect/MultiSelect"
import { GeneralEvent } from "../../utils/inputUtils"
import { RequestStatus } from "../../types/statusTypes"
import config from "../../config"
import { useUpsaleFetch, useUpsaleSave } from "../../hooks/upsaleHooks"
import {
  sampleBlock as block,
  sampleRole as role,
} from "../ActivityDetail/ActivityDetailState"
import {
  BlockingReq,
  BonReq,
  FormField,
  PackageType,
  RequiredRoleReq,
} from "../../types/apiTypes"
import RouteMessage from "../../components/Messages/RouteMessage"
import { Field } from "../../components/Messages/FieldMessage"
import { Entity } from "../../components/Messages/EntityMessage"
import {
  useLanguageState,
  useToggle,
  useTranslationState,
} from "../../hooks/stateHooks"
import { useResourceFetch } from "../../hooks/resourceHooks"
import CheckboxList from "../../components/CheckboxList/CheckboxList"
import BonCategorySelect from "../../components/BonCategorySelect/BonCategorySelect"
import InlineFieldset from "../../components/InlineFieldset/InlineFieldset"
import { useIdParam } from "../../hooks/routerHooks"
import { decodeInputName, encodeInputName, uid } from "../../utils/stringUtils"

const sampleOption = {
  availableFrom: 60,
  availableTo: 120,
  costExternal: 50,
  costPerPerson: 50,
  costPersons: 1,
  minCapacity: 1,
  maxCapacity: 50,
}

type BlocksState = Record<string, typeof block>
type RolesState = Record<string, typeof role>
type BonsState = Record<string, typeof bon>

function UpsaleDetail() {
  const id = useIdParam()
  const { language, setLanguage } = useLanguageState()
  const [title, onTitleChange, fillTitle] = useTranslationState()
  const [content, onContentChange, fillContent] = useTranslationState()
  const [saveUpsale, saveStatus, saveError] = useUpsaleSave()
  const [isActive, toggleActive, setIsActive] = useToggle()
  const [isWeb, , setIsWeb] = useToggle()
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [option, setOption] = useState<typeof sampleOption>(sampleOption)
  const [blocks, setBlocks] = useState<BlocksState>({})
  const [roles, setRoles] = useState<RolesState>({})
  const [bons, setBons] = useState<BonsState>({})
  const [hasReservation, toggleHasReservation, setHasReservation] = useToggle()
  const [isConstraint, toggleIsConstraint, setIsConstraint] = useToggle()
  const [types, setTypes] = useState<Set<PackageType>>(Set)
  const [requiredFields, setRequiredFields] = useState<string[]>([])
  const [optionalFields, setOptionalFields] = useState<string[]>([])
  const [resources, resourceStatus, resourceError] = useResourceFetch()
  const [upsales, fetchStatus, fetchError] = useUpsaleFetch(id)
  const upsale = upsales.get(id)

  useEffect(() => {
    if (upsale) {
      setIsWeb(upsale.isForWeb)
      fillTitle(upsale.translations, "title")
      setTypes(Set(upsale.type))
      fillContent(upsale.translations, "content")
      setIsActive(upsale.isActive)
      setIsConstraint(upsale.isConstrainedByOpeningHours)
      setRequiredFields(upsale.requiredFields)
      setOptionalFields(upsale.optionalFields)
      setHasReservation(upsale.containsReservation)
      setOption({
        availableFrom: upsale.availableFrom,
        availableTo: upsale.availableTo,
        costExternal: upsale.cost,
        costPerPerson: upsale.costPerNCapitas.price,
        costPersons: upsale.costPerNCapitas.numberOfPersons,
        minCapacity: upsale.minCapacity,
        maxCapacity: upsale.maxCapacity,
      })
      setBlocks(
        upsale.blocking.reduce(
          (state: BlocksState, item) => ({ ...state, [uid()]: item }),
          {}
        )
      )
      setRoles(
        upsale.requiredRoles.reduce(
          (state: RolesState, item) => ({ ...state, [uid()]: item }),
          {}
        )
      )
      setBons(
        upsale.bons.reduce(
          (state: BonsState, item) => ({ ...state, [uid()]: item }),
          {}
        )
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upsale])

  const onSubmit = () => {
    setIsSubmitted(true)
    const translations = simpleToFullTranslation(
      [title, content],
      ["title", "content"]
    )
    saveUpsale(
      {
        id: idParamToPost(id),
        containsReservation: hasReservation,
        gallery: upsale?.gallery || null,
        availableFrom: option.availableFrom,
        availableTo: option.availableTo,
        cost: option.costExternal,
        costPerNCapitas: {
          price: option.costPerPerson,
          numberOfPersons: option.costPersons,
        },
        maxCapacity: option.maxCapacity,
        minCapacity: option.minCapacity,
        priority: upsale?.priority || 999,
        isConstrainedByOpeningHours: isConstraint,
        blocking: Object.values(blocks),
        bons: Object.values(bons),
        requiredRoles: Object.values(roles),
        translations,
        optionalFields: optionalFields as FormField[],
        requiredFields: requiredFields as FormField[],
        isActive,
        tagIds: upsale?.tagIds || [],
        type: [...types],
        isForWeb: isWeb,
      },
      id
    )
  }

  const handleChangeOption = ({
    currentTarget: { name, value },
  }: SyntheticEvent<HTMLInputElement | HTMLTextAreaElement> | LiteEvent) => {
    setOption({ ...option, [name]: parseFloat(value) })
  }

  const handleChangeTranslation = ({
    currentTarget: { name, value },
  }: ChangeEvent<HTMLInputElement>) => {
    if (name === "title") onTitleChange(value, language)
    if (name === "content") onContentChange(value, language)
  }

  const handleChangeBlock = ({
    currentTarget: { name, value },
  }: GeneralEvent) => {
    const [key, blockKey] = decodeInputName(name)
    setBlocks({
      ...blocks,
      [blockKey]: { ...blocks[blockKey], [key]: value },
    })
  }

  const handleChangeRole = ({
    currentTarget: { name, value },
  }: GeneralEvent) => {
    const [key, roleKey] = decodeInputName(name)
    setRoles({ ...roles, [roleKey]: { ...roles[roleKey], [key]: value } })
  }

  const handleChangeBon = ({
    currentTarget: { name, value },
  }: GeneralEvent) => {
    const [key, bonKey] = decodeInputName(name)
    setBons({ ...bons, [bonKey]: { ...bons[bonKey], [key]: value } })
  }

  const handleChangeType = ({
    currentTarget: { value },
  }: LiteEvent<PackageType>) => {
    setTypes(types.has(value) ? types.remove(value) : types.add(value))
  }

  return (
    <>
      {saveStatus === RequestStatus.SUCCEEDED && !id && (
        <Navigate to={config.routes.upsaleList.path()} />
      )}
      <Results
        statuses={[saveStatus, resourceStatus, fetchStatus]}
        errors={[saveError, resourceError, fetchError]}
        actions={["save", "fetch", "fetch"]}
        entity={Entity.UPSALE}
      />
      <Form
        title={<RouteMessage id={"upsaleDetail"} />}
        statuses={[saveStatus, resourceStatus, fetchStatus]}
        onSubmit={onSubmit}
        toolbar={
          <LanguageTab
            language={language}
            onChange={setLanguage}
            notifications={languageList.filter(
              (lang) => !title[lang] || !content[lang]
            )}
          />
        }
        useGenericCta
      >
        <Input
          messageId={Field.TITLE}
          name={`title`}
          onChange={handleChangeTranslation}
          value={title[language]}
          showRequiredWarning={isSubmitted}
        />
        <Input
          messageId={Field.CONTENT}
          name={`content`}
          onChange={handleChangeTranslation}
          value={content[language]}
          multiline
          showRequiredWarning={isSubmitted}
        />
        <MultiSelect
          onChange={(_: string, values: string[]) => setRequiredFields(values)}
          values={requiredFields}
          options={formFieldsInputOptions}
          messageId={Field.REQUIRED_FIELDS}
          name={"requiredFields"}
        />
        <MultiSelect
          onChange={(_: string, values: string[]) => setOptionalFields(values)}
          values={optionalFields}
          options={formFieldsInputOptions}
          messageId={Field.OPTIONAL_FIELDS}
          name={"optionalFields"}
        />
        <Checkbox
          messageId={Field.IS_ACTIVE}
          name={"isActive"}
          checked={isActive}
          onChange={toggleActive}
        />
        <Checkbox
          messageId={Field.CONTAINS_RESERVATION}
          name={"containsReservation"}
          checked={hasReservation}
          onChange={toggleHasReservation}
        />
        <Checkbox
          messageId={Field.IS_CONSTRAINED}
          name={`isConstrainedByOpeningHours`}
          checked={isConstraint}
          onChange={toggleIsConstraint}
        />
        <CheckboxList<PackageType>
          messageId={Field.TYPE}
          values={types}
          name={`tagId`}
          options={upsaleTypeInputOptions}
          onChange={handleChangeType}
        />
        <Checkbox
          messageId={Field.IS_AGENCY}
          name={`isAgency`}
          checked={!isWeb}
          onChange={() => setIsWeb(false)}
        />
        <Checkbox
          messageId={Field.IS_WEB}
          name={`isWeb`}
          checked={isWeb}
          onChange={() => setIsWeb(true)}
        />
        <InlineFieldset messageId={Field.AVAILABILITY}>
          <MinuteInput
            messageId={Field.FROM}
            value={option.availableFrom}
            name={`availableFrom`}
            onChange={handleChangeOption}
            required
          />
          <MinuteInput
            messageId={Field.TO}
            value={option.availableTo}
            name={`availableTo`}
            onChange={handleChangeOption}
            required
          />
        </InlineFieldset>
        <InlineFieldset messageId={Field.COSTS}>
          <Input
            messageId={Field.EXTERNAL_COST}
            value={option.costExternal}
            name={`costExternal`}
            onChange={handleChangeOption}
            type={"number"}
            min={0}
            required
          />
          <Input
            messageId={Field.COST_PER_PERSON}
            value={option.costPerPerson}
            name={`costPerPerson`}
            onChange={handleChangeOption}
            type={"number"}
            min={0}
            required
          />
          <Input
            messageId={Field.NUMBER_OF_PERSONS}
            value={option.costPersons}
            name={`costPersons`}
            onChange={handleChangeOption}
            type={"number"}
            required
            min={1}
          />
        </InlineFieldset>
        <InlineFieldset messageId={Field.CAPACITY}>
          <Input
            messageId={Field.MIN}
            value={option.minCapacity}
            name={`minCapacity`}
            onChange={handleChangeOption}
            type={"number"}
            min={1}
            required
          />
          <Input
            messageId={Field.MAX}
            value={option.maxCapacity}
            name={`maxCapacity`}
            onChange={handleChangeOption}
            type={"number"}
            min={1}
            required
          />
        </InlineFieldset>
        <MultipleFiledSet
          messageId={Field.BLOCKS}
          entity={Entity.GROUP}
          onCreate={() => setBlocks({ ...blocks, [uid()]: block })}
          onRemove={(key: string) => setBlocks(({ [key]: x, ...r }) => r)}
        >
          {Object.entries(blocks).map(
            ([
              key,
              {
                durationBefore,
                durationActual,
                durationAfter,
                quantityBlocked,
                quantityRequired,
                resourceId,
              },
            ]) => {
              const encodeBlockInputName = (field: keyof BlockingReq) =>
                encodeInputName(field, key)
              return (
                <InlineFieldset key={key}>
                  <MinuteInput
                    messageId={Field.DURATION_BEFORE}
                    value={durationBefore}
                    name={encodeBlockInputName("durationBefore")}
                    onChange={handleChangeBlock}
                  />
                  <MinuteInput
                    messageId={Field.DURATION_ACTUAL}
                    value={durationActual}
                    name={encodeBlockInputName("durationActual")}
                    onChange={handleChangeBlock}
                  />
                  <MinuteInput
                    messageId={Field.DURATION_AFTER}
                    value={durationAfter}
                    name={encodeBlockInputName("durationAfter")}
                    onChange={handleChangeBlock}
                  />
                  <Input
                    messageId={Field.QUANTITY_REQUIRED}
                    name={encodeBlockInputName("quantityRequired")}
                    value={quantityRequired}
                    onChange={handleChangeBlock}
                  />
                  <Input
                    messageId={Field.QUANTITY_BLOCKED}
                    name={encodeBlockInputName("quantityBlocked")}
                    value={quantityBlocked}
                    onChange={handleChangeBlock}
                  />
                  <ComboBox
                    name={encodeBlockInputName("resourceId")}
                    messageId={Field.GROUP}
                    options={resources
                      .toArray()
                      .map(([value, { title: resourceTitle }]) => ({
                        value,
                        content: resourceTitle,
                      }))}
                    onChange={handleChangeBlock}
                    value={resourceId.toString()}
                    dense={false}
                  />
                </InlineFieldset>
              )
            }
          )}
        </MultipleFiledSet>
        <MultipleFiledSet
          messageId={Field.ROLES}
          entity={Entity.ROLE}
          onCreate={() => setRoles({ ...roles, [uid()]: role })}
          onRemove={(key: string) => setRoles(({ [key]: x, ...r }) => r)}
        >
          {Object.entries(roles).map(
            ([
              key,
              {
                role: roleValue,
                reward,
                note,
                offset,
                durationActual,
                durationAfter,
                durationBefore,
              },
            ]) => {
              const encodeRoleInputName = (field: keyof RequiredRoleReq) =>
                encodeInputName(field, key)
              return (
                <div key={key}>
                  <InlineFieldset>
                    <ComboBox
                      messageId={Field.ROLE}
                      name={encodeRoleInputName("role")}
                      value={roleValue}
                      dense={false}
                      options={employeeRoleInputOptions}
                      onChange={handleChangeRole}
                    />
                    <Input
                      messageId={Field.REWARD}
                      name={encodeRoleInputName("reward")}
                      value={reward}
                      type={`number`}
                      min={0}
                      onChange={handleChangeRole}
                    />
                  </InlineFieldset>
                  <InlineFieldset>
                    <MinuteInput
                      messageId={Field.OFFSET}
                      value={offset}
                      name={encodeRoleInputName("offset")}
                      onChange={handleChangeRole}
                    />
                    <MinuteInput
                      messageId={Field.DURATION_BEFORE}
                      value={durationBefore}
                      name={encodeRoleInputName("durationBefore")}
                      onChange={handleChangeRole}
                    />
                    <MinuteInput
                      messageId={Field.DURATION_ACTUAL}
                      value={durationActual}
                      name={encodeRoleInputName("durationActual")}
                      onChange={handleChangeRole}
                    />
                    <MinuteInput
                      messageId={Field.DURATION_AFTER}
                      value={durationAfter}
                      name={encodeRoleInputName("durationAfter")}
                      onChange={handleChangeRole}
                    />
                  </InlineFieldset>
                  <InlineFieldset>
                    <Input
                      messageId={Field.NOTE}
                      name={encodeRoleInputName("note")}
                      onChange={handleChangeRole}
                      value={note}
                    />
                  </InlineFieldset>
                </div>
              )
            }
          )}
        </MultipleFiledSet>
        <MultipleFiledSet
          messageId={Field.BONS}
          entity={Entity.BON}
          onCreate={() => setBons({ ...bons, [uid()]: bon })}
          onRemove={(key: string) => setBons(({ [key]: x, ...r }) => r)}
        >
          {Object.entries(bons).map(
            ([
              key,
              {
                content: bonTitle,
                category,
                count,
                forNPersons,
                responsibility: bonResponsibility,
              },
            ]) => {
              const encodeBonInputName = (field: keyof BonReq) =>
                encodeInputName(field, key)
              return (
                <InlineFieldset key={key}>
                  <BonCategorySelect
                    onChange={handleChangeBon}
                    dense={false}
                    value={category}
                    name={encodeBonInputName(`category`)}
                  />
                  <Input
                    messageId={Field.TITLE}
                    name={encodeBonInputName(`content`)}
                    value={bonTitle}
                    onChange={handleChangeBon}
                  />
                  <Input
                    messageId={Field.QUANTITY}
                    name={encodeBonInputName(`count`)}
                    value={count}
                    onChange={handleChangeBon}
                    type={"number"}
                    min={1}
                  />
                  <Input
                    messageId={Field.FOR_N_PERSONS}
                    name={encodeBonInputName(`forNPersons`)}
                    value={forNPersons}
                    onChange={handleChangeBon}
                    type={"number"}
                    min={1}
                  />
                  <ComboBox
                    messageId={Field.RESPONSIBILITIES}
                    name={encodeBonInputName(`responsibility`)}
                    value={bonResponsibility}
                    dense={false}
                    options={bonResponsibilityOptions}
                    onChange={handleChangeBon}
                    disableClearable
                  />
                </InlineFieldset>
              )
            }
          )}
        </MultipleFiledSet>
      </Form>
    </>
  )
}

export default memo(UpsaleDetail)
