import React from "react"
import image from "./logo.svg"
import "./Logo.css"

export default function Logo() {
  return (
    <a href="/" className={"logo"}>
      <img src={image} alt={"Goldfinters logo"} />
    </a>
  )
}
