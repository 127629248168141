import { Crop } from "react-image-crop"
import { FormattedMessage } from "react-intl"
import { Grid } from "@material-ui/core"
import { Visibility } from "@material-ui/icons"
import React, { ChangeEvent, memo, useEffect, useState } from "react"
import { Map } from "immutable"
import Form from "../../components/Form/Form"
import { MediaItem } from "../../types/globalTypes"
import EntityList from "../../components/EntityList/EntityList"
import Input from "../../components/Input/Input"
import GalleryUpload from "../../components/GalleryUpload/GalleryUpload"
import ImageUpload from "../../components/ImageUpload/ImageUpload"
import Results from "../../components/Results/Results"
import {
  blankMedium,
  createPrice,
  blankSingleTranslations,
  blankTranslation,
  blankTranslations,
  createBlankOptionPrice,
  isSourceVideo,
  languageList,
  simpleToFullTranslation,
  idParamToPost,
} from "../../utils/typeUtils"
import { LinkRes, MediaType, MediumReq, PackageRes } from "../../types/apiTypes"
import LanguageTab from "../../components/LanguageTab/LanguageTab"
import RouteMessage from "../../components/Messages/RouteMessage"
import { Field } from "../../components/Messages/FieldMessage"
import { usePricesFetch, usePriceState } from "../../hooks/priceHooks"
import config from "../../config"
import { Entity } from "../../components/Messages/EntityMessage"
import FieldSet from "../../components/FieldSet/FieldSet"
import {
  useActivityFetch,
  useActivityPriceSave,
  useActivitySave,
} from "../../hooks/activityHooks"
import { useTagState } from "../../hooks/tagHooks"
import {
  defaultCropDimensions,
  useGalleryState,
} from "../../hooks/galleryHooks"
import {
  useLanguageState,
  useToggle,
  useTranslationState,
} from "../../hooks/stateHooks"
import { removeKey } from "../../utils/objectUtils"
import Button from "../../components/Button/Button"
import Checkbox from "../../components/Checkbox/Checkbox"
import { requestUploadFile } from "../../api/galleryRequests"
import { useToken } from "../../hooks/fetchHooks"
import { fileToBase64Image } from "../../utils/apiUtils"
import Modal from "../../components/Modal/Modal"
import ImageCrop from "../../components/ImageCrop/ImageCrop"
import { useIdParam } from "../../hooks/routerHooks"

interface OptionImages {
  [optionId: string]: string | File
}

function PackageDetail() {
  const id = useIdParam()
  const token = useToken()
  const [activityMap, fetchStatus, fetchError] = useActivityFetch(id)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isActive, toggleActive, setIsActive] = useToggle()
  const [isTableUpsale, toggleTableUpsale, setTableUpsale] = useToggle()
  const [savePackage, saveStatus, saveError] = useActivitySave()
  const { language, setLanguage } = useLanguageState()
  const [optionImages, setOptionImages] = useState<OptionImages>({})
  const [title, handleTitleChange, fillTitle] = useTranslationState()
  const [keyword, handleKeywordChange, fillKeyword] = useTranslationState()
  const [description, handleDescriptionChange, fillDescription] =
    useTranslationState()
  const [content, handleContentChange, fillContent] = useTranslationState()
  const galleryProps = useGalleryState()
  const [cropImage, setCropImage] = useState<string | File>(``)
  const [cardImage, setCardImage] = useState<string | File>(``)
  const [mainMedium, setMainMedium] = useState<string | File>(``)
  const { prices, changePrice, fillPrices } = usePriceState()
  const [savePrices, savePricesStatus, savePricesError] = useActivityPriceSave()
  const [fetchPrices, priceFetchStatus, priceFetchError] = usePricesFetch(
    config.agencyIds.web
  )
  const {
    tags,
    handleActiveTagChange,
    handleTagSort,
    activeTag,
    handleTagTitleChange,
    titles,
    contents,
    handleTagContentChange,
    fillWithData: fillOptions,
  } = useTagState()
  const [croppedImage, setCroppedMedium] = useState<MediaItem | null>(null)
  const activity = activityMap.get(id)

  useEffect(() => {
    if (activity) {
      setIsActive(activity.isActive)
      setTableUpsale(activity.addToReservation)
      fillTitle(activity.translations, "title")
      fillDescription(activity.translations, "content")
      fillContent(activity.translations, "additionalInfo")
      fillKeyword(activity.translations, "keyword")
      fillOptions(
        activity.options.map((option) => option.id.toString()),
        activity.options.map((option) => option.translations),
        activity.options.map(() => [])
      )
      fetchPrices((priceMap) => {
        fillPrices(
          Map(
            activity.options.map((option) => [
              `${config.agencyIds.web}-${option.id}`,
              priceMap.get(`${config.agencyIds.web}-${option.id}`) ||
                createBlankOptionPrice(option.id),
            ])
          ),
          Map(activity.options.map((option) => [option.id, activity.id]))
        )
      })
      const {
        media: [
          { url: cropUrl } = blankMedium,
          { url: cardUrl } = blankMedium,
          { url: mainUrl } = blankMedium,
          ...media
        ],
      } = activity.gallery || {
        media: [blankMedium, blankMedium, blankMedium],
      }
      setCropImage(cropUrl)
      setCardImage(cardUrl)
      setMainMedium(mainUrl)
      galleryProps.fillData({ default: media })
      setOptionImages(
        activity.options.reduce((acc, option) => {
          const {
            media: [{ url }],
          } = option.gallery || { media: [blankMedium] }
          acc[option.id] = url
          return acc
        }, {} as OptionImages)
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activity])

  const handleSubmit = async () => {
    if (activity) {
      setIsSubmitted(true)
      await savePrices(prices)
      const media: MediumReq[] = await Promise.all(
        [
          { id: "cropImage", source: cropImage },
          { id: "cardImage", source: cardImage },
          { id: "mainMedium", source: mainMedium },
          ...galleryProps.getGallery(),
        ].map(({ source, id: mediumId }) =>
          (source instanceof File
            ? requestUploadFile(
                token,
                source,
                galleryProps.getMediumCrop(mediumId) || defaultCropDimensions
              )
            : new Promise<LinkRes>((res) => {
                res({ link: source })
              })
          ).then(
            ({ link }): MediumReq => ({
              translations: simpleToFullTranslation(
                [
                  galleryProps.labels[mediumId] || blankSingleTranslations,
                  galleryProps.contents[mediumId] || blankSingleTranslations,
                ],
                ["title", "content"]
              ),
              type: isSourceVideo(source) ? MediaType.VIDEO : MediaType.IMAGE,
              url: link,
            })
          )
        )
      )

      const optionImageUrls: LinkRes[] = await Promise.all(
        tags.map((tagId) => {
          const optionImage = optionImages[tagId]
          return optionImage instanceof File
            ? requestUploadFile(token, optionImage)
            : new Promise<LinkRes>((res) => {
                res({ link: optionImage })
              })
        })
      )

      const optionImageMap = Object.fromEntries(
        tags.map((tag, idx) => [tag, optionImageUrls[idx].link])
      )
      await savePackage(
        {
          id: idParamToPost(id),
          gallery: {
            translations: blankTranslations,
            media,
          },
          isActive,
          containsReservation: activity.containsReservation,
          addToReservation: isTableUpsale,
          type: activity.type,
          options: activity.options.map((option) => ({
            id: option.id,
            gallery: optionImageMap[option.id.toString()]
              ? {
                  media: [
                    {
                      url: optionImageMap[option.id.toString()],
                      type: MediaType.IMAGE,
                      translations: blankTranslations,
                    },
                  ],
                  translations: blankTranslations,
                }
              : null,
            availableFrom: option.availableFrom,
            availableTo: option.availableTo,
            blocking: option.blocking,
            bons: option.bons,
            cost: option.cost,
            costPerNCapitas: option.costPerNCapitas,
            isConstrainedByOpeningHours: option.isConstrainedByOpeningHours,
            maxCapacity: option.maxCapacity,
            minCapacity: option.minCapacity,
            priority: tags.indexOf(option.id.toString()),
            translations: simpleToFullTranslation(
              [titles[option.id], contents[option.id]],
              ["title", "content"]
            ),
            cancellationPolicy: option.cancellationPolicy,
            requiredRoles: option.requiredRoles,
            requiredFields: option.requiredFields,
            optionalFields: option.optionalFields,
          })),
          priority: activity.priority,
          tagIds: activity.tagIds,
          isForWeb: activity.isForWeb,
          translations: simpleToFullTranslation(
            [title, description, content, keyword],
            ["title", "content", "additionalInfo", "keyword"]
          ),
          upsalePackageIds: activity.upsalePackageIds,
          requiredUpsaleIds: activity.requiredUpsaleIds,
        },
        id
      )
    }
  }

  const handlePriceChange = ({
    currentTarget: { name, value },
  }: ChangeEvent<HTMLInputElement>) => {
    changePrice(id, activeTag, name, value)
  }

  const handlePreview = async () => {
    const base64OptionMap = await Promise.all(
      Object.entries(optionImages).map(([optionId, image]) =>
        (image instanceof File
          ? fileToBase64Image(image, { width: 400 })
          : new Promise<string>((res) => {
              res(image)
            })
        ).then((url) => [optionId, url])
      )
    ).then((res) => Object.fromEntries(res))
    const galleryMedia = await Promise.all(
      [
        { id: "cardImage", source: cardImage },
        { id: "mainMedium", source: mainMedium },
        ...galleryProps.getGallery(),
      ].map(({ source, id: mediumId }) =>
        (source instanceof File
          ? fileToBase64Image(
              source,
              galleryProps.getMediumCrop(mediumId) || {}
            )
          : new Promise<string>((res) => {
              res(source)
            })
        ).then((url) => ({
          ...blankTranslation,
          content: galleryProps.contents?.[mediumId]?.[language] || "",
          title: galleryProps.labels?.[mediumId]?.[language] || "",
          type: isSourceVideo(source) ? MediaType.VIDEO : MediaType.IMAGE,
          url,
        }))
      )
    )

    const data: PackageRes = {
      id: idParamToPost(id),
      title: title[language],
      content: description[language],
      additionalInfo: content[language],
      gallery: {
        ...blankTranslation,
        media: galleryMedia,
      },
      options:
        activity?.options?.map((option) => ({
          ...blankTranslation,
          ...option,
          title: titles[option.id][language],
          content: contents[option.id][language],
          gallery: base64OptionMap[option.id]
            ? {
                ...blankTranslation,
                media: [
                  {
                    ...blankTranslation,
                    type: MediaType.IMAGE,
                    url: base64OptionMap[option.id],
                  },
                ],
              }
            : null,
          price: {
            agencyId: config.agencyIds.web,
            packageOptionId: option.id,
            deposit: createPrice(prices[id]?.[option.id]?.deposit),
            feeOver10Persons: createPrice(prices[id]?.[option.id]?.fee),
            price: createPrice(prices[id]?.[option.id]?.priceUnit),
            pricePerNCapitas: {
              numberOfPersons: 1,
              price: createPrice(prices[id]?.[option.id]?.pricePerPerson),
            },
          },
        })) || [],
      keyword: "",
      metaData: "",
      priority: activity?.priority || 0,
      requiredUpsaleIds: activity?.requiredUpsaleIds || [],
      tagIds: activity?.tagIds || [],
      upsalePackageIds: activity?.upsalePackageIds || [],
      addToReservation: activity?.addToReservation || false,
      containsReservation: activity?.containsReservation || false,
      type: activity?.type || [],
    }
    const preview = window.open(config.packagePreview, "name")
    await new Promise<void>((res) => {
      setTimeout(() => res(), 3000)
    })
    preview?.postMessage({ preview: data }, "*")
  }

  const handleInitCropFile = (mediumId: string) => {
    const search = galleryProps
      .getGallery()
      .find((medium) => medium.id === mediumId)
    if (search) setCroppedMedium(search)
  }

  return (
    <>
      <Modal
        open={!!croppedImage}
        onClose={() => setCroppedMedium(null)}
        maxWidth={false}
      >
        {croppedImage && (
          <ImageCrop
            image={croppedImage.source}
            defaultCrop={galleryProps.getMediumCrop(croppedImage.id)}
            onComplete={({ x = 0, y = 0, width = 0, height = 0 }: Crop) => {
              galleryProps.setMediumCrop(croppedImage.id, {
                x,
                y,
                width,
                height,
              })
            }}
          />
        )}
      </Modal>
      <Results
        statuses={[fetchStatus, saveStatus]}
        errors={[fetchError, saveError]}
        actions={["fetch", "save"]}
        entity={Entity.PACKAGE}
      />
      <Results
        statuses={[savePricesStatus, priceFetchStatus]}
        errors={[savePricesError, priceFetchError]}
        actions={["save", "fetch"]}
        entity={Entity.PRICE}
      />
      <Form
        title={<RouteMessage id={"packageDetail"} />}
        onSubmit={handleSubmit}
        statuses={[fetchStatus, saveStatus, savePricesStatus, priceFetchStatus]}
        toolbar={
          <LanguageTab
            language={language}
            onChange={setLanguage}
            notifications={languageList.filter(
              (lang) =>
                !title[lang] ||
                !description[lang] ||
                !content[lang] ||
                tags.some((tag) => !titles[tag][lang] || !contents[tag][lang])
            )}
          />
        }
        useGenericCta
        altActions={
          <Button
            type={"button"}
            startIcon={<Visibility />}
            onClick={handlePreview}
          >
            <FormattedMessage
              id={"showPreview"}
              defaultMessage={"Show preview"}
            />
          </Button>
        }
      >
        <Input
          messageId={Field.TITLE}
          name={`title`}
          onChange={(e) => handleTitleChange(e.currentTarget.value, language)}
          value={title[language]}
          showRequiredWarning={isSubmitted}
        />
        <Input
          messageId={Field.WEB_TITLE}
          name={`keyword`}
          onChange={(e) => handleKeywordChange(e.currentTarget.value, language)}
          value={keyword[language]}
        />
        <Input
          messageId={Field.DESCRIPTION}
          name={`description`}
          onChange={(e) =>
            handleDescriptionChange(e.currentTarget.value, language)
          }
          value={description[language]}
          multiline
          showRequiredWarning={isSubmitted}
        />
        <Input
          messageId={Field.ACTIVITY_CONTENT}
          name={`content`}
          onChange={(e) => handleContentChange(e.currentTarget.value, language)}
          value={content[language]}
          multiline
          showRequiredWarning={isSubmitted}
        />
        <Checkbox
          messageId={Field.IS_ACTIVE}
          name={"isActive"}
          checked={isActive}
          onChange={toggleActive}
        />
        <Checkbox
          messageId={Field.RESERVATION_UPSALE}
          name={"isTableUpsale"}
          checked={isTableUpsale}
          onChange={toggleTableUpsale}
        />
        <ImageUpload
          source={cropImage}
          name={`cropImage`}
          onUpload={setCropImage}
          onDelete={() => setCropImage(``)}
          messageId={Field.CROP_IMAGE}
          showRequiredWarning={isSubmitted}
        />
        <ImageUpload
          source={cardImage}
          name={`cardImage`}
          onUpload={setCardImage}
          onDelete={() => setCardImage(``)}
          messageId={Field.CARD_IMAGE}
          showRequiredWarning={isSubmitted}
        />
        <ImageUpload
          source={mainMedium}
          name={`mainMedium`}
          onUpload={setMainMedium}
          onDelete={() => setMainMedium(``)}
          allowURL
          messageId={Field.MAIN_IMAGE_VIDEO}
          showRequiredWarning={isSubmitted}
        />
        <GalleryUpload
          gallery={galleryProps.getGallery()}
          name={`gallery`}
          labels={galleryProps.labels}
          language={language}
          onUpload={galleryProps.handleUpload}
          onSort={galleryProps.handleSort}
          onLabelChange={galleryProps.handleLabelChange}
          onDelete={galleryProps.handleDelete}
          onCrop={handleInitCropFile}
          messageId={Field.GALLERY}
        />
        <EntityList
          entityPairs={tags.map((tag) => [tag, titles[tag][language]])}
          onEntityChange={handleActiveTagChange}
          onSort={handleTagSort}
          activeEntity={activeTag}
        >
          <Input
            messageId={Field.OPTION_TITLE}
            value={titles[activeTag][language]}
            name={`title-${activeTag}`}
            onChange={(e) =>
              handleTagTitleChange(e.currentTarget.value, language)
            }
            showRequiredWarning={isSubmitted}
          />
          <Input
            messageId={Field.OPTION_CONTENT}
            value={contents[activeTag][language]}
            name={`content-${activeTag}`}
            onChange={(e) =>
              handleTagContentChange(e.currentTarget.value, language)
            }
            multiline
            showRequiredWarning={isSubmitted}
          />
          <ImageUpload
            source={optionImages[activeTag]}
            name={`optionImage-${activeTag}`}
            onUpload={(file) =>
              setOptionImages({ ...optionImages, [activeTag]: file })
            }
            onDelete={() => setOptionImages(removeKey(optionImages, activeTag))}
            messageId={Field.CARD_IMAGE}
            showRequiredWarning={isSubmitted && tags.length > 1}
          />
          <FieldSet messageId={Field.PRICE_LIST}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Input
                  onChange={handlePriceChange}
                  defaultValue={0}
                  value={prices[id]?.[activeTag]?.priceUnit}
                  name={"priceUnit"}
                  messageId={Field.PRICE}
                  type={"number"}
                  min={0}
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  onChange={handlePriceChange}
                  defaultValue={0}
                  value={prices[id]?.[activeTag]?.pricePerPerson}
                  name={"pricePerPerson"}
                  messageId={Field.PRICE_PERSON}
                  type={"number"}
                  min={0}
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  onChange={handlePriceChange}
                  defaultValue={0}
                  value={prices[id]?.[activeTag]?.deposit}
                  name={"deposit"}
                  messageId={Field.DEPOSIT}
                  type={"number"}
                  min={0}
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  onChange={handlePriceChange}
                  defaultValue={0}
                  value={prices[id]?.[activeTag]?.fee}
                  name={"fee"}
                  messageId={Field.FEE}
                  type={"number"}
                  min={0}
                />
              </Grid>
            </Grid>
          </FieldSet>
        </EntityList>
      </Form>
    </>
  )
}

export default memo(PackageDetail)
