import makeStyles from "@material-ui/core/styles/makeStyles"
import { Theme } from "@material-ui/core"

export default makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    listStyle: "none",
    margin: 0,
    padding: 0,
  },
  item: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    margin: theme.spacing(0.5),
  },
  button: {
    width: "100%",
    height: "100%",
    borderRadius: "50%",
    overflow: "hidden",
    color: "transparent",
    border: "2px solid rgba(255, 255, 255, 0.2)",
    outline: "none",
  },
  activeButton: {
    borderColor: "rgba(255, 255, 255, 0.8)",
  },
}))
