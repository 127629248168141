import { Set } from "immutable"
import {
  addDays, addHours,
  addMonths,
  addWeeks,
  endOfDay,
  endOfMonth,
  endOfWeek,
  format,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subMonths,
  subWeeks
} from "date-fns"
import { FormField } from "types/apiTypes"
import { Period } from "../types/filterTypes"
import config from "../config"
import { timeFormFieldSet } from "./typeUtils"
import { isValid, roundToNearestMinutesWithOptions } from "date-fns/fp"
import { complement } from "ramda"

export const millisecondsInDay = 24 * 60 * 60 * 1000
const options = { weekStartsOn: 1 as 1 }
const today = new Date()
const periodMap: { [key in Period]: [string, string] } = {
  [Period.THIS_WEEK]: [
    startOfWeek(today, options).toISOString(),
    endOfWeek(today, options).toISOString(),
  ],
  [Period.LAST_WEEK]: [
    startOfWeek(subWeeks(today, 1), options).toISOString(),
    endOfWeek(subWeeks(today, 1), options).toISOString(),
  ],
  [Period.NEXT_WEEK]: [
    startOfWeek(addWeeks(today, 1), options).toISOString(),
    endOfWeek(addWeeks(today, 1), options).toISOString(),
  ],
  [Period.THIS_MONTH]: [
    startOfMonth(today).toISOString(),
    endOfMonth(today).toISOString(),
  ],
  [Period.LAST_MONTH]: [
    startOfMonth(subMonths(today, 1)).toISOString(),
    endOfMonth(subMonths(today, 1)).toISOString(),
  ],
  [Period.NEXT_MONTH]: [
    startOfMonth(addMonths(today, 1)).toISOString(),
    endOfMonth(addMonths(today, 1)).toISOString(),
  ],
  [Period.CUSTOM]: [startOfMonth(today).toISOString(), today.toISOString()],
  [Period.TODAY]: [
    startOfDay(today).toISOString(),
    endOfDay(today).toISOString(),
  ],
  [Period.GF_TODAY]: [
    addHours(startOfDay(today), 7).toISOString(),
    addHours(endOfDay(today), 7).toISOString(),
  ],
  [Period.TOMORROW]: [
    startOfDay(addDays(today, 1)).toISOString(),
    endOfDay(addDays(today, 1)).toISOString(),
  ],
}
const dateMap: { [key: string]: Period } = Object.fromEntries(
  Object.entries(periodMap).map(([period, [start, end]]): [string, Period] => [
    `${start}-${end}`,
    period as Period,
  ])
)

export const isInvalid = complement(isValid)

export function formatDateTime(
  date: number | null | undefined | Date,
  reverse: boolean = false
): string {
  if (!date || isInvalid(date)) return ""
  return format(
    date,
    reverse ? config.dateTimeFormatReverse : config.dateTimeFormat
  )
}

export function formatTime(
  date: number | null | undefined | Date,
): string {
  if (!date || isInvalid(date)) return ""
  return format(date, config.timeFormat)
}

export function formatContactInfoValue(key: FormField, value: string): string {
  if (timeFormFieldSet.has(key)) {
    return formatDateTime(parseInt(value, 10)) || value
  }
  return value
}

export function getFilterDatesByPeriod(period: Period): Set<string> {
  return Set<string>(periodMap[period])
}

export function getPeriodByDates(period?: Set<string>): Period {
  const [from, to] = period?.toArray() || []
  return dateMap[`${from}-${to}`] || Period.CUSTOM
}

export const roundToQuarterHour: (date: number | Date) => Date =
  roundToNearestMinutesWithOptions({
    nearestTo: 15,
  })

/**
 * Returns current time rounded to closest quarter-hour.
 */
export const currentQuarterTime = (): Date => roundToQuarterHour(Date.now())
