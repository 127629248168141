import React from "react"
import { FormattedMessage } from "react-intl"
import { InvoicePublisher } from "../../types/apiTypes"

interface IInvoicePublisherMessageProps {
  id: InvoicePublisher
}

export default function InvoicePublisherMessage({
  id,
}: IInvoicePublisherMessageProps) {
  switch (id) {
    case InvoicePublisher.ADDLAND:
      return <FormattedMessage id={`ADDLAND`} defaultMessage={`Addland`} />
    case InvoicePublisher.ADDLAND_INCOMING:
      return (
        <FormattedMessage
          id={`ADDLAND_INCOMING`}
          defaultMessage={`Addland Incoming`}
        />
      )
    case InvoicePublisher.ADDLAND_SERVICES:
      return (
        <FormattedMessage
          id={`ADDLAND_SERVICES`}
          defaultMessage={`Addland Services`}
        />
      )
    case InvoicePublisher.EMPTY:
      return (
        <FormattedMessage id={`EMPTY`} defaultMessage={`Without publisher`} />
      )
    default:
      return null
  }
}
