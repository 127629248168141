import { useDispatch, useSelector } from "react-redux"
import ListAlt from "@material-ui/icons/ListAlt"
import SupervisedUserCircle from "@material-ui/icons/SupervisedUserCircle"
import { useIntl } from "react-intl"
import { resetOrderStatus } from "actions/orderActions"
import React, { CSSProperties, useCallback, useEffect } from "react"
import { useToggle } from "../../hooks/stateHooks"
import EmployeeList from "./EmployeeList"
import OrderList from "./OrderList"
import ToolbarButton from "../../components/ToolbarButton/ToolbarButton"
import { useOrderFetch } from "../../hooks/orderHooks"
import {
  selectHeededConflictIds,
  selectOrderIdsWithError,
} from "../../selectors/orderSelector"
import {
  makeHexSemiTransparent,
  transparentRed,
  transparentOrange,
  yellow,
} from "../../utils/colorUtils"

export default function Orders() {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()
  const [isOrderView, toggleView] = useToggle(true)
  const statuses = useOrderFetch()
  const idsInConflict = useSelector(selectHeededConflictIds)
  const idsWithError = useSelector(selectOrderIdsWithError)

  const styleRowFn = useCallback(
    (id: number, defaultColor: string, isLocked?: boolean): CSSProperties => {
      const color = isLocked ? yellow : defaultColor
      if (idsInConflict.has(id))
        return {
          background: `linear-gradient(90deg, ${transparentRed}, ${
            color ? makeHexSemiTransparent(color) : transparentRed
          })`,
        }
      if (idsWithError.has(id))
        return {
          background: `linear-gradient(90deg, ${transparentOrange}, ${
            color ? makeHexSemiTransparent(color) : transparentOrange
          })`,
        }
      return {
        backgroundColor: color
          ? `${makeHexSemiTransparent(color)}`
          : `transparent`,
      }
    },
    [idsInConflict, idsWithError]
  )

  const toolbar = (
    <ToolbarButton
      icon={isOrderView ? <SupervisedUserCircle /> : <ListAlt />}
      onClick={toggleView}
      title={
        isOrderView
          ? formatMessage({
              id: "showUsers",
              defaultMessage: "Show users",
            })
          : formatMessage({
              id: "showActivities",
              defaultMessage: "Show activities",
            })
      }
    />
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => dispatch(resetOrderStatus()) as unknown as void, [])

  return isOrderView ? (
    <OrderList toolbar={toolbar} statuses={statuses} styleRowFn={styleRowFn} />
  ) : (
    <EmployeeList toolbar={toolbar} styleRowFn={styleRowFn} />
  )
}
