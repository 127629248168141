import React from "react"
import { FormattedMessage } from "react-intl"
import { BonResponsibility } from "../../types/apiTypes"

interface IBonResponsibilityMessageProps {
  id: BonResponsibility
}

export default function BonResponsibilityMessage({
  id,
}: IBonResponsibilityMessageProps) {
  switch (id) {
    case BonResponsibility.BAR:
      return <FormattedMessage id={`BAR`} defaultMessage={`BAR`} />
    case BonResponsibility.COORDINATOR:
      return (
        <FormattedMessage id={`COORDINATOR`} defaultMessage={`COORDINATOR`} />
      )
    case BonResponsibility.HOSTESS:
      return <FormattedMessage id={`HOSTESS`} defaultMessage={`HOSTESS`} />
    case BonResponsibility.SHUTTLE:
      return <FormattedMessage id={`SHUTTLE`} defaultMessage={`SHUTTLE`} />
    default:
      return null
  }
}
