import React, { FormEvent, useState } from "react"
import { useParams, Navigate } from "react-router-dom"
import Box from "@material-ui/core/Box"
import Avatar from "@material-ui/core/Avatar"
import LockOutlinedIcon from "@material-ui/icons/LockOutlined"
import Typography from "@material-ui/core/Typography"
import { FormattedMessage } from "react-intl"
import Input from "../../components/Input/Input"
import { Field } from "../../components/Messages/FieldMessage"
import LoadingButton from "../../components/LoadingButton/LoadingButton"
import { RequestStatus } from "../../types/statusTypes"
import { requestRecoverPassword } from "../../api/interfaceRequests"
import config from "../../config"

export default function RecoverPassword() {
  const { token = "" } = useParams<{ token: string }>()
  const [status, setStatus] = useState(RequestStatus.INITIAL)
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    try {
      setStatus(RequestStatus.REQUESTED)
      await requestRecoverPassword({
        email: e.currentTarget.email.value,
        token,
        password: e.currentTarget.password.value,
      })
      setStatus(RequestStatus.SUCCEEDED)
    } catch {
      setStatus(RequestStatus.SUCCEEDED)
    }
  }
  return (
    <>
      {status === RequestStatus.SUCCEEDED && (
        <Navigate to={config.routes.signIn.path()} />
      )}
      <Box display={"flex"} alignItems={"center"} mb={2}>
        <Box pr={2}>
          <Avatar>
            <LockOutlinedIcon />
          </Avatar>
        </Box>
        <Typography component="h1" variant="h5">
          <FormattedMessage
            id={`createPassword`}
            defaultMessage={`Create password`}
          />
        </Typography>
      </Box>
      <form method={"post"} onSubmit={handleSubmit}>
        <Input name={"email"} messageId={Field.EMAIL} type={"email"} required />
        <Input
          name={"password"}
          messageId={Field.PASSWORD}
          required
          type={"password"}
        />
        <Box mt={2}>
          <LoadingButton
            isLoading={status === RequestStatus.REQUESTED}
            disabled={status === RequestStatus.SUCCEEDED}
            type={"submit"}
            variant={"contained"}
            color={"primary"}
          >
            <FormattedMessage
              id={`createPassword`}
              defaultMessage={`Create password`}
            />
          </LoadingButton>
        </Box>
      </form>
    </>
  )
}
