export enum Source {
  WEB = "WEB",
  AGENCY = "AGENCY",
}

export enum Period {
  THIS_WEEK = "THIS_WEEK",
  LAST_WEEK = "LAST_WEEK",
  NEXT_WEEK = "NEXT_WEEK",
  THIS_MONTH = "THIS_MONTH",
  LAST_MONTH = "LAST_MONTH",
  NEXT_MONTH = "NEXT_MONTH",
  CUSTOM = "CUSTOM",
  TODAY = "TODAY",
  GF_TODAY = "GF_TODAY",
  TOMORROW = "TOMORROW",
}

export enum Availability {
  NA = "NA",
  DAY = "DAY",
  PART = "PART",
}
