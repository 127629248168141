import { FormattedMessage } from "react-intl"
import React, { ChangeEvent, useEffect, useState } from "react"
import Input from "../../components/Input/Input"
import { Field } from "../../components/Messages/FieldMessage"
import Modal from "../../components/Modal/Modal"
import { useSectionsSave } from "../../hooks/reservationHooks"
import EntityList from "../../components/EntityList/EntityList"
import { firstTagId, useTagState } from "../../hooks/tagHooks"
import LanguageTab from "../../components/LanguageTab/LanguageTab"
import { useLanguageState } from "../../hooks/stateHooks"
import MultipleFiledSet from "../../components/MultipleFiledSet/MultipleFiledSet"
import { Entity } from "../../components/Messages/EntityMessage"
import { BonCategory, BonReq, BonResponsibility } from "../../types/apiTypes"
import { idParamToPost, simpleToFullTranslation } from "../../utils/typeUtils"
import Form from "../../components/Form/Form"
import BonCategorySelect from "../../components/BonCategorySelect/BonCategorySelect"
import { GeneralEvent } from "../../utils/inputUtils"
import InlineFieldset from "../../components/InlineFieldset/InlineFieldset"
import { decodeInputName, encodeInputName, uid } from "../../utils/stringUtils"
import { SectionMap } from "../../models/ordersModel"

interface IReservationSectionModalProps {
  onClose: () => void
  isOpen: boolean
  sectionMap: SectionMap
}

type NumberMap = { [key: string]: number }
type BonMap = { [key: string]: Array<BonReq & { id: string }> }

const numberMap: NumberMap = {
  [firstTagId]: 0,
}

const bonMap: BonMap = {
  [firstTagId]: [],
}

const encodeBonInputName = (key: keyof BonReq, id: string) =>
  encodeInputName(key, id)

export default function ReservationSectionModal({
  onClose,
  isOpen,
  sectionMap,
}: IReservationSectionModalProps) {
  const { language, setLanguage } = useLanguageState()
  const [prices, setPrices] = useState<NumberMap>(numberMap)
  const [bons, setBons] = useState<BonMap>(bonMap)
  const [saveSections, saveStatus] = useSectionsSave()
  const {
    tags,
    activeTag,
    handleActiveTagChange,
    titles,
    handleTagTitleChange,
    fillWithData,
  } = useTagState()

  useEffect(() => {
    const sections = sectionMap.valueSeq().toArray()
    fillWithData(
      sectionMap.keySeq().toArray(),
      sections.map(({ translations }) => translations),
      []
    )
    setPrices(
      sections.reduce((acc: NumberMap, { price, id }) => {
        acc[id.toString()] = price
        return acc
      }, {})
    )
    setBons(
      sections.reduce((acc: BonMap, { bons: sectionBons, id }) => {
        acc[id.toString()] = sectionBons.map((bon) => ({
          ...bon,
          id: uid(),
        }))
        return acc
      }, {})
    )
    // eslint-disable-next-line
  }, [sectionMap])

  const handlePriceChange = ({
    currentTarget: { valueAsNumber },
  }: ChangeEvent<HTMLInputElement>) => {
    setPrices((prev) => ({ ...prev, [activeTag]: valueAsNumber }))
  }

  const handleBonChange = ({
    currentTarget: { value, name },
  }: GeneralEvent) => {
    const [key, id] = decodeInputName(name) as [keyof BonReq, string]
    setBons((prev) => ({
      ...prev,
      [activeTag]: prev[activeTag].map((bon) => ({
        ...bon,
        [key]: id === bon.id ? value : bon[key],
      })),
    }))
  }

  const handleBonCreate = () => {
    setBons((prev) => ({
      ...prev,
      [activeTag]: prev[activeTag].concat({
        count: 1,
        content: "",
        category: BonCategory.BEER_OR_DRINK_CARD,
        forNPersons: 1,
        id: uid(),
        responsibility: BonResponsibility.HOSTESS,
      }),
    }))
  }

  const handleBonRemove = (id: string) => {
    setBons((prev) => ({
      ...prev,
      [activeTag]: prev[activeTag].filter((bon) => bon.id !== id),
    }))
  }

  const handleSubmit = async () => {
    await saveSections(
      tags.map((id) => ({
        bons: bons[id]?.map(({ id: omit, ...bon }) => bon) || [],
        id: idParamToPost(id),
        price: prices[id] || 0,
        translations: simpleToFullTranslation([titles[id]], ["title"]),
      }))
    )
  }

  return (
    <Modal open={isOpen} onClose={onClose} maxWidth={"lg"}>
      <Form
        onSubmit={handleSubmit}
        title={
          <FormattedMessage
            id={`manageSections`}
            defaultMessage={`Manage sections`}
          />
        }
        toolbar={<LanguageTab language={language} onChange={setLanguage} />}
        statuses={[saveStatus]}
        useGenericCta
      >
        <EntityList
          entityPairs={tags.map((tag) => [tag, titles[tag][language]])}
          onEntityChange={handleActiveTagChange}
          activeEntity={activeTag}
        >
          <Input
            messageId={Field.TITLE}
            name={`title`}
            onChange={(e) =>
              handleTagTitleChange(e.currentTarget.value, language)
            }
            value={titles[activeTag][language]}
          />
          <Input
            messageId={Field.PRICE}
            name={"price"}
            type={"number"}
            min={0}
            value={prices[activeTag]}
            onChange={handlePriceChange}
          />
          <MultipleFiledSet
            entity={Entity.BON}
            onCreate={handleBonCreate}
            onRemove={handleBonRemove}
            messageId={Field.BONS}
            dense
          >
            {bons?.[activeTag]?.map(
              ({ category, content, count, forNPersons, id }) => (
                <InlineFieldset key={id}>
                  <BonCategorySelect
                    onChange={handleBonChange}
                    dense={false}
                    value={category}
                    name={encodeBonInputName("category", id)}
                  />
                  <Input
                    messageId={Field.COUNT}
                    type={"number"}
                    min={1}
                    value={count}
                    onChange={handleBonChange}
                    name={encodeBonInputName("count", id)}
                  />
                  <Input
                    messageId={Field.CONTENT}
                    value={content}
                    onChange={handleBonChange}
                    name={encodeBonInputName("content", id)}
                  />
                  <Input
                    messageId={Field.FOR_N_PERSONS}
                    type={"number"}
                    min={0}
                    value={forNPersons}
                    onChange={handleBonChange}
                    name={encodeBonInputName("forNPersons", id)}
                  />
                </InlineFieldset>
              )
            )}
          </MultipleFiledSet>
        </EntityList>
      </Form>
    </Modal>
  )
}
