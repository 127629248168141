import React from "react"
import { EditComponentProps } from "@material-table/core"
import { useSelector } from "react-redux"
import { LiteEvent, ReservationDataGrid } from "../../types/globalTypes"
import { Field } from "../../components/Messages/FieldMessage"
import { blankOrderData } from "../../utils/typeUtils"
import ComboBox from "../../components/ComboBox/ComboBox"
import { selectAgencyInputOptions } from "../../selectors/agencySelector"

export default function ReservationAgencyEditCell({
  rowData: { req = blankOrderData },
  onChange,
}: EditComponentProps<ReservationDataGrid>) {
  const inputOptions = useSelector(selectAgencyInputOptions)
  const handleChange = ({ currentTarget: { value } }: LiteEvent) => {
    onChange({ ...req, agencyId: parseFloat(value) })
  }
  return (
    <ComboBox
      messageId={Field.AGENCY}
      onChange={handleChange}
      name={`reservationAgency`}
      value={req.agencyId.toString()}
      options={inputOptions}
      dense
    />
  )
}
