import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RequestStatus } from "../types/statusTypes"
import {
  selectSeating,
  selectSeatingFetchError,
  selectSeatingFetchStatus,
  selectSectionFetchError,
  selectSectionFetchStatus,
  selectSections,
} from "../selectors/orderSelector"
import { fetchSeating, fetchSections } from "../actions/orderActions"
import { SeatingMap, SectionMap } from "../models/ordersModel"
import { SectionReq } from "../types/apiTypes"
import { useFetchHook } from "./fetchHooks"
import { requestSaveSections } from "../api/orderRequest"

export function useSeatingFetch(): [SeatingMap, RequestStatus, string] {
  const dispatch = useDispatch()
  const status = useSelector(selectSeatingFetchStatus)
  const error = useSelector(selectSeatingFetchError)
  const seating = useSelector(selectSeating)
  useEffect(() => {
    dispatch(fetchSeating())
  }, [dispatch])
  return [seating, status, error]
}

export function useSectionFetch(): [SectionMap, RequestStatus, string] {
  const dispatch = useDispatch()
  const status = useSelector(selectSectionFetchStatus)
  const error = useSelector(selectSectionFetchError)
  const sections = useSelector(selectSections)
  useEffect(() => {
    dispatch(fetchSections())
  }, [dispatch])
  return [sections, status, error]
}

export function useSectionsSave(): [
  (sections: SectionReq[]) => Promise<void>,
  RequestStatus,
  string
] {
  const state = useFetchHook()
  return [
    async (sections) => {
      try {
        state.handleRequest()
        await requestSaveSections(state.token, sections)
        state.handleSuccess()
        // eslint-disable-next-line
      } catch (e: any) {
        state.handleFail(e.message)
      } finally {
        state.handleReset()
      }
    },
    state.status,
    state.error,
  ]
}
