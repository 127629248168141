import React, { ReactNode } from "react"
import { FormattedMessage } from "react-intl"
import { Set } from "immutable"
import { UpsaleMap } from "models/upsaleModel"
import {
  AccessRights,
  BonCategory,
  BonReq,
  BonResponsibility,
  Currency,
  EmployeeRole,
  FormField,
  InvoicePublisher,
  InvoiceStatus,
  Language,
  MediaType,
  MediumResAdmin,
  NoteType,
  OrderEmployeeOrderReq,
  OrderEmployeeResAdmin,
  OrderEmployeeStatus,
  OrderReqAdmin,
  OrderResAdmin,
  OrderStatus,
  PackageOptionPriceResAdmin,
  PackageResAdmin,
  PackageType,
  PaymentMethod,
  PriorityReq,
  RequiredRoleResAdmin,
  ReservationReqAdmin,
  Role,
  TranslationReq,
  UpsaleReqAdmin,
} from "../types/apiTypes"
import {
  BonGroup,
  ExtendedOrderReqAdmin,
  InputOption,
  LiteEvent,
  MediaItem,
  SessionStatus,
  TranslationMap,
  TranslationSimpleMap,
} from "../types/globalTypes"
import { Availability, Period, Source } from "../types/filterTypes"
import config from "../config"
import PaymentMethodMessage from "../components/Messages/PaymentMethodMessage"
import OrderStatusMessage from "../components/Messages/OrderStatusMessage"
import InvoicePublisherMessage from "../components/Messages/InvoicePublisherMessage"
import InvoiceStatusMessage from "../components/Messages/InvoiceStatusMessage"
import ActivityTypeMessage from "../components/Messages/ActivityTypeMessage"
import BonCategoryMessage from "../components/Messages/BonCategoryMessage"
import PeriodMessage from "../components/Messages/PeriodMessage"
import SourceMessage from "../components/Messages/SourceMessage"
import SessionStatusMessage from "../components/Messages/SessionStatusMessage"
import AvailabilityMessage from "../components/Messages/AvailabilityMessage"
import RoleMessage from "../components/Messages/RoleMessage"
import FieldMessage, { Field } from "../components/Messages/FieldMessage"
import { ErrorType } from "../components/Messages/ErrorMessage"
import { isValidEmail } from "./validateUtils"
import BonResponsibilityMessage from "../components/Messages/BonResponsibilityMessage"
import BonGroupMessage from "../components/Messages/BonGroupMessage"
import { uid } from "./stringUtils"
import SortTypeMessage from "../components/Messages/SortTypeMessage"
import BoolMessage from "../components/Messages/BoolMessage"
import { Descendant } from "slate"

export function isNotNull<T>(it: T): it is NonNullable<T> {
  return it != null
}

export function createLiteEvent<T>(
  name: string,
  value: T,
  type?: string,
  checked?: boolean
): LiteEvent<T> {
  return {
    currentTarget: {
      name,
      value,
      valueAsNumber:
        typeof value === "string"
          ? parseInt(value, 10)
          : typeof value === "number"
          ? value
          : NaN,
      type: type || `text`,
      checked: checked || false,
    },
  }
}

export const formFields: FormField[] = [
  FormField.PICKUP_ADDRESS,
  FormField.PICKUP_DATETIME,
  FormField.DROPOUT_ADDRESS,
  FormField.DROPOUT_DATETIME,
  FormField.ACTIVITY_ADDRESS,
  FormField.FLIGHT_ARRIVAL_DATETIME,
  FormField.FLIGHT_NUMBER,
  FormField.GUIDE_CONTACT,
  FormField.COORDINATOR_CONTACT,
  FormField.DRIVER_CONTACT,
  FormField.DRIVE_START_DATETIME,
  FormField.RESERVATION_DATETIME,
  FormField.ACTIVITY_START_DATETIME,
]

export const timeFormFields: FormField[] = [
  FormField.ACTIVITY_START_DATETIME,
  FormField.DRIVE_START_DATETIME,
  FormField.DROPOUT_DATETIME,
  FormField.FLIGHT_ARRIVAL_DATETIME,
  FormField.PICKUP_DATETIME,
  FormField.RESERVATION_DATETIME,
]

export const groupFormFields: FormField[] = [
  FormField.FLIGHT_NUMBER,
  FormField.GUIDE_CONTACT,
  FormField.COORDINATOR_CONTACT,
  FormField.DRIVER_CONTACT,
]

export const addressFormFields: FormField[] = [
  FormField.DROPOUT_ADDRESS,
  FormField.PICKUP_ADDRESS,
  FormField.ACTIVITY_ADDRESS,
]

export const timeFormFieldSet = Set<FormField>(timeFormFields)

export const formFieldsInputOptions: InputOption[] = formFields.map(
  (value) => ({
    value,
    content: <FieldMessage id={value} />,
  })
)

export const OPTION_ALL = "ALL"

const inputOptionAll: InputOption = {
  value: OPTION_ALL,
  content: <FieldMessage id={Field.ALL} />,
}

export const paymentMethodsInputOptions = [
  inputOptionAll,
  {
    value: PaymentMethod.INVOICE,
    content: <PaymentMethodMessage id={PaymentMethod.INVOICE} />,
  },
  {
    value: PaymentMethod.CREDIT_CARD,
    content: <PaymentMethodMessage id={PaymentMethod.CREDIT_CARD} />,
  },
  {
    value: PaymentMethod.CASH,
    content: <PaymentMethodMessage id={PaymentMethod.CASH} />,
  },
  {
    value: PaymentMethod.TRANSFER,
    content: <PaymentMethodMessage id={PaymentMethod.TRANSFER} />,
  },
]

export const orderStatusesInputOptions = [
  {
    value: OrderStatus.CREATED,
    content: <OrderStatusMessage id={OrderStatus.CREATED} />,
  },
  {
    value: OrderStatus.APPROVED,
    content: <OrderStatusMessage id={OrderStatus.APPROVED} />,
  },
  {
    value: OrderStatus.CANCELLED,
    content: <OrderStatusMessage id={OrderStatus.CANCELLED} />,
  },
  {
    value: OrderStatus.FULFILLED,
    content: <OrderStatusMessage id={OrderStatus.FULFILLED} />,
  },
]

export const orderStatusesLookupTable = Object.fromEntries(
  orderStatusesInputOptions.map(({ value, content }) => [value, content])
)

export const invoicePublishersInputOptions = [
  {
    value: InvoicePublisher.EMPTY,
    content: <InvoicePublisherMessage id={InvoicePublisher.EMPTY} />,
  },
  {
    value: InvoicePublisher.ADDLAND,
    content: <InvoicePublisherMessage id={InvoicePublisher.ADDLAND} />,
  },
  {
    value: InvoicePublisher.ADDLAND_INCOMING,
    content: <InvoicePublisherMessage id={InvoicePublisher.ADDLAND_INCOMING} />,
  },
  {
    value: InvoicePublisher.ADDLAND_SERVICES,
    content: <InvoicePublisherMessage id={InvoicePublisher.ADDLAND_SERVICES} />,
  },
]

export const sourceInputOptions = [
  inputOptionAll,
  { value: Source.AGENCY, content: <SourceMessage id={Source.AGENCY} /> },
  { value: Source.WEB, content: <SourceMessage id={Source.WEB} /> },
]

export const reservationInputOptions: InputOption[] = [
  inputOptionAll,
  { value: "true", content: <BoolMessage value={true} /> },
  { value: "", content: <BoolMessage value={false} /> },
]

export const sortTypeInputOptions = [
  { value: "desc", content: <SortTypeMessage id={"desc"} /> },
  { value: "asc", content: <SortTypeMessage id={"asc"} /> },
]

export enum VatOption {
  NONE = "NONE",
  REDUCED = "REDUCED",
  STANDARD = "STANDARD",
}

export const getVatRateByOption = (option?: string): number | null =>
  option === VatOption.STANDARD ? 0.21 : option === VatOption.REDUCED ? 0 : null

export const getVatOptionByRate = (rate?: number | null): VatOption =>
  rate === 0.21
    ? VatOption.STANDARD
    : rate === 0
    ? VatOption.REDUCED
    : VatOption.NONE

export const vatRateInputOptions = [
  {
    value: VatOption.NONE,
    content: (
      <FormattedMessage id={"withoutVAT"} defaultMessage={"Without VAT"} />
    ),
  },
  { value: VatOption.REDUCED, content: "0%" },
  { value: VatOption.STANDARD, content: "21%" },
]

export const invoicePaymentInputOptions = [
  {
    value: InvoiceStatus.PAID,
    content: <InvoiceStatusMessage id={InvoiceStatus.PAID} />,
  },
  {
    value: InvoiceStatus.DELVERED,
    content: <InvoiceStatusMessage id={InvoiceStatus.DELVERED} />,
  },
  {
    value: InvoiceStatus.CANCELLED,
    content: <InvoiceStatusMessage id={InvoiceStatus.CANCELLED} />,
  },
  {
    value: InvoiceStatus.ISSUED,
    content: <InvoiceStatusMessage id={InvoiceStatus.ISSUED} />,
  },
]

export const activityTypeInputOptions = [
  {
    value: PackageType.activity,
    content: <ActivityTypeMessage id={PackageType.activity} />,
  },
  {
    value: PackageType.reservation,
    content: <ActivityTypeMessage id={PackageType.reservation} />,
  },
  {
    value: PackageType.shuttle,
    content: <ActivityTypeMessage id={PackageType.shuttle} />,
  },
  {
    value: PackageType.trip,
    content: <ActivityTypeMessage id={PackageType.trip} />,
  },
  {
    value: PackageType.hen,
    content: <ActivityTypeMessage id={PackageType.hen} />,
  },
  {
    value: PackageType.stag,
    content: <ActivityTypeMessage id={PackageType.stag} />,
  },
]

export const upsaleTypeInputOptions = [
  {
    value: PackageType.activity,
    content: <ActivityTypeMessage id={PackageType.activity} />,
  },
  {
    value: PackageType.reservation,
    content: <ActivityTypeMessage id={PackageType.reservation} />,
  },
  {
    value: PackageType.shuttle,
    content: <ActivityTypeMessage id={PackageType.shuttle} />,
  },
  {
    value: PackageType.trip,
    content: <ActivityTypeMessage id={PackageType.trip} />,
  },
  {
    value: PackageType.hen,
    content: <ActivityTypeMessage id={PackageType.hen} />,
  },
  {
    value: PackageType.stag,
    content: <ActivityTypeMessage id={PackageType.stag} />,
  },
]

export const bonGroupOptions = [
  {
    value: BonGroup.TOKEN,
    content: <BonGroupMessage id={BonGroup.TOKEN} />,
  },
  {
    value: BonGroup.DRINKS,
    content: <BonGroupMessage id={BonGroup.DRINKS} />,
  },
  {
    value: BonGroup.OTHER,
    content: <BonGroupMessage id={BonGroup.OTHER} />,
  },
  {
    value: BonGroup.TIPPING_DOLLAR,
    content: <BonGroupMessage id={BonGroup.TIPPING_DOLLAR} />,
  },
  {
    value: BonGroup.BEER_CARD,
    content: <BonGroupMessage id={BonGroup.BEER_CARD} />,
  },
  {
    value: BonGroup.T_SHIRT,
    content: <BonGroupMessage id={BonGroup.T_SHIRT} />,
  },
]

export const bonCategoryInputOptions = [
  [
    {
      value: BonCategory.TABLE_DANCE_2000,
      content: <BonCategoryMessage id={BonCategory.TABLE_DANCE_2000} />,
    },
    {
      value: BonCategory.TOPLESS_TABLE_DANCE_1000,
      content: <BonCategoryMessage id={BonCategory.TOPLESS_TABLE_DANCE_1000} />,
    },
    {
      value: BonCategory.DANCE_1500,
      content: <BonCategoryMessage id={BonCategory.DANCE_1500} />,
    },
    {
      value: BonCategory.AUTOEROTIC_SHOW_3000,
      content: <BonCategoryMessage id={BonCategory.AUTOEROTIC_SHOW_3000} />,
    },
    {
      value: BonCategory.STAG_SHOW_2500,
      content: <BonCategoryMessage id={BonCategory.STAG_SHOW_2500} />,
    },
    {
      value: BonCategory.VIP_TABLE_DANCE_SPECIAL_3000,
      content: (
        <BonCategoryMessage id={BonCategory.VIP_TABLE_DANCE_SPECIAL_3000} />
      ),
    },
    {
      value: BonCategory.CHR_15,
      content: <BonCategoryMessage id={BonCategory.CHR_15} />,
    },
    {
      value: BonCategory.CHR_30,
      content: <BonCategoryMessage id={BonCategory.CHR_30} />,
    },
    {
      value: BonCategory.CHR_60,
      content: <BonCategoryMessage id={BonCategory.CHR_60} />,
    },
    {
      value: BonCategory.MOET_CHR_30,
      content: <BonCategoryMessage id={BonCategory.MOET_CHR_30} />,
    },
    {
      value: BonCategory.MOET_CHR_60,
      content: <BonCategoryMessage id={BonCategory.MOET_CHR_60} />,
    },
  ],
  [
    {
      value: BonCategory.BOTTLE_ALCOHOL_0_7,
      content: <BonCategoryMessage id={BonCategory.BOTTLE_ALCOHOL_0_7} />,
    },
    {
      value: BonCategory.BOTTLE_ALCOHOL_1_0,
      content: <BonCategoryMessage id={BonCategory.BOTTLE_ALCOHOL_1_0} />,
    },
    {
      value: BonCategory.BOTTLE_ALCOHOL_0_7_PREMIUM_BRANDS,
      content: (
        <BonCategoryMessage
          id={BonCategory.BOTTLE_ALCOHOL_0_7_PREMIUM_BRANDS}
        />
      ),
    },
    {
      value: BonCategory.BOTTLE_ALCOHOL_1_0_PREMIUM_BRANDS,
      content: (
        <BonCategoryMessage
          id={BonCategory.BOTTLE_ALCOHOL_1_0_PREMIUM_BRANDS}
        />
      ),
    },
    {
      value: BonCategory.BOTTLE_ALCOHOL_0_7_MOET_BRUT,
      content: (
        <BonCategoryMessage id={BonCategory.BOTTLE_ALCOHOL_0_7_MOET_BRUT} />
      ),
    },
    {
      value: BonCategory.NONALCOHOLIC_DRINKS_5,
      content: <BonCategoryMessage id={BonCategory.NONALCOHOLIC_DRINKS_5} />,
    },
    {
      value: BonCategory.TAP_BEERS_10,
      content: <BonCategoryMessage id={BonCategory.TAP_BEERS_10} />,
    },
    {
      value: BonCategory.WELCOME_DRINK,
      content: <BonCategoryMessage id={BonCategory.WELCOME_DRINK} />,
    },
    {
      value: BonCategory.BOTTLE_BOHEMIA,
      content: <BonCategoryMessage id={BonCategory.BOTTLE_BOHEMIA} />,
    },
    {
      value: BonCategory.BEER_CAN,
      content: <BonCategoryMessage id={BonCategory.BEER_CAN} />,
    },
    {
      value: BonCategory.OPEN_CONSUMATION_1000,
      content: <BonCategoryMessage id={BonCategory.OPEN_CONSUMATION_1000} />,
    },
    {
      value: BonCategory.OLMECA_SHOT,
      content: <BonCategoryMessage id={BonCategory.OLMECA_SHOT} />,
    },
    {
      value: BonCategory.CORONA_EXTRA,
      content: <BonCategoryMessage id={BonCategory.CORONA_EXTRA} />,
    },
  ],
  [
    {
      value: BonCategory.GIFT_BOA,
      content: <BonCategoryMessage id={BonCategory.GIFT_BOA} />,
    },
    {
      value: BonCategory.SHORTS_FIGHT_WITH_STAG,
      content: <BonCategoryMessage id={BonCategory.SHORTS_FIGHT_WITH_STAG} />,
    },
    {
      value: BonCategory.OTHER,
      content: <BonCategoryMessage id={BonCategory.OTHER} />,
    },
    {
      value: BonCategory.BRACELET_DOUBLETIME,
      content: <BonCategoryMessage id={BonCategory.BRACELET_DOUBLETIME} />,
    },
    {
      value: BonCategory.BRACELET_DRINKS,
      content: <BonCategoryMessage id={BonCategory.BRACELET_DRINKS} />,
    },
    {
      value: BonCategory.UNLIMITED_BEER_FRIEND,
      content: <BonCategoryMessage id={BonCategory.UNLIMITED_BEER_FRIEND} />,
    },
  ],
  [
    {
      value: BonCategory.TIPPING_DOLLARS,
      content: <BonCategoryMessage id={BonCategory.TIPPING_DOLLARS} />,
    },
  ],
  [
    {
      value: BonCategory.BEER_OR_DRINK_CARD,
      content: <BonCategoryMessage id={BonCategory.BEER_OR_DRINK_CARD} />,
    },
  ],
  [
    {
      value: BonCategory.SHIRT_FRIENDS,
      content: <BonCategoryMessage id={BonCategory.SHIRT_FRIENDS} />,
    },
    {
      value: BonCategory.SHIRT_GROOM,
      content: <BonCategoryMessage id={BonCategory.SHIRT_GROOM} />,
    },
  ],
]

export const periodInputOptions = [
  {
    value: Period.THIS_WEEK,
    content: <PeriodMessage id={Period.THIS_WEEK} />,
  },
  {
    value: Period.LAST_WEEK,
    content: <PeriodMessage id={Period.LAST_WEEK} />,
  },
  {
    value: Period.NEXT_WEEK,
    content: <PeriodMessage id={Period.NEXT_WEEK} />,
  },
  {
    value: Period.THIS_MONTH,
    content: <PeriodMessage id={Period.THIS_MONTH} />,
  },
  {
    value: Period.LAST_MONTH,
    content: <PeriodMessage id={Period.LAST_MONTH} />,
  },
  {
    value: Period.NEXT_MONTH,
    content: <PeriodMessage id={Period.NEXT_MONTH} />,
  },
  {
    value: Period.TODAY,
    content: <PeriodMessage id={Period.TODAY} />,
  },
  {
    value: Period.GF_TODAY,
    content: <PeriodMessage id={Period.GF_TODAY} />,
  },
  {
    value: Period.TOMORROW,
    content: <PeriodMessage id={Period.TOMORROW} />,
  },
  {
    value: Period.CUSTOM,
    content: <PeriodMessage id={Period.CUSTOM} />,
  },
]

export const bonResponsibilityOptions = [
  {
    value: BonResponsibility.BAR,
    content: <BonResponsibilityMessage id={BonResponsibility.BAR} />,
  },
  {
    value: BonResponsibility.SHUTTLE,
    content: <BonResponsibilityMessage id={BonResponsibility.SHUTTLE} />,
  },
  {
    value: BonResponsibility.HOSTESS,
    content: <BonResponsibilityMessage id={BonResponsibility.HOSTESS} />,
  },
  {
    value: BonResponsibility.COORDINATOR,
    content: <BonResponsibilityMessage id={BonResponsibility.COORDINATOR} />,
  },
]

export const sessionStatusOptions = [
  {
    value: SessionStatus.ACTIVE,
    content: <SessionStatusMessage id={SessionStatus.ACTIVE} />,
  },
  {
    value: SessionStatus.ENDED,
    content: <SessionStatusMessage id={SessionStatus.ENDED} />,
  },
]

export const agendaInputOptions = [
  {
    value: Availability.NA,
    content: <AvailabilityMessage id={Availability.NA} />,
  },
  {
    value: Availability.DAY,
    content: <AvailabilityMessage id={Availability.DAY} />,
  },
  {
    value: Availability.PART,
    content: <AvailabilityMessage id={Availability.PART} />,
  },
]

export const employeeRoleInputOptions: Array<{
  value: EmployeeRole
  content: ReactNode
}> = [
  {
    value: "CoordinatorInternal",
    content: <RoleMessage id={"CoordinatorInternal"} />,
  },
  {
    value: "CoordinatorExternal",
    content: <RoleMessage id={"CoordinatorExternal"} />,
  },
  {
    value: "Dancer",
    content: <RoleMessage id={"Dancer"} />,
  },
  {
    value: "DancerMale",
    content: <RoleMessage id={"DancerMale"} />,
  },
  {
    value: "DancerToken",
    content: <RoleMessage id={"DancerToken"} />,
  },
  {
    value: "DancerXXL",
    content: <RoleMessage id={"DancerXXL"} />,
  },
  {
    value: "DancerClub",
    content: <RoleMessage id={"DancerClub"} />,
  },
  {
    value: "Driver",
    content: <RoleMessage id={"Driver"} />,
  },
  {
    value: "Security",
    content: <RoleMessage id={"Security"} />,
  },
  {
    value: "Steward",
    content: <RoleMessage id={"Steward"} />,
  },
  {
    value: "Other",
    content: <RoleMessage id={"Other"} />,
  },
]

export const roleInputOptions: Array<{ value: Role; content: ReactNode }> = [
  {
    value: "SuperAdmin",
    content: <RoleMessage id={"SuperAdmin"} />,
  },
  {
    value: "Admin",
    content: <RoleMessage id={"Admin"} />,
  },
  {
    value: "Reservations",
    content: <RoleMessage id={"Reservations"} />,
  },
  {
    value: "Agency",
    content: <RoleMessage id={"Agency"} />,
  },
  {
    value: "Hostess",
    content: <RoleMessage id={"Hostess"} />,
  },
  {
    value: "Manager",
    content: <RoleMessage id={"Manager"} />,
  },
  {
    value: "DanceManager",
    content: <RoleMessage id={"DanceManager"} />,
  },
]

export const blankTranslation: TranslationReq = {
  additionalInfo: "",
  content: "",
  keyword: "",
  metaData: "",
  title: "",
}

export type RichTextTranslations = { [key in Language]: Descendant[] }

export const initialRichText: Descendant[] = [{
  type: "paragraph",
  children: [{ text: "" }],
}]

export const blankRichTextTranslations: RichTextTranslations = {
  [Language.FR]: initialRichText,
  [Language.EN]: initialRichText,
  [Language.CS]: initialRichText,
  [Language.DE]: initialRichText,
}

export const createPrice = (
  crownPrice?: string
): { [key in Currency]: number } => {
  const price = crownPrice ? parseFloat(crownPrice) : 0
  return {
    [Currency.CZK]: price,
    [Currency.EUR]: price > 0 ? Math.round(price / 26) : 0,
  }
}

export const blankTranslations: TranslationMap = {
  [Language.FR]: { ...blankTranslation },
  [Language.EN]: { ...blankTranslation },
  [Language.CS]: { ...blankTranslation },
  [Language.DE]: { ...blankTranslation },
}

export const blankSingleTranslations: TranslationSimpleMap = {
  [Language.FR]: "",
  [Language.EN]: "",
  [Language.CS]: "",
  [Language.DE]: "",
}

export const forbiddenAccessRights: AccessRights = {
  adminCreator: "N",
  agency: "N",
  agencySelf: "N",
  invoice: "N",
  order: "N",
  pack: "N",
  page: "N",
  product: "N",
  resource: "N",
  section: "N",
  session: "N",
  sessionAdmin: "N",
  user: "N",
  userAvailability: "N",
  orderAdmin: "N",
  orderCosts: "N",
  orderEmployee: "N",
  orderPayment: "N",
  orderPrice: "N",
  orderEmployeeReward: "N",
  packagePricesAgency: "N",
  packagePricesWeb: "N",
  uiAgency: "N",
  uiInvoice: "N",
  uiOrderEmployee: "N",
  uiOrderList: "N",
  uiPackage: "N",
  uiPages: "N",
  uiUserRoles: "N",
  uiVoucher: "N",
  uiOrderFilter: "N",
  uiReservation: "N",
  uiReport: "N",
}

export const languageList: Language[] = [
  Language.CS,
  Language.EN,
  Language.DE,
  Language.FR,
]

export function simpleToFullTranslation(
  simpleTranslations: TranslationSimpleMap[],
  keys: Array<keyof TranslationReq>
): TranslationMap {
  return Object.fromEntries(
    languageList.map((language): [Language, TranslationReq] => [
      language,
      {
        ...blankTranslation,
        ...Object.fromEntries(
          keys.map((key, index) => [
            key,
            simpleTranslations?.[index]?.[language] || "",
          ])
        ),
      },
    ])
  ) as TranslationMap
}

export function fullToSimpleTranslation(
  full: TranslationMap,
  key: keyof TranslationReq
): TranslationSimpleMap {
  return {
    [Language.FR]: full[Language.FR][key] || "",
    [Language.EN]: full[Language.EN][key] || "",
    [Language.CS]: full[Language.CS][key] || "",
    [Language.DE]: full[Language.DE][key] || "",
  }
}

export function richToSimpleTranslation(
  rich: RichTextTranslations
): TranslationSimpleMap {
  return {
    [Language.FR]: JSON.stringify(rich[Language.FR]),
    [Language.EN]: JSON.stringify(rich[Language.EN]),
    [Language.CS]: JSON.stringify(rich[Language.CS]),
    [Language.DE]: JSON.stringify(rich[Language.DE]),
  }
}

export const blankContactInfo: { [key in FormField]: string } = {
  [FormField.PICKUP_DATETIME]: "",
  [FormField.PICKUP_ADDRESS]: "",
  [FormField.DROPOUT_ADDRESS]: "",
  [FormField.ACTIVITY_ADDRESS]: "",
  [FormField.COORDINATOR_CONTACT]: "",
  [FormField.DRIVER_CONTACT]: "",
  [FormField.FLIGHT_ARRIVAL_DATETIME]: "",
  [FormField.DRIVE_START_DATETIME]: "",
  [FormField.FLIGHT_NUMBER]: "",
  [FormField.GUIDE_CONTACT]: "",
  [FormField.DROPOUT_DATETIME]: "",
  [FormField.RESERVATION_DATETIME]: "",
  [FormField.ACTIVITY_START_DATETIME]: "",
}

export const blankOrderData: ExtendedOrderReqAdmin = {
  agencyId: config.agencyIds.web,
  balanceHolderId: null,
  balance: 0,
  bons: [],
  cancellation: null,
  cost: 0,
  currency: Currency.CZK,
  deposit: 0,
  email: "",
  id: 0,
  isPaidDeposit: false,
  isPaidRest: false,
  isLocked: false,
  language: Language.EN,
  name: "",
  notes: {
    [NoteType.NOTE_ADMIN]: "",
    [NoteType.NOTE_EMPLOYEE]: "",
    [NoteType.NOTE_OPEN]: "",
    [NoteType.NOTE_EXTRA]: "",
    [NoteType.COLOR]: "",
  },
  overrideInvoicePublisher: null,
  overrideVatRate: null,
  packageOrder: null,
  paymentMethod: PaymentMethod.CASH,
  paymentMethodDeposit: PaymentMethod.CASH,
  phone: "",
  price: 0,
  reservation: null,
  status: OrderStatus.CREATED,
  upsales: [],
  voucherId: null,
  packageId: 0,
  bonSum: [],
  orderEmployees: [],
}

export const blankMedium: MediumResAdmin = {
  translations: blankTranslations,
  type: MediaType.IMAGE,
  url: "",
}

export const createBlankOptionPrice = (
  packageOptionId: number
): PackageOptionPriceResAdmin => ({
  agencyId: config.agencyIds.web,
  packageOptionId,
  feeOver10Persons: 0,
  deposit: 0,
  price: 0,
  pricePerNCapitas: {
    numberOfPersons: 1,
    price: 0,
  },
})

export function isNumeric(num: string) {
  // eslint-disable-next-line no-restricted-globals
  return !isNaN(Number(num))
}

export function isSourceVideo(source: string | File) {
  return typeof source === "string" && source.includes("vimeo.com")
}

export function isMediaItemVideo(medium: MediaItem) {
  return isSourceVideo(medium.source)
}

export type ValidationError = [Field, ErrorType, string]

export function getOrderValidationErrors(
  req: OrderReqAdmin,
  upsaleMap: UpsaleMap,
  lang: Language,
  packageData?: PackageResAdmin
): ValidationError[] {
  return [
    req.agencyId ? null : [Field.SOURCE, ErrorType.REQUIRED],
    // req.packageOrder?.optionId ? null : [Field.ACTIVITY, ErrorType.REQUIRED],
    // req.dtStart ? null : [Field.DATE, ErrorType.REQUIRED],
    req.name ? null : [Field.NAME, ErrorType.REQUIRED],
    req.phone ? null : [Field.PHONE, ErrorType.REQUIRED],
    // req.numberOfPersons >= 1
    //   ? null
    //   : [Field.NUMBER_OF_PERSONS, ErrorType.LOWER_MINIMUM], // numberOfPersons,
    !req.email
      ? [Field.EMAIL, ErrorType.REQUIRED]
      : isValidEmail(req.email)
      ? null
      : [Field.EMAIL, ErrorType.INVALID_PATTERN],
    packageData?.containsReservation &&
    (req.reservation === null || req.reservation.dtStart === 0)
      ? [Field.CONTAINS_RESERVATION, ErrorType.SHOULD_CONTAIN_RESERVATION]
      : null,
    packageData &&
    !packageData.requiredUpsaleIds.every((group) =>
      group.some((upsaleId) =>
        req.upsales.find(({ packageUpsaleId }) => packageUpsaleId === upsaleId)
      )
    )
      ? [Field.REQUIRED_UPSALES, ErrorType.MISSING_REQUIRED_UPSALE]
      : null,
    ...(packageData
      ? formFields.map((field) =>
          packageData.options
            .find((option) => option.id === req.packageOrder?.optionId)
            ?.requiredFields?.includes(field) &&
          !req.packageOrder?.contactInfo[field]
            ? [field, ErrorType.REQUIRED]
            : null
        )
      : []),

    // Checking upsale required fields are filled.
    ...req.upsales
      .map(({ packageUpsaleId, contactInfo }) => {
        const { requiredFields, translations } =
          upsaleMap.get(packageUpsaleId.toString()) || {}
        if (!requiredFields || !translations) {
          return null
        }
        return requiredFields
          .map((field) =>
            !contactInfo[field]
              ? [field, ErrorType.REQUIRED, translations[lang].title]
              : null
          )
          .filter((item) => item)
      })
      .flat(),
  ].filter(isNotNull) as ValidationError[]
}

export function getReservationValidationErrors(
  req: OrderReqAdmin
): ValidationError[] {
  // reservation shouldn't be undefined thanks to filters on page.
  const reservation = req.reservation as ReservationReqAdmin
  return [
    req.name ? null : [Field.NAME, ErrorType.REQUIRED],
    req.email
      ? isValidEmail(req.email)
        ? null
        : [Field.EMAIL, ErrorType.INVALID_EMAIL]
      : [Field.EMAIL, ErrorType.REQUIRED],
    req.agencyId ? null : [Field.SOURCE, ErrorType.REQUIRED],
    reservation.dtStart ? null : [Field.DATE, ErrorType.REQUIRED],
    reservation.numberOfPersons >= 1
      ? null
      : [Field.NUMBER_OF_PERSONS, ErrorType.LOWER_MINIMUM],
    reservation.seatingIds.length > 0
      ? null
      : [Field.TABLE_NUMBER, ErrorType.REQUIRED], // seating
  ].filter(isNotNull) as ValidationError[]
}

export function idParamToPost(id?: string): number {
  return id ? parseFloat(id) : 0
}

// 0:Token 1:Drinks 2:Other 3:Tipping dollar 4:Beer card 5:T-shirt
export const bonCategoryGroupMap: { [key: string]: number } = {
  TABLE_DANCE_2000: 0,
  TOPLESS_TABLE_DANCE_1000: 0,
  DANCE_1500: 0,
  AUTOEROTIC_SHOW_3000: 0,
  STAG_SHOW_2500: 0,
  VIP_TABLE_DANCE_SPECIAL_3000: 0,
  CHR_15: 0,
  CHR_30: 0,
  CHR_60: 0,
  MOET_CHR_30: 0,
  MOET_CHR_60: 0,
  BOTTLE_ALCOHOL_0_7: 1,
  BOTTLE_ALCOHOL_1_0: 1,
  BOTTLE_ALCOHOL_0_7_PREMIUM_BRANDS: 1,
  BOTTLE_ALCOHOL_1_0_PREMIUM_BRANDS: 1,
  BOTTLE_ALCOHOL_0_7_MOET_BRUT: 1,
  NONALCOHOLIC_DRINKS_5: 1,
  TAP_BEERS_10: 1,
  WELCOME_DRINK: 1,
  BOTTLE_BOHEMIA: 1,
  BEER_CAN: 1,
  OPEN_CONSUMATION_1000: 1,
  OLMECA_SHOT: 1,
  CORONA_EXTRA: 1,
  GIFT_BOA: 2,
  SHORTS_FIGHT_WITH_STAG: 2,
  OTHER: 2,
  BRACELET_DOUBLETIME: 2,
  BRACELET_DRINKS: 2,
  UNLIMITED_BEER_FRIEND: 2,
  TIPPING_DOLLARS: 3,
  BEER_OR_DRINK_CARD: 4,
  SHIRT_GROOM: 5,
  SHIRT_FRIENDS: 5,
}

export const employeeSet = Set<Role>([
  "Dancer",
  "DancerClub",
  "DancerToken",
  "DancerMale",
  "DancerXXL",
  "Driver",
  "Steward",
  "CoordinatorInternal",
  "CoordinatorExternal",
  "Security",
  "Other",
])

export function employeeResToReq({
  specification,
  upsaleId,
  orderId,
  packageOptionId,
  ...employee
}: OrderEmployeeResAdmin): OrderEmployeeOrderReq {
  return employee
}

export function orderResToOrderReq(order: OrderResAdmin): OrderReqAdmin {
  return {
    balance: order.balance,
    balanceHolderId: order.balanceHolder?.id || null,
    agencyId: order.agencyId,
    bons: order.bons,
    cancellation: order.cancellation,
    cost: order.cost,
    currency: order.currency,
    deposit: order.deposit,
    email: order.email,
    id: order.id,
    isPaidDeposit: order.isPaidDeposit,
    isPaidRest: order.isPaidRest,
    language: order.language,
    name: order.name,
    notes: order.notes,
    overrideInvoicePublisher: order.overrideInvoicePublisher,
    overrideVatRate: order.overrideVatRate,
    paymentMethod: order.paymentMethod,
    paymentMethodDeposit: order.paymentMethodDeposit,
    phone: order.phone,
    price: order.price,
    isLocked: order.isLocked,
    status: order.status,
    voucherId: order.voucherId,
    orderEmployees: order.orderEmployees.map(employeeResToReq),
    packageOrder: order.packageOrder
      ? {
          contactInfo: order.packageOrder.contactInfo,
          dtStart: order.packageOrder.dtStart,
          optionId: order.packageOrder.option.id,
          numberOfPersons: order.packageOrder.numberOfPersons,
          orderEmployees:
            order.packageOrder.orderEmployees.map(employeeResToReq),
        }
      : null,
    reservation: order.reservation
      ? {
          contactInfo: order.reservation.contactInfo,
          dtStart: order.reservation.dtStart,
          numberOfPersons: order.reservation.numberOfPersons,
          seatingIds: order.reservation.reservation.map(
            ({ seating: { id } }) => id
          ),
        }
      : null,
    upsales: order.upsales.map(
      ({
        upsaleOption,
        contactInfo,
        dtStart,
        numberOfPersons,
        quantity,
        uuid,
        orderEmployees,
      }): UpsaleReqAdmin => ({
        contactInfo,
        dtStart,
        numberOfPersons,
        packageUpsaleId: upsaleOption.id,
        quantity,
        uuid,
        orderEmployees: orderEmployees.map(employeeResToReq),
      })
    ),
  }
}

export const sampleBon: BonReq = {
  category: BonCategory.BOTTLE_ALCOHOL_0_7,
  content: "",
  count: 1,
  forNPersons: 1,
  responsibility: BonResponsibility.BAR,
}

export function listToPriority(
  list?: Array<string | number | null | undefined>,
  fixedIndex?: number
): PriorityReq[] {
  if (!list) return []
  return list
    .filter((i): i is number | string => !!i)
    .map((i, idx) => ({
      id: typeof i === "number" ? i : parseInt(i),
      priority: fixedIndex === undefined ? idx : fixedIndex,
    }))
}

export function priorityToRes(priority?: PriorityReq[]): number[] {
  if (!priority) return []
  return priority.sort((a, b) => b.priority - a.priority).map(({ id }) => id)
}

export const blankRequiredRole: RequiredRoleResAdmin = {
  durationActual: 30 * 60 * 1000, // 30 minutes
  durationAfter: 0,
  durationBefore: 0,
  note: "",
  offset: 0,
  reward: 0,
  role: "Other",
}

export function roleToOrderEmployee(dtStart?: number) {
  if (!dtStart) throw new Error("Invalid dtStart")
  return (role: RequiredRoleResAdmin): OrderEmployeeOrderReq => {
    const start = dtStart + (role.offset - role.durationBefore)
    const end = start + role.durationActual + role.durationAfter
    return {
      dtStart: start,
      dtEnd: end,
      id: 0,
      userId: null,
      note: role.note,
      reward: role.reward,
      role: role.role,
      status: OrderEmployeeStatus.PENDING,
      uuid: uid(),
    }
  }
}

export function getAllOrderResEmployees(
  order: OrderResAdmin
): OrderEmployeeResAdmin[] {
  return [
    ...(order.packageOrder?.orderEmployees || []),
    ...order.upsales.flatMap((upsale) => upsale.orderEmployees),
    ...order.orderEmployees,
  ]
}

export function getAllOrderReqEmployees(
  order: OrderReqAdmin
): OrderEmployeeOrderReq[] {
  return [
    ...(order.packageOrder?.orderEmployees || []),
    ...order.upsales.flatMap((upsale) => upsale.orderEmployees),
    ...order.orderEmployees,
  ]
}
