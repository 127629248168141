import React from "react"
import { FormattedMessage } from "react-intl"

interface ISummaryMessageProps {
  id: string
}

export default function SummaryMessage({ id }: ISummaryMessageProps) {
  switch (id) {
    case "vouchers":
      return <FormattedMessage id={"vouchers"} defaultMessage={`Vouchers`} />
    case "guests":
      return <FormattedMessage id={"guests"} defaultMessage={`Guests`} />
    case "surcharge":
      return <FormattedMessage id={"surcharge"} defaultMessage={`Surcharge`} />
    case "turnover":
      return <FormattedMessage id={"turnover"} defaultMessage={`Turnover`} />
    case "activities":
      return (
        <FormattedMessage id={"activities"} defaultMessage={`Activities`} />
      )
    case "staffCosts":
      return (
        <FormattedMessage id={"staffCosts"} defaultMessage={`Staff Costs`} />
      )
    case "externalCosts":
      return (
        <FormattedMessage
          id={"externalCosts"}
          defaultMessage={`External Costs`}
        />
      )
    case "profit":
      return <FormattedMessage id={"profit"} defaultMessage={`Profit`} />
    case "reward":
      return <FormattedMessage id={"reward"} defaultMessage={`Reward`} />
    case "price":
      return <FormattedMessage id={"price"} defaultMessage={`Price`} />
    case "bons":
      return <FormattedMessage id={"bons"} defaultMessage={`Bons`} />
    default:
      return null
  }
}
