import { makeStyles, Theme } from "@material-ui/core"

export default makeStyles((theme: Theme) => ({
  root: {
    "& > *": {
      display: `block`,
    },
    "& > * + *": {
      marginTop: theme.spacing(1),
    },
  },
}))
