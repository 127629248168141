import React from "react"
import { SortType } from "../../types/globalTypes"
import { FormattedMessage } from "react-intl"

interface ISortTypeMessageProps {
  id: SortType
}

export default function SortTypeMessage({ id }: ISortTypeMessageProps) {
  switch (id) {
    case "desc":
      return <FormattedMessage id={`desc`} defaultMessage={`Descending`} />
    case "asc":
      return <FormattedMessage id={`asc`} defaultMessage={`Ascending`} />
    default:
      return null
  }
}
