import React from "react"
import { FormattedMessage } from "react-intl"

export enum ErrorType {
  INVALID_PATTERN = "invalidPattern",
  INVALID_EMAIL = "invalidEmail",
  REQUIRED = "required",
  SHOULD_FILL = "shouldFill",
  UNAUTHORIZED = "unauthorized",
  INVALID_VOUCHER = "invalidVoucher",
  LOWER_MINIMUM = "lowerMinimum",
  SHOULD_CONTAIN_RESERVATION = "shouldContainReservation",
  MISSING_REQUIRED_UPSALE = "missingRequiredUpsale",
}

interface IErrorMessageProps {
  id: ErrorType | null
}

export default function ErrorMessage({ id }: IErrorMessageProps) {
  if (!id) return null
  switch (id) {
    case ErrorType.MISSING_REQUIRED_UPSALE:
      return (
        <FormattedMessage
          id={`missingRequiredUpsale`}
          defaultMessage={`Required upsale isn't selected`}
        />
      )
    case ErrorType.SHOULD_CONTAIN_RESERVATION:
      return (
        <FormattedMessage
          id={`shouldContainReservation`}
          defaultMessage={`Your order contains resevation. Please book a table.`}
        />
      )
    case ErrorType.LOWER_MINIMUM:
      return (
        <FormattedMessage
          id={`lowerMinimum`}
          defaultMessage={`Value is lower than minimum`}
        />
      )
    case ErrorType.INVALID_EMAIL:
      return (
        <FormattedMessage
          id={`invalidEmail`}
          defaultMessage={`Invalid email`}
        />
      )
    case ErrorType.INVALID_PATTERN:
      return (
        <FormattedMessage
          id={`invalidPattern`}
          defaultMessage={`Invalid pattern`}
        />
      )
    case ErrorType.REQUIRED:
      return (
        <FormattedMessage
          id={`required`}
          defaultMessage={`This field is required`}
        />
      )
    case ErrorType.SHOULD_FILL:
      return (
        <FormattedMessage
          id={`shouldFill`}
          defaultMessage={`This field should be filled`}
        />
      )
    case ErrorType.UNAUTHORIZED:
      return (
        <FormattedMessage
          id={`unauthorized`}
          defaultMessage={`Invalid credentials`}
        />
      )
    case ErrorType.INVALID_VOUCHER:
      return (
        <FormattedMessage
          id={`invalidVoucher`}
          defaultMessage={`Invalid voucher`}
        />
      )
    default:
      return <>Missing translation for {id}</>
  }
}
