import { makeStyles, Theme } from "@material-ui/core"

interface Props {
  isError: boolean
  isSuccess: boolean
  isWarning: boolean
}

export default makeStyles((theme: Theme) => ({
  root: {
    fontSize: theme.typography.h2.fontSize,
    textAlign: "center",
    textTransform: "uppercase",
    fontWeight: "bold",
    letterSpacing: 2,
    padding: theme.spacing(2),
    background: ({ isError, isSuccess, isWarning }: Props) =>
      isError
        ? theme.palette.error.main
        : isWarning
        ? theme.palette.warning.main
        : isSuccess
        ? theme.palette.success.main
        : "transparent",
  },
}))
