import {
  CareerApplicationReq,
  CareerApplicationResAdmin,
} from "../types/apiTypes"
import performRequest, { getIdParam, getIdsParam } from "../utils/apiUtils"
import { HttpMethods } from "../types/globalTypes"

export async function requestCareers(
  basicAuth: string,
  ids: number[] = []
): Promise<CareerApplicationResAdmin[]> {
  return performRequest({
    endpoint: `careerApplication${getIdsParam(ids)}`,
    auth: basicAuth,
    method: HttpMethods.GET,
  })
}

export async function requestSaveCareers(
  basicAuth: string,
  formData: CareerApplicationReq,
  id?: number
): Promise<CareerApplicationReq> {
  return performRequest({
    endpoint: `careerApplication${getIdParam(id)}`,
    auth: basicAuth,
    body: formData,
    method: id ? HttpMethods.PUT : HttpMethods.POST,
  })
}

export function requestDeleteCareer(
  basicAuth: string,
  id: number
): Promise<number> {
  return performRequest({
    endpoint: `careerApplication${getIdParam(id)}`,
    auth: basicAuth,
    method: HttpMethods.DELETE,
  })
}
