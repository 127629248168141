import { Record, RecordOf, Map } from "immutable"
import { normalize, schema } from "normalizr"
import { RequestStatus } from "../types/statusTypes"
import { InvoiceResAdmin } from "../types/apiTypes"

export type InvoiceMap = Map<string, InvoiceResAdmin>

export function normalizeInvoice(data: InvoiceResAdmin[]): InvoiceMap {
  const agenciesSchema = [new schema.Entity("agencies")]
  const {
    entities: { agencies },
  } = normalize<
    InvoiceResAdmin,
    { [key: string]: { [key: string]: InvoiceResAdmin } }
  >(data, agenciesSchema)
  return Map(agencies)
}

const defaultStatuses = {
  fetchInvoices: RequestStatus.INITIAL,
}

export const makeStatuses: Record.Factory<typeof defaultStatuses> = Record(
  defaultStatuses
)

const defaultErrors = {
  fetchInvoice: "",
}

export const makeErrors: Record.Factory<typeof defaultErrors> = Record(
  defaultErrors
)

type InvoiceStateType = {
  statuses: RecordOf<typeof defaultStatuses>
  errors: RecordOf<typeof defaultErrors>
  records: InvoiceMap
}

const defaultInvoicesState: InvoiceStateType = {
  statuses: makeStatuses(),
  errors: makeErrors(),
  records: Map<string, InvoiceResAdmin>(),
}

export type InvoiceState = RecordOf<InvoiceStateType>

export const makeInvoiceSate: Record.Factory<InvoiceStateType> = Record(
  defaultInvoicesState
)
