import { Map, OrderedMap } from "immutable"
import { normalize, schema } from "normalizr"

export function arrayMove<T>(
  array: Array<T>,
  from: number,
  to: number
): Array<T> {
  const arrCopy = [...array]
  const [removedKey] = arrCopy.splice(from, 1)
  arrCopy.splice(to, 0, removedKey)
  return arrCopy
}

export function arraySplit<T>(
  array: Array<T>,
  index: number
): [Array<T>, Array<T>] {
  const first = array.slice(0, index)
  const second = array.slice(index)
  return [first, second]
}

export function arrayCreate<T>(
  length: number,
  callback: (index: number) => T
): Array<T> {
  return Array.from({ length }, (_, i) => callback(i + 1))
}

export function arrayDiff<T>(a: T[], b: T[]): T[] {
  return a.filter((x) => !b.includes(x))
}

export function arrayToMap<T>(data: T[]): Map<string, T> {
  const entitySchema = [new schema.Entity("entity")]
  const {
    entities: { entity },
  } = normalize<T, { [key: string]: { [key: string]: T } }>(data, entitySchema)
  return Map(entity)
}

export function arrayToOrderedMap<T>(data: T[]): OrderedMap<string, T> {
  const entitySchema = [new schema.Entity("entity")]
  const {
    result: idsInOrder,
    entities: { entity },
  } = normalize<T, { [key: string]: { [key: string]: T } }>(data, entitySchema)
  return OrderedMap(idsInOrder.map((id: number) => [id.toString(), entity[id]]))
}

export function arrayUnique<T>(i: T, idx: number, arr: Array<T>): boolean {
  return arr.indexOf(i) === idx
}

export function arrayUnion<T>(a: T[], b: T[]): T[] {
  return [...new Set([...a, ...b])]
}

export function arrayToggle<T>(array: T[], item: T): T[] {
  return array.includes(item)
    ? array.filter((i) => i !== item)
    : array.concat([item])
}

export function localeSort<T extends Record<keyof T, unknown>>(key: keyof T) {
  // @ts-ignore
  return (a: T, b: T) => typeof a[key] === "string" ? a[key].localeCompare(b[key]) : 1
}
