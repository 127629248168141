import { ShowActions, ShowMap } from "../models/showModel"
import { ActionTypes } from "../types/actionTypes"
import { ShowReq, ShowResAdmin } from "../types/apiTypes"

export const fetchShows = (ids?: number[]) =>
  ({
    type: ActionTypes.FETCH_SHOWS_REQUESTED,
    payload: { ids },
  } as const)

export const fetchShowsSucceeded = (shows: ShowMap) =>
  ({
    type: ActionTypes.FETCH_SHOWS_SUCCEEDED,
    payload: { shows },
  } as const)

export const fetchShowsFailed = (message: string) =>
  ({
    type: ActionTypes.FETCH_SHOWS_FAILED,
    payload: { message },
  } as const)

export const saveShow = (formData: ShowReq, id?: number) =>
  ({
    type: ActionTypes.SAVE_SHOW_REQUESTED,
    payload: { formData, id },
  } as const)

export const saveShowSucceeded = (show: ShowResAdmin) =>
  ({
    type: ActionTypes.SAVE_SHOW_SUCCEEDED,
    payload: { show },
  } as const)

export const saveShowFailed = (message: string) =>
  ({
    type: ActionTypes.SAVE_SHOW_FAILED,
    payload: { message },
  } as const)

export const deleteShow = (id: number) =>
  ({
    type: ActionTypes.DELETE_SHOW_REQUESTED,
    payload: { id },
  } as const)

export const deleteShowSucceeded = (id: number) =>
  ({
    type: ActionTypes.DELETE_SHOW_SUCCEEDED,
    payload: { id },
  } as const)

export const deleteShowFailed = (message: string) =>
  ({
    type: ActionTypes.DELETE_SHOW_FAILED,
    payload: { message },
  } as const)

export const resetShowStatus = (key: ShowActions) =>
  ({
    type: ActionTypes.RESET_SHOW_STATUS,
    payload: { key },
  } as const)
