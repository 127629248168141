import React, { Children, ReactNode } from "react"
import { Typography } from "@material-ui/core"
import useInlineFieldsetStyles from "./InlineFieldsetStyles"
import FieldMessage, { Field } from "../Messages/FieldMessage"

interface IInlineFieldsetProps {
  children: ReactNode
  messageId?: Field
}

export default function InlineFieldset({
  children,
  messageId,
}: IInlineFieldsetProps) {
  const classes = useInlineFieldsetStyles({
    childrenNumber: Children.count(children),
  })
  return (
    <fieldset className={classes.root}>
      {messageId && (
        <Typography component={"legend"} variant={"h6"}>
          <FieldMessage id={messageId} />
        </Typography>
      )}
      <div className={classes.container}>
        {children}
      </div>
    </fieldset>
  )
}
