import React from "react"
import { FormattedMessage } from "react-intl"
import { Availability } from "../../types/filterTypes"

interface IAvailabilityMessageProps {
  id: Availability
}

export default function AvailabilityMessage({ id }: IAvailabilityMessageProps) {
  switch (id) {
    case Availability.DAY:
      return <FormattedMessage id={`day`} defaultMessage={`Day`} />
    case Availability.NA:
      return (
        <FormattedMessage id={`unavailable`} defaultMessage={`Unavailable`} />
      )
    case Availability.PART:
      return (
        <FormattedMessage
          id={`partOfTheDay`}
          defaultMessage={`Part of the day`}
        />
      )
    default:
      return null
  }
}
