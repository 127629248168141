import React, { ChangeEvent } from "react"
import { TimerOff } from "@material-ui/icons"
import DataGrid from "../../components/DataGrid/DataGrid"
import SessionFilter from "../../components/SessionFilter/SessionFilter"
import { SessionResAdmin } from "../../types/apiTypes"
import { Field } from "../../components/Messages/FieldMessage"
import { useUserFetch } from "../../hooks/userHooks"
import { useSessionsFetch } from "../../hooks/sessionHooks"
import Results from "../../components/Results/Results"
import { Entity } from "../../components/Messages/EntityMessage"
import { SessionDataGrid, SessionStatus } from "../../types/globalTypes"
import { requestCloseSession } from "../../api/sessionRequests"
import { useFetchHook } from "../../hooks/fetchHooks"
import { SessionBonsCell, SessionSurchargeCell } from "./SessionListCells"
import { formatDateTime } from "../../utils/timeUtils"
import { useLocalStoragePagination } from "../../hooks/interfaceHooks"

export default function SessionList() {
  const [, userFetchStatus, userFetchError] = useUserFetch()
  const [sessions, sessionFetchStatus, sessionFetchError] = useSessionsFetch()
  const { token, handleSuccess, handleFail, handleRequest, error, status } =
    useFetchHook()
  const paginationProps = useLocalStoragePagination("session")

  async function endSessionHandler(
    e: ChangeEvent,
    data: SessionResAdmin | SessionResAdmin[]
  ) {
    try {
      handleRequest()
      const { id } = Array.isArray(data) ? data[0] : data
      await requestCloseSession(token, id)
      handleSuccess()
      // eslint-disable-next-line
    } catch (err: any) {
      handleFail(err.message)
    }
  }

  return (
    <>
      <Results
        statuses={[sessionFetchStatus, status]}
        errors={[sessionFetchError, error]}
        actions={["fetch", "save"]}
        entity={Entity.SESSION}
      />
      <Results
        statuses={[userFetchStatus]}
        errors={[userFetchError]}
        actions={["fetch"]}
        entity={Entity.USER}
      />
      <DataGrid<SessionDataGrid>
        {...paginationProps}
        defaultFilterOpen
        columns={[
          { title: Field.NUMBER, field: "id" },
          { title: Field.USER, field: "name" },
          {
            title: Field.SURCHARGE,
            field: "surchargeReceived",
            render: SessionSurchargeCell,
          },
          { title: Field.BONS, field: "bons", render: SessionBonsCell },
          {
            title: Field.DATE_CREATED,
            field: "dtStart",
            type: "datetime",
            render: ({ dtStart }) => formatDateTime(dtStart),
          },
          {
            title: Field.DATE_ENDED,
            field: "dtEnd",
            type: "datetime",
            render: ({ dtEnd }) => formatDateTime(dtEnd),
          },
          { title: Field.STATUS, field: "status" },
        ]}
        actions={[
          (row) => ({
            icon: () => <TimerOff />,
            tooltip: `End session`,
            onClick: endSessionHandler,
            disabled: row.status === SessionStatus.ENDED,
          }),
        ]}
        data={sessions}
        customFilter={<SessionFilter />}
      />
    </>
  )
}
