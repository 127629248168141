import React, { ChangeEvent, ReactNode } from "react"
import { useSelector } from "react-redux"
import { EditComponentProps } from "@material-table/core"
import FieldMessage, { Field } from "components/Messages/FieldMessage"
import {
  ExtendedOrderReqAdmin,
  InputOption,
  LiteEvent,
  OrderDataGrid,
} from "../../types/globalTypes"
import MultipleFiledSet from "../../components/MultipleFiledSet/MultipleFiledSet"
import {
  blankOrderData,
  blankRequiredRole,
  roleToOrderEmployee,
} from "../../utils/typeUtils"
import { Entity } from "../../components/Messages/EntityMessage"
import RoleMessage from "../../components/Messages/RoleMessage"
import Select from "../../components/Select/Select"
import { selectUserList } from "../../selectors/userSelector"
import Can from "../../components/Can/Can"
import Input from "../../components/Input/Input"
import CellGroup from "../../components/CellGroup/CellGroup"
import { OrderEmployeeOrderReq, UserRes } from "../../types/apiTypes"
import { isEmployeeRole } from "../../utils/apiUtils"

const filterEmployees =
  (key: string) =>
  (employees: OrderEmployeeOrderReq[]): OrderEmployeeOrderReq[] =>
    employees.filter(({ uuid }) => uuid !== key)

function createEmployeeFieldset({
  employees,
  onUserIdChange,
  onRewardChange,
  onNoteChange,
  getUsers,
  getLabel,
}: {
  onUserIdChange: (e: LiteEvent) => void
  onRewardChange: (e: ChangeEvent<HTMLInputElement>) => void
  onNoteChange: (e: ChangeEvent<HTMLInputElement>) => void
  getUsers: (employee: OrderEmployeeOrderReq) => UserRes[]
  employees: OrderEmployeeOrderReq[]
  getLabel: (employee: OrderEmployeeOrderReq) => ReactNode
}) {
  return employees.map((employee) => (
    <CellGroup key={employee.uuid}>
      <Select
        name={employee.uuid}
        options={getUsers(employee).map(
          ({ nickname, id, fullName }): InputOption<string, string> => ({
            value: id.toString(),
            content: nickname || fullName,
            disabled: employees.some((e) => e.userId === id),
          })
        )}
        hasEmptyOption
        value={employee.userId?.toString() || ""}
        onChange={onUserIdChange}
        size={"small"}
        label={getLabel(employee)}
        dense
      />
      <Input
        name={employee.uuid}
        value={employee.reward}
        onChange={onRewardChange}
        label={<FieldMessage id={Field.REWARD} />}
        type={"number"}
        min={0}
        dense
      />
      <Input
        name={employee.uuid}
        value={employee.note}
        onChange={onNoteChange}
        label={<FieldMessage id={Field.NOTE} />}
        dense
      />
    </CellGroup>
  ))
}

const modifyOrderEmployees = (
  { currentTarget: { value, name } }: LiteEvent | ChangeEvent<HTMLInputElement>,
  employees: OrderEmployeeOrderReq[],
  modifier: (val: string) => Partial<OrderEmployeeOrderReq>
): OrderEmployeeOrderReq[] =>
  employees.map((employee) =>
    name === employee.uuid
      ? {
          ...employee,
          ...modifier(value),
        }
      : employee
  )

export function OrderEmployeeEditCell({
  rowData: { req = blankOrderData },
  onChange,
}: EditComponentProps<OrderDataGrid>) {
  const userList = useSelector(selectUserList)
  const changeRow = (data: ExtendedOrderReqAdmin) => {
    onChange(data)
  }
  const handleCreateOrderEmployee = () => {
    changeRow({
      ...req,
      orderEmployees: [
        ...req.orderEmployees,
        roleToOrderEmployee(req?.packageOrder?.dtStart)(blankRequiredRole),
      ],
    })
  }

  const handleRemoveOrderEmployee = (keyToRemove: string) => {
    changeRow({
      ...req,
      orderEmployees: filterEmployees(keyToRemove)(req.orderEmployees),
    })
  }

  const handleRemovePackageEmployee = (keyToRemove: string) => {
    if (req.packageOrder)
      changeRow({
        ...req,
        packageOrder: {
          ...req.packageOrder,
          orderEmployees: filterEmployees(keyToRemove)(
            req.packageOrder.orderEmployees
          ),
        },
      })
  }

  const handleRemoveUpsaleEmployee = (keyToRemove: string) => {
    if (req.packageOrder)
      changeRow({
        ...req,
        upsales: req.upsales.map((upsale) => ({
          ...upsale,
          orderEmployees: filterEmployees(keyToRemove)(upsale.orderEmployees),
        })),
      })
  }

  const handleChangeOrderEmployee = (e: LiteEvent) => {
    changeRow({
      ...req,
      orderEmployees: modifyOrderEmployees(
        e,
        req.orderEmployees,
        (value: string) => ({ userId: parseInt(value) })
      ),
    })
  }

  const handleChangePackageEmployee = (e: LiteEvent) => {
    if (req.packageOrder)
      changeRow({
        ...req,
        packageOrder: {
          ...req?.packageOrder,
          orderEmployees: modifyOrderEmployees(
            e,
            req.packageOrder.orderEmployees,
            (value: string) => ({ userId: parseInt(value) })
          ),
        },
      })
  }

  const handleChangeUpsaleEmployee = (e: LiteEvent) => {
    changeRow({
      ...req,
      upsales: req.upsales.map((upsale) => ({
        ...upsale,
        orderEmployees: modifyOrderEmployees(
          e,
          upsale.orderEmployees,
          (value: string) => ({ userId: parseInt(value) })
        ),
      })),
    })
  }

  const handleChangeOrderEmployeeReward = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    changeRow({
      ...req,
      orderEmployees: modifyOrderEmployees(
        e,
        req.orderEmployees,
        (value: string) => ({ reward: parseInt(value) })
      ),
    })
  }

  const handleChangePackageEmployeeReward = (e: LiteEvent) => {
    if (req.packageOrder)
      changeRow({
        ...req,
        packageOrder: {
          ...req?.packageOrder,
          orderEmployees: modifyOrderEmployees(
            e,
            req.packageOrder.orderEmployees,
            (value: string) => ({ reward: parseInt(value) })
          ),
        },
      })
  }

  const handleChangeUpsaleEmployeeReward = (e: LiteEvent) => {
    changeRow({
      ...req,
      upsales: req.upsales.map((upsale) => ({
        ...upsale,
        orderEmployees: modifyOrderEmployees(
          e,
          upsale.orderEmployees,
          (value: string) => ({ reward: parseInt(value) })
        ),
      })),
    })
  }

  const handleChangeOrderEmployeeNote = (e: ChangeEvent<HTMLInputElement>) => {
    changeRow({
      ...req,
      orderEmployees: modifyOrderEmployees(
        e,
        req.orderEmployees,
        (note: string) => ({ note })
      ),
    })
  }

  const handleChangePackageEmployeeNote = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    if (req.packageOrder)
      changeRow({
        ...req,
        packageOrder: {
          ...req?.packageOrder,
          orderEmployees: modifyOrderEmployees(
            e,
            req.packageOrder.orderEmployees,
            (note: string) => ({ note })
          ),
        },
      })
  }

  const handleChangeUpsaleEmployeeNote = (e: LiteEvent) => {
    changeRow({
      ...req,
      upsales: req.upsales.map((upsale) => ({
        ...upsale,
        orderEmployees: modifyOrderEmployees(
          e,
          upsale.orderEmployees,
          (note: string) => ({ note })
        ),
      })),
    })
  }

  return (
    <Can resource={"orderEmployee"} operations={["W"]}>
      <MultipleFiledSet
        entity={Entity.PACKAGE}
        onRemove={handleRemovePackageEmployee}
        dense
      >
        {createEmployeeFieldset({
          onUserIdChange: handleChangePackageEmployee,
          onRewardChange: handleChangePackageEmployeeReward,
          onNoteChange: handleChangePackageEmployeeNote,
          getUsers: (employee) =>
            userList.filter(({ roles }) =>
              roles.some((r) => r === employee.role)
            ),
          employees: req.packageOrder?.orderEmployees || [],
          getLabel: (employee) => (
            <span>
              <RoleMessage id={employee.role} /> (
              <FieldMessage id={Field.ACTIVITY} />)
            </span>
          ),
        })}
      </MultipleFiledSet>
      <MultipleFiledSet
        entity={Entity.UPSALE}
        onRemove={handleRemoveUpsaleEmployee}
        dense
      >
        {createEmployeeFieldset({
          onUserIdChange: handleChangeUpsaleEmployee,
          onRewardChange: handleChangeUpsaleEmployeeReward,
          onNoteChange: handleChangeUpsaleEmployeeNote,
          getUsers: (employee) =>
            userList.filter(({ roles }) =>
              roles.some((r) => r === employee.role)
            ),
          employees: req.upsales.flatMap((upsale) => upsale.orderEmployees),
          getLabel: (employee) => (
            <span>
              <RoleMessage id={employee.role} /> (
              <FieldMessage id={Field.UPSALES} />)
            </span>
          ),
        })}
      </MultipleFiledSet>
      <MultipleFiledSet
        entity={Entity.EMPLOYEE}
        onCreate={req.packageOrder ? handleCreateOrderEmployee : undefined}
        onRemove={handleRemoveOrderEmployee}
        dense
      >
        {createEmployeeFieldset({
          onUserIdChange: handleChangeOrderEmployee,
          onRewardChange: handleChangeOrderEmployeeReward,
          onNoteChange: handleChangeOrderEmployeeNote,
          getUsers: () =>
            userList.filter(({ roles }) =>
              roles.some((r) => isEmployeeRole(r))
            ),
          employees: req.orderEmployees,
          getLabel: () => <RoleMessage id={"Other"} />,
        })}
      </MultipleFiledSet>
    </Can>
  )
}
