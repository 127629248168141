import { call, delay, put, select } from "redux-saga/effects"
import { IdRes, SiteResAdmin } from "../types/apiTypes"
import { selectToken } from "../selectors/interfaceSelector"
import {
  deleteNews,
  deleteNewsFailed,
  deleteNewsSucceeded,
  fetchNews,
  fetchNewsFailed,
  fetchNewsSuccess,
  resetNewsStatus,
  saveNews,
  saveNewsFailed,
  saveNewsSucceeded,
} from "actions/newsActions"
import {
  requestDeleteNews,
  requestGetNews,
  requestSaveNews,
} from "api/newsRequests"
import { NewsActions, normalizeNews } from "models/newsModel"

function* resetStatus(key: NewsActions) {
  yield delay(3000)
  yield put(resetNewsStatus(key))
}

export function* fetchNewsSaga({
  payload: { ids },
}: ReturnType<typeof fetchNews>) {
  try {
    const auth: string = yield select(selectToken)
    const news: SiteResAdmin[] = yield call(requestGetNews, auth, ids)
    yield put(fetchNewsSuccess(normalizeNews(news)))
  } catch (e: unknown) {
    yield put(fetchNewsFailed(e instanceof Error ? e.message : ""))
  } finally {
    yield* resetStatus("fetchNews")
  }
}

export function* saveNewsSaga({
  payload: { data, id },
}: ReturnType<typeof saveNews>) {
  try {
    const auth: string = yield select(selectToken)
    const res: IdRes<number> = yield call(requestSaveNews, auth, data, id)
    const [product]: SiteResAdmin[] = yield call(requestGetNews, auth, [res.id])
    yield put(saveNewsSucceeded(product))
  } catch (e: unknown) {
    yield put(saveNewsFailed(e instanceof Error ? e.message : ""))
  } finally {
    yield* resetStatus("saveNews")
  }
}

export function* deleteNewsSaga({
  payload: { id },
}: ReturnType<typeof deleteNews>) {
  try {
    const auth: string = yield select(selectToken)
    yield call(requestDeleteNews, auth, id)
    yield put(deleteNewsSucceeded(id))
  } catch (e: unknown) {
    yield put(deleteNewsFailed(e instanceof Error ? e.message : ""))
  } finally {
    yield* resetStatus("deleteNews")
  }
}
