import React from "react"
import { useIntl } from "react-intl"
import { FormField } from "../../types/apiTypes"

export enum Field {
  BALANCE = "BALANCE",
  REQUIRED_UPSALES = "REQUIRED_UPSALES",
  OFFSET = "OFFSET",
  ORDER_STATUS = "ORDER_STATUS",
  SESSION_STATUS = "SESSION_STATUS",
  INVOICE_STATUS = "INVOICE_STATUS",
  RESERVATION_UPSALE = "RESERVATION_UPSALE",
  NICKNAME = "NICKNAME",
  COUNT = "COUNT",
  INVOICE_DAY = "INVOICE_DAY",
  SPECIAL_REQUEST = "SPECIAL_REQUEST",
  EMPLOYEE = "EMPLOYEE",
  PANORAMA = "PANORAMA",
  PERIOD = "PERIOD",
  KEYWORDS = "KEYWORDS",
  PURPOSE = "PURPOSE",
  MAIN_IMAGE_VIDEO = "MAIN_IMAGE_VIDEO",
  CARD_IMAGE = "CARD_IMAGE",
  CROP_IMAGE = "CROP_IMAGE",
  GALLERY = "GALLERY",
  HISTORY = "HISTORY",
  SUPERSTAR = "SUPERSTAR",
  SURCHARGE = "SURCHARGE",
  DUE_DATE = "DUE_DATE",
  DATE_CREATED = "DATE_CREATED",
  DATE_ENDED = "DATE_ENDED",
  DATE_USED = "DATE_USED",
  NUMBER = "NUMBER",
  AGENCY = "AGENCY",
  NAME = "NAME",
  ROLE = "ROLE",
  ROLES = "ROLES",
  REWARD = "REWARD",
  TYPE = "TYPE",
  ACCOUNT_NUMBER = "ACCOUNT_NUMBER",
  SEND_INVOICE = "SEND_INVOICE",
  OPTION = "OPTION",
  NOTE = "NOTE",
  NOTE_AGENCY = "NOTE_AGENCY",
  NOTE_ADMIN = "NOTE_ADMIN",
  NOTE_EMPLOYEE = "NOTE_EMPLOYEE",
  COLOR = "COLOR",
  ADDRESS = "ADDRESS",
  EMAIL = "EMAIL",
  PHONE = "PHONE",
  POSITION = "POSITION",
  PASSWORD = "PASSWORD",
  URLS = "URLS",
  URL = "URL",
  META = "META",
  VIDEO = "VIDEO",
  VIDEOS = "VIDEOS",
  FIGHTERS = "FIGHTERS",
  TITLE = "TITLE",
  WEB_TITLE = "WEB_TITLE",
  OPTION_TITLE = "OPTION_TITLE",
  DESCRIPTION = "DESCRIPTION",
  ACTIVITIES = "ACTIVITIES",
  ACTIVITY = "ACTIVITY",
  CONTENT = "CONTENT",
  PROGRAM = "PROGRAM",
  OPTION_CONTENT = "OPTION_CONTENT",
  ACTIVITY_CONTENT = "ACTIVITY_CONTENT",
  DRINKS = "DRINKS",
  DANCERS = "DANCERS",
  FROM = "FROM",
  TO = "TO",
  PRICE = "PRICE",
  PRICE_ACTIVITY = "PRICE_ACTIVITY",
  PRICE_PERSON = "PRICE_PERSON",
  DEPOSIT = "DEPOSIT",
  CANCELLATION_FEE = "CANCELLATION_FEE",
  FEE = "FEE",
  BONS = "BONS",
  VAT_ID = "VAT_ID",
  ID = "ID",
  CRN = "CRN",
  DATE = "DATE",
  TIME = "TIME",
  DAYS = "DAYS",
  IS_ACTIVE = "IS_ACTIVE",
  VAT_RATE = "VAT_RATE",
  IS_AGENCY = "IS_AGENCY",
  IS_ORDER_PAID = "IS_ORDER_PAID",
  IS_DEPOSIT_PAID = "IS_DEPOSIT_PAID",
  IS_WEB = "IS_WEB",
  IS_CONSTRAINED = "IS_CONSTRAINED",
  FOR_N_PERSONS = "FOR_N_PERSONS",
  VOUCHER_CODE = "VOUCHER_CODE",
  CONTACT = "CONTACT",
  CONTACTS = "CONTACTS",
  BILLING_INFO = "BILLING_INFO",
  INVOICE_PUBLISHER = "INVOICE_PUBLISHER",
  PAYMENT_METHOD = "PAYMENT_METHOD",
  STATUS = "STATUS",
  PRICE_LIST = "PRICE_LIST",
  REQUIRED_FIELDS = "REQUIRED_FIELDS",
  OPTIONAL_FIELDS = "OPTIONAL_FIELDS",
  UPSALES = "UPSALES",
  CONTAINS_RESERVATION = "CONTAINS_RESERVATION",
  AVAILABILITY = "AVAILABILITY",
  COSTS = "COSTS",
  EXTERNAL_COST = "EXTERNAL_COST",
  EXTERNAL_PERSON_COST = "EXTERNAL_PERSON_COST",
  COST_PER_PERSON = "COST_PER_PERSON",
  TOTAL_PRICE = "TOTAL_PRICE",
  FEE_OVER = "FEE_OVER",
  CAPACITY = "CAPACITY",
  MIN = "MIN",
  MAX = "MAX",
  CANCELLATION_POLICY = "CANCELLATION_POLICY",
  INTERVAL = "INTERVAL",
  PERCENTAGE = "PERCENTAGE",
  BLOCKS = "BLOCKS",
  DURATION_BEFORE = "DURATION_BEFORE",
  DURATION_AFTER = "DURATION_AFTER",
  DURATION_ACTUAL = "DURATION_ACTUAL",
  LIMIT = "LIMIT",
  GROUP = "GROUP",
  QUANTITY = "QUANTITY",
  QUANTITY_BLOCKED = "QUANTITY_BLOCKED",
  QUANTITY_REQUIRED = "QUANTITY_REQUIRED",
  CATEGORY = "CATEGORY",
  USER = "USER",
  USERS = "USERS",
  EMPLOYEES = "EMPLOYEES",
  RESPONSIBILITIES = "RESPONSIBILITIES",
  SOURCE = "SOURCE",
  NUMBER_OF_PERSONS = "NUMBER_OF_PERSONS",
  EXTRAS = "EXTRAS",
  SECTION = "SECTION",
  TABLE_NUMBER = "TABLE_NUMBER",
  TIPPING_DOLLARS = "TIPPING_DOLLARS",
  PAYMENT_STATUS = "PAYMENT_STATUS",
  ALL = "ALL",
  BANNER_TEXT = "BANNER_TEXT",
  HEADLINE = "HEADLINE",
  ICON = "ICON",
  IMAGE_OR_VIDEO = "IMAGE_OR_VIDEO",
  DISPLAY_IN_NOTIFICATION_BAR = "DISPLAY_IN_NOTIFICATION_BAR",
  UPSALE_DATETIME = "UPSALE_DATETIME",
  SORT_TYPE = "SORT_TYPE",
}

interface IFieldMessageProps {
  id: Field | FormField
}

export function useFieldMessage(): (
  id: Field | FormField
) => string | undefined {
  const { formatMessage } = useIntl()
  return (id) => {
    switch (id) {
      case Field.REQUIRED_UPSALES:
        return formatMessage({
          id: `REQUIRED_UPSALES`,
          defaultMessage: `Required upsales`,
        })
      case Field.OFFSET:
        return formatMessage({
          id: `OFFSET`,
          defaultMessage: `Offset`,
        })
      case Field.ORDER_STATUS:
        return formatMessage({
          id: `ORDER_STATUS`,
          defaultMessage: `Status`,
        })
      case Field.SESSION_STATUS:
        return formatMessage({
          id: `SESSION_STATUS`,
          defaultMessage: `Status`,
        })
      case Field.INVOICE_STATUS:
        return formatMessage({
          id: `INVOICE_STATUS`,
          defaultMessage: `Status`,
        })
      case FormField.ACTIVITY_START_DATETIME:
        return formatMessage({
          id: `ACTIVITY_START_DATETIME`,
          defaultMessage: `Activity start date`,
        })
      case FormField.DROPOUT_DATETIME:
        return formatMessage({
          id: `DROPOUT_DATETIME`,
          defaultMessage: `Dropout date`,
        })
      case FormField.RESERVATION_DATETIME:
        return formatMessage({
          id: `RESERVATION_DATETIME`,
          defaultMessage: `Reservation date`,
        })
      case FormField.ACTIVITY_ADDRESS:
        return formatMessage({
          id: `ACTIVITY_ADDRESS`,
          defaultMessage: `Activity address`,
        })
      case FormField.COORDINATOR_CONTACT:
        return formatMessage({
          id: `COORDINATOR_CONTACT`,
          defaultMessage: `Coordinator contact`,
        })
      case FormField.DRIVE_START_DATETIME:
        return formatMessage({
          id: `DRIVE_START_DATETIME`,
          defaultMessage: `Drive start date`,
        })
      case FormField.DRIVER_CONTACT:
        return formatMessage({
          id: `DRIVER_CONTACT`,
          defaultMessage: `Driver contact`,
        })
      case FormField.FLIGHT_ARRIVAL_DATETIME:
        return formatMessage({
          id: `FLIGHT_ARRIVAL_DATETIME`,
          defaultMessage: `Flight arrival date`,
        })
      case FormField.FLIGHT_NUMBER:
        return formatMessage({
          id: `FLIGHT_NUMBER`,
          defaultMessage: `Flight number`,
        })
      case FormField.GUIDE_CONTACT:
        return formatMessage({
          id: `GUIDE_CONTACT`,
          defaultMessage: `Guide contact`,
        })
      case Field.RESERVATION_UPSALE:
        return formatMessage({
          id: `reservationUpsale`,
          defaultMessage: `Use as table upsale`,
        })
      case Field.SEND_INVOICE:
        return formatMessage({
          id: `sendInvoice`,
          defaultMessage: `Send invoice`,
        })
      case Field.NICKNAME:
        return formatMessage({
          id: `nickname`,
          defaultMessage: `Nickname`,
        })
      case Field.OPTION_TITLE:
        return formatMessage({
          id: `optionTitle`,
          defaultMessage: `Option title`,
        })
      case Field.WEB_TITLE:
        return formatMessage({
          id: `webTitle`,
          defaultMessage: `Web title`,
        })
      case Field.OPTION_CONTENT:
        return formatMessage({
          id: `optionContent`,
          defaultMessage: `Option contents`,
        })
      case Field.ACTIVITY_CONTENT:
        return formatMessage({
          id: `activityContent`,
          defaultMessage: `Package includes`,
        })
      case Field.ID:
        return formatMessage({
          id: `id`,
          defaultMessage: `ID`,
        })
      case Field.EXTERNAL_PERSON_COST:
        return formatMessage({
          id: `externalPersonCost`,
          defaultMessage: `Ext. cost / person`,
        })
      case Field.SPECIAL_REQUEST:
        return formatMessage({
          id: `specialRequest`,
          defaultMessage: `Special request`,
        })
      case Field.INVOICE_DAY:
        return formatMessage({
          id: `invoiceDay`,
          defaultMessage: `Invoice day`,
        })
      case Field.EMPLOYEE:
        return formatMessage({ id: `employee`, defaultMessage: `Employee` })
      case Field.KEYWORDS:
        return formatMessage({ id: `keywords`, defaultMessage: `Keywords` })
      case Field.PURPOSE:
        return formatMessage({ id: `purpose`, defaultMessage: `Purpose` })
      case Field.DAYS:
        return formatMessage({ id: `days`, defaultMessage: `Days` })
      case Field.ROLE:
        return formatMessage({ id: `role`, defaultMessage: `Role` })
      case Field.COLOR:
        return formatMessage({ id: `color`, defaultMessage: `Color` })
      case Field.OPTION:
        return formatMessage({ id: `option`, defaultMessage: `Option` })
      case Field.CARD_IMAGE:
        return formatMessage({ id: `cardImage`, defaultMessage: `Card Image` })
      case Field.CROP_IMAGE:
        return formatMessage({
          id: `cropImage`,
          defaultMessage: `Cropped card image`,
        })
      case Field.MAIN_IMAGE_VIDEO:
        return formatMessage({
          id: `mainImageVideo`,
          defaultMessage: `Main Image or Video`,
        })
      case Field.GALLERY:
        return formatMessage({ id: `gallery`, defaultMessage: `Gallery` })
      case Field.LIMIT:
        return formatMessage({ id: `limit`, defaultMessage: `Limit` })
      case Field.GROUP:
        return formatMessage({ id: `group`, defaultMessage: `Group` })
      case Field.FOR_N_PERSONS:
        return formatMessage({
          id: `forNPersons`,
          defaultMessage: `For X persons`,
        })
      case Field.INTERVAL:
        return formatMessage({ id: `interval`, defaultMessage: `Interval` })
      case Field.PERCENTAGE:
        return formatMessage({ id: `percentage`, defaultMessage: `Percentage` })
      case Field.USER:
        return formatMessage({ id: `user`, defaultMessage: `User` })
      case Field.USERS:
        return formatMessage({ id: `users`, defaultMessage: `Users` })
      case Field.QUANTITY:
        return formatMessage({ id: `quantity`, defaultMessage: `Quantity` })
      case Field.QUANTITY_REQUIRED:
        return formatMessage({
          id: `quantityRequired`,
          defaultMessage: `Quantity required`,
        })
      case Field.QUANTITY_BLOCKED:
        return formatMessage({
          id: `quantityBlocked`,
          defaultMessage: `Quantity blocked`,
        })
      case Field.DURATION_AFTER:
        return formatMessage({
          id: `durationAfter`,
          defaultMessage: `Duration after`,
        })
      case Field.DURATION_ACTUAL:
        return formatMessage({
          id: `durationActual`,
          defaultMessage: `Actual duration`,
        })
      case Field.DURATION_BEFORE:
        return formatMessage({
          id: `durationBefore`,
          defaultMessage: `Duration before`,
        })
      case Field.MIN:
        return formatMessage({ id: `min`, defaultMessage: `Minimum` })
      case Field.MAX:
        return formatMessage({ id: `max`, defaultMessage: `Maximum` })
      case Field.FROM:
        return formatMessage({ id: `from`, defaultMessage: `From` })
      case Field.TO:
        return formatMessage({ id: `to`, defaultMessage: `To` })
      case Field.CAPACITY:
        return formatMessage({ id: `capacity`, defaultMessage: `Capacity` })
      case Field.CANCELLATION_POLICY:
        return formatMessage({
          id: `cancellationPolicy`,
          defaultMessage: `Cancellation policy`,
        })
      case Field.BLOCKS:
        return formatMessage({ id: `blocks`, defaultMessage: `Blocks` })
      case Field.ROLES:
        return formatMessage({ id: `roles`, defaultMessage: `Roles` })
      case Field.RESPONSIBILITIES:
        return formatMessage({
          id: `responsibilities`,
          defaultMessage: `Responsibilities`,
        })
      case Field.EXTERNAL_COST:
        return formatMessage({
          id: `externalCost`,
          defaultMessage: `External cost`,
        })
      case Field.AVAILABILITY:
        return formatMessage({
          id: `availability`,
          defaultMessage: `Availability`,
        })
      case Field.TYPE:
        return formatMessage({ id: `type`, defaultMessage: `Type` })
      case Field.IS_CONSTRAINED:
        return formatMessage({
          id: `isConstrained`,
          defaultMessage: `Constrained by opening hours`,
        })
      case Field.IS_WEB:
        return formatMessage({ id: `isWeb`, defaultMessage: `Web` })
      case Field.IS_AGENCY:
        return formatMessage({ id: `isAgency`, defaultMessage: `Agency` })
      case Field.CONTAINS_RESERVATION:
        return formatMessage({
          id: `containsReservation`,
          defaultMessage: `Contains Reservation`,
        })
      case Field.IS_ACTIVE:
        return formatMessage({ id: `isActive`, defaultMessage: `Active` })
      case Field.UPSALES:
        return formatMessage({ id: `upsales`, defaultMessage: `Upsales` })
      case Field.OPTIONAL_FIELDS:
        return formatMessage({
          id: `optionalFields`,
          defaultMessage: `Optional Fields`,
        })
      case Field.REQUIRED_FIELDS:
        return formatMessage({
          id: `requiredFields`,
          defaultMessage: `Required Fields`,
        })
      case Field.FEE_OVER:
        return formatMessage({ id: `feeOver`, defaultMessage: `Fee over 10` })
      case Field.COST_PER_PERSON:
        return formatMessage({
          id: `costPerPerson`,
          defaultMessage: `Cost per Person`,
        })
      case Field.CONTACT:
        return formatMessage({ id: `contact`, defaultMessage: `Contact` })
      case Field.CONTACTS:
        return formatMessage({ id: `contacts`, defaultMessage: `Contacts` })
      case Field.BILLING_INFO:
        return formatMessage({
          id: `billingInfo`,
          defaultMessage: `Billing Info`,
        })
      case Field.ACCOUNT_NUMBER:
        return formatMessage({
          id: `accountNumber`,
          defaultMessage: `Account number`,
        })
      case Field.VAT_ID:
        return formatMessage({ id: `varId`, defaultMessage: `VAT ID` })
      case Field.CRN:
        return formatMessage({ id: `crn`, defaultMessage: `CRN` })
      case Field.INVOICE_PUBLISHER:
        return formatMessage({
          id: `invoicePublisher`,
          defaultMessage: `Invoice Publisher`,
        })
      case Field.VAT_RATE:
        return formatMessage({
          id: `vatRate`,
          defaultMessage: `VAT Rate`,
        })
      case Field.PRICE_LIST:
        return formatMessage({ id: `priceList`, defaultMessage: `Price list` })
      case Field.CONTENT:
        return formatMessage({ id: `content`, defaultMessage: `Content` })
      case Field.TITLE:
        return formatMessage({ id: `title`, defaultMessage: `Title` })
      case Field.BONS:
        return formatMessage({ id: `bons`, defaultMessage: `Bons` })
      case Field.SURCHARGE:
        return formatMessage({ id: `surcharge`, defaultMessage: `Surcharge` })
      case Field.DATE_CREATED:
        return formatMessage({
          id: `dateCreated`,
          defaultMessage: `Date Created`,
        })
      case Field.DATE_ENDED:
        return formatMessage({ id: `dateEnded`, defaultMessage: `Date Ended` })
      case Field.DATE_USED:
        return formatMessage({ id: `dateUsed`, defaultMessage: `Date Used` })
      case Field.NUMBER:
        return formatMessage({ id: `number`, defaultMessage: `Number` })
      case Field.DUE_DATE:
        return formatMessage({ id: `dueDate`, defaultMessage: `Due date` })
      case Field.AGENCY:
        return formatMessage({ id: `agency`, defaultMessage: `Agency` })
      case Field.PAYMENT_STATUS:
        return formatMessage({ id: `paymentStatus`, defaultMessage: `Status` })
      case Field.SOURCE:
        return formatMessage({ id: `source`, defaultMessage: `Source` })
      case Field.ACTIVITY:
        return formatMessage({ id: `activity`, defaultMessage: `Activity` })
      case Field.NUMBER_OF_PERSONS:
        return formatMessage({
          id: `numberOfPersons`,
          defaultMessage: `Person number`,
        })
      case Field.ADDRESS:
        return formatMessage({ id: `address`, defaultMessage: `Address` })
      case Field.EMPLOYEES:
        return formatMessage({ id: `employees`, defaultMessage: `Employees` })
      case Field.REWARD:
        return formatMessage({ id: `reward`, defaultMessage: `Reward` })
      case Field.EXTRAS:
        return formatMessage({ id: `extras`, defaultMessage: `Extras` })
      case Field.NOTE:
        return formatMessage({ id: `note`, defaultMessage: `Note` })
      case Field.NOTE_AGENCY:
        return formatMessage({ id: `noteAgency`, defaultMessage: `Agency` })
      case Field.NOTE_ADMIN:
        return formatMessage({ id: `noteAdmin`, defaultMessage: `Admin` })
      case Field.NOTE_EMPLOYEE:
        return formatMessage({ id: `noteEmployee`, defaultMessage: `Employee` })
      case Field.PAYMENT_METHOD:
        return formatMessage({
          id: `paymentMethod`,
          defaultMessage: `Payment Method`,
        })
      case Field.COSTS:
        return formatMessage({ id: `costs`, defaultMessage: `Costs` })
      case Field.STATUS:
        return formatMessage({ id: `status`, defaultMessage: `Status` })
      case Field.SECTION:
        return formatMessage({ id: `section`, defaultMessage: `Section` })
      case Field.TABLE_NUMBER:
        return formatMessage({
          id: `tableNumber`,
          defaultMessage: `Table Number`,
        })
      case Field.TIPPING_DOLLARS:
        return formatMessage({
          id: "tippingDollars",
          defaultMessage: "Tipping Dollars",
        })
      case Field.PRICE:
        return formatMessage({ id: `price`, defaultMessage: `Price` })
      case Field.PRICE_ACTIVITY:
        return formatMessage({
          id: `priceActivity`,
          defaultMessage: `Activity Price`,
        })
      case Field.TOTAL_PRICE:
        return formatMessage({
          id: `priceTotal`,
          defaultMessage: `Total price`,
        })
      case Field.PRICE_PERSON:
        return formatMessage({
          id: `pricePerson`,
          defaultMessage: `Price per person`,
        })
      case Field.DEPOSIT:
        return formatMessage({
          id: `deposit`,
          defaultMessage: `Deposit`,
        })
      case Field.FEE:
        return formatMessage({
          id: `fee`,
          defaultMessage: `Fee over 10`,
        })
      case Field.EMAIL:
        return formatMessage({ id: `EMAIL`, defaultMessage: `Email` })
      case Field.PASSWORD:
        return formatMessage({ id: `password`, defaultMessage: `Password` })
      case Field.NAME:
        return formatMessage({ id: `NAME`, defaultMessage: `Name` })
      case FormField.DROPOUT_ADDRESS:
        return formatMessage({
          id: `DROPOUT_ADDRESS`,
          defaultMessage: `Dropout Address`,
        })
      case Field.PHONE:
        return formatMessage({ id: `PHONE_NUMBER`, defaultMessage: `Phone` })
      case FormField.PICKUP_ADDRESS:
        return formatMessage({
          id: `PICKUP_ADDRESS`,
          defaultMessage: `Pickup Address`,
        })
      case FormField.PICKUP_DATETIME:
        return formatMessage({
          id: `PICKUP_DATETIME`,
          defaultMessage: `Pickup Date`,
        })
      case Field.UPSALE_DATETIME:
        return formatMessage({
          id: `UPSALE_DATETIME`,
          defaultMessage: `Upsale Date`,
        })
      case Field.DESCRIPTION:
        return formatMessage({
          id: `DESCRIPTION`,
          defaultMessage: `Description`,
        })
      case Field.META:
        return formatMessage({ id: `META`, defaultMessage: `Meta description` })
      case Field.ACTIVITIES:
        return formatMessage({ id: `ACTIVITIES`, defaultMessage: `Activities` })
      case Field.VIDEO:
        return formatMessage({ id: `VIDEO`, defaultMessage: `Video` })
      case Field.VIDEOS:
        return formatMessage({ id: `VIDEOS`, defaultMessage: `Videos` })
      case Field.FIGHTERS:
        return formatMessage({ id: `FIGHTERS`, defaultMessage: `Fighters` })
      case Field.DRINKS:
        return formatMessage({ id: `DRINKS`, defaultMessage: `Drinks` })
      case Field.DANCERS:
        return formatMessage({ id: `DANCERS`, defaultMessage: `Dancers` })
      case Field.POSITION:
        return formatMessage({ id: `POSITION`, defaultMessage: `Position` })
      case Field.CATEGORY:
        return formatMessage({ id: `CATEGORY`, defaultMessage: `Category` })
      case Field.DATE:
        return formatMessage({ id: `DATE`, defaultMessage: `Date` })
      case Field.TIME:
        return formatMessage({ id: `TIME`, defaultMessage: `Time` })
      case Field.URL:
        return formatMessage({ id: `URL`, defaultMessage: `URL` })
      case Field.URLS:
        return formatMessage({ id: `URLS`, defaultMessage: `URLs` })
      case Field.VOUCHER_CODE:
        return formatMessage({
          id: `VOUCHER_CODE`,
          defaultMessage: `Voucher code`,
        })
      case Field.PANORAMA:
        return formatMessage({ id: `PANORAMA`, defaultMessage: `Panorama` })
      case Field.PERIOD:
        return formatMessage({ id: `PERIOD`, defaultMessage: `Period` })
      case Field.IS_ORDER_PAID:
        return formatMessage({
          id: `IS_ORDER_PAID`,
          defaultMessage: `Is order paid`,
        })
      case Field.IS_DEPOSIT_PAID:
        return formatMessage({
          id: `IS_DEPOSIT_PAID`,
          defaultMessage: `Is deposit paid`,
        })
      case Field.COUNT:
        return formatMessage({
          id: `COUNT`,
          defaultMessage: `Count`,
        })
      case Field.CANCELLATION_FEE:
        return formatMessage({
          id: `CANCELLATION_FEE`,
          defaultMessage: `Cancellation fee`,
        })
      case Field.ALL:
        return formatMessage({
          id: `ALL`,
          defaultMessage: `All`,
        })
      case Field.BANNER_TEXT:
        return formatMessage({
          id: `BANNER_TEXT`,
          defaultMessage: `Banner text`,
        })
      case Field.HEADLINE:
        return formatMessage({
          id: `HEADLINE`,
          defaultMessage: `Headline`,
        })
      case Field.ICON:
        return formatMessage({
          id: `ICON`,
          defaultMessage: `Icon`,
        })
      case Field.IMAGE_OR_VIDEO:
        return formatMessage({
          id: `IMAGE_OR_VIDEO`,
          defaultMessage: `Image or video`,
        })
      case Field.DISPLAY_IN_NOTIFICATION_BAR:
        return formatMessage({
          id: `DISPLAY_IN_NOTIFICATION_BAR`,
          defaultMessage: `Display in notification bar`,
        })
      case Field.SORT_TYPE:
        return formatMessage({
          id: `SORT_TYPE`,
          defaultMessage: `Sort type`,
        })
      case Field.HISTORY:
        return formatMessage({
          id: `HISTORY`,
          defaultMessage: `History`,
        })
      case Field.SUPERSTAR:
        return formatMessage({
          id: `SUPERSTAR`,
          defaultMessage: `Superstar`,
        })
      case Field.PROGRAM:
        return formatMessage({
          id: `PROGRAM`,
          defaultMessage: `Program for tonight`,
        })
      case Field.BALANCE:
        return formatMessage({
          id: `BALANCE`,
          defaultMessage: `Balance`,
        })
      default:
        return `Missing translation for ${id}`
    }
  }
}

export default function FieldMessage({ id }: IFieldMessageProps) {
  const translate = useFieldMessage()
  return <>{translate(id)}</>
}
