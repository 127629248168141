import { Record, RecordOf, Map } from "immutable"
import { RequestStatus } from "../types/statusTypes"
import { ProductResAdmin, ShowResAdmin } from "../types/apiTypes"
import { normalize, schema } from "normalizr"

export type ShowMap = Map<string, ShowResAdmin>

export function normalizeShows(data: ShowResAdmin[]): ShowMap {
  const productSchema = [new schema.Entity("shows")]
  const {
    entities: { shows },
  } = normalize<
    ProductResAdmin,
    { [key: string]: { [key: string]: ShowResAdmin } }
    >(data, productSchema)
  return Map(shows)
}

const defaultStatuses = {
  fetchShows: RequestStatus.INITIAL,
  deleteShow: RequestStatus.INITIAL,
  saveShow: RequestStatus.INITIAL,
}

const makeStatuses: Record.Factory<typeof defaultStatuses> = Record(
  defaultStatuses
)

const defaultErrors = {
  fetchShows: "",
  deleteShow: "",
  saveShow: "",
}

const makeErrors: Record.Factory<typeof defaultErrors> = Record(defaultErrors)

type ShowStateType = {
  statuses: RecordOf<typeof defaultStatuses>
  errors: RecordOf<typeof defaultErrors>
  records: ShowMap
}

const defaultShowState: ShowStateType = {
  statuses: makeStatuses(),
  errors: makeErrors(),
  records: Map() as ShowMap,
}

export type ShowState = RecordOf<ShowStateType>

export type ShowActions = keyof typeof defaultStatuses

export const makeShowState: Record.Factory<ShowStateType> = Record(
  defaultShowState
)
