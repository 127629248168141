import { call, put, select } from "redux-saga/effects"
import { selectToken } from "../selectors/interfaceSelector"
import {
  deleteCareer,
  deleteCareerFailed,
  deleteCareerSucceed,
  fetchCareer,
  fetchCareerFailed,
  fetchCareerSucceed,
  saveCareer,
  saveCareerFailed,
  saveCareerSucceed,
} from "../actions/careerActions"
import { CareerApplicationResAdmin } from "../types/apiTypes"
import {
  requestCareers,
  requestDeleteCareer,
  requestSaveCareers,
} from "../api/careerRequests"
import { normalizeCareers } from "../models/careerModel"

export function* fetchCareersSaga({
  payload: { ids },
}: ReturnType<typeof fetchCareer>) {
  try {
    const auth: string = yield select(selectToken)
    const data: CareerApplicationResAdmin[] = yield call(
      requestCareers,
      auth,
      ids
    )
    yield put(fetchCareerSucceed(normalizeCareers(data)))
    // eslint-disable-next-line
  } catch (e: any) {
    yield put(fetchCareerFailed(e.message))
  }
}

export function* saveCareersSaga({
  payload: { id, formData },
}: ReturnType<typeof saveCareer>) {
  try {
    const auth: string = yield select(selectToken)
    const response: CareerApplicationResAdmin = yield call(
      requestSaveCareers,
      auth,
      formData,
      id
    )
    yield put(saveCareerSucceed(response))
    // eslint-disable-next-line
  } catch (e: any) {
    yield put(saveCareerFailed(e.message))
  }
}

export function* deleteCareerSaga({
  payload: { id },
}: ReturnType<typeof deleteCareer>) {
  try {
    const auth: string = yield select(selectToken)
    const responseId: number = yield call(requestDeleteCareer, auth, id)
    yield put(deleteCareerSucceed(responseId))
    // eslint-disable-next-line
  } catch (e: any) {
    yield put(deleteCareerFailed(e.message))
  }
}
