import React from "react"
import { useDispatch, useSelector } from "react-redux"
import Filter, { FilterTypes } from "../Filter/Filter"
import { Field } from "../Messages/FieldMessage"
import { selectFilterMap } from "../../selectors/interfaceSelector"
import { setFilter } from "../../actions/interfaceActions"
import { sourceInputOptions } from "../../utils/typeUtils"

export default function SourceFilter() {
  const filters = useSelector(selectFilterMap)
  const dispatch = useDispatch()

  const handleChange = (key: string, value: string[]) => {
    dispatch(setFilter(key, value))
  }

  return (
    <Filter
      fields={[
        {
          name: Field.SOURCE,
          options: sourceInputOptions,
          type: FilterTypes.RadioList,
          values: filters.get(Field.SOURCE)?.toArray() || [],
        },
      ]}
      onChange={handleChange}
    />
  )
}
