import { ChangeEvent, useState } from "react"
import { useSelector } from "react-redux"
import { Language, TranslationReq } from "../types/apiTypes"
import { TranslationMap, TranslationSimpleMap } from "../types/globalTypes"
import {
  blankRichTextTranslations,
  blankSingleTranslations,
  blankTranslations,
  fullToSimpleTranslation,
  initialRichText,
  RichTextTranslations,
} from "../utils/typeUtils"
import { RequestStatus } from "../types/statusTypes"
import { selectToken } from "../selectors/interfaceSelector"
import { requestGetPage, requestUpdatePage } from "../api/pageRequests"
import { delay } from "../utils/apiUtils"
import { Descendant } from "slate"

export function useToggle(
  defaultValue = false
): [boolean, () => void, (v: boolean) => void] {
  const [bool, setBool] = useState<boolean>(defaultValue)
  return [bool, () => setBool(!bool), (value: boolean) => setBool(value)]
}

export function useModalState(
  defaultValue = false
): [boolean, () => void, () => void] {
  const [bool, setBool] = useState<boolean>(defaultValue)
  return [bool, () => setBool(true), () => setBool(false)]
}

export function useToggleArray<T>(): [
  T[],
  (value: T) => void,
  (force: T[]) => void
] {
  const [array, setArray] = useState<T[]>([])
  return [
    array,
    (value: T) =>
      setArray(
        array.includes(value)
          ? array.filter((item) => item !== value)
          : [...array, value]
      ),
    (force: T[]) => setArray(force),
  ]
}

export function useLanguageState(defaultLanguage: Language = Language.CS) {
  const [language, setLanguage] = useState<Language>(defaultLanguage)
  return {
    language,
    setLanguage,
  }
}

export function useTranslationState(): [
  TranslationSimpleMap,
  (value: string, language: Language) => void,
  (req: TranslationMap, key: keyof TranslationReq) => void
] {
  const [translation, setTranslation] = useState<TranslationSimpleMap>(
    blankSingleTranslations
  )
  return [
    translation,
    (value: string, language: Language) => {
      setTranslation({ ...translation, [language]: value })
    },
    (req: TranslationMap, key: keyof TranslationReq) => {
      setTranslation(fullToSimpleTranslation(req, key))
    },
  ]
}

export function useRichTextState(
  language: Language
): [
  RichTextTranslations,
  (value: Descendant[]) => void,
  (req: TranslationMap, key: keyof TranslationReq) => void
] {
  const [translation, setTranslation] = useState<RichTextTranslations>(
    blankRichTextTranslations
  )
  return [
    translation,
    (value: Descendant[]) => {
      setTranslation({ ...translation, [language]: value })
    },
    (req: TranslationMap, key: keyof TranslationReq) => {
      setTranslation({
        [Language.FR]: req[Language.FR][key]
          ? JSON.parse(req[Language.FR][key] as string)
          : initialRichText,
        [Language.EN]: req[Language.EN][key]
          ? JSON.parse(req[Language.EN][key] as string)
          : initialRichText,
        [Language.CS]: req[Language.CS][key]
          ? JSON.parse(req[Language.CS][key] as string)
          : initialRichText,
        [Language.DE]: req[Language.DE][key]
          ? JSON.parse(req[Language.DE][key] as string)
          : initialRichText,
      })
    },
  ]
}

export function usePageSharedState(pageId: number) {
  const [language, setLanguage] = useState(Language.CS)
  const [fetchStatus, setFetchStatus] = useState(RequestStatus.INITIAL)
  const [fetchError, setFetchError] = useState("")
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [saveStatus, setSaveStatus] = useState(RequestStatus.INITIAL)
  const [saveError, setSaveError] = useState("")
  const [translations, setTranslations] =
    useState<TranslationMap>(blankTranslations)
  const token = useSelector(selectToken)
  return {
    isSubmitted,
    language,
    setLanguage,
    fetchStatus,
    saveStatus,
    fetchError,
    saveError,
    translations,
    meta: translations[language].content,
    handleMeta: (event: ChangeEvent<HTMLInputElement>) =>
      setTranslations({
        ...translations,
        [language]: {
          ...translations[language],
          content: event.currentTarget.value,
        },
      }),
    title: translations[language].title,
    handleTitle: (event: ChangeEvent<HTMLInputElement>) =>
      setTranslations({
        ...translations,
        [language]: {
          ...translations[language],
          title: event.currentTarget.value,
        },
      }),
    keywords: translations[language].metaData,
    handleKeywords: (event: ChangeEvent<HTMLInputElement>) =>
      setTranslations({
        ...translations,
        [language]: {
          ...translations[language],
          metaData: event.currentTarget.value,
        },
      }),
    savePage: async () => {
      setIsSubmitted(true)
      try {
        setSaveStatus(RequestStatus.REQUESTED)
        await requestUpdatePage(token, { translations, id: pageId }, pageId)
        setSaveStatus(RequestStatus.SUCCEEDED)
        // eslint-disable-next-line
      } catch (e: any) {
        setSaveStatus(RequestStatus.FAILED)
        setSaveError(e.message)
      } finally {
        await delay(2000)
        setSaveError("")
        setSaveStatus(RequestStatus.INITIAL)
      }
    },
    fetchPage: async () => {
      try {
        setFetchStatus(RequestStatus.REQUESTED)
        const page = await requestGetPage(token, pageId)
        setTranslations(page.translations)
        setFetchStatus(RequestStatus.SUCCEEDED)
        // eslint-disable-next-line
      } catch (e: any) {
        setFetchStatus(RequestStatus.FAILED)
        setFetchError(e.message)
      } finally {
        await delay(2000)
        setFetchError("")
        setFetchStatus(RequestStatus.INITIAL)
      }
    },
  }
}
