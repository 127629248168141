import React from "react"
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom"
import { useSelector } from "react-redux"
import config from "config"
import SignIn from "pages/SignIn/SignIn"
import PublicLayout from "components/PublicLayout/PublicLayout"
import AuthLayout from "components/AuthLayout/AuthLayout"
import NotFound from "pages/NotFound/NotFound"
import { selectRestorationFetchStatus } from "selectors/interfaceSelector"
import { RequestStatus } from "types/statusTypes"
import SpinnerLayout from "components/SpinnerLayout/SpinnerLayout"
import PageDetail from "../../pages/PageDetail/PageDetail"
import PageList from "../../pages/PageList/PageList"
import Report from "../../pages/Report/Report"
import Reservation from "../../pages/Reservation/Reservation"
import UserDetail from "../../pages/UserDetail/UserDetail"
import AgencyDetail from "../../pages/AgencyDetail/AgencyDetail"
import AgencyList from "../../pages/AgencyList/AgencyList"
import UserList from "../../pages/UserList/UserList"
import ShowList from "../../pages/ShowList/ShowList"
import ActivityList from "../../pages/ActivityList/ActivityList"
import ActivityDetail from "../../pages/ActivityDetail/ActivityDetail"
import PackageList from "../../pages/PackageList/PackageList"
import PackageDetail from "../../pages/PackageDetail/PackageDetail"
import Orders from "../../pages/Orders/Orders"
import ProductList from "../../pages/ProductList/ProductList"
import ProductDetail from "../../pages/ProductDetail/ProductDetail"
import CareerList from "../../pages/CareerList/CareerList"
import CareerDetail from "../../pages/CareerDetail/CareerDetail"
import ShowDetail from "../../pages/ShowDetail/ShowDetail"
import VoucherList from "../../pages/VoucherList/VoucherList"
import VoucherDetail from "../../pages/VoucherDetail/VoucherDetail"
import InvoiceList from "../../pages/InvoiceList/InvoiceList"
import SessionList from "../../pages/SessionList/SessionList"
import UpsaleList from "../../pages/UpsaleList/UpsaleList"
import UpsaleDetail from "../../pages/UpsaleDetail/UpsaleDetail"
import UpsellingList from "../../pages/UpsellingList/UpsellingList"
import UpsellingDetail from "../../pages/UpsellingDetail/UpsellingDetail"
import ForgotPassword from "../../pages/ForgotPassword/ForgotPassword"
import RecoverPassword from "../../pages/RecoverPassword/RecoverPassword"
import Dashboard from "../../pages/Dashboard/Dashboard"
import NewsList from "pages/NewsList/NewsList"
import NewsDetail from "pages/NewsDetail/NewsDetail"
import AuthRoute from "../AuthRoute/AuthRoute"
import OrderEmployee from "../../pages/OrderEmployee/OrderEmployee"

export interface RouterProps {
  isDark?: boolean
  toggleColorScheme?: () => void
}

export default function Router(routerProps: RouterProps) {
  const interfaceFetchStatus = useSelector(selectRestorationFetchStatus)
  return (
    <BrowserRouter>
      {/* glob spinner */}
      <SpinnerLayout
        isVisible={
          interfaceFetchStatus === RequestStatus.REQUESTED ||
          interfaceFetchStatus === RequestStatus.INITIAL
        }
      />
      <Routes>
        <Route element={<PublicLayout />} path={"/public"}>
          <Route path={config.routes.signIn.path()} element={<SignIn />} />
          <Route
            path={config.routes.forgotPassword.path()}
            element={<ForgotPassword />}
          />
          <Route
            path={config.routes.recoverPassword.path()}
            element={<RecoverPassword />}
          />
          <Route path={"/public/*"} element={<NotFound />} />
        </Route>
        <Route element={<AuthLayout {...routerProps} />} path={`/app`}>
          <Route
            path={config.routes.dashboard.path()}
            element={<AuthRoute route={"dashboard"} element={<Dashboard />} />}
          />
          <Route
            path={config.routes.agencyList.path()}
            element={
              <AuthRoute route={"agencyList"} element={<AgencyList />} />
            }
          />
          <Route
            path={config.routes.agencyDetail.path()}
            element={
              <AuthRoute route={"agencyDetail"} element={<AgencyDetail />} />
            }
          />
          <Route
            path={config.routes.agencyDetail.path("")}
            element={
              <AuthRoute route={"agencyDetail"} element={<AgencyDetail />} />
            }
          />
          <Route
            path={config.routes.userList.path()}
            element={<AuthRoute element={<UserList />} route={"userList"} />}
          />
          <Route
            path={config.routes.userDetail.path()}
            element={
              <AuthRoute route={"userDetail"} element={<UserDetail />} />
            }
          />
          <Route
            path={config.routes.userDetail.path("")}
            element={
              <AuthRoute route={"userDetail"} element={<UserDetail />} />
            }
          />
          <Route
            path={config.routes.activityList.path()}
            element={
              <AuthRoute route={"activityList"} element={<ActivityList />} />
            }
          />
          <Route
            path={config.routes.activityDetail.path()}
            element={
              <AuthRoute
                route={"activityDetail"}
                element={<ActivityDetail />}
              />
            }
          />
          <Route
            path={config.routes.activityDetail.path("")}
            element={
              <AuthRoute
                route={"activityDetail"}
                element={<ActivityDetail />}
              />
            }
          />
          <Route
            path={config.routes.packageList.path()}
            element={
              <AuthRoute route={"packageList"} element={<PackageList />} />
            }
          />
          <Route
            path={config.routes.packageDetail.path()}
            element={
              <AuthRoute route={"packageDetail"} element={<PackageDetail />} />
            }
          />
          <Route
            path={config.routes.newsList.path()}
            element={<AuthRoute route={"newsList"} element={<NewsList />} />}
          />
          <Route
            path={config.routes.newsDetail.path()}
            element={
              <AuthRoute route={"newsDetail"} element={<NewsDetail />} />
            }
          />
          <Route
            path={config.routes.newsDetail.path("")}
            element={
              <AuthRoute route={"newsDetail"} element={<NewsDetail />} />
            }
          />
          <Route
            path={config.routes.upsaleList.path()}
            element={
              <AuthRoute route={"upsaleList"} element={<UpsaleList />} />
            }
          />
          <Route
            path={config.routes.upsaleDetail.path()}
            element={
              <AuthRoute route={"upsaleDetail"} element={<UpsaleDetail />} />
            }
          />
          <Route
            path={config.routes.upsaleDetail.path("")}
            element={
              <AuthRoute route={"upsaleDetail"} element={<UpsaleDetail />} />
            }
          />
          <Route
            path={config.routes.ordersList.path()}
            element={<AuthRoute route={"ordersList"} element={<Orders />} />}
          />
          <Route
            path={config.routes.orderEmployee.path()}
            element={<AuthRoute route={"orderEmployee"} element={<OrderEmployee />} />}
          />
          <Route
            path={config.routes.productList.path()}
            element={
              <AuthRoute route={"productList"} element={<ProductList />} />
            }
          />
          <Route
            path={config.routes.showList.path()}
            element={
              <AuthRoute route={"showList"} element={<ShowList />} />
            }
          />
          <Route
            path={config.routes.showDetail.path("")}
            element={
              <AuthRoute route={"showDetail"} element={<ShowDetail />} />
            }
          />
          <Route
            path={config.routes.showDetail.path()}
            element={
              <AuthRoute route={"showDetail"} element={<ShowDetail />} />
            }
          />
          <Route
            path={config.routes.productDetail.path()}
            element={
              <AuthRoute route={"productDetail"} element={<ProductDetail />} />
            }
          />
          <Route
            path={config.routes.productDetail.path("")}
            element={
              <AuthRoute route={"productDetail"} element={<ProductDetail />} />
            }
          />
          <Route
            path={config.routes.report.path()}
            element={<AuthRoute route={"report"} element={<Report />} />}
          />
          <Route
            path={config.routes.reservation.path()}
            element={
              <AuthRoute route={"reservation"} element={<Reservation />} />
            }
          />
          <Route
            path={config.routes.careerList.path()}
            element={
              <AuthRoute route={"careerList"} element={<CareerList />} />
            }
          />
          <Route
            path={config.routes.careerDetail.path()}
            element={
              <AuthRoute route={"careerDetail"} element={<CareerDetail />} />
            }
          />
          <Route
            path={config.routes.upsellingList.path()}
            element={
              <AuthRoute route={"upsellingList"} element={<UpsellingList />} />
            }
          />
          <Route
            path={config.routes.upsellingDetail.path()}
            element={
              <AuthRoute
                route={"upsellingDetail"}
                element={<UpsellingDetail />}
              />
            }
          />
          <Route
            path={config.routes.voucherList.path()}
            element={
              <AuthRoute route={"voucherList"} element={<VoucherList />} />
            }
          />
          <Route
            path={config.routes.voucherDetail.path()}
            element={
              <AuthRoute route={"voucherDetail"} element={<VoucherDetail />} />
            }
          />
          <Route
            path={config.routes.voucherDetail.path('')}
            element={
              <AuthRoute route={"voucherDetail"} element={<VoucherDetail />} />
            }
          />
          <Route
            path={config.routes.pageList.path()}
            element={<AuthRoute route={"pageList"} element={<PageList />} />}
          />
          <Route
            path={config.routes.pageDetail.path()}
            element={
              <AuthRoute route={"pageDetail"} element={<PageDetail />} />
            }
          />
          <Route
            path={config.routes.invoiceList.path()}
            element={
              <AuthRoute route={"invoiceList"} element={<InvoiceList />} />
            }
          />
          <Route
            path={config.routes.sessionList.path()}
            element={
              <AuthRoute route={"sessionList"} element={<SessionList />} />
            }
          />
          <Route path={"/app/*"} element={<NotFound />} />
        </Route>
        <Route
          path={"*"}
          element={<Navigate to={config.routes.signIn.path()} />}
        />
      </Routes>
    </BrowserRouter>
  )
}
