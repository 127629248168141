import { Map } from "immutable"
import React, { useEffect, useState } from "react"
import Summary, { SummaryItem } from "components/Summary/Summary"
import { useLanguage } from "hooks/interfaceHooks"
import { SectionMap } from "../../models/ordersModel"
import { requestGetReservationCapacity } from "../../api/reservationRequests"
import { useToken } from "../../hooks/fetchHooks"
import { useSelector } from "react-redux"
import { selectFilterMap } from "../../selectors/interfaceSelector"
import { Field } from "../../components/Messages/FieldMessage"
import { ReservationCapacityResInner } from "../../types/apiTypes"
import { FormattedMessage } from "react-intl"

type CapacityMap = Map<number, ReservationCapacityResInner>

interface Props {
  sectionMap: SectionMap
}

const ReservationSummary = ({ sectionMap }: Props) => {
  const [capacityMap, setCapacityMap] = useState<CapacityMap>(Map())
  const language = useLanguage()
  const token = useToken()
  const filters = useSelector(selectFilterMap)
  const dtStart = new Date(filters.get(Field.DATE)?.first() || 0).valueOf()

  useEffect(() => {
    requestGetReservationCapacity(token, dtStart).then(({ value }) => {
      setCapacityMap(
        Map(
          Object.entries(value).map(([id, capacity]) => [
            parseInt(id),
            capacity,
          ])
        )
      )
    })
  }, [dtStart, token])

  return (
    <Summary
      summary={sectionMap
        .valueSeq()
        .toArray()
        .map(
          (section): SummaryItem => ({
            title: section.translations[language].title,
            description: (
              <FormattedMessage
                id={"reservationCapacity"}
                defaultMessage={"Section capacity"}
                values={{ ...capacityMap.get(section.id) }}
              />
            ),
          })
        )}
    />
  )
}

export default ReservationSummary
