import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ProductReq } from "../types/apiTypes"
import { RequestStatus } from "../types/statusTypes"
import {
  selectProductDeleteError,
  selectProductDeleteStatus,
  selectProductFetchError,
  selectProductFetchStatus,
  selectProductRecords,
  selectProductSaveError,
  selectProductSaveStatus,
} from "../selectors/productSelector"
import {
  deleteProduct,
  fetchProduct,
  saveProduct,
} from "../actions/productActions"
import { ProductMap } from "../models/productModel"

export function useProductSave(): [
  (data: ProductReq, id?: string) => void,
  RequestStatus,
  string
] {
  const dispatch = useDispatch()
  const status = useSelector(selectProductSaveStatus)
  const error = useSelector(selectProductSaveError)
  return [
    (data: ProductReq, id?: string) =>
      dispatch(saveProduct(data, id ? parseFloat(id) : undefined)),
    status,
    error,
  ]
}

export function useProductDelete(): [
  (id: number) => void,
  RequestStatus,
  string
] {
  const dispatch = useDispatch()
  const status = useSelector(selectProductDeleteStatus)
  const error = useSelector(selectProductDeleteError)
  return [(id: number) => dispatch(deleteProduct(id)), status, error]
}

export function useProductFetch(
  id?: string
): [ProductMap, RequestStatus, string] {
  const dispatch = useDispatch()
  const products = useSelector(selectProductRecords)
  const status = useSelector(selectProductFetchStatus)
  const error = useSelector(selectProductFetchError)
  const product = id ? products.get(id) : null
  useEffect(() => {
    if (!product) dispatch(fetchProduct(id ? [parseFloat(id)] : undefined))
  }, [dispatch, id, product])
  return [products, status, error]
}
