import React, { SyntheticEvent } from "react"
import { Hidden } from "@material-ui/core"
import MenuItem from "@material-ui/core/MenuItem"
import Button from "@material-ui/core/Button"
import Menu from "@material-ui/core/Menu"
import Box from "@material-ui/core/Box"
import LanguageIcon from "@material-ui/icons/Translate"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { useDispatch, useSelector } from "react-redux"
import { selectLocale } from "../../selectors/interfaceSelector"
import { changeLocale } from "../../actions/interfaceActions"
import { Locale, locales } from "../../types/globalTypes"
import LocaleMessage from "../Messages/LocaleMessage"

export default function LocaleSelect() {
  const locale = useSelector(selectLocale)
  const dispatch = useDispatch()
  const [languageMenu, setLanguageMenu] =
    React.useState<HTMLButtonElement | null>(null)

  const handleLanguageIconClick = (
    event: SyntheticEvent<HTMLButtonElement>
  ) => {
    setLanguageMenu(event.currentTarget)
  }

  const handleLanguageMenuClose = () => {
    setLanguageMenu(null)
  }

  const handleLanguageMenuClick = (key: Locale) => {
    dispatch(changeLocale(key))
    setLanguageMenu(null)
  }

  return (
    <>
      <Button
        color="inherit"
        aria-owns={languageMenu ? "language-menu" : undefined}
        aria-haspopup="true"
        aria-label={`todo`}
        onClick={handleLanguageIconClick}
        data-ga-event-category="AppBar"
        data-ga-event-action="language"
      >
        <Hidden smDown>
          <LanguageIcon />
        </Hidden>
        <Box component="span" mx={2}>
          <LocaleMessage locale={locale} />
        </Box>
        <ExpandMoreIcon fontSize="small" />
      </Button>
      <Menu
        id="language-menu"
        anchorEl={languageMenu}
        open={Boolean(languageMenu)}
        onClose={handleLanguageMenuClose}
      >
        {locales.map((key) => (
          <MenuItem
            component="a"
            data-no-link="true"
            key={key}
            selected={locale === key}
            onClick={() => handleLanguageMenuClick(key)}
            lang={key}
            hrefLang={key}
          >
            <LocaleMessage locale={key} />
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
