import React, { ReactNode, Fragment } from "react"
import { IntlProvider as Provider } from "react-intl"
import { useSelector } from "react-redux"
import translations from "../../translations.json"
import { selectLocale } from "../../selectors/interfaceSelector"
import { getDefaultLocale } from "../../models/interfaceModel"
import { Locale } from "../../types/globalTypes"

interface Props {
  children: ReactNode
  overrideLocale?: Locale
}

export default function IntlProvider({ children, overrideLocale }: Props) {
  const locale = useSelector(selectLocale)

  return (
    <Provider
      defaultLocale={getDefaultLocale()}
      textComponent={Fragment}
      messages={translations[overrideLocale || locale]}
      locale={overrideLocale || locale}
    >
      {children}
    </Provider>
  )
}
