import React, { useEffect } from "react"
import { Link, useParams } from "react-router-dom"
import { OrderEmployeeStatus } from "../../types/apiTypes"
import { RequestStatus } from "../../types/statusTypes"
import { useFetchHook } from "../../hooks/fetchHooks"
import { requestSaveEmployeeStatus } from "../../api/orderRequest"
import { FormattedMessage } from "react-intl"
import Grid from "@material-ui/core/Grid"
import { Button, Typography } from "@material-ui/core"
import config from "../../config"
import { ArrowBack } from "@material-ui/icons"
import RouteMessage from "../../components/Messages/RouteMessage"

export default function OrderEmployee() {
  const { status = null, id = null } =
    useParams<{ id: string; status: OrderEmployeeStatus }>()
  const {
    token,
    status: fetchStatus,
    handleRequest,
    handleFail,
    handleSuccess,
  } = useFetchHook()

  useEffect(() => {
    const employeeId = id ? parseInt(id) : null
    if (employeeId && status) {
      handleRequest()
      requestSaveEmployeeStatus(token, employeeId, status)
        .then(handleSuccess)
        .catch(handleFail)
    }
  }, [handleSuccess, handleFail, handleRequest, token, status, id])

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography component={"h1"} variant={"h6"}>
          {status === OrderEmployeeStatus.ACCEPTED && (
            <FormattedMessage
              id={`orderEmployeeStatusAccepted`}
              defaultMessage={`Order confirmation`}
            />
          )}
          {status === OrderEmployeeStatus.DECLINED && (
            <FormattedMessage
              id={`orderEmployeeStatusDeclined`}
              defaultMessage={`Order denial`}
            />
          )}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {fetchStatus === RequestStatus.SUCCEEDED && (
          <FormattedMessage
            id={"orderEmployeeResponseSucceeded"}
            defaultMessage={"Your response was successfully submitted"}
          />
        )}
        {fetchStatus === RequestStatus.FAILED && (
          <FormattedMessage
            id={"orderEmployeeResponseFailed"}
            defaultMessage={"Your response failed"}
          />
        )}
        {(fetchStatus === RequestStatus.REQUESTED ||
          fetchStatus === RequestStatus.INITIAL) && (
          <FormattedMessage
            id={"orderEmployeeResponseRequested"}
            defaultMessage={"Your response is being submitted"}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <Button
          component={Link}
          to={config.routes.ordersList.path()}
          variant={"contained"}
          color={"primary"}
          startIcon={<ArrowBack />}
        >
          <RouteMessage id={"ordersList"} />
        </Button>
      </Grid>
    </Grid>
  )
}
