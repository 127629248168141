import React from "react"
import { FormattedMessage } from "react-intl"
import { PaymentMethod } from "../../types/apiTypes"

interface IPaymentMethodMessageProps {
  id: PaymentMethod
}

export default function PaymentMethodMessage({
  id,
}: IPaymentMethodMessageProps) {
  switch (id) {
    case PaymentMethod.CREDIT_CARD:
      return (
        <FormattedMessage id={`CREDIT_CARD`} defaultMessage={`Credit card`} />
      )
    case PaymentMethod.CASH:
      return <FormattedMessage id={`CASH`} defaultMessage={`Cash`} />
    case PaymentMethod.INVOICE:
      return <FormattedMessage id={`INVOICE`} defaultMessage={`Invoice`} />
    case PaymentMethod.TRANSFER:
      return <FormattedMessage id={`TRANSFER`} defaultMessage={`Transfer`} />
    default:
      return null
  }
}
