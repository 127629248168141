import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ActivityMap } from "../models/activityModel"
import { RequestStatus } from "../types/statusTypes"
import {
  selectActivityDeleteError,
  selectActivityDeleteStatus,
  selectActivityFetchError,
  selectActivityFetchStatus,
  selectActivityRecords,
  selectActivitySaveError,
  selectActivitySaveStatus,
  selectOrderFields,
} from "../selectors/activitySelector"
import {
  deleteActivity,
  fetchActivities,
  saveActivity,
} from "../actions/activityActions"
import { FormField, PackageReq } from "../types/apiTypes"
import { PriceState } from "./priceHooks"
import config from "../config"
import { delayMoment, delayWhile } from "../utils/apiUtils"
import {
  requestSavePackagePrices,
  requestSaveUpsalePrices,
} from "../api/activityRequests"
import { useFetchHook } from "./fetchHooks"
import { AppState } from "../store"
import { sortBy } from "ramda"

export function useActivityFetch(
  id?: string
): [ActivityMap, RequestStatus, string] {
  const dispatch = useDispatch()
  const status = useSelector(selectActivityFetchStatus)
  const error = useSelector(selectActivityFetchError)
  const activities = useSelector(selectActivityRecords)
  useEffect(() => {
    dispatch(fetchActivities(id ? [parseFloat(id)] : undefined))
  }, [dispatch, id])
  return [activities, status, error]
}

export function useActivitySave(): [
  (data: PackageReq, id?: string) => void,
  RequestStatus,
  string
] {
  const dispatch = useDispatch()
  const status = useSelector(selectActivitySaveStatus)
  const error = useSelector(selectActivitySaveError)
  return [
    (data: PackageReq, id?: string) =>
      dispatch(saveActivity(data, id ? parseFloat(id) : undefined)),
    status,
    error,
  ]
}

export function useActivityDelete(): [
  (id: number) => void,
  RequestStatus,
  string
] {
  const dispatch = useDispatch()
  const status = useSelector(selectActivityDeleteStatus)
  const error = useSelector(selectActivityDeleteError)
  return [(id: number) => dispatch(deleteActivity(id)), status, error]
}

export function useActivityPriceSave(
  isUpsale: boolean = false
): [(prices: PriceState, agencyId?: number) => void, RequestStatus, string] {
  const state = useFetchHook()
  const requestFn = isUpsale
    ? requestSaveUpsalePrices
    : requestSavePackagePrices
  return [
    async (priceState) => {
      try {
        state.handleRequest()
        const prices = Object.values(priceState).reduce(
          (acc, curr) => ({ ...acc, ...curr }),
          {}
        )
        await requestFn(state.token, {
          value: Object.entries(prices).map(([optionId, price]) => ({
            agencyId: config.agencyIds.web,
            packageOptionId: parseFloat(optionId),
            deposit: parseFloat(price.deposit || `0`),
            feeOver10Persons: parseFloat(price.fee || `0`),
            price: parseFloat(price.priceUnit || `0`),
            pricePerNCapitas: {
              numberOfPersons: 1,
              price: parseFloat(price.pricePerPerson || `0`),
            },
          })),
        })
        state.handleSuccess()
        await delayMoment()
        state.handleReset()
        // eslint-disable-next-line
      } catch (e: any) {
        state.handleFail(e.message)
        await delayWhile()
        state.handleReset()
      }
    },
    state.status,
    state.error,
  ]
}

export function useRequiredFirstFormFields(
  formFields: FormField[],
  optionId?: number
): [FormField[], (field: FormField) => boolean] {
  const [orderFields, requiredFields] = useSelector((state: AppState) =>
    selectOrderFields(state, optionId)
  )
  const requiredFirst = sortBy((field: FormField) => !requiredFields.has(field))
  return [
    requiredFirst(formFields).filter((field) => orderFields.has(field)),
    (field: FormField) => requiredFields.has(field),
  ]
}
