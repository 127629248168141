import { createSelector } from "reselect"
import { AppState } from "../store"
import { selectFilters, selectLanguage } from "./interfaceSelector"
import { Source } from "../types/filterTypes"

export const selectUpsaleRecords = (state: AppState) => state.upsale.records

export const selectUpsaleFetchStatus = (state: AppState) =>
  state.upsale.statuses.fetchUpsales

export const selectUpsaleSaveStatus = (state: AppState) =>
  state.upsale.statuses.saveUpsale

export const selectUpsaleDeleteStatus = (state: AppState) =>
  state.upsale.statuses.deleteUpsale

export const selectUpsaleFetchError = (state: AppState) =>
  state.upsale.errors.fetchUpsales

export const selectUpsaleSaveError = (state: AppState) =>
  state.upsale.errors.saveUpsale

export const selectUpsaleDeleteError = (state: AppState) =>
  state.upsale.errors.deleteUpsale

export const selectUpsaleList = createSelector(selectUpsaleRecords, (map) =>
  map.valueSeq().toArray()
)

export const selectUpsellingDataGrid = createSelector(
  selectUpsaleList,
  (list) =>
    list.map((upsale) => ({ ...upsale })).filter((upsale) => upsale.isForWeb)
)

export const selectUpsaleDataGrid = createSelector(
  selectUpsaleList,
  selectFilters,
  (list, filters) => {
    const filterSource = filters.SOURCE
    return (
      list
        // https://github.com/mbrn/material-table/issues/1371
        .map((upsale) => ({ ...upsale }))
        .filter(
          (upsale) =>
            (filterSource.has(Source.WEB) ? upsale.isForWeb : true) &&
            (filterSource.has(Source.AGENCY) ? !upsale.isForWeb : true)
        )
    )
  }
)

export const selectAgencyUpsales = createSelector(
  selectUpsaleRecords,
  (upsales) => upsales.filter(({ isForWeb }) => !isForWeb)
)

export const selectUpsaleInputOptions = createSelector(
  selectUpsaleList,
  selectLanguage,
  (state: AppState, isWeb: boolean, isAgency: boolean) => [isWeb, isAgency],
  (upsales, language, [isWeb, isAgency]) =>
    upsales
      .filter(({ isForWeb }) =>
        isWeb ? isForWeb : isAgency ? !isForWeb : false
      )
      .map(({ id, translations }) => ({
        value: id.toString(),
        content: translations[language].title,
      }))
)
