import React from "react"
import { FormattedMessage } from "react-intl"
import { Period } from "../../types/filterTypes"

interface IPeriodMessageProps {
  id: Period
}

export default function PeriodMessage({ id }: IPeriodMessageProps) {
  switch (id) {
    case Period.THIS_WEEK:
      return <FormattedMessage id={`thisWeek`} defaultMessage={`This week`} />
    case Period.THIS_MONTH:
      return <FormattedMessage id={`thisMonth`} defaultMessage={`This month`} />
    case Period.LAST_WEEK:
      return <FormattedMessage id={`lastWeek`} defaultMessage={`Last week`} />
    case Period.LAST_MONTH:
      return <FormattedMessage id={`lastMonth`} defaultMessage={`Last month`} />
    case Period.CUSTOM:
      return <FormattedMessage id={`custom`} defaultMessage={`Custom`} />
    case Period.NEXT_WEEK:
      return <FormattedMessage id={`nextWeek`} defaultMessage={`Next week`} />
    case Period.NEXT_MONTH:
      return <FormattedMessage id={`nextMonth`} defaultMessage={`Next month`} />
    case Period.TODAY:
      return <FormattedMessage id={`today`} defaultMessage={`Today`} />
    case Period.GF_TODAY:
      return <FormattedMessage id={`gfToday`} defaultMessage={`GF Today`} />
    case Period.TOMORROW:
      return <FormattedMessage id={`tomorrow`} defaultMessage={`Tomorrow`} />
    default:
      return null
  }
}
