import performRequest, { getIdsParam } from "../utils/apiUtils"
import { HttpMethods } from "../types/globalTypes"
import { InvoiceResAdmin } from "../types/apiTypes"
import config from "../config"

export async function requestInvoices(
  basicAuth: string,
  ids?: number[]
): Promise<InvoiceResAdmin[]> {
  return performRequest({
    endpoint: `invoices${getIdsParam(ids)}`,
    auth: basicAuth,
    method: HttpMethods.GET,
  })
}

export async function requestDownloadInvoice(
  basicAuth: string,
  id: number
): Promise<Blob> {
  return fetch(`${config.privateEndpoint}/invoices/pdf/${id}`, {
    method: HttpMethods.GET,
    headers: {
      Authorization: basicAuth,
    },
  }).then((res) => res.blob())
}
