import React from "react"
import Avatar from "@material-ui/core/Avatar"
import Image from "../Image/Image"
// @ts-ignore
import vimeoIcon from "./vimeo.png"
import { isSourceVideo } from "../../utils/typeUtils"

interface IThumbnailProps {
  source: File | string
  alt?: string
  link?: boolean
  size?: "small" | "medium" | "large"
}

const styleMap = {
  small: { width: 40, height: 40 },
  medium: { width: 80, height: 80 },
  large: { width: 160, height: 160 },
}

export default function Thumbnail({
  source,
  alt,
  size = "small",
  link = false,
}: IThumbnailProps) {
  return (
    <Image
      source={source}
      render={(src: string) => {
        const image = (
          <Avatar
            src={isSourceVideo(src) ? vimeoIcon : src}
            alt={alt}
            variant={"rounded"}
            style={styleMap[size]}
          />
        )
        return link ? (
          <a href={src} target="_blank" rel="noopener noreferrer">
            {image}
          </a>
        ) : (
          image
        )
      }}
    />
  )
}
