import React, { ReactNode, useState, useEffect } from "react"
import { Portal, Snackbar } from "@material-ui/core"
import { AlertTitle, Alert, Color } from "@material-ui/lab"
import useStyles from "./ToastStyle"

interface IToastProps {
  open: boolean
  handleClose?: () => void
  severity?: Color
  message?: ReactNode
  title: ReactNode
}

export default function Toast({
  open,
  handleClose,
  severity = "info",
  message,
  title,
}: IToastProps) {
  const classes = useStyles()

  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setIsOpen(open)
  }, [open, message])

  const handleOnClose = () => {
    handleClose ? handleClose() : setIsOpen(false)
  }

  return (
    <Portal>
      <Snackbar
        open={isOpen}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        onClose={handleOnClose}
      >
        <Alert severity={severity} className={classes.root}>
          <AlertTitle>{title}</AlertTitle>
          {message}
        </Alert>
      </Snackbar>
    </Portal>
  )
}
