import { all, takeLatest } from "redux-saga/effects"
import { deleteUserSaga, fetchUsersSaga, saveUserSaga } from "./userSaga"
import {
  authenticateUserSaga,
  changeLocaleSaga,
  logoutUserSaga,
  restoreInterfaceSaga,
} from "./interfaceSaga"
import { ActionTypes } from "../types/actionTypes"
import { fetchAgenciesSaga } from "./agencySaga"
import {
  deleteActivitySaga,
  fetchActivitiesSaga,
  saveActivitySaga,
  fetchResourcesSaga,
} from "./activitySaga"
import {
  fetchOrdersSaga,
  fetchSeatingSaga,
  fetchSectionsSaga,
  saveEmployeeStatusSaga,
  fetchConflictsSaga,
  sendVoucherEmailSaga,
} from "./orderSaga"
import {
  fetchProductsSaga,
  saveProductSaga,
  deleteProductSaga,
} from "./productsSaga"
import {
  deleteCareerSaga,
  fetchCareersSaga,
  saveCareersSaga,
} from "./careerSaga"
import {
  deleteUpsaleSaga,
  fetchUpsalesSaga,
  saveUpsaleSaga,
} from "./upsaleSaga"
import { fetchInvoicesSaga } from "./invoiceSaga"
import { fetchSessionsSaga } from "./sessionSaga"
import { deleteNewsSaga, fetchNewsSaga, saveNewsSaga } from "./newsSaga"
import { deleteShowSaga, fetchShowSaga, saveShowSaga } from "./showSaga"

function* watchForSaveEmployeeStatus() {
  yield takeLatest(
    ActionTypes.SAVE_EMPLOYEE_STATUS_REQUESTED,
    saveEmployeeStatusSaga
  )
}

function* watchForFetchUsers() {
  yield takeLatest(ActionTypes.FETCH_USERS_REQUESTED, fetchUsersSaga)
}

function* watchForFetchConflicts() {
  yield takeLatest(ActionTypes.FETCH_CONFLICTS_REQUESTED, fetchConflictsSaga)
}

function* watchForSaveUser() {
  yield takeLatest(ActionTypes.SAVE_USER_REQUESTED, saveUserSaga)
}

function* watchForDeleteUser() {
  yield takeLatest(ActionTypes.DELETE_USER_REQUESTED, deleteUserSaga)
}

function* watchForUserLogin() {
  yield takeLatest(ActionTypes.LOG_USER_REQUESTED, authenticateUserSaga)
}

function* watchForUserLogout() {
  yield takeLatest(ActionTypes.LOGOUT_USER_REQUESTED, logoutUserSaga)
}

function* watchForRestoreInterface() {
  yield takeLatest(
    ActionTypes.RESTORE_INTERFACE_REQUESTED,
    restoreInterfaceSaga
  )
}

function* watchForChangeLocale() {
  yield takeLatest(ActionTypes.CHANGE_LOCALE_REQUESTED, changeLocaleSaga)
}

function* watchFormFetchAgencies() {
  yield takeLatest(ActionTypes.FETCH_AGENCIES_REQUESTED, fetchAgenciesSaga)
}

function* watchForSaveActivity() {
  yield takeLatest(ActionTypes.SAVE_ACTIVITY_REQUESTED, saveActivitySaga)
}

function* watchForDeleteActivity() {
  yield takeLatest(ActionTypes.DELETE_ACTIVITY_REQUESTED, deleteActivitySaga)
}

function* watchFormFetchActivities() {
  yield takeLatest(ActionTypes.FETCH_ACTIVITIES_REQUESTED, fetchActivitiesSaga)
}

function* watchOrdersFetch() {
  yield takeLatest(
    [
      ActionTypes.FETCH_ORDERS_REQUESTED,
      ActionTypes.SET_ORDER_PAGE_NUMBER,
      ActionTypes.SET_ORDER_PAGE_SIZE,
      ActionTypes.SET_ORDER_FILTER,
      ActionTypes.SET_ORDER_SEARCH_QUERY,
      ActionTypes.SET_ORDER_SORT_TYPE
    ],
    fetchOrdersSaga
  )
}

function* watchSectionsFetch() {
  yield takeLatest(ActionTypes.FETCH_SECTIONS_REQUESTED, fetchSectionsSaga)
}

function* watchSeatingFetch() {
  yield takeLatest(ActionTypes.FETCH_SEATING_REQUESTED, fetchSeatingSaga)
}

function* watchProductsFetch() {
  yield takeLatest(ActionTypes.FETCH_PRODUCT_REQUESTED, fetchProductsSaga)
}

function* watchForSaveProduct() {
  yield takeLatest(ActionTypes.SAVE_PRODUCT_REQUESTED, saveProductSaga)
}

function* watchForDeleteProduct() {
  yield takeLatest(ActionTypes.DELETE_PRODUCT_REQUESTED, deleteProductSaga)
}

function* watchForResourcesFetch() {
  yield takeLatest(ActionTypes.FETCH_RESOURCES_REQUESTED, fetchResourcesSaga)
}

function* watchForCareerFetch() {
  yield takeLatest(ActionTypes.FETCH_CAREER_REQUESTED, fetchCareersSaga)
}

function* watchForCareerSave() {
  yield takeLatest(ActionTypes.SAVE_CAREER_REQUESTED, saveCareersSaga)
}

function* watchForCareerDelete() {
  yield takeLatest(ActionTypes.DELETE_CAREER_FAILED, deleteCareerSaga)
}

function* watchForUpsaleFetch() {
  yield takeLatest(ActionTypes.FETCH_UPSALES_REQUESTED, fetchUpsalesSaga)
}

function* watchForUpsaleSave() {
  yield takeLatest(ActionTypes.SAVE_UPSALE_REQUESTED, saveUpsaleSaga)
}

function* watchForUpsaleDelete() {
  yield takeLatest(ActionTypes.DELETE_UPSALE_REQUESTED, deleteUpsaleSaga)
}

function* watchForInvoiceFetch() {
  yield takeLatest(ActionTypes.FETCH_INVOICES_REQUESTED, fetchInvoicesSaga)
}

function* watchForSessionsFetch() {
  yield takeLatest(ActionTypes.FETCH_SESSIONS_REQUESTED, fetchSessionsSaga)
}

function* watchForSendVoucherEmail() {
  yield takeLatest(
    ActionTypes.SEND_VOUCHER_EMAIL_REQUESTED,
    sendVoucherEmailSaga
  )
}

function* watchForNewsFetch() {
  yield takeLatest(ActionTypes.FETCH_NEWS_REQUESTED, fetchNewsSaga)
}

function* watchForSaveNews() {
  yield takeLatest(ActionTypes.SAVE_NEWS_REQUESTED, saveNewsSaga)
}

function* watchForDeleteNews() {
  yield takeLatest(ActionTypes.DELETE_NEWS_REQUESTED, deleteNewsSaga)
}

function* watchForShowFetch() {
  yield takeLatest(ActionTypes.FETCH_SHOWS_REQUESTED, fetchShowSaga)
}

function* watchForSaveShow() {
  yield takeLatest(ActionTypes.SAVE_SHOW_REQUESTED, saveShowSaga)
}

function* watchForDeleteShow() {
  yield takeLatest(ActionTypes.DELETE_SHOW_REQUESTED, deleteShowSaga)
}

export default function* rootSaga() {
  yield all([
    watchForSaveEmployeeStatus(),
    watchForDeleteUser(),
    watchForSaveUser(),
    watchFormFetchAgencies(),
    watchFormFetchActivities(),
    watchForSaveActivity(),
    watchForDeleteActivity(),
    watchForFetchUsers(),
    watchForUserLogin(),
    watchForRestoreInterface(),
    watchForUserLogout(),
    watchForChangeLocale(),
    watchOrdersFetch(),
    watchSectionsFetch(),
    watchSeatingFetch(),
    watchProductsFetch(),
    watchForSaveProduct(),
    watchForDeleteProduct(),
    watchForResourcesFetch(),
    watchForCareerFetch(),
    watchForCareerSave(),
    watchForCareerDelete(),
    watchForInvoiceFetch(),
    watchForSessionsFetch(),
    watchForUpsaleFetch(),
    watchForUpsaleSave(),
    watchForUpsaleDelete(),
    watchForFetchConflicts(),
    watchForSendVoucherEmail(),
    watchForNewsFetch(),
    watchForSaveNews(),
    watchForDeleteNews(),
    watchForShowFetch(),
    watchForSaveShow(),
    watchForDeleteShow(),
  ])
}
