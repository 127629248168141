import React, { ReactNode } from "react"
import { FormattedMessage } from "react-intl"
import clsx from "clsx"
import Button from "@material-ui/core/Button"
import { Fab, Tab } from "@material-ui/core"
import AddIcon from "@material-ui/icons/Add"
import useStyles from "./EntityListStyles"
import Sortable from "../Sortable/Sortable"

interface IProps {
  entityPairs: [string, string][]
  children: ReactNode
  onCreate?: () => void
  onDelete?: () => void
  onSort?: (sourceIndex: number, destinationIndex: number) => void
  onEntityChange: (key: string) => void
  activeEntity: string | null
}

export default function EntityList({
  entityPairs,
  children,
  onEntityChange,
  onCreate,
  onDelete,
  onSort,
  activeEntity,
}: IProps) {
  const classes = useStyles()

  function handleChange(event: React.ChangeEvent<{}>, newValue: string) {
    onEntityChange(newValue)
  }

  return (
    <div className={classes.root}>
      <Sortable
        id={`entityList`}
        onSort={onSort}
        parentProps={{ as: "main", className: classes.tabs }}
        isHorizontal
      >
        {entityPairs.map(([key, label]) => (
          <Tab
            key={key}
            label={
              label || (
                <FormattedMessage id={`empty`} defaultMessage={`Empty`} />
              )
            }
            className={clsx(
              classes.tab,
              key === activeEntity && classes.activeTab
            )}
            onClick={(event) => handleChange(event, key)}
            fullWidth
          />
        ))}
      </Sortable>
      {onCreate && (
        <Fab
          color="primary"
          aria-label="add"
          onClick={() => onCreate()}
          size={"small"}
          className={classes.addButton}
        >
          <AddIcon />
        </Fab>
      )}
      <div className={classes.content}>
        {children}
        {onDelete && activeEntity && (
          <Button
            variant={"contained"}
            onClick={onDelete}
            className={classes.deleteButton}
          >
            <FormattedMessage id={`delete`} defaultMessage={`Delete`} />
          </Button>
        )}
      </div>
    </div>
  )
}
