import { PageReq, PageResAdmin } from "../types/apiTypes"
import performRequest, { getIdParam } from "../utils/apiUtils"
import { HttpMethods } from "../types/globalTypes"

export async function requestGetPage(
  basicAuth: string,
  id: number
): Promise<PageResAdmin> {
  return performRequest(
    {
      endpoint: `page${getIdParam(id)}`,
      auth: basicAuth,
      method: HttpMethods.GET,
    },
    true
  )
}

export async function requestUpdatePage(
  basicAuth: string,
  body: PageReq,
  id: number
): Promise<PageResAdmin> {
  return performRequest(
    {
      endpoint: `page${getIdParam(id)}`,
      auth: basicAuth,
      method: HttpMethods.PUT,
      body,
    },
    true
  )
}
