import { makeStyles } from "@material-ui/core/styles"
import { Theme } from "@material-ui/core"

export default makeStyles((theme: Theme) => ({
  root: {
    tableLayout: "fixed",
    width: "100%",
    fontSize: theme.typography.h6.fontSize,
    "& td": {
      verticalAlign: "top",
      paddingTop: 4,
      paddingBottom: 4,
    },
    "& th": {
      verticalAlign: "top",
      paddingTop: 4,
      paddingBottom: 4,
      textAlign: "left",
      width: "38%",
    },
  },
  content: {
    padding: 0,
    margin: 0,
    listStyle: "none",
  },
  surcharge: {
    fontWeight: "bold",
  },
}))
