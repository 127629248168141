import React from "react"
import Box from "@material-ui/core/Box"
import { useDispatch } from "react-redux"
import { FormattedMessage } from "react-intl"
import { Alert } from "@material-ui/lab"
import LoadingButton from "../../components/LoadingButton/LoadingButton"
import { RequestStatus } from "../../types/statusTypes"
import Button from "../../components/Button/Button"
import { logoutUser } from "../../actions/interfaceActions"
import Modal from "../../components/Modal/Modal"
import ErrorMessage, { ErrorType } from "../../components/Messages/ErrorMessage"

interface IVoucherDetailModalProps {
  isOpen: boolean
  name: string
  status: RequestStatus
  onConfirm: () => void
}

export default function VoucherDetailModal({
  isOpen,
  name,
  status,
  onConfirm,
}: IVoucherDetailModalProps) {
  const dispatch = useDispatch()

  const handleCancel = () => {
    dispatch(logoutUser())
  }

  return (
    <Modal
      open={isOpen}
      title={
        <FormattedMessage
          id={`loggedAs`}
          defaultMessage={`Logged as: {name}`}
          values={{ name }}
        />
      }
    >
      {status === RequestStatus.FAILED && (
        <Box mb={4}>
          <Alert severity="error">
            <ErrorMessage id={ErrorType.UNAUTHORIZED} />
          </Alert>
        </Box>
      )}
      <LoadingButton
        isLoading={status === RequestStatus.REQUESTED}
        color={"primary"}
        variant={"contained"}
        onClick={onConfirm}
      >
        <FormattedMessage
          id={`startSession`}
          defaultMessage={`Start session`}
        />
      </LoadingButton>
      <Button color={"primary"} variant={"outlined"} onClick={handleCancel}>
        <FormattedMessage id={`changeUser`} defaultMessage={`Change user`} />
      </Button>
    </Modal>
  )
}
