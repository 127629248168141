import React, { useEffect } from "react"
import Form from "../../components/Form/Form"
import Input from "../../components/Input/Input"
import LanguageTab from "../../components/LanguageTab/LanguageTab"
import config from "../../config"
import Results from "../../components/Results/Results"
import WebPageMessage, {
  WebPage,
} from "../../components/Messages/WebPageMessage"
import { Field } from "../../components/Messages/FieldMessage"
import { Entity } from "../../components/Messages/EntityMessage"
import { usePageSharedState } from "../../hooks/stateHooks"
import { languageList } from "../../utils/typeUtils"

export default function PageContact() {
  const state = usePageSharedState(config.pageIds.contact)

  useEffect(() => {
    state.fetchPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = async () => {
    await state.savePage()
  }

  return (
    <>
      <Results
        statuses={[state.saveStatus, state.fetchStatus]}
        errors={[state.saveError, state.fetchError]}
        actions={["save", "fetch"]}
        entity={Entity.PAGE}
      />
      <Form
        title={<WebPageMessage id={WebPage.CONTACT} />}
        onSubmit={handleSubmit}
        statuses={[state.saveStatus, state.fetchStatus]}
        toolbar={
          <LanguageTab
            language={state.language}
            onChange={state.setLanguage}
            notifications={languageList.filter((lang) => {
              const { content, title, metaData } = state.translations[lang]
              return !content || !title || !metaData
            })}
          />
        }
        useGenericCta
      >
        <Input
          name={`title`}
          messageId={Field.TITLE}
          onChange={state.handleTitle}
          value={state.title}
          showRequiredWarning={state.isSubmitted}
        />
        <Input
          name={`meta`}
          messageId={Field.META}
          onChange={state.handleMeta}
          value={state.meta}
          showRequiredWarning={state.isSubmitted}
        />
        <Input
          name={`keywords`}
          messageId={Field.KEYWORDS}
          onChange={state.handleKeywords}
          value={state.keywords}
          showRequiredWarning={state.isSubmitted}
        />
      </Form>
    </>
  )
}
