import { createSelector } from "reselect"
import { getAllOrderResEmployees } from "../utils/typeUtils"
import { Set } from "immutable"
import { selectEmployeesList } from "./userSelector"
import { ReportDataGrid, ReportEmployeeDataGrid } from "../types/globalTypes"
import { selectOrders, selectOrdersList } from "./orderSelector"
import { selectAgencyRecords } from "./agencySelector"
import { OrderResAdmin } from "../types/apiTypes"
import { AgencyMap } from "../models/agencyModel"

export const selectReport = createSelector(
  selectOrdersList,
  selectAgencyRecords,
  (orders: OrderResAdmin[], agencyMap: AgencyMap): ReportDataGrid[] =>
  orders.filter(
    ({ packageOrder }) => packageOrder && packageOrder.dtStart <= Date.now()
  ).map((order: OrderResAdmin) => ({
    ...order,
    agencyName: agencyMap.get(order.agencyId.toString())?.title,
    employeeReward: getAllOrderResEmployees(order).reduce(
      (acc: number, { reward }) => acc + reward,
      0
    )
  }))
)

interface Summary {
  guests: number
  turnover: number
  staffCosts: number
  externalCosts: number
  activities: number
  profit: number
}

const reportSummary = {
  activities: Set<number>([]),
  guests: 0,
  turnover: 0,
  staffCosts: 0,
  externalCosts: 0
}
export const selectReportSummary = createSelector(
  selectReport,
  (orders): Summary => {
    const summary = orders.reduce(
      (reduction: typeof reportSummary, order) => ({
        turnover: reduction.turnover + order.price,
        activities: order.packageOrder
          ? reduction.activities.has(order.packageOrder.option.packageId)
            ? reduction.activities
            : reduction.activities.add(order.packageOrder.option.packageId)
          : reduction.activities,
        guests:
          reduction.guests +
          (order.packageOrder
            ? order.packageOrder.numberOfPersons
            : order.reservation
              ? order.reservation.numberOfPersons
              : 0),
        staffCosts:
          reduction.staffCosts +
          getAllOrderResEmployees(order).reduce(
            (acc, { reward }) => acc + reward,
            0
          ),
        externalCosts: reduction.externalCosts + order.cost
      }),
      reportSummary
    )
    return {
      ...summary,
      activities: summary.activities.size,
      profit: summary.turnover - summary.externalCosts - summary.staffCosts
    }
  }
)
export const selectReportEmployeeDataGrid = createSelector(
  selectEmployeesList,
  selectOrders,
  (employeeList, orders) =>
    // TODO: refactor
    employeeList.map(
      (employee): ReportEmployeeDataGrid =>
        orders
          .valueSeq()
          .toArray()
          .flatMap((order) => getAllOrderResEmployees(order))
          .reduce(
            (acc: ReportEmployeeDataGrid, item) => {
              if (item.userId === employee.id) {
                const orderData = orders.get(item.orderId.toString())
                return {
                  ...acc,
                  reward: acc.reward + item.reward,
                  activities: orderData
                    ? [...acc.activities, orderData]
                    : [...acc.activities]
                }
              }
              return acc
            },
            { ...employee, reward: 0, activities: [] }
          )
    )
)