import React from "react"
import { FormattedMessage } from "react-intl"
import { Role } from "../../types/apiTypes"

interface IRoleMessageProps {
  id: Role
}

export default function RoleMessage({ id }: IRoleMessageProps) {
  switch (id) {
    case "DanceManager":
      return (
        <FormattedMessage id={`DanceManager`} defaultMessage={`Dance manager`} />
      )
    case "DancerToken":
      return (
        <FormattedMessage id={`DancerToken`} defaultMessage={`Dancer (chip)`} />
      )
    case "Admin":
      return <FormattedMessage id={`Admin`} defaultMessage={`Admin`} />
    case "DancerClub":
      return (
        <FormattedMessage id={`DancerClub`} defaultMessage={`Club Dancer`} />
      )
    case "Agency":
      return <FormattedMessage id={`Agency`} defaultMessage={`Agency`} />
    case "CoordinatorExternal":
      return (
        <FormattedMessage
          id={`CoordinatorExternal`}
          defaultMessage={`External Coordinator`}
        />
      )
    case "CoordinatorInternal":
      return (
        <FormattedMessage
          id={`CoordinatorInternal`}
          defaultMessage={`Internal Coordinator`}
        />
      )
    case "Dancer":
      return <FormattedMessage id={`Dancer`} defaultMessage={`Dancer`} />
    case "DancerMale":
      return (
        <FormattedMessage id={`DancerMale`} defaultMessage={`Male dancer`} />
      )
    case "DancerXXL":
      return <FormattedMessage id={`DancerXXL`} defaultMessage={`XXL dancer`} />
    case "Driver":
      return <FormattedMessage id={`Driver`} defaultMessage={`Driver`} />
    case "Hostess":
      return <FormattedMessage id={`Hostess`} defaultMessage={`Hostess`} />
    case "Manager":
      return <FormattedMessage id={`Manager`} defaultMessage={`Manager`} />
    case "Other":
      return <FormattedMessage id={`Other`} defaultMessage={`Other`} />
    case "Reservations":
      return (
        <FormattedMessage id={`Reservations`} defaultMessage={`Reservations`} />
      )
    case "Security":
      return <FormattedMessage id={`Security`} defaultMessage={`Security`} />
    case "Steward":
      return <FormattedMessage id={`Steward`} defaultMessage={`Steward`} />
    case "SuperAdmin":
      return (
        <FormattedMessage id={`SuperAdmin`} defaultMessage={`SuperAdmin`} />
      )
    default:
      return null
  }
}
