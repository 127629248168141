import { addWeeks, formatISO } from "date-fns"
import { Record, RecordOf, Set } from "immutable"
import { RequestStatus } from "../types/statusTypes"
import { Locale } from "../types/globalTypes"
import { OrderStatus, UserRes } from "../types/apiTypes"
import { Field } from "../components/Messages/FieldMessage"
import { Entity } from "../components/Messages/EntityMessage"
import { OPTION_ALL } from "utils/typeUtils"

export const defaultFilters = {
  [Field.ROLE]: Set<string>(),
  [Field.EMPLOYEES]: Set<string>(),
  [Field.SOURCE]: Set<string>([OPTION_ALL]),
  [Field.TYPE]: Set<string>(),
  [Field.PAYMENT_METHOD]: Set<string>([OPTION_ALL]),
  [Field.DATE]: Set<string>([
    formatISO(new Date()),
    formatISO(addWeeks(new Date(), 1)),
  ]),
  [Field.ORDER_STATUS]: Set<string>([
    OrderStatus.CREATED,
    OrderStatus.APPROVED,
  ]),
  [Field.SESSION_STATUS]: Set<string>([]),
  [Field.INVOICE_STATUS]: Set<string>([
    OrderStatus.CREATED,
    OrderStatus.APPROVED,
  ]),
  [Field.ACTIVITY]: Set<string>(),
  [Field.AGENCY]: Set<string>(),
  // entity ids
  [Entity.ORDER]: Set<string>(),
}

const makeFilters: Record.Factory<typeof defaultFilters> =
  Record(defaultFilters)

const defaultStatuses = {
  loginUser: RequestStatus.INITIAL,
  logoutUser: RequestStatus.INITIAL,
  restoreInterface: RequestStatus.INITIAL,
  changeLocale: RequestStatus.INITIAL,
}

const makeStatuses: Record.Factory<typeof defaultStatuses> =
  Record(defaultStatuses)

const defaultErrors = {
  loginUser: "",
  logoutUser: "",
  restoreInterface: "",
  changeLocale: "",
}

export function getDefaultLocale(): Locale {
  if (navigator.language.startsWith("cs")) return "cs"
  return "cs"
}

const makeErrors: Record.Factory<typeof defaultErrors> = Record(defaultErrors)

type InterfaceStateType = {
  statuses: RecordOf<typeof defaultStatuses>
  errors: RecordOf<typeof defaultErrors>
  user: UserRes | null
  token: string
  locale: Locale
  zoom: number
  filters: RecordOf<typeof defaultFilters>
  backlink: string | null
}

const defaultInterfaceState: InterfaceStateType = {
  statuses: makeStatuses(),
  errors: makeErrors(),
  user: null,
  token: "",
  locale: getDefaultLocale(),
  zoom: 100,
  filters: makeFilters(),
  backlink: null,
}

export type FilterKeys = keyof typeof defaultFilters

export type InterfaceState = RecordOf<InterfaceStateType>

export const makeInterfaceState: Record.Factory<InterfaceStateType> = Record(
  defaultInterfaceState
)
