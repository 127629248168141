import React, { ChangeEvent, useState } from "react"
import { Set } from "immutable"
import Button from "@material-ui/core/Button"
import { FormattedMessage } from "react-intl"
import { ExpandMore } from "@material-ui/icons"
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import { Box, Typography } from "@material-ui/core"
import { useDispatch, useSelector } from "react-redux"
import {
  Conflict,
  selectHeededConflictCount,
  selectConflictsGroupedByOrderPairs,
  selectOrderFilter,
} from "../../selectors/orderSelector"
import useStyles from "./OrderConflictsStyle"
import { Entity } from "../Messages/EntityMessage"
import { setOrderFilter } from "../../actions/orderActions"
import { OrderConflictResElement } from "../../types/apiTypes"
import {
  requestSaveEmployeeConflictIgnore,
  requestSaveResourceConflictIgnore,
} from "../../api/orderRequest"
import { useFetchHook } from "../../hooks/fetchHooks"
import FieldMessage, { Field } from "../Messages/FieldMessage"
import { formatDateTime, formatTime } from "../../utils/timeUtils"
import Modal from "../Modal/Modal"

const createKey = ({
  entityId,
  orderId1,
  orderId2,
}: OrderConflictResElement): string => `${entityId}-${orderId1}-${orderId2}`

export default function OrderConflicts() {
  const state = useFetchHook()
  const conflicts = useSelector(selectConflictsGroupedByOrderPairs)
  const count = useSelector(selectHeededConflictCount)
  const { button, conflicts: list, title, ...classes } = useStyles()
  const dispatch = useDispatch()
  const filters = useSelector(selectOrderFilter)
  const [ignored, setIgnored] = useState<Set<string>>(Set())
  const [confirmConflict, setConfirmConflict] = useState<Conflict | null>(null)
  const idsToResolve = filters[Entity.ORDER]

  if (conflicts.length === 0) return null

  const handleResolve = (ids: number[]) => () => {
    dispatch(
      setOrderFilter(
        Entity.ORDER,
        ids.map((id) => id.toString())
      )
    )
  }

  const handleCancel = () => {
    dispatch(setOrderFilter(Entity.ORDER, []))
  }

  const handleChange = (event: ChangeEvent<{}>, expanded: boolean) => {
    if (!expanded) handleCancel()
  }

  const handleConfirmIgnore = (conflict: Conflict) => () => {
    setConfirmConflict(conflict)
  }

  const handleModalClose = () => {
    setConfirmConflict(null)
  }

  const handleIgnore = async () => {
    const conflict = confirmConflict as Conflict
    const key = createKey(conflict)
    try {
      setIgnored((set) => set.add(key))
      state.handleRequest()
      const request =
        conflict.type === "employee"
          ? requestSaveEmployeeConflictIgnore
          : requestSaveResourceConflictIgnore
      await request(state.token, {
        firstId: conflict.orderId1,
        secondId: conflict.orderId2,
      })
      state.handleSuccess()
    } catch {
      setIgnored((set) => set.delete(key))
      state.handleFail("error")
    } finally {
      setConfirmConflict(null)
    }
  }


  return (
    <>
      <Accordion onChange={handleChange}>
        <AccordionSummary expandIcon={<ExpandMore />} classes={classes}>
          <FormattedMessage
            id={`resolveConflicts`}
            defaultMessage={`Number of conflicts: {count}`}
            values={{ count }}
          />
        </AccordionSummary>
        {conflicts.flatMap(([_, items]) =>
          items.map((conflict: Conflict, index) => {
            const key = createKey(conflict)
            const isResolving =
              idsToResolve.has(conflict.orderId1.toString()) &&
              idsToResolve.has(conflict.orderId2.toString())
            return (
              <AccordionDetails key={key}>
                <Box flexBasis={200} flexShrink={0} fontWeight={"bold"}>
                  {index === 0 && (
                    <>
                      <h3 className={title}>
                        {conflict.order1Title} 💥 <br/>
                        {conflict.order2Title}
                      </h3>
                      <Button
                        size={"small"}
                        variant={"outlined"}
                        color={isResolving ? "default" : "secondary"}
                        className={button}
                        onClick={
                          isResolving
                            ? handleCancel
                            : handleResolve([
                              conflict.orderId1,
                              conflict.orderId2,
                            ])
                        }
                      >
                        {isResolving ? (
                          <FormattedMessage
                            id={`cancel`}
                            defaultMessage={`Cancel`}
                          />
                        ) : (
                          <FormattedMessage
                            id={`resolveConflict`}
                            defaultMessage={`Resolve`}
                          />
                        )}
                      </Button>
                    </>
                  )}
                </Box>
                <Box flexBasis={300} ml={8} component={"ul"} mt={0} mb={0}>
                  <Typography variant={"button"}>
                    <FieldMessage
                      id={
                        conflict.type === "employee"
                          ? Field.EMPLOYEE
                          : Field.GROUP
                      }
                    />
                  </Typography>
                  <div>{conflict.entityTitle}</div>
                  <ul className={list}>
                    {conflict.conflictIntervals.map((i) => (
                      <FormattedMessage
                        tagName={"li"}
                        key={`${i.dtStart}-${i.dtEnd}`}
                        id={"conflictInterval"}
                        defaultMessage={"From {dtStart} to {dtEnd}"}
                        values={{
                          dtStart: formatDateTime(i.dtStart),
                          dtEnd: formatTime(i.dtEnd),
                        }}
                      />
                    ))}
                  </ul>
                </Box>
                <Box ml={8} display={"flex"} alignItems={"start"}>
                  <Button
                    size={"small"}
                    variant={"text"}
                    color={"secondary"}
                    className={button}
                    onClick={handleConfirmIgnore(conflict)}
                    disabled={conflict.isIgnored || ignored.has(key)}
                  >
                    {conflict.isIgnored ? (
                      <FormattedMessage
                        id={`ignored`}
                        defaultMessage={`Ignored`}
                      />
                    ) : (
                      <FormattedMessage id={`ignore`} defaultMessage={`Ignore`} />
                    )}
                  </Button>
                </Box>
              </AccordionDetails>
            )
          })
        )}
      </Accordion>
      <Modal
        open={!!confirmConflict}
        onClose={handleModalClose}
        dividers
        actions={
          <Button color="primary" variant={"contained"} onClick={handleIgnore}>
            <FormattedMessage
              id={"conflictIgnore"}
              defaultMessage={"ignoreConflict"}
            />
          </Button>
        }
      >
        <Typography variant={"h6"}>
          <FormattedMessage
            id={"confirmConflictIgnore"}
            defaultMessage={"Do you want to ignore the conflict?"}
          />
        </Typography>
      </Modal>
    </>
  )
}
