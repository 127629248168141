import React, { ReactNode } from "react"
import { FormattedMessage } from "react-intl"
import { Box, Grid, Paper, Typography } from "@material-ui/core"
import Section from "../Section/Section"
import IconCell from "../IconCell/IconCell"

export interface SummaryItem {
  title: ReactNode
  description: ReactNode
  tooltip?: ReactNode
}

interface ISummaryProps {
  summary: SummaryItem[]
}

export default function Summary({ summary }: ISummaryProps) {
  return (
    <Section
      heading={<FormattedMessage id={`summary`} defaultMessage={"Summary"} />}
      padding={4}
      isSeparated
    >
      <Grid container spacing={2}>
        {summary.map(({ title, tooltip, description }, index) => (
          <Grid item xs={6} md={3} key={index}>
            <Box
              component={Paper}
              textAlign={`center`}
              p={4}
              height={160}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              flexDirection={"column"}
            >
              <Typography variant={"h4"}>{title}</Typography>
              <IconCell tooltip={tooltip}>
                <Typography variant={"button"}>
                  {description}
                </Typography>
              </IconCell>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Section>
  )
}
