import React, { StrictMode } from "react"
import { Provider as StoreProvider } from "react-redux"
import store from "../../store"
import Router from "../Router/Router"
import { restoreInterface } from "../../actions/interfaceActions"
import IntlProvider from "../IntlProvider/IntlProvider"
import Providers from "../Providers/Providers"

// restore interface on mount
store.dispatch(restoreInterface())

export default function App() {
  return (
    <StrictMode>
      <StoreProvider store={store}>
        <IntlProvider>
          <Providers>
            {(isDark, toggleScheme) => (
              <Router isDark={isDark} toggleColorScheme={toggleScheme} />
            )}
          </Providers>
        </IntlProvider>
      </StoreProvider>
    </StrictMode>
  )
}
