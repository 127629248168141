import { createSelector } from "reselect"
import { AppState } from "../store"

export const selectShowRecords = (state: AppState) => state.show.records

export const selectShowFetchStatus = (state: AppState) =>
  state.show.statuses.fetchShows

export const selectShowDeleteStatus = (state: AppState) =>
  state.show.statuses.deleteShow

export const selectShowSaveStatus = (state: AppState) =>
  state.show.statuses.saveShow

export const selectShowFetchError = (state: AppState) =>
  state.show.errors.fetchShows

export const selectShowDeleteError = (state: AppState) =>
  state.show.errors.deleteShow

export const selectShowSaveError = (state: AppState) =>
  state.show.errors.saveShow

export const selectShowById = createSelector(
  selectShowRecords,
  (state: AppState, id?: string) => id,
  (shows, id) => (id && shows.size > 0 ? shows.get(id) : null)
)
