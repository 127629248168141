interface GeneralObject {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

export function removeKey(object: GeneralObject, key: string): GeneralObject {
  const { [key]: toBeRemoved, ...rest } = object
  return rest
}

export function renameKeys(
  obj: GeneralObject,
  newKeys: GeneralObject
): GeneralObject {
  const keyValues = Object.keys(obj).map((key) => {
    const newKey = newKeys[key] || key
    return { [newKey]: obj[key] }
  })
  return Object.assign({}, ...keyValues)
}
