import Settings from "@material-ui/icons/Settings"
import React, { useState } from "react"
import { useSelector } from "react-redux"
import { FormattedMessage } from "react-intl"
import Button from "../../components/Button/Button"
import DataGrid from "../../components/DataGrid/DataGrid"
import {
  ExtendedOrderReqAdmin,
  ReservationDataGrid,
} from "../../types/globalTypes"
import { useAgencyFetch } from "../../hooks/agencyHooks"
import RouteMessage from "../../components/Messages/RouteMessage"
import {
  selectIsReservationFilterOpen,
  selectReservationDataGrid,
} from "../../selectors/reservationSelector"
import { useSeatingFetch, useSectionFetch } from "../../hooks/reservationHooks"
import Results from "../../components/Results/Results"
import { Entity } from "../../components/Messages/EntityMessage"
import {
  blankOrderData,
  getReservationValidationErrors,
  ValidationError,
} from "../../utils/typeUtils"
import ReservationFilter from "../../components/ReservationFilter/ReservationFilter"
import Can from "../../components/Can/Can"
import columns from "./ReservationColumns"
import StatusProgressBar from "../../components/StatusProgressBar/StatusProgressBar"
import ReservationSectionModal from "./ReservationSectionModal"
import ValidationErrors from "../../components/ValidationErrors/ValidationErrors"
import { useOrderFetch, useOrderSave } from "hooks/orderHooks"
import { OrderReqAdmin } from "../../types/apiTypes"
import { useLocalStoragePagination } from "../../hooks/interfaceHooks"
import ReservationSummary from "./ReservationSummary"

export default function Reservation() {
  const statuses = useOrderFetch()
  const [errors, setErrors] = useState<ValidationError[]>([])
  const reservationList = useSelector(selectReservationDataGrid)
  const [, agencyStatus] = useAgencyFetch()
  const isFilterOpen = useSelector(selectIsReservationFilterOpen)
  const [, seatingStatus] = useSeatingFetch()
  const [saveOrder, saveStatus, saveError] = useOrderSave()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const paginationProps = useLocalStoragePagination("reservation")
  const [sectionMap] = useSectionFetch()

  const handleRowSave = (
    { packageId, upsales, ...data }: ExtendedOrderReqAdmin = blankOrderData,
    id?: number
  ): Promise<void> =>
    new Promise((resolve, reject) => {
      if (data) {
        const orderReq: OrderReqAdmin = {
          ...data,
          upsales,
        }
        const validationErrors = getReservationValidationErrors(orderReq)
        if (validationErrors.filter((i) => i).length > 0) {
          setErrors(validationErrors)
          reject()
        } else {
          saveOrder(orderReq, id)
          resolve()
        }
      } else {
        reject()
      }
    })

  return (
    <>
      <Results
        statuses={[saveStatus]}
        errors={[saveError]}
        actions={["save"]}
        entity={Entity.RESERVATION}
      />
      <StatusProgressBar
        statuses={[...statuses, agencyStatus, seatingStatus]}
      />
      <ValidationErrors errors={errors} />
      <DataGrid<ReservationDataGrid>
        {...paginationProps}
        title={<RouteMessage id={"reservation"} />}
        searchable={false}
        data={reservationList}
        cta={
          <Can resource={"section"} operations={["W"]}>
            <Button
              onClick={() => setIsModalOpen(true)}
              variant="contained"
              color={`primary`}
              startIcon={<Settings />}
            >
              <FormattedMessage
                id={`manageSections`}
                defaultMessage={`Manage sections`}
              />
            </Button>
          </Can>
        }
        customFilter={<ReservationFilter />}
        defaultFilterOpen={isFilterOpen}
        columns={columns}
        editable={{
          onRowUpdate: ({ id, req }) => handleRowSave(req, id),
          onRowAdd: ({ req }) => handleRowSave(req),
          onRowAddCancelled: () => setErrors([]),
          onRowUpdateCancelled: () => setErrors([]),
        }}
      >
        <ReservationSummary sectionMap={sectionMap} />
      </DataGrid>
      <Can resource={"section"} operations={["W"]}>
        <ReservationSectionModal
          onClose={() => setIsModalOpen(false)}
          isOpen={isModalOpen}
          sectionMap={sectionMap}
        />
      </Can>
    </>
  )
}
