import React from "react"
import { EditComponentProps } from "@material-table/core"
import DateTimeInput from "../../components/DateTimeInput/DateTimeInput"
import { LiteEvent, ReservationDataGrid } from "../../types/globalTypes"
import { blankOrderData } from "../../utils/typeUtils"

export default function ReservationDateEditCell({
  rowData: { req = blankOrderData },
  onChange,
}: EditComponentProps<ReservationDataGrid>) {

  const handleChange = ({ currentTarget: { value } }: LiteEvent<number>) => {
    onChange({ ...req, dtStart: value })
  }

  return (
    <DateTimeInput
      onChange={handleChange}
      name={`reservationDate`}
      value={req.reservation?.dtStart}
      dense
    />
  )
}
