import { makeStyles } from "@material-ui/core"

const useSelectStyles = makeStyles((theme) => ({
  root: {
    width: `100%`,
    minWidth: "180px",
  },
  promoted: {
    fontWeight: 'bold',
    background: theme.palette.success.light,
  },
  demoted: {
    fontWeight: 'bold',
    background: theme.palette.warning.light,
  },
}))

export default useSelectStyles
