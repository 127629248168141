/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RequestStatus } from "../types/statusTypes"
import {
  requestActiveSession,
  requestCloseSession,
  requestOpenSession,
} from "../api/sessionRequests"
import {
  selectSessionDataGrid,
  selectSessionFetchError,
  selectSessionFetchStatus,
} from "../selectors/sessionSelector"
import { fetchSessions } from "../actions/sessionActions"
import { useFetchHook } from "./fetchHooks"
import { SessionDataGrid } from "../types/globalTypes"

export function useSessionHook() {
  const [session, setSession] = useState<number | null>(null)

  const { token, status, error, handleRequest, handleFail, handleSuccess } =
    useFetchHook()

  useEffect(() => {
    async function getActiveSession() {
      try {
        handleRequest()
        const { id } = await requestActiveSession(token)
        setSession(id)
        handleSuccess()
      } catch (err) {
        handleSuccess()
        console.log("User has no active session")
      }
    }
    getActiveSession()
  }, [token])

  return {
    openSession: async (): Promise<void> => {
      try {
        handleRequest()
        const { id } = await requestOpenSession(token)
        setSession(id)
        handleSuccess()
        // eslint-disable-next-line
      } catch (e: any) {
        handleFail(e.message)
      }
    },
    closeSession: async (): Promise<void> => {
      try {
        if (session) {
          handleRequest()
          await requestCloseSession(token, session)
          handleSuccess()
        }
        // eslint-disable-next-line
      } catch (e: any) {
        handleFail(e.message)
      }
    },
    session,
    status,
    error,
  }
}

export function useSessionsFetch(
  sessionId?: string
): [SessionDataGrid[], RequestStatus, string] {
  const reduxDispatch = useDispatch()
  const status = useSelector(selectSessionFetchStatus)
  const error = useSelector(selectSessionFetchError)
  const data = useSelector(selectSessionDataGrid)

  useEffect(() => {
    reduxDispatch(
      fetchSessions(sessionId ? [parseFloat(sessionId)] : undefined)
    )
  }, [reduxDispatch, sessionId])

  return [data, status, error]
}
