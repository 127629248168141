import React from "react"
import Modal from "../../components/Modal/Modal"
import Button from "../../components/Button/Button"
import { Typography } from "@material-ui/core"
import { FormattedMessage } from "react-intl"

interface IOrderBalanceConfirmDialogProps {
  open: boolean
  onClose: () => void
  onConfirm: () => void
}

export default function OrderBalanceConfirmDialog({
  open,
  onClose,
  onConfirm,
}: IOrderBalanceConfirmDialogProps) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      dividers
      actions={
        <Button color="primary" variant={"contained"} onClick={onConfirm}>
          <FormattedMessage
            id={"acceptBalance"}
            defaultMessage={"Accept balance"}
          />
        </Button>
      }
    >
      <Typography variant={"h6"}>
        <FormattedMessage
          id={"confirmBalanceAccept"}
          defaultMessage={"Do you want to accept the balance?"}
        />
      </Typography>
    </Modal>
  )
}
