import React from "react"
import { FormattedMessage } from "react-intl"
import { Typography } from "@material-ui/core"
import useStyles from "./CopyrightStyles"

export default function Copyright() {
  const classes = useStyles()
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
      className={classes.footer}
    >
      <FormattedMessage
        id={`allRightsReserved`}
        defaultMessage={`All rights reserved`}
      />
      &nbsp; GoldFingers &nbsp;
      {new Date().getFullYear()}
    </Typography>
  )
}
