import { Map, Set } from "immutable"
import { createSelector } from "reselect"
import { AppState } from "../store"
import { selectFilters, selectLanguage } from "./interfaceSelector"
import { ActivityMap, OptionMap, ResourceMap } from "../models/activityModel"
import { FormField, PackageOptionResAdmin } from "../types/apiTypes"
import { Source } from "../types/filterTypes"

export function selectActivityFetchStatus(state: AppState) {
  return state.activity.statuses.fetchActivities
}

export function selectActivitySaveStatus(state: AppState) {
  return state.activity.statuses.saveActivity
}

export function selectActivityDeleteStatus(state: AppState) {
  return state.activity.statuses.deleteActivity
}

export function selectResourceFetchStatus(state: AppState) {
  return state.activity.statuses.fetchResources
}
export function selectActivityFetchError(state: AppState) {
  return state.activity.errors.fetchActivities
}

export function selectActivitySaveError(state: AppState) {
  return state.activity.errors.saveActivity
}

export function selectResourceFetchError(state: AppState) {
  return state.activity.errors.fetchResources
}

export function selectActivityDeleteError(state: AppState) {
  return state.activity.errors.deleteActivity
}

export function selectActivityRecords(state: AppState): ActivityMap {
  return state.activity.records
}

export function selectResources(state: AppState): ResourceMap {
  return state.activity.resources
}

export const selectActivityList = createSelector(selectActivityRecords, (map) =>
  map.valueSeq().toArray()
)

export const selectActivityDataGrid = createSelector(
  selectActivityList,
  selectFilters,
  (activities, filters) => {
    const filterSource = filters.SOURCE
    return activities.filter(
      ({ isForWeb }) =>
        (filterSource.has(Source.WEB) ? isForWeb : true) &&
        (filterSource.has(Source.AGENCY) ? !isForWeb : true)
    )
  }
)

export const selectAgencyActivities = createSelector(
  selectActivityRecords,
  (activities) => activities.filter(({ isForWeb }) => !isForWeb)
)

export const selectActivityInputOptions = createSelector(
  selectActivityList,
  selectLanguage,
  (state: AppState, isWeb: boolean, isAgency: boolean) => [isWeb, isAgency],
  (activities, language, [isWeb, isAgency]) =>
    activities
      .filter(({ isForWeb }) =>
        isWeb ? isForWeb : isAgency ? !isForWeb : true
      )
      .map((item) => ({
        value: item.id.toString(),
        content: item.translations[language].title,
      }))
)

export const selectActivityById = createSelector(
  selectActivityRecords,
  (state: AppState, id: number): number => id,
  (records, id) => records.get(id.toString())
)

export const selectOptionMap = createSelector(
  selectActivityRecords,
  (activities) =>
    activities.reduce(
      (optionMap: OptionMap, { options }) =>
        optionMap.withMutations((map) =>
          options.forEach((option) => map.set(option.id.toString(), option))
        ),
      Map<string, PackageOptionResAdmin>()
    )
)

export const selectOptionIdPackageIdMap = createSelector(
  selectActivityRecords,
  (activities) =>
    Map<number, number>(
      activities.flatMap((activity) =>
        activity.options.map((option) => [option.id, option.packageId])
      )
    )
)

export const selectOptionLookupTable = createSelector(
  selectOptionMap,
  selectActivityRecords,
  selectLanguage,
  (options, activities, language) =>
    options
      .map(
        ({ translations, packageId }) =>
          `${
            activities.get(packageId.toString())?.translations?.[language]
              ?.title
          } – ${translations[language].title}`
      )
      .toJS()
)

export const selectOrderFields = createSelector(
  selectOptionMap,
  (state: AppState, optionId?: number) => optionId,
  (optionMap, optionId): [Set<FormField>, Set<FormField>] => {
    if (!optionId) return [Set([]), Set([])]
    const option = optionMap.get(optionId.toString())
    return [
      Set([
        ...(option?.requiredFields || []),
        ...(option?.optionalFields || [])
      ]),
      Set([...(option?.requiredFields || [])])
    ]
  }
)
