import React from "react"
import { ExtendedOrderReqAdmin } from "../../types/globalTypes"
import { FormattedMessage } from "react-intl"
import BonSummary from "../BonSummary/BonSummary"

interface IOrderBonsProps {
  order: ExtendedOrderReqAdmin
}

export default function OrderBons({ order }: IOrderBonsProps) {
  const hasBons = order.bonSum.length && order.bonSum.length > 0
  const hasCustomBons = order.bons.length && order.bons.length > 0
  return (
    <>
      {hasBons ? (
        <div>
          <FormattedMessage id={`bons`} defaultMessage={`Bons`} />: <br />
          <BonSummary summary={order.bonSum || []} element={"div"} />
        </div>
      ) : null}
      {hasCustomBons ? (
        <div>
          <FormattedMessage id={`customBons`} defaultMessage={`Custom Bons`} />:{" "}
          <br />
          <BonSummary summary={order.bons || []} element={"div"} />
        </div>
      ) : null}
    </>
  )
}
