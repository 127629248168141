import { Collection, Map, OrderedMap, Record, RecordOf, Set } from "immutable"
import { RequestStatus } from "../types/statusTypes"
import {
  OrderConflictResElement,
  OrderEmployeeResAdmin,
  OrderResAdmin,
  OrderStatus,
  SeatingResAdmin,
  SectionResAdmin
} from "../types/apiTypes"
import { Field } from "../components/Messages/FieldMessage"
import { OPTION_ALL } from "../utils/typeUtils"
import { addWeeks, formatISO } from "date-fns"
import { Entity } from "../components/Messages/EntityMessage"
import { SortType } from "../types/globalTypes"

export type OrdersMap = OrderedMap<string, OrderResAdmin>

export type SectionMap = Map<string, SectionResAdmin>

export type SeatingMap = Map<string, SeatingResAdmin>

export type ConflictingOrdersMap = Map<
  string,
  [OrderResAdmin[], number[], OrderResAdmin | undefined]
>

export type ConflictingEmployeesMap = Map<
  string,
  Collection<number, OrderEmployeeResAdmin>
>

export const defaultFilters = {
  [Field.DATE]: Set<string>([
    formatISO(new Date()),
    formatISO(addWeeks(new Date(), 1)),
  ]),
  [Field.SOURCE]: Set<string>([OPTION_ALL]),
  [Field.ORDER_STATUS]: Set<string>([
    OrderStatus.CREATED,
    OrderStatus.APPROVED,
  ]),
  [Field.PAYMENT_METHOD]: Set<string>([OPTION_ALL]),
  [Field.AGENCY]: Set<string>(),
  [Entity.ORDER]: Set<string>(),
  [Field.EMPLOYEE]: Set<string>(),
  [Field.ACTIVITY]: Set<string>(),
  [Field.CONTAINS_RESERVATION]: Set<string>([OPTION_ALL]),
}

const defaultStatuses = {
  fetchOrders: RequestStatus.INITIAL,
  fetchConflicts: RequestStatus.INITIAL,
  fetchSections: RequestStatus.INITIAL,
  fetchSeating: RequestStatus.INITIAL,
  saveEmployeeStatus: RequestStatus.INITIAL,
  saveEmployee: RequestStatus.INITIAL,
  sendVoucherEmail: RequestStatus.INITIAL,
}

const makeStatuses: Record.Factory<typeof defaultStatuses> =
  Record(defaultStatuses)

export const makeFilters = (
  filters: typeof defaultFilters
): Record.Factory<typeof defaultFilters> => Record(filters)

const defaultErrors = {
  fetchOrders: "",
  fetchConflicts: "",
  fetchSections: "",
  fetchSeating: "",
  fetchEmployees: "",
  saveEmployeeStatus: "",
  saveEmployee: "",
  sendVoucherEmail: "",
}

const makeErrors: Record.Factory<typeof defaultErrors> = Record(defaultErrors)

type OrderStateType = {
  statuses: RecordOf<typeof defaultStatuses>
  errors: RecordOf<typeof defaultErrors>
  records: OrdersMap
  sections: SectionMap
  seating: SeatingMap
  conflictingResources: OrderConflictResElement[]
  conflictingEmployees: OrderConflictResElement[]
  filters: RecordOf<typeof defaultFilters>
  pageNumber: number
  pageSize: number
  searchQuery: string
  sortType: SortType
}

const defaultOrderState: OrderStateType = {
  statuses: makeStatuses(),
  errors: makeErrors(),
  records: Map() as OrdersMap,
  sections: Map() as SectionMap,
  seating: Map() as SeatingMap,
  conflictingResources: [],
  conflictingEmployees: [],
  filters: makeFilters(defaultFilters)(),
  pageNumber: 0,
  pageSize: 100,
  searchQuery: "",
  sortType: "desc",
}

export type OrderState = RecordOf<typeof defaultOrderState>

export type OrderActions = keyof typeof defaultStatuses

export const makeOrderState: Record.Factory<OrderStateType> =
  Record(defaultOrderState)
