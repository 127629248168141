import { SyntheticEvent } from "react"
import { LiteEvent } from "../types/globalTypes"

export type GeneralEvent =
  | SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>
  | LiteEvent

export function getTargetStringValue({
  currentTarget: target,
}: GeneralEvent): string {
  return target instanceof HTMLInputElement && target.type === "checkbox"
    ? target.checked
      ? "checked"
      : ""
    : target.value
}
