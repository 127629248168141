import React from "react"
import LinearProgress from "@material-ui/core/LinearProgress"
import { RequestStatus } from "../../types/statusTypes"

interface IStatusProgressBarProps {
  statuses: RequestStatus[]
}

export default function StatusProgressBar({
  statuses,
}: IStatusProgressBarProps) {
  const progress = statuses
    ? ((statuses.length -
        statuses.filter((status) => status === RequestStatus.REQUESTED)
          .length) /
        statuses.length) *
      100
    : 100
  return (
    <LinearProgress
      variant="determinate"
      value={progress}
      valueBuffer={50}
      style={{ opacity: progress === 100 ? 0 : 1 }}
    />
  )
}
