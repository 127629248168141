import React from "react"
import { useDispatch, useSelector } from "react-redux"
import Filter, { FilterTypes } from "../Filter/Filter"
import { Field } from "../Messages/FieldMessage"
import {
  employeeRoleInputOptions,
  roleInputOptions,
} from "../../utils/typeUtils"
import { selectFilterMap } from "../../selectors/interfaceSelector"
import { setFilter } from "../../actions/interfaceActions"

const inputOptions = [...employeeRoleInputOptions, ...roleInputOptions]

export default function UserFilter() {
  const filters = useSelector(selectFilterMap)
  const dispatch = useDispatch()

  const handleChange = (key: string, value: string[]) => {
    dispatch(setFilter(key, value))
  }

  return (
    <Filter
      fields={[
        {
          name: Field.ROLE,
          values: filters.get(Field.ROLE)?.toArray() || [],
          options: inputOptions,
          type: FilterTypes.MultiSelect,
        },
      ]}
      onChange={handleChange}
    />
  )
}
