import React from "react"
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
} from "@material-ui/pickers"
import { LiteEvent } from "../../types/globalTypes"
import { createLiteEvent } from "../../utils/typeUtils"
import useStyles from "./DateInputStyles"
import FieldMessage, { Field } from "../Messages/FieldMessage"
import { FormField } from "../../types/apiTypes"

interface IDateInputProps {
  messageId: Field | FormField
  value?: number | null
  name: string
  onChange?: (e: LiteEvent<number>) => void
}

export default function DateInput({
  value,
  onChange,
  messageId,
  name,
  ...keyboardProps
}: IDateInputProps & Omit<KeyboardDatePickerProps, "onChange">) {
  const classes = useStyles()

  const handleChange = (date: Date | null) => {
    if (date && onChange) onChange(createLiteEvent<number>(name, date?.valueOf()))
  }

  return (
    <KeyboardDatePicker
      className={classes.root}
      label={<FieldMessage id={messageId} />}
      inputVariant={`outlined`}
      onChange={handleChange}
      value={value}
      {...keyboardProps}
    />
  )
}
