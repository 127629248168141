import React from "react"
import { useSelector } from "react-redux"
import { ReservationDataGrid } from "../../types/globalTypes"
import config from "../../config"
import { selectAgencyRecords } from "../../selectors/agencySelector"

export default function ReservationAgencyCell({
  agencyId,
}: ReservationDataGrid) {
  const agencyMap = useSelector(selectAgencyRecords)
  return (
    <>
      {agencyId === config.agencyIds.web
        ? "Web"
        : agencyMap.get(agencyId.toString())?.title}
    </>
  )
}
