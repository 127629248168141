import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  selectCareerDeleteError,
  selectCareerDeleteStatus,
  selectCareerFetchError,
  selectCareerFetchStatus,
  selectCareerRecords,
  selectCareerSaveError,
  selectCareerSaveStatus,
} from "../selectors/careerSelector"
import { deleteCareer, fetchCareer, saveCareer } from "../actions/careerActions"
import { CareerMap } from "../models/careerModel"
import { RequestStatus } from "../types/statusTypes"
import { CareerApplicationReq } from "../types/apiTypes"

export function useCareerFetch(
  id?: string
): [CareerMap, RequestStatus, string] {
  const dispatch = useDispatch()
  const careers = useSelector(selectCareerRecords)
  const status = useSelector(selectCareerFetchStatus)
  const error = useSelector(selectCareerFetchError)
  useEffect(() => {
    dispatch(fetchCareer(id ? [parseFloat(id)] : undefined))
  }, [dispatch, id])
  return [careers, status, error]
}

export function useCareerDelete(): [
  (id: number) => void,
  RequestStatus,
  string
] {
  const dispatch = useDispatch()
  const status = useSelector(selectCareerDeleteStatus)
  const error = useSelector(selectCareerDeleteError)
  return [(id: number) => dispatch(deleteCareer(id)), status, error]
}

export function useCareerSave(): [
  (data: CareerApplicationReq) => void,
  RequestStatus,
  string
] {
  const dispatch = useDispatch()
  const status = useSelector(selectCareerSaveStatus)
  const error = useSelector(selectCareerSaveError)
  return [(data) => dispatch(saveCareer(data)), status, error]
}
