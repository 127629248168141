import React from "react"
import { PackageUpsaleResAdmin } from "../../types/apiTypes"
import ActivityTypeMessage from "../../components/Messages/ActivityTypeMessage"
import CellGroup from "../../components/CellGroup/CellGroup"

export default function UpsaleListTypeCell({ type }: PackageUpsaleResAdmin) {
  return (
    <CellGroup>
      {type.map((id) => (
        <ActivityTypeMessage key={id} id={id} />
      ))}
    </CellGroup>
  )
}
