import React, { SyntheticEvent, useEffect, useState } from "react"
import {
  addDays,
  addHours,
  addMilliseconds,
  differenceInCalendarDays,
  differenceInMilliseconds,
  endOfDay,
  isSameDay,
  setHours,
  setMinutes,
  startOfDay,
} from "date-fns"
import { useSelector } from "react-redux"
import { useInView } from "react-intersection-observer"
import { FormatDateOptions, useIntl } from "react-intl"
import { Availability } from "../../types/filterTypes"
import { EventType, LiteEvent } from "../../types/globalTypes"
import { arrayCreate, arraySplit } from "../../utils/arrayUtils"
import {
  agendaInputOptions,
  idParamToPost,
  isNumeric,
} from "../../utils/typeUtils"
import MinuteRange from "../MinuteRange/MinuteRange"
import MultipleFiledSet from "../MultipleFiledSet/MultipleFiledSet"
import RadioList from "../RadioList/RadioList"
import { TimeParts } from "../TimeParts/TimeParts"
import useStyles from "./AgendaStyles"
import EntityMessage, { Entity } from "../Messages/EntityMessage"
import { selectToken } from "../../selectors/interfaceSelector"
import { RequestStatus } from "../../types/statusTypes"
import {
  requestDeleteAvailability,
  requestGetAvailability,
  requestSaveAvailability,
} from "../../api/userRequests"
import { delayMoment, delayWhile } from "../../utils/apiUtils"
import Toast from "../Toast/Toast"
import { decodeInputName, encodeInputName } from "../../utils/stringUtils"

const format: FormatDateOptions = {
  weekday: "long",
  day: "numeric",
  month: "long",
}

const wholeDayMS = 24 * 60 * 60 * 1000 - 1000

const increment = 7

function getRadioListValue(events: Array<EventType>): Availability {
  if (events.length === 0) return Availability.NA
  if (events.length === 1) {
    const { dtEnd, dtStart } = events[0]
    return dtEnd - dtStart >= wholeDayMS ? Availability.DAY : Availability.PART
  }
  return Availability.PART
}

function getDayEvents(events: EventType[], date: Date): EventType[] {
  return events.filter(({ dtStart }) => isSameDay(date, dtStart))
}

function getSampleEventDates(day: Date): [number, number] {
  return [
    setMinutes(setHours(day, 12), 0).valueOf(),
    setMinutes(setHours(day, 14), 0).valueOf(),
  ]
}

interface IAgendaProps {
  minDays?: number
  userId: number
}

export default function Agenda({ minDays = increment, userId }: IAgendaProps) {
  const token = useSelector(selectToken)
  const [status, setStatus] = useState(RequestStatus.INITIAL)
  const [error, setError] = useState("")
  const [ref, inView] = useInView()
  const [events, setEvents] = useState<EventType[]>([])
  const { formatDateToParts } = useIntl()
  const classes = useStyles()
  const lastEvent = events[events.length - 1]
  const todayMidnight = startOfDay(new Date())
  const lastEventDaysDiff = differenceInCalendarDays(
    lastEvent?.dtStart || todayMidnight,
    todayMidnight
  )
  const [daysNumber, setDaysNumber] = useState(
    minDays > lastEventDaysDiff ? minDays : lastEventDaysDiff
  )

  const handleError = async (e: Error) => {
    setStatus(RequestStatus.FAILED)
    setError(e.message)
    await delayWhile()
    setStatus(RequestStatus.INITIAL)
  }

  const handleSuccess = async () => {
    setStatus(RequestStatus.SUCCEEDED)
    await delayMoment()
    setStatus(RequestStatus.INITIAL)
  }

  // increment number of days on scroll
  useEffect(() => {
    if (inView) setDaysNumber((days) => days + increment)
  }, [inView, setDaysNumber])

  // load existing agenda
  useEffect(() => {
    ;(async function fetchAgenda() {
      try {
        setStatus(RequestStatus.REQUESTED)
        const availability = await requestGetAvailability(token, [userId])
        setEvents(
          availability.map((item) => ({
            dtStart: item.availableFrom,
            dtEnd: item.availableTo,
            id: item.id.toString(),
          }))
        )
        await handleSuccess()
        // eslint-disable-next-line
      } catch (e: any) {
        await handleError(e)
      }
    })()
  }, [token, userId])

  const addEvent = async (
    dtStart: number = Date.now(),
    dtEnd: number = addHours(Date.now(), 1).valueOf()
  ) => {
    try {
      setStatus(RequestStatus.REQUESTED)
      const { id } = await requestSaveAvailability(token, {
        availableFrom: dtStart,
        availableTo: dtEnd,
        userId,
        id: 0,
      })
      setEvents((oldEvents) => [
        ...oldEvents,
        { id: id.toString(), dtStart, dtEnd },
      ])
      await handleSuccess()
      // eslint-disable-next-line
    } catch (e: any) {
      await handleError(e)
    }
  }

  const changeEvent = async (id: string, dtStart?: number, dtEnd?: number) => {
    try {
      setStatus(RequestStatus.REQUESTED)
      const index = events.findIndex((ev) => ev.id === id)
      await requestSaveAvailability(
        token,
        {
          id: idParamToPost(id),
          userId,
          availableFrom: dtStart || events[index].dtStart,
          availableTo: dtEnd || events[index].dtEnd,
        },
        parseFloat(id)
      )
      setEvents(
        events.map((item, idx) =>
          idx === index
            ? {
                id,
                dtStart: dtStart || item.dtStart,
                dtEnd: dtEnd || item.dtEnd,
              }
            : item
        )
      )
      await handleSuccess()
      // eslint-disable-next-line
    } catch (e: any) {
      await handleError(e)
    }
  }

  const removeEvents = async (...ids: string[]) => {
    try {
      setStatus(RequestStatus.REQUESTED)
      await Promise.all(
        ids.map((id) =>
          isNumeric(id)
            ? requestDeleteAvailability(token, parseFloat(id))
            : new Promise<number>((res) => res(0))
        )
      )
      setEvents((oldEvents) => oldEvents.filter(({ id }) => !ids.includes(id)))
      await handleSuccess()
      // eslint-disable-next-line
    } catch (e: any) {
      await handleError(e)
    }
  }

  const handleMinuteChange = ({
    currentTarget: { name, value: milliseconds },
  }: LiteEvent) => {
    const [date, id, indicator] = decodeInputName(name)
    const dateSummed = addMilliseconds(
      new Date(parseFloat(date)),
      parseFloat(milliseconds)
    ).valueOf()
    changeEvent(
      id,
      indicator === `from` ? dateSummed : undefined,
      indicator === `to` ? dateSummed : undefined
    )
  }

  const handleRemoveEvent = (id: string) => {
    removeEvents(id)
  }

  const handleCreateEvent = (day: Date) => {
    addEvent(...getSampleEventDates(day))
  }

  const handleChangeType = ({
    currentTarget: { name, value },
  }: SyntheticEvent<HTMLInputElement>) => {
    const date = new Date(name)
    const dateEventsIds = getDayEvents(events, date).map((e) => e.id)
    removeEvents(...dateEventsIds)
    if (value === Availability.DAY)
      addEvent(startOfDay(date).valueOf(), endOfDay(date).valueOf())
    if (value === Availability.PART) addEvent(...getSampleEventDates(date))
  }

  return (
    <>
      <Toast
        open={status === RequestStatus.FAILED}
        title={<EntityMessage id={Entity.AVAILABILITY} />}
        severity={"error"}
        message={error}
      />
      <ul className={classes.root}>
        {arrayCreate(daysNumber, (index) => {
          const date = addDays(todayMidnight, index)
          const [firstPart, secondPart] = arraySplit(
            formatDateToParts(date, format),
            2 // [weekName, separator], [..rest]
          )
          const dayEvents = getDayEvents(events, date)
          const value = getRadioListValue(dayEvents)

          return (
            <li key={index} className={classes.item}>
              <TimeParts parts={firstPart} variant={"button"} component={"p"} />
              <TimeParts parts={secondPart} variant={"h6"} component={"p"} />
              <RadioList
                name={date.toISOString()}
                value={value}
                options={agendaInputOptions}
                onChange={handleChangeType}
                isCompact
              />
              {value === Availability.PART && (
                <MultipleFiledSet
                  onRemove={handleRemoveEvent}
                  onCreate={() => handleCreateEvent(date)}
                  entity={Entity.TIME}
                  dense
                >
                  {dayEvents.map(({ dtStart, dtEnd, id }) => (
                    <MinuteRange
                      key={id}
                      value={[
                        differenceInMilliseconds(dtStart, date),
                        differenceInMilliseconds(dtEnd, date),
                      ]}
                      name={encodeInputName(date.valueOf(), id)}
                      onChange={handleMinuteChange}
                    />
                  ))}
                </MultipleFiledSet>
              )}
            </li>
          )
        })}
        <li className={classes.subject} ref={ref} />
      </ul>
    </>
  )
}
