import React, { SyntheticEvent } from "react"
import { InputError, InputOption } from "../../types/globalTypes"
import useStyles from "./RadioListStyles"
import FieldMessage, { Field } from "../Messages/FieldMessage"

interface IRadioListProps {
  messageId?: Field
  options: InputOption[]
  name: string
  value?: string
  defaultValue?: string
  inputError?: InputError
  onChange?: (e: SyntheticEvent<HTMLInputElement>) => void
  onClick?: (e: SyntheticEvent<HTMLInputElement>) => void
  isCompact?: boolean
}

export default function RadioList({
  messageId,
  options,
  name,
  onChange = () => {},
  onClick = () => {},
  value,
  defaultValue,
  isCompact = false,
}: IRadioListProps) {
  const classes = useStyles({ isCompact })

  return (
    <fieldset className={classes.root}>
      {messageId && (
        <legend className={classes.legend}>
          <FieldMessage id={messageId} />
        </legend>
      )}
      <div className={classes.options}>
        {options.map(({ value: optionValue, content }) => (
          <div key={`${name}-${optionValue}`} className={classes.item}>
            <input
              id={`${name}-${optionValue}`}
              type={"radio"}
              value={optionValue}
              name={name}
              {...(defaultValue && {
                defaultChecked: defaultValue === optionValue,
              })}
              {...(value && { checked: value === optionValue })}
              onChange={onChange}
              onClick={onClick}
            />
            <label htmlFor={`${name}-${optionValue}`}>{content}</label>
          </div>
        ))}
      </div>
    </fieldset>
  )
}
