import React from "react"
import Button from "@material-ui/core/Button"
import { Add } from "@material-ui/icons"
import { FormattedMessage } from "react-intl"

interface IAgencyDetailPriceButtonProps {
  onClick: () => void
}

export default function AgencyDetailPriceButton({
  onClick,
}: IAgencyDetailPriceButtonProps) {
  return (
    <Button
      variant={"outlined"}
      color={"secondary"}
      startIcon={<Add />}
      onClick={onClick}
    >
      <FormattedMessage
        id={"fillPriceList"}
        defaultMessage={"Fill price list"}
      />
    </Button>
  )
}
