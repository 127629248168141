import React, { useEffect, useState } from "react"
import Form from "../../components/Form/Form"
import GalleryUpload from "../../components/GalleryUpload/GalleryUpload"
import Input from "../../components/Input/Input"
import LanguageTab from "../../components/LanguageTab/LanguageTab"
import config from "../../config"
import { languageList, simpleToFullTranslation } from "../../utils/typeUtils"
import { MediaType } from "../../types/apiTypes"
import Results from "../../components/Results/Results"
import WebPageMessage, {
  WebPage,
} from "../../components/Messages/WebPageMessage"
import { Field } from "../../components/Messages/FieldMessage"
import { Entity } from "../../components/Messages/EntityMessage"
import {
  useGalleryFetch,
  useGallerySave,
  useGalleryState,
} from "../../hooks/galleryHooks"
import { usePageSharedState } from "../../hooks/stateHooks"

const videoGalleryNamespace = "video"
const fighterGalleryNamespace = "fighter"

export default function PageHomepage() {
  const [galleryId, setGalleryId] = useState<null | number>(null)
  const state = usePageSharedState(config.pageIds.support)
  const videoState = useGalleryState(videoGalleryNamespace)
  const fighterState = useGalleryState(fighterGalleryNamespace)
  const [saveGallery, saveGalleryStatus, saveGalleryError] = useGallerySave([
    config.tagIds.gallery.support,
  ])
  const [fetchGallery, fetchGalleryStatus, fetchGalleryError] = useGalleryFetch(
    config.tagIds.gallery.support
  )

  useEffect(() => {
    state.fetchPage()
    fetchGallery([], ([{ media, id } = { media: [], id: null }]) => {
      const videos = media.filter((medium) => medium.type === MediaType.VIDEO)
      const fighters = media.filter((medium) => medium.type === MediaType.IMAGE)
      setGalleryId(id)
      videoState.fillData({ [videoGalleryNamespace]: videos })
      fighterState.fillData({ [fighterGalleryNamespace]: fighters })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = async () => {
    await state.savePage()
    await saveGallery(
      galleryId,
      [...videoState.getGallery(), ...fighterState.getGallery()],
      [
        ...videoState
          .getGallery()
          .map(({ id }) =>
            simpleToFullTranslation([videoState.labels[id]], ["title"])
          ),
        ...fighterState
          .getGallery()
          .map(({ id }) =>
            simpleToFullTranslation(
              [fighterState.labels[id], fighterState.contents[id]],
              ["title", "content"]
            )
          ),
      ],
      [
        ...videoState.getGallery().map(() => MediaType.VIDEO),
        ...fighterState.getGallery().map(() => MediaType.IMAGE),
      ]
    )
  }

  return (
    <>
      <Results
        statuses={[state.saveStatus, state.fetchStatus]}
        errors={[state.saveError, state.fetchError]}
        actions={["save", "fetch"]}
        entity={Entity.PAGE}
      />
      <Results
        statuses={[saveGalleryStatus, fetchGalleryStatus]}
        errors={[saveGalleryError, fetchGalleryError]}
        actions={["save", "fetch"]}
        entity={Entity.GALLERY}
      />
      <Form
        statuses={[
          saveGalleryStatus,
          fetchGalleryStatus,
          state.saveStatus,
          state.fetchStatus,
        ]}
        title={<WebPageMessage id={WebPage.SUPPORT} />}
        onSubmit={handleSubmit}
        toolbar={
          <LanguageTab
            language={state.language}
            onChange={state.setLanguage}
            notifications={languageList.filter((lang) => {
              const { content, title, metaData } = state.translations[lang]
              return !content || !title || !metaData
            })}
          />
        }
        useGenericCta
      >
        <Input
          name={`title`}
          messageId={Field.TITLE}
          onChange={state.handleTitle}
          value={state.title}
          showRequiredWarning={state.isSubmitted}
        />
        <Input
          name={`meta`}
          messageId={Field.META}
          onChange={state.handleMeta}
          value={state.meta}
          showRequiredWarning={state.isSubmitted}
        />
        <Input
          name={`keywords`}
          messageId={Field.KEYWORDS}
          onChange={state.handleKeywords}
          value={state.keywords}
          showRequiredWarning={state.isSubmitted}
        />
        <GalleryUpload
          name={`videos`}
          language={state.language}
          gallery={videoState.getGallery()}
          labels={videoState.labels}
          onUpload={videoState.handleUpload}
          onSort={videoState.handleSort}
          onLabelChange={videoState.handleLabelChange}
          onDelete={videoState.handleDelete}
          allowURL
          messageId={Field.VIDEOS}
        />
        <GalleryUpload
          name={`fighters`}
          language={state.language}
          gallery={fighterState.getGallery()}
          labels={fighterState.labels}
          contents={fighterState.contents}
          onUpload={fighterState.handleUpload}
          onSort={fighterState.handleSort}
          onLabelChange={fighterState.handleLabelChange}
          onContentChange={fighterState.handleContentChange}
          onDelete={fighterState.handleDelete}
          messageId={Field.FIGHTERS}
        />
      </Form>
    </>
  )
}
