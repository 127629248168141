import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { UserReq } from "../types/apiTypes"
import { RequestStatus } from "../types/statusTypes"
import {
  selectUserDeleteError,
  selectUserDeleteStatus,
  selectUserFetchError,
  selectUserFetchStatus,
  selectUserRecords,
  selectUserSaveError,
  selectUserSaveStatus,
} from "../selectors/userSelector"
import { deleteUser, fetchUsers, saveUser } from "../actions/userActions"
import { UserMap } from "../models/userModel"
import { useFetchHook } from "./fetchHooks"
import { requestGetAvailability } from "../api/userRequests"

export function useUserSave(): [
  (data: UserReq, id?: string) => void,
  RequestStatus,
  string
] {
  const dispatch = useDispatch()
  const status = useSelector(selectUserSaveStatus)
  const error = useSelector(selectUserSaveError)
  return [
    (data: UserReq, id?: string) =>
      dispatch(saveUser(data, id ? parseFloat(id) : undefined)),
    status,
    error,
  ]
}

export function useUserDelete(): [(id: number) => void, RequestStatus, string] {
  const dispatch = useDispatch()
  const status = useSelector(selectUserDeleteStatus)
  const error = useSelector(selectUserDeleteError)
  return [(id: number) => dispatch(deleteUser(id)), status, error]
}

export function useUserFetch(id?: string): [UserMap, RequestStatus, string] {
  const dispatch = useDispatch()
  const status = useSelector(selectUserFetchStatus)
  const error = useSelector(selectUserFetchError)
  const userMap = useSelector(selectUserRecords)

  useEffect(() => {
    dispatch(fetchUsers(id ? [parseFloat(id)] : undefined))
  }, [dispatch, id])

  return [userMap, status, error]
}

export interface Agenda {
  [userId: string]: Array<[number, number]>
}

export function useAgendaFetch(
  userIds: number[]
): [Agenda, RequestStatus, string] {
  const { status, error, token, handleRequest, handleSuccess, handleFail } =
    useFetchHook()
  const [agenda, setAgenda] = useState<Agenda>({})
  useEffect(() => {
    ;(async function fetchAgenda() {
      if (userIds.length > 0) {
        try {
          handleRequest()
          const response = await requestGetAvailability(token, userIds)
          setAgenda(
            response.reduce((acc, { userId, availableTo, availableFrom }) => {
              const key = userId.toString()
              const value: [number, number] = [availableFrom, availableTo]
              acc[key] = acc[key] ? [...acc[key], value] : [value]
              return acc
            }, {} as Agenda)
          )
          handleSuccess()
          // eslint-disable-next-line
        } catch (e: any) {
          console.error(e)
          handleFail(e.message)
        }
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userIds.length])
  return [agenda, status, error]
}
