import React, { useState, useEffect, ChangeEvent } from "react"
import { Navigate } from "react-router-dom"
import Form from "components/Form/Form"
import Input from "components/Input/Input"
import LanguageTab from "components/LanguageTab/LanguageTab"
import FieldMessage, { Field } from "components/Messages/FieldMessage"
import RouteMessage from "components/Messages/RouteMessage"
import Switch from "components/Switch/Switch"
import { useLanguageState, useRichTextState } from "hooks/stateHooks"
import DateRange from "components/DateRange/DateRange"
import RichText from "components/RichText/RichText"
import {
  blankSingleTranslations,
  blankTranslations,
  fullToSimpleTranslation,
  idParamToPost,
  isSourceVideo,
  languageList,
  richToSimpleTranslation,
  simpleToFullTranslation,
} from "utils/typeUtils"
import { LinkRes, MediaType, MediumReq } from "types/apiTypes"
import config from "config"
import {
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core"
import ImageUpload from "components/ImageUpload/ImageUpload"
import { requestUploadFile } from "api/galleryRequests"
import { useToken } from "hooks/fetchHooks"
import { useNewsFetch, useNewsSave } from "hooks/newsHooks"
import Results from "components/Results/Results"
import { Entity } from "components/Messages/EntityMessage"
import { RequestStatus } from "types/statusTypes"
import { useIdParam } from "../../hooks/routerHooks"
import { useGalleryState } from "../../hooks/galleryHooks"
import GalleryUpload from "../../components/GalleryUpload/GalleryUpload"

function NewsDetail() {
  const id = useIdParam()
  const [newsMap, fetchStatus, fetchError] = useNewsFetch(id)
  const token = useToken()
  const { language, setLanguage } = useLanguageState()
  const [mainMedium, setMainMedium] = useState<string | File>(``)
  const galleryState = useGalleryState()
  const [bannerText, setBannerText] = useState(blankSingleTranslations)
  const [headline, setHeadline] = useState(blankSingleTranslations)
  const [urlSlug, setUrlSlug] = useState(blankSingleTranslations)
  const [isActive, setIsActive] = useState(true)
  const [dateFrom, setDateFrom] = useState<number>(Date.now())
  const [dateTo, setDateTo] = useState<number>(Date.now())
  const [richText, setRichText, restoreRichText] = useRichTextState(language)
  const [icon, setIcon] = useState("")
  const [saveNews, saveStatus, saveError] = useNewsSave()
  const news = newsMap.get(id)

  const handleDateRangeChange = (
    name: string,
    [from, to]: [number, number]
  ) => {
    setDateFrom(from)
    setDateTo(to)
  }
  const handleChangeBanner = (e: ChangeEvent<HTMLInputElement>) => {
    setBannerText({ ...bannerText, [language]: e.target.value })
  }
  const handleChangeHeadline = (e: ChangeEvent<HTMLInputElement>) => {
    setHeadline({ ...headline, [language]: e.target.value })
  }
  const handleChangeUrlSlug = (e: ChangeEvent<HTMLInputElement>) => {
    setUrlSlug({ ...urlSlug, [language]: e.target.value })
  }

  const handleSubmit = async () => {
    const media: MediumReq[] = await Promise.all(
      [
        { id: "mainMedium", source: mainMedium },
        ...galleryState.getGallery(),
      ].map(({ source, id: mediumId }) =>
        (source instanceof File
          ? requestUploadFile(token, source)
          : new Promise<LinkRes>((res) => {
              res({ link: source })
            })
        ).then(
          ({ link }): MediumReq => ({
            translations: simpleToFullTranslation(
              [
                galleryState.labels[mediumId] || blankSingleTranslations,
                galleryState.contents[mediumId] || blankSingleTranslations,
              ],
              ["title", "content"]
            ),
            type: isSourceVideo(source) ? MediaType.VIDEO : MediaType.IMAGE,
            url: link,
          })
        )
      )
    )

    saveNews(
      {
        gallery: {
          translations: blankTranslations,
          media,
        },
        id: idParamToPost(id),
        notification: {
          dtEnd: dateTo,
          dtStart: dateFrom,
          isActive,
          iconUrl: icon,
          translations: simpleToFullTranslation(
            [
              bannerText || blankSingleTranslations,
              urlSlug || blankSingleTranslations,
            ],
            ["title", "additionalInfo"]
          ),
        },
        translations: simpleToFullTranslation(
          [
            headline || blankSingleTranslations,
            richToSimpleTranslation(richText),
          ],
          ["title", "content"]
        ),
      },
      id
    )
  }

  useEffect(() => {
    if (news) {
      const [main, ...rest] = news.gallery?.media || []
      setMainMedium(main?.url)
      galleryState.fillData({ default: rest })

      if (news.notification) {
        setIcon(news.notification.iconUrl)
        setIsActive(news.notification.isActive)
        setDateFrom(news.notification.dtStart)
        setDateTo(news.notification.dtEnd)
        setBannerText(
          fullToSimpleTranslation(news.notification.translations, "title")
        )
        setUrlSlug(
          fullToSimpleTranslation(
            news.notification.translations,
            "additionalInfo"
          )
        )
      }
      setHeadline(fullToSimpleTranslation(news.translations, "title"))
      restoreRichText(news.translations, "content")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [news])

  return (
    <>
      {!id && saveStatus === RequestStatus.SUCCEEDED && (
        <Navigate to={config.routes.newsList.path()} />
      )}
      <Results
        statuses={[fetchStatus, saveStatus]}
        errors={[fetchError, saveError]}
        actions={["fetch", "save"]}
        entity={Entity.NEWS}
      />
      <Form
        title={<RouteMessage id={"newsDetail"} />}
        onSubmit={handleSubmit}
        statuses={[saveStatus, fetchStatus]}
        toolbar={
          <LanguageTab
            language={language}
            onChange={setLanguage}
            notifications={languageList.filter(
              (lang) => !bannerText[lang] || !headline[lang] || !urlSlug[lang]
            )}
          />
        }
        useGenericCta
      >
        <FormLabel component="legend">
          <FieldMessage id={Field.ICON} />
        </FormLabel>
        <RadioGroup
          row
          aria-label="gender"
          name="row-radio-buttons-group"
          value={icon}
          onChange={(e) => setIcon(e.target.value)}
        >
          {config.icons.map((iconItem) => (
            <FormControlLabel
              key={iconItem}
              value={iconItem}
              control={<Radio size="small" />}
              label={
                <img
                  style={{
                    width: "30px",
                  }}
                  src={`${config.iconsBaseUrl}${iconItem}`}
                  alt="missing icon"
                />
              }
            />
          ))}
        </RadioGroup>
        <Input
          messageId={Field.BANNER_TEXT}
          name={`banner`}
          onChange={handleChangeBanner}
          type={`text`}
          value={bannerText[language]}
          autoFocus
        />
        <Input
          messageId={Field.HEADLINE}
          name={`headline`}
          onChange={handleChangeHeadline}
          type={`text`}
          value={headline[language]}
        />
        <Input
          messageId={Field.URL}
          name={`urg`}
          onChange={handleChangeUrlSlug}
          type={`text`}
          value={urlSlug[language]}
        />
        <RichText value={richText} language={language} onChange={setRichText} />
        <ImageUpload
          source={mainMedium}
          name={`mainMedium`}
          onUpload={setMainMedium}
          onDelete={() => setMainMedium(``)}
          allowURL
          messageId={Field.IMAGE_OR_VIDEO}
        />
        <GalleryUpload
          name={`gallery`}
          messageId={Field.GALLERY}
          language={language}
          gallery={galleryState.getGallery()}
          labels={galleryState.labels}
          onUpload={(files) => galleryState.handleUpload(files)}
          onSort={(prev, next) => galleryState.handleSort(prev, next)}
          onDelete={(itemId) => galleryState.handleDelete(itemId)}
          onLabelChange={galleryState.handleLabelChange}
          allowURL
        />
        <Switch
          messageId={Field.DISPLAY_IN_NOTIFICATION_BAR}
          checked={isActive}
          name={"active"}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setIsActive(e.target.checked)
          }
        />
        {isActive && (
          <DateRange
            name="activeDates"
            value={[dateFrom, dateTo]}
            onChange={handleDateRangeChange}
          />
        )}
      </Form>
    </>
  )
}

export default NewsDetail
