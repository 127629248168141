import React, { ChangeEvent } from "react"
import { Set } from "immutable"
import { InputOption, LiteEvent } from "../../types/globalTypes"
import useStyles from "./CheckboxListStyles"
import FieldMessage, { Field } from "../Messages/FieldMessage"
import CheckButton from "../CheckButton/CheckButton"
import { createLiteEvent } from "../../utils/typeUtils"

interface ICheckboxListProps<T extends string = string> {
  messageId?: Field
  options: InputOption<T>[]
  name: string
  values: Set<T>
  value?: T
  onChange?: (e: LiteEvent<T>) => void
}

export default function CheckboxList<T extends string = string>({
  messageId,
  options,
  name,
  onChange = () => {},
  values,
  value,
}: ICheckboxListProps<T>) {
  const classes = useStyles()

  const handleOptionChange = ({
    currentTarget: { value: optionValue },
  }: ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore // TODO: how to narrow down generic type to string?
    onChange(createLiteEvent<T>(name, optionValue))
  }

  return (
    <fieldset className={classes.root}>
      {messageId && (
        <legend className={classes.legend}>
          <FieldMessage id={messageId} />
        </legend>
      )}
      <div className={classes.options}>
        {options.map(({ value: optionValue, content }) => (
          <CheckButton
            name={`${name}-${optionValue}`}
            onChange={handleOptionChange}
            checked={values.has(optionValue) || optionValue === value}
            value={optionValue}
            label={content}
            key={`${name}-${optionValue}`}
          />
        ))}
      </div>
    </fieldset>
  )
}
