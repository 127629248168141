import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"

export default makeStyles<Theme, { isLoading: boolean }>((theme: Theme) =>
  createStyles({
    root: {
      pointerEvents: (props) => (props.isLoading ? `none` : `auto`),
      opacity: (props) => (props.isLoading ? 0.5 : 1),
      "& > *:last-child": {
        marginTop: theme.spacing(3),
      },
    },
    header: {
      display: `flex`,
      alignItems: `center`,
      justifyContent: `space-between`,
      padding: theme.spacing(2),
    },
    body: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(4),
      maxWidth: 1140,
    },
  })
)
