import { createStyles, makeStyles, Theme } from "@material-ui/core"

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflowY: "visible",
      minWidth: 700,
      [theme.breakpoints.down("sm")]: {
        minWidth: 400,
      },
    },
    dialogAction: {
      position: "absolute",
      top: theme.spacing(-3),
      right: theme.spacing(-3),
    },
    content: {
      padding: theme.spacing(3),
    },
    title: {
      paddingBottom: 0,
    },
  })
)
