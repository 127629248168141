import { ArrowBack } from "@material-ui/icons"
import React from "react"
import { FormattedMessage } from "react-intl"
import { Typography, Button } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import { Link } from "react-router-dom"
import { useCareerFetch } from "../../hooks/careerHooks"
import Results from "../../components/Results/Results"
import { Entity } from "../../components/Messages/EntityMessage"
import ApplicantCard from "../../components/ApplicantCard/ApplicantCard"
import RouteMessage from "../../components/Messages/RouteMessage"
import config from "../../config"
import { useIdParam } from "../../hooks/routerHooks"

export default function CareerDetail() {
  const id = useIdParam()
  const [careers, fetchStatus, fetchError] = useCareerFetch(id)
  const { fileUrls, name, message, phone, email, position } = careers.get(
    id
  ) || { fileUrls: [] }

  return (
    <>
      <Results
        statuses={[fetchStatus]}
        errors={[fetchError]}
        actions={["fetch"]}
        entity={Entity.CAREER}
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography component={"h1"} variant={"h6"}>
            <FormattedMessage
              id={`applicantDetail`}
              defaultMessage={`Applicant Detail`}
            />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ApplicantCard
            fileUrls={fileUrls}
            name={name}
            message={message}
            phone={phone}
            email={email}
            position={position}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            component={Link}
            to={config.routes.careerList.path()}
            variant={"contained"}
            color={"primary"}
            startIcon={<ArrowBack />}
          >
            <RouteMessage id={"careerList"} />
          </Button>
        </Grid>
      </Grid>
    </>
  )
}
