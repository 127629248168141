import React, { useState } from "react"
import { FormattedMessage } from "react-intl"
import { useSelector } from "react-redux"
import { Edit } from "@material-ui/icons"
import { EditComponentProps } from "@material-table/core"
import { Button } from "@material-ui/core"
import { ExtendedOrderReqAdmin, OrderDataGrid } from "../../types/globalTypes"
import CellGroup from "../../components/CellGroup/CellGroup"
import {
  blankContactInfo,
  blankOrderData,
  isNotNull,
} from "../../utils/typeUtils"
import FieldMessage, { Field } from "../../components/Messages/FieldMessage"
import ReservationModal from "../../components/ReservationModal/ReservationModal"
import { selectSeating, selectSections } from "../../selectors/orderSelector"
import { getReservationContents } from "../../utils/apiUtils"
import { useIsCompetent } from "../../hooks/interfaceHooks"
import { useOrderPriceFetch } from "../../hooks/orderHooks"
import OrderBonModal from "./OrderBonModal"
import { ComputedBon, ReservationInnerResAdmin } from "../../types/apiTypes"
import OrderBons from "../../components/OrderBons/OrderBons"
import { noop } from "ramda-adjunct"
import { isSameDay } from "date-fns"

export function OrderExtrasEditCell({
  onChange,
  rowData: { req = blankOrderData },
}: EditComponentProps<OrderDataGrid>) {
  const canEditBons = useIsCompetent("orderAdmin", "W")
  const [fetchPrice] = useOrderPriceFetch()
  const [reservationPrice, setReservationPrice] = useState<number>(0)
  const [isReservationModalOpen, setIsReservationModalOpen] = useState(false)
  const [isBonModalOpen, setIsBonModalOpen] = useState(false)
  const [reservationBons, setReservationBons] = useState<ComputedBon[]>([])
  const sections = useSelector(selectSections)
  const seating = useSelector(selectSeating)

  const handleChangeRow = (data: ExtendedOrderReqAdmin): void => {
    onChange(data)
  }

  const updateReservationPrice = (data: ExtendedOrderReqAdmin) => {
    fetchPrice(data)
      .then(([_, { price }, { reservation }]) => {
        setReservationPrice(price)
        setReservationBons(reservation)
      })
      .catch(noop)
  }

  const handleTableChange = (tableId: number) => {
    const data: ExtendedOrderReqAdmin = {
      ...req,
      reservation: req.reservation
        ? {
            ...req.reservation,
            seatingIds: req.reservation.seatingIds.some(
              (seatingId) => seatingId === tableId
            )
              ? req.reservation.seatingIds.filter(
                  (seatingId) => seatingId !== tableId
                )
              : req.reservation.seatingIds.concat(tableId),
          }
        : {
            contactInfo: blankContactInfo,
            dtStart: Date.now(),
            numberOfPersons: 1,
            seatingIds: [tableId],
          },
    }
    handleChangeRow(data)
    updateReservationPrice(data)
  }

  const handleDateChange = (dtStart: number) => {
    handleChangeRow({
      ...req,
      reservation: req.reservation
        ? {
            ...req.reservation,
            dtStart,
            seatingIds: isSameDay(req.reservation.dtStart, dtStart)
              ? req.reservation.seatingIds
              : [],
          }
        : {
            dtStart,
            seatingIds: [],
            numberOfPersons: 1,
            contactInfo: blankContactInfo,
          },
    })
    setReservationPrice(0)
    setReservationBons([])
  }

  const handlePersonChange = (numberOfPersons: number) => {
    const data: ExtendedOrderReqAdmin = {
      ...req,
      reservation: req.reservation
        ? { ...req.reservation, numberOfPersons }
        : {
            numberOfPersons,
            seatingIds: [],
            dtStart: Date.now(),
            contactInfo: blankContactInfo,
          },
    }
    handleChangeRow(data)
    updateReservationPrice(data)
  }

  const handleCloseReservationModal = () => {
    fetchPrice(req)
      .then(([price, _, { bonSum }]) => {
        handleChangeRow({ ...req, ...price, bonSum })
      })
      .catch(noop)
      .finally(() => setIsReservationModalOpen(false))
  }

  const handleReservationCancel = () => {
    const newReq = { ...req, reservation: null }
    handleChangeRow(newReq)
    setReservationPrice(0)
    setReservationBons([])
    fetchPrice(newReq)
      .then(([price, _, { bonSum }]) => {
        handleChangeRow({ ...newReq, ...price, bonSum })
      })
      .catch(noop)
      .finally(() => setIsReservationModalOpen(false))
  }

  const handleOpenReservationModal = () => {
    updateReservationPrice(req)
    if (req.reservation === null) {
      handleChangeRow({
        ...req,
        reservation: {
          seatingIds: [],
          dtStart: req.packageOrder?.dtStart || Date.now(),
          numberOfPersons: req.packageOrder?.numberOfPersons || 1,
          contactInfo: blankContactInfo,
        },
      })
    }
    setIsReservationModalOpen(true)
  }

  const handleCloseBonModal = () => {
    setIsBonModalOpen(false)
  }

  const handleOpenBonModal = () => {
    setIsBonModalOpen(true)
  }

  const reservationContents = getReservationContents(
    req?.reservation?.seatingIds
      .map((id): ReservationInnerResAdmin | null => {
        const table = seating.get(id.toString())
        const section = sections.get(table?.sectionId.toString() || "")
        return table && section
          ? {
              seating: table,
              section,
            }
          : null
      })
      .filter(isNotNull)
  )

  return (
    <>
      <CellGroup>
        {/* Reservation */}
        <Button
          onClick={handleOpenReservationModal}
          variant={"outlined"}
          color={"secondary"}
          startIcon={<Edit />}
        >
          <FormattedMessage id={`reservation`} defaultMessage={`Reservation`} />
        </Button>
        {reservationContents ? <p>{reservationContents}</p> : null}

        {/* Bons */}
        {canEditBons && req?.packageOrder?.optionId ? (
          <Button
            onClick={handleOpenBonModal}
            variant={"outlined"}
            color={"secondary"}
            startIcon={<Edit />}
          >
            <FieldMessage id={Field.BONS} />
          </Button>
        ) : null}
        {canEditBons && req && <OrderBons order={req} />}
      </CellGroup>
      {/* reservation modal */}
      <ReservationModal
        onTableChange={handleTableChange}
        onDateChange={handleDateChange}
        onPersonChange={handlePersonChange}
        onClose={handleCloseReservationModal}
        onCancel={handleReservationCancel}
        isOpen={isReservationModalOpen}
        seating={seating}
        sections={sections}
        date={req?.reservation?.dtStart}
        seatingIds={req?.reservation?.seatingIds || []}
        price={reservationPrice}
        bonSummary={reservationBons}
        numberOfPersons={req?.reservation?.numberOfPersons}
      />
      {/* bon modal */}
      {canEditBons && (
        <OrderBonModal
          onChange={handleChangeRow}
          onClose={handleCloseBonModal}
          isOpen={isBonModalOpen}
          req={req}
        />
      )}
    </>
  )
}
