import { Typography } from "@material-ui/core"
import React from "react"
import { Variant } from "@material-ui/core/styles/createTypography"

interface ITimePartsProps {
  parts: Intl.DateTimeFormatPart[]
  variant: Variant
  component: "p" | "span"
}

export function TimeParts({ parts, variant, component }: ITimePartsProps) {
  return (
    <Typography variant={variant} component={component}>
      {parts.map(({ value }, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <span key={idx}>{value}</span>
      ))}
    </Typography>
  )
}
