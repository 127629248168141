import { Box } from "@material-ui/core"
import React from "react"
import { OrderResAdmin } from "../../types/apiTypes"
import { formatDateTime } from "../../utils/timeUtils"

interface IOrderInfoProps {
  order?: OrderResAdmin
}

export default function OrderInfo({ order }: IOrderInfoProps) {
  return order && order.packageOrder ? (
    <Box display={"flex"} flexWrap={"nowrap"}>
      <Box component={"span"} width={80}>
        ID: {order.id}
      </Box>
      <Box component={"span"} mr={"auto"}>
        {formatDateTime(order.packageOrder.dtStart)}
      </Box>
      <Box component={"span"} ml={2}>
        {order.name} ({order.email})
      </Box>
    </Box>
  ) : null
}
