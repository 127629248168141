import { makeStyles, Theme, createStyles } from "@material-ui/core/styles"

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: `relative`,
      marginTop: theme.spacing(2),
    },
    addButton: {
      position: `absolute`,
      right: 0,
      top: 0,
    },
    deleteButton: {
      display: `block`,
    },
    content: {
      padding: theme.spacing(4),
      background: theme.palette.background.default,
      "& > * + *": {
        marginTop: theme.spacing(2),
      },
    },
    tabs: {
      display: `flex`,
      alignItems: `flex-start`,
      maxWidth: `100%`,
      overflow: `auto`,
    },
    tab: {
      background: theme.palette.background.paper,
      color: theme.palette.text.secondary,
      width: 120,
    },
    activeTab: {
      background: theme.palette.background.default,
      color: theme.palette.text.primary,
      fontWeight: 600,
    },
  })
)
