import React, { MouseEvent } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { BaseEditor, Editor, Element as SlateElement, Transforms } from "slate"
import { ReactEditor, useSlate } from "slate-react"
import isUrl from "is-url"
import LinkIcon from "@material-ui/icons/Link"
import LinkOffIcon from "@material-ui/icons/LinkOff"
import { CustomEditor } from "./index"
import {
  isBlockActive,
  isLinkActive,
  isMarkActive,
  unwrapLink,
  wrapLink,
} from "./richTextUtils"
import {
  RenderElementProps,
  RenderLeafProps,
} from "slate-react/dist/components/editable"

interface PropsToolbar {
  children: React.ReactNode
}

interface PropsToolbarButton {
  children: React.ReactNode
  active: boolean
  onMouseDown: (event: MouseEvent<HTMLSpanElement>) => void
}

export type FormatMark = "bold" | "italic"

export type FormatBlock =
  | "paragraph"
  | "numbered-list"
  | "bulleted-list"
  | "list-item"
  | "link"

const LIST_TYPES = ["numbered-list", "bulleted-list"]

const useStyles = makeStyles({
  root: {
    paddingTop: 10,
    "& > *": {
      padding: 4,
    },
  },
})

export const RichTextToolbar = ({ children }: PropsToolbar) => {
  const classes = useStyles()
  return <div className={classes.root}>{children}</div>
}

export const ToolbarButton = ({
  children,
  active,
  onMouseDown,
}: PropsToolbarButton) => (
  <span
    role="button"
    tabIndex={0}
    style={{ color: active ? "black" : "lightgrey" }}
    onMouseDown={onMouseDown}
  >
    {children}
  </span>
)

export const Element = ({
  attributes,
  children,
  element,
}: RenderElementProps) => {
  switch (element.type) {
    case "bulleted-list":
      return <ul {...attributes}>{children}</ul>
    case "list-item":
      return <li {...attributes}>{children}</li>
    case "numbered-list":
      return <ol {...attributes}>{children}</ol>
    case "link":
      return (
        <a {...attributes} href={"url" in element ? element.url : "#invalid"}>
          {children}
        </a>
      )
    default:
      return <p {...attributes}>{children}</p>
  }
}

export const Leaf = (props: RenderLeafProps) => (
  <span
    {...props.attributes}
    style={{
      fontWeight: props.leaf.bold ? "bold" : "normal",
      textDecoration: props.leaf.italic ? "underline" : "none",
    }}
  >
    {props.children}
  </span>
)

export const toggleMark = (
  editor: BaseEditor & ReactEditor,
  format: FormatMark
) => {
  const isActive = isMarkActive(editor, format)
  if (isActive) {
    Editor.removeMark(editor, format)
  } else {
    Editor.addMark(editor, format, true)
  }
}

export const toggleBlock = (editor: CustomEditor, format: FormatBlock) => {
  const isActive = isBlockActive(editor, format)
  const isList = LIST_TYPES.includes(format)

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      LIST_TYPES.includes(
        (!Editor.isEditor(n) && SlateElement.isElement(n) && n.type) || ""
      ),
    split: true,
  })
  const newProperties: Partial<SlateElement> = {
    type: isActive ? "paragraph" : isList ? "list-item" : format,
  }
  Transforms.setNodes(editor, newProperties)

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

export const MarkButton = ({
  format,
  icon,
}: {
  format: FormatMark
  icon: React.ReactNode
}) => {
  const editor = useSlate()
  return (
    <ToolbarButton
      active={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault()
        toggleMark(editor, format)
      }}
    >
      {icon}
    </ToolbarButton>
  )
}

export const BlockButton = ({
  format,
  icon,
}: {
  format: FormatBlock
  icon: React.ReactNode
}) => {
  const editor = useSlate()
  return (
    <ToolbarButton
      active={isBlockActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault()
        toggleBlock(editor, format)
      }}
    >
      {icon}
    </ToolbarButton>
  )
}

export const withLinks = (editor: CustomEditor) => {
  const { insertData, insertText, isInline } = editor

  // eslint-disable-next-line no-param-reassign
  editor.isInline = (element) =>
    element.type === "link" ? true : isInline(element)

  // eslint-disable-next-line no-param-reassign
  editor.insertText = (text) => {
    if (text && isUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertText(text)
    }
  }

  // eslint-disable-next-line no-param-reassign
  editor.insertData = (data) => {
    const text = data.getData("text/plain")

    if (text && isUrl(text)) {
      wrapLink(editor, text)
    } else {
      insertData(data)
    }
  }

  return editor
}

export const insertLink = (editor: CustomEditor, url: string) => {
  if (editor.selection) {
    wrapLink(editor, url)
  }
}

export const LinkButton = () => {
  const editor = useSlate()
  return (
    <ToolbarButton
      active={isLinkActive(editor)}
      onMouseDown={(event) => {
        event.preventDefault()
        const url = window.prompt("Enter the URL of the link:")
        if (!url) return
        insertLink(editor, url)
      }}
    >
      <LinkIcon />
    </ToolbarButton>
  )
}

export const RemoveLinkButton = () => {
  const editor = useSlate()
  return (
    <ToolbarButton
      active={isLinkActive(editor)}
      onMouseDown={() => {
        if (isLinkActive(editor)) {
          unwrapLink(editor)
        }
      }}
    >
      <LinkOffIcon />
    </ToolbarButton>
  )
}
