import { useState } from "react"
import { useSelector } from "react-redux"
import { Voucher } from "../types/globalTypes"
import { RequestStatus } from "../types/statusTypes"
import { selectToken } from "../selectors/interfaceSelector"
import { requestGetVoucher, requestRedeemVoucher } from "../api/orderRequest"
import { StatusRes } from "../types/apiTypes"
import { ErrorType } from "../components/Messages/ErrorMessage"

export function useFetchVoucher(): [
  (code: string) => Promise<Voucher | null>,
  RequestStatus,
  ErrorType | null
] {
  const token = useSelector(selectToken)
  const [error, setError] = useState<ErrorType | null>(null)
  const [status, setStatus] = useState(RequestStatus.INITIAL)

  return [
    async (code: string): Promise<Voucher | null> => {
      try {
        setStatus(RequestStatus.REQUESTED)
        const voucherRes = await requestGetVoucher(token, code)
        setStatus(RequestStatus.SUCCEEDED)
        return voucherRes
      } catch (e) {
        setStatus(RequestStatus.FAILED)
        setError(ErrorType.INVALID_VOUCHER)
        return null
      }
    },
    status,
    error,
  ]
}

export function useRedeemVoucher(): [
  (sessionId: number, voucherId: string) => Promise<StatusRes | null>,
  RequestStatus,
  ErrorType | null
] {
  const [error, setError] = useState<ErrorType | null>(null)
  const [status, setStatus] = useState(RequestStatus.INITIAL)
  const token = useSelector(selectToken)

  return [
    async (sessionId: number, voucherId: string): Promise<StatusRes | null> => {
      try {
        setStatus(RequestStatus.REQUESTED)
        const result = await requestRedeemVoucher(token, sessionId, voucherId)
        setStatus(RequestStatus.SUCCEEDED)
        return result
      } catch (e) {
        setStatus(RequestStatus.FAILED)
        setError(ErrorType.INVALID_VOUCHER)
        return null
      }
    },
    status,
    error,
  ]
}
