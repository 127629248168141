import React from "react"
import { Hidden, IconButton } from "@material-ui/core"
import PlusIcon from "@material-ui/icons/Add"
import MinusIcon from "@material-ui/icons/Remove"
import { storeItems } from "../../utils/apiUtils"

interface IZoomProps {
  value: number
  onChange: (value: number) => void
}

const step = 10
const max = 120
const min = 50

export default function Zoom({ value, onChange }: IZoomProps) {
  const handleIncrement = () => {
    const newValue = value + step
    if (newValue <= max) {
      onChange(newValue)
      storeItems({ zoom: newValue.toString() })
    }
  }
  const handleDecrement = () => {
    const newValue = value - step
    if (newValue >= min) {
      onChange(newValue)
      storeItems({ zoom: newValue.toString() })
    }
  }

  return (
    <div>
      <IconButton onClick={handleDecrement} color="inherit">
        <MinusIcon />
      </IconButton>
      <Hidden smDown>
        <span>{value} %</span>
      </Hidden>
      <IconButton onClick={handleIncrement} color="inherit">
        <PlusIcon />
      </IconButton>
    </div>
  )
}
