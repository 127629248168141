import React, { ChangeEvent } from "react"
import { FormattedMessage } from "react-intl"
import { Box, DialogActions, Typography } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import Modal from "../Modal/Modal"
import Button from "../Button/Button"
import DateTimeInput from "../DateTimeInput/DateTimeInput"
import { Field } from "../Messages/FieldMessage"
import Price from "../Price/Price"
import { ComputedBon, Currency } from "../../types/apiTypes"
import { SeatingMap, SectionMap } from "../../models/ordersModel"
import { useLanguage } from "../../hooks/interfaceHooks"
import CheckButton from "../CheckButton/CheckButton"
import { LiteEvent } from "../../types/globalTypes"
import { useSeatingAvailabilityFetch } from "../../hooks/orderHooks"
import plan from "./plan.png"
import BonSummary from "../BonSummary/BonSummary"
import NumberInput from "../NumberInput/NumberInput"
import { decodeInputName, encodeInputName } from "../../utils/stringUtils"

interface ReservationModalProps {
  onTableChange: (tableId: number) => void
  onDateChange: (date: number) => void
  onPersonChange: (number: number) => void
  onCancel: () => void
  isOpen: boolean
  onClose: () => void
  seating: SeatingMap
  sections: SectionMap
  date?: number
  numberOfPersons?: number
  seatingIds: number[]
  price: number
  bonSummary: ComputedBon[]
}

export default function ReservationModal({
  onTableChange,
  onDateChange,
  onPersonChange,
  isOpen,
  onClose,
  seating,
  sections,
  date,
  seatingIds,
  price,
  bonSummary,
  numberOfPersons,
  onCancel,
}: ReservationModalProps) {
  const language = useLanguage()
  const [unavailableSeating, setUnavailableSeating] =
    useSeatingAvailabilityFetch(date)

  const handleTableChange = ({
    currentTarget: { name },
  }: ChangeEvent<HTMLInputElement>) => {
    const [table] = decodeInputName(name)
    const tableId = parseFloat(table)
    onTableChange(tableId)
    if (unavailableSeating.includes(tableId)) {
      setUnavailableSeating(unavailableSeating.filter((id) => id !== tableId))
    }
  }

  const handleDateChange = ({ currentTarget: { value } }: LiteEvent<number>) => {
    onDateChange(value)
  }

  return (
    <Modal
      onClose={onClose}
      open={isOpen}
      maxWidth={"md"}
      fullWidth
      title={
        <FormattedMessage
          id={"tableReservation"}
          defaultMessage={"Table reservation"}
        />
      }
    >
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Box mb={4}>
            <a href={plan} target={"_blank"} rel="noreferrer">
              <img
                src={plan}
                alt={"Plan of the club"}
                style={{ width: `100%` }}
              />
            </a>
          </Box>
          <Box mb={2}>
            <DateTimeInput
              value={date}
              name={"reservationDate"}
              onChange={handleDateChange}
              messageId={Field.DATE}
            />
          </Box>
          <Box mb={4}>
            <NumberInput
              value={numberOfPersons}
              name={"reservationNumberOfPersons"}
              onChange={onPersonChange}
              messageId={Field.NUMBER_OF_PERSONS}
              min={1}
            />
          </Box>
          <Typography variant={"body1"}>
            <FormattedMessage
              id={"bookingPrice"}
              defaultMessage={"Booking price"}
            />
          </Typography>
          <Typography variant={"h6"}>
            <Price currency={Currency.CZK} price={price} />
          </Typography>
          <div>
            <BonSummary summary={bonSummary} />
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          {sections
            .valueSeq()
            .map(
              ({
                translations,
                bons: sectionBons,
                price: sectionPrice,
                id,
              }) => (
                <Box key={id} mb={4}>
                  <Typography variant={"h6"}>
                    {translations[language].title} (
                    <Price price={sectionPrice} />
                    {sectionBons.length > 0 && ", "}
                    <BonSummary
                      separator={", "}
                      element={"span"}
                      summary={sectionBons}
                    />
                    )
                  </Typography>
                  <Box mt={2}>
                    <Grid container spacing={2}>
                      {seating
                        .filter(({ sectionId }) => sectionId === id)
                        .valueSeq()
                        .map((table) => (
                          <Grid item xs={4} sm={3} key={table.id}>
                            <CheckButton
                              onChange={handleTableChange}
                              name={encodeInputName(table.id, "table")}
                              label={table.title}
                              checked={seatingIds.some(
                                (seatingId) => seatingId === table.id
                              )}
                              disabled={
                                !seatingIds.some(
                                  (seatingId) => seatingId === table.id
                                ) && unavailableSeating.includes(table.id)
                              }
                            />
                          </Grid>
                        ))}
                    </Grid>
                  </Box>
                </Box>
              )
            )}
        </Grid>
      </Grid>
      <DialogActions>
        <Button variant={"outlined"} color={"secondary"} onClick={onCancel}>
          <FormattedMessage
            id={"cancelReservation"}
            defaultMessage={"Cancel reservation"}
          />
        </Button>
        <Button variant={"contained"} color={"primary"} onClick={onClose}>
          <FormattedMessage
            id={"submitReservation"}
            defaultMessage={"Save reservation"}
          />
        </Button>
      </DialogActions>
    </Modal>
  )
}
