import React, { ChangeEvent } from "react"
import { EditComponentProps } from "@material-table/core"
import { LiteEvent, OrderDataGrid } from "../../types/globalTypes"
import CellGroup from "../../components/CellGroup/CellGroup"
import Input from "../../components/Input/Input"
import { blankOrderData } from "../../utils/typeUtils"
import { NoteType } from "../../types/apiTypes"
import { Field } from "../../components/Messages/FieldMessage"
import ColorPicker from "../../components/ColorPicker/ColorPicker"
import Can from "../../components/Can/Can"

const messageNoteMap: { [key in NoteType]: Field } = {
  [NoteType.NOTE_ADMIN]: Field.NOTE_ADMIN,
  [NoteType.COLOR]: Field.COLOR,
  [NoteType.NOTE_EMPLOYEE]: Field.NOTE_EMPLOYEE,
  [NoteType.NOTE_OPEN]: Field.NOTE_AGENCY,
  [NoteType.NOTE_EXTRA]: Field.SPECIAL_REQUEST,
}

export function OrderNoteEditCell({
  onChange,
  rowData: { req = blankOrderData },
}: EditComponentProps<OrderDataGrid>) {
  const handleChange = ({
    currentTarget: { name, value },
  }: ChangeEvent<HTMLInputElement>) => {
    const key = name as NoteType
    onChange({ ...req, notes: { ...req.notes, [key]: value } })
  }

  const handleColorChange = ({ currentTarget: { value } }: LiteEvent) => {
    onChange({ ...req, notes: { ...req.notes, [NoteType.COLOR]: value } })
  }

  return (
    <CellGroup>
      <Can resource={"orderAdmin"} operations={["W"]}>
        <Input
          key={NoteType.NOTE_ADMIN}
          type="text"
          name={NoteType.NOTE_ADMIN}
          messageId={messageNoteMap[NoteType.NOTE_ADMIN]}
          value={req.notes[NoteType.NOTE_ADMIN]}
          onChange={handleChange}
          dense
        />
      </Can>
      <Can resource={"orderEmployee"} operations={["W"]}>
        <Input
          key={NoteType.NOTE_EMPLOYEE}
          type="text"
          name={NoteType.NOTE_EMPLOYEE}
          messageId={messageNoteMap[NoteType.NOTE_EMPLOYEE]}
          value={req.notes[NoteType.NOTE_EMPLOYEE]}
          onChange={handleChange}
          dense
        />
      </Can>
      <Input
        key={NoteType.NOTE_OPEN}
        type="text"
        name={NoteType.NOTE_OPEN}
        messageId={messageNoteMap[NoteType.NOTE_OPEN]}
        value={req.notes[NoteType.NOTE_OPEN]}
        onChange={handleChange}
        dense
      />
      <Input
        key={NoteType.NOTE_EXTRA}
        type="text"
        name={NoteType.NOTE_EXTRA}
        messageId={messageNoteMap[NoteType.NOTE_EXTRA]}
        value={req.notes[NoteType.NOTE_EXTRA]}
        onChange={handleChange}
        dense
      />
      <ColorPicker onChange={handleColorChange} value={req?.notes.COLOR} />
    </CellGroup>
  )
}
