import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Form from "../../components/Form/Form"
import Input from "../../components/Input/Input"
import LanguageTab from "../../components/LanguageTab/LanguageTab"
import EntityList from "../../components/EntityList/EntityList"
import MultiSelect from "../../components/MultiSelect/MultiSelect"
import { selectActivityInputOptions } from "../../selectors/activitySelector"
import { fetchActivities } from "../../actions/activityActions"
import ImageUpload from "../../components/ImageUpload/ImageUpload"
import config from "../../config"
import { blankTranslations, languageList } from "../../utils/typeUtils"
import Results from "../../components/Results/Results"
import WebPageMessage, {
  WebPage,
} from "../../components/Messages/WebPageMessage"
import { Field } from "../../components/Messages/FieldMessage"
import { Entity } from "../../components/Messages/EntityMessage"
import {
  useTagDelete,
  useTagFetch,
  useTagSave,
  useTagState,
} from "../../hooks/tagHooks"
import { useGalleryFetch, useGallerySave } from "../../hooks/galleryHooks"
import { usePageSharedState } from "../../hooks/stateHooks"
import { AppState } from "../../store"
import { arrayDiff } from "../../utils/arrayUtils"

export default function PageParty() {
  const dispatch = useDispatch()
  const state = usePageSharedState(config.pageIds.party)
  const tagState = useTagState()
  const activityInputOptions = useSelector((appState: AppState) =>
    selectActivityInputOptions(appState, true, false)
  )
  const [medium, setMedium] = useState<File | string>("")
  const [galleryId, setGalleryId] = useState<number | null>(null)
  const [saveGallery, saveGalleryStatus, saveGalleryError] = useGallerySave([
    config.tagIds.gallery.party,
  ])
  const [fetchGallery, fetchGalleryStatus, fetchGalleryError] = useGalleryFetch(
    config.tagIds.gallery.party
  )
  const [saveTags, saveTagsStatus, saveTagsError] = useTagSave(
    "package",
    config.tagCategories.party
  )
  const [deleteTag, deleteTagStatus, deleteTagError] = useTagDelete("package")
  const [fetchTags, fetchTagsStatus, fetchTagsError] = useTagFetch()
  const [remoteTags, setRemoteTags] = useState<number[]>([])

  useEffect(() => {
    state.fetchPage()
    dispatch(fetchActivities())
    fetchGallery(
      [],
      ([{ media: [{ url }], id } = { media: [{ url: "" }], id: null }]) => {
        setGalleryId(id)
        setMedium(url)
      }
    )
    fetchTags(
      "package",
      (tags) => {
        tagState.fillWithData(
          tags.map((tag) => tag.id.toString()),
          tags.map((tag) => tag.translations),
          tags.map((tag) => tag.entityIds.map((id) => id.toString()))
        )
        setRemoteTags(tags.map((tag) => tag.id))
      },
      config.tagCategories.party
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  const handleSubmit = async () => {
    await state.savePage()
    const tagIds = await saveTags(
      tagState.tags,
      tagState.entities,
      tagState.titles,
      tagState.contents
    )
    if (tagIds) {
      tagState.handleTagSave(tagIds)
      await Promise.all(
        arrayDiff(remoteTags, tagIds).map((id) => deleteTag(id))
      )
      setRemoteTags(tagIds)
    }
    if (medium)
      await saveGallery(
        galleryId,
        [{ source: medium, id: "medium" }],
        [blankTranslations]
      )
  }

  const handleTagDelete = async () => {
    tagState.handleTagDelete()
    // if (isNumeric(tagState.activeTag))
    //   await deleteTag(parseInt(tagState.activeTag, 10))
  }

  return (
    <>
      <Results
        statuses={[state.fetchStatus, state.saveStatus]}
        errors={[state.fetchError, state.saveError]}
        actions={["fetch", "save"]}
        entity={Entity.PAGE}
      />
      <Results
        statuses={[saveGalleryStatus, fetchGalleryStatus]}
        errors={[saveGalleryError, fetchGalleryError]}
        actions={["save", "fetch"]}
        entity={Entity.GALLERY}
      />
      <Results
        statuses={[saveTagsStatus, fetchTagsStatus, deleteTagStatus]}
        errors={[saveTagsError, fetchTagsError, deleteTagError]}
        actions={["save", "fetch", "delete"]}
        entity={Entity.TAG}
      />
      <Form
        statuses={[
          saveTagsStatus,
          fetchTagsStatus,
          saveGalleryStatus,
          fetchGalleryStatus,
          state.fetchStatus,
          state.saveStatus,
        ]}
        title={<WebPageMessage id={WebPage.PARTY} />}
        onSubmit={handleSubmit}
        toolbar={
          <LanguageTab
            language={state.language}
            onChange={state.setLanguage}
            notifications={languageList.filter((lang) => {
              const { content, title, metaData } = state.translations[lang]
              return !content || !title || !metaData
            })}
          />
        }
        useGenericCta
      >
        <Input
          name={`title`}
          messageId={Field.TITLE}
          onChange={state.handleTitle}
          value={state.title}
          showRequiredWarning={state.isSubmitted}
        />
        <Input
          name={`meta`}
          messageId={Field.META}
          onChange={state.handleMeta}
          value={state.meta}
          showRequiredWarning={state.isSubmitted}
        />
        <Input
          name={`keywords`}
          messageId={Field.KEYWORDS}
          onChange={state.handleKeywords}
          value={state.keywords}
          showRequiredWarning={state.isSubmitted}
        />
        <EntityList
          entityPairs={tagState.tags.map((tag) => [
            tag,
            tagState.titles?.[tag]?.[state.language] || "",
          ])}
          onEntityChange={tagState.handleActiveTagChange}
          onCreate={tagState.handleTagCreate}
          onDelete={handleTagDelete}
          onSort={tagState.handleTagSort}
          activeEntity={tagState.activeTag}
        >
          <Input
            name={`tagTitle`}
            messageId={Field.TITLE}
            onChange={(e) =>
              tagState.handleTagTitleChange(
                e.currentTarget.value,
                state.language
              )
            }
            value={tagState.titles?.[tagState.activeTag]?.[state.language]}
          />
          <Input
            name={`tagContent`}
            messageId={Field.CONTENT}
            onChange={(e) =>
              tagState.handleTagContentChange(
                e.currentTarget.value,
                state.language
              )
            }
            value={tagState.contents?.[tagState.activeTag]?.[state.language]}
            multiline
          />
          <MultiSelect
            onChange={tagState.handleEntitiesChange}
            values={tagState.entities?.[tagState.activeTag] || []}
            options={activityInputOptions}
            messageId={Field.ACTIVITIES}
            name={`tagEntities`}
          />
        </EntityList>
        <ImageUpload
          source={medium}
          name={`medium`}
          onUpload={setMedium}
          onDelete={() => setMedium(``)}
          showRequiredWarning={state.isSubmitted}
        />
      </Form>
    </>
  )
}
