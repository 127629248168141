import config from "../config"
import getMockResponse from "../api/mockRequests"
import { HttpMethods, StorageItems, Voucher } from "../types/globalTypes"
import {
  BonCategory,
  BonResponsibility,
  ComputedBon,
  ErrorRes,
  PriceContentResAdmin,
  PriceResInnerAdmin,
  ReservationInnerResAdmin,
  Role,
} from "../types/apiTypes"
import { employeeSet } from "./typeUtils"
import { isNotNil } from "ramda-adjunct"

const storagePrefix = `gf-crm-v1`

export function delay(ms: number): Promise<undefined> {
  return new Promise((res) => setTimeout(res, ms))
}

export function delayMoment() {
  return delay(1000)
}

export function delayWhile() {
  return delay(5000)
}

interface RequestConfig {
  endpoint: string
  method: HttpMethods
  auth?: string
  body?: object
  headers?: HeadersInit
}

async function performMockRequest<T>({
  endpoint,
  body,
  method,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
RequestConfig): Promise<T> {
  // await delay(50)
  const data = getMockResponse(endpoint, method, body) as unknown as T
  if (!data) throw Error(`No mock data found`)
  return new Promise<T>((res) => {
    res(data)
  })
}

async function performRealRequest<T>({
  endpoint,
  method,
  auth,
  body,
  headers,
}: RequestConfig): Promise<T> {
  const baseUrl = auth ? config.privateEndpoint : config.publicEndpoint
  const response = await fetch(`${baseUrl}/${endpoint}`, {
    method,
    headers: {
      ...(!(body instanceof FormData) && {
        "Content-Type": "application/json",
        "Cache-Control": "no-store",
      }),
      Authorization: auth || "Bearer asdf",
      ...headers,
    },
    ...(body !== undefined && {
      body: body instanceof FormData ? body : JSON.stringify(body),
    }),
  })
  const json = await response.json()
  if (response.status >= 300) {
    const error = json as ErrorRes
    throw Error(error?.reason || response.statusText)
  }
  return json
}

export default function performRequest<T>(
  requestConfig: RequestConfig,
  useRealReq = true
): Promise<T> {
  if (process.env.NODE_ENV === "test")
    return performMockRequest<T>(requestConfig)
  if (useRealReq) return performRealRequest<T>(requestConfig)
  return config.useMockData
    ? performMockRequest<T>(requestConfig)
    : performRealRequest<T>(requestConfig)
}

export function generateBasicAuthToken(
  email: string,
  password: string
): string {
  return `Basic ${btoa(`${email}:${password}`)}`
}

function isJson(str: string): boolean {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export function storeItems(items: Partial<StorageItems>): void {
  Object.entries(items).forEach(([key, value]) => {
    localStorage.setItem(
      `${storagePrefix}_${key}`,
      typeof value === "object" ? JSON.stringify(value) : value
    )
  })
}

export function clearItems(keys: string[]) {
  if (window.localStorage)
    keys.forEach((key) => localStorage.removeItem(`${storagePrefix}_${key}`))
}

export function readItems(
  keys: Array<keyof StorageItems>
): Partial<StorageItems> {
  if (!window.localStorage) return {}
  return keys.reduce(
    (items: Partial<StorageItems>, key: keyof StorageItems) => {
      const item = localStorage.getItem(`${storagePrefix}_${key}`)
      if (!item) return items
      if (isJson(item)) {
        items[key] = JSON.parse(item) // eslint-disable-line no-param-reassign
      } else if (item) {
        // @ts-ignore
        items[key] = item // eslint-disable-line no-param-reassign
      }
      return items
    },
    {}
  )
}

export function getBonSummary(bons: ComputedBon[]): ComputedBon[] {
  const summary = bons.reduce(
    (acc: { [key: string]: number }, bon: ComputedBon) => {
      const key = `${bon.category}-${bon.content}`
      acc[key] = acc[key] ? acc[key] + bon.count : bon.count
      return acc
    },
    {}
  )
  return Object.entries(summary).map(([key, number]): ComputedBon => {
    const [category, content] = key.split("-")
    return {
      content,
      count: number,
      category: category as BonCategory,
      responsibility: BonResponsibility.BAR,
    }
  })
}

export function getIdsParam(ids: Array<string | number> = []): string {
  return ids.length > 0 ? `?ids=${ids.join(",")}` : ""
}

export function getIdParam(id?: number | null): string {
  return id ? `/${id}` : ""
}

export function createQuery(
  params?: Record<string, number | string | Array<string | number>>
): string {
  if (!params) return ``
  return Object.entries(params).reduce((acc, [key, value]) => {
    const formattedValue = Array.isArray(value)
      ? value.length > 0
        ? `[${value
            .map((it) => (typeof it === "string" ? `"${it}"` : it))
            .join(`,`)}]`
        : null
      : value
    const separator = acc === `?` ? `` : `&`
    if (isNotNil(formattedValue))
      return `${acc}${separator}${key}=${formattedValue}`
    return acc
  }, `?`)
}

export function getReservationContents(
  reservation?: ReservationInnerResAdmin[]
): string {
  if (!reservation) return ""
  return reservation
    .map(
      ({ seating, section }) =>
        `${seating.title} (${section.translations?.EN?.title})`
    )
    .join(`, `)
}

export function isEmployeeRole(role: Role): boolean {
  return employeeSet.has(role)
}

export function isAgencyRole(role: Role): boolean {
  return role === "Agency"
}

export function isAdminRole(role: Role): boolean {
  return role === "Admin" || role === "SuperAdmin" || role === "Manager"
}

export function downloadBlob(blob: Blob, name: string): void {
  const blobUrl = URL.createObjectURL(blob)
  const link = document.createElement("a")
  link.href = blobUrl
  link.download = name
  document.body.appendChild(link)
  link.dispatchEvent(
    new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  )
  document.body.removeChild(link)
}

export const blobToBase64 = (blob: Blob): Promise<string> => {
  const reader = new FileReader()
  reader.readAsDataURL(blob)
  return new Promise((resolve, reject) => {
    reader.onloadend = () => {
      const { result } = reader
      if (typeof result === "string") {
        resolve(result)
      } else {
        reject()
      }
    }
    reader.onerror = reject
  })
}

function imageToDataUri(
  img: HTMLImageElement,
  width: number,
  height: number,
  x: number = 0,
  y: number = 0
): string {
  console.log({ width, height, x, y })
  const canvas = document.createElement("canvas")
  const ctx = canvas.getContext("2d")
  if (ctx === null) return ""
  canvas.width = width
  canvas.height = height
  ctx.drawImage(img, x, y, width, height, 0, 0, width, height)
  return canvas.toDataURL()
}

export function fileToBase64Image(
  file: File,
  {
    width,
    height,
    x,
    y,
  }: {
    width?: number
    height?: number
    x?: number
    y?: number
  }
): Promise<string> {
  if (!file.type.startsWith("image")) throw new Error("File must be image")
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      const image = document.createElement("img")
      image.src = typeof reader.result === "string" ? reader.result : ""
      image.onload = () => {
        const ratio = image.height / image.width
        const imageWidth = width || image.width
        const imageHeight = height || imageWidth * ratio
        const data = imageToDataUri(image, imageWidth, imageHeight, x, y)
        resolve(data)
      }
      image.onerror = (error) => reject(error)
    }
    reader.onerror = (error) => reject(error)
  })
}

export function getVoucherSurcharge(voucher: Voucher): number {
  return "packageOrder" in voucher ? voucher.price - voucher.deposit : 0
}

export function getUpsalesTotal(
  price: PriceResInnerAdmin,
  key: keyof PriceContentResAdmin = "price"
): number {
  return Object.values(price.upsales).reduce(
    (acc, upsalePrice) => acc + upsalePrice[key],
    0
  )
}

export function assertFulfilled<T>(
  item: PromiseSettledResult<T>
): item is PromiseFulfilledResult<T> {
  return item.status === "fulfilled"
}
