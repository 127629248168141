import { makeStyles, Theme } from "@material-ui/core"

interface Props {
  padding: number
  margin: number
  isSeparated: boolean
}

export default makeStyles((theme: Theme) => ({
  root: {
    paddingTop: ({ padding }: Props) => theme.spacing(padding),
    paddingBottom: ({ padding }: Props) => theme.spacing(padding),
    marginTop: ({ margin }: Props) => theme.spacing(margin),
    marginBottom: ({ margin }: Props) => theme.spacing(margin),
    borderTop: ({ isSeparated }: Props) =>
      isSeparated ? `1px solid ${theme.palette.grey.A100}` : `none`,
    borderBottom: ({ isSeparated }: Props) =>
      isSeparated ? `1px solid ${theme.palette.grey.A100}` : `none`,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "600px",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "400px",
    },
  },
  heading: {
    marginBottom: theme.spacing(2),
  },
}))
