import { SiteReqAdmin, SiteResAdmin } from "types/apiTypes"
import { HttpMethods } from "types/globalTypes"
import performRequest, { getIdParam, getIdsParam } from "utils/apiUtils"

export async function requestGetNews(
  basicAuth: string,
  ids: number[] = []
): Promise<SiteResAdmin[]> {
  return performRequest(
    {
      endpoint: `site${getIdsParam(ids)}`,
      auth: basicAuth,
      method: HttpMethods.GET,
    },
    true
  )
}

export async function requestSaveNews(
  basicAuth: string,
  news: SiteReqAdmin,
  id?: number
): Promise<SiteReqAdmin> {
  return performRequest(
    {
      endpoint: `site${getIdParam(id)}`,
      auth: basicAuth,
      method: id ? HttpMethods.PUT : HttpMethods.POST,
      body: news,
    },
    true
  )
}

export async function requestDeleteNews(basicAuth: string, id: number) {
  return performRequest(
    {
      endpoint: `site${getIdParam(id)}`,
      auth: basicAuth,
      method: HttpMethods.DELETE,
    },
    true
  )
}
