import { createSelector } from "reselect"
import { AppState } from "../store"
import { SessionDataGrid, SessionStatus } from "../types/globalTypes"
import { selectUserRecords } from "./userSelector"
import { SessionResAdmin } from "../types/apiTypes"
import { selectFilters } from "./interfaceSelector"
import { Field } from "../components/Messages/FieldMessage"
import { getBonSummary, getVoucherSurcharge } from "../utils/apiUtils"

export const selectSessionRecords = (state: AppState) => state.session.records

export function selectSessionFetchStatus(state: AppState) {
  return state.session.statuses.fetchSessions
}
export function selectSessionFetchError(state: AppState) {
  return state.session.errors.fetchSessions
}

export const selectSessionList = createSelector(
  selectSessionRecords,
  selectFilters,
  (map, filters): SessionResAdmin[] => {
    const filterStatus = filters[Field.SESSION_STATUS]
    const filterEmployees = filters[Field.EMPLOYEES]
    return map
      .filter(
        (session) =>
          (filterStatus.size > 0
            ? filterStatus.has(
                session.dtEnd ? SessionStatus.ENDED : SessionStatus.ACTIVE
              )
            : true) &&
          (filterEmployees.size > 0
            ? filterEmployees.has(session.userId.toString())
            : true)
      )
      .valueSeq()
      .toArray()
  }
)

export const selectSessionDataGrid = createSelector(
  selectSessionList,
  selectUserRecords,
  (sessions: SessionResAdmin[], users): SessionDataGrid[] =>
    sessions.map((session) => ({
      ...session,
      name: users.get(session.userId.toString())?.fullName || "",
      bonSum: getBonSummary(session.orders.flatMap(({ bonSum }) => bonSum)),
      status: session.dtEnd ? SessionStatus.ENDED : SessionStatus.ACTIVE,
      surchargeReceived: session.orders.reduce(
        (acc, order) => acc + getVoucherSurcharge(order),
        0
      ),
    }))
)
