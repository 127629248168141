import React, { ChangeEvent } from "react"
import { EditComponentProps } from "@material-table/core"
import { ReservationDataGrid } from "../../types/globalTypes"
import { Field } from "../../components/Messages/FieldMessage"
import { blankOrderData } from "../../utils/typeUtils"
import Input from "../../components/Input/Input"

export default function ReservationPersonsEditCell({
  rowData: { req = blankOrderData },
  onChange,
}: EditComponentProps<ReservationDataGrid>) {
  const handleChange = ({
    currentTarget: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    onChange({
      ...req,
      numberOfPersons: parseFloat(value),
    })
  }
  return (
    <Input
      messageId={Field.NUMBER_OF_PERSONS}
      onChange={handleChange}
      name={`reservationTable`}
      value={req.reservation?.numberOfPersons.toString()}
      type={"number"}
      min={1}
      dense
    />
  )
}
