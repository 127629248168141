import React from "react"
import { Descendant } from "slate"
import { RichTextTranslations } from "../../utils/typeUtils"
import { Language } from "../../types/apiTypes"
import RichTextInput from "./RichTextInput"

interface RichTextProps {
  value: RichTextTranslations
  language: Language
  onChange: (newValue: Descendant[]) => void
}

const RichText = ({ value, onChange, language }: RichTextProps) => (
  // force remount when language changes...
  // TODO: throw Slate away
  <RichTextInput value={value[language]} onChange={onChange} key={language} />
)

export default RichText
