import React from "react"
import { FormattedMessage } from "react-intl"
import { OrderStatus } from "../../types/apiTypes"
import OrderStatusMessage from "../Messages/OrderStatusMessage"
import useStyles from "./VoucherStatusStyles"

interface IVoucherStatusProps {
  status?: OrderStatus
  isInvalid: boolean
  isRedeemed: boolean
  isLoading: boolean
  isDepositUnpaid: boolean
}

export default function VoucherStatus({
  status,
  isInvalid,
  isRedeemed,
  isLoading,
  isDepositUnpaid,
}: IVoucherStatusProps) {
  const classes = useStyles({
    isError:
      isInvalid ||
      isRedeemed ||
      isDepositUnpaid ||
      status === OrderStatus.CANCELLED ||
      status === OrderStatus.FULFILLED,
    isSuccess: status === OrderStatus.APPROVED,
    isWarning: status === OrderStatus.CREATED,
  })

  return (
    <div className={classes.root}>
      {isLoading ? (
        <FormattedMessage id={"loading"} defaultMessage={"Loading"} />
      ) : isInvalid ? (
        <FormattedMessage id={"invalid"} defaultMessage={"Invalid"} />
      ) : isRedeemed ? (
        <FormattedMessage id={"alreadyUsed"} defaultMessage={"Already used"} />
      ) : isDepositUnpaid ? (
        <FormattedMessage
          id={"depositNotPaid"}
          defaultMessage={"Deposit not piad"}
        />
      ) : status ? (
        <OrderStatusMessage id={status} />
      ) : (
        <FormattedMessage id={"readyToScan"} defaultMessage={"Ready to scan"} />
      )}
    </div>
  )
}
