import { UserActions, UserMap } from "../models/userModel"
import { ActionTypes } from "../types/actionTypes"
import { UserReq, UserRes } from "../types/apiTypes"

export const fetchUsers = (ids?: number[]) =>
  ({
    type: ActionTypes.FETCH_USERS_REQUESTED,
    payload: { ids },
  } as const)

export const fetchUsersSucceeded = (users: UserMap) =>
  ({
    type: ActionTypes.FETCH_USERS_SUCCEEDED,
    payload: { users },
  } as const)

export const fetchUsersFailed = (message: string) =>
  ({
    type: ActionTypes.FETCH_USERS_FAILED,
    payload: { message },
  } as const)

export const saveUser = (formData: UserReq, id?: number) =>
  ({
    type: ActionTypes.SAVE_USER_REQUESTED,
    payload: { formData, id },
  } as const)

export const saveUserSucceeded = (user: UserRes) =>
  ({
    type: ActionTypes.SAVE_USER_SUCCEEDED,
    payload: { user },
  } as const)

export const saveUserFailed = (message: string) =>
  ({
    type: ActionTypes.SAVE_USER_FAILED,
    payload: { message },
  } as const)

export const deleteUser = (id: number) =>
  ({
    type: ActionTypes.DELETE_USER_REQUESTED,
    payload: { id },
  } as const)

export const deleteUserSucceeded = (id: number) =>
  ({
    type: ActionTypes.DELETE_USER_SUCCEEDED,
    payload: { id },
  } as const)

export const deleteUserFailed = (message: string) =>
  ({
    type: ActionTypes.DELETE_USER_FAILED,
    payload: { message },
  } as const)

export const resetUserStatus = (key: UserActions) =>
  ({
    type: ActionTypes.RESET_USER_STATUS,
    payload: { key },
  } as const)
