import React, { ReactElement } from "react"
import { useImageReader } from "../../hooks/utilHooks"

interface IImageProps {
  source: string | File
  render?: (source: string) => ReactElement | null
  alt?: string
}

export default function Image({ source, alt = ``, render }: IImageProps) {
  const src = useImageReader(source)
  return render ? render(src) : <img src={src} alt={alt} />
}
