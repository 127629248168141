import React from "react"
import { useLocation } from "react-router-dom"
import { MenuItem, ListItemText, ListItemIcon } from "@material-ui/core"
import CheckIcon from "@material-ui/icons/Check"
import config from "../../config"
import RouteLink from "../RouteLink/RouteLink"
import useStyles from "./NavLinkStyles"

interface INavLinkProps {
  route: keyof typeof config.routes
  children: React.ReactNode
}

export default function NavLink({ route, children }: INavLinkProps) {
  const { pathname } = useLocation()
  const { path } = config.routes[route]
  const classes = useStyles()
  const isRouteActive = pathname.includes(path())

  return (
    <RouteLink key={route} route={route}>
      <MenuItem selected={isRouteActive}>
        <ListItemText>{children}</ListItemText>
        {isRouteActive && (
          <ListItemIcon className={classes.icon}>
            <CheckIcon />
          </ListItemIcon>
        )}
      </MenuItem>
    </RouteLink>
  )
}
