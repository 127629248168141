import {
  IdRes,
  PackageUpsaleReq,
  PackageUpsaleResAdmin,
} from "../types/apiTypes"
import performRequest, { getIdParam, getIdsParam } from "../utils/apiUtils"
import { HttpMethods } from "../types/globalTypes"

export async function requestGetUpsales(
  basicAuth: string,
  ids: number[] = []
): Promise<PackageUpsaleResAdmin[]> {
  return performRequest(
    {
      endpoint: `upsale${getIdsParam(ids)}`,
      auth: basicAuth,
      method: HttpMethods.GET,
    },
    true
  )
}

export async function requestSaveUpsale(
  basicAuth: string,
  upsale: PackageUpsaleReq,
  id?: number
): Promise<IdRes<number>> {
  return performRequest(
    {
      endpoint: `upsale${getIdParam(id)}`,
      auth: basicAuth,
      method: id ? HttpMethods.PUT : HttpMethods.POST,
      body: upsale,
    },
    true
  )
}

export async function requestDeleteUpsale(
  basicAuth: string,
  id: number
): Promise<IdRes<number>> {
  return performRequest(
    {
      endpoint: `upsale${getIdParam(id)}`,
      auth: basicAuth,
      method: HttpMethods.DELETE,
    },
    true
  )
}
