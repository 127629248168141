import React, { ChangeEvent } from "react"
import { useDispatch, useSelector } from "react-redux"
import Avatar from "@material-ui/core/Avatar"
import Box from "@material-ui/core/Box"
import LockOutlinedIcon from "@material-ui/icons/LockOutlined"
import Typography from "@material-ui/core/Typography"
import { FormattedMessage } from "react-intl"
import RouteLink from "../../components/RouteLink/RouteLink"
import { logUser } from "../../actions/interfaceActions"
import LoadingButton from "../../components/LoadingButton/LoadingButton"
import { selectLoginFetchStatus } from "../../selectors/interfaceSelector"
import { RequestStatus } from "../../types/statusTypes"
import Input from "../../components/Input/Input"
import { Field } from "../../components/Messages/FieldMessage"
import { ErrorType } from "../../components/Messages/ErrorMessage"

export default function SignIn() {
  const dispatch = useDispatch()
  const fetchStatus = useSelector(selectLoginFetchStatus)

  const handleSubmit = (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault()
    dispatch(logUser(e.target.email.value, e.target.password.value))
  }

  return (
    <>
      <Box display={"flex"} alignItems={"center"} mb={2}>
        <Box pr={2}>
          <Avatar>
            <LockOutlinedIcon />
          </Avatar>
        </Box>
        <Typography component="h1" variant="h5">
          <FormattedMessage id={`signIn`} defaultMessage={`Sign In`} />
        </Typography>
      </Box>
      {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
      <form onSubmit={handleSubmit} method={"post"} role={"form"}>
        <Input
          messageId={Field.EMAIL}
          name={"email"}
          type="email"
          inputError={
            fetchStatus === RequestStatus.FAILED
              ? { type: ErrorType.UNAUTHORIZED }
              : undefined
          }
          autoFocus
          required
        />
        <Input
          messageId={Field.PASSWORD}
          name={"password"}
          type="password"
          required
        />
        <Box display={"flex"} alignItems={"center"} flexWrap={"wrap"} mt={3}>
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            isLoading={fetchStatus === RequestStatus.REQUESTED}
            disabled={fetchStatus === RequestStatus.SUCCEEDED}
          >
            <FormattedMessage id={`signIn`} defaultMessage={`Sign In`} />
          </LoadingButton>
          <Box flexGrow={1} textAlign={"right"}>
            <RouteLink route={"forgotPassword"}>
              <FormattedMessage
                id={`forgottenPassword`}
                defaultMessage={`Forgotten password`}
              />
            </RouteLink>
          </Box>
        </Box>
      </form>
    </>
  )
}
