import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ResourceMap } from "../models/activityModel"
import { RequestStatus } from "../types/statusTypes"
import {
  selectResourceFetchError,
  selectResourceFetchStatus,
  selectResources,
} from "../selectors/activitySelector"
import { fetchResources } from "../actions/activityActions"

export function useResourceFetch(
  ids?: number[]
): [ResourceMap, RequestStatus, string] {
  const dispatch = useDispatch()
  const status = useSelector(selectResourceFetchStatus)
  const error = useSelector(selectResourceFetchError)
  const resources = useSelector(selectResources)
  useEffect(() => {
    dispatch(fetchResources(ids))
  }, [dispatch, ids])
  return [resources, status, error]
}
