import React from "react"
import { Typography } from "@material-ui/core"
import useStyles from "./FieldSetStyles"
import FieldMessage, { Field } from "../Messages/FieldMessage"

interface IFieldSetProps {
  children: React.ReactNode
  messageId?: Field
  dense?: boolean
}

export default function FieldSet({
  children,
  messageId,
  dense = false,
}: IFieldSetProps) {
  const classNames = useStyles({ dense })

  return (
    <fieldset className={classNames.root}>
      {messageId && (
        <Typography component={"legend"} variant={dense ? "caption" : "h6"}>
          <FieldMessage id={messageId} />
        </Typography>
      )}
      {children}
    </fieldset>
  )
}
