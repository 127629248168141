import { call, put, select } from "redux-saga/effects"
import {
  fetchInvoices,
  fetchInvoicesFailed,
  fetchInvoicesSucceed,
} from "../actions/invoiceActions"
import { requestInvoices } from "../api/invoiceRequests"
import { selectToken } from "../selectors/interfaceSelector"
import { normalizeInvoice } from "../models/invoiceModel"
import { InvoiceResAdmin } from "../types/apiTypes"

export function* fetchInvoicesSaga({
  payload: { ids },
}: ReturnType<typeof fetchInvoices>) {
  try {
    const auth: string = yield select(selectToken)
    const invoices: InvoiceResAdmin[] = yield call(requestInvoices, auth, ids)
    yield put(fetchInvoicesSucceed(normalizeInvoice(invoices)))
    // eslint-disable-next-line
  } catch (e: any) {
    yield put(fetchInvoicesFailed(e.message))
  }
}
