import React from "react"
import { Link as RouterLink, LinkProps } from "react-router-dom"
import MaterialLink from "@material-ui/core/Link"
import config from "../../config"

interface Props {
  route: keyof typeof config.routes
  children: React.ReactNode
  id?: number
}

const Component = React.forwardRef<HTMLAnchorElement, LinkProps>(
  (props, ref) => <RouterLink ref={ref} {...props} />
)

export default function RouteLink({ route, id, children }: Props) {
  const { path } = config.routes[route]

  return (
    <MaterialLink
      underline={"none"}
      component={Component}
      to={path(id ? id.toString() : undefined)}
      color="inherit"
    >
      {children}
    </MaterialLink>
  )
}
