import { makeStyles, Theme } from "@material-ui/core/styles"

export default makeStyles((theme: Theme) => ({
  child: (props: { dense: boolean }) => ({
    display: `flex`,
    alignItems: `center`,
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(props.dense ? 0 : 2),
    marginTop: theme.spacing(props.dense ? 0 : 1),
    "&:not(:last-child)": {
      borderBottom: props.dense ? 0 : `1px solid ${theme.palette.divider}`,
    },
  }),
  removeButton: (props: { dense: boolean }) => ({
    marginRight: theme.spacing(props.dense ? 1 : 2),
  }),
  actions: (props: { dense: boolean }) => ({
    "&:not(:empty):not(:only-child)": {
      marginTop: theme.spacing(props.dense ? 1 : 2),
    },
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  }),
}))
