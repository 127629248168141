import { makeStyles, Theme } from "@material-ui/core"
import { cellStyleCompact } from "./DataGridOverrides"

export default makeStyles((theme: Theme) => ({
  root: {
    "& > *:not(:first-child)": {
      marginTop: theme.spacing(4),
    },
    "& thead > tr:first-child > th:first-child": cellStyleCompact,
    "& thead > tr:first-child > th:first-child svg": {
      display: `none`,
    },
    "& thead th": {
      fontWeight: `bold`,
    },
    /*
     * have to use !important here, because material-table is inlining its
     * styles on invidivual elements making it impossible overriding them with
     * regular selector of any specificity
     */
    "& table.MuiTable-root td.MuiTableCell-root": {
      paddingLeft: "8px !important",
      paddingRight: "8px !important",
    },
  },
}))

export const toolbarStyles = makeStyles((theme: Theme) => ({
  root: {
    "& > * > *:first-child": {
      display: 'contents',
      marginRight: theme.spacing(4),
    },
    "& > * > * >*:first-child": {
      marginRight: "auto",
    },
    "& > * > *:nth-child(2)": {
      display: 'none'
    },
  },
}))

export const paperStyles = makeStyles({
  root: {},
})
