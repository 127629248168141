import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { invoicePaymentInputOptions, periodInputOptions } from "utils/typeUtils"
import Filter, { FilterTypes } from "../Filter/Filter"
import { selectAgencyRecords } from "../../selectors/agencySelector"
import { Field } from "../Messages/FieldMessage"
import { selectFilterMap } from "../../selectors/interfaceSelector"
import { setFilter } from "../../actions/interfaceActions"
import { getPeriodByDates } from "../../utils/timeUtils"

export default function InvoiceFilter() {
  const agencies = useSelector(selectAgencyRecords)
  const filters = useSelector(selectFilterMap)
  const dispatch = useDispatch()

  const handleChange = (key: string, value: string[]) => {
    dispatch(setFilter(key, value))
  }

  return (
    <Filter
      fields={[
        {
          name: Field.AGENCY,
          values: filters.get(Field.AGENCY)?.toArray() || [],
          type: FilterTypes.MultiSelect,
          options: agencies.toArray().map(([, agency]) => ({
            value: agency.id.toString(),
            content: agency.title,
          })),
        },
        {
          name: Field.INVOICE_STATUS,
          values: filters.get(Field.INVOICE_STATUS)?.toArray() || [],
          type: FilterTypes.RadioList,
          options: invoicePaymentInputOptions,
        },
        {
          name: Field.PERIOD,
          values: [getPeriodByDates(filters.get(Field.DATE))],
          type: FilterTypes.RadioList,
          options: periodInputOptions,
        },
        {
          name: Field.DATE,
          values: filters.get(Field.DATE)?.toArray() || [],
          type: FilterTypes.DateRange,
          options: [],
        },
      ]}
      onChange={handleChange}
    />
  )
}
