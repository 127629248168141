import { call, delay, put, select } from "redux-saga/effects"
import {
  deleteProduct,
  deleteProductFailed,
  deleteProductSucceeded,
  fetchProduct,
  fetchProductFailed,
  fetchProductSuccess,
  resetProductStatus,
  saveProduct,
  saveProductFailed,
  saveProductSucceeded,
} from "../actions/productActions"
import {
  requestDeleteProduct,
  requestGetProducts,
  requestSaveProduct,
} from "../api/productsRequests"
import { normalizeProducts, ProductActions } from "../models/productModel"
import { IdRes, ProductResAdmin } from "../types/apiTypes"
import { selectToken } from "../selectors/interfaceSelector"

function* resetStatus(key: ProductActions) {
  yield delay(3000)
  yield put(resetProductStatus(key))
}

export function* fetchProductsSaga({
  payload: { ids },
}: ReturnType<typeof fetchProduct>) {
  try {
    const auth: string = yield select(selectToken)
    const products: ProductResAdmin[] = yield call(
      requestGetProducts,
      auth,
      ids
    )
    yield put(fetchProductSuccess(normalizeProducts(products)))
    // eslint-disable-next-line
  } catch (e: any) {
    yield put(fetchProductFailed(e.message))
  } finally {
    yield* resetStatus("fetchProducts")
  }
}

export function* saveProductSaga({
  payload: { data, id },
}: ReturnType<typeof saveProduct>) {
  try {
    const auth: string = yield select(selectToken)
    const res: IdRes<number> = yield call(requestSaveProduct, auth, data, id)
    const [product]: ProductResAdmin[] = yield call(requestGetProducts, auth, [
      res.id,
    ])
    yield put(saveProductSucceeded(product))
    // eslint-disable-next-line
  } catch (e: any) {
    yield put(saveProductFailed(e.message))
  } finally {
    yield* resetStatus("saveProduct")
  }
}

export function* deleteProductSaga({
  payload: { id },
}: ReturnType<typeof deleteProduct>) {
  try {
    const auth: string = yield select(selectToken)
    yield call(requestDeleteProduct, auth, id)
    yield put(deleteProductSucceeded(id))
    // eslint-disable-next-line
  } catch (e: any) {
    yield put(deleteProductFailed(e.message))
  } finally {
    yield* resetStatus("deleteProduct")
  }
}
