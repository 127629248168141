import React, { ChangeEvent, useState } from "react"
import Input from "../Input/Input"
import { Field } from "../Messages/FieldMessage"
import { FormField } from "../../types/apiTypes"

interface INumberInputProps {
  value?: number
  name: string
  onChange: (value: number) => void
  min?: number
  max?: number
  dense?: boolean
  disabled?: boolean
  messageId: Field | FormField
}

export default function NumberInput({
  value,
  name,
  min,
  max,
  onChange,
  messageId,
  dense = false,
  disabled = false
}: INumberInputProps) {
  const [isCleared, setIsCleared] = useState<boolean>(false)

  const handleChange = ({
    currentTarget: { valueAsNumber },
  }: ChangeEvent<HTMLInputElement>) => {
    if (isNaN(valueAsNumber)) {
      setIsCleared(true)
    } else {
      if (min && valueAsNumber < min) {
        onChange(min)
      } else {
        onChange(valueAsNumber)
      }
    }
  }

  const handleBlur = () => {
    setIsCleared(false)
  }

  return (
    <Input
      onChange={handleChange}
      onBlur={handleBlur}
      name={name}
      value={isCleared ? undefined : value}
      type={"number"}
      min={min}
      max={max}
      dense={dense}
      disabled={disabled}
      messageId={messageId}
    />
  )
}
