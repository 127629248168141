import React from "react"
import MultipleFiledSet from "../../components/MultipleFiledSet/MultipleFiledSet"
import { Entity } from "../../components/Messages/EntityMessage"
import Modal from "../../components/Modal/Modal"
import { ExtendedOrderReqAdmin } from "../../types/globalTypes"
import BonCategorySelect from "../../components/BonCategorySelect/BonCategorySelect"
import Input from "../../components/Input/Input"
import { Field } from "../../components/Messages/FieldMessage"
import ComboBox from "../../components/ComboBox/ComboBox"
import { bonResponsibilityOptions, sampleBon } from "../../utils/typeUtils"
import InlineFieldset from "../../components/InlineFieldset/InlineFieldset"
import { GeneralEvent } from "../../utils/inputUtils"
import { decodeInputName, encodeInputName } from "../../utils/stringUtils"

interface IOrderBonModalProps {
  onChange: (data: ExtendedOrderReqAdmin) => void
  onClose: () => void
  isOpen: boolean
  req: ExtendedOrderReqAdmin
}

export default function OrderBonModal({
  onChange,
  onClose,
  isOpen,
  req,
}: IOrderBonModalProps) {
  const getBonIndex = (key: string): number => parseFloat(key)

  const handleAddBon = () => {
    onChange({ ...req, bons: [...req.bons, sampleBon] })
  }

  const handleRemoveBon = (key: string) => {
    const index = getBonIndex(key)
    onChange({ ...req, bons: req.bons.filter((_, idx) => idx !== index) })
  }

  const handleChangeBon = (e: GeneralEvent) => {
    const [key, name] = decodeInputName(e.currentTarget.name)
    const index = getBonIndex(key)
    onChange({
      ...req,
      bons: req.bons.map((bon, idx) =>
        idx === index ? { ...bon, [name]: e.currentTarget.value } : bon
      ),
    })
  }


  return (
    <Modal onClose={onClose} open={isOpen} maxWidth={"md"} fullWidth>
      <MultipleFiledSet
        entity={Entity.BON}
        onCreate={handleAddBon}
        onRemove={handleRemoveBon}
      >
        {req.bons.map((bon, index) => (
          <InlineFieldset key={encodeInputName(index)}>
            <BonCategorySelect
              onChange={handleChangeBon}
              dense={false}
              value={bon.category}
              name={encodeInputName(index, 'category')}
            />
            <Input
              messageId={Field.TITLE}
              name={encodeInputName(index, 'content')}
              value={bon.content}
              onChange={handleChangeBon}
            />
            <Input
              messageId={Field.QUANTITY}
              name={encodeInputName(index, 'count')}
              value={bon.count}
              onChange={handleChangeBon}
              type={"number"}
              min={1}
            />
            <Input
              messageId={Field.FOR_N_PERSONS}
              name={encodeInputName(index, 'forNPersons')}
              value={bon.forNPersons}
              onChange={handleChangeBon}
              type={"number"}
              min={1}
            />
            <ComboBox
              messageId={Field.RESPONSIBILITIES}
              name={encodeInputName(index, 'responsibility')}
              value={bon.responsibility}
              dense={false}
              options={bonResponsibilityOptions}
              onChange={handleChangeBon}
              disableClearable
            />
          </InlineFieldset>
        ))}
      </MultipleFiledSet>
    </Modal>
  )
}
