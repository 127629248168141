import React from "react"
import { ReservationDataGrid } from "../../types/globalTypes"
import Price from "../../components/Price/Price"
import Can from "../../components/Can/Can"

export default function ReservationPriceCell({ price }: ReservationDataGrid) {
  return (
    <Can resource={"orderPrice"} operations={["R", "W"]}>
      <Price price={price} />
    </Can>
  )
}
