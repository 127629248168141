import { call, put, select } from "redux-saga/effects"
import { fetchSessions, fetchSessionsSucceed } from "../actions/sessionActions"
import { selectToken } from "../selectors/interfaceSelector"
import { requestSessions } from "../api/sessionRequests"
import { normalizeSession } from "../models/sessionModel"
import { fetchInvoicesFailed } from "../actions/invoiceActions"
import { SessionResAdmin } from "../types/apiTypes"

export function* fetchSessionsSaga({
  payload: { ids },
}: ReturnType<typeof fetchSessions>) {
  try {
    const auth: string = yield select(selectToken)
    const sessions: SessionResAdmin[] = yield call(requestSessions, auth, ids)
    yield put(fetchSessionsSucceed(normalizeSession(sessions)))
    // eslint-disable-next-line
  } catch (e: any) {
    yield put(fetchInvoicesFailed(e.message))
  }
}
