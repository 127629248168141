import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"
import { Props } from "./SpinnerLayout"

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "fixed",
      width: "100vw",
      height: "100vh",
      top: 0,
      left: 0,
      zIndex: theme.zIndex.modal,
      background: theme.palette.primary.dark,
      color: theme.palette.background.paper,
      opacity: (props: Props) => (props.isVisible ? 1 : 0),
      pointerEvents: "none",
      transition: `opacity 0.5s`,
    },
  })
)
