import React, { useMemo } from "react"
import DateFnsUtils from "@date-io/date-fns"
import enLocale from "date-fns/locale/en-GB"
import csLocale from "date-fns/locale/cs"
import { createTheme, CssBaseline } from "@material-ui/core"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import { useSchemePreference } from "hooks/interfaceHooks"
import { deepPurple, grey, purple, red } from "@material-ui/core/colors"
import { ThemeProvider } from "@material-ui/core/styles"
import { useSelector } from "react-redux"
import { selectLocale } from "../../selectors/interfaceSelector"

interface IProvidersProps {
  children: (isDark: boolean, toggleScheme: () => void) => React.ReactNode
}

const localeMap = {
  en: enLocale,
  cs: csLocale,
}

export default function Providers({ children }: IProvidersProps) {
  const [isDark, toggleScheme] = useSchemePreference()
  const locale = useSelector(selectLocale)
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          primary: {
            main: isDark ? purple["600"] : purple["900"],
          },
          secondary: {
            main: deepPurple["400"],
            dark: grey["900"],
          },
          error: red,
          type: isDark ? "dark" : "light",
        },
        typography: {
          button: {
            fontSize: `0.8em`,
            letterSpacing: `0.2em`,
          },
        },
        overrides: {
          MuiButton: {
            outlined: {
              padding: "6px 15px",
            },
          },
          MuiInputBase: {
            root: {
              fontSize: `inherit`,
            },
          },
          MuiFormControlLabel: {
            label: {
              fontSize: `inherit`,
            },
          },
        },
      }),
    [isDark]
  )
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children(isDark, toggleScheme)}
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  )
}
