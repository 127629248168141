import React, { useCallback, useState } from "react"
import { createEditor, Descendant } from "slate"
import { Editable, Slate, withReact } from "slate-react"
import {
  BlockButton,
  Element,
  Leaf,
  LinkButton,
  MarkButton,
  RemoveLinkButton,
  RichTextToolbar as Toolbar,
  withLinks,
} from "./RichTextToolbar"
import FormatBoldIcon from "@material-ui/icons/FormatBold"
import FormatItalicIcon from "@material-ui/icons/FormatItalic"
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered"
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted"
import { useIntl } from "react-intl"
import {
  RenderElementProps,
  RenderLeafProps,
} from "slate-react/dist/components/editable"

interface IRichTextInputProps {
  value: Descendant[]
  onChange: (newValue: Descendant[]) => void
}

export default function RichTextInput({
  value,
  onChange,
}: IRichTextInputProps) {
  const { formatMessage } = useIntl()
  const [editor] = useState(() => withLinks(withReact(createEditor())))
  const renderLeaf = useCallback(
    ({ children, ...props }: RenderLeafProps) => (
      <Leaf {...props}>{children}</Leaf>
    ),
    []
  )

  const handleChange = (val: Descendant[]) => {
    onChange(val)
  }

  const renderElement = useCallback(
    ({ children, ...props }: RenderElementProps) => (
      <Element {...props}>{children}</Element>
    ),
    []
  )

  editor.children = value

  return (
    <Slate editor={editor} value={value} onChange={handleChange}>
      <div>
        <Toolbar>
          <MarkButton format="bold" icon={<FormatBoldIcon />} />
          <MarkButton format="italic" icon={<FormatItalicIcon />} />
          <BlockButton
            format="numbered-list"
            icon={<FormatListNumberedIcon />}
          />
          <BlockButton
            format="bulleted-list"
            icon={<FormatListBulletedIcon />}
          />
          <LinkButton />
          <RemoveLinkButton />
        </Toolbar>
        <Editable
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder={formatMessage({
            id: "richTextPlaceholder",
            defaultMessage: "Html text",
          })}
          style={{
            border: "1px solid rgb(0 0 0 / 23%)",
            padding: "1rem",
            borderRadius: "4px",
            marginBottom: "1rem",
          }}
        />
      </div>
    </Slate>
  )
}
