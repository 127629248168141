import React, { useState } from "react"
import { Navigate } from "react-router-dom"
import { ShowResAdmin } from "../../types/apiTypes"
import DataGrid from "../../components/DataGrid/DataGrid"
import config from "../../config"
import RouteMessage from "../../components/Messages/RouteMessage"
import { Field } from "../../components/Messages/FieldMessage"
import { useShowDelete, useShowFetch } from "../../hooks/showHooks"
import Results from "../../components/Results/Results"
import { Entity } from "../../components/Messages/EntityMessage"
import StatusProgressBar from "../../components/StatusProgressBar/StatusProgressBar"
import { useLanguage, useLocalStoragePagination } from "../../hooks/interfaceHooks"
import Button from "../../components/Button/Button"
import AddBox from "@material-ui/icons/AddBox"
import { FormattedMessage } from "react-intl"

export default function ShowList() {
  const language = useLanguage()
  const [shows, fetchStatus, fetchError] = useShowFetch()
  const [deleteShow, deleteStatus, deleteError] = useShowDelete()
  const [redirectId, setRedirectId] = useState<string | null>(null)
  const paginationProps = useLocalStoragePagination("show")

  if (redirectId !== null)
    return <Navigate to={config.routes.showDetail.path(redirectId)} />

  return (
    <>
      <Results
        statuses={[fetchStatus, deleteStatus]}
        errors={[fetchError, deleteError]}
        actions={["fetch", "delete"]}
        entity={Entity.CAREER}
      />
      <StatusProgressBar statuses={[fetchStatus]} />
      <DataGrid<ShowResAdmin>
        {...paginationProps}
        title={<RouteMessage id={"showList"} />}
        onRowClick={(_, row) => setRedirectId(row ? row.id.toString() : null)}
        columns={[
          {
            title: Field.TITLE,
            field: `translations.${language}.title`,
          }
        ]}
        data={shows.valueSeq().toArray()}
        editable={{
          onRowDelete: ({ id }) =>
            new Promise<void>((resolve) => {
              deleteShow(id)
              resolve()
            }),
        }}
        cta={
          <Button
            onClick={() => setRedirectId(``)}
            variant="contained"
            color={`primary`}
            startIcon={<AddBox />}
          >
            <FormattedMessage
              id={`createShow`}
              defaultMessage={`Create show`}
            />
          </Button>
        }
      />
    </>
  )
}
