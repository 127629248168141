import React from "react"
import Brightness7Icon from "@material-ui/icons/Brightness7"
import Brightness4Icon from "@material-ui/icons/Brightness4"
import { IconButton } from "@material-ui/core"
import { RouterProps } from "../Router/Router"

export default function ColorSchemeSelect({
  isDark,
  toggleColorScheme = () => {},
}: RouterProps) {
  return (
    <IconButton onClick={toggleColorScheme} color="inherit">
      {isDark ? <Brightness7Icon /> : <Brightness4Icon />}
    </IconButton>
  )
}
