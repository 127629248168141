import * as React from "react"
import TextField, { TextFieldProps } from "@material-ui/core/TextField"
import { InputError } from "../../types/globalTypes"
import FieldMessage, { Field } from "../Messages/FieldMessage"
import ErrorMessage, { ErrorType } from "../Messages/ErrorMessage"
import { FormField } from "../../types/apiTypes"
import useInputStyles from "./InputStyle"

interface IInputProps {
  messageId?: Field | FormField
  name: string
  inputError?: InputError
  dense?: boolean
  showRequiredWarning?: boolean
  required?: boolean
  min?: number
  max?: number
}

export default function Input({
  messageId,
  name,
  inputError,
  dense,
  helperText,
  InputProps,
  InputLabelProps,
  defaultValue,
  type,
  value,
  min = type === "number" ? 0 : undefined,
  max,
  showRequiredWarning = false,
  inputProps,
  label,
  required = false,
  ...props
}: IInputProps & TextFieldProps) {
  const displayWarning = showRequiredWarning && !value
  const classes = useInputStyles()
  return (
    <TextField
      {...props}
      required={required}
      className={classes.root}
      type={type}
      InputProps={{
        ...InputProps,
        style: { ...(InputProps?.style || {}), fontSize: `inherit` },
      }}
      inputProps={{ ...inputProps, min, max }}
      InputLabelProps={{
        ...InputLabelProps,
        style: { ...(InputLabelProps?.style || {}), fontSize: `inherit` },
        shrink: true,
      }}
      name={name}
      id={name}
      label={label || (messageId && <FieldMessage id={messageId} />)}
      size={dense ? "small" : "medium"}
      margin={dense ? "none" : "normal"}
      variant={"outlined"}
      error={!!inputError || displayWarning}
      helperText={
        inputError?.type || displayWarning ? (
          <ErrorMessage id={inputError?.type || ErrorType.SHOULD_FILL} />
        ) : (
          helperText
        )
      }
      fullWidth
      defaultValue={defaultValue}
      value={value}
      key={`${defaultValue}`}
    />
  )
}
