import { useCallback, useState } from "react"
import { useSelector } from "react-redux"
import { selectToken } from "../selectors/interfaceSelector"
import { ErrorType } from "../components/Messages/ErrorMessage"
import { RequestStatus } from "../types/statusTypes"

export function useToken(): string {
  return useSelector(selectToken)
}

export function useFetchHook() {
  const token = useToken()
  const [error, setError] = useState<ErrorType | string>("")
  const [status, setStatus] = useState(RequestStatus.INITIAL)
  const handleRequest = useCallback(() => {
    setStatus(RequestStatus.REQUESTED)
  }, [])
  const handleSuccess = useCallback(() => {
    setStatus(RequestStatus.SUCCEEDED)
  }, [])
  const handleFail = useCallback((message: string) => {
    setStatus(RequestStatus.FAILED)
    setError(message)
  }, [])
  const handleReset = useCallback(() => {
    setStatus(RequestStatus.INITIAL)
    setError("")
  }, [])
  return {
    token,
    status,
    error,
    handleRequest,
    handleSuccess,
    handleFail,
    handleReset,
  }
}
