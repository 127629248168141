import { Set } from "immutable"
import { RequestStatus } from "../types/statusTypes"
import { InterfaceState, makeInterfaceState } from "../models/interfaceModel"
import { ActionTypes, AnyAction } from "../types/actionTypes"
import { Field } from "../components/Messages/FieldMessage"
import { Entity } from "../components/Messages/EntityMessage"
import { getFilterDatesByPeriod } from "../utils/timeUtils"
import { Period } from "../types/filterTypes"

const initialState: InterfaceState = makeInterfaceState()

export default function interfaceReducer(
  state = initialState,
  action: AnyAction | null = null
): InterfaceState {
  switch (action?.type) {
    case ActionTypes.LOG_USER_REQUESTED: {
      return state.set(
        "statuses",
        state.statuses.set("loginUser", RequestStatus.REQUESTED)
      )
    }
    case ActionTypes.LOG_USER_SUCCEEDED: {
      return state
        .set(
          "statuses",
          state.statuses.set("loginUser", RequestStatus.SUCCEEDED)
        )
        .set("user", action.payload.user)
        .set("token", action.payload.token)
    }
    case ActionTypes.LOG_USER_FAILED: {
      return state
        .set("statuses", state.statuses.set("loginUser", RequestStatus.FAILED))
        .set("errors", state.errors.set("loginUser", action.payload.message))
    }
    case ActionTypes.RESTORE_INTERFACE_REQUESTED: {
      return state.set(
        "statuses",
        state.statuses.set("restoreInterface", RequestStatus.REQUESTED)
      )
    }
    case ActionTypes.RESTORE_INTERFACE_SUCCEEDED: {
      return state
        .set(
          "statuses",
          state.statuses.set("restoreInterface", RequestStatus.SUCCEEDED)
        )
        .set(
          "zoom",
          action.payload.zoom
            ? parseInt(action.payload.zoom, 10)
            : initialState.zoom
        )
        .set("locale", action.payload.locale || initialState.locale)
    }
    case ActionTypes.RESTORE_INTERFACE_FAILED: {
      return state
        .set(
          "statuses",
          state.statuses.set("restoreInterface", RequestStatus.FAILED)
        )
        .set(
          "errors",
          state.errors.set("restoreInterface", action.payload.message)
        )
    }
    case ActionTypes.LOGOUT_USER_REQUESTED: {
      return state.set(
        "statuses",
        state.statuses.set("logoutUser", RequestStatus.REQUESTED)
      )
    }
    case ActionTypes.LOGOUT_USER_SUCCEEDED: {
      return state
        .set(
          "statuses",
          state.statuses
            .set("logoutUser", RequestStatus.SUCCEEDED)
            .set("loginUser", RequestStatus.INITIAL)
        )
        .set("errors", state.errors.set("logoutUser", ""))
        .set("token", "")
        .set("user", null)
    }
    case ActionTypes.LOGOUT_USER_FAILED: {
      return state
        .set("statuses", state.statuses.set("logoutUser", RequestStatus.FAILED))
        .set("errors", state.errors.set("logoutUser", action.payload.message))
        .set("user", null)
    }
    case ActionTypes.CHANGE_LOCALE_REQUESTED: {
      return state
        .set(
          "statuses",
          state.statuses.set("changeLocale", RequestStatus.REQUESTED)
        )
        .set("locale", action.payload.locale)
    }
    case ActionTypes.CHANGE_LOCALE_SUCCEEDED: {
      return state
        .set(
          "statuses",
          state.statuses.set("changeLocale", RequestStatus.SUCCEEDED)
        )
        .set("locale", action.payload.locale)
    }
    case ActionTypes.CHANGE_LOCALE_FAILED: {
      return state
        .set(
          "statuses",
          state.statuses.set("changeLocale", RequestStatus.FAILED)
        )
        .set("errors", state.errors.set("changeLocale", action.payload.message))
    }
    case ActionTypes.SET_ZOOM: {
      return state.set("zoom", action.payload.value)
    }
    case ActionTypes.SET_BACKLINK: {
      return state.set("backlink", action.payload.value)
    }
    // filter
    case ActionTypes.SET_FILTER: {
      const { key, value } = action.payload
      switch (key) {
        case Entity.ORDER:
          return state.set(
            "filters",
            initialState.filters.set(key, Set<string>(value))
          )
        case Field.ACTIVITY:
        case Field.AGENCY:
        case Field.EMPLOYEES:
        case Field.SOURCE:
        case Field.TYPE:
        case Field.PAYMENT_METHOD:
        case Field.DATE:
        case Field.INVOICE_STATUS:
        case Field.ORDER_STATUS:
        case Field.SESSION_STATUS:
        case Field.ROLE:
          return state.set(
            "filters",
            state.filters.set(key, Set<string>(value))
          )
        case Field.PERIOD:
          return state.set(
            "filters",
            state.filters.set(
              Field.DATE,
              getFilterDatesByPeriod(value[0] as Period)
            )
          )
        default:
          return state
      }
    }
    default: {
      return state
    }
  }
}
