import { CustomEditor, LinkElement } from "./index"
import { BaseEditor, Editor, Element as SlateElement, Range, Transforms } from "slate"
import { FormatBlock, FormatMark } from "./RichTextToolbar"
import { ReactEditor } from "slate-react"

export const isLinkActive = (editor: CustomEditor) => {
  const [link] = Editor.nodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "link"
  })
  return !!link
}
export const isMarkActive = (editor: BaseEditor & ReactEditor, format: FormatMark) => {
  const marks = Editor.marks(editor)
  return marks ? marks[format] === true : false
}
export const isBlockActive = (editor: CustomEditor, format: FormatBlock) => {
  const [match] = Editor.nodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format
  })

  return !!match
}
export const unwrapLink = (editor: CustomEditor) => {
  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "link"
  })
}
export const wrapLink = (editor: CustomEditor, url: string) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor)
  }

  const { selection } = editor
  const isCollapsed = selection && Range.isCollapsed(selection)
  const link: LinkElement = {
    type: "link",
    url,
    children: isCollapsed ? [{ text: url }] : []
  }

  if (isCollapsed) {
    Transforms.insertNodes(editor, link)
  } else {
    Transforms.wrapNodes(editor, link, { split: true })
    Transforms.collapse(editor, { edge: "end" })
  }
}