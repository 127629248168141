import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { PackageUpsaleReq } from "../types/apiTypes"
import { RequestStatus } from "../types/statusTypes"
import {
  selectUpsaleDeleteError,
  selectUpsaleDeleteStatus,
  selectUpsaleFetchError,
  selectUpsaleFetchStatus,
  selectUpsaleRecords,
  selectUpsaleSaveError,
  selectUpsaleSaveStatus,
} from "../selectors/upsaleSelector"
import {
  deleteUpsale,
  fetchUpsales,
  saveUpsale,
} from "../actions/upsaleActions"
import { UpsaleMap } from "../models/upsaleModel"

export function useUpsaleSave(): [
  (data: PackageUpsaleReq, id?: string) => void,
  RequestStatus,
  string
] {
  const dispatch = useDispatch()
  const status = useSelector(selectUpsaleSaveStatus)
  const error = useSelector(selectUpsaleSaveError)
  return [
    (data: PackageUpsaleReq, id?: string) =>
      dispatch(saveUpsale(data, id ? parseFloat(id) : undefined)),
    status,
    error,
  ]
}

export function useUpsaleDelete(): [
  (id: number) => void,
  RequestStatus,
  string
] {
  const dispatch = useDispatch()
  const status = useSelector(selectUpsaleDeleteStatus)
  const error = useSelector(selectUpsaleDeleteError)
  return [(id: number) => dispatch(deleteUpsale(id)), status, error]
}

export function useUpsaleFetch(
  id?: string
): [UpsaleMap, RequestStatus, string] {
  const dispatch = useDispatch()
  const upsales = useSelector(selectUpsaleRecords)
  const status = useSelector(selectUpsaleFetchStatus)
  const error = useSelector(selectUpsaleFetchError)
  const upsale = id ? upsales.get(id) : null
  useEffect(() => {
    if (!upsale) dispatch(fetchUpsales(id ? [parseFloat(id)] : undefined))
  }, [dispatch, id, upsale])
  return [upsales, status, error]
}
