import { useSelector } from "react-redux"
import { useState } from "react"
import { RequestStatus } from "../types/statusTypes"
import { selectToken } from "../selectors/interfaceSelector"
import {
  requestDeleteGallery,
  requestGetGalleries,
  requestSaveGallery,
  requestUploadFile,
} from "../api/galleryRequests"
import { assertFulfilled, delay } from "../utils/apiUtils"
import {
  GalleryResAdmin,
  IdRes,
  Language,
  LinkRes,
  MediaType,
  MediumReq,
  MediumResAdmin,
} from "../types/apiTypes"
import { requestGetTag } from "../api/tagRequests"
import { ITranslations, MediaItem, TranslationMap } from "../types/globalTypes"
import {
  blankSingleTranslations,
  blankTranslations,
  fullToSimpleTranslation, listToPriority
} from "../utils/typeUtils"
import { removeKey } from "../utils/objectUtils"
import { arrayMove } from "../utils/arrayUtils"
import { uid } from "../utils/stringUtils"

export interface CropDimensions {
  x: number
  y: number
  width: number
  height: number
}

interface CropMap {
  [key: string]: CropDimensions
}

export const defaultCropDimensions: CropDimensions = {
  x: 0,
  y: 0,
  width: 3440,
  height: 3440,
}

export function useGallerySave(
  tagIds: number[] = []
): [
  (
    galleryId: null | number,
    media: MediaItem[],
    mediaTranslations: Array<TranslationMap | null>,
    types?: MediaType[],
    tagId?: number,
    translation?: TranslationMap,
    priority?: number,
    crop?: CropMap
  ) => Promise<number | null>,
  RequestStatus,
  string
] {
  const token = useSelector(selectToken)
  const [status, setStatus] = useState(RequestStatus.INITIAL)
  const [error, setError] = useState("")

  return [
    async (
      galleryId: null | number,
      media: MediaItem[],
      mediaTranslations?: Array<TranslationMap | null>,
      types?: MediaType[],
      tagId?: number,
      translation?: TranslationMap,
      priority = 99,
      crop?: CropMap
    ): Promise<number | null> => {
      try {
        setStatus(RequestStatus.REQUESTED)
        const uploads = await Promise.allSettled(
          media.map((medium) =>
            medium.source instanceof File
              ? requestUploadFile(
                  token,
                  medium.source,
                  crop ? crop[medium.id] || defaultCropDimensions : undefined
                )
              : new Promise<LinkRes>((res) =>
                  res({ link: medium.source as string })
                )
          )
        )
        const mediaReq: MediumReq[] = uploads.filter(assertFulfilled).map(
          ({ value: { link } }, idx): MediumReq => ({
            url: link,
            type: types?.[idx] || MediaType.IMAGE,
            translations: mediaTranslations?.[idx] || blankTranslations,
          })
        )
        const { id }: IdRes<number> = await requestSaveGallery(
          token,
          {
            id: galleryId || 0,
            isActive: true,
            media: mediaReq,
            priority,
            tagIds: listToPriority([...tagIds, tagId]),
            translations: translation || blankTranslations,
          },
          galleryId
        )
        setStatus(RequestStatus.SUCCEEDED)
        return id
        // eslint-disable-next-line
      } catch (e: any) {
        setStatus(RequestStatus.FAILED)
        setError(e.message)
        return null
      } finally {
        await delay(500)
        setError("")
        setStatus(RequestStatus.INITIAL)
      }
    },
    status,
    error,
  ]
}

export function useGalleryFetch(
  tagId?: number
): [
  (
    ids: Array<number | null>,
    callback: (galleries: Array<GalleryResAdmin | undefined>) => void
  ) => Promise<null>,
  RequestStatus,
  string
] {
  const token = useSelector(selectToken)
  const [status, setStatus] = useState(RequestStatus.INITIAL)
  const [error, setError] = useState("")

  return [
    async (
      ids: Array<number | null>,
      callback: (galleries: Array<GalleryResAdmin | undefined>) => void
    ): Promise<null> => {
      try {
        setStatus(RequestStatus.REQUESTED)
        const realIds = ids.filter(Boolean) as number[]
        if (realIds.length > 0) {
          const galleries = await requestGetGalleries(token, realIds)
          callback(
            ids.map((id) =>
              id === null
                ? undefined
                : galleries.find((gallery) => gallery.id === id)
            )
          )
        }
        if (tagId && realIds.length === 0) {
          const { entityIds } = await requestGetTag(token, "gallery", tagId)
          if (entityIds.length > 0) {
            const galleries = await requestGetGalleries(token, entityIds)
            callback(galleries.sort((a, b) => a.priority - b.priority))
          }
        }
        setStatus(RequestStatus.SUCCEEDED)
        // eslint-disable-next-line
      } catch (e: any) {
        console.error(e)
        setStatus(RequestStatus.FAILED)
        setError(e.message)
      }
      return null
    },
    status,
    error,
  ]
}

export function useGalleryDelete(): [
  (id: number) => Promise<null>,
  RequestStatus,
  string
] {
  const token = useSelector(selectToken)
  const [status, setStatus] = useState(RequestStatus.INITIAL)
  const [error, setError] = useState("")

  return [
    async (id: number): Promise<null> => {
      try {
        setStatus(RequestStatus.REQUESTED)
        await requestDeleteGallery(token, id)
        setStatus(RequestStatus.SUCCEEDED)
        // eslint-disable-next-line
      } catch (e: any) {
        setStatus(RequestStatus.FAILED)
        setError(e.message)
      } finally {
        await delay(500)
        setError("")
        setStatus(RequestStatus.INITIAL)
      }
      return null
    },
    status,
    error,
  ]
}

export function useGalleryState(defaultNamespace: string = "default") {
  const [gallery, setGallery] = useState<{ [key: string]: MediaItem[] }>({
    [defaultNamespace]: [],
  })
  const [labels, setLabels] = useState<ITranslations>({})
  const [contents, setContents] = useState<ITranslations>({})
  const [crop, setCrop] = useState<CropMap>({})

  return {
    gallery,
    getGallery: (namespace = defaultNamespace) => gallery[namespace] || [],
    setGallery,
    labels,
    contents,
    crop,
    getMediumCrop(id: string): CropDimensions | undefined {
      return crop[id]
    },
    setMediumCrop(id: string, dimensions: CropDimensions): void {
      setCrop({ ...crop, [id]: dimensions })
    },
    handleUpload(files: Array<File | string>, namespace = defaultNamespace) {
      const newGallery = files.map((file) => ({
        id: uid(),
        source: file,
      }))
      const newTranslations = newGallery.reduce((acc, item) => {
        acc[item.id] = blankSingleTranslations
        return acc
      }, {} as ITranslations)
      setGallery({
        ...gallery,
        [namespace]: [...(gallery[namespace] || []), ...newGallery],
      })
      setLabels({ ...labels, ...newTranslations })
      setContents({ ...contents, ...newTranslations })
    },
    handleLabelChange(id: string, language: Language, value: string) {
      setLabels({
        ...labels,
        [id]: {
          ...labels[id],
          [language]: value,
        },
      })
    },
    handleContentChange(id: string, language: Language, value: string) {
      setContents({
        ...contents,
        [id]: {
          ...contents[id],
          [language]: value,
        },
      })
    },
    handleDelete(id: string, namespace = defaultNamespace) {
      setGallery({
        ...gallery,
        [namespace]: gallery[namespace].filter((i) => i.id !== id),
      })
      setLabels(removeKey(labels, id))
      setContents(removeKey(contents, id))
    },
    handleSort(
      source: number,
      destination: number,
      namespace = defaultNamespace
    ) {
      setGallery({
        ...gallery,
        [namespace]: arrayMove(gallery[namespace], source, destination),
      })
    },
    fillData(mediaMap: { [namespace: string]: MediumResAdmin[] }) {
      const galleryWithKeys: [
        string,
        Array<MediumResAdmin & { id: string }>
      ][] = Object.entries(mediaMap).map(([key, media]) => [
        key,
        media.map((medium) => ({ id: uid(), ...medium })),
      ])
      setGallery(
        Object.fromEntries(
          galleryWithKeys.map(([namespace, media]) => [
            namespace,
            media.map(({ url, id }) => ({ id, source: url })),
          ])
        )
      )
      setLabels(
        Object.fromEntries(
          galleryWithKeys
            .flatMap(([, media]) => media)
            .map(({ id, translations }) => [
              id,
              fullToSimpleTranslation(translations, "title"),
            ])
        )
      )
      setContents(
        Object.fromEntries(
          galleryWithKeys
            .flatMap(([, media]) => media)
            .map(({ id, translations }) => [
              id,
              fullToSimpleTranslation(translations, "content"),
            ])
        )
      )
    },
  }
}
