import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { sessionStatusOptions } from "utils/typeUtils"
import Filter, { FilterTypes } from "../Filter/Filter"
import { selectUserInputOptions } from "../../selectors/userSelector"
import { Field } from "../Messages/FieldMessage"
import { selectFilterMap } from "../../selectors/interfaceSelector"
import { setFilter } from "../../actions/interfaceActions"

export default function SessionFilter() {
  const filters = useSelector(selectFilterMap)
  const userOptions = useSelector(selectUserInputOptions)
  const dispatch = useDispatch()

  const handleChange = (key: string, value: string[]) => {
    dispatch(setFilter(key, value))
  }

  return (
    <Filter
      fields={[
        {
          name: Field.SESSION_STATUS,
          values: filters.get(Field.SESSION_STATUS)?.toArray() || [],
          options: sessionStatusOptions,
          type: FilterTypes.RadioList,
        },
        {
          name: Field.EMPLOYEES,
          values: filters.get(Field.EMPLOYEES)?.toArray() || [],
          options: userOptions,
          type: FilterTypes.MultiSelect,
        },
      ]}
      onChange={handleChange}
    />
  )
}
