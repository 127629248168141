import makeStyles from "@material-ui/core/styles/makeStyles"
import { Theme } from "@material-ui/core"

export default makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 0,
    display: `block`,
    "& > input": {
      appearance: `none`,
      padding: 0,
      margin: 0,
      width: 0,
      height: 0,
    },
    "& > label": {
      display: `inline-block`,
      padding: theme.spacing(1.5),
      borderRadius: theme.spacing(1),
      width: `100%`,
      overflow: `hidden`,
      textOverflow: `ellipsis`,
      cursor: "pointer",
      textAlign: "center",
      letterSpacing: 2,
      border: `1px solid ${theme.palette.text.disabled}`,
    },
    "& > input:checked + label": {
      borderColor: theme.palette.secondary.main,
      background: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },
    "& > input:disabled + label": {
      borderColor: theme.palette.grey.A100,
      background: theme.palette.grey.A100,
      color: theme.palette.grey.A700,
      cursor: "not-allowed",
    },
  },
}))
