// @ts-ignore
import { v4 as uuidv4 } from "uuid"

const separator = "@"

export function uid(): string {
  return uuidv4()
}

export function encodeInputName(...parts: Array<string|null|number>): string {
  return parts.join(separator)
}

export function decodeInputName(name: string): string[] {
  return name.split(separator)
}