import {
  BlockingReq,
  BonReq,
  FormField,
  PackageCancellationPolicyReq,
  PackageOptionResAdmin,
  RequiredRoleReq,
} from "../../types/apiTypes"
import { firstTagId } from "../../hooks/tagHooks"
import { millisecondsInDay } from "../../utils/timeUtils"
import { sampleBon } from "../../utils/typeUtils"
import { uid } from "../../utils/stringUtils"
import { lensPath, over } from "ramda"
import { removeKey } from "../../utils/objectUtils"

enum Types {
  CREATE_OPTION,
  REMOVE_OPTION,
  CHANGE_OPTION,
  CHANGE_OPTION_FIELDS,
  ADD_POLICY,
  REMOVE_POLICY,
  CHANGE_POLICY,
  ADD_ROLE,
  REMOVE_ROLE,
  CHANGE_ROLE,
  ADD_BLOCK,
  REMOVE_BLOCK,
  CHANGE_BLOCK,
  ADD_BON,
  REMOVE_BON,
  CHANGE_BON,
  FILL_WITH_OPTIONS,
  USE_WEB_POLICIES,
}

export const sampleCancelPolicy: PackageCancellationPolicyReq = {
  interval: 0,
  percentage: 0,
}

export const defaultWebPolicies = {
  zero: {
    interval: 30 * millisecondsInDay,
    percentage: 0,
  },
  half: {
    interval: 29 * millisecondsInDay,
    percentage: 50,
  },
  full: {
    interval: 2 * millisecondsInDay,
    percentage: 100,
  },
}

export const sampleRole: RequiredRoleReq = {
  durationActual: 0,
  durationAfter: 0,
  durationBefore: 0,
  note: "",
  offset: 0,
  role: "Dancer",
  reward: 1000,
}

export const sampleBlock: BlockingReq = {
  quantityBlocked: 0,
  quantityRequired: 0,
  resourceId: 1,
  durationActual: 0,
  durationAfter: 0,
  durationBefore: 0,
  offset: 0,
}

const sampleOption = {
  isConstrainedByOpeningHours: false,
  maxCapacity: 10,
  minCapacity: 1,
  availableFrom: 0,
  availableTo: 0,
  costExternal: 0,
  costPerPerson: 0,
  costPersons: 1,
  cancelPolicies: { [uid()]: { ...sampleCancelPolicy } },
  roles: { [uid()]: { ...sampleRole } },
  blocks: { [uid()]: { ...sampleBlock } },
  bons: { [uid()]: { ...sampleBon } },
  requiredFields: new Set([]) as Set<FormField>,
  optionalFields: new Set([]) as Set<FormField>,
}

type State = { [key: string]: typeof sampleOption }

export const activityDetailState: State = {
  [firstTagId]: { ...sampleOption },
}

export const activityDetailActionCreators = {
  fillWithOptions: (options: PackageOptionResAdmin[]) =>
    ({
      type: Types.FILL_WITH_OPTIONS,
      payload: { options, optionId: "" },
    } as const),
  addPolicy: (optionId: string) =>
    ({
      type: Types.ADD_POLICY,
      payload: { optionId },
    } as const),
  removePolicy: (optionId: string, key: string) =>
    ({
      type: Types.REMOVE_POLICY,
      payload: { optionId, key },
    } as const),
  changePolicy: (optionId: string, key: string, prop: string, value: string) =>
    ({
      type: Types.CHANGE_POLICY,
      payload: { optionId, key, prop, value },
    } as const),
  addRole: (optionId: string) =>
    ({
      type: Types.ADD_ROLE,
      payload: { optionId },
    } as const),
  removeRole: (optionId: string, key: string) =>
    ({
      type: Types.REMOVE_ROLE,
      payload: { optionId, key },
    } as const),
  changeRole: (optionId: string, key: string, prop: string, value: string) =>
    ({
      type: Types.CHANGE_ROLE,
      payload: { optionId, key, prop, value },
    } as const),
  addBlock: (optionId: string) =>
    ({
      type: Types.ADD_BLOCK,
      payload: { optionId },
    } as const),
  removeBlock: (optionId: string, key: string) =>
    ({
      type: Types.REMOVE_BLOCK,
      payload: { optionId, key },
    } as const),
  changeBlock: (optionId: string, key: string, prop: string, value: string) =>
    ({
      type: Types.CHANGE_BLOCK,
      payload: { optionId, key, prop, value },
    } as const),
  addBon: (optionId: string) =>
    ({
      type: Types.ADD_BON,
      payload: { optionId },
    } as const),
  removeBon: (optionId: string, key: string) =>
    ({
      type: Types.REMOVE_BON,
      payload: { optionId, key },
    } as const),
  changeBon: (optionId: string, key: string, prop: string, value: string) =>
    ({
      type: Types.CHANGE_BON,
      payload: { optionId, key, prop, value },
    } as const),
  changeOption: (optionId: string, key: string, value: string) =>
    ({
      type: Types.CHANGE_OPTION,
      payload: { optionId, key, value },
    } as const),
  changeOptionFields: (
    optionId: string,
    key: string,
    fields?: Set<FormField>
  ) =>
    ({
      type: Types.CHANGE_OPTION_FIELDS,
      payload: { optionId, key, fields },
    } as const),
  createOption: (optionId: string) =>
    ({
      type: Types.CREATE_OPTION,
      payload: { optionId },
    } as const),
  removeOption: (optionId: string) =>
    ({
      type: Types.REMOVE_OPTION,
      payload: { optionId },
    } as const),
  useWebPolicies: (optionId: string) =>
    ({
      type: Types.USE_WEB_POLICIES,
      payload: { optionId },
    } as const),
}

// explanation: https://warhol.io/blog/reducing-redux-boilerplate-in-typescript
/* eslint-disable @typescript-eslint/no-explicit-any */
type Action = {
  [Name in keyof typeof activityDetailActionCreators]: typeof activityDetailActionCreators[Name] extends (
    ...args: any[]
  ) => any
    ? ReturnType<typeof activityDetailActionCreators[Name]>
    : never
}[keyof typeof activityDetailActionCreators]
/* eslint-disable @typescript-eslint/no-explicit-any */

export function activityDetailReducer(state: State, action: Action): State {
  const { optionId } = action.payload

  switch (action.type) {
    case Types.FILL_WITH_OPTIONS: {
      return action.payload.options.reduce((acc: State, option) => {
        acc[option.id] = {
          isConstrainedByOpeningHours: option.isConstrainedByOpeningHours,
          maxCapacity: option.maxCapacity,
          minCapacity: option.minCapacity,
          availableFrom: option.availableFrom,
          availableTo: option.availableTo,
          costExternal: option.cost,
          costPerPerson: option.costPerNCapitas.price,
          costPersons: option.costPerNCapitas.numberOfPersons,
          requiredFields: new Set(option.requiredFields),
          optionalFields: new Set(option.optionalFields),
          blocks: option.blocking.reduce(
            (draft: { [key: string]: typeof sampleBlock }, block, idx) => {
              draft[idx] = block
              return draft
            },
            {}
          ),
          bons: option.bons.reduce(
            (draft: { [key: string]: typeof sampleBon }, bon, idx) => {
              draft[idx] = bon
              return draft
            },
            {}
          ),
          cancelPolicies: option.cancellationPolicy.reduce(
            (
              draft: { [key: string]: typeof sampleCancelPolicy },
              policy,
              idx
            ) => {
              draft[idx] = policy
              return draft
            },
            {}
          ),
          roles: option.requiredRoles.reduce(
            (draft: { [key: string]: typeof sampleRole }, role, idx) => {
              draft[idx] = role
              return draft
            },
            {}
          ),
        }
        return acc
      }, {} as State)
    }

    case Types.CHANGE_OPTION: {
      const { key, value } = action.payload
      return {
        ...state,
        [optionId]: {
          ...state[optionId],
          [key]: key === "isConstrainedByOpeningHours" ? Boolean(value) : parseFloat(value),
        },
      }
    }

    case Types.CHANGE_OPTION_FIELDS: {
      const { key, fields } = action.payload
      return fields
        ? {
            ...state,
            [optionId]: {
              ...state[optionId],
              [key]: fields,
            },
          }
        : state
    }

    case Types.ADD_POLICY: {
      return {
        ...state,
        [optionId]: {
          ...state[optionId],
          cancelPolicies: {
            ...state[optionId].cancelPolicies,
            [uid()]: { ...sampleCancelPolicy },
          },
        },
      }
    }

    case Types.REMOVE_POLICY: {
      const { key } = action.payload
      return {
        ...state,
        [optionId]: {
          ...state[optionId],
          cancelPolicies: removeKey(state[optionId].cancelPolicies, key),
        },
      }
    }

    case Types.CHANGE_POLICY: {
      const { key, prop, value } = action.payload
      const path = lensPath<State, PackageCancellationPolicyReq>([
        optionId,
        "cancelPolicies",
        key,
      ])
      const modifier = (
        policy: PackageCancellationPolicyReq
      ): PackageCancellationPolicyReq => {
        if (prop === "days")
          return {
            ...policy,
            interval:
              (value ? parseFloat(value) * millisecondsInDay : 0) +
              (policy.interval % millisecondsInDay),
          }
        if (prop === "interval")
          return {
            ...policy,
            interval:
              parseFloat(value) +
              Math.floor(policy.interval / millisecondsInDay) *
                millisecondsInDay,
          }
        return {
          ...policy,
          percentage: parseFloat(value),
        }
      }
      return over(path, modifier, state)
    }

    case Types.ADD_BLOCK: {
      return {
        ...state,
        [optionId]: {
          ...state[optionId],
          blocks: {
            ...state[optionId].blocks,
            [uid()]: { ...sampleBlock },
          },
        },
      }
    }

    case Types.REMOVE_BLOCK: {
      const { key } = action.payload
      return {
        ...state,
        [optionId]: {
          ...state[optionId],
          blocks: removeKey(state[optionId].blocks, key),
        },
      }
    }

    case Types.CHANGE_BLOCK: {
      const { key, prop, value } = action.payload
      const path = lensPath<State, BlockingReq>([optionId, "blocks", key])
      const modifier = (role: BlockingReq): BlockingReq => ({
        ...role,
        [prop]: parseFloat(value),
      })
      return over(path, modifier, state)
    }

    case Types.ADD_ROLE: {
      return {
        ...state,
        [optionId]: {
          ...state[optionId],
          roles: {
            ...state[optionId].roles,
            [uid()]: { ...sampleRole },
          },
        },
      }
    }

    case Types.REMOVE_ROLE: {
      const { key } = action.payload
      return {
        ...state,
        [optionId]: {
          ...state[optionId],
          roles: removeKey(state[optionId].roles, key),
        },
      }
    }

    case Types.ADD_BON: {
      return {
        ...state,
        [optionId]: {
          ...state[optionId],
          bons: {
            ...state[optionId].bons,
            [uid()]: { ...sampleBon },
          },
        },
      }
    }

    case Types.REMOVE_BON: {
      const { key } = action.payload
      return {
        ...state,
        [optionId]: {
          ...state[optionId],
          bons: removeKey(state[optionId].bons, key),
        },
      }
    }

    case Types.CHANGE_BON: {
      const { key, prop, value } = action.payload
      const path = lensPath<State, BonReq>([optionId, "bons", key])
      const modifier = (role: BonReq): BonReq => ({
        ...role,
        [prop]:
          prop === "forNPersons" || prop === "count"
            ? parseFloat(value)
            : value,
      })
      return over(path, modifier, state)
    }

    case Types.CHANGE_ROLE: {
      const { key, prop, value } = action.payload
      const path = lensPath<State, RequiredRoleReq>([optionId, "roles", key])
      const modifier = (role: RequiredRoleReq): RequiredRoleReq => ({
        ...role,
        [prop]: prop === "role" || prop === "note" ? value : parseFloat(value),
      })
      return over(path, modifier, state)
    }

    case Types.CREATE_OPTION: {
      return { ...state, [optionId]: sampleOption }
    }

    case Types.REMOVE_OPTION: {
      return removeKey(state, optionId)
    }

    case Types.USE_WEB_POLICIES: {
      return {
        ...state,
        [optionId]: {
          ...state[optionId],
          cancelPolicies: defaultWebPolicies,
        },
      }
    }

    default:
      return state
  }
}
