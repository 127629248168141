import React from "react"
import PageHomepage from "./PageHomepage"
import PageSupport from "./PageSupport"
import PageGallery from "./PageGallery"
import PageCareer from "./PageCareer"
import PageContact from "./PageContact"
import PageParty from "./PageParty"
import PageOffer from "./PageOffer"
import PageShow from "./PageShow"
import { useIdParam } from "../../hooks/routerHooks"

export default function PageDetail() {
  const pageKey = useIdParam()
  switch (pageKey) {
    case "homepage":
      return <PageHomepage />
    case "party":
      return <PageParty />
    case "offer":
      return <PageOffer />
    case "support":
      return <PageSupport />
    case "gallery":
      return <PageGallery />
    case "career":
      return <PageCareer />
    case "contact":
      return <PageContact />
    case "show":
      return <PageShow />
    default:
      return null
  }
}
