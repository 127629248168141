import { Set } from "immutable"
import { createSelector } from "reselect"
import { employeeSet } from "utils/typeUtils"
import { selectAgencyRecords } from "./agencySelector"
import { Field } from "../components/Messages/FieldMessage"
import { selectFilters, selectLoggedUser } from "./interfaceSelector"
import { UserRes } from "../types/apiTypes"
import { AppState } from "../store"

export function selectUserFetchStatus(state: AppState) {
  return state.user.statuses.fetchUsers
}

export function selectUserSaveStatus(state: AppState) {
  return state.user.statuses.saveUser
}

export function selectUserDeleteStatus(state: AppState) {
  return state.user.statuses.deleteUser
}

export function selectUserFetchError(state: AppState) {
  return state.user.errors.fetchUsers
}

export function selectUserSaveError(state: AppState) {
  return state.user.errors.saveUser
}

export function selectUserDeleteError(state: AppState) {
  return state.user.errors.deleteUser
}

export function selectUserRecords(state: AppState) {
  return state.user.records
}

export const selectUserGridData = createSelector(
  selectUserRecords,
  selectAgencyRecords,
  selectFilters,
  selectLoggedUser,
  (userMap, agencyMap, filters, loggedUser) =>
    userMap
      .valueSeq()
      .toArray()
      .filter((user) => {
        // TODO: temporarily filter agency's foreign users (should be API's work)
        if (
          loggedUser &&
          loggedUser.roles.includes("Agency") &&
          user.agencyId !== loggedUser.agencyId
        ) {
          return false
        }
        if (filters[Field.ROLE].size === 0) return true
        return filters[Field.ROLE].intersect(Set(user.roles)).size > 0
      })
      .map((user) => ({
        ...user,
        agency: user.agencyId
          ? agencyMap.get(user.agencyId.toString())?.title
          : null,
      }))
)

export const selectUserFilterList = createSelector(
  selectUserGridData,
  selectFilters,
  (userMap) => userMap
)

export const selectUserMap = createSelector(selectUserRecords, (users) =>
  users.valueSeq()
)

export const selectUserList = createSelector(selectUserMap, (map) =>
  map
    .toArray()
    .sort((a, b) =>
      (a.nickname || a.fullName).localeCompare(b.nickname || b.fullName)
    )
)

export const selectUserInputOptions = createSelector(selectUserList, (users) =>
  users.map((user) => ({
    value: user.id.toString(),
    content: `${user.fullName} (${user.email})`,
  }))
)

export const selectEmployeesList = createSelector(selectUserList, (users) =>
  users.reduce((acc: UserRes[], user) => {
    if (user.roles.some((r) => employeeSet.has(r))) {
      return [...acc, user]
    }
    return acc
  }, [])
)

export const selectEmployeesInputOptions = createSelector(
  selectEmployeesList,
  (users) =>
    users.map((user) => ({
      value: user.id.toString(),
      content: `${user.nickname} (${user.email})`,
    }))
)

export const selectUserLookupTable = createSelector(
  selectUserRecords,
  (userMap) => userMap.map((user) => user.fullName).toJS()
)

export const selectLastInsertedUser = createSelector(
  selectUserRecords,
  (userMap): UserRes => userMap.sort((a, b) => a.id - b.id).last()
)
