import React from "react"
import { EditComponentProps } from "@material-table/core"
import { ExtendedOrderReqAdmin, OrderDataGrid } from "../../types/globalTypes"
import CellGroup from "../../components/CellGroup/CellGroup"
import { blankOrderData } from "../../utils/typeUtils"
import { Field } from "../../components/Messages/FieldMessage"
import { useIsCompetent } from "../../hooks/interfaceHooks"
import { PaymentMethod } from "../../types/apiTypes"
import NumberInput from "../../components/NumberInput/NumberInput"

const calcBalance = (price: number, deposit: number): number => price - deposit

export function OrderCostsEditCell({
  rowData: { req = blankOrderData },
  onChange,
}: EditComponentProps<OrderDataGrid>) {
  const canEditPrice = useIsCompetent("orderPrice", "W")
  const canSeePrice = useIsCompetent("orderPrice", "R", "W")
  const canEditCosts = useIsCompetent("orderCosts", "W")
  const canSeeCosts = useIsCompetent("orderCosts", "R", "W")
  const isCashPayment = req.paymentMethodDeposit === PaymentMethod.CASH

  const changeRow = (data: ExtendedOrderReqAdmin): void => {
    onChange(data)
  }

  const handlePriceChange = (price: number) => {
    changeRow({ ...req, price, balance: calcBalance(price, req.deposit) })
  }

  const handleCostChange = (cost: number) => {
    changeRow({ ...req, cost })
  }

  const handleDepositChange = (deposit: number) => {
    changeRow({ ...req, deposit, balance: calcBalance(req.price, deposit) })
  }

  const handleBalanceChange = (balance: number) => {
    changeRow({ ...req, balance })
  }

  return (
    <CellGroup>
      {canSeePrice && (
        <NumberInput
          messageId={Field.TOTAL_PRICE}
          name={`price`}
          onChange={handlePriceChange}
          value={req.price || 0}
          dense
          min={0}
          disabled={!canEditPrice}
        />
      )}
      {canSeeCosts && (
        <NumberInput
          messageId={Field.COSTS}
          name={`cost`}
          onChange={handleCostChange}
          value={req.cost || 0}
          min={0}
          dense
          disabled={!canEditCosts}
        />
      )}
      {canSeePrice && (
        <NumberInput
          messageId={Field.DEPOSIT}
          name={`deposit`}
          min={0}
          onChange={handleDepositChange}
          value={req.deposit || 0}
          dense
          disabled={!canEditPrice}
        />
      )}
      {isCashPayment && canSeePrice && (
        <NumberInput
          messageId={Field.BALANCE}
          name={`balance`}
          min={0}
          onChange={handleBalanceChange}
          value={req.balance || 0}
          dense
          disabled={!canEditPrice}
        />
      )}
    </CellGroup>
  )
}
