import makeStyles from "@material-ui/core/styles/makeStyles"
import { Theme } from "@material-ui/core"

export default makeStyles((theme: Theme) => ({
  root: {
    border: 0,
    padding: 0,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  legend: {
    width: "100%",
    paddingBottom: theme.spacing(1),
  },
  options: {
    display: `flex`,
    flexWrap: `wrap`,
    marginLeft: `-${theme.spacing(1)}px`,
    marginRight: `-${theme.spacing(1)}px`,
    "& > *": {
      minWidth: theme.spacing(16),
      margin: theme.spacing(1),
    },
  },
}))
