import { InvoiceState, makeInvoiceSate } from "../models/invoiceModel"
import { ActionTypes, AnyAction } from "../types/actionTypes"
import { RequestStatus } from "../types/statusTypes"

const initialState: InvoiceState = makeInvoiceSate()

export default function invoiceReducer(
  state = initialState,
  action: AnyAction | null = null
): InvoiceState {
  switch (action?.type) {
    // logout
    case ActionTypes.LOGOUT_USER_SUCCEEDED:
      return initialState

    case ActionTypes.FETCH_INVOICES_REQUESTED: {
      return state.set(
        "statuses",
        state.statuses.set("fetchInvoices", RequestStatus.REQUESTED)
      )
    }

    case ActionTypes.FETCH_INVOICES_SUCCEEDED: {
      return state
        .set(
          "statuses",
          state.statuses.set("fetchInvoices", RequestStatus.SUCCEEDED)
        )
        .set("records", action.payload.invoices)
    }

    case ActionTypes.FETCH_INVOICES_FAILED: {
      return state
        .set(
          "statuses",
          state.statuses.set("fetchInvoices", RequestStatus.FAILED)
        )
        .set("errors", state.errors.set("fetchInvoice", action.payload.error))
    }

    default:
      return state
  }
}
