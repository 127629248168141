import { useEffect } from "react"
import { deleteNews, fetchNews, saveNews } from "actions/newsActions"
import { NewsMap } from "models/newsModel"
import { useDispatch, useSelector } from "react-redux"
import {
  selectNewsDeleteError,
  selectNewsDeleteStatus,
  selectNewsFetchError,
  selectNewsFetchStatus,
  selectNewsRecords,
  selectNewsSaveError,
  selectNewsSaveStatus,
} from "selectors/newsSelector"
import { SiteReqAdmin } from "types/apiTypes"
import { RequestStatus } from "types/statusTypes"

export function useNewsSave(): [
  (data: SiteReqAdmin, id?: string) => void,
  RequestStatus,
  string
] {
  const dispatch = useDispatch()
  const status = useSelector(selectNewsSaveStatus)
  const error = useSelector(selectNewsSaveError)
  return [
    (data: SiteReqAdmin, id?: string) =>
      dispatch(saveNews(data, id ? parseFloat(id) : undefined)),
    status,
    error,
  ]
}

export function useNewsDelete(): [(id: number) => void, RequestStatus, string] {
  const dispatch = useDispatch()
  const status = useSelector(selectNewsDeleteStatus)
  const error = useSelector(selectNewsDeleteError)
  return [(id: number) => dispatch(deleteNews(id)), status, error]
}

export function useNewsFetch(id?: string): [NewsMap, RequestStatus, string] {
  const dispatch = useDispatch()
  const news = useSelector(selectNewsRecords)
  const status = useSelector(selectNewsFetchStatus)
  const error = useSelector(selectNewsFetchError)
  const singleNews = id ? news.get(id) : null
  useEffect(() => {
    if (!singleNews) dispatch(fetchNews(id ? [parseFloat(id)] : undefined))
  }, [dispatch, id, singleNews])
  return [news, status, error]
}
