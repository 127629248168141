import React, { ChangeEvent, ReactNode } from "react"
import MUISelect, { SelectProps } from "@material-ui/core/Select"
import ListSubheader from "@material-ui/core/ListSubheader"
import MenuItem from "@material-ui/core/MenuItem"
import { FormHelperText, FormControl, OutlinedInput } from "@material-ui/core"
import InputLabel from "@material-ui/core/InputLabel"
import { InputError, InputOption, LiteEvent } from "../../types/globalTypes"
import { createLiteEvent } from "../../utils/typeUtils"
import FieldMessage, { Field } from "../Messages/FieldMessage"
import ErrorMessage from "../Messages/ErrorMessage"
import useSelectStyles from "./SelectStyle"

interface ISelectProps {
  messageId?: Field
  label?: ReactNode
  name: string
  inputError?: InputError
  hasEmptyOption?: boolean
  options: InputOption[]
  onChange?: (e: LiteEvent) => void
  dense?: boolean
  size?: "small" | "medium"
  optGroups?: {
    [index: number]: ReactNode
  }
}

export default function Select({
  name,
  options,
  inputError,
  messageId,
  hasEmptyOption = false,
  onChange,
  dense,
  size,
  label,
  optGroups,
  ...props
}: ISelectProps & Omit<SelectProps, "onChange">) {
  const classes = useSelectStyles()
  const handleChange = (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    if (onChange && event.target.value) {
      const value = event.target.value as string
      onChange(createLiteEvent(name, value))
    }
  }

  const inputLabel = messageId ? <FieldMessage id={messageId} /> : label

  return (
    <FormControl
      className={classes.root}
      error={!!inputError}
      fullWidth
      margin={dense ? "none" : "normal"}
      variant={"outlined"}
      size={size}
    >
      {(messageId || label) && (
        <InputLabel htmlFor={name} style={{ fontSize: `inherit` }} shrink>
          {inputLabel}
        </InputLabel>
      )}
      <MUISelect
        {...props}
        inputProps={{ size: "small" }}
        id={name}
        name={name}
        onChange={handleChange}
        margin={dense ? "dense" : undefined}
        input={
          <OutlinedInput notched name={name} id={name} label={inputLabel} />
        }
      >
        {hasEmptyOption && (
          <MenuItem value="undefined" style={{ minHeight: "36px" }} />
        )}
        {options.map(
          ({ content, value, disabled, promoted, demoted }, index) => [
            <MenuItem
              key={value}
              value={value}
              disabled={disabled}
              className={
                promoted
                  ? classes.promoted
                  : demoted
                  ? classes.demoted
                  : undefined
              }
            >
              {content}
            </MenuItem>,
            optGroups?.[index] && (
              <ListSubheader key={`${value}-heading`}>
                {optGroups[index]}
              </ListSubheader>
            ),
          ]
        )}
      </MUISelect>
      {inputError && (
        <FormHelperText>
          {inputError.message || <ErrorMessage id={inputError.type} />}
        </FormHelperText>
      )}
    </FormControl>
  )
}
