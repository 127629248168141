import { createSelector } from "reselect"
import { AppState } from "../store"
import { InputOption } from "../types/globalTypes"
import config from "../config"
import { selectLoggedUser } from "./interfaceSelector"
import { selectUserRecords } from "./userSelector"
import { UserRes } from "../types/apiTypes"
import { AgencyMap } from "../models/agencyModel"

export function selectAgencyFetchStatus(state: AppState) {
  return state.agency.statuses.fetchAgencies
}

export function selectAgencyFetchError(state: AppState) {
  return state.agency.errors.fetchAgencies
}

export function selectAgencyRecords(state: AppState): AgencyMap {
  return state.agency.records
}

export const selectAgencyMap = createSelector(selectAgencyRecords, (agencies) =>
  agencies.valueSeq()
)

export const selectAgencyList = createSelector(selectAgencyRecords, (map) =>
  Object.values(map.toJS())
)

export const selectAgencyById = createSelector(
  selectAgencyRecords,
  (state: AppState, id?: string) => id,
  (agencies, id) => (id ? agencies.get(id) : null)
)

export const selectAgencyInputOptions = createSelector(
  selectAgencyRecords,
  selectLoggedUser,
  (agencies, user): InputOption[] => {
    const webOption = { value: config.agencyIds.web.toString(), content: "Web" }
    const agencyOptions = agencies
      .toArray()
      .map(([id, agency]) => ({
        value: id,
        content: agency.title,
      }))
      .sort((a, b) => a.content.localeCompare(b.content))
    return user?.agencyId ? agencyOptions : [webOption, ...agencyOptions]
  }
)

export const selectAgencyUserDataGrid = createSelector(
  [selectUserRecords, (state: unknown, agencyId: number) => agencyId],
  (users, agencyId): UserRes[] =>
    users
      .valueSeq()
      .toArray()
      .map((user) => ({ ...user }))
      .filter((user) => user.agencyId === agencyId)
)
