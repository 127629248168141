import React, { ChangeEvent, ReactNode } from "react"
import FieldMessage, { Field } from "../Messages/FieldMessage"
import useStyles from "./CheckButtonStyles"

interface ICheckButtonProps {
  name: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  messageId?: Field
  label?: ReactNode
  checked?: boolean
  disabled?: boolean
  value?: string
}

export default function CheckButton({
  onChange,
  messageId,
  name,
  label,
  checked = false,
  disabled = false,
  value,
}: ICheckButtonProps) {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <input
        type={"checkbox"}
        onChange={onChange}
        name={name}
        id={name}
        checked={checked}
        disabled={disabled}
        value={value}
      />
      <label htmlFor={name}>
        {label || (messageId && <FieldMessage id={messageId} />)}
      </label>
    </div>
  )
}
