import React from "react"
import { FormattedMessage } from "react-intl"

interface IActionMessageProps {
  id: "fetch" | "save" | "delete" | "sendEmail"
}

export default function ActionMessage({ id }: IActionMessageProps) {
  switch (id) {
    case "delete":
      return <FormattedMessage id={`delete`} defaultMessage={`Delete`} />
    case "save":
      return <FormattedMessage id={`save`} defaultMessage={`Save`} />
    case "fetch":
      return <FormattedMessage id={`fetch`} defaultMessage={`Load`} />
    case "sendEmail":
      return (
        <FormattedMessage
          id={`sendingEmail`}
          defaultMessage={`Sending email`}
        />
      )
    default:
      return null
  }
}
