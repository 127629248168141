import { createSelector } from "reselect"
import { ReservationResAdmin } from "../types/apiTypes"
import { Field } from "../components/Messages/FieldMessage"
import { ReservationDataGrid } from "../types/globalTypes"
import { selectFilters } from "./interfaceSelector"
import { selectOrdersList } from "./orderSelector"
import { orderResToOrderReq } from "utils/typeUtils"

export const selectIsReservationFilterOpen = createSelector(
  selectFilters,
  (filters) => filters[Field.SOURCE].size > 0 || filters[Field.DATE].size > 0
)

export const selectReservationDataGrid = createSelector(
  selectOrdersList,
  (orders): ReservationDataGrid[] =>
    orders
      .filter((order) => !order.packageOrder && order.reservation)
      .map((order): ReservationDataGrid => ({
        ...order,
        reservation: order.reservation as ReservationResAdmin,
        req: {
          ...orderResToOrderReq(order),
          packageId: 0,
          bonSum: order.bonSum
        }
      }))
)
