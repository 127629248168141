import { HttpMethods } from "../types/globalTypes"
import {
  AccountingInfo,
  AgencyResAdmin,
  Bon,
  BonCategory,
  BonResponsibility,
  CareerApplicationResAdmin,
  CareerPosition,
  Currency,
  FormField,
  InvoicePublisher,
  InvoiceResAdmin,
  InvoiceStatus,
  Language,
  NoteType,
  OrderResAdmin,
  OrderStatus,
  PackageOptionPriceResAdmin,
  PackageOptionResAdmin,
  PackageResAdmin,
  PackageUpsaleResAdmin,
  PaymentMethod,
  ReservationResAdmin,
  ResourceBlockingResAdmin,
  ResourceResAdmin,
  SeatingResAdmin,
  SectionResAdmin,
  SessionResAdmin,
  TranslationResAdmin,
  UserRes,
} from "../types/apiTypes"
import { arrayCreate } from "../utils/arrayUtils"
import { blankContactInfo, forbiddenAccessRights } from "../utils/typeUtils"
import { uid } from "../utils/stringUtils"

export const getMockTranslation = (
  language: Language,
  parentId: number,
  entityName: string
): TranslationResAdmin => ({
  additionalInfo: ``,
  content: `${entityName} content ${parentId} (${language})`,
  keyword: null,
  metaData: `${entityName} metaData ${parentId} (${language})`,
  title: `${entityName} title ${parentId} (${language})`,
})

export const getMockTranslations = (
  parentId = Math.ceil(Math.random() * 10) + 1,
  entityName: string = ""
): { [key in Language]: TranslationResAdmin } => ({
  [Language.CS]: getMockTranslation(Language.CS, parentId, entityName),
  [Language.DE]: getMockTranslation(Language.DE, parentId, entityName),
  [Language.EN]: getMockTranslation(Language.EN, parentId, entityName),
  [Language.FR]: getMockTranslation(Language.FR, parentId, entityName),
})

function getRandomBool(defaultTestBool = true) {
  return process.env.NODE_ENV === "test" ? defaultTestBool : Math.random() > 0.5
}

function getRandomNumber(max = 99, min = 1): number {
  return process.env.NODE_ENV === "test"
    ? Math.floor((max - min) / 2)
    : Math.floor(Math.random() * max) + min
}

function getRandomDate(isPast = true): number {
  return process.env.NODE_ENV === "test"
    ? 1584699727693
    : Date.now() -
        getRandomNumber(1000 * 60 * 60 * 24 * 7, 1000 * 60 * 60 * 24) *
          (isPast ? 1 : -1)
}

export const getMockResource = (id: number): ResourceResAdmin => ({
  id,
  maxCapacity: getRandomNumber(20),
  title: `Resource ${id}`,
})

export const getMockPrice = (optionId: number): PackageOptionPriceResAdmin => ({
  agencyId: 1,
  deposit: getRandomNumber(2000),
  feeOver10Persons: getRandomNumber(500),
  packageOptionId: optionId,
  price: getRandomNumber(8000),
  pricePerNCapitas: {
    numberOfPersons: 1,
    price: getRandomNumber(1000),
  },
})

export const getMockUser = (id: number = 999): UserRes => ({
  id,
  email: `josef.${id}@email.com`,
  accountNumber: ``,
  agencyId: null,
  crn: ``,
  fullName: ``,
  note: ``,
  vatId: ``,
  phone: ``,
  address: ``,
  roles: ["SuperAdmin"],
  nickname: "Pepa",
  sendInvoice: true,
  accessRights: forbiddenAccessRights,
})

export const getMockUsers = (ids: number[]): UserRes[] => ids.map(getMockUser)

export const getMockBon = (id: number): Bon => ({
  category: BonCategory.BEER_OR_DRINK_CARD,
  count: 42,
  content: `Bon ${id}`,
  forNPersons: getRandomNumber(500),
  responsibility: BonResponsibility.BAR,
})

export const getMockBons = (length: number): Bon[] =>
  arrayCreate(length, getMockBon)

export const getMockContactInfo = (): { [key in FormField]: string } => ({
  [FormField.PICKUP_DATETIME]: "",
  [FormField.PICKUP_ADDRESS]: "Václavské náměstí 448",
  [FormField.DROPOUT_ADDRESS]: "Bubenská 1",
  [FormField.ACTIVITY_ADDRESS]: "",
  [FormField.COORDINATOR_CONTACT]: "",
  [FormField.DRIVER_CONTACT]: "",
  [FormField.FLIGHT_ARRIVAL_DATETIME]: "",
  [FormField.DRIVE_START_DATETIME]: "",
  [FormField.FLIGHT_NUMBER]: "",
  [FormField.GUIDE_CONTACT]: "",
  [FormField.DROPOUT_DATETIME]: "",
  [FormField.ACTIVITY_START_DATETIME]: "",
  [FormField.RESERVATION_DATETIME]: "",
})

export const getResourceBlocking = (id: number): ResourceBlockingResAdmin => ({
  durationActual: getRandomNumber(120, 60),
  durationAfter: getRandomNumber(20, 10),
  durationBefore: getRandomNumber(30, 20),
  quantityBlocked: getRandomNumber(10),
  quantityRequired: getRandomNumber(10),
  offset: getRandomNumber(10),
  resourceId: id,
})

export const getMockOption = (id: number): PackageOptionResAdmin => ({
  maxCapacity: 8,
  requiredFields: [FormField.DROPOUT_ADDRESS],
  optionalFields: [FormField.DROPOUT_ADDRESS],
  minCapacity: 2,
  bons: getMockBons(3),
  blocking: [1, 2].map(getResourceBlocking),
  gallery: null,
  id,
  packageId: id,
  priority: 5,
  translations: getMockTranslations(id, `option`),
  availableFrom: 60 * 60 * 7,
  availableTo: 60 * 60 * 20,
  cost: getRandomNumber(10000, 2000),
  costPerNCapitas: {
    numberOfPersons: 1,
    price: getRandomNumber(1000, 200),
  },
  isConstrainedByOpeningHours: getRandomBool(),
  cancellationPolicy: [],
  requiredRoles: [],
})

export const getMockOptions = (length: number) =>
  arrayCreate(length, getMockOption)

export const getMockActivity = (id: number = 999): PackageResAdmin => ({
  id,
  requiredUpsaleIds: [],
  containsReservation: true,
  gallery: null,
  isActive: true,
  options: getMockOptions(4),
  priority: 1,
  tagIds: [{ id: 1, priority: 1 }],
  translations: getMockTranslations(id, `activity`),
  upsalePackageIds: [
    { upsaleId: 4, priority: 1 },
    { upsaleId: 5, priority: 2 },
    { upsaleId: 6, priority: 3 },
  ],
  addToReservation: false,
  isForWeb: getRandomBool(),
  type: []
})

export const getMockActivities = (ids: number[]): PackageResAdmin[] =>
  ids.map(getMockActivity)

export const getMockAgency = (id: number): AgencyResAdmin => ({
  accountNumber: `account number`,
  address: `address`,
  crn: `crn`,
  email: `mail`,
  guides: [{ name: `Oleg`, phone: `777 0`, email: "oleg@vysup.cz" }],
  id,
  invoiceDates: [1, 2, 3],
  invoicePublisher: InvoicePublisher.ADDLAND_INCOMING,
  note: `note`,
  paymentMethods: [PaymentMethod.CREDIT_CARD],
  phone: `phone`,
  title: id === 1 ? `Web` : `Agency ${id}`,
  vatId: `vat`,
  vatRate: 0.21,
})

export const getMockUpsale = (id: number): PackageUpsaleResAdmin => ({
  isForWeb: getRandomBool(),
  maxCapacity: 8,
  minCapacity: 2,
  bons: getMockBons(3),
  blocking: [1, 2, 3].map(getResourceBlocking),
  gallery: null,
  id,
  priority: 5,
  translations: getMockTranslations(id, `upsale`),
  availableFrom: 60 * 60 * 7,
  availableTo: 60 * 60 * 20,
  cost: getRandomNumber(1000, 200),
  costPerNCapitas: {
    numberOfPersons: 1,
    price: getRandomNumber(100, 20),
  },
  isConstrainedByOpeningHours: getRandomBool(),
  requiredRoles: [],
  containsReservation: true,
  isActive: true,
  optionalFields: [],
  requiredFields: [],
  tagIds: [],
  type: []
})

export const getMockAgencies = (ids: number[]): AgencyResAdmin[] =>
  ids.map(getMockAgency)

export const getMockSeating = (id: number): SeatingResAdmin => ({
  id,
  maxCapacity: getRandomNumber(20, 10),
  minCapacity: getRandomNumber(1, 10),
  sectionId: id,
  title: `Seating ${id}`,
})

export const getMockSection = (id: number): SectionResAdmin => ({
  bons: getMockBons(5),
  id,
  price: getRandomNumber(10000, 1000),
  translations: getMockTranslations(id, "Section"),
})

export const getMockReservation = (): ReservationResAdmin => ({
  dtStart: getRandomDate(true),
  numberOfPersons: getRandomNumber(20),
  contactInfo: getMockContactInfo(),
  bons: [],
  reservation: [1, 2].map((reservationId) => ({
    section: getMockSection(reservationId),
    seating: getMockSeating(reservationId),
  })),
})

export const getMockOrder = (id: number): OrderResAdmin => ({
  id,
  balanceHolder: null,
  balance: 0,
  bons: [],
  bonSum: [],
  bonsComputed: [],
  currency: Currency.CZK,
  deposit: 0,
  language: Language.EN,
  isLocked: false,
  packageOrder: {
    bons: [],
    contactInfo: getMockContactInfo(),
    numberOfPersons: getRandomNumber(20),
    dtStart: getRandomDate(),
    pack: getMockActivity(1),
    option: getMockOption(3),
    orderEmployees: []
  },
  email: "john@doe.com",
  name: "John Doe",
  phone: "+420 777 777 888",
  price: 8422,
  reservation: getRandomBool() ? getMockReservation() : null,
  agencyId: id,
  cancellation: null,
  cost: 500,
  notes: {
    [NoteType.NOTE_ADMIN]: "note admin",
    [NoteType.NOTE_EMPLOYEE]: "note employee",
    [NoteType.NOTE_OPEN]: "note open",
    [NoteType.NOTE_EXTRA]: "note extra",
    [NoteType.COLOR]: "#00ff00",
  },
  paymentMethod: PaymentMethod.INVOICE,
  paymentMethodDeposit: PaymentMethod.CASH,
  status: OrderStatus.CREATED,
  voucherId: "alfabetagama",
  isPaidDeposit: getRandomBool(),
  isPaidRest: getRandomBool(),
  overrideVatRate: null,
  overrideInvoicePublisher: null,
  voucherUsedAt: getRandomDate(),
  paidByUserId: null,
  upsales: arrayCreate(2, (upsaleId) => ({
    upsaleOption: getMockUpsale(upsaleId + 3),
    numberOfPersons: getRandomNumber(5),
    quantity: getRandomNumber(5),
    dtStart: getRandomDate(),
    contactInfo: blankContactInfo,
    bons: [],
    uuid: uid(),
    orderEmployees: []
  })),
  orderEmployees: []
})

export const getMockOrders = (ids: number[]): OrderResAdmin[] =>
  ids.map(getMockOrder)

export const getMockCareer = (id: number): CareerApplicationResAdmin => ({
  id,
  email: "some email",
  fileUrls: ["some links"],
  message: "some message",
  name: "some name",
  phone: "7777777",
  position: CareerPosition.receptionist,
})

export const getMockCareers = (ids: number[]) => ids.map(getMockCareer)

export const getMockVoucher = () =>
  getRandomBool() ? getMockOrder(1) : getMockReservation()

export const getMockAccountingInfo = (): AccountingInfo => ({
  accountNumber: `12345`,
  crn: `5678`,
  vatRate: null,
  vatId: `91011`,
})

export const getMockInvoice = (id: number): InvoiceResAdmin => ({
  accountingInfo: getMockAccountingInfo(),
  address: `Klimentská 36`,
  agencyId: 1,
  createdAt: getRandomDate(),
  dueDate: getRandomDate(false),
  status: InvoiceStatus.PAID,
  id,
  invoicePublisher: InvoicePublisher.ADDLAND,
  orders: getMockOrders([1, 2]),
  paymentMethod: PaymentMethod.CREDIT_CARD,
  title: `Invoice ${id}`,
})

export const getMockInvoices = (ids: number[]): InvoiceResAdmin[] =>
  ids.map((id) => getMockInvoice(id))

export const getMockSession = (id: number, i: number): SessionResAdmin => ({
  id: 2231 + i,
  dtStart: (i === 0 && 1583020800) || (i % 2 === 0 && 1583452800) || 1584230400,
  dtEnd: (i === 0 && 1583107200) || (i % 2 === 0 && 1583107200) || null,
  orders: getMockOrders([1, 2, 3]),
  userId: 1,
})

export const getMockSessions = (ids: number[]): SessionResAdmin[] =>
  ids.map((id, i) => getMockSession(id, i))

export const startNewSession = () => ({
  id: 2231,
  dtStart: 1584230400,
  dtEnd: null,
  orders: getMockOrders([1, 2]),
  reservations: [],
  userId: 1,
})

const endSession = () => ({ status: "ok" })

const redeemVoucher = () => ({ status: "ok" })

export default function getMockResponse(
  endpoint: string,
  method: HttpMethods,
  body?: object
) {
  // if no ids are present in the endpoint, use many numbers as a fallback
  const ids: number[] = (
    endpoint.match(/\d+/g) || ["1", "2", "3", "4", "5", "6"]
  ).map((id) => parseInt(id, 10))

  // delete method returns id of the deleted item
  if (method === HttpMethods.DELETE) return { id: ids[0] }

  // return the same body with random id
  if (method === HttpMethods.POST && body)
    return { ...body, id: getRandomNumber(100) }

  if (method === HttpMethods.PUT && body) return { ...body, id: ids[0] }

  if (endpoint.startsWith(`user/whoami`)) return getMockUser()

  if (endpoint.startsWith(`user`)) return getMockUsers(ids)

  if (endpoint.startsWith(`agency`)) return getMockAgencies(ids)

  if (endpoint.startsWith(`package`)) return getMockActivities(ids)

  if (endpoint.startsWith(`orders`)) return getMockOrders(ids)

  if (endpoint.startsWith(`career`)) return getMockCareers(ids)

  if (endpoint.startsWith(`voucher`)) return getMockVoucher()

  if (endpoint.startsWith(`section`)) return ids.map(getMockSection)

  if (endpoint.startsWith(`seating`)) return ids.map(getMockSeating)

  if (endpoint.startsWith(`price`)) return ids.map(getMockPrice)

  if (endpoint.startsWith("invoice")) return getMockInvoices(ids)

  if (endpoint.startsWith("resource")) return ids.map(getMockResource)

  if (endpoint.startsWith("session")) return getMockSessions(ids)

  if (endpoint.startsWith("startSession")) return startNewSession()

  if (endpoint.startsWith("endSession")) return endSession()

  if (endpoint.startsWith("validVouch/")) return redeemVoucher()

  return null
}
