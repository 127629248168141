import {
  AvailableTimesReq,
  AvailableTimesRes,
  CancellationFeeResAdmin, ConflictIgnoreReq,
  IdRes,
  MinimalOrderReqAdmin,
  OrderConflictReq, OrderConflictRes,
  OrderEmployeeResAdmin,
  OrderEmployeeStatus,
  OrderReqAdmin,
  OrderResAdmin,
  PriceResAdmin,
  SeatingResAdmin,
  SectionReq,
  SectionResAdmin,
  StatusRes
} from "../types/apiTypes"
import performRequest, { createQuery, getIdParam, getIdsParam } from "../utils/apiUtils"
import { HttpMethods } from "../types/globalTypes"

export async function requestGetOrders(
  basicAuth: string,
  ids: Array<string|number> = [],
  filterParams?: {
    sortOrder: "asc" | "desc",
    contactInfo?: string,
    limit?: number,
    offset?: number,
    dtStart?: number,
    dtEnd?: number,
    agencyIds?: Array<number|string>,
    agencyIdsExclude?: Array<number|string>,
    status?: string[],
    paymentMethods?: string[],
    paymentMethodsDeposit?: string[],
    packageIds?: string[]
    orderEmployeeIds?: string[]
    hasReservation?: string
  }
): Promise<OrderResAdmin[]> {
  return performRequest({
    endpoint: `order${getIdsParam(ids) || createQuery(filterParams)}`,
    auth: basicAuth,
    method: HttpMethods.GET,
  })
}

export async function requestDeleteOrder(
  basicAuth: string,
  id: number
): Promise<StatusRes> {
  return performRequest({
    endpoint: `order${getIdParam(id)}`,
    auth: basicAuth,
    method: HttpMethods.DELETE,
  })
}

export async function requestSaveOrder(
  basicAuth: string,
  order: OrderReqAdmin,
  id?: number
): Promise<IdRes<number>> {
  return performRequest({
    endpoint: `order${getIdParam(id)}`,
    auth: basicAuth,
    method: id ? HttpMethods.PUT : HttpMethods.POST,
    body: order,
  })
}

export async function requestGetSections(
  basicAuth: string,
  ids: number[] = []
): Promise<SectionResAdmin[]> {
  return performRequest({
    endpoint: `section${getIdsParam(ids)}`,
    auth: basicAuth,
    method: HttpMethods.GET,
  })
}
export async function requestSaveSections(
  basicAuth: string,
  body: SectionReq[]
): Promise<SectionResAdmin[]> {
  return performRequest({
    endpoint: `section/batch`,
    auth: basicAuth,
    method: HttpMethods.POST,
    body,
  })
}

export async function requestGetSeating(
  basicAuth: string,
  ids: number[] = []
): Promise<SeatingResAdmin[]> {
  return performRequest({
    endpoint: `seating${getIdsParam(ids)}`,
    auth: basicAuth,
    method: HttpMethods.GET,
  })
}

export async function requestGetVoucher(
  basicAuth: string,
  code: string
): Promise<OrderResAdmin> {
  return performRequest({
    endpoint: `voucher/${code}`,
    auth: basicAuth,
    method: HttpMethods.GET,
  })
}

export async function requestRedeemVoucher(
  basicAuth: string,
  sessionId: number,
  voucherId: string
): Promise<StatusRes> {
  return performRequest({
    endpoint: `session/${sessionId}/add/${voucherId}`,
    auth: basicAuth,
    method: HttpMethods.POST,
  })
}

export async function requestPostVoucher(
  basicAuth: string,
  code: string
): Promise<OrderResAdmin[]> {
  return performRequest({
    endpoint: `voucher/${code}`,
    auth: basicAuth,
    method: HttpMethods.POST,
  })
}

export async function requestGetUnavailableSeating(
  basicAuth: string,
  dtStart: number,
): Promise<number[]> {
  return performRequest({
    endpoint: `unavailableSeating?dtStart=${dtStart}`,
    method: HttpMethods.POST,
    auth: basicAuth,
  })
}

export async function requestGetOrderPrice(
  basicAuth: string,
  body: MinimalOrderReqAdmin
): Promise<PriceResAdmin> {
  return performRequest({
    endpoint: `price`,
    auth: basicAuth,
    method: HttpMethods.POST,
    body,
  })
}

export async function requestGetOrderAvailableTimes(
  body: AvailableTimesReq
): Promise<AvailableTimesRes> {
  return performRequest({
    endpoint: `order/availability`,
    method: HttpMethods.POST,
    body,
  })
}

export async function requestGetOrderTimes(
  auth: string,
  dtStart: number
): Promise<number[]> {
  return performRequest({
    endpoint: `order/times?dtStart=${dtStart}`,
    auth,
    method: HttpMethods.GET,
  })
}

export async function requestGetOrderEmployees(
  auth: string
): Promise<{ [orderId: string]: OrderEmployeeResAdmin[] }> {
  return performRequest({
    endpoint: `orderEmployees/batch`,
    method: HttpMethods.GET,
    auth,
  })
}

export async function requestSaveEmployeeStatus(
  auth: string,
  id: number,
  status: OrderEmployeeStatus
): Promise<StatusRes> {
  return performRequest({
    endpoint: `orderEmployees/status/${id}?status=${status}`,
    method: HttpMethods.GET,
    auth,
  })
}

export async function requestGetEmployeeConflicts(
  auth: string,
  dtStart: number,
  userIds?: number[],
  orderId?: number
): Promise<OrderEmployeeResAdmin[]> {
  return performRequest<OrderEmployeeResAdmin[]>({
    endpoint: `orderEmployees/duplicates?dtStart=${dtStart}${
      userIds ? `&userIds=${userIds.join(`,`)}` : ``
    }`,
    method: HttpMethods.GET,
    auth,
  }).then((conflicts) =>
    conflicts.filter((conflict) => conflict.orderId !== orderId)
  )
}

export async function requestGetOrderConflicts(
  auth: string,
  body: OrderConflictReq
): Promise<number[]> {
  return performRequest({
    endpoint: `order/availability`,
    method: HttpMethods.POST,
    auth,
    body,
  })
}

export async function requestGetConflictsForAllOrders(
  auth: string
): Promise<OrderConflictRes> {
  return performRequest({
    endpoint: `conflict`,
    method: HttpMethods.GET,
    auth,
  })
}

export async function requestSaveEmployeeConflictIgnore(
  auth: string,
  body: ConflictIgnoreReq
): Promise<OrderConflictRes> {
  return performRequest({
    endpoint: `conflictIgnore/employee`,
    method: HttpMethods.POST,
    auth,
    body
  })
}

export async function requestSaveResourceConflictIgnore(
  auth: string,
  body: ConflictIgnoreReq
): Promise<OrderConflictRes> {
  return performRequest({
    endpoint: `conflictIgnore/resource`,
    method: HttpMethods.POST,
    auth,
    body
  })
}

export async function requestGetCancellationFee(
  auth: string,
  orderId: number,
  cancelledAt: number
): Promise<CancellationFeeResAdmin> {
  return performRequest({
    endpoint: `order/cancellationFee?orderId=${orderId}&cancelledAt=${cancelledAt}`,
    method: HttpMethods.GET,
    auth,
  })
}

export async function requestSendVoucherEmail(
  auth: string,
  id: number
): Promise<string> {
  return performRequest({
    endpoint: `order/resend/voucher/${id}`,
    method: HttpMethods.POST,
    auth,
  })
}
