import { makeStyles, Theme } from "@material-ui/core"

export default makeStyles((theme: Theme) => ({
  root: {
    background: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
  expandIcon: {
    color: theme.palette.error.contrastText,
  },
  title: {
    fontWeight: "bold",
    fontSize: theme.typography.fontSize,
    marginTop: 0,
    marginBottom: 8,
  },
  button: {
    minWidth: 120,
  },
  conflicts: {
    listStyle: "none",
    margin: 0,
    padding: 0
  }
}))
