import { isEmpty, reject } from "ramda"

/**
 * Returns given list without _empty_ values or given object without properties
 * having _empty_ values.
 *
 * Definition of _empty_ is non-nil value having zero length, so the condition
 * is met only for empty string, list or object.
 */
export const rejectEmpty = reject(isEmpty)
