import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { periodInputOptions, sourceInputOptions } from "../../utils/typeUtils"
import Filter, { FilterTypes } from "../Filter/Filter"
import { Field } from "../Messages/FieldMessage"
import { selectFilterMap } from "../../selectors/interfaceSelector"
import { setFilter } from "../../actions/interfaceActions"
import { getPeriodByDates } from "../../utils/timeUtils"

export default function ReservationFilter() {
  const dispatch = useDispatch()
  const filters = useSelector(selectFilterMap)

  const handleChange = (key: string, value: string[]) => {
    dispatch(setFilter(key, value))
  }

  return (
    <Filter
      fields={[
        {
          name: Field.SOURCE,
          options: sourceInputOptions,
          type: FilterTypes.RadioList,
          values: filters.get(Field.SOURCE)?.toArray() || [],
        },
        {
          name: Field.PERIOD,
          options: periodInputOptions,
          type: FilterTypes.RadioList,
          values: [getPeriodByDates(filters.get(Field.DATE))],
        },
        {
          name: Field.DATE,
          options: [],
          type: FilterTypes.DateRange,
          values: filters.get(Field.DATE)?.toArray() || [],
        },
      ]}
      onChange={handleChange}
    />
  )
}
