import "react-redux"
import { combineReducers, createStore, applyMiddleware, compose } from "redux"
import createSagaMiddleware from "redux-saga"
import user from "./reducers/userReducer"
import show from "./reducers/showReducer"
import ui from "./reducers/interfaceReducer"
import agency from "./reducers/agencyReducer"
import activity from "./reducers/activityReducer"
import order from "./reducers/orderReducer"
import product from "./reducers/productReducer"
import career from "./reducers/careerReducer"
import invoice from "./reducers/invoiceReducer"
import session from "./reducers/sessionReducer"
import upsale from "./reducers/upsaleReducer"
import news from "./reducers/newsReducer"
import rootSaga from "./sagas/rootSaga"

const rootReducer = combineReducers({
  user,
  ui,
  activity,
  agency,
  order,
  product,
  career,
  invoice,
  session,
  upsale,
  news,
  show,
})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const sagaMiddleware = createSagaMiddleware()

export type AppState = ReturnType<typeof rootReducer>

declare module "react-redux" {
  export interface DefaultRootState extends AppState {}
}

export default createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
)

sagaMiddleware.run(rootSaga)
