import { NewsActions, NewsMap } from "models/newsModel"
import { ActionTypes } from "types/actionTypes"
import { SiteReqAdmin, SiteResAdmin } from "types/apiTypes"

export const fetchNews = (ids?: number[]) =>
  ({
    type: ActionTypes.FETCH_NEWS_REQUESTED,
    payload: {
      ids,
    },
  } as const)

export const fetchNewsSuccess = (news: NewsMap) =>
  ({
    type: ActionTypes.FETCH_NEWS_SUCCEEDED,
    payload: {
      news,
    },
  } as const)

export const fetchNewsFailed = (message: string) =>
  ({
    type: ActionTypes.FETCH_NEWS_FAILED,
    payload: {
      message,
    },
  } as const)

export const saveNews = (data: SiteReqAdmin, id?: number) =>
  ({
    type: ActionTypes.SAVE_NEWS_REQUESTED,
    payload: {
      data,
      id,
    },
  } as const)

export const saveNewsSucceeded = (news: SiteResAdmin) =>
  ({
    type: ActionTypes.SAVE_NEWS_SUCCEEDED,
    payload: {
      news,
    },
  } as const)

export const saveNewsFailed = (message: string) =>
  ({
    type: ActionTypes.SAVE_NEWS_FAILED,
    payload: {
      message,
    },
  } as const)

export const deleteNews = (id: number) =>
  ({
    type: ActionTypes.DELETE_NEWS_REQUESTED,
    payload: {
      id,
    },
  } as const)

export const deleteNewsSucceeded = (id: number) =>
  ({
    type: ActionTypes.DELETE_NEWS_SUCCEEDED,
    payload: {
      id,
    },
  } as const)

export const deleteNewsFailed = (message: string) =>
  ({
    type: ActionTypes.DELETE_NEWS_FAILED,
    payload: {
      message,
    },
  } as const)

export const resetNewsStatus = (key: NewsActions) =>
  ({
    type: ActionTypes.RESET_NEWS_STATUS,
    payload: {
      key,
    },
  } as const)
