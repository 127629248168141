import React, { FC } from "react"
import { FormattedMessage } from "react-intl"
import {
  Button,
  Avatar,
  Card,
  CardHeader,
  CardContent,
  Typography,
  CardActions,
  Box,
} from "@material-ui/core"
import config from "../../config"

interface IApplicantCardProps {
  name?: string
  message?: string
  phone?: string
  email?: string
  position?: string
  fileUrls: string[]
}

const ApplicantCard: FC<IApplicantCardProps> = ({
  fileUrls,
  name,
  message,
  phone,
  email,
  position,
}) => (
  <Box maxWidth={320}>
    <Card variant={"outlined"}>
      <CardHeader
        avatar={<Avatar />}
        title={name}
        subheader={position?.toUpperCase()}
      />
      <CardContent>
        <Typography component={"p"} variant={"body2"}>
          {email}
        </Typography>
        <Typography component={"p"} variant={"body2"}>
          {phone}
        </Typography>
        <p>{message}</p>
        {fileUrls.length > 0 && (
          <ul>
            {fileUrls.map((url, idx) => (
              <li key={url}>
                <a href={`${config.storageEndpoint}/${url}`}>File {idx + 1}</a>
              </li>
            ))}
          </ul>
        )}
      </CardContent>
      <CardActions>
        <Button href={`mailto:${email}`}>
          <FormattedMessage id={"sendEmail"} defaultMessage={"Send email"} />
        </Button>
        <Button href={`tel:${phone}`}>
          <FormattedMessage id={"callNumber"} defaultMessage={"Call"} />
        </Button>
      </CardActions>
    </Card>
  </Box>
)
export default ApplicantCard
