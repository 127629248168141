import React, { Fragment, useState } from "react"
import { useSelector } from "react-redux"
import Box from "@material-ui/core/Box"
import UpsaleSummary, { UpsaleFields } from "components/UpsaleSummary/UpsaleSummary"
import { FormattedMessage } from "react-intl"
import CellGroup from "../../components/CellGroup/CellGroup"
import IconCell from "../../components/IconCell/IconCell"
import { FormField, OrderResAdmin, OrderStatus, PaymentMethod } from "../../types/apiTypes"
import { OrderDataGrid, ReportDataGrid } from "../../types/globalTypes"
import Price from "../../components/Price/Price"
import SourceMessage from "../../components/Messages/SourceMessage"
import { Source } from "../../types/filterTypes"
import PaymentMethodMessage from "../../components/Messages/PaymentMethodMessage"
import OrderStatusMessage from "../../components/Messages/OrderStatusMessage"
import FieldMessage, { Field } from "../../components/Messages/FieldMessage"
import { getReservationContents } from "../../utils/apiUtils"
import { selectUserRecords } from "../../selectors/userSelector"
import { useGetLoggedUser, useIsCompetent, useLanguage } from "../../hooks/interfaceHooks"
import { useRequiredFirstFormFields } from "../../hooks/activityHooks"
import { selectActivityRecords, selectOptionMap } from "../../selectors/activitySelector"
import EmployeeCell from "../../components/Cells/EmployeeCell"
import Can from "../../components/Can/Can"
import { formatContactInfoValue, formatDateTime } from "../../utils/timeUtils"
import { addressFormFields, blankContactInfo, groupFormFields, timeFormFields } from "../../utils/typeUtils"
import OrderBons from "../../components/OrderBons/OrderBons"
import PersonsMessage from "../../components/Messages/PersonsMessage"
import OrderBalanceConfirmDialog from "./OrderBalanceConfirmDialog"
import { useOrderSave } from "../../hooks/orderHooks"
import { RequestStatus } from "../../types/statusTypes"
import StatusButton from "../../components/StatusButton/StatusButton"

interface PackageOrderProps {
  packageOrder: NonNullable<OrderResAdmin["packageOrder"]>
}

function ActivityTitle({ packageOrder: { option } }: PackageOrderProps) {
  const lang = useLanguage()
  const activityMap = useSelector(selectActivityRecords)
  const activity = activityMap.get(option.packageId.toString())

  return (
    <>
      <div>{activity?.translations[lang]?.title}</div>
      <div>{option.translations[lang].title}</div>
    </>
  )
}

function OptionContent({ packageOrder }: PackageOrderProps) {
  const lang = useLanguage()
  const optionMap = useSelector(selectOptionMap)
  const option = optionMap.get(packageOrder.option.id.toString())

  return (
    <>
      {option?.translations[lang]?.content.split("\n").map((feat) => (
        <p key={`key-${feat}`}>{feat}</p>
      ))}
    </>
  )
}

export function OrderActivityCell({ packageOrder, status }: OrderResAdmin) {
  if (!packageOrder) return null

  return (
    <IconCell
      tooltip={<OptionContent packageOrder={packageOrder} />}
      element={status === OrderStatus.CREATED ? "strong" : "span"}
    >
      <ActivityTitle packageOrder={packageOrder} />
    </IconCell>
  )
}

export function OrderGroupCell({
  name,
  email,
  phone,
  packageOrder,
}: OrderDataGrid | OrderResAdmin) {
  const [sortedFields] = useRequiredFirstFormFields(
    groupFormFields,
    packageOrder?.option.id
  )
  return (
    <CellGroup>
      <div>
        <FieldMessage id={Field.NAME} />: {name}
      </div>
      <div>
        <FieldMessage id={Field.EMAIL} />: {email}
      </div>
      <div>
        <FieldMessage id={Field.PHONE} />: {phone}
      </div>
      {sortedFields.map(
        (field) =>
          packageOrder && (
            <div key={field}>
              <FieldMessage id={field} />: {packageOrder.contactInfo[field]}
            </div>
          )
      )}
    </CellGroup>
  )
}

function OrderDateTime({
  field,
  dateString,
}: {
  field?: FormField
  dateString: string
}) {
  if (!dateString) return null
  const [date, time] = dateString.split(" ")
  return (
    <span>
      {field && (
        <>
          <FieldMessage id={field} />
          <br />
        </>
      )}
      <FieldMessage id={Field.DATE} />: {date}
      <br />
      <FieldMessage id={Field.TIME} />: {time}
    </span>
  )
}

export function OrderDate({
  packageOrder,
  reservation,
}: Pick<OrderResAdmin, "packageOrder" | "reservation">) {
  const [sortedFields] = useRequiredFirstFormFields(
    timeFormFields,
    packageOrder?.option?.id
  )
  const dtStart = packageOrder?.dtStart || reservation?.dtStart
  const contactInfo =
    packageOrder?.contactInfo || reservation?.contactInfo || blankContactInfo

  return (
    <>
      <OrderDateTime dateString={formatDateTime(dtStart)} />
      {sortedFields.map((field) => (
        <OrderDateTime
          field={field}
          key={field}
          dateString={formatContactInfoValue(field, contactInfo[field])}
        />
      ))}
    </>
  )
}

export function OrderDateCell({ packageOrder, reservation }: OrderResAdmin) {
  return (
    <CellGroup>
      <OrderDate packageOrder={packageOrder} reservation={reservation} />
    </CellGroup>
  )
}

export function OrderAddress({ packageOrder }: PackageOrderProps) {
  const [sortedFields] = useRequiredFirstFormFields(
    addressFormFields,
    packageOrder.option.id
  )

  const nonEmptyFields = sortedFields.filter(
    (field) => packageOrder.contactInfo[field]
  )

  return (
    <>
      {nonEmptyFields.map((field) => (
        <span key={field}>
          <FieldMessage id={field} />: {packageOrder.contactInfo[field]}
        </span>
      ))}
    </>
  )
}

export function OrderAddressCell({ packageOrder }: OrderResAdmin) {
  if (!packageOrder) return null

  return (
    <CellGroup>
      <OrderAddress packageOrder={packageOrder} />
    </CellGroup>
  )
}

export function OrderNoteCell({
  notes: { NOTE_ADMIN, NOTE_EMPLOYEE, NOTE_OPEN, NOTE_EXTRA },
}: OrderDataGrid) {
  return (
    <CellGroup>
      {NOTE_ADMIN && (
        <Can resource={"orderAdmin"} operations={["R", "W"]}>
          <span>
            <FieldMessage id={Field.NOTE_ADMIN} />: {NOTE_ADMIN}
          </span>
        </Can>
      )}
      {NOTE_EMPLOYEE && (
        <Can resource={"orderEmployee"} operations={["R", "W"]}>
          <span>
            <FieldMessage id={Field.NOTE_EMPLOYEE} />: {NOTE_EMPLOYEE}
          </span>
        </Can>
      )}
      {NOTE_OPEN && (
        <span>
          <FieldMessage id={Field.NOTE_AGENCY} />: {NOTE_OPEN}
        </span>
      )}
      {NOTE_EXTRA && (
        <span>
          <FieldMessage id={Field.SPECIAL_REQUEST} />: {NOTE_EXTRA}
        </span>
      )}
    </CellGroup>
  )
}

export function OrderExtrasCell({ reservation, req }: OrderDataGrid) {
  return (
    <CellGroup>
      {reservation && (
        <div>
          <FormattedMessage
            id={`reservations`}
            defaultMessage={`Reservations`}
          />
          :<br />
          {formatDateTime(reservation.dtStart)}
          <br />
          <PersonsMessage count={reservation.numberOfPersons} />
          <br />
          {getReservationContents(reservation.reservation)}
        </div>
      )}
      {req && <OrderBons order={req} />}
    </CellGroup>
  )
}

export function OrderUpsalesCell({ upsales }: OrderDataGrid | OrderResAdmin) {
  const language = useLanguage()
  return upsales.length > 0 ? (
    <UpsaleSummary
      summary={upsales.map(({ upsaleOption, quantity, contactInfo }) => [
        upsaleOption.translations[language].title,
        quantity,
        contactInfo,
      ])}
    />
  ) : null
}

export function OrderMethodCell({
  paymentMethod,
  paymentMethodDeposit,
  isPaidRest,
  isPaidDeposit,
}: OrderDataGrid) {
  return (
    <CellGroup>
      {isPaidRest ? (
        <FormattedMessage
          id={"orderPaid"}
          defaultMessage={"The order is paid"}
        />
      ) : (
        <FormattedMessage
          id={"orderUnpaid"}
          defaultMessage={"The order is not paid"}
        />
      )}
      <span>
        (<PaymentMethodMessage id={paymentMethod} />)
      </span>
      {isPaidDeposit ? (
        <FormattedMessage
          id={"depositPaid"}
          defaultMessage={"The deposit is paid"}
        />
      ) : (
        <FormattedMessage
          id={"depositUnpaid"}
          defaultMessage={"The deposit is not paid"}
        />
      )}
      <span>
        (<PaymentMethodMessage id={paymentMethodDeposit} />)
      </span>
    </CellGroup>
  )
}

export function OrderStatusCell({ status, cancellation }: OrderDataGrid) {
  return (
    <CellGroup>
      <OrderStatusMessage id={status} />
      {status === OrderStatus.CANCELLED ? (
        <Price price={cancellation?.cancellationFee} />
      ) : (
        <span />
      )}
    </CellGroup>
  )
}

export function OrderCostsCell(order: OrderDataGrid | ReportDataGrid) {
  const user = useGetLoggedUser()
  const canSeePrice = useIsCompetent("orderPrice", "R", "W")
  const canSeeCosts = useIsCompetent("orderCosts", "R", "W")
  const [isOpen, setIsOpen] = useState(false)
  const [saveOrder, saveStatus] = useOrderSave()
  const isDataGrid = "req" in order
  const isCashPayment = order.paymentMethodDeposit === PaymentMethod.CASH
  const holder = order.balanceHolder
  const isHolderManager = holder?.roles.includes("Manager")
  const isHolderLogged = holder?.id === user?.id

  const handleBalanceConfirm = async () => {
    if (isDataGrid && order.req) {
      const {
        req: { bonSum, packageId, ...orderReq },
      } = order
      setIsOpen(false)
      await saveOrder(
        {
          ...orderReq,
          balanceHolderId: user?.id || null,
        },
        orderReq.id
      )
    }
  }

  return (
    <CellGroup>
      {canSeePrice && (
        <Box display={"flex"} justifyContent={"space-between"}>
          <FieldMessage id={Field.PRICE} />
          {": "}
          <Price price={order.price} />
        </Box>
      )}
      {canSeeCosts && (
        <Box display={"flex"} justifyContent={"space-between"}>
          <FieldMessage id={Field.COSTS} />
          {": "}
          <Price price={order.cost} />
        </Box>
      )}
      {canSeePrice && (
        <Box display={"flex"} justifyContent={"space-between"}>
          <FieldMessage id={Field.DEPOSIT} />
          {": "}
          <Price price={order.deposit} />
        </Box>
      )}
      {canSeePrice && isCashPayment && (
        <Box display={"flex"} justifyContent={"space-between"}>
          <FieldMessage id={Field.BALANCE} />
          {": "}
          <Price price={order.balance} />
        </Box>
      )}
      {isDataGrid && isCashPayment && (
        <>
          <StatusButton
            onClick={() => setIsOpen(true)}
            isLoading={saveStatus === RequestStatus.REQUESTED}
            variant={isHolderLogged ? "contained" : "outlined"}
            status={
              isHolderManager
                ? "success"
                : holder === null
                ? "danger"
                : "warning"
            }
            disabled={isHolderManager || isHolderLogged}
          >
            {holder?.fullName || (
              <FormattedMessage id="customer" defaultMessage={"Customer"} />
            )}
          </StatusButton>
          <OrderBalanceConfirmDialog
            open={isOpen}
            onConfirm={handleBalanceConfirm}
            onClose={() => setIsOpen(false)}
          />
        </>
      )}
    </CellGroup>
  )
}

export function OrderSourceCell({ agencyName }: OrderDataGrid | ReportDataGrid) {
  return <>{agencyName || <SourceMessage id={Source.WEB} />}</>
}

export function OrderEmployeeCell({
  allEmployees,
  packageOrder,
  reservation,
  upsales,
}: OrderDataGrid) {
  const language = useLanguage()
  const userMap = useSelector(selectUserRecords)
  const canSeePrice = useIsCompetent("orderPrice", "R", "W")
  return (
    <Can resource={"orderEmployee"} operations={["R", "W"]}>
      <CellGroup>
        {allEmployees.map(
          ({
            userId,
            status,
            id,
            note,
            role,
            reward,
            packageOptionId,
            upsaleId,
          }) => (
            <Box key={id} display={"flex"} flexDirection={"column"}>
              <EmployeeCell
                name={userMap.get(userId?.toString() || "")?.nickname || ""}
                status={status}
                role={role}
              />
              {canSeePrice && <Price price={reward} />}
              <div>{note}</div>

              {packageOptionId && packageOrder && (
                <>
                  <ActivityTitle packageOrder={packageOrder} />
                  <OrderDate
                    packageOrder={packageOrder}
                    reservation={reservation}
                  />
                  <OrderAddress packageOrder={packageOrder} />
                </>
              )}

              {upsaleId &&
                upsales
                  .filter((up) => up.upsaleOption.id === upsaleId)
                  .map(({ contactInfo, upsaleOption, uuid }) => (
                    <Fragment key={uuid}>
                      <div>{upsaleOption.translations[language].title}</div>
                      <UpsaleFields fields={contactInfo} />
                    </Fragment>
                  ))}
            </Box>
          )
        )}
      </CellGroup>
    </Can>
  )
}
