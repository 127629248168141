import { intersperse } from "ramda"
import React, { Children, ReactElement, ReactNode } from "react"

export interface SpacerProps {
  children: ReactNode

  separator?: ReactNode
}

/**
 * Displays _renderable*_ children separated with specified {@link ReactNode}
 * (space by default). Useful for rendering various inline lists and/or labels
 * composed of multiple optional parts.
 *
 * Accepts children in any valid shape including single arrays.
 *
 * _* `undefined`, `null` and `boolean` values are ignored_
 *
 * @example
 * const first = "Foo";
 * const second = "Bar";
 * const third = "Baz";
 *
 * <Spacer>
 *   {"Foo"}{"Bar"}{"Baz"}
 * </Spacer>
 * // Foo Bar Baz
 *
 * <Spacer separator=", ">
 *   {["Foo", "Bar", "Baz"]}
 * </Spacer>
 * // Foo, Bar, Baz
 *
 * <Spacer separator=" - ">
 *   {"Foo"}
 *   {null}
 *   {"Baz"}
 * </Spacer>
 * // Foo - Baz
 */
export function Spacer({
  children,
  separator = " ",
}: SpacerProps): ReactElement {
  return <>{intersperse(separator, Children.toArray(children))}</>
}
