import React, { useState } from "react"
import { useSelector } from "react-redux"
import { EditComponentProps } from "@material-table/core"
import { Button } from "@material-ui/core"
import { Add } from "@material-ui/icons"
import { FormattedMessage } from "react-intl"
import {
  ExtendedOrderReqAdmin,
  ReservationDataGrid,
} from "../../types/globalTypes"
import {
  blankContactInfo,
  blankOrderData,
  isNotNull,
} from "../../utils/typeUtils"
import ReservationModal from "../../components/ReservationModal/ReservationModal"
import { getReservationContents } from "../../utils/apiUtils"
import { selectSeating, selectSections } from "../../selectors/orderSelector"
import { ComputedBon } from "../../types/apiTypes"
import { useOrderPriceFetch } from "hooks/orderHooks"
import { noop } from "ramda-adjunct"

export default function ReservationTableEditCell({
  rowData: { req = blankOrderData },
  onChange,
}: EditComponentProps<ReservationDataGrid>) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [fetchPrice] = useOrderPriceFetch()
  const [reservationBons, setReservationBons] = useState<ComputedBon[]>([])
  const seating = useSelector(selectSeating)
  const sections = useSelector(selectSections)

  const handleChangeRow = (data: ExtendedOrderReqAdmin): void => {
    onChange(data)
  }

  const updateReservationPrice = (data: ExtendedOrderReqAdmin) => {
    fetchPrice(data)
      .then(([price, _, { reservation }]) => {
        handleChangeRow({ ...data, ...price })
        setReservationBons(reservation)
      })
      .catch(noop)
  }

  const handleTableChange = (tableId: number) => {
    const seatingIds: number[] = req.reservation
      ? req.reservation.seatingIds.some((seatingId) => seatingId === tableId)
        ? req.reservation.seatingIds.filter(
            (seatingId) => seatingId !== tableId
          )
        : req.reservation.seatingIds.concat(tableId)
      : [tableId]

    const data: ExtendedOrderReqAdmin = {
      ...req,
      reservation: req.reservation
        ? { ...req.reservation, seatingIds }
        : {
            contactInfo: blankContactInfo,
            dtStart: req.packageOrder?.dtStart || Date.now(),
            numberOfPersons: req.packageOrder?.numberOfPersons || 1,
            seatingIds,
          },
    }
    handleChangeRow(data)
    updateReservationPrice(data)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
    handleChangeRow({ ...req, reservation: null, price: 0 })
    setReservationBons([])
  }

  const handleDateChange = (dtStart: number) => {
    handleChangeRow({
      ...req,
      reservation: req.reservation && { ...req.reservation, dtStart },
    })
  }

  const handlePersonChange = (numberOfPersons: number) => {
    handleChangeRow({
      ...req,
      reservation: req.reservation && { ...req.reservation, numberOfPersons },
    })
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleOpenModal = () => {
    updateReservationPrice(req)
    setIsModalOpen(true)
  }

  return (
    <>
      <Button
        onClick={handleOpenModal}
        variant={"outlined"}
        color={"secondary"}
        startIcon={<Add />}
      >
        <FormattedMessage id={`reservation`} defaultMessage={`Reservation`} />
      </Button>
      <p>
        {getReservationContents(
          req.reservation?.seatingIds
            .map((id) => {
              const table = seating.get(id.toString())
              const section = sections.get(table?.sectionId.toString() || "")
              return table && section
                ? {
                    bons: [],
                    seating: table,
                    section,
                  }
                : null
            })
            .filter(isNotNull)
        )}
      </p>
      <ReservationModal
        onTableChange={handleTableChange}
        onDateChange={handleDateChange}
        onPersonChange={handlePersonChange}
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onCancel={handleCancel}
        seating={seating}
        sections={sections}
        date={req.reservation?.dtStart}
        numberOfPersons={req.reservation?.numberOfPersons}
        seatingIds={req.reservation?.seatingIds || []}
        price={req.price || 0}
        bonSummary={reservationBons}
      />
    </>
  )
}
