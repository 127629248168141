import { Map, Record, RecordOf } from "immutable"
import { normalize, schema } from "normalizr"
import { RequestStatus } from "../types/statusTypes"
import { SessionResAdmin } from "../types/apiTypes"

export type SessionMap = Map<string, SessionResAdmin>

export function normalizeSession(data: SessionResAdmin[]): SessionMap {
  const sessionsSchema = [new schema.Entity("sessions")]
  const {
    entities: { sessions },
  } = normalize<
    SessionResAdmin,
    { [key: string]: { [key: string]: SessionResAdmin } }
  >(data, sessionsSchema)
  return Map(sessions)
}

const defaultStatuses = {
  fetchSessions: RequestStatus.INITIAL,
}

const makeStatuses: Record.Factory<typeof defaultStatuses> = Record(
  defaultStatuses
)

const defaultErrors = {
  fetchSessions: "",
}

const makeErrors: Record.Factory<typeof defaultErrors> = Record(defaultErrors)

type SessionStateType = {
  statuses: RecordOf<typeof defaultStatuses>
  errors: RecordOf<typeof defaultErrors>
  records: SessionMap
}

const defaultSessionState: SessionStateType = {
  statuses: makeStatuses(),
  errors: makeErrors(),
  records: Map() as SessionMap,
}

export type SessionState = RecordOf<SessionStateType>

export type SessionActions = keyof typeof defaultStatuses

export const makeSessionState: Record.Factory<SessionStateType> = Record(
  defaultSessionState
)
