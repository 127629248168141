import React, { Children, ReactElement, ReactNode } from "react"
import { FormattedMessage } from "react-intl"
import { Button, IconButton } from "@material-ui/core"
import { Add, Delete } from "@material-ui/icons"
import FieldSet from "../FieldSet/FieldSet"
import useStyles from "./MultipleFiledSetStyles"
import { Field } from "../Messages/FieldMessage"
import EntityMessage, { Entity } from "../Messages/EntityMessage"

interface IMultipleFiledSetProps {
  children: ReactElement[]
  altCta?: ReactNode
  onCreate?: () => void
  onRemove?: (key: string) => void
  entity: Entity
  messageId?: Field
  dense?: boolean
}

export default function MultipleFiledSet({
  messageId,
  children,
  onCreate,
  onRemove,
  dense = false,
  entity,
  altCta,
}: IMultipleFiledSetProps) {
  const classes = useStyles({ dense })

  return (
    <FieldSet messageId={messageId} dense={dense}>
      {Children.map(children, (child, index) => (
        <div className={classes.child}>
          <IconButton
            aria-label={`delete`}
            className={classes.removeButton}
            size={dense ? "small" : "medium"}
            disabled={!onRemove}
            onClick={() =>
              onRemove &&
              onRemove(
                typeof child.key === "string" ? child.key : index.toString()
              )
            }
          >
            <Delete />
          </IconButton>
          {child}
        </div>
      ))}
      <div className={classes.actions}>
        {onCreate && (
          <Button
            onClick={onCreate}
            variant={"outlined"}
            color={"secondary"}
            startIcon={<Add />}
          >
            <FormattedMessage id={"add"} defaultMessage={"Add"} />
            &nbsp;
            <EntityMessage id={entity} />
          </Button>
        )}
        {altCta}
      </div>
    </FieldSet>
  )
}
