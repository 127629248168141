import React, { ReactNode } from "react"
import { useSelector } from "react-redux"
import DataGrid from "../../components/DataGrid/DataGrid"
import OrderFilter from "../../components/OrderFilter/OrderFilter"
import RouteMessage from "../../components/Messages/RouteMessage"
import { Field } from "../../components/Messages/FieldMessage"
import { useOrderFetch } from "../../hooks/orderHooks"
import { useCan, useLocalStoragePagination } from "../../hooks/interfaceHooks"
import {
  OrderActivityCell,
  OrderGroupCell,
  OrderSourceCell,
  OrderAddressCell,
  OrderCostsCell,
} from "../Orders/OrderListCells"
import StatusProgressBar from "../../components/StatusProgressBar/StatusProgressBar"
import { formatDateTime } from "../../utils/timeUtils"
import ReportSummary from "./ReportSummary"
import { selectReport } from "../../selectors/reportSelector"
import { ReportDataGrid } from "../../types/globalTypes"

interface Props {
  toolbarIcons: ReactNode
}
export default function ReportList({ toolbarIcons }: Props) {
  const report = useSelector(selectReport)
  const can = useCan()
  const statuses = useOrderFetch()
  const paginationProps = useLocalStoragePagination("report")

  return (
    <>
      <StatusProgressBar statuses={statuses} />
      <DataGrid<ReportDataGrid>
        {...paginationProps}
        title={<RouteMessage id={"report"} />}
        data={report}
        customFilter={<OrderFilter />}
        toolbarIcons={toolbarIcons}
        defaultFilterOpen
        columns={[
          {
            title: Field.DATE,
            field: "dtStart",
            render: ({ packageOrder }: ReportDataGrid) =>
              formatDateTime(packageOrder?.dtStart),
            customExport: ({ packageOrder }: ReportDataGrid) =>
              formatDateTime(packageOrder?.dtStart, true),
          },
          {
            title: Field.GROUP,
            render: OrderGroupCell,
            customSort: (a: ReportDataGrid, b: ReportDataGrid) =>
              a.name.localeCompare(b.name),
            exportField: "name",
          },
          {
            title: Field.ADDRESS,
            render: OrderAddressCell,
            sorting: false,
            customExport: ({ packageOrder }: ReportDataGrid) =>
              JSON.stringify(packageOrder?.contactInfo),
          },
          {
            title: Field.ACTIVITY,
            render: (props: ReportDataGrid) => <OrderActivityCell {...props} />,
            customSort: (a: ReportDataGrid, b: ReportDataGrid) => {
              if (a.packageOrder && b.packageOrder) {
                return a.packageOrder.option.id - b.packageOrder.option.id
              }
              return 1
            },
            customExport: (data: ReportDataGrid) =>
              data.packageOrder?.option.translations.EN.title || "",
          },
          {
            title: Field.EXTRAS,
            field: "req",
            render: () => null, // TODO
            sorting: false,
          },
          {
            title: Field.NUMBER_OF_PERSONS,
            field: "numberOfPersons",
            customSort: (a: ReportDataGrid, b: ReportDataGrid) => {
              if (a.packageOrder && b.packageOrder) {
                return (
                  a.packageOrder.numberOfPersons -
                  b.packageOrder.numberOfPersons
                )
              }
              return 1
            },
            customExport: (data: ReportDataGrid) =>
              data.packageOrder?.numberOfPersons.toString() || "",
          },
          {
            title: Field.SOURCE,
            render: (props: ReportDataGrid) => <OrderSourceCell {...props} />,
            customSort: (a: ReportDataGrid, b: ReportDataGrid) =>
              a.agencyId - b.agencyId,
            exportField: "agencyId",
          },
          {
            title: Field.REWARD,
            field: "employeeReward",
            hidden: !can("orderEmployeeReward", "R"),
          },
          {
            title: Field.COSTS,
            render: OrderCostsCell,
            customSort: (a: ReportDataGrid, b: ReportDataGrid) =>
              a.price - b.price,
            exportField: "price",
            hidden: !can("orderCosts", "R"),
          },
        ]}
      >
        <ReportSummary />
      </DataGrid>
    </>
  )
}
