import { ActionTypes } from "../types/actionTypes"
import { AgencyActions, AgencyMap } from "../models/agencyModel"
import { AgencyResAdmin } from "../types/apiTypes"

export const fetchAgencies = (ids?: number[]) =>
  ({
    type: ActionTypes.FETCH_AGENCIES_REQUESTED,
    payload: {
      ids,
    },
  } as const)

export const fetchAgenciesFailed = (message: string) =>
  ({
    type: ActionTypes.FETCH_AGENCIES_FAILED,
    payload: {
      message,
    },
  } as const)

export const fetchAgenciesSucceeded = (agencies: AgencyMap) =>
  ({
    type: ActionTypes.FETCH_AGENCIES_SUCCEEDED,
    payload: {
      agencies,
    },
  } as const)

export const removeAgency = (id: number) =>
  ({
    type: ActionTypes.REMOVE_AGENCY,
    payload: { id },
  } as const)

export const addAgency = (agency: AgencyResAdmin) =>
  ({
    type: ActionTypes.ADD_AGENCY,
    payload: { agency },
  } as const)

export const resetAgencyStatus = (key: AgencyActions) =>
  ({
    type: ActionTypes.RESET_AGENCY_STATUS,
    payload: { key },
  } as const)
