import React from "react"
import { useSelector } from "react-redux"
import { localeSort } from "utils/arrayUtils"
import { Field } from "../../components/Messages/FieldMessage"
import Results from "../../components/Results/Results"
import { Entity } from "../../components/Messages/EntityMessage"
import StatusProgressBar from "../../components/StatusProgressBar/StatusProgressBar"
import DataGrid from "../../components/DataGrid/DataGrid"
import { UserRes } from "../../types/apiTypes"
import FieldSet from "../../components/FieldSet/FieldSet"
import { useUserDelete, useUserFetch, useUserSave } from "../../hooks/userHooks"
import { AppState } from "../../store"
import { selectAgencyUserDataGrid } from "../../selectors/agencySelector"
import { useLocalStoragePagination } from "../../hooks/interfaceHooks"

interface IAgencyDetailUserListProps {
  agencyId: number
}

export default function AgencyDetailUserList({
  agencyId,
}: IAgencyDetailUserListProps) {
  const [, fetchStatus, fetchError] = useUserFetch()
  const [deleteUser, deleteStatus, deleteUserError] = useUserDelete()
  const [saveUser, saveStatus, saveError] = useUserSave()
  const paginationProps = useLocalStoragePagination("agencyUser")
  const userGridData = useSelector((state: AppState) =>
    selectAgencyUserDataGrid(state, agencyId)
  )

  return (
    <FieldSet messageId={Field.USERS}>
      <Results
        statuses={[deleteStatus, saveStatus, fetchStatus]}
        errors={[deleteUserError, saveError, fetchError]}
        actions={[`delete`, "save", "fetch"]}
        entity={Entity.USER}
      />
      <StatusProgressBar statuses={[saveStatus, deleteStatus, fetchStatus]} />
      <DataGrid<UserRes>
        {...paginationProps}
        data={userGridData}
        columns={[
          {
            title: Field.NICKNAME,
            field: "nickname",
            initialEditValue: "",
            customSort: localeSort("nickname"),
            defaultSort: "asc",
          },
          { title: Field.NAME, field: "fullName", initialEditValue: "" },
          { title: Field.EMAIL, field: "email", initialEditValue: "" },
          { title: Field.PHONE, field: "phone", initialEditValue: "" },
          { title: Field.NOTE, field: "note", initialEditValue: "" },
        ]}
        editable={{
          onRowAdd: ({ nickname, fullName, email, phone, note }: UserRes) =>
            new Promise<void>((resolve) => {
              saveUser({
                id: 0,
                agencyId,
                nickname: nickname || "",
                fullName: fullName || "",
                email: email || "",
                phone: phone || "",
                note: note || "",
                sendInvoice: false,
                roles: ["Agency"],
                accountNumber: "",
                address: "",
                crn: "",
                vatId: "",
              })
              resolve()
            }),
          onRowUpdate: ({ accessRights, ...userData }: UserRes) =>
            new Promise<void>((resolve) => {
              saveUser(userData, userData.id.toString())
              resolve()
            }),
          onRowDelete: ({ id: userId }) =>
            new Promise<void>((resolve) => {
              deleteUser(userId)
              resolve()
            }),
        }}
        options={{ pageSize: 5 }}
      />
    </FieldSet>
  )
}
