import performRequest, { getIdParam, getIdsParam } from "../utils/apiUtils"
import { HttpMethods } from "../types/globalTypes"
import { GalleryReq, GalleryResAdmin, IdRes, LinkRes } from "../types/apiTypes"
import { CropDimensions } from "../hooks/galleryHooks"

export function requestUploadFile(
  basicAuth: string,
  file: File,
  crop?: CropDimensions
): Promise<LinkRes> {
  const formData = new FormData()
  formData.append("file", file)
  if (crop) {
    formData.append("startX", Math.round(crop.x).toString())
    formData.append("startY", Math.round(crop.y).toString())
    formData.append("endX", Math.round(crop.x + crop.width).toString())
    formData.append("endY", Math.round(crop.y + crop.height).toString())
  }
  return performRequest(
    {
      endpoint: `upload`,
      auth: basicAuth,
      method: HttpMethods.POST,
      body: formData,
    },
    true
  )
}

export function requestGetGalleries(
  basicAuth: string,
  ids: Array<number> = []
): Promise<GalleryResAdmin[]> {
  return performRequest(
    {
      endpoint: `gallery${getIdsParam(ids)}`,
      auth: basicAuth,
      method: HttpMethods.GET,
    },
    true
  )
}

export function requestSaveGallery(
  basicAuth: string,
  body: GalleryReq,
  id?: number | null
): Promise<IdRes<number>> {
  return performRequest(
    {
      endpoint: `gallery${getIdParam(id)}`,
      auth: basicAuth,
      method: id ? HttpMethods.PUT : HttpMethods.POST,
      body,
    },
    true
  )
}

export function requestDeleteGallery(
  basicAuth: string,
  id: number
): Promise<IdRes<number>> {
  return performRequest(
    {
      endpoint: `gallery${getIdParam(id)}`,
      auth: basicAuth,
      method: HttpMethods.DELETE,
    },
    true
  )
}
