import { call, delay, put, select } from "redux-saga/effects"
import { selectToken } from "../selectors/interfaceSelector"
import {
  fetchAgencies,
  fetchAgenciesFailed,
  fetchAgenciesSucceeded,
  resetAgencyStatus,
} from "../actions/agencyActions"
import { requestGetAgencies } from "../api/agencyRequests"
import { AgencyActions, normalizeAgencies } from "../models/agencyModel"
import { AgencyResAdmin } from "../types/apiTypes"

function* resetStatus(key: AgencyActions) {
  yield delay(3000)
  yield put(resetAgencyStatus(key))
}

export function* fetchAgenciesSaga({
  payload: { ids },
}: ReturnType<typeof fetchAgencies>) {
  try {
    const auth: string = yield select(selectToken)
    const data: AgencyResAdmin[] = yield call(requestGetAgencies, auth, ids)
    yield put(fetchAgenciesSucceeded(normalizeAgencies(data)))
    // eslint-disable-next-line
  } catch (e: any) {
    yield put(fetchAgenciesFailed(e.message))
  } finally {
    yield* resetStatus("fetchAgencies")
  }
}
