import { Typography } from "@material-ui/core"
import React, { ReactNode } from "react"
import useStyles from "./SectionStyle"

export interface ISectionProps {
  children: ReactNode
  heading: ReactNode
  padding?: number
  margin?: number
  isSeparated?: boolean
}

export default function Section({
  children,
  heading,
  padding = 0,
  margin = 0,
  isSeparated = false,
}: ISectionProps) {
  const classes = useStyles({ padding, isSeparated, margin })
  return (
    <section className={classes.root}>
      <Typography component={"h2"} variant={"h6"} className={classes.heading}>
        {heading}
      </Typography>
      {children}
    </section>
  )
}
