import { ActionTypes } from "../types/actionTypes"
import {
  OrdersMap,
  SeatingMap,
  SectionMap
} from "../models/ordersModel"
import {
  OrderConflictRes,
  OrderEmployeeResAdmin,
  OrderEmployeeStatus,
  OrderResAdmin
} from "../types/apiTypes"
import { SortType } from "../types/globalTypes"

export const fetchOrders = (ids?: number[]) =>
  ({
    type: ActionTypes.FETCH_ORDERS_REQUESTED,
    payload: { ids },
  } as const)

export const fetchOrdersSucceeded = (orders: OrdersMap, strategy: "set" | "merge") =>
  ({
    type: ActionTypes.FETCH_ORDERS_SUCCEEDED,
    payload: { orders, strategy },
  } as const)

export const fetchOrdersFailed = (message: string) =>
  ({
    type: ActionTypes.FETCH_ORDERS_FAILED,
    payload: { message },
  } as const)

export const fetchSections = (ids?: number[]) =>
  ({
    type: ActionTypes.FETCH_SECTIONS_REQUESTED,
    payload: { ids },
  } as const)

export const fetchSectionsSucceeded = (sections: SectionMap) =>
  ({
    type: ActionTypes.FETCH_SECTIONS_SUCCEEDED,
    payload: { sections },
  } as const)

export const fetchSectionsFailed = (message: string) =>
  ({
    type: ActionTypes.FETCH_SECTIONS_FAILED,
    payload: { message },
  } as const)

export const fetchSeating = (ids?: number[]) =>
  ({
    type: ActionTypes.FETCH_SEATING_REQUESTED,
    payload: { ids },
  } as const)

export const fetchSeatingSucceeded = (seating: SeatingMap) =>
  ({
    type: ActionTypes.FETCH_SEATING_SUCCEEDED,
    payload: { seating },
  } as const)

export const fetchSeatingFailed = (message: string) =>
  ({
    type: ActionTypes.FETCH_SEATING_FAILED,
    payload: { message },
  } as const)

export const fetchConflicts = () =>
  ({
    type: ActionTypes.FETCH_CONFLICTS_REQUESTED,
  } as const)

export const fetchConflictsSucceeded = (conflicts: OrderConflictRes) =>
  ({
    type: ActionTypes.FETCH_CONFLICTS_SUCCEEDED,
    payload: { conflicts },
  } as const)

export const fetchConflictsFailed = (message: string) =>
  ({
    type: ActionTypes.FETCH_CONFLICTS_FAILED,
    payload: { message },
  } as const)

export const removeOrder = (id: number) =>
  ({
    type: ActionTypes.REMOVE_ORDER,
    payload: { id },
  } as const)

export const addOrder = (order: OrderResAdmin) =>
  ({
    type: ActionTypes.ADD_ORDER,
    payload: { order },
  } as const)


export const saveEmployeeStatus = (
  id: number,
  orderId: number,
  status: OrderEmployeeStatus
) =>
  ({
    type: ActionTypes.SAVE_EMPLOYEE_STATUS_REQUESTED,
    payload: { id, status, orderId },
  } as const)

export const saveEmployeeStatusSucceeded = (
  id: number,
  orderId: number,
  status: OrderEmployeeStatus
) =>
  ({
    type: ActionTypes.SAVE_EMPLOYEE_STATUS_SUCCEEDED,
    payload: { id, orderId, status },
  } as const)

export const saveEmployeeStatusFailed = (message: string) =>
  ({
    type: ActionTypes.SAVE_EMPLOYEE_STATUS_FAILED,
    payload: { message },
  } as const)

export const saveEmployeeSucceeded = (
  id: number,
  employee: OrderEmployeeResAdmin
) =>
  ({
    type: ActionTypes.SAVE_EMPLOYEE_SUCCEEDED,
    payload: { id, employee },
  } as const)

export const saveEmployeeFailed = (message: string) =>
  ({
    type: ActionTypes.SAVE_EMPLOYEE_FAILED,
    payload: { message },
  } as const)

export const sendVoucherEmail = (id: number) =>
  ({
    type: ActionTypes.SEND_VOUCHER_EMAIL_REQUESTED,
    payload: { id },
  } as const)

export const sendVoucherEmailFailed = (message: string) =>
  ({
    type: ActionTypes.SEND_VOUCHER_EMAIL_FAILED,
    payload: { message },
  } as const)

export const sendVoucherEmailSucceeded = () =>
  ({
    type: ActionTypes.SEND_VOUCHER_EMAIL_SUCCEEDED,
  } as const)

export const resetOrderStatus = () =>
  ({
    type: ActionTypes.RESET_ORDER_STATUS,
  } as const)

export const setOrderFilter = (key: string, value: string[]) =>
  ({
    type: ActionTypes.SET_ORDER_FILTER,
    payload: { key, value },
  } as const)

export const setOrderSearchQuery = (query: string) =>
  ({
    type: ActionTypes.SET_ORDER_SEARCH_QUERY,
    payload: query,
  } as const)

export const setOrderPageNumber = (payload: number) =>
  ({
    type: ActionTypes.SET_ORDER_PAGE_NUMBER,
    payload,
  } as const)

export const setOrderPageSize = (payload: number) =>
  ({
    type: ActionTypes.SET_ORDER_PAGE_SIZE,
    payload,
  } as const)

export const setOrderSortType = (payload: SortType) =>
  ({
    type: ActionTypes.SET_ORDER_SORT_TYPE,
    payload,
  } as const)
