import React from "react"
import { FormattedMessage } from "react-intl"

interface IBoolMessageProps {
  value: boolean
}

export default function BoolMessage({ value }: IBoolMessageProps) {
  if (value) return <FormattedMessage id={"yes"} defaultMessage={"Yes"} />

  return <FormattedMessage id={"no"} defaultMessage={"No"} />
}
