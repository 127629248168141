import React from "react"
import { SessionDataGrid } from "../../types/globalTypes"
import BonSummary from "../../components/BonSummary/BonSummary"
import IconCell from "../../components/IconCell/IconCell"
import Price from "../../components/Price/Price"

export function SessionBonsCell({ bonSum }: SessionDataGrid) {
  if (bonSum.length === 0) return null
  return (
    <IconCell tooltip={<BonSummary summary={bonSum} />} />
  )
}

export function SessionSurchargeCell({ surchargeReceived }: SessionDataGrid) {
  return <Price price={surchargeReceived} />
}
