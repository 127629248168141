import { Map, Record, RecordOf } from "immutable"
import { normalize, schema } from "normalizr"
import { RequestStatus } from "../types/statusTypes"
import { SiteResAdmin } from "../types/apiTypes"

export type NewsMap = Map<string, SiteResAdmin>

export function normalizeNews(data: SiteResAdmin[]): NewsMap {
  const newsSchema = [new schema.Entity("news")]
  const {
    entities: { news },
  } = normalize<
    SiteResAdmin,
    { [key: string]: { [key: string]: SiteResAdmin } }
  >(data, newsSchema)
  return Map(news)
}

const defaultStatuses = {
  fetchNews: RequestStatus.INITIAL,
  saveNews: RequestStatus.INITIAL,
  deleteNews: RequestStatus.INITIAL,
}

const makeStatuses: Record.Factory<typeof defaultStatuses> =
  Record(defaultStatuses)

const defaultErrors = {
  fetchNews: "",
  saveNews: "",
  deleteNews: "",
}

const makeErrors: Record.Factory<typeof defaultErrors> = Record(defaultErrors)

export type NewsStateType = {
  statuses: RecordOf<typeof defaultStatuses>
  errors: RecordOf<typeof defaultErrors>
  records: NewsMap
}

const defaultNewsState: NewsStateType = {
  statuses: makeStatuses(),
  errors: makeErrors(),
  records: Map() as NewsMap,
}

export type NewsState = RecordOf<typeof defaultNewsState>

export type NewsActions = keyof typeof defaultStatuses

export const makeDefaultNewsState: Record.Factory<NewsStateType> =
  Record(defaultNewsState)
