import React from "react"
import ReactDOM from "react-dom"

interface IPrintProps {
  children: React.ReactNode
}

export default function Print({ children }: IPrintProps) {
  const element = document.getElementById("print")
  return element ? ReactDOM.createPortal(children, element) : null
}
