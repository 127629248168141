import React from "react"
import { IconButton } from "@material-ui/core"

interface IToolbarButtonProps {
  icon: React.ReactNode
  onClick: () => void
  title?: string
}

export default function ToolbarButton({
  onClick,
  title,
  icon,
}: IToolbarButtonProps) {
  return (
    <IconButton color={"default"} onClick={onClick} title={title}>
      {icon}
    </IconButton>
  )
}
