import { useState } from "react"
import { Map } from "immutable"
import { PackageOptionPriceResAdmin } from "../types/apiTypes"
import { RequestStatus } from "../types/statusTypes"
import {
  requestGetPackagePrices,
  requestGetUpsalePrices,
} from "../api/agencyRequests"
import { OptionPrices } from "../types/globalTypes"
import { arrayUnion, arrayUnique } from "../utils/arrayUtils"
import { useFetchHook } from "./fetchHooks"

export type OptionPriceMap = Map<string, PackageOptionPriceResAdmin>

export const makePriceId = (agencyId: number, optionId: number) =>
  `${agencyId}-${optionId}`

export function usePricesFetch(
  agencyId?: number | null,
  isUpsale: boolean = false
): [
  (callback: (priceMap: OptionPriceMap) => void) => Promise<void>,
  RequestStatus,
  string
] {
  const state = useFetchHook()
  const requestFn = isUpsale ? requestGetUpsalePrices : requestGetPackagePrices
  return [
    async function fetchPrices(callback) {
      try {
        state.handleRequest()
        if (agencyId) {
          const response = await requestFn(state.token, agencyId)
          callback(
            Map(
              response.map((price) => [
                makePriceId(price.agencyId, price.packageOptionId),
                price,
              ])
            )
          )
        }
        state.handleSuccess()
        // eslint-disable-next-line
      } catch (e: any) {
        console.error(e)
        state.handleFail(e.message)
      }
    },
    state.status,
    state.error,
  ]
}

export type PriceState = Record<string, Record<string, OptionPrices>>

export function usePriceState(): {
  prices: PriceState
  priceOrder: string[]
  fillPrices: (
    map: OptionPriceMap,
    optionPackageMap: Map<number, number>
  ) => void
  changePrice: (
    activityId: string,
    optionId: string,
    key: string,
    value: string
  ) => void
  removePriceGroup: (activityId: string) => void
  addPriceGroup: (activityId: string, optionIds: string[]) => void
} {
  const [prices, setPrices] = useState<PriceState>({})
  const [priceOrder, setPriceOrder] = useState<string[]>([])
  return {
    prices,
    priceOrder,
    fillPrices: (priceMap, optionPackageMap) => {
      setPrices({
        ...priceMap.reduce(
          (acc: PriceState, price: PackageOptionPriceResAdmin) => {
            const packageId = optionPackageMap
              .get(price.packageOptionId)
              ?.toString()
            if (!packageId)
              throw new Error(
                `Cannot find package id for option ${price.packageOptionId}. Make sure you provide correct option-package map.`
              )
            const packageOptionId = price.packageOptionId.toString()
            acc[packageId] = {
              ...acc[packageId],
              [packageOptionId]: {
                priceUnit: price.price.toString(),
                pricePerPerson: price.pricePerNCapitas.price.toString(),
                deposit: price.deposit.toString(),
                fee: price.feeOver10Persons.toString(),
              },
            }
            return acc
          },
          {}
        ),
        ...prices,
      })
      setPriceOrder((prevPriceOrder) =>
        arrayUnion(
          prevPriceOrder,
          priceMap
            .valueSeq()
            .toArray()
            .map(
              ({ packageOptionId }) =>
                optionPackageMap.get(packageOptionId)?.toString() ||
                "missing packageOptionMap"
            )
            .filter(arrayUnique)
        )
      )
    },
    changePrice: (activityId, optionId, key, value) => {
      setPrices({
        ...prices,
        [activityId]: {
          ...prices[activityId],
          [optionId]: {
            ...prices[activityId][optionId],
            [key]: value,
          },
        },
      })
    },
    removePriceGroup: (activityId: string) => {
      setPrices(({ [activityId]: x, ...r }) => r)
      setPriceOrder((order) => order.filter((id) => id !== activityId))
    },
    addPriceGroup: (activityId, optionIds) => {
      setPrices({
        ...prices,
        [activityId]: optionIds.reduce(
          (acc: Record<string, OptionPrices>, id) => {
            acc[id] = {}
            return acc
          },
          {}
        ),
      })
      setPriceOrder((order) => [...order, activityId])
    },
  }
}
