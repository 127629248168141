import React from "react"
import { ReservationDataGrid } from "../../types/globalTypes"
import FieldMessage, { Field } from "../../components/Messages/FieldMessage"
import IconCell from "../../components/IconCell/IconCell"

export default function ReservationGroupCell({
  name,
  email,
  phone,
}: ReservationDataGrid) {
  return (
    <IconCell
      tooltip={
        <>
          <p>
            <FieldMessage id={Field.NAME} />: {name}
          </p>
          <p>
            <FieldMessage id={Field.EMAIL} />: {email}
          </p>
          <p>
            <FieldMessage id={Field.PHONE} />: {phone}
          </p>
        </>
      }
    >
      {name}
    </IconCell>
  )
}
