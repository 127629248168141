import { createSelector } from "reselect"
import { AppState } from "../store"

export const selectCareerRecords = (state: AppState) => state.career.records

export const selectCareerFetchStatus = (state: AppState) =>
  state.career.statuses.fetchCareer

export const selectCareerDeleteStatus = (state: AppState) =>
  state.career.statuses.deleteCareer

export const selectCareerSaveStatus = (state: AppState) =>
  state.career.statuses.saveCareer

export const selectCareerFetchError = (state: AppState) =>
  state.career.errors.fetchCareer

export const selectCareerDeleteError = (state: AppState) =>
  state.career.errors.deleteCareer

export const selectCareerSaveError = (state: AppState) =>
  state.career.errors.saveCareer

export const selectCareerById = createSelector(
  selectCareerRecords,
  (state: AppState, id?: string) => id,
  (careers, id) => (id && careers.size > 0 ? careers.get(id) : null)
)
