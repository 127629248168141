import React, { ChangeEvent, memo, useEffect, useState } from "react"
import { Grid } from "@material-ui/core"
import { Map } from "immutable"
import Form from "../../components/Form/Form"
import Input from "../../components/Input/Input"
import ImageUpload from "../../components/ImageUpload/ImageUpload"
import Results from "../../components/Results/Results"
import {
  blankMedium,
  blankTranslations,
  createBlankOptionPrice,
  languageList,
  simpleToFullTranslation,
} from "../../utils/typeUtils"
import LanguageTab from "../../components/LanguageTab/LanguageTab"
import { useUpsaleFetch, useUpsaleSave } from "../../hooks/upsaleHooks"
import { Field } from "../../components/Messages/FieldMessage"
import RouteMessage from "../../components/Messages/RouteMessage"
import { Entity } from "../../components/Messages/EntityMessage"
import { usePricesFetch, usePriceState } from "../../hooks/priceHooks"
import config from "../../config"
import FieldSet from "../../components/FieldSet/FieldSet"
import {
  useLanguageState,
  useToggle,
  useTranslationState,
} from "../../hooks/stateHooks"
import { useActivityPriceSave } from "../../hooks/activityHooks"
import { MediaType } from "../../types/apiTypes"
import { requestUploadFile } from "../../api/galleryRequests"
import { useToken } from "../../hooks/fetchHooks"
import Checkbox from "../../components/Checkbox/Checkbox"
import { useIdParam } from "../../hooks/routerHooks"

function UpsellingDetail() {
  const id = useIdParam()
  const token = useToken()
  const { language, setLanguage } = useLanguageState()
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isActive, toggleActive, setIsActive] = useToggle()
  const [upsaleMap, fetchStatus, fetchError] = useUpsaleFetch(id)
  const [saveUpsale, saveStatus, saveError] = useUpsaleSave()
  const [title, handleTitleChange, fillTitle] = useTranslationState()
  const [content, handleContentChange, fillContent] = useTranslationState()
  const { prices, fillPrices, changePrice } = usePriceState()
  const [fetchPrices, priceFetchStatus, priceFetchError] = usePricesFetch(
    config.agencyIds.web,
    true
  )
  const [savePrices, priceSaveStatus, priceSaveError] = useActivityPriceSave(
    true
  )
  const [mainImage, setMainImage] = useState<string | File>(``)
  const upsale = upsaleMap.get(id)

  useEffect(() => {
    if (upsale) {
      setIsActive(upsale.isActive)
      fillTitle(upsale.translations, "title")
      fillContent(upsale.translations, "content")
      const {
        media: [{ url }],
      } = upsale.gallery || { media: [blankMedium] }
      setMainImage(url)
      fetchPrices((priceMap) => {
        fillPrices(
          Map([
            [
              `${config.agencyIds.web}-${upsale.id}`,
              priceMap.get(`${config.agencyIds.web}-${upsale.id}`) ||
                createBlankOptionPrice(upsale.id),
            ],
          ]),
          Map([[upsale.id, upsale.id]])
        )
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upsale])

  const onSubmit = async () => {
    if (upsale) {
      setIsSubmitted(true)
      const { link } = await (mainImage instanceof File
        ? requestUploadFile(token, mainImage)
        : { link: mainImage })
      const translations = simpleToFullTranslation(
        [title, content],
        ["title", "content"]
      )
      savePrices(prices)
      saveUpsale(
        {
          ...upsale,
          translations,
          isActive,
          gallery: {
            translations: blankTranslations,
            media: [
              {
                translations: blankTranslations,
                type: MediaType.IMAGE,
                url: link,
              },
            ],
          },
        },
        id
      )
    }
  }

  const handlePriceChange = ({
    currentTarget: { name, value },
  }: ChangeEvent<HTMLInputElement>) => {
    changePrice(id, upsale?.id.toString() as string, name, value)
  }

  return (
    <>
      <Results
        statuses={[fetchStatus, saveStatus]}
        errors={[fetchError, saveError]}
        actions={["fetch", "save"]}
        entity={Entity.UPSALE}
      />
      <Results
        statuses={[priceFetchStatus, priceSaveStatus]}
        errors={[priceFetchError, priceSaveError]}
        actions={["fetch", "save"]}
        entity={Entity.PRICE}
      />
      <Form
        title={<RouteMessage id={"upsellingDetail"} />}
        onSubmit={onSubmit}
        statuses={[saveStatus, fetchStatus]}
        toolbar={
          <LanguageTab
            language={language}
            onChange={setLanguage}
            notifications={languageList.filter(
              (lang) => !title[lang] || !content[lang]
            )}
          />
        }
        useGenericCta
      >
        <Input
          messageId={Field.TITLE}
          name={`title`}
          onChange={(e) => handleTitleChange(e.currentTarget.value, language)}
          value={title[language]}
          showRequiredWarning={isSubmitted}
        />
        <Input
          messageId={Field.CONTENT}
          name={`content`}
          onChange={(e) => handleContentChange(e.currentTarget.value, language)}
          value={content[language]}
          multiline
          showRequiredWarning={isSubmitted}
        />
        <Checkbox
          messageId={Field.IS_ACTIVE}
          name={"isActive"}
          checked={isActive}
          onChange={toggleActive}
        />
        <ImageUpload
          source={mainImage}
          name={`mainImage`}
          onUpload={setMainImage}
          onDelete={() => setMainImage(``)}
          showRequiredWarning={isSubmitted}
        />
        <FieldSet messageId={Field.PRICE_LIST}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Input
                onChange={handlePriceChange}
                defaultValue={0}
                value={prices[id]?.[upsale?.id.toString() as string]?.priceUnit}
                name={"priceUnit"}
                messageId={Field.PRICE}
                type={"number"}
                min={0}
              />
            </Grid>
            <Grid item xs={3}>
              <Input
                onChange={handlePriceChange}
                defaultValue={0}
                value={
                  prices[id]?.[upsale?.id.toString() as string]?.pricePerPerson
                }
                name={"pricePerPerson"}
                messageId={Field.PRICE_PERSON}
                type={"number"}
                min={0}
              />
            </Grid>
            <Grid item xs={3}>
              <Input
                onChange={handlePriceChange}
                defaultValue={0}
                value={prices[id]?.[upsale?.id.toString() as string]?.deposit}
                name={"deposit"}
                messageId={Field.DEPOSIT}
                type={"number"}
              />
            </Grid>
            <Grid item xs={3}>
              <Input
                onChange={handlePriceChange}
                defaultValue={0}
                value={prices[id]?.[upsale?.id.toString() as string]?.fee}
                name={"fee"}
                messageId={Field.FEE}
                type={"number"}
              />
            </Grid>
          </Grid>
        </FieldSet>
      </Form>
    </>
  )
}

export default memo(UpsellingDetail)
