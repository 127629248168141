import { addYears } from "date-fns"
import performRequest, { getIdParam, getIdsParam } from "../utils/apiUtils"
import { HttpMethods } from "../types/globalTypes"
import {
  IdRes,
  UserAvailabilityReq,
  UserAvailabilityResAdmin,
  UserReq,
  UserRes,
} from "../types/apiTypes"

export async function requestGetUsers(
  basicAuth: string,
  ids: number[] = []
): Promise<UserRes> {
  return performRequest({
    endpoint: `user${getIdsParam(ids)}`,
    auth: basicAuth,
    method: HttpMethods.GET,
  })
}

export async function requestDeleteUser(
  basicAuth: string,
  id: number
): Promise<number> {
  return performRequest({
    endpoint: `user${getIdParam(id)}`,
    auth: basicAuth,
    method: HttpMethods.DELETE,
  })
}

export async function requestSaveUser(
  basicAuth: string,
  user: UserReq,
  id?: number
): Promise<number> {
  return performRequest({
    endpoint: `user${getIdParam(id)}`,
    auth: basicAuth,
    body: user,
    method: id ? HttpMethods.PUT : HttpMethods.POST,
  })
}

export async function requestSaveAvailability(
  basicAuth: string,
  body: UserAvailabilityReq,
  id?: number
): Promise<IdRes<number>> {
  return performRequest({
    endpoint: `user/availability${getIdParam(id)}`,
    auth: basicAuth,
    method: id ? HttpMethods.PUT : HttpMethods.POST,
    body,
  })
}

export async function requestDeleteAvailability(
  basicAuth: string,
  id: number
): Promise<number> {
  return performRequest({
    endpoint: `user/availability${getIdParam(id)}`,
    auth: basicAuth,
    method: HttpMethods.DELETE,
  })
}

export async function requestGetAvailability(
  basicAuth: string,
  userIds: number[],
  from: number = Date.now(),
  to: number = addYears(Date.now(), 100).valueOf()
): Promise<UserAvailabilityResAdmin[]> {
  return performRequest({
    endpoint: `user/availability?from=${from}&to=${to}&userIds=${userIds.join(
      ","
    )}`,
    auth: basicAuth,
    method: HttpMethods.GET,
  })
}
